import React from 'react';

interface RecommendationSwaggerTabProps {
    endpoint: string | null;
}

export const RecommendationSwaggerTab: React.FC<RecommendationSwaggerTabProps> = ({ endpoint }) => {
    return (
        <div className="flex flex-1">
            {endpoint && <iframe style={{ minHeight: 800 }} src={endpoint} className="w-full h-full" />}
        </div>
    );
};
