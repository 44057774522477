import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inputTypes, runSchedules } from '../../constants';
import { useAccountIdString } from '../../hooks';
import {
    ArtifactDetailsResponseModel,
    ArtifactListModel,
    ArtifactPostResponseModel,
    BaseEntityValue,
    DataSourceDetailsProperties,
    DataSourceDetailsResponseModel,
    SelectOptionType,
} from '../../models';
import axios from '../../services/axios';
import { deleteErrorKey, getAccountIdFromQuery, getFormattedQuery } from '../../utils';
import { DynamicInputs, RunScheduleComponentForModal, shouldDynamicInputRender } from '../common';
import { InputField, SelectWithPopupField, SelectWithPopupTypes } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader, toast } from '../ui';

export interface CreateArtifactModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    artifact: ArtifactListModel | null;
    onSuccess?: (item: any, isEditing: boolean) => any;
}

type ArtifactFromDataSourceStateType = {
    inputs: DataSourceDetailsProperties[];
    dataSource: SelectOptionType | null;
    title: string;
    schedule?: any;
    scheduleFrequency?: string;
};

const initialState = {
    inputs: [],
    dataSource: null,
    title: '',
    schedule: runSchedules[0],
    scheduleFrequency: '',
};

export const CreateArtifactModal: React.FC<CreateArtifactModalProps> = ({
    isVisible,
    onHide,
    isEditing,
    artifact,
    onSuccess,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const accountId = useAccountIdString();

    /**
     * Local state
     */
    const [state, setState] = useState<ArtifactFromDataSourceStateType>({ ...initialState });
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [inputsLoading, setInputsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (isEditing && artifact) {
            fetchArtifactDetails(artifact.id);
        }
    }, [isEditing, artifact]);

    const onValidate = () => {
        let [isValid, _errors]: [boolean, any] = [true, {}];
        const { dataSource, inputs, schedule, title, scheduleFrequency } = state;

        if (!dataSource) {
            _errors.dataSource = 'Data Source is a required field!';
            isValid = false;
        }

        if (!title) {
            _errors.title = 'Title is required!';
            isValid = false;
        }

        inputs.forEach(inp => {
            if (shouldDynamicInputRender(inp, inputs) && inp.required && !inp.value) {
                isValid = false;
                _errors[inp.inputName] = 'This field is required';
            }
        });

        if (!schedule) {
            _errors.schedule = 'Schedule is Required!';
            isValid = false;
        }

        if (schedule?.value === '2' || schedule?.value === '3')
            if (!scheduleFrequency) {
                _errors.scheduleFrequency = 'This field is required!';
                isValid = false;
            }

        setErrors(_errors);
        return isValid;
    };

    const resetValues = () => setState({ ...initialState });

    const fetchDataSourceInputs = async id => {
        try {
            setInputsLoading(true);
            const query = {
                id,
                accountId,
            };

            const inputsResponse: DataSourceDetailsResponseModel = await axios.get(
                `/dataSource?${getFormattedQuery(query)}`
            );
            let inputs =
                inputsResponse.model?.inputs?.map(el => {
                    if (el.inputType === inputTypes.dropdown || el.inputType === inputTypes.dropdownOpen)
                        return {
                            ...el,
                            value: el.defaultValue ? el?.options?.find(x => x.value === el?.defaultValue) : null,
                            inputName: el.name,
                        };
                    return {
                        ...el,
                        value: el?.defaultValue || '',
                        inputName: el.name,
                    };
                }) || [];
            setState(prev => ({ ...prev, inputs }));
        } catch (err: any) {
        } finally {
            setInputsLoading(false);
        }
    };

    const fetchArtifactDetails = async id => {
        try {
            setDetailsLoading(true);
            const query = {
                accountId: getAccountIdFromQuery(),
                id,
            };
            const { model }: ArtifactDetailsResponseModel = await axios.get(`/artifact?${getFormattedQuery(query)}`);

            if (!model.dataSource) {
                toast.error('Something went wrong, try again!');
                return;
            }

            let inputs: DataSourceDetailsProperties[][] = model.dataSource?.inputs.map(
                (property: DataSourceDetailsProperties) => {
                    const { values } = model;
                    let _inputs: BaseEntityValue[] | undefined =
                        values.filter(el => el.propertyId === property.id) || [];

                    if (_inputs.length < 1) {
                        _inputs = [
                            {
                                id: 0,
                                propertyId: property.id,
                                propertyName: property.name,
                                value: property.defaultValue,
                            },
                        ];
                    }

                    let _arr = _inputs?.map(input => {
                        let options = property.options;

                        let value: any = null;
                        if (
                            property.inputType === inputTypes.dropdown ||
                            property.inputType === inputTypes.dropdownOpen
                        ) {
                            value = input?.value
                                ? property?.options?.find(x => x.value === input?.value)
                                : property?.options?.find(x => x.value === property?.defaultValue) || null;
                            if (!value && input?.value) {
                                let newOption = {
                                    label: input?.value || '',
                                    value: input?.value || '',
                                };
                                options.push(newOption);
                                value = newOption;
                            }
                        } else {
                            value = input?.value || property?.defaultValue || '';
                        }

                        return {
                            ...property,
                            options,
                            value,
                            inputName: property?.isRepeatable ? `${property.name} ${nanoid()}` : property.name,
                            valueId: input?.id || 0,
                        };
                    });

                    return _arr;
                }
            );

            const _artifactState: ArtifactFromDataSourceStateType = {
                dataSource: { label: model.dataSource.name, value: model.dataSource.id?.toString() },
                inputs: inputs.flat(),
                title: model.title,
                scheduleFrequency: model.scheduleFrequency?.toString() || '',
                schedule: runSchedules.find(y => y.value === model?.scheduleId?.toString()) || null,
            };

            setState(_artifactState);
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.stopPropagation();
        e.preventDefault();

        const { dataSource, inputs, title, scheduleFrequency, schedule } = state;
        if (!onValidate() || inputsLoading) return;

        try {
            setFormLoading(true);
            const payload = {
                id: isEditing ? artifact?.id : 0,
                dataSourceId: dataSource?.value,
                scheduleId: schedule?.value,
                scheduleFrequency: scheduleFrequency || '',
                title,
                runImmediately: true,
                accountId,
                values: inputs
                    .filter(x => shouldDynamicInputRender(x, inputs))
                    .map(inp => ({
                        id: isEditing ? inp?.valueId : 0,
                        propertyId: inp.id,
                        value: inp?.value?.value || inp?.value,
                    })),
            };
            const response: ArtifactPostResponseModel = await axios.post('/artifact', payload);
            resetValues();
            onSuccess && onSuccess(response.model, !!isEditing);
            onHide();
        } catch (err) {
        } finally {
            setFormLoading(false);
        }
    };

    const onChangeState = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setState(prev => ({ ...prev, [key]: value }));
    };

    const onChangeInputs = (key, value) => {
        deleteErrorKey(key, setErrors);
        setState(prev => ({
            ...prev,
            inputs: prev.inputs.map(inp => (inp.inputName === key ? { ...inp, value } : inp)),
        }));
    };

    const onChangeDataSource = item => {
        deleteErrorKey('dataSource', setErrors);
        setState(prev => ({ ...prev, dataSource: item }));
        fetchDataSourceInputs(item?.value);
    };

    const renderProperties = () => {
        if (inputsLoading) {
            return (
                <div className="flex flex-col p-10 justify-center items-center">
                    <Loader />
                </div>
            );
        }

        return (
            <DynamicInputs
                onChangeInputsList={_properties => {
                    setState(prev => ({
                        ...prev,
                        inputs: _properties,
                    }));
                }}
                onChangeInput={onChangeInputs}
                properties={state.inputs}
                className="mt-4"
                errors={errors}
            />
        );
    };

    const formId = useMemo(() => {
        return isEditing ? 'editArtifactForm' : 'addArtifactForm';
    }, [isEditing]);

    return (
        <>
            <SideModal
                isVisible={isVisible}
                onHide={onHide}
                title={isEditing ? t('@components.modals.edit-artifact') : t('@components.modals.create-artifact')}
                buttonText={isEditing ? t('@common.edit') : t('@common.create')}
                formId={formId}
                loading={formLoading}
            >
                <form onSubmit={handleSubmit} id={formId} className="flex flex-col h-full border-solid px-4 py-6">
                    {detailsLoading ? (
                        <div className="flex flex-col p-10 justify-center items-center">
                            <Loader />
                        </div>
                    ) : (
                        <div className={`flex flex-col w-full pb-4`}>
                            <InputField
                                required
                                disabled={isEditing}
                                label={t('@common.title')}
                                placeholder={t('@common.write.here')}
                                name="artifact-title"
                                value={state.title}
                                errorMessage={errors?.title}
                                onChange={e => onChangeState('title', e.target.value)}
                                maxLength={50}
                            />
                            <SelectWithPopupField
                                required
                                label={t('@common.data-source')}
                                placeholder={t('@common.choose-data-source')}
                                fetchDataOnMount={false}
                                onChangeItem={onChangeDataSource}
                                className="mt-4"
                                selectedItem={state.dataSource}
                                tableType={SelectWithPopupTypes.dataSource}
                                popupKeyTitle="@common.select-data-source"
                                errorMessage={errors?.dataSource}
                            />
                            {renderProperties()}
                            <RunScheduleComponentForModal
                                errors={errors}
                                fieldsState={{
                                    schedule: state.schedule,
                                    scheduleFrequency: state.scheduleFrequency,
                                }}
                                onChangeState={onChangeState}
                            />
                        </div>
                    )}
                </form>
            </SideModal>
        </>
    );
};
