import { ApexOptions } from 'apexcharts';
import deepmerge from 'deepmerge';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import ReactApexChart, { Props } from 'react-apexcharts';
import { Loader } from '../../../../../../components/ui';
import { LookalikeEngineSimilaritiesListModel } from '../../../../../../models/entity-models/api/lookalike-engine/lookalike-engine-similarities';

const _formatter = Intl.NumberFormat('en', { notation: 'compact' });

const _options: ApexOptions = {
    chart: {
        height: '100%',
        zoom: {
            enabled: false,
            type: 'x',
        },
        toolbar: {
            show: false,
        },
    },
    grid: {
        yaxis: {
            lines: {
                show: false,
            },
        },
        xaxis: {
            lines: {
                show: true,
            },
        },
    },
    yaxis: {
        labels: {
            formatter: val => _formatter.format(val),
            style: {
                colors: '#7483A1',
                fontSize: '12px',
            },
        },
        title: {
            text: 'Reach',
            style: {
                color: '#11254A',
                fontSize: '16px',
                fontWeight: '500',
            },
        },
    },
    markers: {
        size: 9,
        fillOpacity: 0.5,
        radius: 2,
        shape: 'circle',
        strokeOpacity: 1,
        strokeColors: '#3A81F6',
        strokeWidth: 1,
    },
    xaxis: {
        crosshairs: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
        axisTicks: {
            show: true,
        },
        tickAmount: 10,
        axisBorder: {
            show: true,
        },
        title: {
            text: 'Similarity',
            style: {
                color: '#11254A',
                fontSize: '16px',
                fontWeight: '500',
            },
        },
        max: 100,
        min: 0,
        labels: {
            formatter: v => v + '%',
            style: {
                colors: '#7483A1',
                fontSize: '12px',
            },
        },
    },
    tooltip: {
        enabled: true,
        followCursor: false,
        intersect: false,
        marker: {
            show: false,
        },
    },
};

interface ScatterChartProps extends Props {
    title?: string;
    containerClassName?: string;
    containerStyle?: CSSProperties;
    data: LookalikeEngineSimilaritiesListModel[];
    noDataMessage?: string | ReactNode;
    loading: boolean;
}

export const ScatterChart: React.FC<ScatterChartProps> = ({
    containerClassName,
    containerStyle,
    data,
    loading,
    noDataMessage,
}) => {
    const options = useMemo(() => {
        let _opt = deepmerge(_options, {});
        return _opt;
    }, [data]);

    const dataArray: [number, number | null][] = data.map(x => [x.similarity, x.reach]);

    const { initialLoading, refreshLoading } = useMemo(() => {
        return {
            initialLoading: loading && !dataArray.length,
            refreshLoading: loading && !!dataArray.length,
        };
    }, [loading, dataArray]);

    return (
        <div
            className={`flex flex-col w-full  mt-6 border border-mainBorder bg-white card-shadow rounded p-4 ${containerClassName}`}
            style={{
                ...containerStyle,
                minHeight: 500,
                height: 'calc(100vh - 260px)',
                maxHeight: 'calc(100vh - 260px)',
            }}
        >
            {initialLoading ? (
                <div className="h-full w-full justify-center items-center flex">
                    <Loader size={34} />
                </div>
            ) : !dataArray.length ? (
                typeof noDataMessage === 'string' ? (
                    <div className="flex w-full h-full justify-center items-center p-10">
                        <p className="text-secondaryText text-base">{noDataMessage}</p>
                    </div>
                ) : (
                    noDataMessage
                )
            ) : (
                <ReactApexChart
                    options={options}
                    type="scatter"
                    height="100%"
                    width="100%"
                    series={[
                        {
                            name: 'Reach',
                            data: dataArray,
                        },
                    ]}
                />
            )}
        </div>
    );
};
