import { Icon, IconTypes } from '../../common';

export interface HeaderProps {
    title: string;
    hide: (e: any) => any;
    showIcon?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, hide, showIcon = true }) => {
    return (
        <div className="bg-greyWhite200 justify-between items-center border-b border-grey200 p-4 flex">
            <h4 className="text-base font-semibold text-blueMainText">{title}</h4>
            {showIcon && <Icon name={IconTypes.close} onClick={hide} className="cursor-pointer" />}
        </div>
    );
};

export default Header;
