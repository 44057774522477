import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export const getRecommendationEngineFormSchema = (t: TFunction<'translation', undefined>) => {
    return yup.object().shape({
        title: yup.string().required(t('@validations.field.isRequired', { field: t('@common.title') })),
        objectiveId: yup
            .number()
            .moreThan(0, t('@validations.field.isRequired', { field: t('@recommendation.objective') })),
        scheduleId: yup.number().moreThan(0, t('@validations.field.isRequired', { field: t('@common.schedule') })),
        scheduleFrequency: yup.number().when('scheduleId', {
            is: scheduleId => scheduleId === 2 || scheduleId === 3,
            then: yup
                .number()
                .moreThan(0, t('@validations.this.field.isRequired', { defaultValue: 'This field is required!' })),
        }),
        catalogs: yup.array().min(
            1,
            t('@validations.select.minimum.one.item', {
                item: t('@common.catalog'),
                defaultValue: 'Please select at least one {{item}}!',
            })
        ),
        filters: yup.array(),
    });
};
