import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as DatasourceIcon } from '../../../assets/images/empty/empty-dataset.svg';
import { RunStatusComponent } from '../../../components/common';
import { DetailsInfoOverview } from '../../../components/details-entity';
import { Error404 } from '../../../components/errors';
import { SelectField } from '../../../components/inputs';
import { CreateDataSourceModal, CreateDataSourceReaderModal } from '../../../components/modals';
import { NoDataImagesType, Table, TableFilters } from '../../../components/tables';
import { toast } from '../../../components/ui';
import { NOT_FOUND_PATH, statusOptionTypes, STORAGE_PATH } from '../../../constants';
import { useAccountId, useToggleVisibility } from '../../../hooks';
import {
    DataSourceDetailsModel,
    DataSourceDetailsResponseModel,
    DataSourceReaderListModel,
    SelectOptionType,
} from '../../../models';
import {
    actions as dataSourceReadersActions,
    selectors as dataSourceReadersSelectors,
} from '../../../redux/thunk/app/dataSource/dataSourceReadersThunk';
import axios from '../../../services/axios';
import { formatArrayForSelectOptions, formatDateAndTime, queryParams, showFiltersHeader } from '../../../utils';

export const DataSourceDetailsPage: React.FC = () => {
    const params: { id?: string } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const { t } = useTranslation();
    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility();
    const [isDataSourceModalVisible, shouldRenderDataSourceModal, onToggleDataSourceModal] = useToggleVisibility();
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);

    /**
     * Selectors
     */
    const dataSourceReadersResponse = useSelector(dataSourceReadersSelectors.getResponse);
    const dataSourceReadersLoading = useSelector(dataSourceReadersSelectors.getIsLoading);

    /**
     * Local State
     */
    const [dataSourceReaderIdForEdit, setDataSourceReaderIdForEdit] = useState<number | null>(null);
    const [dataSourceDetails, setDataSourceDetails] = useState<DataSourceDetailsModel | null>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const [detailsError, setDetailsError] = useState({
        status: 0,
        message: '',
    });

    useEffect(() => {
        return () => {
            dispatch(dataSourceReadersActions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        let id: number | null = parseInt('' + params.id) ?? null;
        if (id) {
            setDetailsError({ message: '', status: 0 });
            requestDataSourceDetails(id);
            requestDataSourceReaderList({ dataSourceId: id, searchQuery: '' });
        } else history.push(NOT_FOUND_PATH + queryParams.formatForNavigation());
    }, [params.id, accountId]);

    useEffect(() => {
        if (dataSourceReadersResponse.query?.status) {
            setStatusTypeSelected(dataSourceReadersResponse.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [dataSourceReadersResponse.query]);

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestDataSourceReaderList({ page: 0, status: status });
    };

    const requestDataSourceReaderList = (params: any, resetFilters?: boolean) => {
        dispatch(dataSourceReadersActions.request({ accountId, page: 0, ...params }, resetFilters));
    };

    const requestDataSourceDetails = async (id: number) => {
        try {
            setDetailsLoading(true);

            const params = new URLSearchParams({
                accountId: accountId.toString(),
                id: id.toString(),
            });
            const response: DataSourceDetailsResponseModel = await axios.get(`/dataSource?${params}`);

            setDataSourceDetails(response.model);
        } catch (err: any) {
            if (err?.response?.status) {
                setDetailsError({
                    message: err?.message,
                    status: err?.response?.status,
                });
            }
        } finally {
            setDetailsLoading(false);
        }
    };

    const onPageChanged = page => requestDataSourceReaderList({ page });

    const onClickEdit = (row: DataSourceReaderListModel) => {
        onToggleVisibility(true);
        setDataSourceReaderIdForEdit(row.id);
    };

    const onClickCreate = () => onToggleVisibility(true);

    const onClickTerminate = async (row: DataSourceReaderListModel) => {
        try {
            await axios.post('/dataSourceReader/terminate', { id: row.id, accountId });
            requestDataSourceReaderList({});
            toast.success(
                t('@dataSourceReader.started.terminating', { defaultValue: 'Data Source Reader is terminating!' })
            );
        } catch (err: any) {}
    };

    const onClickRun = async (row: DataSourceReaderListModel) => {
        try {
            await axios.post(`/dataSourceReader/run`, { id: row.id, accountId });
            requestDataSourceReaderList({});
            toast.success(
                t('@dataSourceReader.started.running', { defaultValue: 'Data Source Reader started running!' })
            );
        } catch (err) {}
    };

    const onSuccessEditDataSource = (item: DataSourceDetailsModel) => {
        setDataSourceDetails(item);
        toast.success(t('@success.edit.dataSource', { defaultValue: 'Data source has been edited!' }));
    };

    const columns = useMemo(() => {
        let cols: TableColumn<DataSourceReaderListModel>[] = [
            {
                name: t('@common.title'),
                selector: row => row.title,
            },
            {
                name: t('@object.storage.path', { defaultValue: 'Object Path' }),
                cell: row => {
                    let path = row?.objectStoragePath;
                    let linkTo = `${STORAGE_PATH}${
                        path.startsWith('/') ? path : '/' + path
                    }${queryParams.formatForNavigation()}`;
                    return (
                        <Link to={linkTo} className="w-full h-auto hover:underline">
                            {row.objectStoragePath}
                        </Link>
                    );
                },
            },
            {
                name: t('@common.run-status'),
                width: '140px',
                cell: row => <RunStatusComponent row={row} />,
            },
            {
                name: t('@common.last-modified'),
                selector: row => row?.lastModified ?? '',
            },
            {
                name: t('@common.modified-by'),
                selector: row => row?.modifiedBy ?? '',
            },
        ];

        return cols;
    }, [t]);

    const paginationProps = useMemo(
        () => ({
            currentPage: dataSourceReadersResponse.currentPage || 0,
            pageItems: dataSourceReadersResponse.models.length,
            totalPages: dataSourceReadersResponse.totalPages,
            totalItems: dataSourceReadersResponse.totalItems,
            onChangePage: onPageChanged,
        }),
        [dataSourceReadersResponse]
    );

    if (detailsError.status === 404) {
        return <Error404 model="dataSource" />;
    }

    return (
        <div className="">
            {shouldRenderDataSourceModal && (
                <CreateDataSourceModal
                    isVisible={isDataSourceModalVisible}
                    isEditing
                    dataSourceId={dataSourceDetails?.id ?? 0}
                    onHide={() => {
                        onToggleDataSourceModal(false);
                    }}
                    onSuccess={onSuccessEditDataSource}
                />
            )}
            {shouldRender && (
                <CreateDataSourceReaderModal
                    isVisible={isVisible}
                    isEditing={!!dataSourceReaderIdForEdit}
                    idForEdit={dataSourceReaderIdForEdit ?? undefined}
                    onSuccess={isEditing => {
                        toast.success(
                            t(`@success.${isEditing ? 'edit' : 'create'}.dataSourceReader`, {
                                defaultValue: `Data Source Reader has been ${isEditing ? 'edited' : 'created'}!`,
                            })
                        );
                        dispatch(dataSourceReadersActions.request({ page: 0 }));
                    }}
                    onHide={() => {
                        onToggleVisibility(false);
                        setDataSourceReaderIdForEdit(null);
                    }}
                    disableDataSource
                    preSelectedDataSource={
                        dataSourceDetails
                            ? { label: dataSourceDetails.name, value: dataSourceDetails.id?.toString() }
                            : undefined
                    }
                />
            )}

            <DetailsInfoOverview
                loading={detailsLoading}
                title={t('@dataSource.overview', { defaultValue: 'Data Source overview' })}
                titleIconComponent={<DatasourceIcon className="h-7 w-7 text-mainGrayText mr-4" />}
                titleRightComponent={
                    <div
                        onClick={() => onToggleDataSourceModal(true)}
                        className="flex hover:underline text-blueMainText cursor-pointer px-2 items-center"
                    >
                        <span className="mr-2 text-base mt-0.5">{t('@common.edit')}</span>
                        <GjirafaIcon name="Edit" size={20} />
                    </div>
                }
                data={{
                    '@common.name': {
                        value: dataSourceDetails?.name ?? '',
                    },
                    '@common.type': {
                        value: dataSourceDetails?.dataSourceTypeName ?? '',
                    },
                    '@createdAt': {
                        value: formatDateAndTime(dataSourceDetails?.createdAt ?? ''),
                    },
                    '@created.by': {
                        value: dataSourceDetails?.createdBy ?? '',
                    },
                    '@common.last-modified': {
                        value: formatDateAndTime(dataSourceDetails?.lastModified ?? ''),
                    },
                    '@modifiedBy': { value: dataSourceDetails?.modifiedBy ?? '' },
                }}
            />

            <Table
                selectableRows
                isFromSelectPopup={false}
                subHeader={showFiltersHeader(dataSourceReadersResponse)}
                headerComponent={
                    <TableFilters
                        onChangeQuery={(key, value) => {
                            requestDataSourceReaderList({
                                [key]: value,
                            });
                        }}
                        response={dataSourceReadersResponse}
                        onSearch={searchQuery => requestDataSourceReaderList({ searchQuery, page: 0 })}
                    >
                        <SelectField
                            disabled={false}
                            isClearable
                            label="Status"
                            className="m-2"
                            placeholder={t('@common.choose-status')}
                            style={{ width: 240 }}
                            onChange={onChangeStatusType}
                            options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                            value={selectedStatusType}
                            isLoading={dataSourceReadersLoading}
                        />
                    </TableFilters>
                }
                loading={dataSourceReadersLoading}
                showHeaderButtons
                headerButtonProps={{
                    className: 'mt-6',
                    style: { marginBottom: 0 },
                    onRefresh: () => requestDataSourceReaderList({}),
                    createTitle: t('@read.from.this.dataSource', { defaultValue: 'Read from this Data Source' }),
                    onClickCreate,
                }}
                emptyDataProps={{
                    imageType: NoDataImagesType.DataSourceReader,
                    actionMessage: t('@create.dataSourceReader', { defaultValue: '+ Read from this Data source' }),
                    emptyText: t('@empty.dataSourceReader', { defaultValue: 'No Data source readers found!' }),
                    onClickAction: onClickCreate,
                }}
                requestList={(query, resetQuery) => dispatch(dataSourceReadersActions.request(query, resetQuery))}
                columns={columns}
                data={dataSourceReadersResponse.models}
                modelEndpoint="dataSourceReader"
                pagination
                paginationProps={paginationProps}
                response={dataSourceReadersResponse}
                actions={[
                    {
                        action: 'edit',
                        onClick: onClickEdit,
                    },
                    {
                        action: row => (row?.runStatus === 'Running' ? 'Terminate' : 'run'),
                        onClick: async row => (row?.runStatus === 'Running' ? onClickTerminate(row) : onClickRun(row)),
                        displayName: (row: DataSourceReaderListModel) =>
                            row.runStatus === 'Running' ? 'Terminate' : 'Run',
                        disable: (row: DataSourceReaderListModel) => row?.runStatus === 'Terminating',
                    },
                    {
                        action: 'delete',
                    },
                ]}
            />
        </div>
    );
};
