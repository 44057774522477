import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationItemDetailsResponseModel } from '../../models';
import axios from '../../services/axios';
import { getFormattedQuery } from '../../utils';
import { InputField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateConfigurationItemModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    configurationItemForEdit?: any;
    onSuccess: (isEditing: boolean) => any;
}
export const CreateConfigurationItemModal: React.FC<CreateConfigurationItemModalProps> = ({
    isVisible,
    onHide,
    configurationItemForEdit,
    isEditing,
    onSuccess,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();

    /**
     * Local State
     */
    const [loading, setLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [fieldValues, setFieldValues] = useState({
        key: '',
        value: '',
    });

    useEffect(() => {
        if (isEditing && !!configurationItemForEdit) {
            fetchDetails(configurationItemForEdit?.id);
        }
    }, [isEditing, configurationItemForEdit]);

    const fetchDetails = async id => {
        try {
            setDetailsLoading(true);
            let query = {
                id,
            };

            const res: ConfigurationItemDetailsResponseModel = await axios.get(
                `/configurationItem?${getFormattedQuery(query)}`
            );
            setFieldValues({
                key: res.model.key,
                value: res.model.value,
            });
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setLoading(true);

            let payload: any = {
                id: isEditing ? configurationItemForEdit?.id : 0,
                value: fieldValues.value,
            };

            if (!isEditing) {
                payload.key = fieldValues.key;
            }

            await axios.post('/configurationItem', payload);
            onSuccess(!!isEditing);
        } catch (err: any) {
        } finally {
            setLoading(false);
        }
    };
    const onChangeInputs = (key, value) => setFieldValues(prev => ({ ...prev, [key]: value }));

    const { formId, title, buttonText } = useMemo(() => {
        return {
            formId: isEditing ? 'editConfigurationItem' : 'addConfigurationItem',
            title: isEditing ? 'Edit Configuration' : 'Create Configuration',
            buttonText: isEditing ? t('@common.edit') : t('@common.create'),
        };
    }, [isEditing]);

    return (
        <>
            <SideModal
                loading={loading}
                isVisible={isVisible}
                onHide={onHide}
                title={title}
                buttonText={buttonText}
                formId={formId}
            >
                <form onSubmit={handleSubmit} id={formId} className="flex flex-col h-full border-solid px-4 py-6">
                    {detailsLoading ? (
                        <div className="py-12 w-full flex items-center justify-center">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <InputField
                                required
                                disabled={isEditing}
                                label="Key"
                                placeholder={t('@common.write.here')}
                                className="mb-6"
                                value={fieldValues.key}
                                onChange={e => onChangeInputs('key', e.target.value)}
                            />
                            <InputField
                                required
                                label="Value"
                                placeholder={t('@common.write.here')}
                                className="mb-6"
                                value={fieldValues.value}
                                onChange={e => onChangeInputs('value', e.target.value)}
                            />
                        </>
                    )}
                </form>
            </SideModal>
        </>
    );
};
