import React, { CSSProperties } from 'react';
import { useMemo } from 'react';
import { breakpoints } from '../../constants';
import { useCurrentWidth } from '../../hooks';

interface SectionContainerForCreatePagesProps {
    className?: string;
    style?: CSSProperties;
    width?: 'small' | 'large';
}

export const SectionContainerForCreatePages: React.FC<SectionContainerForCreatePagesProps> = ({
    children,
    className,
    style,
    width = 'small',
}) => {
    const currentWidth = useCurrentWidth();

    let sectionStyle: CSSProperties = useMemo(() => {
        let _w = currentWidth >= breakpoints.md ? (width === 'small' ? 546 : 835) : '100%';
        return {
            minWidth: _w,
            maxWidth: _w,
            width: _w,
        };
    }, [currentWidth, width]);

    return (
        <div style={{ ...sectionStyle, ...style }} className={`${className}`}>
            {children}
        </div>
    );
};
