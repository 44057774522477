import React from 'react';
import { useMemo } from 'react';
import { WorkflowModelListModel } from '../../../../../models';

export interface ModelConfusionMatrixProps {
    model: WorkflowModelListModel;
}

export const ModelConfusionMatrix: React.FC<ModelConfusionMatrixProps> = ({ model }) => {
    const { wrapper } = model;

    /**
     * Old Confusion matrix classes
     */
    // const { commonSquareClass, commonLabelText } = useMemo(() => {
    //     return {
    //         commonSquareClass: "h-20 w-20 flex items-center justify-center text-blueMainText text-base font-medium",
    //         commonLabelText: "text-xs font-medium",
    //         verticalLabel: ""
    //     }
    // }, []);

    const { squareClassName, textClassName, textTitleClassName } = useMemo(() => {
        return {
            squareClassName: 'flex flex-col justify-center items-center p-3 border border-mainBackground h-auto w-40',
            textClassName: 'text-blueMainText font-medium truncate',
            textTitleClassName: 'text-blueMainText text-base font-semibold',
        };
    }, []);

    const { fn, fp, n, tn, totalActualNo, totalActualYes, totalPredictedNo, totalPredictedYes, tp } = useMemo(() => {
        let n,
            tn,
            tp,
            fn,
            fp,
            totalActualNo,
            totalActualYes,
            totalPredictedNo,
            totalPredictedYes = 0;
        if (wrapper?.confusionMatrix?.length) {
            const { confusionMatrix } = wrapper;

            tn = (confusionMatrix[0] && confusionMatrix[0][0]) || 0;
            fp = (confusionMatrix[0] && confusionMatrix[0][1]) || 0;
            fn = (confusionMatrix[1] && confusionMatrix[1][0]) || 0;
            tp = (confusionMatrix[1] && confusionMatrix[1][1]) || 0;

            totalActualNo = tn + fp;
            totalActualYes = fn + tp;

            totalPredictedNo = tn + fn;
            totalPredictedYes = fp + tp;

            n = totalPredictedYes + totalPredictedNo;
        }
        return {
            n,
            tn,
            tp,
            fn,
            fp,
            totalActualNo,
            totalActualYes,
            totalPredictedNo,
            totalPredictedYes,
        };
    }, []);

    if (!wrapper?.confusionMatrix.length) {
        return (
            <div className="flex w-full h-auto p-10 justify-center items-center">
                <span className="text-blueMainText font-medium text-base">No Confusion Matrix for this model!</span>
            </div>
        );
    }

    return (
        <div className="flex w-full flex-col p-10 ">
            <div className="flex flex-row ">
                <span className={`${squareClassName} ${textClassName} `} style={{ border: 'none' }}>
                    n = {n}
                </span>
                <span className={`${squareClassName} ${textTitleClassName} bg-mainBackground border-mainBackground`}>
                    Predicted: No
                </span>
                <span className={`${squareClassName} bg-mainBlue border-mainBlue border-b-0 text-white`}>
                    Predicted: Yes
                </span>
            </div>
            <div className="flex flex-row">
                <span className={`${squareClassName} ${textTitleClassName} bg-mainBackground border-mainBackground `}>
                    Actual: No
                </span>
                <span
                    className={`${squareClassName} text-sm border-l border-t border-b-0 border-r-0 border-blueMainText bg-mainGrayText  text-white`}
                >
                    TN = {tn}
                </span>
                <span
                    className={`${squareClassName} ${textClassName} text-sm border-l-0 border-t border-b-0 border-r  border-blueMainText`}
                >
                    FP = {fp}
                </span>
                <span className={`${squareClassName} ${textClassName}`}>{totalActualNo}</span>
            </div>
            <div className="flex flex-row">
                <span className={`${squareClassName} bg-mainBlue border-mainBlue text-white border-r-0`}>
                    Actual: Yes
                </span>
                <span
                    className={`${squareClassName} ${textClassName} text-sm border-l border-t-0 border-b border-r-0  border-blueMainText `}
                >
                    FN = {fn}
                </span>
                <span
                    className={`${squareClassName} bg-mainGrayText text-sm text-white border-l-0 border-t-0 border-b border-r  border-blueMainText`}
                >
                    TP = {tp}
                </span>
                <span className={`${squareClassName} ${textClassName}`}>{totalActualYes}</span>
            </div>
            <div className="flex flex-row ">
                <span className={`${squareClassName} ${textClassName} `} style={{ border: 'none' }}></span>
                <span className={`${squareClassName} ${textClassName}`}>{totalPredictedNo}</span>
                <span className={`${squareClassName} ${textClassName}`}>{totalPredictedYes}</span>
            </div>
        </div>
    );
};
