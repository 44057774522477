import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationListModel } from '../../../models';
import { formatDateAndTime } from '../../../utils';
import { NotificationDate } from './NotificationDate';
import { StatusImage } from './StatusImage';

export interface INotificationItemProps {
    className?: string;
    style?: CSSProperties;
    notification: NotificationListModel;
    onClick: (e: any) => any;
    isFromSeeAll?: boolean;
    showAccountName?: boolean;
}

export enum NotificationEntityTypes {
    RecommendationEngine = 'RecommendationEngine',
    LookalikeEngine = 'LookalikeEngine',
    PropensityEngine = 'PropensityEngine',
    Artifact = 'Artifact',
    Datasource = 'Datasource',
    Catalog = 'Catalog',
    Filter = 'Filter',
    Model = 'Model',
    Dataset = 'Dataset',
    Flow = 'Flow',
    Deployment = 'Deployment',
    Account = 'Account',
    Notebook = 'Notebook',
    Invoice = 'Invoice',
    PaymentMethod = 'PaymentMethod',
    DataSourceReader = 'DataSourceReader',
    CustomEndpoint = 'CustomEndpoint',
    Pipeline = 'Pipeline',
    PipelineJob = 'PipelineJob',
    PipelineTask = 'PipelineTask',
    ApiKey = 'ApiKey',
}

export const CustomNotificationEntities: NotificationEntityTypes[] = [
    NotificationEntityTypes.Invoice,
    NotificationEntityTypes.PaymentMethod,
];

export const NotificationItem: React.FC<INotificationItemProps> = React.memo(
    ({ notification, className, style, onClick, isFromSeeAll, showAccountName = true }) => {
        const { t } = useTranslation();

        const {
            isRead,
            insertDateTime,
            notification: { accountName },
        } = notification;

        const [entity, ...entityName] = notification?.entity?.entityType?.split(':') || ['', ''];
        const status = notification?.entity?.status || '';

        const { messageName, messageContent } = useMemo(() => {
            let _status = status?.toLocaleLowerCase();
            const isCustomNotification = !!CustomNotificationEntities.includes(NotificationEntityTypes[entity]);

            let message: string = t(
                `@notification.${isCustomNotification ? entity.toLocaleLowerCase() : 'entity'}.${_status}`,
                {
                    entity: entityName.toString(),
                    defaultValue: `{{entity}} 'message' ${_status}`,
                }
            );

            let splitKey = isCustomNotification ? ' ' : entityName.toString();
            const splitMessage = message.split(splitKey);

            return {
                messageName: isCustomNotification ? splitMessage[0] + ' ' : entityName,
                messageContent: splitMessage.slice(1).join(splitKey).toString() ?? '',
            };
        }, [entity, status]);

        return (
            <div
                onClick={onClick}
                className={`w-full flex flex-row overflow-x-hidden justify-between flex-nowrap ${
                    isFromSeeAll ? 'p-4 rounded mt-2 items-center' : 'items-start p-2 px-1 rounded-lg mt-1'
                } bg-white hover:bg-greyWhite200 cursor-pointer transition-all duration-300 ${className}`}
                style={style}
            >
                <StatusImage status={status} />
                <div
                    className={`flex flex-1 flex-col ${
                        isFromSeeAll ? 'pl-4' : 'pl-2'
                    } whitespace-pre-line overflow-x-hidden`}
                >
                    <span className={`font-normal  text-sm text-secondaryText whitespace-pre-wrap`}>
                        <span className="text-blueDark font-medium">{messageName}</span>
                        {messageContent}
                    </span>

                    {showAccountName && (
                        <div className="flex w-full justify-start items-center mt-1">
                            <span className="text-primaryBlue600 text-xs font-medium">{accountName}</span>
                        </div>
                    )}

                    {!isFromSeeAll && (
                        <div className="flex justify-start mt-3 items-center">
                            <NotificationDate date={insertDateTime} />
                            {!isRead && <span className="h-2 w-2 rounded-full bg-mainBlue" />}
                        </div>
                    )}
                </div>
                {isFromSeeAll && (
                    <div className="flex justify-end items-center">
                        {!isRead && <span className="h-2 w-2 rounded-full bg-mainBlue mr-2" />}
                        <p className="text-secondaryText text-sm">{formatDateAndTime(insertDateTime, 'HH:mm')}</p>
                    </div>
                )}
            </div>
        );
    }
);
