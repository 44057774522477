import _ from 'lodash';
import { isQueryEmpty } from '.';
import { SPECIAL_STRING } from '../constants';
import { ListResponseModel } from '../models';

export const isEmptyObject = obj => {
    return Object.entries(obj).length < 1;
};

export const getLastItem = (arr: any[]) => arr[arr.length - 1];

const AsyncFunction = (async () => {}).constructor;

export const isAsyncFunction = (p: any) => p instanceof AsyncFunction;

export const removeDuplicatesCharsInside = (str: string, char = '/') => {
    const splitArr = str.split(char);

    return splitArr
        .filter((x, i) => {
            if (i === 0 || i === splitArr.length - 1) {
                return true;
            }
            return x.length > 0;
        })
        .join(char);
};

export const onDownloadFileFromUrl = (url: string) => {
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);

    link.click();
    link.parentNode?.removeChild(link);
};

export const isValidNumber = value => {
    var reg = new RegExp(/^\d*\.?\d*$/);
    if (value !== '' && (!parseFloat(value) || !reg.test(value))) return false;

    return true;
};

export const removeDuplicates = (arr: any[], key: string) => {
    let finalArray: any[] = [];

    const uniqueObject = {};
    for (let i = 0; i < arr.length; i++) {
        const currentItem = arr[i];
        let property = currentItem[key];

        if (property) {
            uniqueObject[property] = currentItem;
        }
    }

    Object.entries(uniqueObject).forEach(items => {
        finalArray.push(items[1]);
    });

    return finalArray;
};

export const removeDuplicateStrings = (arr: string[]) => {
    const uniqueKeys: any = {};

    arr.forEach(x => (uniqueKeys[x] = 'test'));
    return Object.entries(uniqueKeys).map(([key]) => key);
};

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getTotalSecondsFromTime = (time: string) => {
    let totalSeconds = 0;

    try {
        let splitTime = time ? time.split(':') : '00:00:00:00'.split(':');

        if (!splitTime.length) {
            return 0;
        }

        const [days, hours, minutes, seconds] = splitTime.map(x => parseInt(x) || 0) || [0, 0, 0, 0];
        totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    } catch (err: any) {
        totalSeconds = 0;
    } finally {
        return totalSeconds;
    }
};

export const getTimeFromSeconds = (totalSeconds: number) => {
    let d = 86400,
        h = 3600,
        m = 60;

    let days = Math.floor(totalSeconds / d);
    let hours = Math.floor((totalSeconds - days * d) / h);
    let minutes = Math.floor((totalSeconds - days * d - hours * h) / m);
    let seconds = totalSeconds - days * d - hours * h - minutes * m;

    return `${days > 0 ? days.toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' : ''}${
        hours > 0 || days > 0 ? hours.toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' : ''
    }${minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
    })}:${seconds.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;
};

export const replaceDotCharsInString = (text: string) => {
    let _str = '';

    for (let i = 0; i < text.length; i++) {
        let current = text[i];
        if (current === '.') {
            current = SPECIAL_STRING;
        }
        _str += current;
    }
    return _str;
};

export const getToken = () => {
    //const sessionSSO = deviceLocalStorage.getItem(LocalStorageKeys.Token);
    const sessionSSO = localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTHORITY_API_URL}:aihub_react`);
    const user = sessionSSO && JSON.parse(sessionSSO);
    return user?.access_token;
};

export const removeToken = () => {
    //deviceLocalStorage.removeItem(LocalStorageKeys.Token);
    localStorage.removeItem(`oidc.user:${process.env.REACT_APP_AUTHORITY_API_URL}:aihub_react`);
};

export const getFileExtension = (url: string) => {
    let splitArr = url.split('.');
    return splitArr[splitArr.length - 1];
};

export const replaceUrlExtension = (url, type) => {
    const splitUrl = url.split('.');
    let filteredURL = splitUrl?.map((el, i) => (i === splitUrl.length - 1 ? type : el)).join('.');
    return filteredURL;
};

export const getJsonObjectFromUrl = async url => {
    let replacedUrl = replaceUrlExtension(url, 'json');
    return await (await fetch(replacedUrl)).json();
};

export const getFileFromUrl = async url => {
    return await fetch(url);
};

export const convertArrayToCamelCase = arr => {
    let finalArray: any = [];
    arr.forEach(obj => {
        let finalObj = {};
        Object.entries(obj).forEach(([key, value]) => {
            finalObj[_.camelCase(key)] = value;
        });
        finalArray.push(finalObj);
    });

    return finalArray;
};

export const deleteErrorKey = (key, setErrors) => {
    setErrors(prev => {
        delete prev[key];
        return prev;
    });
};

export const getLastObjectFromArray = array => {
    return array[array.length - 1];
};

export const formatArrayForSelectOptions = (
    array: any,
    labelKey = 'name',
    valueKey = 'id',
    spreadOtherFields?: boolean
): [] => {
    return array?.length > 0
        ? array?.map(el => {
              if (spreadOtherFields) {
                  return {
                      label: el[labelKey]?.toString() || el?.title?.toString(),
                      value: el[valueKey]?.toString(),
                      ...el,
                  };
              }
              return {
                  label: el[labelKey]?.toString() || el?.title?.toString(),
                  value: el[valueKey]?.toString(),
              };
          })
        : [];
};

export const replaceLastObjectInArray = (inputArray: any, inputObject) => {
    return [...inputArray.map((el, i) => (i === inputArray.length - 1 ? inputObject : el))];
};

export const splitArrayIntoChunks = (arr, chunk) => {
    let finalArray;

    for (let i = 0; i < arr.length; i += chunk) {
        let tempArray;
        tempArray = arr.slice(i, i + chunk);
        finalArray = finalArray ? [...finalArray, tempArray] : [tempArray];
    }

    return finalArray;
};

export const upperCaseFirstCharacter = (text?: string) => {
    if (text) return text[0].toUpperCase() + text.slice(1);
    return '';
};

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getGroupedOptions = (options, keyProperty = 'groupName') => {
    let tempOptions = [...options];
    let groupedOptions: any = [];
    let uniqueKeys: any = [];

    options.forEach((el, i) => {
        if (el[keyProperty] && !uniqueKeys.includes(el[keyProperty])) {
            uniqueKeys.push(el[keyProperty]);
        }
        if (!el[keyProperty]) {
            tempOptions[i] = {
                ...tempOptions[i],
                [keyProperty]: 'Default',
            };
            if (!uniqueKeys.includes('Default')) uniqueKeys.push('Default');
        }
    });

    groupedOptions = uniqueKeys.map(el => ({
        label: el,
        options: tempOptions.filter(x => x[keyProperty] === el),
    }));

    return groupedOptions;
};

export const showFiltersHeader = (response: ListResponseModel<any>) =>
    !(isQueryEmpty(response.query) && response.models.length < 1);
