import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useCurrentWidth } from '../../hooks';
import { AccountDetailsResponseModel } from '../../models';
import { selectors as accountDetailsSelectors } from '../../redux/thunk/app/account/accountDetailsThunk';
import { actions as themeActions, selectors as themeSelectors } from '../../redux/thunk/theme';
import { sidebarAnimationStyles, sidebarAnimationStylesMobile } from '../../styles/animationStyles';
import { cx } from '../../utils';
import Account from '../account';
import { Icon, IconTypes } from '../common';
import { MainSidebarMenu } from './components';
import { mainSidebarLinks } from './sidebar-links';

export interface SidebarProps {
    wrapperClassName?: string;
}

export const MainSidebar: React.FC<SidebarProps> = ({ wrapperClassName }) => {
    const dispatch = useDispatch();
    const isMenuOpened = useSelector(themeSelectors.getIsMenuOpened);
    const width = useCurrentWidth();
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);

    const styleAnimation = useMemo(() => {
        return width < 900 ? sidebarAnimationStylesMobile : sidebarAnimationStyles;
    }, [width]);

    const toggleMenu = () => {
        dispatch(themeActions.toggleMenu());
    };
    return (
        <>
            <CSSTransition in={isMenuOpened} timeout={50} unmountOnExit={false} mountOnEnter={false}>
                {state => (
                    <aside
                        style={{
                            width: 276,
                            transition: 'width 200ms linear',
                            background: 'white',
                            scrollbarGutter: isMenuOpened ? 'stable' : 'auto',
                            ...styleAnimation[state],
                        }}
                        className={cx([
                            `py-4 ${isMenuOpened && 'pl-3'} bg-white overflow-y-auto overflow-x-hidden h-full`,
                            wrapperClassName,
                        ])}
                    >
                        {width < 900 && isMenuOpened && (
                            <div className="flex w-full justify-end pr-2">
                                <div className="p-1 cursor-pointer" onClick={toggleMenu}>
                                    <Icon name={IconTypes.close} />
                                </div>
                            </div>
                        )}
                        <div className={`${isMenuOpened && 'pr-4 pl-1'}`}>
                            <Account />
                        </div>
                        <MainSidebarMenu disableLinks={!accountDetails.isActive} list={mainSidebarLinks} />
                    </aside>
                )}
            </CSSTransition>
            {width < 900 && isMenuOpened && (
                <div className="absolute h-screen w-full bg-mainOverlay opacity-30 z-40 top-0" onClick={toggleMenu} />
            )}
        </>
    );
};
