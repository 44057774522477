export * from './CreateButton';
export * from './OutlineButton';
export * from './UploadButton';
export * from './Button';
export * from './Checkbox';
export * from './ToggleButton';
export * from './DisabledButton';
export * from './CopyButton';
export * from './RadioButton';
export * from './ToggleInput';

export enum ButtonStyleTypes {
    primary = 'primary',
    primaryOutline = 'primaryOutline',
    secondary = 'secondary',
    secondaryOutline = 'secondaryOutline',
}

export enum ButtonSizeTypes {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export enum ButtonStateTypes {
    normal = 'normal',
    hovered = 'hovered',
    pressed = 'pressed',
    disabled = 'disabled',
}
