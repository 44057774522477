import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import {
    FooterButtons,
    HeaderForCreatePages,
    RunScheduleComponent,
    shouldDynamicInputRender,
    SingleDynamicInput,
} from '../../../components/common';
import { Error404 } from '../../../components/errors';
import { InputField, SwitchInput, TextareaField } from '../../../components/inputs';
import { ErrorTextComponent } from '../../../components/messages';
import { LoadingModal } from '../../../components/modals';
import { Loader, toast } from '../../../components/ui';
import { inputTypes, runSchedules } from '../../../constants';
import { useAccountId, useCurrentWidth, useDetailsError } from '../../../hooks';
/**
 * Models - constants
 */
import {
    AlgorithmDetailsResponseModel,
    AlgorithmPropertyDetailsModel,
    ArtifactDetailsResponseModel,
    BaseEntityProperty,
    BaseEntityValue,
    ModelInstanceTypeListResponseModel,
    SelectOptionType,
    WorkflowModelDetailsResponseModel,
    WorkflowModelListModel,
} from '../../../models';
import { actions as algorithmActions } from '../../../redux/thunk/app/algorithm/algorithmsThunk';
/**
 * Redux
 */
import { actions as allModelsActions } from '../../../redux/thunk/app/model/allModelsThunk';
import {
    actions as instanceTypesActions,
    selectors as instanceTypesSelectors,
} from '../../../redux/thunk/app/model/modelInstanceTypesThunk';
/**
 * services - utils - hooks
 */
import axios from '../../../services/axios';
import { addNewOptionToDynamicInputs, getFormattedQuery, isValidNumber } from '../../../utils';
import AlgorithmCard from './components/AlgorithmCard';

const initialAlgorithmData = {
    isLoading: false,
    inputs: [],
    outputs: [],
    splitDataValues: {
        left: '',
        right: '',
    },
};

export interface CreateModelPageProps {
    isFromSelectPopup?: boolean;
    onSuccessCreated?: (item: WorkflowModelListModel) => any;
    onCancel?: () => any;
}

type runScheduleDataType = {
    scheduleFrequency: string;
    instanceType: SelectOptionType | null;
};

export const CreateModelPage: React.FC<CreateModelPageProps> = ({ isFromSelectPopup, onCancel, onSuccessCreated }) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params: any = useParams();
    const currentWidth = useCurrentWidth();
    const { detailsError, handleError, resetError } = useDetailsError();

    const isEditing = params?.id;

    /**
     * Redux Selectors
     */
    const instanceTypesResponse: ModelInstanceTypeListResponseModel = useSelector(instanceTypesSelectors.getResponse);
    const instanceTypesOptions: SelectOptionType[] = useMemo(
        () =>
            instanceTypesResponse.models
                ? instanceTypesResponse.models.map(x => ({ value: x.id.toString(), label: x.name, ...x }))
                : [],
        [instanceTypesResponse]
    );
    const instanceTypesLoading = useSelector(instanceTypesSelectors.getIsLoading);

    /**
     * Local State
     */
    const [isCreateModelLoading, setIsCreateModelLoading] = useState(false);
    const [areDetailsLoading, setAreDetailsLoading] = useState(false);
    const [basicInformationData, setBasicInformationData] = useState({
        name: '',
        description: '',
    });
    const [algorithmData, setAlgorithmData] = useState<any>(initialAlgorithmData);
    const [runScheduleData, setRunScheduleData] = useState<runScheduleDataType>({
        scheduleFrequency: '',
        instanceType: null,
    });
    const [errors, setErrors] = useState<any>({});
    const [algorithmSelected, setAlgorithmSelected] = useState<any>(null);
    const [artifactSelected, setArtifact] = useState<any>(null);
    const [scheduleSelectedOption, setScheduleSelectedOption] = useState<any>(null);
    const [isSplitDataShown, setIsSplitDataShown] = useState(false);

    useEffect(() => {
        dispatch(algorithmActions.request());
        if (instanceTypesOptions.length < 1) {
            dispatch(instanceTypesActions.request());
        }
        setArtifact(null);
        setAlgorithmSelected(null);
        setAlgorithmData(initialAlgorithmData);
    }, [accountId]);

    useEffect(() => {
        if (params?.id) {
            const { id } = params;
            fetchDetails(id);
        }
    }, [accountId, params?.id]);

    useEffect(() => {
        deleteErrorKey('splitData');
    }, [isSplitDataShown]);

    useEffect(() => {
        if (algorithmSelected) {
            if (algorithmSelected?.hasArtifact && !artifactSelected) {
                return;
            }
            if (!isEditing) requestAlgorithmData();
        }
    }, [algorithmSelected, artifactSelected]);

    const fetchDetails = async id => {
        try {
            setAreDetailsLoading(true);
            resetError();
            let query = {
                accountId,
                id,
            };
            const modelDetailsResponse: WorkflowModelDetailsResponseModel = await axios.get(
                `/model?${getFormattedQuery(query)}`
            );
            const { algorithmId, inputArtifactId, instanceType } = modelDetailsResponse.model;
            let artifact: ArtifactDetailsResponseModel | null = null;

            if (inputArtifactId) {
                const query = {
                    id: inputArtifactId,
                };
                const artifactResponse: ArtifactDetailsResponseModel = await axios.get(
                    `/artifact?${getFormattedQuery(query)}`
                );
                artifact = artifactResponse;
            }

            const algorithm = {
                ...modelDetailsResponse.model.algorithm,
                name: modelDetailsResponse.model.algorithmName,
                id: modelDetailsResponse.model.algorithmId,
            };

            let algorithmQuery: any = {
                id: algorithmId,
                accountId,
            };
            if (inputArtifactId) {
                algorithmQuery.artifactId = inputArtifactId;
            }
            const responseAlgorithm: AlgorithmDetailsResponseModel = await axios.get(
                `/algorithm?${getFormattedQuery(algorithmQuery)}`
            );
            let inputs: BaseEntityProperty[] = responseAlgorithm.model.inputs
                .map((property: AlgorithmPropertyDetailsModel) => {
                    let inputValues: BaseEntityValue[] =
                        modelDetailsResponse.model.values?.filter(x => x.propertyId === property.id) || [];

                    if (inputValues.length < 1) {
                        inputValues = [
                            {
                                id: 0,
                                propertyId: property.id,
                                propertyName: property.name,
                                value: property.defaultValue,
                            },
                        ];
                    }

                    let _arr = inputValues.map(inputValue => {
                        let options = property.options;
                        let value: any = null;
                        if (
                            property.inputType === inputTypes.dropdown ||
                            property.inputType === inputTypes.dropdownOpen
                        ) {
                            value = inputValue?.value
                                ? property?.options?.find(x => x.value === inputValue?.value)
                                : property?.options?.find(x => x.value === property.defaultValue) || null;
                            if (!value && inputValue?.value) {
                                value = {
                                    label: inputValue?.value,
                                    value: inputValue?.value,
                                };
                                options.push(value);
                            }
                        } else {
                            value = inputValue?.value || property?.defaultValue || '';
                        }

                        return {
                            ...property,
                            propertyId: inputValue?.propertyId || 0,
                            inputName: property?.isRepeatable ? `${property.name} ${nanoid()}` : property.name,
                            value,
                            valueId: inputValue?.id,
                        };
                    });

                    return _arr;
                })
                .flat();

            const outputs = responseAlgorithm.model.outputs;

            const splitData = inputs.find(el => el.name === 'SplitData');

            if (splitData) {
                onChangeSplitData('left', splitData.value);
                setIsSplitDataShown(true);
            }

            setAlgorithmSelected({
                value: algorithm?.id,
                label: algorithm?.name,
                ...algorithm,
            });

            setArtifact(artifact ? { value: artifact.model?.id, label: artifact.model?.title } : null);

            setAlgorithmData(prev => ({
                ...prev,
                inputs,
                outputs,
            }));

            setBasicInformationData({
                description: modelDetailsResponse.model.description,
                name: modelDetailsResponse.model.title,
            });

            setScheduleSelectedOption(
                runSchedules.find(x => x.value === modelDetailsResponse.model.scheduleId?.toString()) || null
            );
            setRunScheduleData({
                scheduleFrequency: modelDetailsResponse.model.scheduleFrequency.toString(),
                instanceType: {
                    label: instanceType.name,
                    value: instanceType.id?.toString(),
                },
            });
        } catch (err: any) {
            handleError(err);
        } finally {
            setAreDetailsLoading(false);
        }
    };

    const handleCancel = () => {
        if (isFromSelectPopup) {
            onCancel && onCancel();
            return;
        }
        history.goBack();
    };

    const handleRunTraining = () => {
        postModel(true);
    };

    const handleSave = () => {
        postModel();
    };

    const postModel = async (runImmediately = false) => {
        if (algorithmData?.isLoading) {
            return;
        }

        if (!onValidateInputs()) {
            toast.error('Please fill all required fields!');
            return;
        }

        const { inputs } = algorithmData;
        const { scheduleFrequency } = runScheduleData;
        const { description, name } = basicInformationData;

        let payload: any = {
            id: isEditing ? params?.id : 0,
            accountId,
            algorithmId: algorithmSelected?.value,
            inputArtifactId: artifactSelected?.value,
            scheduleId: scheduleSelectedOption?.value,
            scheduleFrequency: !!scheduleFrequency ? scheduleFrequency : 0,
            title: name,
            description,
            instanceTypeId: runScheduleData.instanceType?.value,
            values: inputs
                .filter(el => (el?.name !== 'SplitData' ? true : isSplitDataShown))
                .filter((property: AlgorithmPropertyDetailsModel) => shouldDynamicInputRender(property, inputs))
                .map((el: AlgorithmPropertyDetailsModel) => ({
                    id: isEditing ? el.valueId : 0,
                    propertyId: el?.id,
                    value: el?.value?.value || el?.value,
                })),
            runImmediately,
        };

        try {
            setIsCreateModelLoading(true);
            const response: any = await axios.post('/model', payload);
            let toastMessage =
                (isEditing ? 'Model has been updated' : 'Model has been created') +
                (!!runImmediately ? ' and started running!' : '');
            toast.success(toastMessage);

            if (isFromSelectPopup) {
                onSuccessCreated && onSuccessCreated(response.model);
            } else {
                dispatch(allModelsActions.request({ accountId }));
                history.replace(`/workflow/models${location.search}`);
            }
        } catch (err: any) {
        } finally {
            setIsCreateModelLoading(false);
        }
    };

    /**
     * @return boolean
     */
    const onValidateInputs = () => {
        let isValid = true;
        let _errors: any = {};

        const { name } = basicInformationData;
        const { inputs } = algorithmData;

        if (!name) {
            _errors.name = 'Please fill out this field';
            isValid = false;
        }
        if (algorithmSelected?.hasArtifact && !artifactSelected) {
            _errors.artifact = 'Please select one artifact!';
            isValid = false;
        }
        if (!algorithmSelected) {
            _errors.algorithm = 'Please select one algorithm!';
            isValid = false;
        }
        const splitData = inputs.find(el => el.name === 'SplitData');

        if (splitData?.required && !isSplitDataShown) {
            _errors.splitData = 'Split data is required';
            isValid = false;
        }

        //dynamic inputs validation
        inputs
            .filter(el => el.name !== 'SplitData')
            .forEach(el => {
                if (shouldDynamicInputRender(el, inputs) && !el?.value && el?.required) {
                    _errors[el?.inputName] = 'This field is required';
                    isValid = false;
                }
            });

        if (!scheduleSelectedOption) {
            _errors.schedule = 'please select a schedule';
            isValid = false;
        }

        if (!runScheduleData.instanceType) {
            isValid = false;
            _errors.instanceType = 'Instance type is required!';
        }

        if (scheduleSelectedOption?.value === '2' || scheduleSelectedOption?.value === '3') {
            if (!runScheduleData?.scheduleFrequency) {
                _errors.scheduleFrequency = 'This field is required!';
                isValid = false;
            }
        }

        setErrors(_errors);
        return isValid;
    };
    const deleteErrorKey = key => {
        setErrors(prev => {
            delete prev[key];
            return prev;
        });
    };

    const onChangeSplitData = (key: string, _value: string) => {
        if (!isValidNumber(_value)) {
            return;
        }
        let value = parseFloat(_value) || '';
        if (value < 0) {
            value = 0;
        }
        if (value > 100) {
            value = 100;
        }
        if (key === 'left') {
            setAlgorithmData(prev => ({
                ...prev,
                splitDataValues: { left: value, right: 100 - (typeof value === 'string' ? 0 : value) },
                inputs: prev.inputs.map(el => (el?.name === 'SplitData' ? { ...el, value } : el)),
            }));
            return;
        }
        deleteErrorKey('splitData');
        setAlgorithmData(prev => ({
            ...prev,
            splitDataValues: { right: value, left: 100 - (typeof value === 'string' ? 0 : value) },
            inputs: prev.inputs.map(el =>
                el?.name === 'SplitData' ? { ...el, value: 100 - (typeof value === 'string' ? 0 : value) } : el
            ),
        }));
    };

    const onChangeAlgorithm = (el: any) => {
        deleteErrorKey('algorithm');
        setAlgorithmSelected(el);
        if (!isEditing) {
            setAlgorithmData({ ...initialAlgorithmData });
            setArtifact(null);
        }
    };

    const onChangeArtifact = value => {
        deleteErrorKey('artifact');
        setArtifact(value);
    };

    const onChangeParameters = (key, value) => {
        deleteErrorKey(key);
        setAlgorithmData(previous => {
            const inputs = previous.inputs.map(el => (el.inputName === key ? { ...el, value } : el));

            return {
                ...previous,
                inputs,
            };
        });
    };

    const onChangeRunScheduleData = (key, value) => {
        deleteErrorKey(key);
        setRunScheduleData(prev => ({ ...prev, [key]: value }));
    };

    const onChangeBasicInformationData = (key: string, value: string) => {
        deleteErrorKey(key);
        setBasicInformationData(previous => ({ ...previous, [key]: value }));
    };

    const requestAlgorithmData = async () => {
        const query: any = {
            id: algorithmSelected?.value,
            accountId,
        };

        if (algorithmSelected?.hasArtifact) {
            query.artifactId = artifactSelected?.value;
        }

        try {
            setAlgorithmData({
                ...initialAlgorithmData,
                isLoading: true,
            });
            const response: AlgorithmDetailsResponseModel = await axios.get(`/algorithm?${getFormattedQuery(query)}`);
            const inputs = response.model?.inputs?.map(el => ({
                ...el,
                inputName: el.name,
                value:
                    el?.inputType === inputTypes.dropdown || el?.inputType === inputTypes.dropdownOpen
                        ? el?.options?.find(x => x?.value === el?.defaultValue) || null
                        : el?.defaultValue || '',
            }));
            const outputs = response.model?.outputs;
            const splitData: any = inputs.find(el => el.name === 'SplitData');

            if (splitData) {
                setIsSplitDataShown(splitData?.defaultValue?.toLocaleLowerCase() === 'true' || false);
                onChangeSplitData('left', splitData.value);
            }

            setAlgorithmData(previous => ({
                ...previous,
                inputs,
                outputs,
            }));
        } catch (err: any) {
            console.log({ err });
        } finally {
            setAlgorithmData(previous => ({
                ...previous,
                isLoading: false,
            }));
        }
    };

    /**
     * Renders
     * @returns JSX
     */
    const renderSplitData = () => {
        const { splitDataValues, inputs } = algorithmData;

        const splitData = inputs.find(el => el.name === 'SplitData');

        if (!splitData) {
            return null;
        }

        return (
            <div className="mb-2">
                <div className="w-full h-auto flex flex-row items-center justify-start mb-1">
                    <SwitchInput
                        value={isSplitDataShown}
                        onChange={val => {
                            setIsSplitDataShown(val);
                            deleteErrorKey('splitData');
                        }}
                        label={t('@pages.workflow.split-data')}
                        required={splitData?.required}
                    />
                </div>
                {isSplitDataShown && (
                    <div className="w-full mt-1 flex flex-row justify-between items-end">
                        <InputField
                            name="left"
                            onChange={e => onChangeSplitData('left', e.target.value)}
                            label={t('@pages.workflow.split-data-label1')}
                            placeholder={t('@pages.workflow.enter-percentage-here')}
                            value={splitDataValues.left}
                        />
                        <span className="font-medium text-blueMainText text-sm h-9 px-4 flex items justify-center items-center">
                            :
                        </span>
                        <InputField
                            name="right"
                            label={t('@pages.workflow.split-data-label2')}
                            placeholder={t('@pages.workflow.enter-percentage-here')}
                            value={splitDataValues.right}
                            onChange={e => onChangeSplitData('right', e.target.value)}
                        />
                    </div>
                )}
                <ErrorTextComponent message={errors?.splitData} />
            </div>
        );
    };

    const renderParameters = () => {
        const { inputs } = algorithmData;

        if (!!inputs?.length) {
            return (
                <div className="">
                    <span className="text-base font-normal text-secondaryText">{t('@common.parameters')}</span>
                    {inputs
                        ?.filter(x => x.name !== 'SplitData')
                        .map((property: BaseEntityProperty, index) => (
                            <div
                                key={property.id || index}
                                className={`w-full flex flex-row items-end justify-between mt-3 last:mb-0`}
                            >
                                <SingleDynamicInput
                                    onChangeInputsList={_inputs => {
                                        setAlgorithmData(prev => ({
                                            ...prev,
                                            inputs: _inputs,
                                        }));
                                    }}
                                    handleCreateOption={(name, item: string) => {
                                        setAlgorithmData(prev => ({
                                            ...prev,
                                            inputs: addNewOptionToDynamicInputs(prev.inputs, name, item),
                                        }));
                                    }}
                                    onChangeInput={(name, item) => onChangeParameters(name, item)}
                                    property={property}
                                    properties={inputs}
                                    errors={errors}
                                />
                            </div>
                        ))}
                </div>
            );
        }
    };

    const renderEvaluation = () => {
        const { outputs } = algorithmData;
        if (!!outputs?.length) {
            return (
                <div className="pt-7">
                    <span className="mb-1 text-sm font-normal text-secondaryText">{t('@common.evaluation')}</span>
                    {outputs.map((el, index) => {
                        return (
                            el && (
                                <div
                                    key={el?.id || index}
                                    className="w-full flex flex-row items-center justify-start mb-2 last:mb-0"
                                >
                                    {!!el?.name && (
                                        <InputField
                                            readOnly
                                            disabled
                                            value={el?.name}
                                            inputClassName="bg-blueStroke"
                                            className="mr-2"
                                            style={{ maxWidth: 160 }}
                                        />
                                    )}
                                    {!!el?.dataTypeId && (
                                        <InputField
                                            readOnly
                                            disabled
                                            value={el?.dataTypeId}
                                            inputClassName="bg-blueStroke"
                                            style={{ maxWidth: 160 }}
                                        />
                                    )}
                                </div>
                            )
                        );
                    })}
                </div>
            );
        }
    };

    if (isEditing && detailsError.status === 404) {
        return <Error404 model="model" />;
    }

    return (
        <PageAnimationContainer>
            <div>
                <form className="flex flex-col items-center" id="createModelForm" onSubmit={e => e.preventDefault()}>
                    <LoadingModal isVisible={isCreateModelLoading || areDetailsLoading} />
                    <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                    <div
                        className=""
                        style={{
                            minWidth: currentWidth < 900 ? '100%' : 546,
                            width: currentWidth < 900 ? '100%' : 546,
                            maxWidth: '100%',
                        }}
                    >
                        <InputField
                            required
                            name="name-model"
                            id="name-model"
                            type="text"
                            value={basicInformationData.name}
                            onChange={e => onChangeBasicInformationData('name', e.target.value)}
                            label={t('@common.title')}
                            placeholder={t('@common.write.here')}
                            errorMessage={errors?.name}
                        />
                        <TextareaField
                            value={basicInformationData.description}
                            onChange={e => onChangeBasicInformationData('description', e.target.value)}
                            label={t('@common.description')}
                            placeholder={t('@common.enter-description-here')}
                            errorMessage={errors?.description}
                            className="mt-4"
                        />
                    </div>

                    <HeaderForCreatePages title={t('@common.algorithm')} className="mt-10" />
                    <AlgorithmCard
                        artifactSelected={artifactSelected}
                        algorithmSelected={algorithmSelected}
                        onChangeAlgorithm={onChangeAlgorithm}
                        onChangeArtifact={onChangeArtifact}
                        isEditing={isEditing}
                        errors={errors}
                    >
                        {renderSplitData()}
                        {renderParameters()}
                        {algorithmData?.isLoading && (
                            <div className="w-full flex p-6 items-center justify-center">
                                <Loader color="#1D79F2" />
                            </div>
                        )}
                        {renderEvaluation()}
                    </AlgorithmCard>

                    <RunScheduleComponent
                        errors={errors}
                        onChangeRunScheduleData={onChangeRunScheduleData}
                        onChangeScheduleSelected={value => {
                            setScheduleSelectedOption(value);
                            deleteErrorKey('schedule');
                        }}
                        scheduleSelectedOption={scheduleSelectedOption}
                        runScheduleData={runScheduleData}
                        instanceTypeProps={{
                            loading: instanceTypesLoading,
                            onChange: value => onChangeRunScheduleData('instanceType', value),
                            options: instanceTypesOptions,
                            value: runScheduleData.instanceType,
                        }}
                    />

                    <HeaderForCreatePages title="" hideIcon className="mt-10" />
                    <FooterButtons
                        onClickCancel={handleCancel}
                        onClickSave={handleSave}
                        onClickThirdButton={handleRunTraining}
                        hideThirdButton={!!isFromSelectPopup}
                        thirdButtonTitle={t('@common.save-and-run')}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
