import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useKeyPressListener } from '../../../hooks';
import { modalAnimationStyles, modalOverlayAnimationStyles } from '../../../styles/animationStyles';

export interface BasePopupProps {
    isVisible: boolean;
    onHide: () => any;
    className?: string;
    children: ReactNode;
}

export const BasePopup: React.FC<BasePopupProps> = ({ children, isVisible, onHide, className }) => {
    useKeyPressListener(onKeyPress);

    const [shouldUnMount, setShouldUnMount] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShouldUnMount(!isVisible);
        }, 400);
    }, [isVisible]);

    function onKeyPress({ key }: KeyboardEvent) {
        key === 'Escape' && onHide();
    }
    return createPortal(
        <CSSTransition in={isVisible} timeout={100} unmountOnExit={shouldUnMount}>
            {state => (
                <div
                    onClick={e => {
                        e.stopPropagation();
                        onHide();
                    }}
                    className={`fixed flex z-40 inset-0 overflow-y-auto bg-mainOverlay/50 items-center justify-center`}
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                    style={{
                        transition: 'opacity 500ms ease-in-out',
                        opacity: 0,
                        ...modalOverlayAnimationStyles[state],
                    }}
                >
                    <div
                        style={{
                            transition: 'opacity 500ms ease-in-out',
                            opacity: 0,
                            ...modalAnimationStyles[state],
                        }}
                        onClick={e => e.stopPropagation()}
                        className={`inline-block ${className}`}
                    >
                        {children}
                    </div>
                </div>
            )}
        </CSSTransition>,
        document.body
    );
};
