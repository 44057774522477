import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import type { Identifier, XYCoord } from 'dnd-core';
import React, { useEffect, useMemo, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DragIcon } from '../../../../../../assets/icons/drag-icon.svg';
import { ToggleInput } from '../../../../../../components/buttons';
import { Clickable } from '../../../../../../components/common';
import { InputField, SelectField } from '../../../../../../components/inputs';
import { FieldLabel } from '../../../../../../components/inputs/components/FieldLabel';
import {
    taskFilePathDataTypesOptions,
    taskInputDataTypesOptions,
    taskInputTypes,
    taskInputTypesOptions,
} from '../../../constants';
import { TInput } from '../../hooks';
import { RemoveButton } from '../RemoveButton';
import { Options } from './Options';

interface ITaskInputCardProps {
    onToggleOpen: () => void;
    isOpen: boolean;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    removeCard: (_id: string) => void;
    input: TInput;
    onChangeInput: (key: keyof TInput, value: any) => void;
    index: number;
}

export const ItemTypes = {
    CARD: 'card',
};

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const inputsClassName = 'w-full mr-0 sm:w-[194px] sm:mr-4';

export const TaskInputCard = React.memo(
    ({ onToggleOpen, isOpen, moveCard, index, removeCard, input, onChangeInput }: ITaskInputCardProps) => {
        const { t } = useTranslation();
        const ref = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (isOpen && ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, [ref, isOpen]);

        const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
            accept: ItemTypes.CARD,
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            hover(item: DragItem, monitor) {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;

                if (dragIndex === hoverIndex) {
                    return;
                }

                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }

                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }

                moveCard(dragIndex, hoverIndex);

                item.index = hoverIndex;
            },
        });
        const [{ isDragging }, drag] = useDrag({
            type: ItemTypes.CARD,
            item: () => {
                return { id: input._id, index };
            },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const { dataTypeOptions, showOptions } = useMemo(() => {
            const inputType = input.inputType?.value;
            const typesWithOptions = [taskInputTypes.dropdown];

            return {
                dataTypeOptions:
                    inputType === taskInputTypes.filePath
                        ? taskFilePathDataTypesOptions
                        : inputType === taskInputTypes.input
                        ? taskInputDataTypesOptions
                        : [],
                showOptions: typesWithOptions.some(x => x === inputType),
            };
        }, [input.inputType?.value]);

        drag(drop(ref));
        return (
            <div
                ref={ref}
                data-handler-id={handlerId}
                className={`flex flex-col w-full px-4 transition-all duration-300 border border-white rounded-md ${
                    isDragging ? 'opacity-0' : 'opacity-100'
                }`}
            >
                <div
                    onClick={e => {
                        e.stopPropagation();
                        onToggleOpen();
                    }}
                    className={`flex flex-row items-center cursor-pointer w-full py-2.5 border-b border-mainBorder`}
                >
                    <div className="flex flex-1 items-center">
                        {/* <Clickable onClick={e => e.stopPropagation()}>
                            <DragIcon />
                        </Clickable> */}
                        <h2 className={`text-blueMainText font-medium text-sm ml-2`}>{input.name || `-`}</h2>
                    </div>

                    <Clickable className={`transition-all duration-300 delay-0 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                        <GjirafaIcon size={20} name="ArrowDown" />
                    </Clickable>
                </div>

                <AnimateHeight duration={250} delay={50} height={isOpen ? 'auto' : 0}>
                    <div className="w-full py-4 p-8">
                        <div className="flex w-full">
                            <InputField
                                placeholder={t('@common.write.here')}
                                label={t('@common.name')}
                                className={`${inputsClassName}`}
                                value={input.name}
                                required
                                disabled
                                onChange={e => onChangeInput('name', e.target.value)}
                            />
                            <SelectField
                                required
                                label={t('@input.type', { defaultValue: 'Input Type' })}
                                className={`${inputsClassName}`}
                                placeholder={t('@choose.inputType', { defaultValue: 'Choose an input type...' })}
                                options={taskInputTypesOptions}
                                formatOptionLabel={option => t(`@option.${option.value}`)}
                                value={input.inputType}
                                disabled
                                onChange={item => onChangeInput('inputType', item)}
                            />
                                {/* disabled={!dataTypeOptions.length} */}
                            <SelectField
                                disabled={true}
                                label={t('@dataType')}
                                className={`transition-all duration-200 ${
                                    dataTypeOptions.length ? 'opacity-100' : 'opacity-0 cursor-default'
                                } ${inputsClassName}`}
                                placeholder={t('@choose.dataType', { defaultValue: 'Choose a data type...' })}
                                options={dataTypeOptions}
                                value={input.dataType}
                                onChange={item => onChangeInput('dataType', item)}
                            />
                            <div className="flex flex-col justify-between">
                                <FieldLabel label={t('@required', { defaultValue: 'Required' })} />
                                <div className="h-10 flex items-center">
                                    <ToggleInput
                                        isToggled={input.required}
                                        onToggle={() => onChangeInput('required', !input.required)}
                                    />
                                </div>
                            </div>
                            {/* <RemoveButton className="ml-auto mb-1.5 mt-auto" onClick={() => removeCard(input._id)} /> */}
                        </div>
                        {showOptions && (
                            <Options
                                options={input.options}
                                setOptions={options => onChangeInput('options', options)}
                            />
                        )}
                    </div>
                </AnimateHeight>
            </div>
        );
    },
    arePropsEqual
);

function arePropsEqual(prevProps: ITaskInputCardProps, nextProps: ITaskInputCardProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(nextProps)) {
        return false;
    }
    return true;
}
