import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { trimEnd, trimStart } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoragePopupOptions } from '.';
/**
 * Components
 */
import { AnimationComponent } from '../../../components/animation';
import { CreateButton, UploadButton } from '../../../components/buttons';
import { RefreshButton } from '../../../components/buttons/RefreshButton';
import { DropzoneCardContainer } from '../../../components/cards';
import { ConfirmationPopupModal } from '../../../components/modals';
import { ReactTableWrapper } from '../../../components/react-table/ReactTableWrapper';
import { SelectableRowComponent } from '../../../components/tables';
import { Loader, toast } from '../../../components/ui';
import { storagePageContext } from '../../../contexts';
import { useAccountId, useToggleVisibility } from '../../../hooks';
import {
    PresignedUrlDetailsResponseModel,
    StorageObjectListModel,
    StorageObjectListResponseModel,
} from '../../../models';
/**
 * redux - services
 */
import { selectors } from '../../../redux/thunk/app/storage/storageObjectsThunk';
import { actions as uploadObjectsActions } from '../../../redux/thunk/app/storage/uploadObjectsThunk';
import axios from '../../../services/axios';
import { formatBytes, formatDateAndTime, showServerErrors } from '../../../utils';
/**
 * Local Components
 */
import {
    GoBackRowComponent,
    MoveObjectsModal,
    SearchKeysComponent,
    StorageKeyCellComponent,
    StorageNoDataComponent,
} from './components';
import { CreateNewFolderComponent } from './components/CreateNewFolderComponent';
import { GlobalActionsComponent } from './components/GlobalActionsComponent';
import { StoragePaginationComponent } from './components/StoragePaginationComponent';
import { StorageTableActions } from './components/StorageTableActions';
import { FileStateType, UploadSelectedFilesModal } from './components/UploadSelectedFilesModal';
import { useStorageHook } from './hooks/useStorageHook';
import {
    getSelectMessageKeyForSelectPopup,
    goBackKey,
    isSelectableRowDisabled,
    storageGoBackRowData,
} from './storage.helper';
/**
 * Style
 */
import { storageTableCustomStyle, storageTableStyle } from './style';

type SelectedRowsProps = {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: StorageObjectListModel[];
};
interface StoragePageProps {
    options?: StoragePopupOptions;
    isFromSelectPopup?: boolean;
    selectPopupProps?: {
        onChangeSelectedRows?: (items: StorageObjectListModel[]) => any;
    };
}

export const StoragePage: React.FC<StoragePageProps> = ({ isFromSelectPopup = false, options, selectPopupProps }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();

    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility();
    const [isDeleteConfirmVisible, shouldRenderDeleteConfirm, onToggleDeleteConfirm] = useToggleVisibility();
    const [isMoveModalVisible, shouldRenderMoveModal, onToggleMoveModal] = useToggleVisibility();

    let uploadSuccessTimeout: any = null;

    /**
     * Selectors
     */
    const loading = useSelector(selectors.getIsLoading);
    const response: StorageObjectListResponseModel = useSelector(selectors.getResponse);

    /**
     * Local State
     */
    const [clearSelectedRows, setClearSelectedRow] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[] | FileList>([]);
    const [selectedRows, setSelectedRows] = useState<StorageObjectListModel[]>([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedItemForRename, setSelectedItemForRename] = useState<StorageObjectListModel | null>(null);
    const [isLoadingPath, setIsLoadingPath] = useState<boolean>(false)

    const onClearSelectedRows = () => {
        setSelectedRows([]);
        setClearSelectedRow(p => !p);
    };

    const { onRefresh, onRequestKey, paramsPath, onLoadMore } = useStorageHook({
        isFromSelectPopup,
        onClearSelectedRows,
    });

    const onFilesUploaded = (files: File[] | FileList) => {
        setUploadedFiles(files);
        onToggleVisibility(true);
    };

    const onSubmitFiles = (files: FileStateType[]) => {
        let key = paramsPath?.endsWith('/') ? paramsPath : paramsPath.length ? paramsPath + '/' : '';

        files.forEach(({ name, file }) => {
            dispatch(
                uploadObjectsActions.upload(
                    { accountId, name, fileContents: file, key: key + name },
                    objectSuccessUploaded
                )
            );
        });
        setUploadedFiles([]);
        onToggleVisibility(false);
    };

    const onCreateFolder = async name => {
        try {
            let newKey = '';

            if (paramsPath.endsWith('/')) {
                newKey = paramsPath + name;
            } else {
                let splitArr = paramsPath.split('/');
                newKey = splitArr.toString().length
                    ? splitArr.slice(0, splitArr.length - 1).join('/') + '/' + name
                    : name;
            }

            const query = {
                accountId: `${accountId}`,
                key: newKey,
                isFolder: 'true',
            };

            await axios.get(`/storage/object/presignedUrl?${new URLSearchParams(query)}`);
            toast.success(t('@folder.created.successfully', { defaultValue: 'New folder has been created!' }));

            if (isFromSelectPopup) {
                onRefresh();
            } else onRequestKey(newKey + '/');
            return true;
        } catch (err) {
            showServerErrors(err);
            return false;
        }
    };

    const onClickGoBackRow = () => onRequestKey(goBackKey(paramsPath));

    const onClickFolder = (_key: string) => onRequestKey(trimStart(_key, '/'));

    useEffect(() => {
        if (isLoadingPath) setIsLoadingPath(false)
    }, [response?.model])

    useEffect(() => {
        setIsLoadingPath(true)
    }, [paramsPath])

    const handleDelete = async () => {
        try {
            setDeleteLoading(true);
            let prefixes: string[] = [];
            let keys: string[] = [];

            selectedRows.forEach(({ key }) => {
                (key.endsWith('/') ? prefixes : keys).push(key);
            });

            const query = new URLSearchParams();
            query.append('accountId', accountId.toString());

            keys.forEach(x => query.append('keys', x));
            prefixes.forEach(x => query.append('prefixes', x));

            await axios.delete(`/storage/object?${query}`);

            setClearSelectedRow(p => !p);
            setSelectedRows([]);
            toast.success(
                t('@toast-messages.success-storageObject-delete', { defaultValue: 'Storage Object has been deleted' })
            );
            onToggleDeleteConfirm(false);
            onRefresh();
        } catch (err) {
            showServerErrors(err);
        } finally {
            setDeleteLoading(false);
        }
    };

    const objectSuccessUploaded = () => {
        clearTimeout(uploadSuccessTimeout);
        uploadSuccessTimeout = setTimeout(() => {
            onRefresh();
        }, 1000);
    };

    const onSuccessMoveObjects = () => onRefresh();

    /**
     * Action Methods
     */
    const onClickRename = useCallback(
        (row: StorageObjectListModel) => {
            setSelectedItemForRename(prev => {
                return prev && prev.key === row.key ? null : row;
            });
        },
        [setSelectedItemForRename]
    );

    const onClickMove = useCallback((row: StorageObjectListModel) => {
        setSelectedRows([row]);
        onToggleMoveModal(true);
    }, []);

    const onClickRemove = useCallback(
        (row: StorageObjectListModel) => {
            setSelectedRows([row]);
            onToggleDeleteConfirm(true);
        },
        [setSelectedRows, onToggleDeleteConfirm]
    );
    const onClickDownload = useCallback(
        async (row: StorageObjectListModel) => {
            const query = {
                accountId: accountId.toString(),
                quickShareUrl: 'true',
                isFolder: 'false',
                key: row.key,
                duration: '1',
            };
            const response: PresignedUrlDetailsResponseModel = await axios.get(
                `/storage/object/presignedUrl?${new URLSearchParams(query)}`
            );
            return response.model;
        },
        [accountId]
    );

    const onSelectedRowsChange = useCallback((props: SelectedRowsProps) => {
        setSelectedRows(props.selectedRows.filter(x => !x.goBack));
    }, []);

    const columns = useMemo(() => {
        let _columns: TableColumn<StorageObjectListModel>[] = [
            {
                selector: row => row.key,
                name: 'Key',
                grow: 1,
                cell: (row: StorageObjectListModel) => {
                    const splitKey = trimEnd(row.key, '/').split('/');
                    const key = splitKey[splitKey.length - 1];

                    return row?.goBack ? (
                        <GoBackRowComponent onClick={onClickGoBackRow} />
                    ) : (
                        <StorageKeyCellComponent
                            keyText={key}
                            row={row}
                            onRefreshList={onRefresh}
                            onClick={() => row?.isFolder && onClickFolder(row.key)}
                        />
                    );
                },
            },
            {
                selector: row => row.size,
                name: 'Size',
                width: '110px',
                maxWidth: 'auto',
                cell: (row: StorageObjectListModel) => {
                    let displaySize = !(row.isFolder || row.goBack);
                    return <span>{displaySize ? formatBytes(row.size) : ''}</span>;
                },
            },
            {
                selector: row => row.lastModified,
                name: 'Last Modified',
                maxWidth: '180px',
                cell: (row: StorageObjectListModel) => (
                    <span>{row.isFolder ? '' : formatDateAndTime(row.lastModified || '')}</span>
                ),
            },
        ];

        if (!isFromSelectPopup)
            _columns.push({
                name: '',
                width: '100px',
                cell: (row: StorageObjectListModel) => {
                    if (row.goBack) return <></>;
                    return (
                        <StorageTableActions
                            row={row}
                            onClickDownload={() => onClickDownload(row)}
                            onClickMove={() => onClickMove(row)}
                            onClickRemove={() => onClickRemove(row)}
                            onClickRename={() => onClickRename(row)}
                        />
                    );
                },
            });

        return _columns;
    }, [isFromSelectPopup, paramsPath]);

    const multiSelectActive = useMemo(() => selectedRows.length > 0, [selectedRows]);

    const { totalItems, totalSize } = useMemo(() => {
        const objectListModel = response.model.objectListModel ?? [];
        return {
            totalSize: objectListModel.reduce((acc, item) => acc + (parseInt(item.size) ?? 0), 0),
            totalItems: objectListModel.length,
        };
    }, [response.model.objectListModel]);

    return (
        <storagePageContext.Provider value={{ selectedItemForRename, setSelectedItemForRename }}>
            {shouldRender && (
                <UploadSelectedFilesModal
                    isVisible={isVisible}
                    files={uploadedFiles}
                    onHide={() => onToggleVisibility(false)}
                    onSubmit={onSubmitFiles}
                />
            )}
            {shouldRenderDeleteConfirm && (
                <ConfirmationPopupModal
                    isVisible={isDeleteConfirmVisible}
                    onCancel={() => {
                        onToggleDeleteConfirm(false);
                        selectedRows.length === 1 && onClearSelectedRows();
                    }}
                    title={t('@confirm-delete.storageObject', { defaultValue: 'Storage object delete confirmation!' })}
                    description={t('@delete.confirm.message', {
                        defaultValue: 'Are you sure you want to delete?',
                    })}
                    onConfirm={() => handleDelete()}
                    loading={deleteLoading}
                />
            )}
            {shouldRenderMoveModal && (
                <MoveObjectsModal
                    isVisible={isMoveModalVisible}
                    objects={selectedRows}
                    onHide={() => {
                        onToggleMoveModal(false);
                        selectedRows.length === 1 && onClearSelectedRows();
                    }}
                    onSuccess={onSuccessMoveObjects}
                    paramsPath={paramsPath}
                />
            )}

            <DropzoneCardContainer
                cardOverlayProps={{
                    message: t('@storage.drop.files.here.to.upload', { defaultValue: 'Drop files here to upload...' }),
                }}
                options={{ accept: undefined }}
                className={`flex flex-col flex-1 pb-4 ${isFromSelectPopup && 'px-4'}`}
                onUploadFiles={onFilesUploaded}
            >
                <AnimationComponent show={true} type="fade-top" delay={200} duration={300}>
                    <div className="flex transition-all duration-500 flex-col md:flex-row w-full pb-5 md:justify-between md:items-center flex-wrap overflow-visible z-1">
                        <div className="flex flex-1 flex-wrap">
                            <UploadButton
                                multiple
                                accept="*"
                                handleUpload={event => onFilesUploaded(event.target.files || [])}
                                childrenContainerClassName="max-w-auto mr-3 mb-1"
                            >
                                <CreateButton
                                    className="pr-4"
                                    icon={
                                        <div className="mr-3">
                                            <GjirafaIcon name="Upload" size="18" />
                                        </div>
                                    }
                                    title={t('@upload.files', { defaultValue: 'Upload files' })}
                                />
                            </UploadButton>
                            <CreateNewFolderComponent className="mr-3 mb-1" onCreateFolder={onCreateFolder} />
                            {!isFromSelectPopup && (
                                <GlobalActionsComponent
                                    triggerClassName="mb-1"
                                    isActive={multiSelectActive}
                                    onClickAction={type => {
                                        if (type === 'remove') onToggleDeleteConfirm(true);
                                        if (type === 'move') onToggleMoveModal(true);
                                    }}
                                />
                            )}
                        </div>
                        <div className="flex h-full justify-end">
                            <RefreshButton className="mb-1" onRefresh={() => onRefresh(false)} />
                        </div>
                    </div>
                </AnimationComponent>
                <ReactTableWrapper withAnimation>
                    <DataTable
                        fixedHeader={false}
                        noHeader
                        responsive
                        highlightOnHover
                        subHeader
                        style={storageTableStyle}
                        customStyles={storageTableCustomStyle}
                        subHeaderComponent={
                            <div className="p-4 flex w-full justify-between flex-wrap overflow-hidden">
                                <SearchKeysComponent
                                    isLoading={loading && !!response.model.objectListModel.length}
                                    paramsPath={paramsPath}
                                    onSubmit={key => {
                                        onRequestKey(key);
                                    }}
                                />
                                <span className="text-secondaryText text-sm font-normal">
                                    {t('@storage.total.items', {
                                        defaultValue: '{{number}} items',
                                        number: totalItems,
                                    })}
                                    {' / '}
                                    {formatBytes(totalSize)}
                                </span>
                            </div>
                        }
                        disabled={isLoadingPath}
                        keyField="key"
                        data={
                            response.model.objectListModel?.length && paramsPath.length
                                ? [storageGoBackRowData, ...(response.model.objectListModel || [])]
                                : response.model.objectListModel
                        }
                        columns={columns}
                        pagination
                        paginationServer
                        paginationComponent={() => (
                            <StoragePaginationComponent totalItems={totalItems} onLoadMore={onLoadMore} />
                        )}
                        noDataComponent={
                            <StorageNoDataComponent
                                onClickGoBack={onClickGoBackRow}
                                handleFilesSelected={onFilesUploaded}
                                displayGoBack={!!paramsPath?.length}
                            />
                        }
                        progressPending={loading && response.model.objectListModel.length < 1}
                        progressComponent={
                            <div className="p-10">
                                <Loader />
                            </div>
                        }
                        onSelectedRowsChange={onSelectedRowsChange}
                        selectableRowsHighlight
                        selectableRows
                        selectableRowsSingle={isFromSelectPopup && options && !options.multiple}
                        selectableRowsNoSelectAll={!!isFromSelectPopup}
                        selectableRowDisabled={row => isSelectableRowDisabled(row, options)}
                        selectableRowsComponent={SelectableRowComponent}
                        selectableRowsComponentProps={{
                            componentType: isFromSelectPopup && !options?.multiple ? 'radio' : 'checkbox',
                        }}
                        clearSelectedRows={clearSelectedRows}
                    />
                </ReactTableWrapper>
            </DropzoneCardContainer>
            {isFromSelectPopup && (
                <div className="w-full sticky bottom-0 left-0 right-0 bg-white px-4 pb-2 pt-2">
                    <div className="flex w-full rounded border border-mainBorder p-2 justify-between items-center">
                        <span className="text-sm font-medium text-blueMainText">
                            {t(getSelectMessageKeyForSelectPopup(options))}
                        </span>
                        <CreateButton
                            icon={<GjirafaIcon name="Selection" />}
                            disabled={selectedRows.length < 1}
                            title="Select"
                            onClick={() => {
                                const onChangeSelectedRows = selectPopupProps?.onChangeSelectedRows;
                                onChangeSelectedRows && onChangeSelectedRows(selectedRows);
                            }}
                        />
                    </div>
                </div>
            )}
        </storagePageContext.Provider>
    );
};
