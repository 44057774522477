import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inputTypes, runSchedules } from '../../constants';
import { useAccountId } from '../../hooks';
import {
    BaseEntityValue,
    DataSourceDetailsProperties,
    DataSourceDetailsResponseModel,
    DataSourceReaderDetailsResponseModel,
    SelectOptionType,
} from '../../models';
import { splitPathAndFileNameFromKey } from '../../pages/data/storage/storage.helper';
import axios from '../../services/axios';
import { deleteErrorKey, getAccountIdFromQuery, getFormattedQuery } from '../../utils';
import { DynamicInputs, RunScheduleComponentForModal, shouldDynamicInputRender } from '../common';
import { InputField, SelectWithPopupField, SelectWithPopupTypes, SelectWithStoragePopupField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

interface CreateDataSourceReaderModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    idForEdit?: number;
    onSuccess?: (dataSourceId: number, isEditing: boolean) => any;
    preSelectedDataSource?: SelectOptionType;
    disableDataSource?: boolean;
}

type stateFieldsType = {
    inputs: DataSourceDetailsProperties[];
    dataSource: SelectOptionType | null;
    title: string;
    objectStoragePath: SelectOptionType | null;
    objectStorageName: string;
    schedule?: any;
    scheduleFrequency?: string;
};

export const CreateDataSourceReaderModal: React.FC<CreateDataSourceReaderModalProps> = ({
    preSelectedDataSource,
    isVisible,
    onHide,
    idForEdit,
    disableDataSource,
    isEditing,
    onSuccess,
}) => {
    const accountId = useAccountId();
    const { t } = useTranslation();

    const [fieldsState, setFieldsState] = useState<stateFieldsType>({
        dataSource: preSelectedDataSource ?? null,
        inputs: [],
        objectStoragePath: null,
        title: '',
        schedule: runSchedules[0],
        scheduleFrequency: '',
        objectStorageName: '',
    });
    const [inputsLoading, setInputsLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (isEditing && idForEdit) {
            fetchDetails();
        }
    }, [isEditing, idForEdit]);

    useEffect(() => {
        if (preSelectedDataSource && isVisible && !isEditing) fetchDataSourceInputs(preSelectedDataSource?.value);
    }, [preSelectedDataSource, isVisible, isEditing]);

    const onValidate = () => {
        let [isValid, _errors]: [boolean, any] = [true, {}];
        const { dataSource, inputs, schedule, title, scheduleFrequency, objectStoragePath, objectStorageName } =
            fieldsState;

        if (!title) {
            _errors.title = t('@validations.field.isRequired', {
                defaultValue: '{{field}} is required!',
                field: 'Title',
            });
            isValid = false;
        }

        if (!dataSource) {
            _errors.dataSource = t('@validations.field.isRequired', {
                defaultValue: '{{field}} is required!',
                field: 'Data Source',
            });
            isValid = false;
        }

        if (!objectStoragePath) {
            _errors.objectStoragePath = t('@validations.field.isRequired', {
                defaultValue: '{{field}} is required!',
                field: 'Object Storage Path',
            });
            isValid = false;
        }

        if (objectStorageName.trim().length < 1) {
            _errors.objectStorageName = t('@validations.field.isRequired', {
                defaultValue: '{{field}} is required!',
                field: 'Object Storage Name',
            });
            isValid = false;
        } else if (!/^[a-zA-Z(0-9)_-]+$/.test(objectStorageName)) {
            _errors.objectStorageName = t('@validations.field.notValid', {
                defaultValue: '{{field}} is not valid!',
                field: 'Object Storage Path',
            });
            isValid = false;
        }

        inputs.forEach(inp => {
            if (shouldDynamicInputRender(inp, inputs) && inp.required && !inp.value) {
                isValid = false;
                _errors[inp.inputName] = t('@validations.field.isRequired', {
                    defaultValue: '{{field}} is required!',
                    field: inp.inputName,
                });
            }
        });

        if (!schedule) {
            _errors.schedule = t('@validations.field.isRequired', {
                defaultValue: '{{field}} is required!',
                field: 'Schedule',
            });
            isValid = false;
        }

        if (schedule?.value === '2' || schedule?.value === '3') {
            if (!scheduleFrequency) {
                _errors.scheduleFrequency = t('@validations.this.field.isRequired', {
                    defaultValue: 'This field is required!',
                });
                isValid = false;
            }
        }

        setErrors(_errors);
        return isValid;
    };

    const fetchDetails = async () => {
        try {
            setDetailsLoading(true);
            const query = {
                accountId: getAccountIdFromQuery(),
                id: idForEdit,
            };
            const { model }: DataSourceReaderDetailsResponseModel = await axios.get(
                `/dataSourceReader?${getFormattedQuery(query)}`
            );

            let inputs: DataSourceDetailsProperties[][] = model.dataSource?.inputs.map(
                (property: DataSourceDetailsProperties) => {
                    const { values } = model;
                    let _inputs: BaseEntityValue[] | undefined =
                        values.filter(el => el.propertyId === property.id) || [];

                    if (_inputs.length < 1) {
                        _inputs = [
                            {
                                id: 0,
                                propertyId: property.id,
                                propertyName: property.name,
                                value: property.defaultValue,
                            },
                        ];
                    }

                    let _arr = _inputs?.map(input => {
                        let options = property.options;

                        let value: any = null;
                        if (
                            property.inputType === inputTypes.dropdown ||
                            property.inputType === inputTypes.dropdownOpen
                        ) {
                            value = input?.value
                                ? property?.options?.find(x => x.value === input?.value)
                                : property?.options?.find(x => x.value === property?.defaultValue) || null;
                            if (!value && input?.value) {
                                let newOption = {
                                    label: input?.value || '',
                                    value: input?.value || '',
                                };
                                options.push(newOption);
                                value = newOption;
                            }
                        } else {
                            value = input?.value || property?.defaultValue || '';
                        }

                        return {
                            ...property,
                            options,
                            value,
                            inputName: property?.isRepeatable ? `${property.name} ${nanoid()}` : property.name,
                            valueId: input?.id || 0,
                        };
                    });

                    return _arr;
                }
            );

            const { path, name } = splitPathAndFileNameFromKey(model.objectStoragePath);
            setFieldsState({
                dataSource: { label: model.dataSource.name, value: model.dataSource.id?.toString() },
                inputs: inputs.flat(),
                title: model.title,
                scheduleFrequency: model.scheduleFrequency?.toString() || '',
                schedule: runSchedules.find(y => y.value === model?.scheduleId?.toString()) || null,
                objectStoragePath: { value: path, label: path },
                objectStorageName: name.includes('.') ? name.split('.').slice(0, 1).join('').toString() : name,
            });
        } catch (err) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const fetchDataSourceInputs = async id => {
        try {
            setInputsLoading(true);
            const query = {
                id,
                accountId,
            };

            const inputsResponse: DataSourceDetailsResponseModel = await axios.get(
                `/dataSource?${getFormattedQuery(query)}`
            );
            let inputs =
                inputsResponse.model?.inputs?.map(el => {
                    if (el.inputType === inputTypes.dropdown || el.inputType === inputTypes.dropdownOpen)
                        return {
                            ...el,
                            value: el.defaultValue ? el?.options?.find(x => x.value === el?.defaultValue) : null,
                            inputName: el.name,
                        };
                    return {
                        ...el,
                        value: el?.defaultValue || '',
                        inputName: el.name,
                    };
                }) || [];
            setFieldsState(prev => ({ ...prev, inputs }));
        } catch (err: any) {
        } finally {
            setInputsLoading(false);
        }
    };

    const onChangeState = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFieldsState(prev => ({ ...prev, [key]: value }));
    };

    const onChangeInputs = (key, value) => {
        deleteErrorKey(key, setErrors);
        setFieldsState(prev => ({
            ...prev,
            inputs: prev.inputs.map(inp => (inp.inputName === key ? { ...inp, value } : inp)),
        }));
    };

    const onChangeDataSource = item => {
        deleteErrorKey('dataSource', setErrors);
        setFieldsState(prev => ({ ...prev, dataSource: item }));
        fetchDataSourceInputs(item?.value);
    };

    const handleSubmit = async e => {
        e.stopPropagation();
        e.preventDefault();

        const { dataSource, inputs, title, scheduleFrequency, schedule, objectStoragePath, objectStorageName } =
            fieldsState;
        if (!onValidate() || inputsLoading) return;

        try {
            setFormLoading(true);

            const payload = {
                id: isEditing ? idForEdit : 0,
                accountId,
                dataSourceId: dataSource?.value,
                title,
                objectStoragePath: objectStoragePath?.value + objectStorageName + '.csv',
                scheduleId: schedule?.value,
                scheduleFrequency: scheduleFrequency || '',
                runImmediately: true,
                values: inputs
                    .filter(x => shouldDynamicInputRender(x, inputs))
                    .map(inp => ({
                        id: isEditing ? inp?.valueId : 0,
                        propertyId: inp.id,
                        value: inp?.value?.value || inp?.value,
                    })),
            };
            const response: DataSourceReaderDetailsResponseModel = await axios.post('/dataSourceReader', payload);

            onSuccess && onSuccess(response.model.dataSource.id, !!isEditing);
            onHide();
        } catch (err) {
        } finally {
            setFormLoading(false);
        }
    };

    const renderProperties = () => {
        if (inputsLoading) {
            return (
                <div className="flex flex-col p-10 justify-center items-center">
                    <Loader />
                </div>
            );
        }

        return (
            <DynamicInputs
                onChangeInputsList={_properties => {
                    setFieldsState(prev => ({
                        ...prev,
                        inputs: _properties,
                    }));
                }}
                onChangeInput={onChangeInputs}
                properties={fieldsState.inputs}
                className="mt-4"
                errors={errors}
            />
        );
    };

    const formId = useMemo(() => (isEditing ? 'editDataSourceReaderForm' : 'addDataSourceReaderForm'), [isEditing]);

    return (
        <>
            <SideModal
                isVisible={isVisible}
                onHide={onHide}
                title={
                    isEditing
                        ? t('@components.modals.edit-dataSourceReader')
                        : t('@components.modals.create-dataSourceReader')
                }
                buttonText={isEditing ? t('@common.edit') : t('@common.create')}
                formId={formId}
                loading={formLoading}
            >
                <form onSubmit={handleSubmit} id={formId} className="flex flex-col h-full border-solid px-4 py-6">
                    {detailsLoading ? (
                        <div className="flex flex-col p-10 justify-center items-center">
                            <Loader />
                        </div>
                    ) : (
                        <div className={`flex flex-col w-full pb-4`}>
                            <InputField
                                required
                                label={t('@common.title')}
                                placeholder={t('@common.write.here')}
                                name="dataSourceReader-title"
                                value={fieldsState.title}
                                errorMessage={errors?.title}
                                onChange={e => onChangeState('title', e.target.value)}
                            />
                            <SelectWithStoragePopupField
                                className="mt-4"
                                required
                                selectedItems={fieldsState.objectStoragePath ? [fieldsState.objectStoragePath] : []}
                                onChangeSelectedItems={items => onChangeState('objectStoragePath', items[0] ?? null)}
                                options={{
                                    isFolder: true,
                                    multiple: false,
                                    inputInside: true,
                                }}
                                showSelectedItemInsideInput
                                placeholder={t('@select.folder')}
                                errorMessage={errors.objectStoragePath}
                                label={t('@object.storage.path', { defaultValue: 'Object Storage Path' })}
                            />
                            <div className="flex justify-between mt-4 items-start w-full">
                                <InputField
                                    required
                                    value={fieldsState.objectStorageName}
                                    onChange={e => onChangeState('objectStorageName', e.target.value)}
                                    label={t('@object.storage.name', { defaultValue: 'Object Storage Name' })}
                                    placeholder={t('@common.write.here')}
                                    errorMessage={errors.objectStorageName}
                                />
                                <span className="text-blueMainText text-sm ml-2 mt-11">.csv</span>
                            </div>
                            <SelectWithPopupField
                                required
                                disabled={disableDataSource}
                                label={t('@common.data-source')}
                                placeholder={t('@common.choose-data-source')}
                                fetchDataOnMount={false}
                                onChangeItem={onChangeDataSource}
                                className="mt-4"
                                selectedItem={fieldsState.dataSource}
                                tableType={SelectWithPopupTypes.dataSource}
                                popupKeyTitle="@common.select-data-source"
                                errorMessage={errors?.dataSource}
                            />
                            {renderProperties()}
                            <RunScheduleComponentForModal
                                errors={errors}
                                fieldsState={{
                                    schedule: fieldsState.schedule,
                                    scheduleFrequency: fieldsState.scheduleFrequency,
                                }}
                                onChangeState={onChangeState}
                            />
                        </div>
                    )}
                </form>
            </SideModal>
        </>
    );
};
