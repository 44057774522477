import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import { HeaderForCreatePages } from '../../../components/common';
import { Error404 } from '../../../components/errors';
import { InputField, SelectWithPopupField, SelectWithPopupTypes, TextareaField } from '../../../components/inputs';
import { Loader, toast } from '../../../components/ui';
import { artifactContentTypes, FEATURE_ENGINEERING_PATH, tabularQueryArtifacts } from '../../../constants';
import { useAccountId, useCurrentWidth, useDetailsError } from '../../../hooks';
import {
    ArtifactDetailsResponseModel,
    FlowDetailsResponseModel,
    FlowInstanceTypeListResponseModel,
    SelectOptionType,
    TabularDataSummary,
} from '../../../models';
import {
    actions as instanceTypesActions,
    selectors as instanceTypesSelectors,
} from '../../../redux/thunk/app/flow/flowInstanceTypesThunk';
import axios from '../../../services/axios';
import { deleteErrorKey, getFormattedQuery, queryParams } from '../../../utils';
import { CreateTabularFlow, CreateTextualFlow } from './components';

export type BasicInformationType = {
    title: string;
    description: string;
    artifact: SelectOptionType | null;
    optionalArtifact?: SelectOptionType | null | string;
};
export interface CommonFlowProps {
    basicInformation: BasicInformationType;
    flowEditResponseModel: FlowDetailsResponseModel | null;
    onValidateBasicInformation: () => boolean;
    formId: string;
    onCancel: () => any;
    onSuccess: (edited: boolean, runImmediately: boolean) => any;
}

const formId = 'createFeatureEngineeringPage';

export const CreateFeatureEngineeringPage: React.FC = () => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const history = useHistory();
    const location = useLocation();
    const params: any = useParams();
    const currentWidth = useCurrentWidth();
    const dispatch = useDispatch();
    const isEditing = params?.id;
    const { detailsError, handleError, resetError } = useDetailsError();

    const instanceTypesResponse: FlowInstanceTypeListResponseModel = useSelector(instanceTypesSelectors.getResponse);

    /**
     * Local State
     */
    const [tabularSummary, setTabularSummary] = useState<TabularDataSummary | null>(null);
    const [artifactContentType, setArtifactContentType] = useState<artifactContentTypes | null>(null);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [artifactLoading, setArtifactLoading] = useState(false);
    const [basicInformation, setBasicInformation] = useState<BasicInformationType>({
        title: '',
        description: '',
        artifact: null,
        optionalArtifact: null,
    });
    const [flowDetailsResponse, setFlowDetailsResponse] = useState<FlowDetailsResponseModel | null>(null);
    const [errors, setErrors] = useState<any>({});

    /**
     * Effects
     */
    useEffect(() => {
        if (!instanceTypesResponse.models.length) {
            dispatch(instanceTypesActions.request());
        }
    }, [accountId]);

    useEffect(() => {
        isEditing && setDetailsLoading(true);
        if (isEditing) {
            fetchFlowDetails(params?.id);
        }
    }, [accountId, isEditing]);

    const checkArtifactContentType = async id => {
        try {
            setArtifactLoading(true);
            const {
                model: { artifactContentTypeId },
            }: ArtifactDetailsResponseModel = await axios.get(`/artifact?${getFormattedQuery({ id })}`);

            setArtifactContentType(artifactContentTypeId);

            if (artifactContentTypeId === artifactContentTypes.tabular) {
                await fetchInitialTabularSummary(id);
            }
        } catch (err: any) {
        } finally {
            setArtifactLoading(false);
        }
    };
    const fetchInitialTabularSummary = async artifactId => {
        try {
            setTabularSummary(null);
            const summary: TabularDataSummary = await axios.post('/summary/tabular', {
                accountId,
                artifactId,
            });
            setTabularSummary(summary);
        } catch (err: any) {}
    };
    const onSuccess = useCallback(
        (edited: boolean, runImmediately: boolean) => {
            toast.success(
                `${isEditing || edited ? 'Flow has been updated' : 'New Flow has been created'}${
                    runImmediately ? ' and started running' : ''
                }!`
            );
            history.push(FEATURE_ENGINEERING_PATH + queryParams.formatForNavigation());
        },
        [history, location]
    );

    const onValidateBasicInformation = () => {
        let isValid = true;
        let _errors: any = {};

        const { title, artifact } = basicInformation;
        if (!title) {
            _errors.title = 'Title is required!';
            isValid = false;
        }

        if (!artifact) {
            _errors.artifact = 'Artifact is required!';
            isValid = false;
        }

        setErrors(_errors);
        return isValid;
    };

    const fetchFlowDetails = async (id: number) => {
        try {
            setDetailsLoading(true);
            resetError();
            const _flowDetailsResponse: FlowDetailsResponseModel = await axios.get(
                `/flow?${getFormattedQuery({ id })}`
            );
            const { description, title, inputArtifactId, optionalArtifactId } = _flowDetailsResponse.model;
            const artifactQuery = {
                id: inputArtifactId,
            };
            const {
                model: { title: label, id: value, artifactContentTypeId },
            }: ArtifactDetailsResponseModel = await axios.get(`/artifact?${getFormattedQuery(artifactQuery)}`);
            const artifact = { label, value: value?.toString() };

            setBasicInformation({
                artifact,
                description,
                title,
                optionalArtifact: optionalArtifactId?.toString(),
            });
            setArtifactContentType(artifactContentTypeId);
            setFlowDetailsResponse(_flowDetailsResponse);
        } catch (err: any) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onChangeArtifact = async item => {
        checkArtifactContentType(item?.value);
        onChangeBasicInformation('artifact', item);
    };

    const onChangeBasicInformation = (key, value) => {
        deleteErrorKey(key, setErrors);
        setBasicInformation(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const onCancel = useCallback(() => {
        history.goBack();
    }, [history]);

    if (isEditing && detailsError.status === 404) {
        return <Error404 model="flow" />;
    }

    return (
        <>
            {detailsLoading ? (
                <div className="w-full flex justify-center items-center flex-1">
                    <Loader size={40} />
                </div>
            ) : (
                <PageAnimationContainer>
                    <div className="flex flex-col items-center">
                        <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                        <div
                            className="flex flex-col"
                            style={{
                                minWidth: currentWidth < 900 ? '100%' : 546,
                                width: 'auto',
                            }}
                        >
                            <InputField
                                form={formId}
                                label={t('@common.title')}
                                name="title-flow"
                                id="title-flow"
                                required
                                value={basicInformation.title}
                                onChange={e => onChangeBasicInformation('title', e.target.value)}
                                placeholder={t('@common.write.here')}
                                errorMessage={errors?.title}
                            />
                            <TextareaField
                                form={formId}
                                className="mt-4"
                                label={t('@common.description')}
                                onChange={e => onChangeBasicInformation('description', e.target.value)}
                                value={basicInformation.description}
                                placeholder={t('@common.enter-description-here')}
                            />
                            <SelectWithPopupField
                                required
                                label={t('@common.artifact')}
                                placeholder={t('@common.choose-artifact')}
                                disabled={isEditing}
                                onChangeItem={value => {
                                    onChangeArtifact(value);
                                }}
                                selectedItem={basicInformation.artifact}
                                errorMessage={errors?.artifact}
                                tableType={SelectWithPopupTypes.artifact}
                                className="mt-4"
                            />
                            {artifactContentType === artifactContentTypes.textual && (
                                <SelectWithPopupField
                                    label={t('@common.optional-artifact')}
                                    isClearable
                                    placeholder={t('@common.choose-artifact')}
                                    fetchDataOnMount
                                    query={{ ...tabularQueryArtifacts }}
                                    disableFilters
                                    onChangeItem={value => {
                                        onChangeBasicInformation('optionalArtifact', value);
                                    }}
                                    selectedItem={
                                        typeof basicInformation.optionalArtifact === 'object'
                                            ? basicInformation.optionalArtifact
                                            : null
                                    }
                                    selectedId={
                                        typeof basicInformation.optionalArtifact !== 'object'
                                            ? basicInformation.optionalArtifact
                                            : undefined
                                    }
                                    errorMessage={errors['optionalArtifact']}
                                    tableType={SelectWithPopupTypes.artifact}
                                    className="mt-4"
                                />
                            )}
                        </div>

                        {artifactLoading && (
                            <div className="w-full flex justify-center items-center p-10 mt-4">
                                <Loader size={40} />
                            </div>
                        )}
                        {artifactContentType === artifactContentTypes.tabular &&
                            !detailsLoading &&
                            !artifactLoading && (
                                <CreateTabularFlow
                                    onSuccess={onSuccess}
                                    onCancel={onCancel}
                                    formId={formId}
                                    basicInformation={basicInformation}
                                    initialTabularSummary={tabularSummary}
                                    flowEditResponseModel={flowDetailsResponse}
                                    onValidateBasicInformation={onValidateBasicInformation}
                                />
                            )}

                        {artifactContentType === artifactContentTypes.textual &&
                            !detailsLoading &&
                            !artifactLoading && (
                                <CreateTextualFlow
                                    basicInformation={basicInformation}
                                    flowEditResponseModel={flowDetailsResponse}
                                    formId={formId}
                                    onCancel={onCancel}
                                    onSuccess={onSuccess}
                                    onValidateBasicInformation={onValidateBasicInformation}
                                />
                            )}
                    </div>
                </PageAnimationContainer>
            )}
        </>
    );
};
