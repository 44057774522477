import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Button } from '../../../../components/buttons';
import { InputField } from '../../../../components/inputs';
import { Loader } from '../../../../components/ui';

interface CreateNewFolderComponentProps {
    onCreateFolder: (name: string) => Promise<boolean>;
    className?: string;
}

const CREATING_KEY = 'create-folder';
const NOT_CREATING_KEY = 'not-creating';
const folderNameRegex: RegExp = /^[a-zA-Zа-яА-Я0-9_-]+$/;

export const CreateNewFolderComponent: React.FC<CreateNewFolderComponentProps> = ({ onCreateFolder, className }) => {
    const { t } = useTranslation();

    const [isCreating, setIsCreating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [input, setInput] = useState({
        value: '',
        error: '',
    });

    const onSubmit = async () => {
        const { error, value } = input;

        if (value.length < 1 || !!error) {
            return;
        }

        setIsLoading(true);
        let response = await onCreateFolder(value);
        setIsLoading(false);
        if (response) {
            setInput({ value: '', error: '' });
            setIsCreating(false);
        }
    };

    const onChangeInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        const isValid = value.length ? folderNameRegex.test(value) : true;
        setInput({
            error: isValid ? '' : t('@folder.invalid', { defaultValue: 'Folder name is not valid!' }),
            value,
        });
    };

    const onKeyPress = event => {
        let code = event.keyCode || event.which;
        code === 13 && onSubmit();
    };

    return (
        <div className={className} style={{ maxWidth: 160, minWidth: 160 }}>
            <SwitchTransition>
                <CSSTransition
                    key={isCreating ? CREATING_KEY : NOT_CREATING_KEY}
                    addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                    classNames="switchTop"
                >
                    {isCreating ? (
                        <div className="flex items-center flex-none">
                            <InputField
                                placeholder={t('@common.write.here', { defaultValue: 'Write here...' })}
                                autoFocus
                                hideLabel
                                disabled={isLoading}
                                maxLength={250}
                                onChange={onChangeInput}
                                value={input.value}
                                onBlur={() => !isLoading && setIsCreating(false)}
                                errorMessage={input?.error}
                                onKeyPress={onKeyPress}
                            />
                            {isLoading && <Loader size={28} className="ml-2" />}
                        </div>
                    ) : (
                        <Button
                            onClick={() => {
                                setIsCreating(true);
                            }}
                            className="!text-greyBlack300 w-full"
                            title={t('@new.folder', { defaultValue: 'New Folder' })}
                            icon={
                                <div className="mr-2">
                                    <GjirafaIcon name="Plus" size="18"/>
                                </div>
                            }
                        />
                    )}
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
};
