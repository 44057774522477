import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CommonTableProps } from '.';
import { breakpoints, DATA_SOURCES_PATH } from '../../constants';
import { useAccountId, useCurrentWidth } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { DataSourceListModel, DataSourceListResponseModel, SelectOptionType } from '../../models';
import { CreateDataSourceReaderButton } from '../../pages/data/data-sources/components';
import { actions as dataSourcesActions, selectors } from '../../redux/thunk/app/dataSource/dataSourcesThunk';
import {
    actions as dataSourceTypesActions,
    selectors as dataSourceTypeSelectors,
} from '../../redux/thunk/app/dataSource/dataSourceTypesThunk';
import { formatArrayForSelectOptions, getWidth, showFiltersHeader } from '../../utils';
import { IconTypes } from '../common';
import { SelectField } from '../inputs';
import { CreateDataSourceModal, CreateDataSourceReaderModal } from '../modals';
import { toast } from '../ui';
import { NoDataImagesType, TableActionRowProps } from './components';
import { TableFilters } from './components/TableFilters';
import { Table } from './Table';
import { TableMobile } from './TableMobile';

export interface DataSourceTableProps extends CommonTableProps {}

export const DataSourceTable: FC<DataSourceTableProps> = ({
    fetchDataOnMount,
    isFromSelectPopup,
    tablePopupProps,
    className,
    disableFilters,
    query,
    hideFilters,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const history = useHistory();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const dispatch = useDispatch();

    /**
     * toggle visibility
     */
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });
    const [isReaderModalVisible, shouldRenderReaderModal, onToggleReaderModal] = useToggleVisibility({});

    /**
     * Redux Selectors
     */
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);
    const response: DataSourceListResponseModel = useSelector(selectors.getResponse);
    const dataSourceTypeResponse = useSelector(dataSourceTypeSelectors.getResponse);

    /**
     * Local State
     */
    const [selectedIdForEdit, setSelectedIdForEdit] = useState<number | null>(null);
    const [typeSelected, setTypeSelected] = useState<SelectOptionType | null>(null);
    const [selectedDataSource, setSelectedDataSource] = useState<SelectOptionType | null>();

    const requestDataSources = (payload, resetQuery?: boolean) => {
        dispatch(dataSourcesActions.request({ accountId, ...payload }, resetQuery));
    };

    useEffect(() => {
        dispatch(dataSourceTypesActions.request());
        if (fetchDataOnMount) {
            requestDataSources({ page: 0, searchQuery: '', ...query });
        }
    }, [accountId]);

    useEffect(() => {
        if (response.query?.dataSourceTypeId) setTypeSelected(response.query.dataSourceTypeId);
        else setTypeSelected(null);
    }, [response.query]);

    const onPageChanged = page => requestDataSources({ page });

    const onClickCreate = () => onToggleCreateModal(true);

    const onClickEdit = row => {
        onToggleCreateModal(true);
        setSelectedIdForEdit(row?.id);
    };

    const onClickReaderFromDataSource = (row: DataSourceListModel) => {
        setSelectedDataSource({
            label: row.name,
            value: row.id?.toString(),
        });
        onToggleReaderModal(true);
    };

    const onSuccessCreated = (dataSource, edited) => {
        toast.success(
            edited
                ? t('@success.edit.dataSource', { defaultValue: 'Data source has been edited!' })
                : t('@success.create.dataSource', { defaultValue: 'New Data Source has been created!' })
        );
        requestDataSources({ page: 0, dataSourceTypeId: null });
        onSelectRow(dataSource);
    };

    const onSuccessCreatedReader = id => {
        toast.success(t('@success.create.dataSourceReader', { defaultValue: 'Data Source Reader has been created!' }));
        setTimeout(() => {
            history.push(`/data/data-sources/details/${id}${window.location.search}`);
        }, 450);
    };

    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : response?.currentPage || 0;
        !loading && requestDataSources({ page });
    };

    const onSelectRow = (row: DataSourceListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.name,
                value: row.id.toString(),
                ...row,
            });
        }
    };

    const onRowClicked = (row: DataSourceListModel) => {
        history.push(`${DATA_SOURCES_PATH}/details/${row.id}${window.location.search}`);
    };

    const onChangeType = (item: SelectOptionType | null) => {
        setTypeSelected(item);
        requestDataSources({ page: 0, dataSourceTypeId: item });
    };

    const onSearch = searchQuery => requestDataSources({ searchQuery, page: 0 });

    /**
     * Memo hooks
     */
    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.data-sources.no-data-sources'),
            actionMessage: `+ ${t('@pages.data-sources.create-new-data-source')}`,
            imageType: NoDataImagesType.DataSource,
            onClickAction: onClickCreate,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            totalPages: response.totalPages,
            currentPage: response.currentPage,
            pageItems: response.models.length,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );
    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'details',
                displayName: t('@view.details', { defaultValue: 'View details' }),
                onClick: onRowClicked,
            },
            {
                action: 'edit',
                onClick: onClickEdit,
            },
            {
                action: IconTypes.dataSourceReader,
                displayName: 'Read from this data source',
                onClick: onClickReaderFromDataSource,
            },
            {
                action: 'delete',
            },
        ],
        []
    );

    const commonTableProps = {
        selectableRows: !isFromSelectPopup,
        modelEndpoint: 'dataSource',
        requestList: requestDataSources,
        showHeaderButtons: true,
        isFromSelectPopup,
        emptyDataProps,
        response,
        loading,
    };
    return (
        <div className={`flex flex-col ${className}`}>
            {shouldRenderCreateModal && (
                <CreateDataSourceModal
                    isVisible={isCreateModalVisible}
                    onHide={() => {
                        onToggleCreateModal(false);
                        setSelectedIdForEdit(null);
                    }}
                    onSuccess={onSuccessCreated}
                    isEditing={!!selectedIdForEdit}
                    dataSourceId={selectedIdForEdit}
                />
            )}

            {shouldRenderReaderModal && (
                <CreateDataSourceReaderModal
                    isVisible={isReaderModalVisible}
                    disableDataSource
                    preSelectedDataSource={selectedDataSource ?? undefined}
                    onHide={() => {
                        onToggleReaderModal(false);
                        setSelectedDataSource(null);
                    }}
                    onSuccess={onSuccessCreatedReader}
                />
            )}

            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    headerButtonProps={{
                        onClickCreate,
                        onRefresh,
                        leftButtons: isFromSelectPopup ? undefined : (
                            <CreateDataSourceReaderButton isActive={response.models.length > 0} />
                        ),
                        leftButtonsAfterCreate: true,
                    }}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestDataSources({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            hideFilters={hideFilters}
                            onSearch={onSearch}
                        >
                            <SelectField
                                disabled={disableFilters}
                                label={t('@common.type')}
                                placeholder={t('@components.modals.choose-data-source-type')}
                                onChange={onChangeType}
                                style={{ width: 240 }}
                                className="m-2"
                                isClearable
                                value={typeSelected}
                                options={formatArrayForSelectOptions(dataSourceTypeResponse.models)}
                            />
                        </TableFilters>
                    }
                    highlightOnHover
                    pagination
                    selectedRow={tablePopupProps && tablePopupProps.selectedItem}
                    onRowClicked={isFromSelectPopup ? onSelectRow : onRowClicked}
                    paginationProps={paginationProps}
                    showHeader={false}
                    columns={[
                        {
                            selector: row => row?.name ?? '',
                            cell: row => <span>{row?.name}</span>,
                            name: t('@common.name'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.dataSourceType ?? '',
                            name: t('@common.type'),
                            sortable: true,
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={isFromSelectPopup ? [] : tableActions}
                />
            )}

            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    headerButtonProps={{
                        onClickCreate,
                        onRefresh,
                    }}
                    mainKeyProperty="name"
                    initialLoading={initLoading}
                    onClickItem={onSelectRow}
                    selectedItem={tablePopupProps && tablePopupProps.selectedItem}
                    columns={[
                        {
                            selector: row => row?.name ?? '',
                            name: t('@common.name'),
                        },
                        {
                            selector: row => row?.dataSourceType ?? '',
                            name: t('@common.type'),
                        },
                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                        },
                    ]}
                    actions={isFromSelectPopup ? [] : tableActions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
