import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { OutlineButton } from '../../../../components/buttons';
import { Icon, IconTypes, SingleDynamicInput } from '../../../../components/common';
import { useCurrentWidth } from '../../../../hooks';
import { BaseEntityProperty } from '../../../../models';
import { splitArrayIntoChunks } from '../../../../utils';
import { TextualFlowStepStateType } from './CreateTextualFlow';

export interface TextualFlowStepProps {
    style?: CSSProperties;
    className?: string;
    title: string;
    inputs: BaseEntityProperty[];
    onChangeInputs: (key: string, e: any) => any;
    errors?: any;
    handleCreateOption?: (inputName: string, value: string) => any;
    isLastItem?: boolean;
    isRepeatable?: boolean;
    onDeleteInputs?: (_otherStepId: number) => any;
    onDuplicateInputs?: () => any;
    otherSteps?: TextualFlowStepStateType[];
    onChangeOtherStepsInputs?: (_otherStepId: number, key: string, value: string) => any;
}
export const TextualFlowStep: React.FC<TextualFlowStepProps> = ({
    errors,
    handleCreateOption,
    onChangeInputs,
    inputs,
    className,
    title,
    isLastItem,
    style,
    isRepeatable,
    onDeleteInputs,
    onDuplicateInputs,
    onChangeOtherStepsInputs,
    otherSteps,
}) => {
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();

    const renderInputs = (_inputs = inputs, _onChangeInputs = onChangeInputs) => {
        let chunkedArray = splitArrayIntoChunks(_inputs, 2);
        return chunkedArray?.map((el, i) => {
            const [firstInput, secondInput]: [BaseEntityProperty, BaseEntityProperty] = el;

            const commonProps = {
                properties: _inputs || [],
                className: 'sm:mr-2',
                errors,
                handleCreateOption,
                onChangeInput: _onChangeInputs,
            };

            return (
                <div key={i} className="flex flex-col sm:flex-row justify-between w-full items-end">
                    {el.length > 1 ? (
                        <>
                            <SingleDynamicInput {...commonProps} property={firstInput} />
                            <SingleDynamicInput {...commonProps} property={secondInput} />
                        </>
                    ) : (
                        <SingleDynamicInput {...commonProps} property={firstInput} />
                    )}
                </div>
            );
        });
    };

    return (
        <div className={`flex flex-col items-center ${className}`} style={style}>
            <div
                className="flex flex-col h-auto rounded card-shadow bg-white"
                style={{
                    width: currentWidth < 1120 ? '100%' : 835,
                    maxWidth: '100%',
                }}
            >
                <div className="h-auto flex flex-row px-6 py-4 w-full border-b border-mainBorder justify-between items-center">
                    <span className="text-blueMainText text-sm sm:text-base font-normal text-left">{title}</span>
                </div>
                {isRepeatable ? (
                    <div className="p-6">
                        {otherSteps?.map((stp, i) => (
                            <div key={stp._id + i} className={`flex w-full flex-row items-end ${i > 0 && 'mt-3'}`}>
                                <div className="flex flex-col">
                                    {renderInputs(
                                        stp.inputs,
                                        (key, val) =>
                                            onChangeOtherStepsInputs && onChangeOtherStepsInputs(stp._id, key, val)
                                    )}
                                </div>
                                <div className="flex flex-row items-center">
                                    {otherSteps.length > 1 && (
                                        <Icon
                                            name={IconTypes.delete}
                                            size={30}
                                            className="cursor-pointer"
                                            onClick={() => onDeleteInputs && onDeleteInputs(stp._id)}
                                        />
                                    )}
                                    {otherSteps.length - 1 === i && (
                                        <OutlineButton
                                            icon={<Icon className="mr-1" name={IconTypes['plus-main-color']} />}
                                            title={t('@common.add')}
                                            className="ml-2"
                                            onClick={() => onDuplicateInputs && onDuplicateInputs()}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                        {(!otherSteps || otherSteps?.length < 1) && (
                            <OutlineButton
                                title={t('@common.add')}
                                className="ml-2"
                                onClick={() => onDuplicateInputs && onDuplicateInputs()}
                            />
                        )}
                    </div>
                ) : (
                    <div className={`p-6 flex w-full flex-col`}>{renderInputs()}</div>
                )}
            </div>
            {!isLastItem && (
                <div
                    style={{
                        height: 24,
                        width: 1,
                        marginTop: 1,
                        border: '1px dashed #A3B0C2',
                        transform: 'rotate(180deg)',
                    }}
                />
            )}
        </div>
    );
};
