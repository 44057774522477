import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import { Bisko403ErrorAlert } from '../../../components/bisko';
import { InformationCard } from '../../../components/cards';
import { Loader } from '../../../components/ui';
import { ACCOUNT_SETTINGS_PATH, QueryParamsKeys } from '../../../constants';
import { AccountDetailsResponseModel, StateErrorType } from '../../../models';
import { selectors as accountDetailsSelectors } from '../../../redux/thunk/app/account/accountDetailsThunk';
import { selectors as biskoSegmentsSelectors } from '../../../redux/thunk/bisko/biskoSegmentsThunk';
import { queryParams } from '../../../utils';
import { settingsTabs } from '../../settings';
import { LookalikeEngineTable, LookalikeInformation } from './components';

export const LookalikeEnginePage = () => {
    const { t } = useTranslation();

    const accountLoading = useSelector(accountDetailsSelectors.getIsLoading);
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const biskoSegmentsLoading = useSelector(biskoSegmentsSelectors.getIsLoading);
    const biskoSegmentsError: StateErrorType = useSelector(biskoSegmentsSelectors.getError);

    const renderTable = () => {
        if (biskoSegmentsLoading || accountLoading)
            return (
                <div className="flex w-full justify-center items-center p-10">
                    <Loader />
                </div>
            );

        if (biskoSegmentsError?.status === 404)
            return (
                <div className="flex w-full flex-col md:flex-row">
                    <div className="flex flex-1 md:mr-5" />
                    <span className=" flex-1 md:ml-5 text-deleteColor text-sm font-normal mt-2 whitespace-pre-wrap pb-10">
                        {t('@to.use.lookalikeEngine.add.bisko.integration', {
                            defaultValue: 'To use Lookalike engine you should Add a Bisko Integration!',
                        })}
                        {'\n'}
                        {accountDetails.isAdmin && (
                            <Link
                                to={{
                                    pathname: ACCOUNT_SETTINGS_PATH,
                                    search: queryParams.appendNewKey(
                                        QueryParamsKeys.tab_key,
                                        settingsTabs.accountIntegrations
                                    ),
                                }}
                                className="text-mainBlue font-medium mt-1 underline cursor-pointer"
                            >
                                {t('@go.to.integrationsTab', { defaultValue: 'Go to Integrations Tab' })}
                            </Link>
                        )}
                    </span>
                </div>
            );

        if (biskoSegmentsError?.status === 403)
            return (
                <div className="flex w-full flex-col md:flex-row">
                    <div className="flex flex-1 md:mr-5" />
                    <span className=" flex-1 md:ml-5 text-deleteColor text-sm font-normal mt-2 whitespace-pre-wrap pb-10">
                        {t('@error.bisko.user.access', {
                            defaultValue: `Bisko Error: You don't have access to bisko organization!`,
                        })}
                    </span>
                </div>
            );
        return <LookalikeEngineTable />;
    };

    return (
        <PageAnimationContainer>
            <div className="w-full relative">
                <Bisko403ErrorAlert className="mb-4" error={biskoSegmentsError} />
                <InformationCard title="Lookalike Engine" showBackButton={false} style={{ height: 'auto', flex: 1 }}>
                    <div className="flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 w-full">
                        <LookalikeInformation />
                        {renderTable()}
                    </div>
                </InformationCard>
            </div>
        </PageAnimationContainer>
    );
};
