import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RefreshButton } from '../../../../../components/buttons/RefreshButton';
import { RunStatusComponent } from '../../../../../components/common';
import { NoDataImagesType, Table, TableFilters } from '../../../../../components/tables';
import { PIPELINES_PATH } from '../../../../../constants';
import { useAccountId } from '../../../../../hooks';
import { PipelineJobRunListModel, PipelineJobRunListResponseModel } from '../../../../../models';
import { actions, selectors } from '../../../../../redux/thunk/app/pipeline-job/pipelineJobRunsThunk';
import { queryParams, showFiltersHeader } from '../../../../../utils';

interface PipelineJobRunsTabProps {
    pipelineJobId: number;
}

export const PipelineJobRunsTab: React.FC<PipelineJobRunsTabProps> = ({ pipelineJobId }) => {
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const { t } = useTranslation();
    const history = useHistory();

    /**
     * Selectors
     */
    const response: PipelineJobRunListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);

    const requestList = (payload: object = {}, reset?: boolean) => {
        dispatch(actions.request({ accountId, pipelineJobId, ...payload }, reset));
    };

    useEffect(() => {
        if (pipelineJobId) {
            requestList({ pipelineJobId });
        }
    }, [accountId, pipelineJobId]);

    const onPageChanged = page => requestList({ page });

    const onSelectRow = (row: PipelineJobRunListModel) => {
        history.push(`${PIPELINES_PATH}/run-details/${row.id}${queryParams.formatForNavigation()}`);
    };

    return (
        <>
            {!!pipelineJobId && (
                <Table
                    loading={loading}
                    subHeader={showFiltersHeader(response)}
                    highlightOnHover={true}
                    headerComponent={
                        <TableFilters
                            onSearch={searchQuery => requestList({ searchQuery, page: 0 })}
                            response={response}
                            className="flex-row-reverse justify-between"
                        >
                            <div className="flex flex-1 justify-end">
                                <RefreshButton onRefresh={() => requestList({})} />
                            </div>
                        </TableFilters>
                    }
                    className="shadow-none border border-mainBorder rounded"
                    columns={[
                        {
                            selector: row => row?.message,
                            name: t('@message', { defaultValue: 'Message' }),
                            grow: 2,
                            cell: (row: PipelineJobRunListModel) => (
                                <div className="flex justify-start items-center items-center">
                                    <RunStatusComponent
                                        row={row}
                                        runStatusKey="status"
                                        runDurationKey="duration"
                                        style={{
                                            maxWidth: 50,
                                        }}
                                    />
                                    <span style={{whiteSpace: 'nowrap'}} className="ml-1" >{row.message || row.status}</span>
                                </div>
                            ),
                        },

                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                            cell: row => <span>{row?.lastModified}</span>,
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={[
                        {
                            action: 'details',
                            displayName: t('@view.details', { defaultValue: 'View details' }),
                            onClick: onSelectRow,
                        },
                    ]}
                    emptyDataProps={{
                        actionMessage: '',
                        emptyText: t('@no.pipelineJobRuns.found', {
                            defaultValue: 'There are no Runs yet for this PipelineJob',
                        }),
                        imageType: NoDataImagesType.PipelineJobRun,
                    }}
                    data={response.models}
                    response={response}
                    modelEndpoint="pipeline/jobRun"
                    isFromSelectPopup={false}
                    showHeaderButtons={false}
                    showHeader={false}
                    selectableRows={false}
                    onRowClicked={onSelectRow}
                    pagination
                    paginationProps={{
                        currentPage: response.currentPage || 0,
                        pageItems: response.models.length,
                        totalPages: response.totalPages,
                        totalItems: response.totalItems,
                        onChangePage: onPageChanged,
                    }}
                />
            )}
        </>
    );
};
