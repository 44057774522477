import { useTranslation } from 'react-i18next';
import { AlertInfoComponent } from '../components/alerts';
import { PageAnimationContainer } from '../components/animation/PageAnimationContainer';

export const ComingSoonPage = () => {
    const { t } = useTranslation();

    return (
        <PageAnimationContainer>
            <div className="flex flex-col flex-1 h-full">
                <AlertInfoComponent
                    text={t('this.page.will.be.available.soon')}
                    className="!bg-mainBlue/20"
                    textClassName="text-sm"
                    textStyle={{ color: '#1D79F2' }}
                />
            </div>
        </PageAnimationContainer>
    );
};
