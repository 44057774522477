import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
/**
 * Components
 */
import { FooterButtons, HeaderForCreatePages } from '../../../components/common';
import { InputField, SelectField } from '../../../components/inputs';
import { toast } from '../../../components/ui';
import { BASE_PROPENSITY_PATH, NOT_FOUND_PATH } from '../../../constants';
import { useAccountId, useBiskoIntegrationError, useCurrentWidth } from '../../../hooks';
import {
    BiskoPropertyListDataModel,
    PropensityEngineProductTypeListResponseModel,
    SelectOptionType,
} from '../../../models';
import {
    actions as productTypesActions,
    selectors as productTypesSelectors,
} from '../../../redux/thunk/app/engines/propensity/propensityProductTypesThunk';
import { selectors as biskoPropertiesSelectors } from '../../../redux/thunk/bisko/biskoPropertiesThunk';
import axios from '../../../services/axios';
import { deleteErrorKey, formatArrayForSelectOptions, queryParams, upperCaseFirstCharacter } from '../../../utils';

type FieldValuesType = {
    title: string;
    property: SelectOptionType | null;
    productType: SelectOptionType | null;
};

export const CreatePropensityEnginePage = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const location = useLocation();

    useBiskoIntegrationError({ path: `${BASE_PROPENSITY_PATH}/${params?.type}`, selectors: biskoPropertiesSelectors });

    /**
     * Selectors
     */
    const biskoPropertiesLoading = useSelector(biskoPropertiesSelectors.getIsLoading);
    const biskoPropertiesResponse: BiskoPropertyListDataModel = useSelector(biskoPropertiesSelectors.getData);
    const productTypesLoading = useSelector(productTypesSelectors.getIsLoading);
    const productTypesResponse: PropensityEngineProductTypeListResponseModel = useSelector(
        productTypesSelectors.getResponse
    );

    /**
     * constants
     */
    const isEditing = params?.id;
    const biskoPropertiesOptions: SelectOptionType[] = formatArrayForSelectOptions(
        biskoPropertiesResponse.properties,
        'name',
        'id'
    );
    const productTypesOptions: SelectOptionType[] = formatArrayForSelectOptions(
        productTypesResponse.models,
        'name',
        'id'
    );

    /**
     * Local State
     */
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [fieldValues, setFieldValues] = useState<FieldValuesType>({
        productType: null,
        property: null,
        title: '',
    });

    useEffect(() => {
        if (!params?.type || !location.pathname.includes(params?.type)) {
            history.push(NOT_FOUND_PATH + location.search);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (productTypesOptions.length < 1) {
            dispatch(productTypesActions.request());
        }
    }, []);

    const onChangeFieldValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const validateFields = () => {
        let isValid = true;
        let _errors: any = {};

        const { productType, property, title } = fieldValues;

        if (!productType) {
            _errors.productType = 'Product type is required!';
            isValid = false;
        }

        if (!title) {
            _errors.title = 'Title is required!';
            isValid = false;
        }

        if (!property) {
            _errors.property = 'Property is required!';
            isValid = false;
        }

        setErrors(_errors);
        return isValid;
    };

    const handleCancel = () => history.goBack();

    const handleSubmit = async e => {
        e.preventDefault();

        if (!validateFields() || formLoading) {
            return;
        }
        try {
            setFormLoading(true);
            let payload = {
                id: isEditing ? params?.id : 0,
                title: fieldValues.title,
                accountId,
                propertyId: fieldValues.property?.value,
                productTypeId: fieldValues.productType?.value,
                type: params?.type,
            };
            await axios.post(`/propensityEngine`, payload);
            toast.success(`${upperCaseFirstCharacter(params?.type)} Propensity engine has been created!`);
            history.push(`${BASE_PROPENSITY_PATH}/${params?.type}` + queryParams.formatForNavigation());
        } catch (err: any) {
            setFormLoading(false);
        }
    };

    const formId = 'createPropensityEngine';
    return (
        <PageAnimationContainer>
            <div className="flex flex-col">
                <form className="flex flex-col items-center" id={formId} onSubmit={handleSubmit}>
                    <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                    <div
                        className="flex flex-col"
                        style={{
                            minWidth: currentWidth < 900 ? '100%' : 546,
                            width: 'auto',
                        }}
                    >
                        <InputField
                            disabled={formLoading}
                            label={t('@common.title')}
                            name={`title-${params?.type}`}
                            required
                            value={fieldValues.title}
                            onChange={e => onChangeFieldValues('title', e.target.value)}
                            placeholder={t('@common.write.here')}
                            errorMessage={errors['title']}
                        />
                    </div>

                    <HeaderForCreatePages hideIcon title="Data" className="mt-10" />
                    <div
                        className="flex flex-col"
                        style={{
                            minWidth: currentWidth < 900 ? '100%' : 546,
                            width: 'auto',
                        }}
                    >
                        <SelectField
                            required
                            disabled={formLoading}
                            isLoading={biskoPropertiesLoading}
                            errorMessage={errors?.property}
                            options={biskoPropertiesOptions}
                            value={fieldValues.property}
                            onChange={value => onChangeFieldValues('property', value)}
                            label={t('@pages.bisko-properties')}
                            placeholder={`${t('@common.choose-a') + ' Property'}`}
                        />
                        <SelectField
                            required
                            disabled={formLoading}
                            isLoading={productTypesLoading}
                            errorMessage={errors?.productType}
                            options={productTypesOptions}
                            value={fieldValues.productType}
                            onChange={value => onChangeFieldValues('productType', value)}
                            label={t('@common.productType')}
                            placeholder={t('@common.choose-productType')}
                            className="mt-4"
                        />
                    </div>

                    <HeaderForCreatePages title="" hideIcon className="mt-10" />
                    <FooterButtons
                        hideSave
                        onClickCancel={handleCancel}
                        onClickThirdButton={handleSubmit}
                        thirdButtonProps={{
                            htmlType: 'submit',
                            loading: formLoading,
                            disabled: formLoading,
                        }}
                        thirdButtonTitle={t('@common.create')}
                        style={{
                            minWidth: currentWidth < 900 ? '100%' : 546,
                        }}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
