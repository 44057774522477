import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { Transition } from 'react-transition-group';
import { rotateAnimationStyles } from '../../../styles/animationStyles';

export interface MenuLinkArrowProps {
    animationIn: boolean;
    size?: number;
}

export const MenuLinkArrow: React.FC<MenuLinkArrowProps> = ({ animationIn, size = 20 }) => {
    return (
        <Transition in={animationIn} timeout={50} unmountOnExit={false}>
            {state => (
                <span
                    className="ml-auto"
                    style={{
                        transition: 'all linear',
                        transform: 'rotate(0deg)',
                        ...rotateAnimationStyles[state],
                    }}
                >
                    <GjirafaIcon name="ArrowUp" size={size} />
                </span>
            )}
        </Transition>
    );
};
