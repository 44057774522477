import { CSSProperties, useMemo } from 'react';
import { Icon } from '..';
import { Tooltip } from '../../ui';
import { RunDuration } from './RunDuration';

type RunStatusComponentProps = {
    row: any;
    showRunStatus?: boolean;
    showTooltip?: boolean;
    runStatusKey?: string;
    runDurationKey?: string;
    style?: CSSProperties;
    className?: string;
};

export const RunStatusComponent: React.FC<RunStatusComponentProps> = ({
    row,
    showRunStatus = true,
    showTooltip = true,
    runStatusKey,
    runDurationKey,
    style,
    className,
}) => {
    const { runStatus, runDuration } = useMemo(
        () => ({
            runStatus: row[runStatusKey || 'runStatus'],
            runDuration: row[runDurationKey || 'runDuration'] ?? '',
        }),
        [row]
    );

    const message = (runStatusKey ? row[runStatusKey] : row?.runStatus) || 'Pending';
    const name = (runStatusKey ? row[runStatusKey] : row?.runStatus) || 'Pending';

    if (!showRunStatus) {
        return (
            <span key={row?.id} className="w-full flex justify-center items-center">
                N/A
            </span>
        );
    }
    let tooltipId = `runStatusTooltip:${row?.title || row?.name || ''}:${row.id}`;

    return (
        <div
            key={row?.id}
            className={`w-full flex justify-center items-center ${className}`}
            style={{ ...style }}
            data-tip={showTooltip}
            data-for={showTooltip && tooltipId}
        >
            <Icon className="cursor-pointer mr-1" name={name} size={22} />
            {showTooltip && (
                <Tooltip id={tooltipId} className="min-w-auto border border-mainBlue" globalEventOff="hover">
                    <span className="whitespace-pre-wrap w-full text-center">
                        {message}
                        {runDuration ? (
                            <>
                                {'\n'}
                                <RunDuration runDuration={runDuration} runStatus={runStatus} />
                            </>
                        ) : (
                            ''
                        )}
                    </span>
                </Tooltip>
            )}
        </div>
    );
};
