import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SelectField } from '../../../components/inputs';
import { toast } from '../../../components/ui';
import { actionTypeOptions, ADMIN_CREATE_FLOW_ACTION_PATH } from '../../../constants';
import { FlowActionListModel, FlowActionListResponseModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/flow/flowActionsThunk';
import { AdminTable } from '../components';
import { EditOrderComponent } from './components/EditOrderComponent';

export const AdminFlowActionsPage = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * Redux Selectors
     */
    const response: FlowActionListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    const requestList = (query: any = {}) => {
        dispatch(actions.request({ ...query, isFromAdmin: true }));
    };
    useEffect(() => {
        requestList({ page: 0, actionType: null });
    }, []);

    const onRefresh = () => {
        !loading && requestList();
    };

    const onClickEdit = (row: FlowActionListModel) => {
        history.push(`/admin/flow-actions/edit-flow-action/${row?.id}`);
    };
    const onClickCreate = () => {
        history.push(ADMIN_CREATE_FLOW_ACTION_PATH);
    };

    return (
        <div className="">
            <AdminTable
                modelEndpoint="flowAction"
                tableFiltersChildren={
                    <div className="">
                        <SelectField
                            isClearable
                            label="Action type"
                            placeholder="Choose action type"
                            style={{ width: 240 }}
                            className="m-2"
                            onChange={item => {
                                requestList({ actionType: item, page: 0 });
                            }}
                            options={actionTypeOptions}
                        />
                    </div>
                }
                requestList={requestList}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row?.name ?? '',
                    },
                    {
                        name: 'Code',
                        selector: row => row?.code ?? '',
                    },
                    {
                        width: '150px',
                        name: 'Action Type',
                        selector: row => row?.actionType ?? '',
                        cell: row => (
                            <span className="justify-center flex w-full">
                                {actionTypeOptions.find(x => x.value === row.actionType?.toString())?.label}
                            </span>
                        ),
                    },
                ]}
                columnsBeforeActions={[
                    {
                        name: '',
                        selector: row => row?.orderNumber ?? '',
                        sortable: true,
                        width: '140px',
                        cell: (row: FlowActionListModel) => (
                            <EditOrderComponent
                                flowAction={row}
                                onSuccess={() => {
                                    onRefresh();
                                    toast.success('Order number has been changed!');
                                }}
                            />
                        ),
                    },
                ]}
                reduxActions={actions}
                loading={loading}
                response={response}
                initLoading={initLoading}
                onClickEdit={onClickEdit}
                onPageChanged={page => {
                    requestList({ page });
                }}
                headerButtonProps={{
                    onClickCreate,
                    onRefresh,
                }}
            />
        </div>
    );
};
