import { useEffect, useMemo, useState } from 'react';
import { AnimationComponent } from '../../../../components/animation';
import { RefreshButton } from '../../../../components/buttons/RefreshButton';
import { AreaChart } from '../../../../components/charts';
import { ApexPieChart } from '../../../../components/charts/ApexPieChart';
import { SelectField } from '../../../../components/inputs';
import { useAccountId } from '../../../../hooks';
import { Nullable, SelectOptionType } from '../../../../models';
import {
    CustomEndpointMetricsModel,
    CustomEndpointMetricsResponseModel,
} from '../../../../models/entity-models/api/custom-endpoint/custom-endpoint-metrics';
import axios from '../../../../services/axios';
import { getLastItem } from '../../../../utils';
import { RequestsPerCountry } from '../../../ai-engine/recommendation/details-tabs/metrics/components';
import {
    areaChartOptions,
    filterDaysOptions,
} from '../../../ai-engine/recommendation/details-tabs/metrics/RecommendationMetricsTab.constants';

interface ICustomEndpointMetricsTab {
    endpointId: number;
}

type TMetrics = {
    deviceRequests: [string, string | number][];
    browserRequests: [string, string | number][];
    geoRequests:
        | [
              {
                  v: string;
                  f: string;
              },
              number
          ][]
        | null;
    dailyRequests: Record<string, number> | null;
};

export const CustomEndpointMetricsTab = ({ endpointId }: ICustomEndpointMetricsTab) => {
    const accountId = useAccountId();

    const [metrics, setMetrics] = useState<CustomEndpointMetricsModel | null>(null);
    const [metricsLoading, setMetricsLoading] = useState(false);
    const [lastDays, setLastDays] = useState<Nullable<SelectOptionType>>(getLastItem(filterDaysOptions));

    useEffect(() => {
        fetchAllMetrics();
    }, [accountId, lastDays, endpointId]);

    const fetchAllMetrics = async () => {
        try {
            setMetricsLoading(true);
            const query = {
                days: lastDays?.value ?? '30',
                endpointId: endpointId?.toString(),
                accountId: accountId?.toString(),
            };
            const response: CustomEndpointMetricsResponseModel = await axios.get(
                `/analytics/customEndpoint?${new URLSearchParams(query)}`
            );
            setMetrics(response.model);
        } catch (err) {
        } finally {
            setMetricsLoading(false);
        }
    };

    const onRefresh = () => fetchAllMetrics();

    const onChangeLastDays = (item: Nullable<SelectOptionType>) => setLastDays(item);

    const { browserRequests, dailyRequests, deviceRequests, geoRequests }: TMetrics = useMemo(() => {
        if (metrics && !metricsLoading) {
            let dailyRequests: Record<string, number> = {};

            metrics.dailyRequests.forEach(item => (dailyRequests[item.partitionDate] = item.total));

            return {
                browserRequests: metrics.browserRequests.slice(0, 5).map(x => [x.browser, x.total]),
                deviceRequests: metrics.deviceRequests.slice(0, 5).map(x => [x.deviceType, x.total]),
                geoRequests: metrics.countryRequests.map(x => [
                    { v: x.countryCode ?? '', f: x.countryName ?? x.countryCode ?? '' },
                    x.total,
                ]),
                dailyRequests,
            };
        }

        return {
            browserRequests: [],
            dailyRequests: {},
            deviceRequests: [],
            geoRequests: [],
        };
    }, [metrics, metricsLoading]);

    return (
        <div className="w-full h-auto">
            <div className="flex justify-between items-end pb-1 ">
                <div className="flex pb-2 items-center">
                    <label className="text-secondaryText font-medium text-sm">Last</label>
                    <SelectField
                        hideLabel
                        placeholder="30"
                        className="ml-2"
                        style={{ width: 78 }}
                        value={lastDays}
                        onChange={onChangeLastDays}
                        options={filterDaysOptions}
                        noOptionsMessage={() => '...'}
                    />
                    <label className="text-blueMainText font-medium text-sm ml-2">Days</label>
                </div>
                <RefreshButton onRefresh={onRefresh} />
            </div>
            <AnimationComponent show type="fade-bottom" transform="transform-gpu" delay={100} duration={200}>
                <div className="border  border-mainBorder rounded flex flex-col w-full py-6 px-2 card-shadow pb-8">
                    <AreaChart
                        title="Requests per Day"
                        labelName="Requests"
                        options={areaChartOptions}
                        data={dailyRequests}
                        loading={metricsLoading}
                        noDataMessage="There are no requests for this Enpoint!"
                    />
                </div>
            </AnimationComponent>

            <AnimationComponent show type="fade-bottom" transform="transform-gpu" delay={200} duration={200}>
                <RequestsPerCountry
                    className="card-shadow"
                    loading={metricsLoading}
                    geoChartData={geoRequests ?? []}
                    noDataMessage="There are no requests for this Endpoint"
                />
            </AnimationComponent>

            <AnimationComponent show type="fade-bottom" transform="transform-gpu" delay={300} duration={200}>
                <div className="flex w-full flex-col lg:flex-row lg:justify-between">
                    <div className="flex bg-white border-mainBorder mt-8 border w-full rounded mr-0 lg:mr-2 lg:w-1/2 overflow-hidden card-shadow">
                        <ApexPieChart
                            loading={metricsLoading}
                            noDataMessage="There are no data yet!"
                            title="Browsers"
                            data={browserRequests}
                        />
                    </div>
                    <div className="flex bg-white border-mainBorder mt-4 border w-full rounded ml-0 lg:mt-8 lg:ml-2 lg:w-1/2 overflow-hidden card-shadow">
                        <ApexPieChart
                            loading={metricsLoading}
                            noDataMessage="There are no data yet!"
                            title="Devices"
                            data={deviceRequests}
                        />
                    </div>
                </div>
            </AnimationComponent>
        </div>
    );
};
