import {
    StripeCardCvcElementOptions,
    StripeCardExpiryElementOptions,
    StripeCardNumberElementOptions,
    StripeElementStyleVariant,
} from '@stripe/stripe-js';

export function useCardOptions() {
    const base: StripeElementStyleVariant = {
        color: '#052D61',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        ':focus': {
            color: '#052D61',
        },

        '::placeholder': {
            color: '#A3B0C2',
        },
    };
    const className = 'border border-mainBorder rounded h-10 flex flex-col justify-center pl-3 mt-1';
    const invalidClassName = 'border-mainError';
    const invalid = {
        color: '#E34850',
        ':focus': {
            color: '#E34850',
        },
        '::placeholder': {
            color: '#E34850',
        },
    };

    const cardNumberOptions: StripeCardNumberElementOptions = {
        style: {
            base,
            invalid,
        },
        classes: {
            base: className,
            invalid: invalidClassName,
        },
        placeholder: 'XXXX-XXXX-XXXX-XXXX',
    };
    const cardExpiryOptions: StripeCardExpiryElementOptions = {
        style: {
            base,
            invalid,
        },
        classes: {
            base: `${className} w-full mr-2`,
            invalid: invalidClassName,
        },
        placeholder: 'MM/YY',
    };
    const cardCvcOptions: StripeCardCvcElementOptions = {
        style: {
            base,
            invalid,
        },
        classes: {
            base: `${className} w-full ml-2`,
            invalid: invalidClassName,
        },
        placeholder: 'XXX',
    };

    return {
        cardNumberOptions,
        cardExpiryOptions,
        cardCvcOptions,
    };
}
