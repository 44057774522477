import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableActionRowProps, ToggleActionButton } from '../../../../components/tables';
import { Loader } from '../../../../components/ui';
import { StorageObjectListModel } from '../../../../models';
import { onDownloadFileFromUrl } from '../../../../utils';

interface StorageTableActionsProps {
    row: StorageObjectListModel;
    onClickRename: () => any;
    onClickMove: () => any;
    onClickRemove: () => any;
    onClickDownload: () => Promise<string>;
}

export const StorageTableActions: React.FC<StorageTableActionsProps> = ({
    onClickDownload,
    onClickMove,
    onClickRemove,
    onClickRename,
    row,
}) => {
    const { t } = useTranslation();
    const [actionLoading, setActionLoading] = useState<string>('');
    const [isOpen, setIsOpen] = useState(false);

    const _onDownload = async () => {
        try {
            setActionLoading('Download');
            let url = await onClickDownload();
            onDownloadFileFromUrl(url);
            setActionLoading('');
        } catch (err) {
            setActionLoading('');
        }
    };

    const actions = useMemo(() => {
        const _actions: TableActionRowProps[] = [
            {
                action: 'Paragraph',
                onClick: () => {
                    setIsOpen(false);
                    onClickRename();
                },
                displayName: t('@rename', { defaultValue: 'Rename' }),
            },
            {
                action: 'Reply',
                onClick: () => {
                    setIsOpen(false);
                    onClickMove();
                },
                displayName: t('@move', { defaultValue: 'Move' }),
            },
            {
                action: 'Delete',
                onClick: () => {
                    setIsOpen(false);
                    onClickRemove();
                },
                displayName: t('@remove', { defaultValue: 'Remove' }),
            },
        ];
        if (!row.isFolder) {
            _actions.push({
                action: 'Download',
                onClick: _onDownload,
                displayName: t('download'),
            });
        }
        return _actions;
    }, [t, row]);

    return (
        <ToggleActionButton
            isDropdownOpen={isOpen}
            setIsDropdownOpen={_open => {
                setIsOpen(_open);
            }}
        >
            {actions.map(_action => {
                const isLoading = actionLoading === _action.action;
                let _actionName = typeof _action.action === 'function' ? _action.action(row) : _action.action;
                let _displayName =
                    typeof _action.displayName === 'function' ? _action.displayName(row) : _action.displayName;

                return (
                    <div
                        key={_actionName}
                        onClick={() => !isLoading && _action.onClick && _action.onClick(null)}
                        className={`${
                            isLoading ? 'cursor-default opacity-90' : 'opacity-100  cursor-pointer'
                        } transition-colors hover:bg-mainBackground px-4 py-2 text-sm font-regular text-blueMainText flex flex-row items-center w-auto hover:text-mainBlue`}
                    >
                        <div className="pr-2">
                            <GjirafaIcon name={_actionName} size="18" />
                        </div>
                        {isLoading ? <Loader size={22} /> : _displayName}
                    </div>
                );
            })}
        </ToggleActionButton>
    );
};
