import { CSSProperties, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ReactComponent as NotFoundImage } from '../../assets/images/404.svg';
import {
    ARTICLE_RECOMMENDATION_ENGINE_PATH,
    ARTIFACTS_PATH,
    CUSTOM_ENDPOINTS_PATH,
    DATASETS_PATH,
    DATA_SOURCES_PATH,
    FEATURE_ENGINEERING_PATH,
    LOOKALIKE_ENGINE_PATH,
    MODELS_PATH,
    TASKS_PATH,
} from '../../constants';
import { queryParams } from '../../utils';
import { AnimationComponent } from '../animation';
import { OutlineButton } from '../buttons';

type TModels =
    | 'flow'
    | 'model'
    | 'dataset'
    | 'artifact'
    | 'dataSource'
    | 'recommendationEngine'
    | 'customEndpoint'
    | 'task'
    | 'lookalikeEngine'
    | 'pipelineJob';

type TErrorModels = Record<
    TModels,
    {
        title: string;
        description: ReactNode;
    }
>;
export interface Error404Props {
    model: TModels;
    className?: string;
    style?: CSSProperties;
    onClickButton?: () => any;
}

export const Error404: FC<Error404Props> = ({ model, style, className, onClickButton }) => {
    const history = useHistory();
    const { t } = useTranslation();

    let errorModels: TErrorModels = {
        flow: {
            title: t('@requested.flow.not.found', { defaultValue: 'Requested Flow not found!' }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(FEATURE_ENGINEERING_PATH + queryParams.formatForNavigation())
                    }
                    title={t('@go.to.flows', { defaultValue: 'Go to Flows' })}
                />
            ),
        },
        model: {
            title: t('@requested.model.not.found', {
                defaultValue: 'Requested Model not found!',
            }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton ? onClickButton() : history.push(MODELS_PATH + queryParams.formatForNavigation())
                    }
                    title={t('@go.to.models', { defaultValue: 'Go to Models' })}
                />
            ),
        },
        dataset: {
            title: t('@requested.dataset.not.found', { defaultValue: 'Requested Dataset not found!' }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(DATASETS_PATH + queryParams.formatForNavigation())
                    }
                    title={t('@go.to.datasets', { defaultValue: 'Go to Datasets' })}
                />
            ),
        },
        artifact: {
            title: t('@requested.artifact.not.found', { defaultValue: 'Requested Artifact not found!' }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(ARTIFACTS_PATH + queryParams.formatForNavigation())
                    }
                    title={t('@go.to.artifacts', { defaultValue: 'Go to Artifacts' })}
                />
            ),
        },
        dataSource: {
            title: t('@requested.dataSource.not.found', { defaultValue: 'Requested Data Source not found!' }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(DATA_SOURCES_PATH + queryParams.formatForNavigation())
                    }
                    title={t('@go.to.dataSources', { defaultValue: 'Go to Data Sources' })}
                />
            ),
        },
        recommendationEngine: {
            title: t('@requested.recommendationEngine.not.found', {
                defaultValue: 'Requested Recommendation Engine not found!',
            }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(ARTICLE_RECOMMENDATION_ENGINE_PATH + queryParams.formatForNavigation())
                    }
                    title={t('@go.to.recommendationEngines', { defaultValue: 'Go to Recommendation Engines' })}
                />
            ),
        },
        task: {
            title: t('@requested.task.not.found', {
                defaultValue: 'Requested Task not found!',
            }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton ? onClickButton() : history.push(TASKS_PATH + queryParams.formatForNavigation)
                    }
                    title={t('@go.to.task', { defaultValue: 'Go to Task' })}
                />
            ),
        },
        customEndpoint: {
            title: t('@requested.customEndpoint.not.found', {
                defaultValue: 'Requested Endpoint not found!',
            }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(CUSTOM_ENDPOINTS_PATH + queryParams.formatForNavigation)
                    }
                    title={t('@go.to.customEndpoints', { defaultValue: 'Go to Endpoints' })}
                />
            ),
        },
        lookalikeEngine: {
            title: t('@requested.lookalikeEngine.not.found', {
                defaultValue: 'Requested Lookalike Engine not found!',
            }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(LOOKALIKE_ENGINE_PATH + queryParams.formatForNavigation())
                    }
                    title={t('@go.to.lookalikeEngines', { defaultValue: 'Go to Lookalike Engines' })}
                />
            ),
        },
        pipelineJob: {
            title: t('@requested.pipelineJob.not.found', {
                defaultValue: 'Requested PipelineJob not found!',
            }),
            description: (
                <OutlineButton
                    onClick={() =>
                        onClickButton
                            ? onClickButton()
                            : history.push(CUSTOM_ENDPOINTS_PATH + queryParams.formatForNavigation)
                    }
                    title={t('@go.to.pipelineJobs', { defaultValue: 'Go to PipelineJobs' })}
                />
            ),
        },
    };

    return (
        <AnimationComponent show type="fade-in" duration={300}>
            <div
                style={style}
                className={`flex flex-1 w-full h-full flex-col items-center justify-center  rounded ${className}`}
            >
                <div className="flex flex-col items-center justify-center">
                    <div className="flex">
                        <NotFoundImage height="100%" width="100%" />
                    </div>
                    <span className="mt-4 text-blueMainText font-semibold">{errorModels[model].title}</span>
                    <span className="mt-4">{errorModels[model].description}</span>
                </div>
            </div>
        </AnimationComponent>
    );
};
