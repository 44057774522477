import { createContext } from 'react';
import { CustomEndpointDetailsModel } from '../models';

export interface ICustomEndpointDetailsContext {
    details: CustomEndpointDetailsModel | null;
    setDetails: React.Dispatch<React.SetStateAction<CustomEndpointDetailsModel | null>>;
}

export const customEndpointDetailsContext = createContext<ICustomEndpointDetailsContext>({
    details: null,
    setDetails: () => {},
});
