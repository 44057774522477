import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotificationIcon } from '../../../assets/icons/notification.svg';

interface INotificationEmptyListProps {
    className?: string;
    style?: CSSProperties;
}

export const NotificationEmptyList = ({ className, style }: INotificationEmptyListProps) => {
    const { t } = useTranslation();

    return (
        <div
            className={`flex flex-col w-full h-auto py-4 justify-center items-center bg-white ${className}`}
            style={style}
        >
            <div className="rounded-full h-14 w-14 justify-center items-center flex bg-greyWhite200">
                <NotificationIcon style={{ height: 30, width: 30 }} />
            </div>
            <span className="text-secondaryText text-sm font-normal mt-2">{t('notifications.empty')}</span>
        </div>
    );
};
