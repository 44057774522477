import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { register } from 'timeago.js';
import i18n from '../../../config/i18n';
import { NOTIFICATIONS_PATH } from '../../../constants';
import { useAccountId } from '../../../hooks';
import { NotificationListModel, NotificationListResponseModel, UserDetailsResponseModel } from '../../../models';
/**
 * Redux
 */
import { actions, selectors } from '../../../redux/thunk/app/notification/notificationsThunk';
import { actions as userActions, selectors as userSelector } from '../../../redux/thunk/app/user/userThunk';
import axios from '../../../services/axios';
import { onNotificationNavigate, queryParams } from '../../../utils';
/**
 * Components
 */
import { Icon, IconTypes } from '../../common';
import { Dropdown } from '../../dropdown';
import { Tooltip } from '../../ui';
import { CounterDisplay } from '../components';
import { NotificationActionsComponent } from './NotificationActionsComponent';
import { NotificationEmptyList } from './NotificationEmptyList';
import { NotificationItem } from './NotificationItem';
import { NotificationLoader } from './NotificationLoader';

export const NotificationDropdown = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const { t } = useTranslation();
    const history = useHistory();

    /**
     * Redux Selectors
     */
    const isLoading = useSelector(selectors.getIsLoading);
    const { models }: NotificationListResponseModel = useSelector(selectors.getResponse);
    const userResponse: UserDetailsResponseModel = useSelector(userSelector.getResponse);
    const isAnimating = useSelector(userSelector.getIsAnimating);
    useEffect(() => {
        (async () => {
            try {
                const timeAgoLocalizationKey = {
                    'en-US': 'en_US',
                    en: 'en_US',
                };

                const timeAgoResources = {
                    'en-US': require('timeago.js/lib/lang/en_US'),
                    sq: require('timeago.js/lib/lang/sq'),
                };

                const lang = i18n.language;
                const locale = timeAgoLocalizationKey[lang] ?? lang;
                if (locale) {
                    register(locale, timeAgoResources[locale].default);
                }
            } catch (err) {}
        })();
    }, [i18n.language]);

    const onClickReadNotification = async (notification: NotificationListModel) => {
        try {
            !notification.isRead && dispatch(actions.readNotification({ accountId, id: notification.id }));

            onNotificationNavigate({
                accountId: notification.notification.accountId?.toString(),
                dispatch,
                entity: notification.entity,
                history,
            });
        } catch (err: any) {}
    };

    const onResetNotificationCount = async () => {
        try {
            if (userResponse.model.notificationCount < 1) return;

            await axios.patch('/user', {
                notificationCountReset: true,
            });

            dispatch(userActions.resetNotificationCount());
        } catch (err: any) {}
    };

    const onClickSeeAllNotifications = () => {
        history.push(NOTIFICATIONS_PATH + queryParams.formatForNavigation());
    };

    const renderNotifications = () => {
        if (models.length < 1 && isLoading) {
            let tooltipId = 'notificationLoader';
            return (
                <div data-for={tooltipId} data-tip="Loading Notifications...">
                    <NotificationLoader className="mt-1" />
                    <NotificationLoader className="mt-1" />
                    <NotificationLoader className="mt-1" />
                    <Tooltip id={tooltipId} />
                </div>
            );
        }
        if (models.length < 1 && !isLoading) return <NotificationEmptyList />;

        return models.map(model => (
            <NotificationItem key={model.id} notification={model} onClick={() => onClickReadNotification(model)} />
        ));
    };

    const { notificationCount } = userResponse.model;
    return (
        <Dropdown
            wrapperClassName="ml-2 z-40"
            withArrow={false}
            _onClickTrigger={() => models.length > 0 && onResetNotificationCount()}
            triggerProps={{
                children: (
                    <div
                        className={`h-10 w-10 justify-center items-center flex mr-2 cursor-pointer bg-greyWhite200 hover:bg-grey200 rounded-full ${
                            isAnimating && 'animate-bounce'
                        }`}
                    >
                        <Icon name={IconTypes.notification} />
                        {notificationCount > 0 && (
                            <CounterDisplay number={notificationCount} className={isAnimating ? '-mr-2' : ''} />
                        )}
                    </div>
                ),
            }}
        >
            <div
                className="bg-white base-card-style w-72 sm:w-88 rounded-lg mt-2 flex flex-col dropdown-shadow"
                style={{ height: 440, overflowX: 'hidden', overflow: 'hidden' }}
            >
                <div className="flex flex-row justify-between items-center px-4 pt-4 pb-3">
                    <span className="text-base text-blueDark font-bold">{t('notifications')}</span>
                    <NotificationActionsComponent />
                </div>
                <div className="mx-4 border-t border-solid border-mainBorder h-0 rounded" />
                <div className="overflow-y-auto w-full p-3 overflow-x-hidden">{renderNotifications()}</div>
                <div
                    className="group cursor-pointer py-4 border-t justify-center items-center flex border-solid border-mainBorder rounded mt-auto"
                    onClick={onClickSeeAllNotifications}
                >
                    <p className="text-secondaryText text-sm group-hover:underline">
                        {t('@see.all.activity', { defaultValue: 'See all activity' })}
                    </p>
                </div>
            </div>
        </Dropdown>
    );
};
