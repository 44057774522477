import React, { useEffect, useState } from 'react';
import { SwitchInput } from '../../../components/inputs';
import { Loader } from '../../../components/ui';

interface ToggleActiveComponentProps {
    isActive: boolean;
    onToggleActivate: (value: boolean) => Promise<boolean>;
}

export const ToggleActiveComponent: React.FC<ToggleActiveComponentProps> = ({
    isActive: _isActive,
    onToggleActivate,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(_isActive);

    useEffect(() => {
        isActive !== _isActive && setIsActive(_isActive);
    }, [_isActive]);

    const onChange = async value => {
        setIsActive(value);
        setIsLoading(true);
        const success: boolean = await onToggleActivate(value);
        !success && setIsActive(_isActive);
        setIsLoading(false);
    };

    return (
        <div className="flex flex-row justify-start w-full items-center">
            <SwitchInput className="mr-2" disabled={isLoading} hideLabel value={isActive} onChange={onChange} />
            {isLoading && <Loader size={26} />}
        </div>
    );
};
