import { FC, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { CommonTableProps } from '.';
import { breakpoints, CREATE_MODEL_PATH, statusOptionTypes } from '../../constants';
import { useAccountId, useCurrentWidth } from '../../hooks';
import { SelectOptionType, WorkflowModelListModel, WorkflowModelListResponseModel } from '../../models';
import { actions as modelsActions, selectors as modelsSelectors } from '../../redux/thunk/app/model/modelsThunk';
import { formatArrayForSelectOptions, getWidth, showFiltersHeader } from '../../utils';
import { RunStatusComponent } from '../common';
import { SelectField, SelectWithPopupField, SelectWithPopupTypes } from '../inputs';
import { NoDataImagesType, TableActionRowProps, TableFilters } from './components';
import ModelRowExpandComponent from './components/expand-row-components/ModelRowExpandComponent';
/**
 * Components
 */
import { Table } from './Table';
import { TableMobile } from './TableMobile';

export interface ModelsTableProps extends CommonTableProps {
    actions?: TableActionRowProps[];
    mobileColumns?: TableColumn<any>[];
}

export const ModelsTable: FC<ModelsTableProps> = ({
    className,
    fetchDataOnMount = true,
    isFromSelectPopup,
    tablePopupProps,
    actions,
    disableFilters,
    hideFilters,
    query,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const location = useLocation();
    const currentWidth = useCurrentWidth();
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);

    /**
     * Redux Selectors
     */
    const response: WorkflowModelListResponseModel = useSelector(modelsSelectors.getResponse);
    const loading = useSelector(modelsSelectors.getIsLoading);
    const initLoading = useSelector(modelsSelectors.getInitialLoading);

    /**
     * Local State
     */
    const [algorithmSelected, setAlgorithmSelected] = useState<any>(null);

    const requestModels = (payload, resetQuery?: boolean) => {
        dispatch(modelsActions.request({ accountId, ...payload }, resetQuery));
    };

    useEffect(() => {
        if (isFromSelectPopup) requestModels({ page: 0, algorithmId: null, searchQuery: '', ...query });
        else fetchDataOnMount && requestModels({ page: 0, ...query });
    }, []);

    useEffect(() => {
        //For algorithm
        if (response.query?.algorithmId) {
            setAlgorithmSelected(response.query.algorithmId);
        } else {
            setAlgorithmSelected(null);
        }

        //For status
        if (response.query?.status) {
            setStatusTypeSelected(response.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [response.query]);

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestModels({ page: 0, status: status });
    };

    const onSelectRow = (row: WorkflowModelListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.title || '',
                value: row.id.toString(),
                ...row,
            });
        }
    };

    const onClickCreate = () => !isFromSelectPopup && history.push(CREATE_MODEL_PATH + location.search);

    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : response?.currentPage || 0;
        !loading && requestModels({ page });
    };
    const onPageChanged = page => requestModels({ page });

    const onChangeAlgorithm = (algorithm: SelectOptionType | null) => {
        setAlgorithmSelected(algorithm);
        requestModels({ page: 0, algorithmId: algorithm });
    };

    const { emptyDataProps, columns } = useMemo(
        () => ({
            columns: [
                {
                    name: t('@common.title'),
                    selector: row => row?.title ?? '',
                    sortable: true,
                    cell: row => <span>{row?.title}</span>,
                },
                {
                    name: t('@common.algorithm'),
                    selector: row => row?.algorithm ?? '',
                    sortable: true,
                    center: true,
                },
                {
                    name: t('@common.run-status'),
                    selector: row => row?.runStatus ?? '',
                    sortable: true,
                    width: '140px',
                    cell: row => <RunStatusComponent row={row} />,
                },
                {
                    name: t('@common.last-modified'),
                    width: '180px',
                    selector: row => row?.lastModified ?? '',
                    cell: row => <span>{row?.lastModified}</span>,
                    sortable: true,
                },
                {
                    name: t('@common.modified-by'),
                    selector: row => row?.modifiedBy ?? '',
                    width: '140px',
                    cell: row => <span>{row?.modifiedBy}</span>,
                },
            ],
            emptyDataProps: {
                imageType: NoDataImagesType.Model,
                emptyText: t('@pages.workflow.no-models'),
                actionMessage: isFromSelectPopup ? '' : `+ ${t('@pages.workflow.create-new-model')}`,
                onClickAction: onClickCreate,
            },
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            totalPages: response.totalPages,
            currentPage: response.currentPage,
            pageItems: response.models.length,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );
    const commonTableProps = {
        modelEndpoint: 'model',
        requestList: requestModels,
        selectableRows: !isFromSelectPopup,
        showHeaderButtons: true,
        isFromSelectPopup,
        emptyDataProps,
        loading,
        response,
        headerButtonProps: isFromSelectPopup
            ? {
                  onRefresh,
                  showDelete: false,
                  showCreate: false,
              }
            : {
                  onRefresh,
                  onClickCreate,
              },
    };
    return (
        <div className={`flex flex-col ${className}`}>
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestModels({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            hideFilters={hideFilters}
                            onSearch={searchQuery => {
                                requestModels({ searchQuery, page: 0 });
                            }}
                        >
                            <SelectWithPopupField
                                disabled={disableFilters}
                                label="Algorithm"
                                isClearable
                                fetchDataOnMount
                                className="w-40 m-2"
                                style={{ width: 200 }}
                                tableType={SelectWithPopupTypes.algorithm}
                                selectedItem={algorithmSelected}
                                onChangeItem={onChangeAlgorithm}
                                placeholder="Select Algorithm..."
                                query={{ searchQuery: '' }}
                            />
                            <SelectField
                                disabled={false}
                                isClearable
                                label="Status"
                                className="m-2"
                                placeholder={t('@common.choose-status')}
                                style={{ width: 240 }}
                                onChange={onChangeStatusType}
                                options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                                value={selectedStatusType}
                                isLoading={loading}
                            />
                        </TableFilters>
                    }
                    pagination
                    paginationProps={paginationProps}
                    selectedRow={tablePopupProps && tablePopupProps.selectedItem}
                    onRowClicked={isFromSelectPopup ? onSelectRow : undefined}
                    expandableRows
                    shouldRowExpandCallback={row => row?.wrapper}
                    expandableRowsComponent={ModelRowExpandComponent}
                    columns={columns}
                    actions={isFromSelectPopup ? [] : actions}
                />
            )}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="title"
                    initialLoading={initLoading}
                    onClickItem={onSelectRow}
                    selectedItem={tablePopupProps && tablePopupProps.selectedItem}
                    columns={columns}
                    actions={isFromSelectPopup ? [] : actions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
