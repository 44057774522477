import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { queryParams } from '../utils';

export function useDetailsError(props?: { goBackPath?: string; paramsId?: string }) {
    const history = useHistory();
    const params: { id?: string } = useParams();

    const [detailsError, setDetailsError] = useState({
        status: 0,
        message: '',
    });

    const handle403 = () => {
        const navigatePath = props?.goBackPath;

        if (navigatePath) history.push(navigatePath + queryParams.formatForNavigation());
        else history.goBack();
    };

    const handleError = (err: any) => {
        const id = props?.paramsId ?? params?.id ?? '';

        if (err?.response?.status === 403) handle403();
        else
            setDetailsError({
                message: err.message,
                status: parseInt(id) ? err?.response?.status : 404,
            });
    };

    const resetError = () =>
        setDetailsError({
            message: '',
            status: 0,
        });

    return {
        handleError,
        resetError,
        detailsError,
    };
}
