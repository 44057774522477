import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { nanoid } from 'nanoid';
import React, { useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { CreateButton, OutlineButton } from '../../../../components/buttons';
import { EditableCellComponent } from '../../../../components/tables/components/EditableCellComponent';
import { customTableStyles } from '../../../../components/tables/styles/styles';
import { modalFadeTopAnimationStyle, modalOverlayAnimationStyles } from '../../../../styles/animationStyles';
import { formatBytes } from '../../../../utils';
import { formatFileName, formatFileNamesBeforeUpload } from '../storage.helper';
interface CustomFile extends File {
    path?: string;
}

export type FileStateType = {
    file: CustomFile;
    name: string;
    id: string;
};
interface UploadSelectedFilesModalProps {
    isVisible: boolean;
    onHide: () => void;
    files?: File[] | FileList;
    onSubmit?: (files: FileStateType[]) => void;
}

const formatFilesArray = (files: File[] | FileList) => {
    let _arr: FileStateType[] = [];

    for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        _arr.push({
            id: nanoid(),
            file: currentFile,
            name: currentFile.name,
        });
    }
    return _arr;
};

interface DataType {
    id: string;
    name: string;
    size: string;
    type: string;
    path?: string;
}

export const UploadSelectedFilesModal: React.FC<UploadSelectedFilesModalProps> = ({
    isVisible,
    onHide,
    files,
    onSubmit,
}) => {
    const { t } = useTranslation();

    const [uploadedFiles, setUploadedFiles] = useState<FileStateType[]>(files ? formatFilesArray(files) : []);

    const onChangeFileName = (id: string, name: string) =>
        setUploadedFiles(p => p.map(x => (x.id === id ? { ...x, name } : x)));

    const onRemoveFile = (id: string) => setUploadedFiles(prev => prev.filter(x => x.id !== id));

    const data = useMemo(() => {
        if (uploadedFiles.length < 1) {
            return [
                {
                    id: 'id',
                    name: '-',
                    size: '-',
                    type: '-',
                },
            ];
        }

        const _data: DataType[] = uploadedFiles.map(x => ({
            name: x.name,
            size: formatBytes(x.file.size),
            id: x.id,
            type: x.file.type,
            path: x.file.path,
        }));

        return _data;
    }, [uploadedFiles]);

    const columns = useMemo(() => {
        let cols: TableColumn<any>[] = [
            {
                selector: row => row?.name ?? '',
                name: t('@common.name'),
                grow: 1,
                cell: (row: DataType) => {
                    return row?.id === 'id' ? (
                        row?.name
                    ) : (
                        <EditableCellComponent
                            value={row?.name}
                            customValueDisplay={row.path ? formatFileName(row.path, row.name) : undefined}
                            onSave={async value => {
                                onChangeFileName(row?.id, value);
                                return true;
                            }}
                        />
                    );
                },
            },
            {
                selector: row => row?.size ?? '',
                grow: 0.5,
                maxWidth: '120px',
                name: t('@common.size', { defaultValue: 'Size' }),
            },
            {
                selector: row => row?.type ?? '',
                maxWidth: '150px',
                grow: 0.5,
                name: t('@common.type', { defaultValue: 'Type' }),
                cell: (row: DataType) => {
                    if (row.id === 'id') {
                        return row.type;
                    }
                    return (
                        <div className="flex w-full justify-between items-center">
                            <span className="truncate">{row.type || 'unknown'}</span>
                            <div
                                className="cursor-pointer hover:bg-mainBackground rounded-full p-1"
                                onClick={() => onRemoveFile(row.id)}
                            >
                                <GjirafaIcon name="Close" size={24} />
                            </div>
                        </div>
                    );
                },
            },
        ];
        return cols;
    }, [uploadedFiles]);

    return (
        <CSSTransition in={isVisible} timeout={50} unmountOnExit={false}>
            {state => (
                <div
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    className={`fixed z-50 inset-0 overflow-y-auto bg-mainOverlay bg-opacity-50 flex justify-center items-center px-0 sm:px-6 py-10`}
                    style={{
                        transition: 'opacity 500ms ease-in-out',
                        opacity: 0,
                        ...modalOverlayAnimationStyles[state],
                    }}
                >
                    <div
                        className="z-50 bg-white rounded w-full  md:w-2/3 lg:w-2/3 xl:w-2/3 h-full flex flex-col w-"
                        style={{ ...modalFadeTopAnimationStyle[state], width: 600 }}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="flex w-full justify-between items-center pl-4 pr-3 py-2 border-b border-mainBorder">
                            <span className="text-sm text-blueMainText font-semibold">Uploaded Files</span>

                            <div className="p-1 cursor-pointer" onClick={() => onHide()}>
                                <GjirafaIcon name="Close" />
                            </div>
                        </div>
                        <div className="flex w-full flex-1 flex-col overflow-y-auto py-4">
                            <span className="text-sm font-medium text-blueMainText pl-4 py-4">
                                {t('@edit.files.before.upload', { defaultValue: ' Edit your files before upload!' })}
                            </span>
                            <div className="w-full overflow-y-visible">
                                {state === 'entered' && (
                                    <DataTable
                                        data={data}
                                        columns={columns}
                                        fixedHeader
                                        noHeader
                                        className="w-full"
                                        responsive
                                        customStyles={{
                                            ...customTableStyles,
                                            table: {
                                                ...customTableStyles.table,
                                                style: {
                                                    ...customTableStyles.table?.style,
                                                    overflowY: 'visible !important',
                                                    width: '100% !important',
                                                    maxWidth: '100% !important',
                                                },
                                            },
                                        }}
                                        style={{
                                            overflowY: 'visible !important',
                                            width: '100% !important',
                                            maxWidth: '100% !important',
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="w-full flex justify-end py-4 px-4 border-t border-mainBorder">
                            <OutlineButton title={t('@common.cancel')} className="mr-2" onClick={() => onHide()} />
                            <CreateButton
                                title={t('@upload.files')}
                                disabled={uploadedFiles.length < 1}
                                onClick={() => {
                                    onSubmit && onSubmit(formatFileNamesBeforeUpload(uploadedFiles));
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </CSSTransition>
    );
};
