import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useCurrentWidthListener } from './hooks';
import Router from './router/Router';

const App = () => {
    useCurrentWidthListener();

    useEffect(() => {
        const { pathname, search } = window.location;
        if (pathname.includes('code') && pathname.includes('scope')) {
            sessionStorage.setItem('url_requested', window.location.origin);
            return;
        }
        const redirectRequestedUri = `${window.location.origin}${pathname}${search}`;
        sessionStorage.setItem('url_requested', redirectRequestedUri);
    }, [window.location]);

    return (
        <>
            <Router />
            <ToastContainer />
        </>
    );
};

export default App;
