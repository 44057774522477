import { TableStyles } from 'react-data-table-component';
export const customTableStyles: TableStyles = {
    subHeader: {
        style: {
            padding: 0,
            height: 'auto',
            minHeight: 'auto',
            borderRadius: '10px',
        },
    },
    header: {
        style: {
            height: 86,
            display: 'flex',
            flexDirection: 'row',
        },
    },
    headRow: {
        style: {
            height: 'auto',
            minHeight: 35,
            paddingTop: 8,
            paddingBottom: 8,
            background: '#F6F7F9',
            borderBottom: 'none',
            display: 'flex',
        },
    },
    headCells: {
        style: {
            width: 'auto',
            justifyContent: 'flex-start !important',
            '&:not(:last-of-type)': {
                borderRight: ' 1px solid #EAEAEA',
            },
            'div > div': {
                whiteSpace: 'pre-wrap !important',
            },
            color: '#667C99',
            fontSize: 12,
            fontWeight: '700',
        },
    },
    cells: {
        style: {
            width: 'auto',
            justifyContent: 'flex-start !important',
            borderBottom: 'none !important',
            color: '#052D61',
            fontSize: 14,
            fontWeight: '400',
        },
    },
    rows: {
        style: {
            height: 'auto',
            '&:not(:last-of-type)': {
                borderBottom: 'none !important',
            },
            outline: 'none !important',
        },
    },
    tableWrapper: {
        style: {
            width: 'auto !important',
            minWidth: '100% !important',
            maxWidth: 'auto !important',
            height: 'auto !important',
            maxHeight: 'auto !important',
        },
    },
    table: {
        style: {
            height: 'auto !important',
            maxHeight: 'auto !important',
        },
    },
    expanderCell: {
        style: {},
    },
    expanderButton: {
        style: {
            '&:active, &:hover, &:focus': {
                backgroundColor: '#F6F7F9 !important',
            },
        },
    },
};

export const activeRowStyle = {
    backgroundColor: '#F6F7F9',
};

export const activeRowStyleHovered = {
    '&:hover': {
        backgroundColor: '#F6F7F9',
        cursor: 'pointer',
    },
};
