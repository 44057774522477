import { CSSProperties, FC, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
/**
 * assets - utils
 */
import { ReactComponent as Option } from '../../../assets/icons/options.svg';
import { splitArrayIntoChunks, upperCaseFirstCharacter } from '../../../utils';
import { Checkbox } from '../../buttons';
import { Icon, IconTypes } from '../../common';
import { Dropdown } from '../../dropdown';
/**
 * Components
 */
import { TableMobileActionProps } from '../TableMobile';

type IDataTableColumn = TableColumn<any>;

export interface TableMobileItemProps {
    item?: any;
    selectableRows?: boolean;
    actions?: TableMobileActionProps[];
    columns: IDataTableColumn[];
    mainKeyProperty?: string;
    style?: CSSProperties;
    onClickItem?: (row: any) => any;
    className?: string;
    isSelected?: boolean;
    onClickAction: (action: string, item: any) => any;
}

export const TableMobileItem: FC<TableMobileItemProps> = ({
    item,
    actions,
    columns,
    mainKeyProperty,
    style,
    onClickItem,
    selectableRows,
    onClickAction,
    isSelected,
}) => {
    const renderProperties = useMemo(() => {
        return () => {
            let filterProperties = splitArrayIntoChunks(
                columns.filter(x => x.selector !== mainKeyProperty),
                2
            );

            if (filterProperties && filterProperties?.length) {
                return filterProperties.map((cols: IDataTableColumn[], i) => {
                    if (cols.length <= 1) {
                        let selector = cols[0].selector;
                        let name = cols[0].name;
                        let value = typeof selector === 'string' && item[selector];

                        if (cols[0].cell) return cols[0].cell(item, 0, cols[0], 0);

                        return (
                            <span
                                key={name + i}
                                className="text-secondaryText font-medium text-xs py-1 flex flex-col justify-start"
                            >
                                <span>{name}:</span>
                                <span className="text-blueMainText text-sm font-normal">{value}</span>
                            </span>
                        );
                    }

                    let [firstCol, secondCol] = cols;
                    return (
                        <div key={i} className="flex flex-row flex-wrap w-full items-center justify-between ">
                            {firstCol.cell ? (
                                firstCol.cell(item, 0, firstCol, 0)
                            ) : (
                                <span className="text-secondaryText font-medium text-xs py-1 flex flex-col justify-start flex-1">
                                    <span>{firstCol.name}:</span>
                                    <span className="text-blueMainText text-sm font-normal">
                                        {typeof firstCol.selector === 'string' && item[firstCol.selector]}
                                    </span>
                                </span>
                            )}

                            {secondCol.cell ? (
                                secondCol.cell(item, 0, firstCol, 0)
                            ) : (
                                <span className="text-secondaryText flex-1 font-medium text-xs py-1.5 flex flex-col justify-start">
                                    <span>{secondCol.name}:</span>
                                    <span className="text-blueMainText text-sm font-normal">
                                        {typeof secondCol.selector === 'string' && item[secondCol.selector]}
                                    </span>
                                </span>
                            )}
                        </div>
                    );
                });
            }
            return null;
        };
    }, [item]);

    return (
        <div
            onClick={e => {
                e.preventDefault();
                if (selectableRows) {
                    return;
                }
                onClickItem && onClickItem(item);
            }}
            className={`mb-4 px-4 border py-4 w-full flex flex-col h-auto base-card-style card-shadow transition-all ${
                isSelected ? 'border-mainBlue border-2 border-collapse' : 'border-none'
            }`}
            style={style}
        >
            {selectableRows && (
                <div
                    className="flex py-3 w-full"
                    onClick={e => {
                        e.preventDefault();
                        onClickItem && onClickItem(item);
                    }}
                >
                    <Checkbox isChecked={!!isSelected} className="mb-" />
                </div>
            )}
            <div className="flex flex-row justify-between items-center">
                <span className="text-blueMainText text-base  justify-between mr-2 truncate">
                    {mainKeyProperty && item[mainKeyProperty]}
                </span>
                {actions && actions?.length > 0 && (
                    <Dropdown
                        withArrow={false}
                        triggerProps={{
                            children: <Option />,
                        }}
                    >
                        <div className="flex flex-col bg-white mt-2 card-shadow w-52 z-40">
                            {actions?.map((_action, index) => {
                                const { action, cell, disable, displayName, onClick } = _action;

                                let _isActionDisabled = typeof disable === 'function' ? disable(item) : disable;
                                let _actionName = typeof action === 'function' ? action(item) : action;
                                let _displayName =
                                    typeof displayName === 'function'
                                        ? displayName(item)
                                        : displayName || upperCaseFirstCharacter(_actionName);

                                if (cell) {
                                    return cell(item);
                                }

                                return (
                                    <span
                                        key={index}
                                        className={`flex px-4 py-2 cursor-pointer hover:bg-mainBackground flex-row w-full items-center justify-start text-xs font-medium text-blueMainText ${
                                            _isActionDisabled && 'opacity-20'
                                        }`}
                                        onClick={e => {
                                            e.stopPropagation();
                                            !_isActionDisabled && onClick
                                                ? onClick(item)
                                                : onClickAction(_actionName, item);
                                        }}
                                    >
                                        <Icon name={IconTypes[_actionName]} size={20} className="mr-4" />

                                        {_displayName}
                                    </span>
                                );
                            })}
                        </div>
                    </Dropdown>
                )}
            </div>
            {renderProperties()}
        </div>
    );
};
