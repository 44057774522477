import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../../components/animation/PageAnimationContainer';
import { FooterButtons, HeaderForCreatePages } from '../../../../components/common';
import { InputField, SelectField, SwitchInput } from '../../../../components/inputs';
/**
 * Components
 */
import { Loader, toast } from '../../../../components/ui';
import { conditionsOperators, dataOperators, FILTERS_PATH } from '../../../../constants';
import { useAccountId, useCurrentWidth } from '../../../../hooks';
import {
    FilterDetailsResponseModel,
    SchemaTypeDetailsModel,
    SchemaTypeDetailsResponseModel,
    SchemaTypeListResponseModel,
    SelectOptionType,
    UserDetailsResponseModel,
} from '../../../../models';
/**
 * Redux
 */
import {
    actions as schemaTypesActions,
    selectors as schemaTypesSelectors,
} from '../../../../redux/thunk/app/schemaType/schemaTypesThunk';
import { selectors as userSelectors } from '../../../../redux/thunk/app/user/userThunk';
import axios from '../../../../services/axios';
import { deleteErrorKey, formatArrayForSelectOptions, getFormattedQuery, queryParams } from '../../../../utils';
import ConditionFilterListComponent, { ConditionsStateType } from './components/ConditionFilterListComponent';

export const CreateFilterPage = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const history = useHistory();
    const params: any = useParams();

    const conditionsRef = useRef<React.ElementRef<typeof ConditionFilterListComponent>>(null);
    const isEditing = params?.id;

    /**
     * Selectors
     */
    const userResponse: UserDetailsResponseModel = useSelector(userSelectors.getResponse);
    const { models: schemaTypes }: SchemaTypeListResponseModel = useSelector(schemaTypesSelectors.getResponse);
    const schemaTypesLoading = useSelector(schemaTypesSelectors.getIsLoading);

    /**
     * Local State
     */
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [fieldValues, setFieldValues] = useState<{
        title: string;
        schemaType: SelectOptionType | null;
        operator: SelectOptionType | null;
    }>({
        title: '',
        schemaType: null,
        operator: conditionsOperators[0],
    });
    const [errors, setErrors] = useState<any>({});
    const [schemaTypeDetails, setSchemaTypeDetails] = useState<SchemaTypeDetailsModel | null>(null);
    const [schemaTypeDetailsLoading, setSchemaTypeDetailsLoading] = useState(false);
    const [isForAdmin, setIsForAdmin] = useState(false);

    useEffect(() => {
        schemaTypes.length < 1 && dispatch(schemaTypesActions.request());
    }, [accountId]);

    useEffect(() => {
        if (params?.id) {
            fetchFilterDetails();
        }
    }, [params?.id, accountId]);

    const fetchFilterDetails = async () => {
        try {
            setDetailsLoading(true);

            const query: Record<string, string> = {
                accountId: accountId?.toString(),
                id: params?.id,
            };

            let { model: filterDetails }: FilterDetailsResponseModel = await axios.get(
                `/filter?${getFormattedQuery(query)}`
            );
            const schemaTypeQuery = {
                id: filterDetails.schemaTypeId,
            };
            let { model: schemaTypeDetails }: SchemaTypeDetailsResponseModel = await axios.get(
                `/schemaType?${getFormattedQuery(schemaTypeQuery)}`
            );
            setSchemaTypeDetails(schemaTypeDetails);
            setFieldValues({
                schemaType: {
                    label: schemaTypeDetails.name,
                    value: schemaTypeDetails.id?.toString(),
                },
                title: filterDetails.title,
                operator: conditionsOperators.find(x => x.value === filterDetails.operator) || conditionsOperators[0],
            });
            if (!filterDetails.accountId) {
                setIsForAdmin(true);
            }

            if (conditionsRef.current) {
                const { _setConditions } = conditionsRef.current;

                let _conditions: ConditionsStateType[] = filterDetails.conditions.map((condition, i) => {
                    const propertyDetails =
                        schemaTypeDetails.properties.find(
                            schemaProperty => schemaProperty.id === condition.propertyId
                        ) || null;

                    let operator =
                        dataOperators.find(
                            _dataOperator =>
                                _dataOperator.value === condition.operator &&
                                _dataOperator?.dataTypes?.includes(propertyDetails?.dataTypeId)
                        ) || null;

                    return {
                        _id: i + 1,
                        errors: {},
                        id: condition.id,
                        operator,
                        property: propertyDetails
                            ? {
                                  label: propertyDetails?.name,
                                  value: propertyDetails?.id?.toString(),
                                  ...propertyDetails,
                              }
                            : null,
                        value: condition.value,
                    };
                });
                _setConditions(_conditions);
            }
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const onChangeFieldValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
        if (key === 'schemaType') {
            onChangedSchemaType(value);
        }
    };

    const onChangedSchemaType = item => {
        fetchSchemaTypeDetails(item?.value);
        if (conditionsRef.current) {
            const { _setConditions, _getConditions } = conditionsRef.current;
            let conditions = _getConditions();
            if (conditions.length > 0) {
                _setConditions(
                    conditions.map(x => ({
                        ...x,
                        errors: {},
                        property: null,
                        operator: null,
                        value: '',
                    }))
                );
            }
        }
    };

    const fetchSchemaTypeDetails = async id => {
        try {
            setSchemaTypeDetailsLoading(true);
            setSchemaTypeDetails(null);

            let response: SchemaTypeDetailsResponseModel = await axios.get(`/schemaType?${getFormattedQuery({ id })}`);
            setSchemaTypeDetails(response.model);
        } catch (err: any) {
            setFieldValues(prev => ({
                ...prev,
                schemaType: null,
            }));
        } finally {
            setSchemaTypeDetailsLoading(false);
        }
    };

    const validateFields = () => {
        let isValid = true;
        let _errors: any = {};

        const { schemaType, title } = fieldValues;

        if (!title) {
            isValid = false;
            _errors.title = 'Title is Required!';
        }

        if (!schemaType) {
            isValid = false;
            _errors.schemaType = 'Schema type is Required!';
        }

        setErrors(_errors);
        return isValid;
    };

    const handleCancel = () => {
        history.goBack();
    };
    const handleSubmit = async e => {
        e.preventDefault();

        if (formLoading || !validateFields() || !conditionsRef.current) {
            return;
        }

        const { validateForm, _getConditions } = conditionsRef.current;
        const { isValid } = validateForm();
        if (!isValid) {
            return;
        }
        try {
            setFormLoading(true);

            const conditions = _getConditions();
            const payload: any = {
                id: isEditing ? params?.id : 0,
                schemaTypeId: schemaTypeDetails?.id,
                title: fieldValues.title,
                operator: fieldValues.operator?.value,
                conditions: conditions.map(_condition => {
                    let value = _condition.value;

                    return {
                        id: _condition.id,
                        propertyId: _condition.property?.value,
                        value,
                        operator: _condition.operator?.value,
                    };
                }),
            };

            if (!isForAdmin) {
                payload.accountId = accountId;
            }

            await axios.post('/filter', payload);
            toast.success(isEditing ? 'Filter has been edited!' : 'New Filter has been created!');
            history.push(FILTERS_PATH + queryParams.formatForNavigation());
        } catch (err: any) {
            setFormLoading(false);
        }
    };

    const renderConditions = () => {
        return (
            <>
                {fieldValues.schemaType && <HeaderForCreatePages hideIcon title="Conditions" className="mt-10" />}
                {(schemaTypeDetailsLoading || detailsLoading) && (
                    <div className="flex w-full justify-center items-center p-10">
                        <Loader />
                    </div>
                )}
                <div className={`flex-col w-full max-w-5xl ${detailsLoading ? 'hidden' : 'flex'}`}>
                    <ConditionFilterListComponent
                        onChangeOperatorType={item => onChangeFieldValues('operator', item)}
                        operatorType={fieldValues.operator}
                        ref={conditionsRef}
                        isVisible={!!schemaTypeDetails && !!fieldValues.schemaType}
                        schemaTypeDetails={schemaTypeDetails}
                    />
                </div>
            </>
        );
    };

    const formId = 'createFilterForm';
    return (
        <PageAnimationContainer>
            <div className="flex flex-col">
                <form id={formId} className="flex flex-col items-center" onSubmit={handleSubmit}>
                    <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                    <div
                        className="flex flex-col"
                        style={{
                            minWidth: currentWidth < 900 ? '100%' : 546,
                            width: 'auto',
                        }}
                    >
                        <InputField
                            disabled={formLoading || detailsLoading}
                            type="submit"
                            label={t('@common.title')}
                            name="title-filter"
                            id="title-filter"
                            required
                            value={fieldValues.title}
                            onChange={e => onChangeFieldValues('title', e.target.value)}
                            placeholder={t('@common.write.here')}
                            errorMessage={errors['title']}
                        />
                        <div className="flex w-full justify-between mt-4">
                            <SelectField
                                disabled={isEditing || detailsLoading}
                                id="schemaType-filter"
                                label={t('@common.schema-type')}
                                placeholder={t('@components.modals.choose-schema-type')}
                                onChange={item => !isEditing && onChangeFieldValues('schemaType', item)}
                                value={fieldValues.schemaType}
                                required
                                isLoading={schemaTypesLoading}
                                errorMessage={errors['schemaType']}
                                className="flex-1 flex mr-2"
                                options={formatArrayForSelectOptions(schemaTypes)}
                            />
                        </div>

                        {userResponse.model && userResponse.model.isAdmin && (
                            <SwitchInput
                                label="Platform's filter"
                                className="mt-4"
                                value={isForAdmin}
                                onChange={e => setIsForAdmin(e)}
                            />
                        )}
                    </div>
                    {renderConditions()}
                    <HeaderForCreatePages title="" hideIcon className="mt-10" />
                    <FooterButtons
                        hideSave
                        onClickCancel={handleCancel}
                        thirdButtonProps={{
                            htmlType: 'submit',
                            type: 'submit',
                            form: formId,
                            loading: formLoading,
                            disabled: formLoading || detailsLoading,
                        }}
                        thirdButtonTitle={isEditing ? t('@common.edit') : t('@common.create')}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
