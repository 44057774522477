import { InputField } from '../../../components/inputs';
import { Icon, IconTypes } from '../../../components/common';
import { OutlineButton } from '../../../components/buttons';
import { SingleOptionType } from './DynamicInputsForm';

export interface DynamicOptionFormProps {
    className?: string;
    itemId: number;
    options: SingleOptionType[];
    onAddNewOption: (itemId: number) => void;
    onDeleteOption: (itemId: number, optionId: any) => void;
    onChangeOptionInputs: (itemId: number, optionId: any, key: string, value: string) => void;
}

export const DynamicOptionForm: React.FC<DynamicOptionFormProps> = ({
    className,
    onAddNewOption,
    onChangeOptionInputs,
    onDeleteOption,
    options,
    itemId,
}) => {
    const renderOptions = () => {
        let commonProps = {
            style: { width: 180 },
            placeHolder: 'Write Here...',
        };
        return options.map(option => {
            let id = option._id;

            return (
                <div className="flex flex-row w-full justify-start items-end mt-2" key={id}>
                    <InputField
                        value={option.label}
                        label="Label"
                        onChange={e => onChangeOptionInputs(itemId, id, 'label', e.target.value)}
                        {...commonProps}
                        className="mr-2"
                    />
                    <InputField
                        value={option.value}
                        label="Value"
                        onChange={e => onChangeOptionInputs(itemId, id, 'value', e.target.value)}
                        className="mr-2"
                        {...commonProps}
                    />
                    <button
                        type="button"
                        onClick={() => {
                            onDeleteOption(itemId, id);
                        }}
                        className="flex justify-center items-center px-2 h-10"
                    >
                        <Icon name={IconTypes.delete} size={24} className="cursor-pointer" />
                    </button>
                </div>
            );
        });
    };

    return (
        <div className={`flex flex-col w-full mt-5  ${className}`}>
            <label className="text-xs sm:text-sm text-secondaryText font-normal">Options:</label>
            {renderOptions()}
            <OutlineButton
                onClick={() => onAddNewOption(itemId)}
                title="+ Add Option"
                className="mt-4"
                style={{ width: 220 }}
            />
        </div>
    );
};
