import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { DetailsInfoOverview } from '../../../../components/details-entity';
import { Error404 } from '../../../../components/errors';
import { PIPELINES_PATH } from '../../../../constants';
import { useAccountId, useDetailsError, useToggleVisibility } from '../../../../hooks';
import { Nullable, PipelineDetailsModel, PipelineDetailsResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import { formatDateAndTime, queryParams } from '../../../../utils';
import {PipelineJobsPage} from '../../pipeline-job/list/PipelineJobsPage';
import { CustomTabContainer } from '../../../../components/tabs/custom-tab/CustomTabContainer'
import { ToggleActionButton, TableActionRow } from '../../../../components/tables';
import { ConfirmationPopupModal } from '../../../../components/modals';
import { toast } from '../../../../components/ui';

enum PipelineJobTab {
    jobs = 'Jobs',
    // metrics = 'metrics',
}

export const PipelineDetailsPage = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const accountId = useAccountId();
    const history = useHistory();
    const { detailsError, handleError, resetError } = useDetailsError();
    const entityId = parseInt(params?.id);

    const [details, setDetails] = useState<Nullable<PipelineDetailsModel>>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    useEffect(() => {
        fetchDetails();
    }, [accountId, entityId]);

    const fetchDetails = async () => {
        try {
            resetError();
            setDetailsLoading(true);
            const response: PipelineDetailsResponseModel = await axios.get(`/pipeline?id=${entityId}`);
            setDetails(response.model);
        } catch (err) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onClickEdit = () =>
        history.push(`${PIPELINES_PATH}/edit-pipeline/${details?.id}${queryParams.formatForNavigation()}`);

    const onClickDelete = () => {
        onToggleVisibility(true)
    }

    const handleDelete = async () => {
        try {
            setDeleteLoading(true)
            await axios.delete(`pipeline?id=${entityId}&accountId=${accountId}`)
            toast.success('Pipeline has been deleted!');
            history.push(`${PIPELINES_PATH}${queryParams.formatForNavigation()}`);
        } catch (err) {
            setDeleteLoading(false)
            onToggleVisibility(false)
            handleError(err)
        }
    }

    const actions = [
        {
            action: 'edit',
            onClick: onClickEdit
        },
        {
            action: 'delete',
            onClick: onClickDelete
        }
    ]

    if (detailsError.status === 404) {
        return <Error404 model="task" />;
    }

    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    title={t(`@confirm-delete.pipeline`, { defaultValue: 'Pipeline delete confirmation'})}
                    isVisible={isModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteLoading}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    description={'Are you sure you want to delete this item'}
                />
            )}
            <DetailsInfoOverview
                data={
                    details
                        ? {
                            '@common.title': details?.title,
                            '@alias': details?.alias,
                            '@createdAt': formatDateAndTime(details?.createdAt),
                            '@createdBy': details.createdBy,
                            '@common.last-modified': formatDateAndTime(details?.lastModified),
                            '@modifiedBy': details?.modifiedBy,
                        }
                        : {}
                }
                loading={detailsLoading}
                title={t('@pipeline.overview', { defaultValue: 'Pipeline overview' })}
                titleRightComponent={
                    <ToggleActionButton>
                        <>
                            {actions.map((_action, i) => {
                                return (
                                    <TableActionRow
                                        key={i}
                                        onClickAction={_action.onClick || (() => {})}
                                        {..._action}
                                        row={i}
                                    />
                                );
                            })}
                        </>
                    </ToggleActionButton>
                }
            />
            <div className="flex flex-1 flex-col">
                <CustomTabContainer
                    className="mt-6"
                    style={{ minHeight: 500 }}
                    animationEnabled={false}
                    defaultActiveTab={PipelineJobTab.jobs}
                    
                    tabs={[
                        {
                            component: <PipelineJobsPage />,
                            displayName: t('@pipelineJob.tab.job', { defaultValue: 'Jobs' }),
                            key: PipelineJobTab.jobs,
                        },
                    ]}
                />
            </div>
        </>
        
        );
    };
    // <PipelineJobsPage />
