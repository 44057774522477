import React from 'react';
import { ReactComponent as UploadImage } from '../../../assets/images/upload-cloud.svg';

export interface DropzoneCardOverlayProps {
    className?: string;
    message?: string;
}
export const DropzoneCardOverlay: React.FC<DropzoneCardOverlayProps> = ({
    className,
    message = 'Drop files to upload them as artifacts',
}) => {
    return (
        <div
            style={{
                backgroundColor: 'rgba(29, 121, 242,0.2',
            }}
            className={`flex flex-col w-full h-full absolute pb-10 z-10 border-dashed border-2 border-mainBlue justify-center items-center left-0 right-0 ${className}`}
        >
            <UploadImage height={60} width={60} />
            <span className="card-shadow mt-2 text-white text-sm justify-center flex rounded-sm bg-mainBlue h-auto w-80 px-2 py-2 ">
                {message}
            </span>
        </div>
    );
};
