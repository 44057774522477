import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AccountDetailsResponseModel } from '../models';
import { selectors as accountDetailsSelectors } from '../redux/thunk/app/account/accountDetailsThunk';
import { CardSettingsTab } from './settings/billing-tabs';

export const AccountNotActivePage: React.FC = () => {
    const history = useHistory();

    const { model }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const loading = useSelector(accountDetailsSelectors.getIsLoading);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!loading && model.isActive) {
            history.replace(`/?accountId=${model.id}`);
        }
    }, [loading, model]);

    if (loading || !model.id || model.isActive) {
        return null;
    }

    return (
        <div className="flex flex-1 flex-col h-full justify-center">
            <span className="font-medium text-base sm:text-2xl text-mainBlue text-start">
                Account is not active yet!
            </span>

            <span className="text-blueMainText font-medium text-left mt-4">
                To activate this account, you need to add a payment card or apply a voucher code!
            </span>
            <div className="bg-white p-4 w-full flex flex-1 flex-col rounded mt-4">
                <CardSettingsTab />
            </div>
            {/* <div className="w-full flex flex-col p-10 sm:flex-row sm:p-0 sm:h-2/3 lg:h-2/3 sm:w-2/3 lg:w-2/3">
                <div className="flex flex-1 justify-end  h-full items-center">
                    <NotActive className="h-full w-full flex flex-1" />
                </div>
                <div className="flex flex-1 flex-col justify-start h-full items-start">
                    <span className="font-medium text-base sm:text-2xl text-mainBlue mt-10 text-start">
                        Account waiting for approvement!
                    </span>

                    <span className="text-blueMainText font-medium text-left mt-10">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam.
                    </span>
                </div>
            </div> */}
        </div>
    );
};
