import React from 'react';
import { AnimationComponent, AnimationProps } from './AnimationComponent';

interface AnimationComponentContainerProps extends AnimationProps {
    enableAnimation?: boolean;
}

export const AnimationComponentContainer: React.FC<AnimationComponentContainerProps> = ({
    enableAnimation = true,
    children,
    ...props
}) => {
    if (enableAnimation) {
        return <AnimationComponent {...props}>{children}</AnimationComponent>;
    }

    return children;
};
