import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export const customEndpointFormSchema = (t: TFunction<'translation', undefined>) =>
    yup.object().shape({
        title: yup.string().required(t('@validations.field.isRequired', { field: t('@common.title') })),
        scriptPath: yup
            .string()
            .required(t('@validations.field.isRequired', { field: 'Python Script' }))
            .matches(
                new RegExp('^.*.py$'),
                t('@validations.invalid.file', { file: 'Python', defaultValue: 'Please select a {{file}} File!' })
            ),
        instanceTypeId: yup.number().moreThan(0, t('@validations.field.isRequired', { field: t('instance.type') })),
        instanceCount: yup
            .number()
            .moreThan(0, t('@validations.field.isRequired', { field: t('number.of.instances') })),
        requirementsPath: yup
            .string()
            .optional()
            .matches(
                new RegExp('^.*.txt$'),
                t('@validations.invalid.file', { file: 'TXT', defaultValue: 'Please select a {{file}} File!' })
            ),
    });
