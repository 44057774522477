import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as themeActions, selectors as themeSelectors } from '../redux/thunk/theme';
import { getWidth } from '../utils';

export function useCurrentWidth() {
    const width = useSelector(themeSelectors.getCurrentWidth);
    return width || getWidth();
}

export function useCurrentWidthListener() {
    const dispatch = useDispatch();

    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId: any = null;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            timeoutId && clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => {
                dispatch(themeActions.setCurrentWidth(getWidth()));
            }, 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, []);
}
