import { Dispatch } from 'redux';
import { InitialStateType, LookalikeEngineDetailsResponseModel } from '../../../../../models';
import axios from '../../../../../services/axios';
import { actionCreator } from '../../../../../utils';
import { RootState } from '../../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/engines/lookalike/lookalikeEngineDetailsThunk/REQUEST',
    SUCCESS: '@app/engines/lookalike/lookalikeEngineDetailsThunk/SUCCESS',
    FAILURE: '@app/engines/lookalike/lookalikeEngineDetailsThunk/FAILURE',
    SET_RESPONSE: '@app/engines/lookalike/lookalikeEngineDetailsThunk/SET_RESPONSE',
};

/**
 * Initial state
 */

const initialState: InitialStateType<LookalikeEngineDetailsResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        success: false,
        message: '',
        model: {
            callbackUrl: '',
            seedSegmentId: 0,
            id: 0,
            biskoDatasetName: '',
            description: '',
            experimentId: '',
            runs: [],
            title: '',
            scheduleFrequency: 0,
            scheduleId: 0,
        },
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.lookalikeEngineDetails.isLoading,
    getResponse: (state: RootState) => state.app.lookalikeEngineDetails.response,
    getErrorMessage: (state: RootState) => state.app.lookalikeEngineDetails.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_RESPONSE } = actionTypes;

const Reducer = (state: InitialStateType<LookalikeEngineDetailsResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                model: null,
            };
        case SET_RESPONSE:
            return {
                ...state,
                response: payload,
                errorMessage: '',
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (accountId, id) {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const response: LookalikeEngineDetailsResponseModel = await axios.get(
                    `lookalikeEngine?accountId=${accountId}&id=${id}`
                );
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
    setResponse: (response: LookalikeEngineDetailsResponseModel) => actionCreator(SET_RESPONSE, response),
};
