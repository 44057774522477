import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { CustomTab, CustomTabProps } from '.';
import { TabsAnimationContainer } from './TabsAnimationContainer';

type CustomTabContainerTabs = {
    key: any;
    displayName: string;
    component: ReactNode;
    disabled?: boolean;
} | null;

interface CustomTabContainerProps {
    tabs: CustomTabContainerTabs[];
    scrollToTopTabs?: string[];
    defaultActiveTab?: string;
    style?: CSSProperties;
    className?: string;
    contentClassName?: string;
    tabHeaderProps?: CustomTabProps;
    animationEnabled?: boolean;
}

export const CustomTabContainer: React.FC<CustomTabContainerProps> = ({
    tabs,
    className,
    style,
    scrollToTopTabs,
    defaultActiveTab,
    contentClassName,
    tabHeaderProps,
    animationEnabled = true,
}) => {
    const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
    const [activeTab, setActiveTab] = useState<string>('');

    useEffect(() => {
        if (defaultActiveTab && defaultActiveTab !== activeTab) {
            let tabsKey = tabs.filter(x => x !== null).map(x => x?.key);
            setActiveTab(tabsKey.indexOf(defaultActiveTab) >= 0 ? defaultActiveTab : tabsKey[0]);
        }
    }, [defaultActiveTab]);

    useEffect(() => {
        scrollIntoView();
    }, [divRef, activeTab]);

    const scrollIntoView = (_activeTab = activeTab) => {
        let shouldScrollTop = scrollToTopTabs ? scrollToTopTabs.includes(_activeTab) : false;
        if (divRef !== null && shouldScrollTop) {
            divRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const renderCurrentTab = () => {
        const currentTab = tabs.find(x => x?.key === activeTab) || null;

        return currentTab ? currentTab.component : null;
    };

    return (
        <div
            style={{ ...style }}
            ref={r => {
                if (r !== null) {
                    setDivRef(r);
                }
            }}
            className={`w-full flex flex-1 flex-col card-shadow h-auto rounded bg-white table-body-global-style relative ${className}`}
        >
            <CustomTab
                className="bg-white rounded-t"
                activeTabKey={activeTab}
                onChangeTab={tab => {
                    scrollIntoView(tab.key);
                    setActiveTab(tab.key);
                }}
                tabs={tabs
                    .filter(x => x !== null)
                    .map(x => ({
                        displayName: x?.displayName ?? '',
                        key: x?.key ?? '',
                        disabled: x?.disabled ?? false,
                    }))}
                {...tabHeaderProps}
            />

            <TabsAnimationContainer className={contentClassName} activeTab={activeTab} animation={animationEnabled}>
                {renderCurrentTab()}
            </TabsAnimationContainer>
        </div>
    );
};
