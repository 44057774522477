import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { trim, trimEnd } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { CopyTextIcon } from '../../../../components/common';
import { InputField } from '../../../../components/inputs';
import { Loader, toast } from '../../../../components/ui';
import { storagePageContext } from '../../../../contexts';

import { StorageObjectListModel } from '../../../../models';
import axios from '../../../../services/axios';
import { getAccountIdFromQuery, showServerErrors } from '../../../../utils';
import { renameObject } from '../storage.helper';

interface StorageKeyCellComponentProps {
    keyText: string;
    onClick: () => any;
    row: StorageObjectListModel;
    onRefreshList?: () => any;
}

const eventTypeOptionKeys = {
    1: '@deleting-folder',
    2: '@moving-folder',
    3: '@deleting-object',
    4: '@moving-object',
};

export const storageEventTypeOptions = {
    Default: 0,
    DeleteBucket: 6,
    DeleteFolder: 1,
    DeleteObject: 3,
    EmptyBucket: 5,
    MoveFolder: 2,
    MoveObject: 4,
};

const EDITING_KEY = 'editing-key';
const NOT_EDITING_KEY = 'not-editing-key';

export const StorageKeyCellComponent: React.FC<StorageKeyCellComponentProps> = ({
    row,
    keyText,
    onClick,
    onRefreshList,
}) => {
    const { t } = useTranslation();
    const { selectedItemForRename, setSelectedItemForRename } = useContext(storagePageContext);
    const [isLoading, setIsLoading] = useState(false);

    const { handleSubmit, setValue, control } = useForm({
        shouldUnregister: false,
    });
    const isEditing = useMemo(
        () => !!(selectedItemForRename && selectedItemForRename.key === row.key),
        [selectedItemForRename, row]
    );

    useEffect(() => {
        if (isEditing) {
            setValue('name', keyText);
        }
    }, [isEditing]);

    const onBlurInput = () => {
        setSelectedItemForRename && setSelectedItemForRename(null);
    };

    const onSubmitForm = async data => {
        const name: string = data?.name ?? '';

        if (!trim(name, '/').length) {
            return;
        }

        try {
            setIsLoading(true);
            let newName = renameObject(row.key, name);
            const payload: any = { accountId: getAccountIdFromQuery(), keys: {}, prefixes: {} };

            if (row.isFolder)
                payload.prefixes = {
                    [row.key]: trimEnd(newName, '/') + '/',
                };
            else
                payload.keys = {
                    [row.key]: trimEnd(newName, '/'),
                };

            await axios.put(`/storage/object`, payload);
            let i18nKey = `@${row.isFolder ? 'folder' : 'object'}.renamed.successfully`;
            toast.success(t(i18nKey));

            setSelectedItemForRename && setSelectedItemForRename(null);
            onRefreshList && onRefreshList();
        } catch (err) {
            showServerErrors(err);
        } finally {
            setIsLoading(false);
        }
    };
    const statusKey: string = useMemo(() => {
        let key = eventTypeOptionKeys[row.eventType];
        return key ? t(key) : '';
    }, [row.eventType]);

    return (
        <div
            className="flex items-center flex-1 h-full group"
            onClick={e => {
                e.stopPropagation();
                !isEditing && onClick();
            }}
        >
            <div>
                <GjirafaIcon size={24} name={row.isFolder ? 'Folder' : 'File'} />
            </div>
            <SwitchTransition>
                <CSSTransition
                    key={isEditing ? EDITING_KEY : NOT_EDITING_KEY}
                    addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                    classNames="switchTop"
                >
                    {isEditing ? (
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmitForm)}
                            className="pl-2 flex items-center"
                        >
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value, name } }) => (
                                    <InputField
                                        disabled={isLoading}
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        style={{ height: 30 }}
                                        hideLabel
                                        placeholder={t('@common.write.here', { defaultValue: 'Write here...' })}
                                        autoFocus
                                        onBlur={onBlurInput}
                                    />
                                )}
                            />
                            {isLoading && <Loader className="ml-2" size={22} />}
                        </form>
                    ) : (
                        <>
                            <span className={`text-sm ml-3 overflow-hidden whitespace-pre-wrap text-ellipsis`}>
                                {keyText}
                            </span>
                            <CopyTextIcon
                                tooltipId="copyFullKey"
                                tooltipContent={t('@copy.fullKey')}
                                textToCopy={row.key}
                                className="ml-2"
                            />

                            {!!statusKey && (
                                <div className="flex w-auto flex-none">
                                    <p className="text-secondaryText text-sm ml-4">{statusKey}</p>
                                    <Loader size={18} color="#667C99" className="ml-2" />
                                </div>
                            )}
                        </>
                    )}
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
};
