import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { nanoid } from 'nanoid';
import React, { useContext, useImperativeHandle, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { SelectField } from '../../../../../components/inputs';
import { ErrorTextComponent } from '../../../../../components/messages';
import { conditionsOperators, dataTypes, dateTimeDataOperatorTypes } from '../../../../../constants';
import { scrollBarContext } from '../../../../../contexts';
import { Nullable, SchemaTypeDetailsModel, SelectOptionType } from '../../../../../models';
import { deleteErrorKey } from '../../../../../utils';
import { ConditionFilterComponent } from './ConditionFilterComponent';

export interface ConditionsStateType {
    id: number;
    _id: any;
    property: Nullable<SelectOptionType>;
    operator: Nullable<SelectOptionType>;
    value: string;
    errors: any;
}

export interface ConditionFilterListComponentProps {
    schemaTypeDetails: SchemaTypeDetailsModel | null;
    isVisible: boolean;
    operatorType: Nullable<SelectOptionType>;
    onChangeOperatorType: (item: Nullable<SelectOptionType>) => any;
}

type RefType = {
    _getConditions: () => ConditionsStateType[];
    _setConditions: (list: ConditionsStateType[]) => void;
    validateForm: () => { message: string; isValid: boolean };
};
const ConditionFilterListComponent: React.ForwardRefRenderFunction<RefType, ConditionFilterListComponentProps> = (
    { schemaTypeDetails, isVisible, operatorType, onChangeOperatorType },
    ref
) => {
    /**
     * Hooks
     */
    const { containerElement } = useContext(scrollBarContext);
    const lastStepRef = React.useRef<HTMLDivElement>(null);

    /**
     * Local State
     */
    const [errors, setErrors] = useState<any>({});
    const [conditions, setConditions] = useState<ConditionsStateType[]>([
        {
            id: 0,
            _id: nanoid(),
            errors: {},
            operator: null,
            property: null,
            value: '',
        },
    ]);

    useImperativeHandle(ref, () => ({
        _getConditions: () => {
            return conditions;
        },
        _setConditions: _tables => {
            setConditions(_tables);
        },
        validateForm,
    }));

    const onScrollToLastStep = () => {
        if (containerElement && lastStepRef.current) {
            lastStepRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const validateForm = () => {
        let response = {
            isValid: true,
            message: '',
        };

        let _errors: any[] = [];

        if (conditions.length < 1) {
            setErrors({
                conditions: 'You should add at least one condition!',
            });
            return {
                isValid: false,
                message: 'You should add at least one conditions!',
            };
        }

        conditions.forEach(item => {
            let _err: any = {};

            if (!item.operator) {
                response.isValid = false;
                _err.operator = 'Operator is required!';
            }

            if (!item.property) {
                response.isValid = false;
                _err.property = 'Property is required!';
            }

            if (item?.operator?.value === dateTimeDataOperatorTypes.TheLast) {
                const [every, time] = item?.value?.split('-');
                if (!every || !time) {
                    response.isValid = false;
                    _err.value = 'Please fill these fields!';
                }
            } else if (item?.property?.dataTypeId === dataTypes.date && !item.value) {
                response.isValid = false;
                _err.value = 'Please select a date!';
            }

            _errors.push(_err);
        });

        setConditions(prev => prev.map((x, i) => ({ ...x, errors: _errors[i] })));
        setErrors({});

        return response;
    };

    const onAddNewCondition = () => {
        deleteErrorKey('conditions', setErrors);
        setConditions(prev => [
            ...prev,
            {
                id: 0,
                _id: nanoid(),
                errors: {},
                operator: null,
                property: null,
                value: '',
            },
        ]);
        setTimeout(() => {
            onScrollToLastStep();
        }, 100);
    };

    const onDelete = _id => {
        setConditions(prev => prev.filter(x => x._id !== _id));
    };

    const onChangeConditionInputs = (_id: number, keyValuesPair: Record<string, any>) => {
        setConditions(prev => {
            return prev.map(x => {
                if (x._id === _id) {
                    let _errs = x.errors;

                    Object.entries(keyValuesPair).forEach(([key]) => {
                        delete _errs[key];
                    });

                    return {
                        ...x,
                        errors: _errs,
                        ...keyValuesPair,
                    };
                }
                return x;
            });
        });
    };

    const renderConditions = () =>
        conditions.map((condition, index) => (
            <ConditionFilterComponent
                index={index}
                operatorType={operatorType}
                conditionState={condition}
                onChangeInputs={keyValue => onChangeConditionInputs(condition._id, keyValue)}
                onDelete={() => onDelete(condition._id)}
                properties={schemaTypeDetails ? schemaTypeDetails.properties : []}
                title={`Condition ${index + 1}`}
            />
        ));

    if (!isVisible) {
        return null;
    }

    return (
        <div className="flex flex-col w-full max-w-5xl items-center bg-white p-4 rounded-lg">
            <div className="w-full p-0 border bg-greyWhite200 border-mainBorder rounded py-6">
                <AnimateHeight height={conditions.length > 1 ? 'auto' : 0} className="w-full">
                    <SelectField
                        value={operatorType}
                        onChange={onChangeOperatorType}
                        options={conditionsOperators}
                        label="With"
                        style={{ width: 160 }}
                        className={`w-40 ml-4 mb-4`}
                    />
                </AnimateHeight>
                {renderConditions()}
                <div ref={lastStepRef} />
                <div
                    className={`w-full flex flex-row justify-start pl-6 mt-6 border-t border-blueStroke text-blueDark400  font-semibold`}
                >
                    <div className="h-6 w-6 border-b border-l border-blueStroke" />
                    <div
                        onClick={onAddNewCondition}
                        className="mt-2 rounded p-1 border transition-all text-greyBlack300 border-blueStroke hover:border-blueDark300 cursor-pointer"
                    >
                        <GjirafaIcon name="Plus" size={24} />
                    </div>
                </div>
            </div>
            <ErrorTextComponent message={errors?.conditions} className="w-full flex justify-start" />
        </div>
    );
};

export default React.forwardRef(ConditionFilterListComponent);
