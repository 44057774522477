import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RunStatusComponent } from '../../../components/common';
import { CreateDeploymentModal } from '../../../components/modals';
import { NoDataImagesType, Table, TableFilters, TableMobile, UrlTableComponent } from '../../../components/tables';
import { toast } from '../../../components/ui';
import { breakpoints } from '../../../constants';
import { useAccountId, useCurrentWidth } from '../../../hooks';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
import { DeploymentListModel, DeploymentListResponseModel } from '../../../models';
import { actions as deploymentInstanceTypeActions } from '../../../redux/thunk/app/deployment/deploymentInstanceTypesThunk';
import {
    actions as deploymentsActions,
    selectors as deploymentsSelectors,
} from '../../../redux/thunk/app/deployment/deploymentsThunk';
import { getWidth, showFiltersHeader } from '../../../utils';
import DeploymentIframe from './components/DeploymentIframe';

export const DeploymentsPage = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const currentWidth = useCurrentWidth();

    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });

    /**
     * Redux Selectors
     */
    const deploymentsResponse: DeploymentListResponseModel = useSelector(deploymentsSelectors.getResponse);
    const deploymentsLoading = useSelector(deploymentsSelectors.getIsLoading);
    const initLoading = useSelector(deploymentsSelectors.getInitialLoading);

    /**
     * Local State
     */
    const [selectedDeploymentForEdit, setSelectedDeploymentForEdit] = useState<DeploymentListModel | null>(null);

    const requestDeployments = (query, resetQuery?: boolean) => {
        dispatch(deploymentsActions.request({ accountId, ...query }, resetQuery));
    };

    useEffect(() => {
        return () => {
            dispatch(deploymentsActions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        requestDeployments({ page: 0, searchQuery: '' });
        dispatch(deploymentInstanceTypeActions.request());
    }, [accountId]);

    const onClickCreate = () => {
        onToggleCreateModal(true);
    };

    const onSuccessCreated = (edited: boolean) => {
        toast.success(
            t(`@success.${edited ? 'edit' : 'create'}.deployment`, {
                defaultValue: `Deployment has been ${edited ? 'edited' : 'created'}`,
            })
        );
        onToggleCreateModal(false);
        requestDeployments({});
    };

    const onHideCreateModal = () => {
        selectedDeploymentForEdit && setSelectedDeploymentForEdit(null);
        onToggleCreateModal(false);
    };

    const onClickEdit = row => {
        onToggleCreateModal(true);
        setSelectedDeploymentForEdit(row);
    };

    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : deploymentsResponse?.currentPage || 0;
        !deploymentsLoading && requestDeployments({ page });
    };

    const onPageChanged = page => {
        requestDeployments({ page });
    };

    const emptyDataProps = useMemo(
        () => ({
            imageType: NoDataImagesType.Deployment,
            onClickAction: onClickCreate,
            actionMessage: t('@pages.workflow.create-deployment', { defaultValue: '+ Create new Deployment' }),
            emptyText: t('@pages.workflow.no-deployments'),
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: deploymentsResponse.currentPage,
            totalItems: deploymentsResponse.totalItems,
            totalPages: deploymentsResponse.totalPages,
            pageItems: deploymentsResponse.models.length,
            onChangePage: onPageChanged,
        }),
        [deploymentsResponse]
    );
    const commonTableProps = {
        selectableRows: true,
        showHeaderButtons: true,
        headerButtonProps: {
            onClickCreate,
            onRefresh,
        },
        response: deploymentsResponse,
        loading: deploymentsLoading,
        emptyDataProps,
        isFromSelectPopup: false,
        requestList: requestDeployments,
        modelEndpoint: 'deployment',
    };

    return (
        <div className="">
            {shouldRenderCreateModal && (
                <CreateDeploymentModal
                    onSuccess={onSuccessCreated}
                    isVisible={isCreateModalVisible}
                    deploymentForEdit={selectedDeploymentForEdit}
                    onHide={onHideCreateModal}
                    isEditing={!!selectedDeploymentForEdit}
                />
            )}
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    pagination
                    subHeader={showFiltersHeader(deploymentsResponse)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                dispatch(
                                    deploymentsActions.request({
                                        [key]: value,
                                    })
                                );
                            }}
                            response={deploymentsResponse}
                            onSearch={searchQuery => {
                                dispatch(
                                    deploymentsActions.request({
                                        searchQuery,
                                        page: 0,
                                    })
                                );
                            }}
                        />
                    }
                    paginationProps={paginationProps}
                    expandableRows
                    expandableRowsComponent={DeploymentIframe}
                    expandableRowDisabled={row => row?.endpoint === '<pending>'}
                    columns={[
                        {
                            name: t('@common.title'),
                            selector: row => row?.title ?? '',
                            cell: row => <span>{row?.title}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.model-title'),
                            selector: row => row?.modelTitle ?? '',
                            sortable: true,
                        },
                        {
                            name: 'Endpoint',
                            selector: row => row?.endpoint ?? '',
                            minWidth: '220px',
                            cell: (row: DeploymentListModel) => {
                                if (row?.endpoint === '<pending>') return <span>{row?.endpoint}</span>;

                                return <UrlTableComponent url={row.endpoint} withIcon />;
                            },
                        },
                        {
                            name: 'Status',
                            selector: row => row?.status ?? '',
                            width: '140px',
                            cell: row => <RunStatusComponent runStatusKey="status" row={row} />,
                        },
                        {
                            name: t('@pages.workflow.instances'),
                            selector: row => row?.instanceTypeName ?? '',
                            cell: row => {
                                return <span>{row?.instanceCount + 'x ' + row?.instanceTypeName}</span>;
                            },
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={[
                        {
                            action: 'edit',
                            onClick: onClickEdit,
                        },
                        {
                            action: 'delete',
                        },
                    ]}
                />
            )}

            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    initialLoading={initLoading}
                    mainKeyProperty="title"
                    columns={[
                        {
                            name: t('@common.title'),
                            selector: row => row?.title ?? '',
                        },
                        {
                            name: t('@common.model-title'),
                            selector: row => row?.modelTitle ?? '',
                        },
                        {
                            name: 'Endpoint',
                            selector: row => row?.endpoint ?? '',
                            cell: row => {
                                if (row?.endpoint === '<pending>') return <span>{row?.endpoint}</span>;
                                return (
                                    <a
                                        onClick={e => e.stopPropagation()}
                                        href={row?.endpoint}
                                        className="text-blueMainText underline"
                                        target="_blank"
                                    >
                                        {row?.endpoint}
                                    </a>
                                );
                            },
                        },
                        {
                            name: t('@pages.workflow.instances'),
                            selector: row => row?.instanceTypeName ?? '',
                            cell: row => {
                                return (
                                    <span className="text-blueMainText text-sm py-0.5 flex flex-1 ">
                                        {row?.instanceCount + 'x ' + row?.instanceTypeName}
                                    </span>
                                );
                            },
                        },
                        {
                            name: 'Status',
                            selector: row => row?.status ?? '',
                            cell: row => <RunStatusComponent row={row} />,
                        },
                        {
                            name: t('@common.last-modified'),
                            selector: row => row?.lastModified ?? '',
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                        },
                    ]}
                    actions={[
                        {
                            action: 'edit',
                            onClick: onClickEdit,
                        },
                        {
                            action: 'delete',
                        },
                    ]}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!deploymentsResponse.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
