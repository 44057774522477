import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ConfirmCardSetupData, SetupIntentResult, StripeCardNumberElement } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Checkbox } from '../../../../components/buttons';
/**
 * Components
 */
import { AddressFormInputsType, BillingAddressForm, onValidateAddressForm } from '../../../../components/forms';
import { InputField } from '../../../../components/inputs';
import { ErrorTextComponent } from '../../../../components/messages';
import { SideModal } from '../../../../components/SideModal';
import { toast } from '../../../../components/ui';
import { useAccountId } from '../../../../hooks';
import {
    AccountAddressModel,
    AccountDetailsResponseModel,
    SetupIntentResponseModel,
    UserDetailsResponseModel,
} from '../../../../models';
import { selectors as accountSelectors } from '../../../../redux/thunk/app/account/accountDetailsThunk';
/**
 * Redux
 */
import { selectors as userSelectors } from '../../../../redux/thunk/app/user/userThunk';
import axios from '../../../../services/axios';
import { deleteErrorKey } from '../../../../utils';
/**
 * hooks - models -services
 */
import { useCardOptions } from './useCardOptions';

export interface AddPaymentCardModalProps {
    isVisible: boolean;
    onHide: () => any;
    onSuccess: (response: SetupIntentResult) => any;
    formTitle?: string;
}

type StripeCardsType = {
    cardNumber: {
        complete: boolean;
        errorMessage: string;
    };
    cardExpiry: {
        complete: boolean;
        errorMessage: string;
    };
    cardCVC: {
        complete: boolean;
        errorMessage: string;
    };
};

export const AddPaymentCardModal: React.FC<AddPaymentCardModalProps> = ({
    formTitle,
    isVisible,
    onHide,
    onSuccess,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const accountId = useAccountId();
    const { cardCvcOptions, cardExpiryOptions, cardNumberOptions } = useCardOptions();

    /**
     * Redux Selectors
     */
    const { model: userDetails }: UserDetailsResponseModel = useSelector(userSelectors.getResponse);
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountSelectors.getResponse);
    /**
     * Local State
     */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAddressSameAsAccount, setIsAddressSameAsAccount] = useState(false);
    const [cardHolderName, setCardHolderName] = useState(userDetails.fullName);
    const [addressValues, setAddressValues] = useState<AddressFormInputsType>({
        addressLine1: '',
        city: '',
        country: '',
        zipCode: '',
        addressLine2: '',
        region: '',
    });
    const [errors, setErrors] = useState<any>({});
    const [stripeCards, setStripeCards] = useState<StripeCardsType>({
        cardNumber: {
            complete: false,
            errorMessage: '',
        },
        cardExpiry: {
            complete: false,
            errorMessage: '',
        },
        cardCVC: {
            complete: false,
            errorMessage: '',
        },
    });

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setIsLoading(true);

            if (!stripe || !elements) {
                return;
            }
            const cardElement: StripeCardNumberElement | null = elements.getElement(CardNumberElement);

            if (!cardHolderName) {
                setErrors(prev => ({
                    ...prev,
                    cardHolderName: 'Card holder name is required!',
                }));
                return;
            } else {
                setErrors(prev => {
                    delete prev.cardHolderName;
                    return prev;
                });
            }
            if (!areCardsValid()) {
                return;
            }

            if (!cardElement || !onValidateAddressForm(addressValues, setErrors)) {
                return;
            }

            const response: SetupIntentResponseModel = await axios.post(`/stripe/setupIntent?accountId=${accountId}`);

            const { region, addressLine2, zipCode, country, addressLine1, city } = addressValues;
            const data: ConfirmCardSetupData = {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardHolderName,
                        address: {
                            city,
                            country: country === 'XK' ? 'AL' : country,
                            state: region,
                            line1: addressLine1,
                            line2: addressLine2,
                            postal_code: zipCode,
                        },
                    },
                },
            };
            const confirmCardResponse = await stripe.confirmCardSetup(response.model.clientSecret, data);

            if (confirmCardResponse.error) {
                toast.error(confirmCardResponse.error.message);
                return;
            }

            setIsAddressSameAsAccount(false);
            onClearAddressValues();
            cardElement.clear();

            onHide();
            onSuccess && onSuccess(confirmCardResponse);
        } catch (err: any) {
            console.log({ err });
        } finally {
            setIsLoading(false);
        }
    };

    const areCardsValid = () => {
        let valid = true;
        let _stripeCards: StripeCardsType = { ...stripeCards };

        Object.entries(stripeCards).forEach(([key, { complete, errorMessage }]) => {
            if (!complete || errorMessage) {
                _stripeCards[key] = {
                    complete,
                    errorMessage: errorMessage || 'This field is required!',
                };
                valid = false;
            }
        });

        !valid && setStripeCards(_stripeCards);
        return valid;
    };

    const onChangeAddressInputs = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setAddressValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };
    const onClearAddressValues = () => {
        setAddressValues({
            addressLine1: '',
            city: '',
            country: '',
            zipCode: '',
            addressLine2: '',
            region: '',
        });
    };

    const onFillAddressFromAccount = () => {
        const billingAddress: AccountAddressModel | null = accountDetails.address || null;
        if (billingAddress) {
            const { addressLine1, city, country, postalCode, state, addressLine2 } = billingAddress;
            setAddressValues({
                addressLine1,
                city,
                country,
                zipCode: postalCode,
                addressLine2,
                region: state,
            });
            setErrors(prev => ({
                ...prev,
                addressLine1: '',
                city: '',
                country: '',
                zipCode: '',
                addressLine2: '',
                region: '',
            }));
        }
    };

    const title = formTitle ? formTitle : t('add.payment.method');
    const formId = 'addCardForm';
    const buttonText = t('@common.add');

    return (
        <SideModal
            loading={isLoading}
            isVisible={isVisible}
            onHide={onHide}
            title={title}
            buttonText={buttonText}
            formId={formId}
        >
            <form onSubmit={handleSubmit} id={formId} className="flex flex-col h-full border-solid px-4 py-6">
                <InputField
                    required
                    label={t('card.holder.name')}
                    placeholder={t('@common.write.here')}
                    value={cardHolderName}
                    errorMessage={errors?.cardHolderName}
                    onChange={e => {
                        setCardHolderName(e.target.value);
                        deleteErrorKey('cardHolderName', setErrors);
                    }}
                />
                <div className="text-sm text-secondaryText mt-4">
                    <label className="text-xs sm:text-sm text-secondaryText font-normal">
                        {t('credit.card.number')} <span className="text-mainError text-base">{' *'}</span>
                    </label>
                    <CardNumberElement
                        onChange={({ error, complete }) => {
                            setStripeCards(prev => ({
                                ...prev,
                                cardNumber: {
                                    complete,
                                    errorMessage: error?.message || '',
                                },
                            }));
                        }}
                        options={{ ...cardNumberOptions, showIcon: true }}
                    />
                    <ErrorTextComponent message={stripeCards.cardNumber.errorMessage} />
                </div>
                <div className="flex flex-row mt-4">
                    <div className="flex flex-1 flex-col text-sm text-secondaryText">
                        <label className="text-xs sm:text-sm text-secondaryText font-normal">
                            {t('month.or.year')} <span className="text-mainError text-base">{' *'}</span>
                        </label>

                        <CardExpiryElement
                            onChange={({ error, complete }) => {
                                setStripeCards(prev => ({
                                    ...prev,
                                    cardExpiry: {
                                        complete,
                                        errorMessage: error?.message || '',
                                    },
                                }));
                            }}
                            options={cardExpiryOptions}
                        />
                        <ErrorTextComponent message={stripeCards.cardExpiry.errorMessage} />
                    </div>
                    <div className="flex flex-1 flex-col text-sm text-secondaryText">
                        <label className="text-xs sm:text-sm text-secondaryText font-normal ml-2">
                            CVC <span className="text-mainError text-base">{' *'}</span>
                        </label>
                        <CardCvcElement
                            onChange={({ error, complete }) => {
                                setStripeCards(prev => ({
                                    ...prev,
                                    cardCVC: {
                                        complete,
                                        errorMessage: error?.message || '',
                                    },
                                }));
                            }}
                            options={cardCvcOptions}
                        />
                        <ErrorTextComponent message={stripeCards.cardCVC.errorMessage} />
                    </div>
                </div>
                <div className="flex flex-col mt-10">
                    <span className="text-base text-blueMainText">{t('billing.address')}</span>
                    <div
                        className="mt-4 flex items-center cursor-pointer"
                        onClick={() => {
                            if (!isAddressSameAsAccount) {
                                onFillAddressFromAccount();
                            } else {
                                onClearAddressValues();
                            }
                            setIsAddressSameAsAccount(prev => !prev);
                        }}
                    >
                        <Checkbox isChecked={isAddressSameAsAccount} />
                        <span className="text-xs text-blueMainText ml-2">
                            {t('billing.address.same.as.account.address')}
                        </span>
                    </div>
                    <BillingAddressForm
                        className="pb-4"
                        errors={errors}
                        formValues={addressValues}
                        onChangeFormInputs={onChangeAddressInputs}
                    />
                </div>
            </form>
        </SideModal>
    );
};
