import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from '../components/ui';
import { StateErrorType } from '../models';
import { queryParams } from '../utils';

export function useBiskoIntegrationError({ selectors, path }: { selectors: any; path: string }) {
    const history = useHistory();
    const { t } = useTranslation();

    const error: StateErrorType = useSelector(selectors?.getError);

    useEffect(() => {
        if (error?.status === 404) {
            toast.error(
                t('@error.missing.bisko.integration', {
                    defaultValue: 'Bisko integration is missing for this account!',
                })
            );
            history.push(path + queryParams.formatForNavigation());
        } else if (error?.status === 403) {
            toast.error(
                t('@error.bisko.user.access', {
                    defaultValue: `Bisko Error: You don't have access to bisko organization!`,
                })
            );
            history.push(path + queryParams.formatForNavigation());
        }
    }, [error]);
}
