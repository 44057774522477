import { useEffect, useState } from 'react';
import { useAccountId } from '../../../../../hooks';
import {
    LookalikeEngineSimilaritiesListModel,
    LookalikeEngineSimilaritiesListResponseModel,
} from '../../../../../models/entity-models/api/lookalike-engine/lookalike-engine-similarities';
import axios from '../../../../../services/axios';
import { MetricsStatisticsWrapper } from './components/MetricsStatisticsWrapper';
import { ScatterChart } from './components/ScatterChart';

interface ILookalikeMetricsTabProps {
    lookalikeEngineId: number;
}

export const LookalikeMetricsTab = ({ lookalikeEngineId }: ILookalikeMetricsTabProps) => {
    const accountId = useAccountId();

    const [similarities, setSimilarities] = useState<LookalikeEngineSimilaritiesListModel[]>([]);
    const [similaritiesLoading, setSimilaritiesLoading] = useState(true);

    useEffect(() => {
        let timeout: any = null;

        timeout = setTimeout(() => fetchSimilarities(), 500);

        return () => clearTimeout(timeout);
    }, [accountId, lookalikeEngineId]);

    const fetchSimilarities = async () => {
        try {
            const queryParams = {
                accountId: accountId?.toString(),
                id: lookalikeEngineId?.toString(),
            };

            setSimilaritiesLoading(true);
            const response: LookalikeEngineSimilaritiesListResponseModel = await axios.get(
                `/lookalikeEngine/similarities?${new URLSearchParams(queryParams)}`
            );
            setSimilarities(response.models ?? []);
        } catch (err) {
        } finally {
            setSimilaritiesLoading(false);
        }
    };

    return (
        <>
            <div className="w-full h-auto">
                <MetricsStatisticsWrapper lookalikeEngineId={lookalikeEngineId} />
                <ScatterChart
                    loading={similaritiesLoading}
                    data={similarities}
                    noDataMessage="There are no data for this Lookalike engine yet!"
                />
            </div>
        </>
    );
};
