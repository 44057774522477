import React, { useEffect, useState } from 'react';
import { StatisticsOverview } from '../../../../../../components/common';
import { useAccountId } from '../../../../../../hooks';
import { RunnableEntityMetricsModel, RunnableEntityMetricsResponseModel } from '../../../../../../models';
import axios from '../../../../../../services/axios';

interface IMetricsStatisticsWrapperProps {
    lookalikeEngineId: number;
}

export const MetricsStatisticsWrapper = ({ lookalikeEngineId }: IMetricsStatisticsWrapperProps) => {
    const accountId = useAccountId();

    const [statistics, setStatistics] = useState<RunnableEntityMetricsModel | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStatistics();
    }, [lookalikeEngineId]);

    const fetchStatistics = async () => {
        try {
            const queryParams = {
                accountId: accountId?.toString(),
                id: lookalikeEngineId?.toString(),
            };

            setLoading(true);
            const response: RunnableEntityMetricsResponseModel = await axios.get(
                `/lookalikeEngine/metrics?${new URLSearchParams(queryParams)}`
            );
            setStatistics(response.model);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <StatisticsOverview
            className="card-shadow"
            loading={loading}
            data={[
                {
                    title: 'Total Training Hours',
                    value: (statistics?.totalTrainingHours.toFixed(2) ?? '0') + 'h',
                },
                {
                    title: 'Succeeded Executions',
                    value: `${statistics?.succeededExecutions ?? '0'}`,
                    valueStyle: { color: '#36B37E' },
                },
                {
                    title: 'Failed Executions',
                    value: `${statistics?.failedExecutions ?? '0'}`,
                    valueStyle: { color: '#E34850' },
                },
            ]}
        />
    );
};
