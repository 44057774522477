import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import update from 'immutability-helper';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Clickable } from '../../../../../components/common';
import { inputTypes } from '../../../../../constants';
import { BaseEntityProperty } from '../../../../../models';
import { AddTask } from '../components/AddTask';
import { PipelineTaskCard } from '../components/PipelineTaskCard';
import { TaskDetailsVisible } from '../components/TaskDetailsVisible';
import { createPipelineContext, TPipelineStepState } from '../contexts';

const errorMessage = '';
interface FieldsErrors {
    taskErrors: any;
    setTaskErrors: any;
}

export const TasksTab = React.memo(({taskErrors, setTaskErrors}: FieldsErrors) => {
    const { stepIdSelected, steps, stepsInputs, variables, setStepIdSelected, setSteps, setStepsInputs } =
        useContext(createPipelineContext);
    const [rightPartVisible, setRightPartVisible] = useState(
        steps.length > 0 && (stepIdSelected === 'addTask' || stepIdSelected === '')
            ? `${steps.map(x => x._id)[steps.length - 1]}`
            : stepIdSelected === ''
            ? 'addTask'
            : stepIdSelected
    );

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setSteps((prev: TPipelineStepState[]) =>
            update(prev, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prev[dragIndex] as TPipelineStepState],
                ],
            })
        );
    }, []);

    useEffect(() => {
        let stepsOutputReferenceNames: string[] = steps.map(x => {
            return x.outputReferenceName;
        });
        steps.map((step, currentStepIndex) => {
            const currentStepInputs = stepsInputs.find(x => x._id === step._id);

            let newInputs: BaseEntityProperty[] = [];
            currentStepInputs?.inputs.map(input => {
                if (input.inputType === inputTypes.dropdownOpen) {
                    let inputValue = input.value?.value ?? input.value;
                    let inputReferenceName = inputValue?.split('.')[0];

                    input.options.map(option => {
                        if (!variables.find(x => x.name == option.label)) {
                            input.options = input.options.filter(x => x.label !== option.label);
                        }
                    });

                    let isOutputReferenceName = stepsOutputReferenceNames.includes(inputReferenceName);
                    let outputVariableExists = variables.find(x => x.isOutput && x.name === inputValue) !== null;

                    //If option is outputVariable
                    if (isOutputReferenceName && outputVariableExists) {
                        let indexOfOutputVariableStep = steps.findIndex(
                            x => x.outputReferenceName === inputReferenceName
                        );

                        if (currentStepIndex < indexOfOutputVariableStep) {
                            let newInput = { ...input, options: [], value: '' };
                            newInputs.push(newInput);
                            return;
                        }
                    }

                    //If option is variable
                    let inputValueExistsAsVariable = !variables.find(x => !x.isOutput && x.name === inputValue);
                    if (!isOutputReferenceName && inputValueExistsAsVariable) {
                        let newInput = { ...input, options: [], value: '' };
                        newInputs.push(newInput);
                        return;
                    }

                    var valueExistsAsOption = !!input.options.find(x => x.label === input.value?.label);
                    if (!valueExistsAsOption) {
                        input.value = '';
                    }
                }
                newInputs.push(input);
            });
            setStepsInputs(prev => prev.map(x => (x._id === step._id ? { ...x, inputs: newInputs } : x)));
        });
    }, [steps, variables]);

    const _idSelected = useMemo(() => {
        if (rightPartVisible === 'addTask' || rightPartVisible === '') {
            setStepIdSelected('');
            return '';
        }

        setStepIdSelected(rightPartVisible);
        return rightPartVisible;
    }, [rightPartVisible]);

    const onStepDeleted = () => {
        const stepsIds = steps.map(x => x._id).filter(x => x !== _idSelected);

        setRightPartVisible(stepsIds[stepsIds.length - 1] ?? 'addTask');
    };

    return (
        <div className="flex flex-col md:flex-row w-full h-[600px]">
            <div
                className="flex flex-2 flex-col overflow-y-auto scroll md:border-r md:border-mainBorder p-4"
                style={{ scrollbarGutter: 'stable' }}
            >
                <div className="w-full flex items-center justify-between mb-4 top-0">
                    <h2 className="text-base text-blueMainText font-medium">Pipeline's Tasks</h2>
                    <Clickable
                        onClick={() => setRightPartVisible('addTask')}
                        className={`p-2 text-primaryBlue500 border rounded transition duration-300  ${
                            rightPartVisible === 'addTask' ? 'border-primaryBlue200' : 'border-white'
                        } hover:border-primaryBlue200`}
                    >
                        <GjirafaIcon name="Plus" />
                    </Clickable>
                </div>

                <DndProvider backend={HTML5Backend}>
                    {steps.map((x, index) => (
                        <PipelineTaskCard
                            onSelect={() => setRightPartVisible(x._id)}
                            imageUrl={x.taskType.imageUrl}
                            errorMessage={errorMessage}
                            isSelected={_idSelected === x._id}
                            displayName={x.displayName}
                            taskName={x.taskTitle}
                            index={index}
                            _id={x._id}
                            moveCard={moveCard}
                            key={x._id}
                        />
                    ))}
                </DndProvider>
            </div>
            <div className="flex flex-3 flex-col overflow-y-auto h-full p-4" style={{ scrollbarGutter: 'stable' }}>
                {rightPartVisible === 'addTask' ? (
                    <AddTask />
                ) : _idSelected ? (
                    <TaskDetailsVisible onDeleted={onStepDeleted} _id={_idSelected} errors={taskErrors} setErrors={setTaskErrors}/>
                ) : null}
            </div>
        </div>
    );
});
