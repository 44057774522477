import React, { CSSProperties } from 'react';

export interface ActionsButtonProps {
    style?: CSSProperties;
    className?: string;
    dotStyle?: CSSProperties;
}
export const ActionsButton: React.FC<ActionsButtonProps> = ({ className, style, dotStyle }) => {
    const Dot = () => (
        <div
            className="rounded-full bg-secondaryText"
            style={{ margin: 1, height: 4, width: 4, borderRadius: 2, ...dotStyle }}
        />
    );

    return (
        <div
            style={{ ...style }}
            className={`flex justify-center items-center w-6 h-6 cursor-pointer transition-all duration-300 hover:bg-mainBackground rounded ${className}`}
        >
            <Dot />
            <Dot />
            <Dot />
        </div>
    );
};
