import { RecommendationEngineTypes } from '../../../../constants';

export interface RecommendationInformationProps {
    recommendationEngineType: RecommendationEngineTypes;
    className?: string;
}

export const RecommendationInformation: React.FC<RecommendationInformationProps> = ({ className }) => {
    return (
        <div className={`pb-10 flex flex-col md:flex-row w-full ${className}`}>
            <div className="flex flex-col mt-5 sm:mt-10 md:mt-20 flex-1 md:mr-5">
                <span className="text-blueMainText font-normal text-sm text-left">
                    Our recommendation engine is an expert system of hybrid ensemble recommenders. The full range of
                    recommendation algorithms powers it. The spectrum of used algorithms starts with non-personalized
                    algorithms and ends with highly specialized deep learning motivated personalized recommendation
                    methods.
                    <br />
                    <br />
                    In between are well known collaborative, content, case and sequence based recommendation algorithms.
                    Each recommendation request goes through complex decision-making mechanism involving these
                    algorithms. Moreover, recommendations are served by low latency highly scalable recommendation
                    serving system.
                </span>
            </div>
            <div className="mt-5 sm:mt-10 md:mt-20 flex flex-col items-start flex-1 md:ml-5">
                <span className="text-blueMainText font-normal text-sm text-left">
                    Given the cascade design of the engine even the first time visitors will be served at least by the
                    subset of recommendation algorithms.
                    <br />
                    <br />
                    Fully managed engine: No need to preprocess data, train or hypertune machine learning models, load
                    balance or manually provision your infrastructure to handle unpredictable traffic spikes. We do it
                    all for you automatically.
                    <br />
                    <br />
                    Click below to start using Recommendation Engine
                </span>
                {/* <CreateButton
                    className="mt-10"
                    title="Start Recommendation Engine"
                    onClick={() => onStartRecommendation && onStartRecommendation()}
                /> */}
            </div>
        </div>
    );
};
