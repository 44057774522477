import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { FixedLoader } from './components/ui';
import i18n from './config/i18n';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

const publishableKey: string = process.env.REACT_APP_STRIPE_PUBLISH_KEY || '';

const stripePromise = loadStripe(publishableKey);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Elements stripe={stripePromise}>
                <I18nextProvider i18n={i18n}>
                    <Suspense fallback={<FixedLoader />}>
                        <App />
                    </Suspense>
                </I18nextProvider>
            </Elements>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
