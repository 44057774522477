import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import {
    ButtonHTMLAttributes,
    cloneElement,
    CSSProperties,
    FC,
    isValidElement,
    MouseEvent,
    ReactElement,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Transition } from 'react-transition-group';
import { dropdownAnimationStyle } from '../../styles/animationStyles';
import { cx } from '../../utils';

export interface DropdownProps {
    wrapperClassName?: string;
    triggerProps?: ButtonHTMLAttributes<HTMLButtonElement>;
    closeIcon?: boolean;
    withOpacity?: boolean;
    children?: ReactNode;
    withArrow?: boolean;
    onOpenChanged?: (value: boolean) => any;
    childrenContainerStyle?: CSSProperties;
    childrenContainerClassName?: string;
    animationStyle?: object;
    _onClickTrigger?: (e: any) => any;
    openProps?: {
        onSetIsOpen: (isOpen: boolean) => any;
        isOpen: boolean;
    };
}

export const Dropdown: FC<DropdownProps> = ({
    wrapperClassName,
    triggerProps,
    withArrow = true,
    children = null,
    childrenContainerStyle,
    childrenContainerClassName,
    animationStyle = dropdownAnimationStyle,
    onOpenChanged,
    _onClickTrigger,
    openProps,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (event.target instanceof HTMLElement) {
                if (!ref.current || ref.current.contains(event.target)) return;
                if (openProps) {
                    openProps.onSetIsOpen(false);
                } else {
                    setOpen(false);
                }
            }
        };
        document.addEventListener('mousedown', (event: any) => listener(event));
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', (event: any) => listener(event));
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, setOpen]);

    useEffect(() => {
        onOpenChanged && onOpenChanged(open);
    }, [open]);

    const onTriggerClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (_onClickTrigger) {
            _onClickTrigger(event);
        }
        if (triggerProps?.onClick) triggerProps.onClick(event);

        if (openProps) {
            openProps.onSetIsOpen(!openProps.isOpen);
        } else {
            setOpen(!open);
        }
    };

    return (
        <div className={cx(['relative', wrapperClassName])} ref={ref}>
            <button
                {...triggerProps}
                className={cx(['flex justify-center items-center text-sm focus:outline-none', triggerProps?.className])}
                onClick={onTriggerClick}
            >
                {triggerProps?.children}
                {withArrow && (
                    <span className="ml-2 mt-1 pointer-events-none">
                        <GjirafaIcon name="ArrowDown" size={18} />
                    </span>
                )}
            </button>
            <Transition in={openProps ? openProps.isOpen : open} timeout={50} unmountOnExit={true}>
                {state => (
                    <div
                        id="dropdownContainer"
                        className={cx(['absolute right-0 z-40', childrenContainerClassName])}
                        role="menu"
                        style={{
                            ...animationStyle[state],
                            ...childrenContainerStyle,
                        }}
                    >
                        {isValidElement(children) && cloneElement(children as ReactElement, {})}
                    </div>
                )}
            </Transition>
        </div>
    );
};
