import { CSSProperties, FC, ReactNode, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { ANIMATION_PAGE_DURATION } from '../../constants/animation-constants';
import { useAccountId } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
/**
 * Models
 */
import { DeleteStateType, ListResponseModel } from '../../models';
import axios from '../../services/axios';
import { AnimationComponent } from '../animation';
import { Checkbox } from '../buttons';
import { InfiniteScroll } from '../common';
import { ConfirmationPopupModal } from '../modals';
/**
 * Components
 */
import { Loader, toast } from '../ui';
import {
    HeaderButtons,
    HeaderButtonsProps,
    NoDataComponent,
    NoDataComponentProps,
    TableActionRowProps,
    TableMobileItem,
} from './components';
import { PaginationComponentProps } from './components/PaginationComponent';

type IDataTableColumn = TableColumn<any>;

export interface TableMobileActionProps extends TableActionRowProps {}
export interface TableMobileProps {
    modelEndpoint: string;
    requestList?: (query: any, resetQuery?: boolean) => any;
    selectableRows?: boolean;
    columns: Array<IDataTableColumn>;
    response: ListResponseModel<any>;
    style?: CSSProperties;
    className?: string;
    pagination?: boolean;
    loading?: boolean;
    initialLoading?: boolean;
    actions?: TableMobileActionProps[];
    header?: ReactNode;
    footer?: ReactNode;
    mainKeyProperty?: string;
    paginationProps: PaginationComponentProps & { hasNextPage: boolean };
    emptyDataProps?: NoDataComponentProps;
    onClickItem?: (row: any) => any;
    selectedItem?: any;
    showHeaderButtons?: boolean;
    headerButtonProps?: HeaderButtonsProps;
    onSelectedItemsChange?: (selectedItems: any[]) => any;
    isFromSelectPopup?: boolean;
}

export const TableMobile: FC<TableMobileProps> = ({
    header,
    response,
    requestList,
    modelEndpoint,
    selectableRows = false,
    footer,
    className,
    columns,
    actions,
    mainKeyProperty = 'title',
    loading,
    initialLoading,
    emptyDataProps,
    paginationProps,
    selectedItem,
    onClickItem,
    showHeaderButtons = false,
    headerButtonProps,
    isFromSelectPopup,
    onSelectedItemsChange,
}) => {
    const accountId = useAccountId();
    const { t } = useTranslation();
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    /**
     * Local state
     */
    const [deleteState, setDeleteState] = useState<DeleteStateType>({
        loading: false,
        isFromActions: false,
    });
    const [selectedItems, setSelectedItems] = useState<any[] | null>(null);

    useEffect(() => {
        if (selectedItems !== null) {
            onSelectedItemsChange && onSelectedItemsChange(selectedItems);
        }
    }, [selectedItems]);

    const onClickAction = (action: string, item: any) => {
        if (action.includes('delete')) {
            setSelectedItems([item]);
            onToggleVisibility(true);
            setDeleteState(prev => ({ ...prev, isFromActions: true }));
        }
    };

    const handleDelete = async () => {
        try {
            let ids = selectedItems ? selectedItems.map(x => x?.id) : [];
            if (ids.length < 1) {
                return;
            }
            setDeleteState(prev => ({ ...prev, loading: true }));

            let searchQuery = '';
            ids.forEach(id => {
                searchQuery += `id=${id}&`;
            });

            await axios.delete(`/${modelEndpoint}?${searchQuery}accountId=${accountId}`);
            toast.success(t(`@toast-messages.success-${modelEndpoint}-delete`));

            if (requestList) {
                requestList({ page: 0 });
            }

            setSelectedItems([]);
            onToggleVisibility(false);
        } catch (err: any) {
        } finally {
            setDeleteState({
                loading: false,
                isFromActions: false,
            });
        }
    };

    const _onClickItem = item => {
        if (selectableRows) {
            setSelectedItems(prev => {
                if (prev === null) return [item];

                let selected = !!prev.find(x => x?.id === item?.id);
                return selected ? prev.filter(x => x?.id !== item?.id) : [...prev, item];
            });
            return;
        }
        onClickItem && onClickItem(item);
    };

    const renderItems = () => {
        const _selectedItemsId = selectedItems ? selectedItems.map(x => x?.id) : [];

        if (initialLoading)
            return (
                <div className="h-auto w-full py-20 flex flex-col items-center justify-center">
                    <Loader />
                </div>
            );

        if (response.models.length < 1) return <NoDataComponent {...emptyDataProps} />;

        return response.models.map((item, index) => {
            let isSelected = _selectedItemsId.includes(item?.id) || selectedItem?.id === item?.id;
            return (
                <TableMobileItem
                    isSelected={isSelected}
                    actions={actions}
                    item={item}
                    columns={columns}
                    key={item?.id || index}
                    mainKeyProperty={mainKeyProperty}
                    onClickItem={_onClickItem}
                    selectableRows={selectableRows}
                    onClickAction={onClickAction}
                />
            );
        });
    };

    const loadNextPage = () => {
        if (!loading && paginationProps.onChangePage) {
            const currentPage = paginationProps.currentPage || 0;
            paginationProps.onChangePage(currentPage + 1);
        }
    };

    const allSelected = response.models.length === selectedItems?.length;
    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    title={t(`@confirm-delete.${modelEndpoint}`)}
                    isVisible={isModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteState.loading}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    description={`Are you sure you want to delete ${selectedItems ? selectedItems.length : ''} item${
                        selectedItems && selectedItems?.length > 1 ? 's' : ''
                    }`}
                />
            )}
            {showHeaderButtons && (
                <AnimationComponent show={true} type="fade-top" delay={ANIMATION_PAGE_DURATION} duration={300}>
                    <div className="w-full">
                        <HeaderButtons
                            showDelete
                            showEdit={false}
                            multiSelectActive={!!selectedItems?.length}
                            onClickDelete={() => {
                                onToggleVisibility(true);
                            }}
                            style={{ zIndex: 0 }}
                            className={`${!isFromSelectPopup && 'pt-2'}`}
                            {...headerButtonProps}
                        />
                    </div>
                </AnimationComponent>
            )}
            {selectableRows && response.models.length > 0 && (
                <div
                    className="flex items-center justify-start pb-3 pl-4 cursor-pointer"
                    onClick={() => {
                        if (!allSelected) {
                            setSelectedItems(response.models);
                        } else {
                            setSelectedItems([]);
                        }
                    }}
                >
                    <Checkbox isChecked={allSelected} />
                    <span className="text-sm font-medium text-mainBlue ml-2">
                        {allSelected ? 'Deselect' : 'Select'} all
                    </span>
                </div>
            )}
            <InfiniteScroll
                className={`flex h-auto box-border flex-col w-full sm:hidden ${className}`}
                loadMore={loadNextPage}
                hasMore={paginationProps.hasNextPage}
                isInitialLoading={initialLoading}
                loading={loading}
                currentPage={paginationProps.currentPage}
                displayShowMore
            >
                {header}
                {renderItems()}
                {footer}
            </InfiniteScroll>
        </>
    );
};
