import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getToken } from '../utils';

const options: InitOptions = {
    // order: ['navigator', 'localStorage'],
    // caches: ['localStorage'],
    debug: process.env.NODE_ENV === 'production' ? false : true,
    load: 'currentOnly',
    fallbackLng: 'en-US',
    saveMissing: true, // send not translated keys to endpoint
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
        useSuspense: true,
    },
    backend: {
        customHeaders: {
            authorization: `Bearer ${getToken()}`,
        },
        requestOptions: () => {
            const access_token = getToken();
            return {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    Accept: 'application/json',
                },
            };
        },
        loadPath: `${process.env.REACT_APP_BASE_URL}/api/language/languageResources?id=0&isoCode={{lng}}`,
        addPath: `${process.env.REACT_APP_BASE_URL}/api/resourceKey/i18n`,
        crossDomain: true,
    },
    keySeparator: false,
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
