import { useTranslation } from 'react-i18next';
import { CreateButton, OutlineButton } from '../../buttons';

export interface FooterProps {
    fireBtn: (e: any) => any;
    value: string;
    hide: (e: any) => any;
    formId?: any;
    showCreateButton?: boolean;
    cancelButtonText?: string;
    loading?: boolean;
}

const Footer: React.FC<FooterProps> = ({
    fireBtn,
    value,
    hide,
    formId,
    cancelButtonText,
    showCreateButton = true,
    loading,
}) => {
    const { t } = useTranslation();

    return (
        <div className="border-t border-grey200 w-full py-6 px-4 flex justify-end bg-white sticky bottom-0">
            <OutlineButton
                htmlType="button"
                onClick={hide}
                className="mr-4"
                style={{
                    minWidth: 100,
                }}
                title={cancelButtonText || t('@common.cancel')}
            />
            {showCreateButton && (
                <CreateButton
                    style={{
                        minWidth: 100,
                        justifyContent: 'center',
                    }}
                    loading={loading}
                    title={value}
                    form={formId}
                    onClick={fireBtn}
                />
            )}
        </div>
    );
};

export default Footer;
