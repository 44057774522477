import { CSSProperties, InputHTMLAttributes, ReactNode, useState } from 'react';
import { inputAnimationClassName, inputTypes } from '../../constants';
import { Icon, IconTypes } from '../common';
import { ErrorTextComponent } from '../messages';

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    label?: string;
    name?: string;
    htmlType?: string;
    className?: string;
    style?: CSSProperties;
    inputClassName?: string;
    inputStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    labelClassName?: string;
    displayAccountIdInLabel?: boolean;
    hideLabel?: boolean;
    labelRight?: ReactNode;
    isClearable?: boolean;
    onClearValue?: () => void;
}

export const InputField: React.FC<InputFieldProps> = ({
    className,
    style,
    errorClassName,
    errorMessage,
    errorStyle,
    inputClassName,
    inputStyle,
    label,
    labelClassName,
    labelStyle,
    name,
    displayAccountIdInLabel = true,
    hideLabel,
    labelRight,
    type,
    htmlType,
    required,
    isClearable,
    onClearValue,
    ...restProps
}) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const _inputClassName = `w-full border text-blueMainText rounded h-8 sm:h-10 box-border py-2 px-3 placeholder-mainPlaceholder font-normal text-xs sm:text-sm transition-all duration-300 ease-in  ${
        !restProps.disabled && inputAnimationClassName
    } ${errorMessage ? 'border-mainError transition-all' : 'border-grey200 transition-all'} ${inputClassName}`;

    return (
        <div
            className={`w-full flex flex-col relative ${className} ${restProps.disabled && 'cursor-not-allowed'}`}
            style={style}
        >
            {!hideLabel && (
                <div
                    style={{
                        minHeight: 24,
                    }}
                    className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                >
                    <label className="text-xs sm:text-sm text-secondaryText font-normal">
                        {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                    </label>
                    {labelRight && labelRight}
                </div>
            )}
            {type === inputTypes.password ? (
                <div className={`flex flex-row items-center ${_inputClassName}`}>
                    <input
                        name={name}
                        className={`w-full`}
                        type={type === inputTypes.password ? (passwordVisible ? 'text' : 'password') : type}
                        {...restProps}
                    />
                    {type === inputTypes.password && (
                        <Icon
                            name={passwordVisible ? IconTypes['eye-off'] : IconTypes.eye}
                            className="mr-3 cursor-pointer ml-3 z-10"
                            style={{ height: 18, width: 18 }}
                            onClick={() => setPasswordVisible(p => !p)}
                        />
                    )}
                </div>
            ) : (
                <input name={name} className={_inputClassName} type={htmlType} {...restProps} />
            )}
            {isClearable && (
                <div
                    className="h-8 sm:h-10 flex flex-col absolute items-center z-10 justify-center cursor-pointer"
                    style={{
                        width: 32,
                        right: 0,
                        bottom: 0,
                    }}
                    onClick={() => {
                        if (onClearValue) {
                            onClearValue();
                        }
                    }}
                >
                    <Icon name={IconTypes['close-bold']} size={10} />
                </div>
            )}
            <ErrorTextComponent message={errorMessage} />
        </div>
    );
};
