import { Dispatch } from 'redux';
import { CustomEndpointVersionListResponseModel, InitialStateType } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator, getFormattedQuery, getFormattedResponseForPayload, getSearchParams } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/custom-endpoint/customEndpointVersionsThunk/REQUEST',
    SUCCESS: '@app/custom-endpoint/customEndpointVersionsThunk/SUCCESS',
    FAILURE: '@app/custom-endpoint/customEndpointVersionsThunk/FAILURE',
};

/**
 * Initial state
 */
const initialState: InitialStateType<CustomEndpointVersionListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
        query: {},
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.customEndpointVersions.isLoading,
    getResponse: (state: RootState) => state.app.customEndpointVersions.response,
    getErrorMessage: (state: RootState) => state.app.customEndpointVersions.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (state: InitialStateType<CustomEndpointVersionListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
                initialLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                initialLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query: any, resetQuery = false) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const previousQuery = getState().app.customEndpointVersions.response.query;

                if (query?.page === undefined) {
                    query.page = previousQuery?.page || 0;
                }

                dispatch(actionCreator(REQUEST));

                let searchParams = getSearchParams(previousQuery, query, resetQuery);

                const response: CustomEndpointVersionListResponseModel = await axios.get(
                    `/customEndpoint/version/list?${getFormattedQuery(searchParams)}`
                );

                let res = getFormattedResponseForPayload(response, [], query, searchParams);

                dispatch(actionCreator(SUCCESS, res));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
