import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { conditionsOperators } from '../../constants';
import { useAccountId } from '../../hooks';
import { CatalogDetailsModel, CatalogDetailsResponseModel, CatalogListModel, SelectOptionType } from '../../models';
import { actions as filterActions } from '../../redux/thunk/app/filter/filtersThunk';
import axios from '../../services/axios';
import { deleteErrorKey, getFormattedQuery } from '../../utils';
import { InputField, SelectWithFilterPopupField, TextareaField } from '../inputs';
import { ErrorTextComponent } from '../messages';
import { SideModal } from '../SideModal';
import { Loader, toast } from '../ui';

/**
 * Types
 */
export interface CreateCatalogFilterModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    idForEdit?: number;
    onSuccess?: (isEditing?: boolean) => any;
    parentCatalog: CatalogListModel | null;
}
type FieldValuesType = {
    name: string;
    description: string;
    filters: SelectOptionType[];
    filtersOperator: SelectOptionType | null;
};

export const CreateCatalogFilterModal: React.FC<CreateCatalogFilterModalProps> = ({
    isVisible,
    parentCatalog,
    onHide,
    isEditing,
    onSuccess,
    idForEdit,
}) => {
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /**
     * Local State
     */
    const [errors, setErrors] = useState<any>({});
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [catalogDetails, setCatalogDetails] = useState<CatalogDetailsModel | null>(null);
    const [formLoading, setFormLoading] = useState(false);
    const [fieldValues, setFieldValues] = useState<FieldValuesType>({
        description: '',
        name: '',
        filters: [],
        filtersOperator: conditionsOperators[0],
    });
    const [catalogFilters, setCatalogFilters] = useState<{ id: number; filterId: number }[]>([]);

    useEffect(() => {
        if (!isVisible) {
            return;
        }
        let query: any = {
            accountId: accountId?.toString(),
        };

        if (!isEditing) {
            query.schemaTypeId = {
                value: parentCatalog?.schemaTypeId?.toString(),
                label: parentCatalog?.schemaType,
            };
            dispatch(filterActions.request(query));
        } else if (catalogDetails) {
            query.schemaTypeId = {
                value: catalogDetails?.schemaType.id?.toString(),
                label: catalogDetails?.schemaType.name,
            };
            dispatch(filterActions.request(query));
        }
    }, [isVisible, catalogDetails]);

    useEffect(() => {
        if (isEditing && idForEdit) {
            requestDetails(idForEdit);
        }
    }, [isEditing, idForEdit]);

    const requestDetails = async id => {
        try {
            setDetailsLoading(true);
            const query = {
                id,
                accountId,
            };

            let catalogResponse: CatalogDetailsResponseModel = await axios.get(`/catalog?${getFormattedQuery(query)}`);
            setCatalogDetails(catalogResponse.model);
            setFieldValues({
                description: catalogResponse.model.description,
                name: catalogResponse.model.name,
                filtersOperator:
                    conditionsOperators.find(x => x.value === catalogResponse.model.filtersOperator) ||
                    conditionsOperators[0],
                filters: catalogResponse.model.filters.map(x => ({
                    label: x.filterTitle,
                    value: x.id.toString(),
                    ...x,
                })),
            });
            setCatalogFilters(catalogResponse.model.filters.map(x => ({ filterId: x.filterId, id: x.id })));
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setFormLoading(true);
            if (!validateForm() || formLoading) {
                return;
            }
            let details: CatalogDetailsModel | null = null;

            if (isEditing) {
                details = catalogDetails;
            } else if (parentCatalog) {
                const query = {
                    id: parentCatalog.id,
                    accountId,
                };

                let response: CatalogDetailsResponseModel = await axios.get(`/catalog?${getFormattedQuery(query)}`);
                details = response.model;
            }

            if (!details) {
                toast.error('Something went wrong while getting parent catalog details!');
                return;
            }

            let payload = {
                id: isEditing ? details.id : 0,
                accountId,
                name: fieldValues.name,
                description: fieldValues.description,
                filtersOperator: fieldValues.filtersOperator?.value,
                filters: fieldValues.filters.map(_filter => ({
                    id: isEditing ? catalogFilters.find(x => x.id.toString() === _filter.value)?.id ?? 0 : 0,
                    filterId:isEditing ? _filter.filterId ?? _filter.value : parseInt(_filter.value),
                })),
                parentId: isEditing ? details?.parentId : details.id,
                artifactId: details.artifact.id,
                schemaTypeId: details.schemaType.id,
                values: details.values.map(_value => ({
                    id: _value.id,
                    propertyId: _value.propertyId,
                    value: _value.value,
                })),
            };

            await axios.post('/catalog', payload);
            onSuccess && onSuccess(!!isEditing);
            onHide();
        } catch (err: any) {
        } finally {
            setFormLoading(false);
        }
    };

    const onChangeFieldValues = (key: string, value) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const validateForm = () => {
        let isValid = true;
        let _errors: any = {};

        if (!fieldValues.name) {
            isValid = false;
            _errors.name = 'Name is required!';
        }

        if (fieldValues.filters.length < 1) {
            isValid = false;
            _errors.filters = 'Select one or more Filter!';
        }

        setErrors(_errors);
        return isValid;
    };

    const { buttonText, title, formId } = useMemo(() => {
        return {
            title: isEditing
                ? t('@components.modals.edit-catalog-filter')
                : t('@components.modals.create-catalog-filter'),
            buttonText: isEditing ? t('@common.edit') : t('@common.create'),
            formId: isEditing ? 'editSubCatalog' : 'createSubCatalog',
        };
    }, []);

    return (
        <SideModal
            onHide={() => {
                setErrors({});
                onHide();
            }}
            isVisible={isVisible}
            title={title}
            buttonText={buttonText}
            formId={formId}
            loading={formLoading}
        >
            {detailsLoading ? (
                <div className="flex justify-center items-center p-10">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit} id={formId} className="px-4 py-6">
                    <InputField
                        errorMessage={errors?.name}
                        required
                        label={t('@common.name')}
                        name="catalog"
                        id="catalogFilterTitle"
                        value={fieldValues.name}
                        onChange={e => onChangeFieldValues('name', e.target.value)}
                        placeholder={t('@common.write.here')}
                    />

                    <TextareaField
                        className="mt-4"
                        placeholder={t('@common.write.here')}
                        label={t('@common.description')}
                        value={fieldValues.description}
                        onChange={e => onChangeFieldValues('description', e.target.value)}
                    />

                    <SelectWithFilterPopupField
                        onChangeOperator={item => onChangeFieldValues('filtersOperator', item)}
                        operatorSelected={fieldValues.filtersOperator}
                        className="mt-4"
                        placeholder={t('@select.filters')}
                        onChangeSelectedItems={_filters => onChangeFieldValues('filters', _filters)}
                        selectedItems={fieldValues.filters}
                        errorMessage={errors?.filters}
                        label={t('@common.filter')}
                        required
                    />
                    <ErrorTextComponent message={errors?.filter} />
                </form>
            )}
        </SideModal>
    );
};
