import { useTranslation } from 'react-i18next';
import { Button, CreateButton } from '../../../../components/buttons';
import { BasePopup } from '../../../../components/modals';

export const PayNowConfirmPopup = ({ onSubmitPayNow, isVisible, onHide, onSubmitLoading }) => {
    const { t } = useTranslation();

    return (
        <BasePopup isVisible={isVisible} onHide={onHide}>
            <div
                className="bg-white p-6 rounded flex flex-col"
                style={{ minWidth: '420px', width: '420px', maxWidth: '100%' }}
            >
                <span className="text-greyBlack300 text-base font-medium mb-6">{t('@confirm-pay.now')}</span>
                <div className="w-full flex justify-end items-center mt-2">
                    <Button
                        title={t('@common.cancel')}
                        className="mr-2"
                        onClick={() => {
                            onHide();
                        }}
                    />
                    <CreateButton
                        loading={onSubmitLoading}
                        disabled={onSubmitLoading}
                        title={t('pay.now')}
                        className="w-auto text-white"
                        onClick={onSubmitPayNow}
                    />
                </div>
            </div>
        </BasePopup>
    );
};
