import React, { CSSProperties } from 'react';

interface StatisticsOverviewProps {
    className?: string;
    style?: CSSProperties;
    data: { title: string; value: string; valueClassName?: string; valueStyle?: CSSProperties }[];
    loading?: boolean;
}

export const StatisticsOverview: React.FC<StatisticsOverviewProps> = ({ data, className, style, loading }) => {
    return (
        <div
            className={`flex flex-col sm:flex-row w-full border rounded border-mainBorder bg-white px-2 ${className}`}
            style={style}
        >
            {data?.map((item, i) => (
                <div key={i} className={`flex flex-1 sm:flex-row flex-col`}>
                    {i > 0 && (
                        <div className="flex flex-col w-full h-full sm:w-auto px-5 sm:py-5 mb-4 sm:mr-5 md:mr-8 xl:mr-10">
                            <div className="h-full bg-mainBorder sm:visible" style={{ width: 1 }} />
                            <div className="sm:hidden w-full bg-mainBorder" style={{ height: 1 }} />
                        </div>
                    )}
                    <div
                        className={`flex flex-1 items-center justify-between sm:items-start flex-col py-3 ${
                            i === 0 ? 'pl-3' : ''
                        }`}
                    >
                        <span className="text-secondaryText text-xs font-medium">{item?.title}</span>
                        {loading ? (
                            <div className="animate-pulse duration-75 flex mt-2">
                                <div className="bg-grey300 w-4 h-8 rounded" />
                                <div className="bg-grey300 w-4 h-8 rounded ml-1" />
                            </div>
                        ) : (
                            <span
                                style={item.valueStyle}
                                className={`mt-2 text-blueDark text-xl md:text-2xl font-bold ${item.valueClassName}`}
                            >
                                {item.value}
                            </span>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
