import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationComponent } from '../animation';

export type DetailsInfoOverviewDataType = Record<
    string,
    | {
          value: string;
          style?: CSSProperties;
          className?: string;
      }
    | ReactNode
>;

export interface DetailsInfoOverviewProps {
    data: DetailsInfoOverviewDataType | null;
    title: string;
    titleIcon?: string;
    titleIconComponent?: ReactNode;
    titleRightComponent?: ReactNode;
    loading: boolean;
    className?: string;
    style?: CSSProperties;
}

const getGridClasses = (length: number) =>
    `sm:grid-flow-rows sm:grid sm:grid-cols-2 md:grid-cols-3 sm:grid-rows-${Math.ceil(
        length / 2
    )} md:grid-rows-${Math.ceil(length / 3)}`;

const singleInfoClassName = 'flex p-2 flex-col items-start';
const keyLoadingClassName = 'h-4 w-28 rounded bg-secondaryTextLight';
const valueLoadingClassName = 'mt-2 h-5 w-40 rounded bg-blueMainTextLight';

export const DetailsInfoOverview: React.FC<DetailsInfoOverviewProps> = ({
    data,
    loading,
    title,
    children,
    className,
    titleIcon,
    titleRightComponent,
    style,
    titleIconComponent,
}) => {
    const { t } = useTranslation();

    const dataArray = useMemo(() => (data ? Object.entries(data) : []), [data]);

    return (
        <AnimationComponent show type="fade-top" duration={300} delay={100}>
            <div
                className={`p-4 w-full h-auto bg-white rounded grid-rows- card-shadow ${className}`}
                style={{ ...style }}
            >
                <div className="flex w-full justify-between mb-2">
                    <div className="flex items-center justify-start">
                        {titleIconComponent ? (
                            titleIconComponent
                        ) : titleIcon ? (
                            <div className="mr-4">
                                <GjirafaIcon name={titleIcon} size={24} />
                            </div>
                        ) : null}
                        <span className="text-blueMainText text-base font-normal">{title}</span>
                    </div>
                    {titleRightComponent}
                </div>

                {loading ? (
                    <div className={`w-full ${getGridClasses(6)} sm:gap-2 animate-pulse duration-150`}>
                        {Array.from(Array(6).keys()).map(x => (
                            <div key={x} className={singleInfoClassName}>
                                <div className={keyLoadingClassName}></div>
                                <div className={valueLoadingClassName}></div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={`w-full ${getGridClasses(dataArray.length)}`}>
                        {dataArray.map(([key, item], i) => (
                            <div key={i} className={singleInfoClassName}>
                                <span className="text-secondaryText font-bold text-xs">{t(key)}</span>
                                <span
                                    className={`mt-2 text-blueMainText text-sm font-normal break-all ${
                                        item && item['className']
                                    }`}
                                    style={item ? item['style'] : {}}
                                >
                                    {React.isValidElement(item) ? item : (item && item['value']) ?? item}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
                {children}
            </div>
        </AnimationComponent>
    );
};
