export const LookalikeInformation = () => (
    <div className="pb-5 flex flex-col md:flex-row w-full">
        <div className="flex flex-col mt-5 sm:mt-10 md:mt-20 flex-1 md:mr-5">
            <span className="text-blueMainText font-normal text-sm text-left whitespace-pre-wrap">
                <br />
                <br />
                Lookalike Modeling is an important AI Hub component for extending the segments in the data management
                platform (Bisko DMP) to more comprehensive and relevant audiences.
                <br />
                <br />
                GjirafaTech’s lookalike modeling engine is an expert system in which the given seed set of entities
                usually expressed as a set of users are used to find an expanded audience of similar entities.
                <br />
                <br />
            </span>
        </div>
        <div className="sm:mt-10 md:mt-20 flex flex-col items-start flex-1 md:ml-5">
            <span className="text-blueMainText font-normal text-sm text-left whitespace-pre-wrap">
                Key Features
                <br />
                <br />• Segment modeling using a broad set of data sources (e.g., content consumption, site behavior,
                and offline data)
                <br />• Rich set of ML solutions stacked on top of each other to facilitate state of the art lookalike
                modeling
                <br />• No overlap between original segments and lookalike segments
                <br />• Automatic monitoring of the lookalike modeling precision to guarantee the highest possible
                quality
            </span>
        </div>
    </div>
);
