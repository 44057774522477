import React, { CSSProperties } from 'react';
import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';

interface SelectedOptionProps {
    label: string;
    onRemove: () => any;
    className?: string;
    style?: CSSProperties;
}

export const SelectedOption: React.FC<SelectedOptionProps> = ({ label, onRemove, style, className }) => (
    <div
        style={style}
        className={`w-auto m-1 flex flex-row items-center justify-between rounded pl-3 pr-1 py-1.5 text-sm text-blueMainText font-normal bg-mainBorder card-shadow ${className}`}
    >
        {label}
        <div className="cursor-pointer ml-2 pr-2 hover:border" onClick={() => onRemove()}>
            <GjirafaIcon name="Close" size={18} />
        </div>
    </div>
);
