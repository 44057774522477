import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RefreshButton } from '../../../../components/buttons/RefreshButton';
import { StatusComponent } from '../../../../components/common';
import { NoDataImagesType, Table, TableFilters } from '../../../../components/tables';
import { customEndpointDetailsContext } from '../../../../contexts';
import { useAccountId } from '../../../../hooks';
import {
    CustomEndpointVersionListModel,
    CustomEndpointVersionListResponseModel,
} from '../../../../models/entity-models/api/custom-endpoint/custom-endpoint-version';
import { actions, selectors } from '../../../../redux/thunk/app/custom-endpoint/customEndpointVersionsThunk';
import { showFiltersHeader } from '../../../../utils';
import { DefaultVersionColumn } from '../components';

export const CustomEndpointVersionsTab = () => {
    const accountId = useAccountId();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { details } = useContext(customEndpointDetailsContext);
    const customEndpointId = details?.id;

    const response: CustomEndpointVersionListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);

    useEffect(() => {
        customEndpointId && requestVersions({ accountId, customEndpointId, page: 0 });
    }, [accountId, customEndpointId]);

    const requestVersions = async (query: any) => dispatch(actions.request(query));

    const onChangePage = (page: number) => requestVersions({ page });

    if (!customEndpointId) return null;

    return (
        <Table
            loading={loading}
            response={response}
            modelEndpoint="customEndpointVersion"
            subHeader={showFiltersHeader(response)}
            headerComponent={
                <TableFilters
                    onSearch={searchQuery => requestVersions({ searchQuery, page: 0 })}
                    response={response}
                    className="flex-row-reverse justify-between"
                >
                    <div className="flex flex-1 justify-end">
                        <RefreshButton onRefresh={() => requestVersions({})} />
                    </div>
                </TableFilters>
            }
            className="shadow-none border border-mainBorder rounded"
            columns={[
                {
                    selector: (row: CustomEndpointVersionListModel) => row.versionNumber,
                    name: t('@version', { defaultValue: 'Version' }),
                },
                {
                    selector: (row: CustomEndpointVersionListModel) => row.buildStatus,
                    name: t('@build.status', { defaultValue: 'Build status' }),
                    cell: (row: CustomEndpointVersionListModel) => (
                        <div className="flex w-full justify-start items-center">
                            <StatusComponent
                                className="pr-2"
                                message={row.buildStatusMessage}
                                status={row.buildStatus}
                            />
                            <span>{row.buildStatus}</span>
                        </div>
                    ),
                },
                {
                    selector: row => row?.lastModified ?? '',
                    name: t('@common.last-modified'),
                    sortable: true,
                },
                {
                    selector: row => row?.modifiedBy ?? '',
                    name: t('@common.modified-by'),
                    sortable: true,
                },
                {
                    selector: (row: CustomEndpointVersionListModel) => row.id,
                    name: '',
                    maxWidth: '160px',
                    cell: (row: CustomEndpointVersionListModel) => (
                        <DefaultVersionColumn
                            onSuccessDeployed={() => requestVersions({})}
                            isDefault={row.isDefault}
                            buildStatus={row.buildStatus}
                            versionId={row.id}
                        />
                    ),
                },
            ]}
            emptyDataProps={{
                actionMessage: '',
                imageType: NoDataImagesType.CustomEndpoint,
                emptyText: t('@no.customEndpointVersions.found', { defaultValue: 'There are no Versions yet...' }),
            }}
            showHeaderButtons={false}
            showHeader={false}
            selectableRows={false}
            pagination
            paginationProps={{
                currentPage: response.currentPage,
                pageItems: response.models.length,
                totalPages: response.totalPages,
                totalItems: response.totalItems,
                onChangePage,
            }}
        />
    );
};
