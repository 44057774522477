import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { ADMIN_SPLIT_CHAR, inputTypes } from '../../../constants';
import { BaseEntityProperty, SelectOptionType } from '../../../models';
import { CreateButton } from '../../buttons';
import {
    InputField,
    MultiSelectField,
    SelectCreatableField,
    SelectField,
    SelectWithPopupField,
    SelectWithPopupTypes,
    SwitchInput,
    TextareaField,
} from '../../inputs';
import { DatePickerInput } from '../../inputs/DatePickerInput';
import { Icon, IconTypes } from '../Icon';
import { DynamicInputsProps } from './DynamicInputs';
import { InfoIconWithModal } from './InfoIconWithModal';

export interface SingleDynamicInputProps extends DynamicInputsProps {
    property: BaseEntityProperty;
}

export const shouldDynamicInputRender = (currentProperty: BaseEntityProperty, properties: BaseEntityProperty[]) => {
    let shouldDisplay = true;
    if ((currentProperty?.conditionalId ?? 0) > 0) {
        shouldDisplay = false;
        let parentProperty: BaseEntityProperty | undefined = properties.find(
            x => x.id === currentProperty.conditionalId
        );
        let conditionalValue: string = currentProperty.conditionalValue || '';
        let parentValue = parentProperty?.value?.value || parentProperty?.value;

        if (!!parentValue?.length)
            shouldDisplay =
                parentValue?.includes(conditionalValue) ||
                conditionalValue.split(ADMIN_SPLIT_CHAR)?.includes(parentValue);
    }
    return shouldDisplay;
};

export const SingleDynamicInput: React.FC<SingleDynamicInputProps> = ({
    className,
    disabled,
    errors,
    isLoading,
    style,
    property,
    properties,
    onChangeInput,
    handleCreateOption,
    onBlurInput,
    onChangeInputsList,
}) => {
    const { t } = useTranslation();
    if (!property) {
        return null;
    }
    const { inputType, name, id, conditionalId } = property;
    if (conditionalId && !shouldDynamicInputRender(property, properties)) {
        return null;
    }

    const hasPropertyInfo = property?.infoLink || property?.infoText;
    const { commonProps, labelRight } = (() => ({
        commonProps: {
            // label: name,
            // className: className || '',
            // style: { ...style },
            errorMessage: errors ? errors[property.inputName] : '',
            hideLabel: true,
            key: id,
            name,
            id: name,
        },
        labelRight: hasPropertyInfo && <InfoIconWithModal property={property} />,
    }))();

    const renderInputByType = () => {
        if (inputType === inputTypes.datePicker) {
            return (
                <DatePickerInput
                    disabled={disabled}
                    onChange={value => onChangeInput && onChangeInput(property.inputName, value, property.inputType)}
                    value={property.value}
                    isClearable={!property.required}
                    required={property.required}
                    labelRight={labelRight}
                    placeholder={t('choose.date')}
                    {...commonProps}
                />
            );
        }

        if (inputType === inputTypes.dropdownMultiSelect) {
            return (
                <MultiSelectField
                    disabled={disabled}
                    isClearable={!property.required}
                    required={property.required}
                    options={property.options}
                    placeholder={`${t('@common.choose')} ${property.name}`}
                    value={property?.value}
                    onChange={item =>
                        onChangeInput &&
                        property.inputName &&
                        onChangeInput(property.inputName, item, property.inputType)
                    }
                    isLoading={isLoading}
                    labelRight={labelRight}
                    {...commonProps}
                />
            );
        }

        if (inputType === inputTypes.dropdown)
            return (
                <SelectField
                    disabled={disabled}
                    isClearable={!property.required}
                    required={property.required}
                    options={property.options}
                    placeholder={`${t('@common.choose')} ${property.name}`}
                    value={typeof property?.value !== 'string' ? property?.value : null}
                    onChange={item =>
                        onChangeInput &&
                        property.inputName &&
                        onChangeInput(property.inputName, item, property.inputType)
                    }
                    isLoading={isLoading}
                    labelRight={labelRight}
                    {...commonProps}
                />
            );

        if (property.inputType === inputTypes.input || property.inputType === inputTypes.password)
            return (
                <InputField
                    required={property.required}
                    disabled={disabled}
                    type={property.inputType}
                    value={property.value?.toString()}
                    onChange={e =>
                        onChangeInput &&
                        property.inputName &&
                        onChangeInput(property.inputName, e.target.value, property.inputType)
                    }
                    onBlur={e => onBlurInput && onBlurInput(property.inputName, e, property?.inputType)}
                    placeholder={t('@common.write.here')}
                    labelRight={labelRight}
                    {...commonProps}
                />
            );

        if (SelectWithPopupTypes[property.inputType])
            return (
                <SelectWithPopupField
                    disabled={disabled}
                    tableType={SelectWithPopupTypes[property.inputType]}
                    fetchDataOnMount
                    onChangeItem={item =>
                        onChangeInput &&
                        property.inputName &&
                        onChangeInput(property.inputName, item, property.inputType)
                    }
                    selectedItem={property.value}
                    placeholder={t('@common.choose') + ' ' + property.inputType}
                    selectedId={property.value}
                    isClearable={!property.required}
                    required={property.required}
                    labelRight={labelRight}
                    {...commonProps}
                />
            );

        if (property.inputType === inputTypes.inputQuery)
            return (
                <TextareaField
                    disabled={disabled}
                    value={property.value}
                    placeholder={t('@common.write.here')}
                    onChange={e =>
                        onChangeInput && onChangeInput(property.inputName, e.target.value, property.inputType)
                    }
                    onBlur={e => onBlurInput && onBlurInput(property.inputName, e, property.inputType)}
                    isAutoSize
                    required={property?.required}
                    labelRight={labelRight}
                    {...commonProps}
                />
            );

        if (property.inputType === inputTypes.dropdownOpen)
            return (
                <SelectCreatableField
                    onCreateOption={value => handleCreateOption && handleCreateOption(property.inputName, value)}
                    disabled={disabled}
                    required={property.required}
                    // label={property.name}
                    options={property.options}
                    placeholder={`${t('@common.choose-or-enter')} ${property.name}`}
                    // className={className}
                    value={typeof property.value !== 'string' ? property.value : null}
                    // style={style}
                    isClearable={!property.required}
                    onChange={(item: SelectOptionType | null) =>
                        onChangeInput && onChangeInput(property.inputName, item, property.inputType)
                    }
                    labelRight={labelRight}
                    {...commonProps}
                />
            );
        if (property.inputType === inputTypes.switch)
            return (
                <SwitchInput
                    onChange={val =>
                        onChangeInput && onChangeInput(property.inputName, val ? 'true' : 'false', property.inputType)
                    }
                    value={property?.value === 'true'}
                    disabled={disabled}
                    required={property.required}
                    labelRight={labelRight}
                    {...commonProps}
                />
            );
    };

    const { shouldAdd, shouldDelete, _index } = (() => {
        let conditions = {
            shouldDelete: false,
            shouldAdd: false,
            _index: -1,
        };

        if (!property?.isRepeatable) {
            return conditions;
        }

        let alikeProperties = properties.filter(x => x.id === property.id).map(x => x.inputName);
        let currentIdex = alikeProperties.indexOf(property.inputName);

        if (alikeProperties.length > 1) {
            conditions.shouldDelete = true;
            conditions.shouldAdd = currentIdex === alikeProperties.length - 1;
        } else {
            conditions.shouldAdd = true;
            conditions.shouldDelete = false;
        }

        conditions._index = properties.map(x => x.inputName).indexOf(property.inputName);

        return conditions;
    })();

    const onAddNewInput = () => {
        if (onChangeInputsList) {
            let _id = nanoid();

            let newProperty = {
                ...property,
                value: '',
                inputName: `${property.name} ${_id}`,
                valueId: 0,
            };
            let _properties = [...properties.slice(0, _index + 1), newProperty, ...properties.slice(_index + 1)];

            onChangeInputsList(_properties);
        }
    };

    const onDeleteInput = () => {
        if (onChangeInputsList) {
            onChangeInputsList(properties.filter(x => x.inputName !== property.inputName));
        }
    };

    return (
        <div className={`flex flex-col w-full ${className}`} style={{ ...style }}>
            <div className={`flex mb-1 flex-row justify-between items-center text-left`}>
                <label className="text-xs sm:text-sm text-secondaryText font-normal">
                    {name}
                    {property.required && <span className="text-mainError text-base">{' *'}</span>}
                </label>
                {labelRight && labelRight}
            </div>
            <div className="flex w-full flex-row justify-between items-start">
                {renderInputByType()}
                <div className="flex items-center" style={{ minHeight: 40 }}>
                    {shouldAdd && property?.isRepeatable && (
                        <CreateButton
                            title=""
                            className={`w-auto ml-2 justify-center`}
                            htmlType="button"
                            type="button"
                            style={{ width: 38, justifyContent: 'center', display: 'flex', flexDirection: 'row' }}
                            icon={<Icon name={IconTypes.plus} size={22} />}
                            onClick={onAddNewInput}
                        />
                    )}
                    {shouldDelete && property?.isRepeatable && (
                        <Icon
                            name={IconTypes.delete}
                            size={22}
                            className="cursor-pointer ml-2"
                            onClick={onDeleteInput}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
