import AXIOS, { AxiosRequestConfig } from 'axios';
import { DetailsResponseModel, PresignedUrlDetailsResponseModel } from '../models';
import axios from './axios';

/**
 * Types
 */

export type uploadToS3FileProps = {
    fileContents: File;
    accountId?: number;
    endpointForPresignedLUrl: string;
    uploadId?: any;
};

type getPresignedUrlProps = {
    accountId?: number;
    fileName: string;
    endpointForPresignedLUrl: string;
    contentType: string;
    uploadId?: any;
};
export type storageUploadToS3FileProps = {
    accountId: number;
    fileContents: File;
    key: string; //entire path
    uploadId?: any;
};

const uploadToS3 = async (fileProps: uploadToS3FileProps, axiosConfig?: AxiosRequestConfig) => {
    const { fileContents, accountId, endpointForPresignedLUrl, uploadId } = fileProps;
    const presignedPostUrl = await getPresignedUrl({
        fileName: fileContents?.name,
        contentType: fileContents.type,
        accountId,
        endpointForPresignedLUrl,
        uploadId,
    });

    let response = await AXIOS.put(presignedPostUrl, fileContents, axiosConfig);
    let relativeUrl = presignedPostUrl.split('?')[0].split('.tech')[1];
    return { relativeUrl, response, s3BuckerUrl: presignedPostUrl.split('?')[0] };
};

const storageUploadToS3 = async (fileProps: storageUploadToS3FileProps, axiosConfig?: AxiosRequestConfig) => {
    const { fileContents, accountId, key } = fileProps;
    const presignedUrl = await storageGetPresignedUrl({ accountId, key, contentType: fileContents.type });
    await AXIOS.put(presignedUrl, fileContents, axiosConfig);
};

const getPresignedUrl: (props: getPresignedUrlProps) => Promise<string> = async ({
    accountId,
    contentType,
    fileName,
    endpointForPresignedLUrl,
    uploadId,
}) => {
    let queryParams: any = {
        fileName,
        contentType,
    };

    if (accountId) {
        queryParams.accountId = accountId?.toString();
    }

    let response: DetailsResponseModel<string> = await axios.get(
        endpointForPresignedLUrl + '?' + new URLSearchParams(queryParams),
        {
            headers: {
                uploadId: uploadId || '',
            },
        }
    );

    return response.model;
};

type storageGetPresignedUrlType = (props: { accountId: number; key: string; contentType: string }) => Promise<string>;

const storageGetPresignedUrl: storageGetPresignedUrlType = async ({ accountId, key, contentType }) => {
    try {
        let query: any = {
            accountId,
            key,
            contentType,
            isPublic: false,
            isFolder: false,
        };
        const response: PresignedUrlDetailsResponseModel = await axios.get(
            `/storage/object/presignedUrl?${new URLSearchParams(query)}`
        );
        return response.model;
    } catch (err) {
        console.log({ err });
        return '';
    }
};

export { uploadToS3, storageUploadToS3 };
