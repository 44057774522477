import { isValid } from 'date-fns';
import { getFileExtension } from '.';
import { dataTypes } from '../constants';

type ValidationReturnType = {
    message: string;
    isValid: boolean;
};
type ValidationArgumentType = {
    value: string;
    dataType: string;
};

export function dataTypeValidation({ value, dataType }: ValidationArgumentType): ValidationReturnType {
    switch (dataType) {
        // case dataTypes.date:
        //     return validateDateTime(value?.trim());
        case dataTypes.number:
            return validateFloat(value?.trim());
        default:
            return {
                message: '',
                isValid: true,
            };
    }
}

export const validateFloat = (value: string) => {
    let response = {
        message: '',
        isValid: true,
    };

    if (!value) {
        response = {
            isValid: false,
            message: 'Please provide a number!',
        };
    } else if (value?.length) {
        let regex = new RegExp(/^-?(0|[1-9]\d*)(\.\d+)?$/);
        if (!regex.test(value)) {
            response = {
                isValid: false,
                message: 'Number is not valid!',
            };
        }
    }

    return response;
};

export const validateDateTime = (value: string) => {
    let response = {
        message: '',
        isValid: true,
    };

    if (!isValid(new Date(value))) {
        response = {
            message: 'Given date is not valid!',
            isValid: false,
        };
    }

    return response;
};

export const shouldUploadVersionToArtifact = (currentUrl: string, newFileName: string) => {
    let currentUrlExtension = getFileExtension(currentUrl);
    let newFileNameExtension = getFileExtension(newFileName);

    return currentUrlExtension.includes(newFileNameExtension);
};
