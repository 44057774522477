import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StatusComponent } from '../../../components/common';
import { CreateCustomEndpointModal } from '../../../components/modals';
import { NoDataImagesType, Table, TableFilters, UrlTableComponent } from '../../../components/tables';
import { toast } from '../../../components/ui';
import { CUSTOM_ENDPOINTS_PATH } from '../../../constants';
import { useAccountId, useToggleVisibility } from '../../../hooks';
import {
    BaseInstanceTypeListModel,
    BaseInstanceTypeListResponseModel,
    CustomEndpointListModel,
    CustomEndpointListResponseModel,
} from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/custom-endpoint/customEndpointsThunk';
import axios from '../../../services/axios';
import { queryParams, showFiltersHeader } from '../../../utils';

export const CustomEndpointsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const history = useHistory();
    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility();

    const loading = useSelector(selectors.getIsLoading);
    const response: CustomEndpointListResponseModel = useSelector(selectors.getResponse);

    const [instanceTypes, setInstanceTypes] = useState<BaseInstanceTypeListModel[]>([]);
    const [instanceTypesLoading, setInstanceTypesLoading] = useState(false);
    const [selectedIdForEdit, setSelectedIdForEdit] = useState<number | null>(null);

    useEffect(() => {
        fetchInstanceTypes();
    }, []);

    useEffect(() => {
        requestList({ searchQuery: '' });
    }, [accountId]);

    const fetchInstanceTypes = async () => {
        try {
            setInstanceTypesLoading(true);
            const response: BaseInstanceTypeListResponseModel = await axios.get('/customEndpoint/instanceType/list');
            setInstanceTypes(response.models);
        } catch (err) {
        } finally {
            setInstanceTypesLoading(false);
        }
    };

    const requestList = (query: any, resetQuery?: boolean) =>
        dispatch(actions.request({ accountId, ...query }, resetQuery));

    const onRefresh = () => requestList({});

    const onClickCreate = () => onToggleVisibility(true);

    const onClickEdit = (row: CustomEndpointListModel) => {
        setSelectedIdForEdit(row.id);
        onToggleVisibility(true);
    };

    const onCancel = () => {
        onToggleVisibility(false);
        setSelectedIdForEdit(null);
    };

    const onRowClicked = (row: CustomEndpointListModel) => {
        history.push(`${CUSTOM_ENDPOINTS_PATH}/details/${row.id}${queryParams.formatForNavigation()}`);
    };

    const onRebuild = async (row: CustomEndpointListModel) => {
        try {
            const payload = {
                accountId,
                id: row.id,
            };
            await axios.post(`/customEndpoint/deploy`, payload);
            toast.success(t('@toast-messages.success-customEndpoint-rebuild'));
        } catch (err) {}
    };

    const onRestart = async (row: CustomEndpointListModel) => {
        try {
            const payload = {
                accountId,
                id: row.id,
            };
            await axios.post(`/customEndpoint/restart`, payload);
            toast.success(t('@toast-messages.success-customEndpoint-restart'));
        } catch (err) {}
    };
    
    const onPageChanged = page => dispatch(actions.request({ page }));
    
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    return (
        <>
            {shouldRender && (
                <CreateCustomEndpointModal
                    instanceTypes={instanceTypes}
                    instanceTypesLoading={instanceTypesLoading}
                    isVisible={isVisible}
                    idForEdit={selectedIdForEdit}
                    onHide={onCancel}
                    onSuccess={onRefresh}
                />
            )}
            <div className="w-full">
                <Table
                    highlightOnHover
                    selectableRows
                    showHeaderButtons
                    onRowClicked={onRowClicked}
                    modelEndpoint="customEndpoint"
                    requestList={requestList}
                    isFromSelectPopup={false}
                    emptyDataProps={{
                        emptyText: t('@pages.no-customEndpoints'),
                        actionMessage: `+ ${t('@pages.create-new-customEndpoint')}`,
                        imageType: NoDataImagesType.CustomEndpoint,
                        onClickAction: onClickCreate,
                    }}
                    pagination
                    paginationProps={paginationProps}
                    loading={loading}
                    response={response}
                    headerButtonProps={{
                        onRefresh,
                        onClickCreate,
                        createTitle: t('@pages.create-new-customEndpoint', { defaultValue: 'Create new Endpoint' }),
                    }}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestList({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                        />
                    }
                    actions={[
                        {
                            action: 'details',
                            displayName: t('@view.details', { defaultValue: 'View details' }),
                            onClick: onRowClicked,
                        },
                        {
                            action: 'edit',
                            onClick: onClickEdit,
                        },
                        {
                            action: 'deploy',
                            displayName: 'Rebuild',
                            onClick: onRebuild,
                        },
                        {
                            action: 'refresh',
                            displayName: 'Restart',
                            onClick: onRestart,
                        },
                        {
                            action: 'delete',
                        },
                    ]}
                    columns={[
                        {
                            name: t('@common.title'),
                            selector: (row: CustomEndpointListModel) => row.title,
                        },
                        {
                            name: t('endpoint'),
                            selector: (row: CustomEndpointListModel) => row.endpoint,
                            grow: 0.8,
                            cell: (row: CustomEndpointListModel) => (
                                <>
                                    {row.endpointDetails && (
                                        <StatusComponent
                                            status={row.endpointDetails.status}
                                            message={row.endpointDetails.message}
                                            className="mr-2"
                                        />
                                    )}
                                    <UrlTableComponent url={row.endpoint} />
                                </>
                            ),
                        },
                        {
                            name: t('@deployed.version', { defaultValue: 'Deployed version' }),
                            selector: (row: CustomEndpointListModel) => row?.defaultVersion?.versionNumber ?? '',
                            style: { justifyContent: 'center' },
                            width: '120px',
                            cell: (row: CustomEndpointListModel) => (
                                <span className="w-full justify-center">
                                    {row.defaultVersion?.versionNumber ??
                                        t('@no.deployed.version', { defaultValue: 'None' })}
                                </span>
                            ),
                        },
                        {
                            width: '120px',
                            name: t('@last.build.status', { defaultValue: 'Last build status' }),
                            selector: (row: CustomEndpointListModel) => row?.lastVersion?.buildStatus ?? '',
                            cell: (row: CustomEndpointListModel) => (
                                <StatusComponent
                                    style={{ width: '100%' }}
                                    className="justify-center"
                                    message={row?.lastVersion?.buildStatusMessage ?? ''}
                                    status={row?.lastVersion?.buildStatus ?? ''}
                                />
                            ),
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                />
            </div>
        </>
    );
};
