import { nanoid } from 'nanoid';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { inputTypes, tabularQueryArtifacts } from '../../constants';
import { useAccountIdString } from '../../hooks';
import {
    BaseEntityValue,
    CatalogDetailsResponseModel,
    SchemaTypeDetailsResponseModel,
    SchemaTypeListResponseModel,
    SchemaTypeProperties,
    SelectOptionType,
} from '../../models';
import {
    actions as schemaTypeActions,
    selectors as schemaTypesSelectors,
} from '../../redux/thunk/app/schemaType/schemaTypesThunk';
import axios from '../../services/axios';
import {
    addNewOptionToDynamicInputs,
    deleteErrorKey,
    formatArrayForSelectOptions,
    getFormattedQuery,
} from '../../utils';
import { DynamicInputs, shouldDynamicInputRender } from '../common';
import { InputField, SelectField, SelectWithPopupField, SelectWithPopupTypes, TextareaField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateCatalogModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    catalogIdForEdit?: any;
    onSuccess?: (item: any, isEditing: boolean) => any;
}

type fieldsType = {
    name: string;
    schemaType: SelectOptionType | null;
    artifact: any;
    description?: string;
};
const initialFieldsValues = {
    name: '',
    schemaType: null,
    artifact: null,
    description: '',
};

export const CreateCatalogModal: FC<CreateCatalogModalProps> = ({
    isVisible,
    onHide,
    catalogIdForEdit,
    isEditing,
    onSuccess,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const accountId = useAccountIdString();
    const dispatch = useDispatch();

    /**
     * Redux Selectors
     */
    const schemaTypeResponse: SchemaTypeListResponseModel = useSelector(schemaTypesSelectors.getResponse);
    const schemaTypeLoading = useSelector(schemaTypesSelectors.getIsLoading);

    /**
     * Local State
     */
    const [fieldsValues, setFieldsValues] = useState<fieldsType>({ ...initialFieldsValues });
    const [errors, setErrors] = useState<any>({});
    const [createCatalogLoading, setCreateCatalogLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [properties, setProperties] = useState<SchemaTypeProperties[]>([]);
    const [propertiesLoading, setPropertiesLoading] = useState(false);

    useEffect(() => {
        if (schemaTypeResponse.models.length < 1) {
            dispatch(schemaTypeActions.request());
        }
    }, []);

    useEffect(() => {
        if (isEditing && catalogIdForEdit) {
            fetchCatalogDetails(catalogIdForEdit);
        }
    }, [catalogIdForEdit]);

    const onChangeFieldValues = (key, value) => {
        setFieldsValues(prev => ({ ...prev, [key]: value }));
        deleteErrorKey(key, setErrors);
        if (key === 'artifact' && fieldsValues.schemaType) {
            fetchSchemaTypeProperties(fieldsValues.schemaType?.value, value?.value);
        }
        if (key === 'schemaType' && !isEditing) {
            fetchSchemaTypeProperties(value?.value, fieldsValues.artifact?.value);
        }
    };
    const onChangeProperties = (key, value) => {
        deleteErrorKey(key, setErrors);
        setProperties(prev => prev.map(property => (property.inputName === key ? { ...property, value } : property)));
    };

    const resetValues = () => {
        setFieldsValues({ ...initialFieldsValues });
        setProperties([]);
    };
    const isFormValid = () => {
        setErrors({});
        let isValid = true;
        let _errs: any = {};

        const { schemaType, artifact, name } = fieldsValues;

        if (!name) {
            _errs.name = 'Name is required!';
            isValid = false;
        }

        if (!artifact) {
            _errs.artifact = 'Artifact is required!';
            isValid = false;
        }

        if (artifact && !schemaType) {
            _errs.schemaType = 'Schema type is required!';
            isValid = false;
        }

        properties.forEach(prop => {
            if (shouldDynamicInputRender(prop, properties) && prop.required && !prop.value) {
                isValid = false;
                _errs[prop.inputName] = 'This field is required';
            }
        });

        setErrors(_errs);
        return isValid;
    };

    const fetchSchemaTypeProperties = async (id, artifactId) => {
        try {
            setPropertiesLoading(true);
            const query = {
                id,
                accountId,
                artifactId,
            };

            const inputsResponse: SchemaTypeDetailsResponseModel = await axios.get(
                `/schemaType?${getFormattedQuery(query)}`
            );

            let inputs = inputsResponse.model?.properties?.map(el => {
                if (el.inputType === inputTypes.dropdown || el.inputType === inputTypes.dropdownOpen) {
                    return {
                        ...el,
                        value: el.defaultValue ? el?.options?.find(x => x.value === el?.defaultValue) : null,
                        inputName: el.name,
                    };
                }
                return {
                    ...el,
                    value: el?.defaultValue || '',
                    inputName: el.name,
                };
            });

            setProperties(inputs);
        } catch (err: any) {
            setProperties([]);
            setFieldsValues(prev => ({
                ...prev,
                schemaType: null,
            }));
        } finally {
            setPropertiesLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        e.stopPropagation();

        if (!isFormValid() || propertiesLoading || createCatalogLoading) {
            return;
        }
        try {
            setCreateCatalogLoading(true);
            const { name, description, artifact, schemaType } = fieldsValues;
            let payload = {
                id: isEditing ? catalogIdForEdit : 0,
                accountId,
                name,
                description,
                artifactId: artifact?.value,
                schemaTypeId: schemaType?.value,
                values: properties
                    .filter(x => shouldDynamicInputRender(x, properties))
                    .map(prop => ({
                        id: isEditing ? prop?.valueId : 0,
                        propertyId: prop.id,
                        value: prop?.value?.value || prop?.value,
                    })),
            };
            let response: CatalogDetailsResponseModel = await axios.post('/catalog', payload);
            resetValues();
            onSuccess && onSuccess(response.model, !!isEditing);
            onHide();
        } catch (err: any) {
        } finally {
            setCreateCatalogLoading(false);
        }
    };

    const fetchCatalogDetails = async id => {
        try {
            setDetailsLoading(true);
            const query = {
                accountId,
                id,
            };
            const { model }: CatalogDetailsResponseModel = await axios.get(`/catalog?${getFormattedQuery(query)}`);

            setFieldsValues({
                name: model.name,
                description: model.description,
                artifact: {
                    label: model.artifact.title,
                    value: model.artifact.id?.toString(),
                },
                schemaType: model?.schemaType
                    ? {
                          label: model?.schemaType.name,
                          value: model?.schemaType?.id?.toString(),
                      }
                    : null,
            });

            let properties: SchemaTypeProperties[][] =
                model?.schemaType?.properties.map((property: SchemaTypeProperties) => {
                    let _inputs: BaseEntityValue[] | undefined =
                        model?.values.filter(el => el.propertyId === property.id) || [];

                    if (_inputs.length < 1) {
                        _inputs = [
                            {
                                id: 0,
                                propertyId: property.id,
                                propertyName: property.name,
                                value: property.defaultValue,
                            },
                        ];
                    }

                    let _arr = _inputs.map(input => {
                        let value: any = null;
                        if (
                            property.inputType === inputTypes.dropdown ||
                            property.inputType === inputTypes.dropdownOpen
                        )
                            value = input?.value
                                ? property?.options?.find(x => x.value === input?.value)
                                : property?.options?.find(x => x.value === property?.defaultValue) || null;
                        else value = input?.value || property?.defaultValue || '';

                        return {
                            ...property,
                            value,
                            inputName: property.isRepeatable ? `${property.name} ${nanoid()}` : property.name,
                            valueId: input?.id || 0,
                        };
                    });

                    return _arr;
                }) || [];

            setProperties(properties?.flat());
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };
    const handleCreateOption = (name, item) => {
        setProperties(prev => addNewOptionToDynamicInputs(prev, name, item));
    };

    /**
     * Renders
     */
    const renderProperties = () => {
        if (propertiesLoading) {
            return (
                <div className="h-auto w-full p-10 flex items-center justify-center">
                    <Loader />
                </div>
            );
        }
        return (
            <DynamicInputs
                onChangeInputsList={_properties => {
                    setProperties(_properties);
                }}
                properties={properties}
                handleCreateOption={handleCreateOption}
                onChangeInput={onChangeProperties}
                className="mt-4"
                errors={errors}
            />
        );
    };

    const { buttonText, title, formId } = useMemo(() => {
        return {
            title: isEditing ? t('@components.modals.edit-catalog') : t('@components.modals.create-catalog'),
            buttonText: isEditing ? t('@common.edit') : t('@common.create'),
            formId: isEditing ? 'editCatalog' : 'createCatalog',
        };
    }, []);
    return (
        <>
            <SideModal
                title={title}
                buttonText={buttonText}
                formId={formId}
                onHide={onHide}
                isVisible={isVisible}
                loading={createCatalogLoading}
            >
                {detailsLoading ? (
                    <div className="py-12 w-full flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} id={formId} className="px-4 py-6">
                        <>
                            <InputField
                                name="catalog"
                                id="catalog"
                                placeholder={t('@common.write.here')}
                                label={t('@common.name')}
                                required
                                value={fieldsValues.name}
                                errorMessage={errors['name']}
                                onChange={e => onChangeFieldValues('name', e.target.value)}
                            />

                            <TextareaField
                                contentEditable={false}
                                placeholder={t('@common.write.here')}
                                label={t('@common.description')}
                                value={fieldsValues?.description}
                                className="mt-4"
                                onChange={e => onChangeFieldValues('description', e.target.value)}
                            />

                            <SelectWithPopupField
                                required
                                className="mt-4"
                                tableType={SelectWithPopupTypes.artifact}
                                label={t('@common.artifact')}
                                disableFilters
                                query={tabularQueryArtifacts}
                                placeholder={t('@common.choose-artifact')}
                                onChangeItem={item => onChangeFieldValues('artifact', item)}
                                selectedItem={fieldsValues.artifact}
                                selectedId={fieldsValues.artifact}
                                errorMessage={errors['artifact']}
                            />
                            {fieldsValues.artifact && (
                                <SelectField
                                    id="CatalogSchemaType"
                                    label={t('@common.schema-type')}
                                    className="mt-4"
                                    placeholder={t('@components.modals.choose-schema-type')}
                                    onChange={item => onChangeFieldValues('schemaType', item)}
                                    value={fieldsValues.schemaType}
                                    disabled={isEditing}
                                    required
                                    isLoading={schemaTypeLoading}
                                    errorMessage={errors['schemaType']}
                                    options={formatArrayForSelectOptions(schemaTypeResponse.models)}
                                />
                            )}
                            {renderProperties()}
                        </>
                    </form>
                )}
            </SideModal>
        </>
    );
};
