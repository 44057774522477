import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { AccountPostResponseModel } from '../../models';
import { actions as accountsActions } from '../../redux/thunk/app/account/accountsThunk';
import axios from '../../services/axios';
import { deleteErrorKey } from '../../utils';
import { OutlineButton } from '../buttons';
import { Icon, IconTypes } from '../common';
import { InputField } from '../inputs';
import { ErrorTextComponent } from '../messages';
/**
 * Components
 */
import { SideModal } from '../SideModal';
import { toast } from '../ui';
import { UploadAccountImage } from './UploadAccountImage';

type FieldValuesType = {
    name: string;
    relativeUrl: string;
};

const initialFieldValues: FieldValuesType = {
    relativeUrl: '',
    name: '',
};

const CreateAccount: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility({
        initialValue: false,
    });

    /**
     * Local State
     */
    const [fieldValues, setFieldValues] = useState<FieldValuesType>({ ...initialFieldValues });
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});

    const onChangeFields = (key, value) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const isFormValid = () => {
        let isValid = true;
        let _errors: any = {};
        const { relativeUrl, name } = fieldValues;

        if (!name) {
            _errors.name = t('@validations.field.isRequired', { field: t('@common.name') });
            isValid = false;
        }

        if (!relativeUrl) {
            _errors.relativeUrl = t('@validations.field.isRequired', { field: t('@image', { defaultValue: 'Image' }) });
            isValid = false;
        }

        setErrors(_errors);
        return isValid;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (formLoading || !isFormValid()) return;

        try {
            setFormLoading(true);

            const { relativeUrl, name } = fieldValues;
            let payload: any = {
                name,
                id: 0,
                relativeUrl: relativeUrl?.split('.tech')[1] || '',
            };

            const response: AccountPostResponseModel = await axios.post('/account', payload);

            toast.success(t('@toast-messages.success-create-account', { defaultValue: 'Account has been created!' }));
            dispatch(accountsActions.request());
            setFieldValues({ ...initialFieldValues });
            history.push(`${window.location.pathname}?accountId=${response.model?.id}`);

            onToggleVisibility(false);
        } catch (err) {
            setFormLoading(false);
        }
    };

    return (
        <>
            <OutlineButton
                onClick={() => {
                    onToggleVisibility(true);
                }}
                className="ml-2"
                icon={<Icon name={IconTypes['plus-main-color']} className="mr-2" />}
                title={t('@common.create')}
            />
            {shouldRender && (
                <SideModal
                    isVisible={isVisible}
                    onHide={() => onToggleVisibility(false)}
                    title={t('@components.account.add-account')}
                    buttonText={t('@common.add')}
                    formId="add-acc"
                    loading={formLoading}
                >
                    <form onSubmit={e => handleSubmit(e)} id="add-acc">
                        <div className="p-5">
                            <UploadAccountImage
                                onChangeRelativeUrl={url => onChangeFields('relativeUrl', url)}
                                relativeUrl={fieldValues.relativeUrl}
                                className="mt-4 sm:pt-0"
                            />
                            <ErrorTextComponent message={errors?.relativeUrl} />
                            <InputField
                                className="mt-4"
                                required
                                onChange={e => onChangeFields('name', e.target.value)}
                                value={fieldValues.name}
                                maxLength={50}
                                name="account-name"
                                label={t('@common.name')}
                                placeholder={t('@common.write.here')}
                                errorMessage={errors?.name}
                            />
                        </div>
                    </form>
                </SideModal>
            )}
        </>
    );
};

export default CreateAccount;
