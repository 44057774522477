import { CSSProperties, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ListResponseModel } from '../../../models';
import { Icon, IconTypes } from '../../common';
import { InputField } from '../../inputs';

export interface TableFiltersProps {
    onSearch: (value: string) => any;
    onChangeQuery?: (key: string, value: any) => any;
    style?: CSSProperties;
    hideFilters?: boolean;
    response: ListResponseModel<any>;
    className?: string;
}

const searchInputName = 'tableFiltersSearch';

export const TableFilters: React.FC<TableFiltersProps> = ({
    onSearch,
    onChangeQuery,
    style,
    className,
    children,
    hideFilters,
    response,
}) => {
    const searchQuery = response?.query?.searchQuery;
    const idQuery = response?.query?.id || false;

    const { t } = useTranslation();

    const { handleSubmit, setValue, control } = useForm({
        shouldUnregister: false,
    });

    useEffect(() => {
        setValue(searchInputName, searchQuery ?? '');
    }, [searchQuery]);

    const onSubmitSearchForm = data => onSearch(data[searchInputName]);

    const onClearSearch = () => {
        setValue(searchInputName, '');
        handleSubmit(onSubmitSearchForm)();
    };

    const onKeyPress = event => {
        let code = event.keyCode || event.which;
        code === 13 && handleSubmit(onSubmitSearchForm)();
    };

    return (
        <div
            className={`w-full flex h-auto flex-row justify-between flex-wrap items-end px-4 pt-4 ${className}`}
            style={style}
        >
            {children && !hideFilters && (
                <div className={`flex flex-1 items-end`}>
                    {children}
                    {!!idQuery && (
                        <div style={{ height: 'auto' }} className="flex-none mb-2">
                            <div
                                className={`flex mb-1 flex-row justify-between items-center text-left `}
                                style={{ minHeight: 24 }}
                            >
                                <label className="text-xs sm:text-sm text-secondaryText font-normal">
                                    {t('filter.from.notification')}
                                </label>
                            </div>
                            <span className="rounded pl-3 border border-grey200 h-10 w-52 flex items-center text-blueMainText font-normal text-sm justify-between">
                                <span className="truncate">{idQuery?.label}</span>
                                <div
                                    className="h-full pr-3 cursor-pointer flex justify-center items-center"
                                    onClick={() => {
                                        onChangeQuery && onChangeQuery('id', null);
                                    }}
                                >
                                    <Icon name={IconTypes.close} />
                                </div>
                            </span>
                        </div>
                    )}
                </div>
            )}
            <div className="flex flex-row justify-center items-center">
                <Controller
                    name={searchInputName}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value, name } }) => (
                        <InputField
                            label={t('@common.search')}
                            placeholder={t('@common.write.here')}
                            onChange={onChange}
                            className="m-2"
                            inputClassName={`${value ? 'pr-8' : ''}`}
                            style={{ width: 200 }}
                            value={value}
                            isClearable={value}
                            onClearValue={onClearSearch}
                            name={name}
                            onKeyPress={onKeyPress}
                            autoComplete="off"
                        />
                    )}
                />
                {!!idQuery && (!children || hideFilters) && (
                    <div style={{ height: 'auto' }} className="flex flex-col">
                        <div
                            className={`flex mb-1 flex-row justify-between items-center text-left `}
                            style={{ minHeight: 24 }}
                        >
                            <label className="text-xs sm:text-sm text-secondaryText font-normal">
                                {t('filter.from.notification')}
                            </label>
                        </div>
                        <span className="rounded pl-3 border border-grey200 h-10 w-52 flex items-center text-blueMainText font-normal text-sm justify-between">
                            <span className="truncate">{idQuery?.label}</span>
                            <div
                                className="h-full pr-3 cursor-pointer flex justify-center items-center"
                                onClick={() => {
                                    onChangeQuery && onChangeQuery('id', null);
                                }}
                            >
                                <Icon name={IconTypes.close} />
                            </div>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};
