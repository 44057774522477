import React, { CSSProperties } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { DropzoneCardOverlay, DropzoneCardOverlayProps } from '.';

export interface DropZoneCardContainerProps {
    accept?: string;
    className?: string;
    onUploadFiles: (files: File[]) => any;
    options?: DropzoneOptions;
    style?: CSSProperties;
    cardOverlayProps?: DropzoneCardOverlayProps;
}

export const DropzoneCardContainer: React.FC<DropZoneCardContainerProps> = ({
    children,
    accept,
    className,
    onUploadFiles,
    options,
    style,
    cardOverlayProps,
}) => {
    const { getRootProps, isDragActive } = useDropzone({
        noClick: true,
        accept,
        multiple: true,
        onDropAccepted: files => {
            onUploadFiles(files);
        },
        ...options,
    });

    return (
        <div
            {...getRootProps({
                className: 'dropzone',
                style: { border: 'none', outline: 'none', ...style },
            })}
            className={`${className} ${isDragActive && 'relative'}`}
        >
            {children}
            {isDragActive && <DropzoneCardOverlay {...cardOverlayProps} />}
        </div>
    );
};
