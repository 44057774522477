import { FC } from 'react';
import { AutoHeightAnimation } from '../../../../components/animation/AutoHeightAnimation';
import { DeploymentListModel } from '../../../../models';

export interface DeploymentIframeProps {
    data?: DeploymentListModel;
    src?: string;
}

const DeploymentIframe: FC<DeploymentIframeProps> = ({ data }) => {
    return (
        <AutoHeightAnimation>
            <div className="w-full h-auto">
                {data?.endpoint !== '<pending>' && (
                    <iframe src={data?.endpoint} style={{ height: 500, maxHeight: 600 }} className="w-full" />
                )}
            </div>
        </AutoHeightAnimation>
    );
};

export default DeploymentIframe;
