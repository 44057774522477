import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OutlineButton } from './OutlineButton';
import { Icon, IconTypes } from '../common';

export interface RefreshButtonProps {
    showAnimation?: boolean;
    onRefresh: (e: any) => any;
    className?: string;
    style?: CSSProperties;
}

export const RefreshButton: React.FC<RefreshButtonProps> = React.memo(
    ({ showAnimation = true, onRefresh, className, style }) => {
        const { t } = useTranslation();
        const [isRefreshing, setIsRefreshing] = useState(false);

        useEffect(() => {
            if (isRefreshing) {
                setTimeout(() => {
                    setIsRefreshing(false);
                }, 300);
            }
        }, [isRefreshing]);

        return (
            <OutlineButton
                onClick={e => {
                    showAnimation && setIsRefreshing(true);
                    onRefresh && onRefresh(e);
                }}
                disabled={isRefreshing}
                icon={
                    <Icon
                        name={IconTypes.refresh}
                        className={`mr-2 ${isRefreshing && 'animate-spin-super-fast bg-opacity-60'}`}
                        size={18}
                    />
                }
                title={t('@common.refresh')}
                style={{ ...style }}
                className={`bg-white mb-3 ${className} ${isRefreshing && 'cursor-default'}`}
            />
        );
    }
);
