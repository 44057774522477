import React, { CSSProperties } from 'react';
import { AnimationComponent } from '../animation';

interface ReactTableWrapperProps {
    withAnimation?: boolean;
    style?: CSSProperties;
    className?: string;
}

export const ReactTableWrapper: React.FC<ReactTableWrapperProps> = ({
    children,
    withAnimation = true,
    className,
    style,
}) => {
    if (withAnimation) {
        return (
            <AnimationComponent type="fade-bottom" delay={200} duration={300} show={true}>
                <div className={`w-full card-shadow table-body-global-style ${className}`} style={style}>
                    {children}
                </div>
            </AnimationComponent>
        );
    }
    return (
        <div className={`w-full  card-shadow table-body-global-style ${className}`} style={style}>
            {children}
        </div>
    );
};
