import React from 'react';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';

export interface ScrollToTopButtonProps {
    onScrollToTop: () => any;
    isVisible: boolean;
    containerElement: HTMLElement | null;
}

export const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ isVisible, onScrollToTop }) => {
    return (
        <div
            onClick={() => onScrollToTop()}
            className={`fixed cursor-pointer z-30 right-0 bottom-20 transition-all duration-150 transform-gpu ${
                isVisible ? 'mr-0 mb-0' : '-mr-9 mb-2'
            } w-9 h-auto bg-primaryBlue500 py-3 flex flex-col items-center justify-center rounded-l hover:bg-primaryBlue600`}
        >
            <ArrowUp />
        </div>
    );
};
