import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
/**
 * hooks - services - utils
 */
import { useAccountId } from '../../hooks';
import { DeploymentDetailsResponseModel, DeploymentListModel, ModelInstanceTypeListResponseModel } from '../../models';
/**
 * redux
 */
import { selectors as instanceTypesSelectors } from '../../redux/thunk/app/deployment/deploymentInstanceTypesThunk';
import axios from '../../services/axios';
import { deleteErrorKey, getFormattedQuery, isValidNumber } from '../../utils';
/**
 * Components
 */
import { InputField, SelectField, SelectWithPopupField, SelectWithPopupTypes, TextareaField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateDeploymentModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    modelDefaultValue?: any;
    deploymentForEdit?: DeploymentListModel | null;
    onSuccess: (edited: boolean) => any;
}

const initialInputsValue = {
    id: 0,
    model: null,
    title: '',
    description: '',
    deploymentInstanceType: null,
    instanceCount: 2,
};

export const CreateDeploymentModal: React.FC<CreateDeploymentModalProps> = ({
    isVisible,
    onHide,
    isEditing,
    onSuccess,
    modelDefaultValue,
    deploymentForEdit,
}) => {
    const { t } = useTranslation();
    const accountId = useAccountId();

    /**
     * Selectors
     */
    const deploymentInstanceTypesResponse: ModelInstanceTypeListResponseModel = useSelector(
        instanceTypesSelectors.getResponse
    );
    const areInstanceTypesLoading = useSelector(instanceTypesSelectors.getIsLoading);

    /**
     * Local State
     */
    const [deploymentDetailsLoading, setDeploymentDetailsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [inputsData, setInputsData] = useState<{
        id: number;
        model: any;
        title: string;
        description: string;
        deploymentInstanceType: any;
        instanceCount: number;
    }>(initialInputsValue);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!areInstanceTypesLoading && isVisible && !isEditing) {
            const defaultInstanceType =
                deploymentInstanceTypesResponse.models.find(el => el.id === 1) ||
                deploymentInstanceTypesResponse.models[0];

            setInputsData(prev => ({
                ...prev,
                deploymentInstanceType: {
                    label: defaultInstanceType?.name,
                    value: defaultInstanceType?.id.toString(),
                },
            }));
        }
    }, [isVisible, areInstanceTypesLoading]);

    useEffect(() => {
        if (modelDefaultValue) setInputsData(prev => ({ ...prev, model: modelDefaultValue }));
    }, [modelDefaultValue]);

    useEffect(() => {
        if (isEditing && deploymentForEdit) {
            (async () => {
                try {
                    setDeploymentDetailsLoading(true);

                    const query = {
                        id: deploymentForEdit?.id,
                        accountId,
                    };

                    const response: DeploymentDetailsResponseModel = await axios.get(
                        `/deployment?${getFormattedQuery(query)}`
                    );
                    const { description, title, id, instanceCount, instanceType, modelId } = response.model;

                    setInputsData({
                        description,
                        id,
                        instanceCount,
                        title,
                        model: {
                            value: modelId.toString(),
                            label: deploymentForEdit?.modelTitle,
                        },
                        deploymentInstanceType: {
                            label: instanceType?.name,
                            value: instanceType?.id?.toString(),
                        },
                    });
                } catch (err: any) {
                    console.log({ err });
                } finally {
                    setDeploymentDetailsLoading(false);
                }
            })();
        }
    }, [isEditing, deploymentForEdit]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (!onValidateInputs()) {
            return;
        }

        try {
            setFormLoading(true);

            const { description, instanceCount, deploymentInstanceType, model, title, id } = inputsData;
            let payload = {
                id,
                accountId,
                title,
                modelId: model?.value,
                instanceCount,
                instanceTypeId: deploymentInstanceType?.value,
                description,
            };
            await axios.post('/deployment', payload);
            setFormLoading(false);
            onSuccess(!!isEditing);
        } catch (err) {
            setFormLoading(false);
        }
    };

    const onValidateInputs = () => {
        let shouldSubmit = true;
        let _errors: any = {};

        for (const key in inputsData) {
            if (key !== 'description' && !inputsData[key] && key !== 'id') {
                _errors[key] = 'This field is required!';
                shouldSubmit = false;
            }
        }

        setErrors(_errors);
        return shouldSubmit;
    };

    const onChangeInputs = (key, value) => {
        deleteErrorKey(key, setErrors);
        setInputsData(prev => ({ ...prev, [key]: value }));
    };

    const { buttonText, title, formId } = useMemo(() => {
        return {
            title: isEditing ? t('@pages.workflow.edit-deployment') : t('@pages.workflow.create-deployment'),
            buttonText: isEditing ? t('@common.edit') : t('@common.create'),
            formId: isEditing ? 'EditDeployment' : 'CreateDeploymentID',
        };
    }, []);

    return (
        <>
            <SideModal
                loading={formLoading}
                isVisible={isVisible}
                buttonText={buttonText}
                onHide={onHide}
                title={title}
                formId={formId}
            >
                <form id={formId} onSubmit={handleSubmit} className="flex flex-col h-full border-solid px-4 py-6">
                    {isEditing && (areInstanceTypesLoading || deploymentDetailsLoading) ? (
                        <div className="flex flex-1 items-center justify-center">
                            <Loader color="#1D79F2" />
                        </div>
                    ) : (
                        <>
                            <SelectWithPopupField
                                tableType={SelectWithPopupTypes.model}
                                required
                                placeholder={t('@common.choose-model')}
                                label={t('@common.model')}
                                onChangeItem={item => onChangeInputs('model', item)}
                                selectedItem={modelDefaultValue || inputsData.model}
                                errorMessage={errors['model']}
                                disabled={modelDefaultValue || isEditing}
                                query={{
                                    notDeployed: true,
                                    trained: 'trained',
                                    algorithmId: null,
                                    searchQuery: '',
                                }}
                            />
                            <InputField
                                name="deployment"
                                id="deployment"
                                required
                                placeholder={t('@common.write.here')}
                                onChange={e => onChangeInputs('title', e.target.value)}
                                value={inputsData.title}
                                label={t('@common.title')}
                                className="mt-4"
                                errorMessage={errors['title']}
                            />
                            <TextareaField
                                contentEditable={false}
                                placeholder={t('@common.write.here')}
                                label={t('@common.description')}
                                value={inputsData.description}
                                className="mt-4"
                                onChange={e => onChangeInputs('description', e.target.value)}
                            />
                            <SelectField
                                required
                                options={deploymentInstanceTypesResponse.models
                                    .map(el => ({
                                        value: el.id.toString(),
                                        label: el.name,
                                    }))
                                    .reverse()}
                                isLoading={areInstanceTypesLoading}
                                placeholder={t('choose.an.instanceType')}
                                label={t('instance.type')}
                                className="mt-4"
                                onChange={item => onChangeInputs('deploymentInstanceType', item)}
                                value={inputsData.deploymentInstanceType}
                                errorMessage={errors['deploymentInstanceType']}
                            />
                            <InputField
                                required
                                placeholder={t('@common.write.here')}
                                label={t('number.of.instances')}
                                className="mt-4"
                                value={inputsData.instanceCount}
                                errorMessage={errors['instanceCount']}
                                onChange={e =>
                                    isValidNumber(e.target.value) && onChangeInputs('instanceCount', e.target.value)
                                }
                            />
                        </>
                    )}
                </form>
            </SideModal>
        </>
    );
};
