import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { roles } from '../../constants';
import { useAccountId } from '../../hooks';
import { AccountApiKeyDetailsModel, AccountApiKeyDetailsResponseModel } from '../../models';
import axios from '../../services/axios';
import { getFormattedQuery } from '../../utils';
import { AccountApiKeyFormSchema } from '../../validations';
import { InputField, SelectField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateAccountApiKeyModalProps {
    isVisible: boolean;
    onHide: () => any;
    idForEdit?: number;
    isEditing?: boolean;
    onSuccess?: (item: any, isEditing: boolean) => any;
}

export const CreateAccountApiKeyModal: React.FC<CreateAccountApiKeyModalProps> = ({
    onHide,
    isVisible,
    idForEdit,
    isEditing,
    onSuccess,
}) => {
    const accountId = useAccountId();
    const { t } = useTranslation();

    const { handleSubmit, setValue, control } = useForm({
        resolver: yupResolver(AccountApiKeyFormSchema),
        reValidateMode: 'onChange',
        shouldUnregister: false,
        mode: 'onBlur',
    });

    const [loading, setLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);

    useEffect(() => {
        if (isEditing && idForEdit) {
            fetchDetails(idForEdit);
        }
    }, [isEditing, idForEdit]);

    const fetchDetails = async (id: number | string) => {
        try {
            setDetailsLoading(true);
            const query = {
                accountId,
                id,
            };
            const { model }: AccountApiKeyDetailsResponseModel = await axios.get(
                `/accountApiKey?${getFormattedQuery(query)}`
            );
            setValue('apiKeyName', model.name);
            setValue('role', model.roleId?.toString());
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const onSubmit = async data => {
        try {
            const { apiKeyName, role } = data;
            setLoading(true);
            let payload = {
                id: isEditing ? idForEdit || 0 : 0,
                accountId,
                name: apiKeyName,
                roleId: role,
            };
            const response: AccountApiKeyDetailsModel = await axios.post('/accountApiKey', payload);
            onSuccess && onSuccess(response, !!isEditing);
        } catch (err: any) {
        } finally {
            setLoading(false);
        }
    };

    const { title, formId, buttonText } = useMemo(
        () => ({
            title: isEditing ? 'Edit Api key' : 'Create Api Key',
            formId: isEditing ? 'EditApiKey' : 'CreateApiKey',
            buttonText: isEditing ? 'Edit' : 'Create',
        }),
        []
    );

    return (
        <>
            <SideModal
                loading={loading}
                title={title}
                buttonText={buttonText}
                onHide={onHide}
                isVisible={isVisible}
                formId={formId}
            >
                <form id={formId} className="py-6 px-4" onSubmit={handleSubmit(onSubmit)}>
                    {detailsLoading ? (
                        <div className="flex justify-center items-center p-10">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <Controller
                                defaultValue=""
                                name="apiKeyName"
                                control={control}
                                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                    <InputField
                                        label={t('@common.name')}
                                        placeholder={t('@common.write.here')}
                                        required
                                        name={name}
                                        errorMessage={error?.message}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                name="role"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <SelectField
                                        className="mt-4"
                                        label={t('@common.role')}
                                        placeholder={t('@common.select')}
                                        options={roles}
                                        errorMessage={error?.message}
                                        onChange={item => onChange(item?.value ?? '')}
                                        value={roles.find(x => x.value === value) || null}
                                    />
                                )}
                            />
                        </>
                    )}
                </form>
            </SideModal>
        </>
    );
};
