import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RecommendationEnginePage } from '.';
import { RecommendationEngineTypes } from '../../../constants';
import { useAccountId } from '../../../hooks';
import { actions } from '../../../redux/thunk/app/engines/recommendation/recommendationEnginesThunk';

export const ArticleRecommendationEnginePage = () => {
    const accountId = useAccountId();
    const dispatch = useDispatch();

    useEffect(() => {
        const type = RecommendationEngineTypes.article;
        dispatch(actions.request({ accountId, type, page: 0, searchQuery: '' }));
    }, [accountId]);

    return <RecommendationEnginePage recommendationEngineType={RecommendationEngineTypes.article} />;
};
