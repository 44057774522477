import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconTypes } from '../../../../../components/common';
import { InputField, SelectField } from '../../../../../components/inputs';
import { FieldLabel } from '../../../../../components/inputs/components/FieldLabel';
import { DatePickerInput } from '../../../../../components/inputs/DatePickerInput';
import { ErrorTextComponent } from '../../../../../components/messages';
import { dataOperators, dataTypes, dateTimeDataOperatorTypes, everyTypesOptions } from '../../../../../constants';
import { BaseEntityProperty, SelectOptionType } from '../../../../../models';
import { ConditionsStateType } from './ConditionFilterListComponent';
import { MultiValueInputComponent } from './MultiValueInputComponent';

export interface ConditionFilterComponentProps {
    properties: BaseEntityProperty[];
    onChangeInputs: (keyValue: Record<string, any>) => any;
    onDelete: () => any;
    title: string;
    conditionState: ConditionsStateType;
    style?: CSSProperties;
    className?: string;
    operatorType: SelectOptionType | null;
    index: number;
}

function getDataOperators(dataType?: string) {
    if (dataType) {
        return dataOperators.filter(_dataOperator => _dataOperator?.dataTypes?.includes(dataType));
    }
    return dataOperators;
}

export const ConditionFilterComponent: React.FC<ConditionFilterComponentProps> = ({
    onChangeInputs,
    properties,
    conditionState,
    onDelete,
    operatorType,
    index,
    className,
    style,
}) => {
    const { t } = useTranslation();

    const { errors, operator, value, property } = conditionState;
    const isValueDisabled = !operator || !property;
    const isOperatorDisabled = !property;

    const propertyOptions = useMemo<SelectOptionType[]>(() => {
        return properties.map(({ options, ...x }) => ({
            label: x.name,
            value: x.id,
            ...x,
        }));
    }, [properties]);

    const renderDateTime = () => {
        if (!operator) return null;
        if (operator?.value === dateTimeDataOperatorTypes.TheLast) {
            const [everyNumber, selected] = value.split('-');

            let everySelected = selected
                ? {
                      label: selected,
                      value: selected,
                  }
                : null;

            return (
                <>
                    <FieldLabel />
                    <div className={`flex justify-start items-start`}>
                        <InputField
                            hideLabel
                            htmlType="number"
                            label=""
                            placeholder="0"
                            value={everyNumber}
                            className="mr-2 w-20 sm:w-16 md:w-20"
                            onChange={e => {
                                let value = `${e.target.value}-${selected || ''}`;
                                onChangeInputs({ value });
                            }}
                        />
                        <SelectField
                            hideLabel
                            label=""
                            className="flex flex-1 md:flex-none md:w-44"
                            onChange={item => {
                                let value = `${everyNumber || ''}-${item?.value}`;
                                onChangeInputs({ value });
                            }}
                            options={everyTypesOptions}
                            value={everySelected}
                        />
                    </div>
                    <ErrorTextComponent message={errors?.value} />
                </>
            );
        }

        return (
            <DatePickerInput
                label={t('@common.date')}
                placeholder={t('choose.date')}
                onChange={date => {
                    onChangeInputs({ value: date.toString() });
                }}
                className={`${isValueDisabled && 'cursor-not-allowed'} sm:flex-1 md:w-56`}
                value={value}
                errorMessage={errors?.value}
            />
        );
    };

    return (
        <div className={`flex flex-col w-full ${className}`} style={{ ...style }}>
            {index > 0 && (
                <div className="w-full justify-center items-center border-t border-blueStroke flex mt-4">
                    <div className="bg-secondaryText h-5 -mt-2.5  justify-center items-center flex-none flex text-xs px-3 rounded text-white">
                        {operatorType?.value}
                    </div>
                </div>
            )}
            <div className="w-full flex flex-col md:flex-row md:items-start px-4 py-2">
                <SelectField
                    className="w-full md:w-48 md:mr-2"
                    label={t('@common.property')}
                    placeholder={t('@common.choose-property')}
                    value={property}
                    options={propertyOptions}
                    onChange={item => onChangeInputs({ property: item, value: '', operator: null })}
                    errorMessage={errors['property']}
                />

                <SelectField
                    label={t('@common.operator')}
                    placeholder={t('@common.choose-operator')}
                    disabled={isOperatorDisabled}
                    options={getDataOperators(property ? property['dataTypeId'] : '')}
                    value={operator}
                    className="w-full md:w-48 md:mr-2 mt-2 md:mt-0"
                    errorMessage={errors['operator']}
                    onChange={item => {
                        if (
                            operator?.value === dateTimeDataOperatorTypes.TheLast ||
                            item?.value === dateTimeDataOperatorTypes.TheLast
                        ) {
                            onChangeInputs({ operator: item, value: '' });
                        } else {
                            onChangeInputs({ operator: item });
                        }
                    }}
                />
                <div className="flex flex-1 justify-between mt-2 md:mt-0">
                    <div className="flex flex-1 flex-col">
                        {property &&
                            operator &&
                            (property['dataTypeId'] === dataTypes.date ? (
                                renderDateTime()
                            ) : (
                                <>
                                    <FieldLabel label="" />
                                    <MultiValueInputComponent
                                        values={value}
                                        onChangeValues={val => onChangeInputs({ value: val })}
                                    />
                                    <ErrorTextComponent message={errors?.value} />
                                </>
                            ))}
                    </div>
                    <div>
                        <FieldLabel label="" />
                        <div className="p-2 cursor-pointer text-secondaryText" onClick={onDelete}>
                            <Icon name={IconTypes.close} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
