import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NoDataImagesType } from '../../../components/tables';
import { ADMIN_CREATE_INTEGRATION_TYPES_PATH, ADMIN_INTEGRATION_TYPES_PATH } from '../../../constants';
import { IntegrationTypeListModel, IntegrationTypeListResponseModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/integrationType/adminIntegrationTypesThunk';
import { AdminTable } from '../components';

export const AdminIntegrationTypesPage = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    /**
     * Redux Selectors
     */
    const response: IntegrationTypeListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    const requestList = (query: any = {}) => {
        dispatch(actions.request({ ...query, isFromAdmin: true }));
    };

    useEffect(() => {
        requestList({ page: 0 });
    }, []);

    const onRefresh = () => {
        !loading && requestList();
    };

    const onClickEdit = (row: IntegrationTypeListModel) => {
        history.push(`${ADMIN_INTEGRATION_TYPES_PATH}/edit-integration-type/${row?.id}`);
    };
    const onClickCreate = () => {
        history.push(ADMIN_CREATE_INTEGRATION_TYPES_PATH);
    };

    return (
        <div className="">
            <AdminTable
                hideIsActiveColumn
                modelEndpoint="/bridge/integrationT"
                requestList={requestList}
                columns={[
                    {
                        name: 'Name',
                        selector: (row: IntegrationTypeListModel) => row?.name ?? '',
                        cell: (row: IntegrationTypeListModel) => (
                            <div className="flex items-center justify-start w-full">
                                <img className="rounded object-contain h-10 w-10 mr-2" src={row.imageUrl} />
                                <span className="text-blueMainText">{row.name}</span>
                            </div>
                        ),
                    },
                    {
                        name: 'Code',
                        selector: (row: IntegrationTypeListModel) => row.code ?? '',
                    },
                    {
                        name: 'Endpoint',
                        selector: (row: IntegrationTypeListModel) => row.endpoint ?? '',
                    },
                ]}
                reduxActions={actions}
                loading={loading}
                response={response}
                initLoading={initLoading}
                onClickEdit={onClickEdit}
                onPageChanged={page => {
                    requestList({ page });
                }}
                headerButtonProps={{
                    onClickCreate,
                    onRefresh,
                }}
                emptyDataProps={{
                    imageType: NoDataImagesType.AccountIntegration,
                    actionMessage: t('@create.new.integrationType', { defaultValue: 'Create new Integration Type' }),
                    onClickAction: onClickCreate,
                    emptyText: t('@pages.no-integrationType', {
                        defaultValue: 'No Integration Type has been added yet',
                    }),
                }}
            />
        </div>
    );
};
