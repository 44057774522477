import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';
import { CopyButton } from '../../../components/buttons';
import { StatusComponent } from '../../../components/common';
import {
    DetailsEmpty,
    DetailsInfoOverview,
    DetailsInfoOverviewDataType,
    DetailsLoader,
} from '../../../components/details-entity';
import { Error404 } from '../../../components/errors';
import { CustomTabContainer } from '../../../components/tabs/custom-tab/CustomTabContainer';
import { customEndpointDetailsContext } from '../../../contexts';
import { useAccountId, useDetailsError, useToggleVisibility } from '../../../hooks';
import {
    CustomEndpointDetailsModel,
    CustomEndpointDetailsResponseModel,
    Nullable,
    BaseInstanceTypeListModel,
    BaseInstanceTypeListResponseModel,
} from '../../../models';
import axios from '../../../services/axios';
import { formatDateAndTime } from '../../../utils';
import {
    CustomEndpointLogsTab,
    CustomEndpointMetricsTab,
    CustomEndpointSwaggerTab,
    CustomEndpointVersionsTab,
} from './details-tabs';
import { toast } from '../../../components/ui';
import { ToggleActionButton, TableActionRow } from '../../../components/tables';
import { CreateCustomEndpointModal, ConfirmationPopupModal } from '../../../components/modals';
import { CUSTOM_ENDPOINTS_PATH } from '../../../constants';

enum tabs {
    logs = 'logs',
    metrics = 'metrics',
    versions = ' versions',
    swagger = 'swagger',
}

export const CustomEndpointDetailsPage = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const accountId = useAccountId();
    const { detailsError, handleError, resetError } = useDetailsError();
    const entityId = parseInt(params?.id);
    const history = useHistory();

    const [details, setDetails] = useState<Nullable<CustomEndpointDetailsModel>>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);

    const [instanceTypes, setInstanceTypes] = useState<BaseInstanceTypeListModel[]>([]);
    const [instanceTypesLoading, setInstanceTypesLoading] = useState(false);
    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility();
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [isDeleteModalVisible, deleteModalShouldRender, onDeleteModalToggle] = useToggleVisibility()

    useEffect(() => {
        fetchDetails();
        fetchInstanceTypes();
    }, [accountId, entityId]);

    const fetchDetails = async () => {
        try {
            resetError();
            setDetailsLoading(true);
            const response: CustomEndpointDetailsResponseModel = await axios.get(`/customEndpoint?id=${entityId}`);
            setDetails(response.model);
        } catch (err) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const fetchInstanceTypes = async () => {
        try {
            setInstanceTypesLoading(true);
            const response: BaseInstanceTypeListResponseModel = await axios.get('/customEndpoint/instanceType/list');
            setInstanceTypes(response.models);
        } catch (err) {
        } finally {
            setInstanceTypesLoading(false);
        }
    };

    const detailsInfoData = useMemo<Nullable<DetailsInfoOverviewDataType>>(() => {
        if (!details) return null;

        const {
            createdAt,
            createdBy,
            modifiedBy,
            lastModified,
            title,
            scriptPath,
            requirementsPath,
            defaultVersion,
            lastVersion,
            alias,
        } = details;

        return {
            '@common.title': title,
            '@alias': alias,
            '@python.script': scriptPath,
            '@requirements.text.file': requirementsPath ?? '',
            '@createdAt': formatDateAndTime(createdAt),
            '@createdBy': createdBy,
            '@common.last-modified': formatDateAndTime(lastModified),
            '@modifiedBy': modifiedBy,
            '@deployed.version': defaultVersion?.versionNumber ?? t('@no.deployed.version', { defaultValue: 'None' }),
            '@last.build.status': lastVersion ? (
                <StatusComponent
                    style={{ width: '100%' }}
                    className="justify-center"
                    message={lastVersion?.buildStatusMessage}
                    status={lastVersion?.buildStatus}
                />
            ) : (
                t('@no.last.build', { defaultValue: 'None' })
            ),
        };
    }, [details]);

    const showLogTab = useMemo(() => {
        if (!details?.endpointDetails) return false;
        const { status, logPresignedUrl } = details.endpointDetails;

        return status.toLocaleLowerCase() === 'failed' && !!logPresignedUrl;
    }, [details?.endpointDetails]);

    if (detailsError.status === 404) {
        return <Error404 model="customEndpoint" />;
    }

    const onRefresh = () => fetchDetails();

    const onClickEdit = () => {
        onToggleVisibility(true);
    };

    const onRebuild = async () => {
        try {
            const payload = {
                accountId,
                id: entityId,
            };
            await axios.post(`/customEndpoint/deploy`, payload);
            toast.success(t('@toast-messages.success-customEndpoint-rebuild'));
        } catch (err) {}
    };

    const onRestart = async () => {
        try {
            const payload = {
                accountId,
                id: entityId,
            };
            await axios.post(`/customEndpoint/restart`, payload);
            toast.success(t('@toast-messages.success-customEndpoint-restart'));
        } catch (err) {}
    };

    const onClickDelete = () => {
        onDeleteModalToggle(true)
    }

    const handleDelete = async () => {
        try {
            setDeleteLoading(true)
            await axios.delete(`customEndpoint?id=${entityId}&accountId=${accountId}`)
            toast.success(`Custom Endpoint has been deleted!`);
            history.push(`${CUSTOM_ENDPOINTS_PATH}${window.location.search}`)
        } catch (err) {
            setDeleteLoading(false)
            onDeleteModalToggle(false)
            handleError(err)
        }
    }

    const actions = [
        {
            action: 'edit',
            onClick: onClickEdit,
        },
        {
            action: 'deploy',
            displayName: 'Rebuild',
            onClick: onRebuild,
        },
        {
            action: 'refresh',
            displayName: 'Restart',
            onClick: onRestart,
        },
        {
            action: 'delete',
            onClick: onClickDelete
        }
    ];

    return (
        <customEndpointDetailsContext.Provider value={{ details, setDetails }}>
            {deleteModalShouldRender && (
                <ConfirmationPopupModal
                    title={'Endpoint delete confirmation'}
                    isVisible={isDeleteModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteLoading}
                    onCancel={() => {
                        onDeleteModalToggle(false);
                    }}
                    description={'Are you sure you want to delete this item'}
                />
            )}
            {shouldRender && (
                <CreateCustomEndpointModal
                    instanceTypes={instanceTypes}
                    instanceTypesLoading={instanceTypesLoading}
                    isVisible={isVisible}
                    idForEdit={entityId}
                    onHide={() => onToggleVisibility(false)}
                    onSuccess={onRefresh}
                />
            )}
            <div className="flex flex-col flex-1">
                <DetailsInfoOverview
                    title={t('@customEndpoint.overview', { defaultValue: 'Endpoint overview' })}
                    data={detailsInfoData}
                    loading={detailsLoading}
                    titleRightComponent={
                        <ToggleActionButton>
                            <>
                                {actions.map((_action, i) => {
                                    return (
                                        <TableActionRow
                                            key={i}
                                            onClickAction={_action.onClick || (() => {})}
                                            {..._action}
                                            row={i}
                                        />
                                    );
                                })}
                            </>
                        </ToggleActionButton>
                    }
                >
                    {details?.apiKey && (
                        <CopyButton
                            className="absolute right-4 bottom-3"
                            successCopyMessage="Api key has been copied to clipboard!"
                            title="Copy Api key"
                            textForCopy={details?.apiKey}
                        />
                    )}
                </DetailsInfoOverview>

                {detailsLoading ? (
                    <DetailsLoader />
                ) : !details ? (
                    <DetailsEmpty onRefresh={fetchDetails} />
                ) : (
                    <div className="flex flex-1 flex-col">
                        <CustomTabContainer
                            tabHeaderProps={{
                                rightComponent: details?.endpointDetails ? (
                                    <StatusComponent
                                        className="mr-6"
                                        iconSize={28}
                                        message={details.endpointDetails.message}
                                        status={details.endpointDetails.status}
                                        tooltipId="customEndpointStatus"
                                    />
                                ) : null,
                            }}
                            style={{ minHeight: 500 }}
                            className="mt-6"
                            animationEnabled={false}
                            defaultActiveTab={tabs.logs}
                            tabs={[
                                showLogTab
                                    ? {
                                          key: tabs.logs,
                                          displayName: 'Logs',
                                          component: <CustomEndpointLogsTab />,
                                      }
                                    : null,
                                {
                                    key: tabs.metrics,
                                    displayName: t('@metrics', { defaultValue: 'Metrics' }),
                                    component: <CustomEndpointMetricsTab endpointId={details.id} />,
                                    disabled: showLogTab,
                                },
                                {
                                    key: tabs.versions,
                                    component: <CustomEndpointVersionsTab />,
                                    displayName: t('@versions', { defaultValue: 'Versions' }),
                                },
                                {
                                    key: tabs.swagger,
                                    displayName: t('@swagger', { defaultValue: 'Swagger' }),
                                    component: <CustomEndpointSwaggerTab endpoint={details?.endpoint ?? null} />,
                                    disabled: showLogTab,
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        </customEndpointDetailsContext.Provider>
    );
};
