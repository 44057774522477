import { CSSProperties, FC } from 'react';

export interface TabChromeProps {
    title: string;
    name: string;
    onClick: (name: any) => void;
    isActive: Boolean;
    style?: CSSProperties;
    className?: string;
}

export const TabChrome: FC<TabChromeProps> = ({ isActive, name, onClick, title, className, style }) => {
    return (
        <div
            onClick={() => onClick(name)}
            style={{ boxShadow: '0px 6opx 0px white', ...style }}
            className={`w-40 h-auto py-3 pl-2 items-center flex rounded-t-lg  cursor-pointer z-10  ${
                isActive ? 'bg-white card-shadow ' : ''
            } ${className}`}
        >
            <span className="font-medium text-blueMainText text-xs">{title}</span>
        </div>
    );
};
