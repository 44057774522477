import React, { CSSProperties, ReactNode } from 'react';

type SubHeaderType = {
    title?: string;
    titleComponent?: ReactNode | undefined;
    rightComponent?: ReactNode | undefined;
    className?: string;
    style?: CSSProperties;
};

export const SubHeader: React.FC<SubHeaderType> = ({ title, rightComponent, style, titleComponent }) => {
    return (
        <div
            className="flex flex-col items-center sm:flex-row sm:justify-between sm:items-center w-full pb-4 h-auto bg-white z-20"
            style={{
                height: 'auto',
                minHeight: 'auto',
                width: '100%',
                ...style,
            }}
        >
            {titleComponent ? (
                titleComponent
            ) : (
                <div style={{ paddingLeft: 24, paddingTop: 24 }} className="w-auto flex">
                    <h1 className="text-secondaryText text-base font-medium">{title}</h1>
                </div>
            )}
            <div className="w-auto flex flex-row justify-end" style={{ paddingRight: 24, paddingTop: 24 }}>
                {rightComponent}
            </div>
        </div>
    );
};
