import * as yup from 'yup';

export const UserAccountFormSchema = yup.object().shape({
    email: yup.string().email('Email must be a valid email!').required('Email is a required field!'),
    role: yup.string().required('Role is a required field!'),
});

export const AccountApiKeyFormSchema = yup.object().shape({
    apiKeyName: yup.string().required('Name is a required field!'),
    role: yup.string().required('Role is a required field!'),
});
