interface ICustomEndpointSwaggerTab {
    endpoint: string | null;
}

export const CustomEndpointSwaggerTab = ({ endpoint }: ICustomEndpointSwaggerTab) => {
    return (
        <div className="flex flex-1">
            {endpoint && <iframe style={{ minHeight: 800 }} src={endpoint} className="w-full h-full" />}
        </div>
    );
};
