import { CSSProperties, ReactNode } from 'react';
import { ReactComponent as CreatedImage } from '../../../assets/icons/statuses/created.svg';
import { ReactComponent as DeletedImage } from '../../../assets/icons/statuses/deleted.svg';
import { ReactComponent as DeployedImage } from '../../../assets/icons/statuses/deployed.svg';
import { ReactComponent as RestartImage } from '../../../assets/icons/statuses/restarted.svg';
import { ReactComponent as FailedImage } from '../../../assets/icons/statuses/failed.svg';
import { ReactComponent as RunImage } from '../../../assets/icons/statuses/running.svg';
import { ReactComponent as SucceededImage } from '../../../assets/icons/statuses/succeeded.svg';
import { ReactComponent as TerminatingImage } from '../../../assets/icons/statuses/terminated.svg';
import { ReactComponent as UpdatedImage } from '../../../assets/icons/statuses/updated.svg';
import { ReactComponent as UploadedImage } from '../../../assets/icons/statuses/uploaded.svg';

export enum NotificationStatusTypes {
    Running = 'Running',
    Terminating = 'Terminating',
    Deleted = 'Deleted',
    Created = 'Created',
    Updated = 'Updated',
    Uploaded = 'Uploaded',
    Deployed = 'Deployed',
    Rebuilded = 'Rebuilded',
    Restart = 'Restarted',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
}

export const CustomNotificationStatuses: NotificationStatusTypes[] = [
    NotificationStatusTypes.Succeeded,
    NotificationStatusTypes.Failed,
];

const svgStyles: CSSProperties = {
    height: '100%',
    width: '100%',
};

const statusImages: Record<string, ReactNode> = {
    created: <CreatedImage style={svgStyles} />,
    deleted: <DeletedImage style={svgStyles} />,
    running: <RunImage style={svgStyles} />,
    terminating: <TerminatingImage style={svgStyles} />,
    updated: <UpdatedImage style={svgStyles} />,
    uploaded: <UploadedImage style={svgStyles} />,
    deployed: <DeployedImage style={svgStyles} />,
    rebuilded: <DeployedImage style={svgStyles} />,
    restarted: <RestartImage style={svgStyles} />,
    succeeded: <SucceededImage style={svgStyles} />,
    failed: <FailedImage style={svgStyles} />,
};

interface IStatusImageProps {
    status: string;
}

export const StatusImage = ({ status }: IStatusImageProps) => {
    return (
        <div className="h-8 w-8 rounded-full border-mainBorder  flex items-center justify-center">
            {statusImages[status.toLocaleLowerCase()]}
        </div>
    );
};
