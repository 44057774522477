import { CSSProperties, useRef, useState } from 'react';
import { breakpoints } from '../../../constants';
import { BaseEntityProperty } from '../../../models';
import { getHeight, getWidth } from '../../../utils';
import { InfoPopupModal, ModalVisibleType } from '../../modals';
import { Icon, IconTypes } from '../Icon';
export interface InfoIconWithModalProps {
    property?: BaseEntityProperty;
    infoText?: string;
    infoLink?: string;
}

type LocalStateType = {
    isVisible: boolean;
    visibleType: ModalVisibleType | '';
};

export const InfoIconWithModal: React.FC<InfoIconWithModalProps> = ({ property, infoLink, infoText }) => {
    const IconRef = useRef<HTMLImageElement>(null);
    const modalWidth = 350;
    let timeoutHideModal: any = null;
    let hoverTimeout: any = null;

    /**
     * Local state
     */
    const [state, setState] = useState<LocalStateType>({
        isVisible: false,
        visibleType: '',
    });
    const [modalStyle, setModalStyle] = useState<CSSProperties>({
        width: modalWidth,
        height: 'auto',
    });

    const onInfoHovered = () => {
        hoverTimeout = setTimeout(() => {
            setState({
                isVisible: true,
                visibleType: ModalVisibleType.hovered,
            });
            findModalPosition();
        }, 300);
    };

    const onInfoClicked = () => {
        clearTimeout(hoverTimeout);
        setState({
            isVisible: true,
            visibleType: ModalVisibleType.clicked,
        });
        findModalPosition();
    };

    const findModalPosition = () => {
        if (IconRef?.current?.getBoundingClientRect) {
            let style: CSSProperties = {};
            const { current } = IconRef;

            let x = current.getBoundingClientRect().x;
            let left = x + 30;

            if (x + modalWidth > getWidth()) {
                left = x - modalWidth - 10;
            } else if (x < modalWidth) {
                left = x + 20;
            }

            if (left < 0) {
                left = 5;
                style.width = '95%';
                style.minWidth = '95%';
            } else {
                style.width = modalWidth;
                style.minWidth = modalWidth;
            }

            style.left = left;
            setModalStyle(prev => ({ ...prev, ...style }));
        }
    };
    const onChangeModalHeight = height => {
        if (IconRef?.current?.getBoundingClientRect) {
            const { current } = IconRef;
            let y = current.getBoundingClientRect().y;
            let top = y;

            if (height) {
                if (y + height >= getHeight()) {
                    top = getHeight() - height - 100;
                }
                if (getWidth() <= breakpoints.sm) {
                    top -= height + 10;
                }
            }
            setModalStyle(prev => ({ ...prev, top }));
        }
    };

    const onHide = () => {
        setState({
            isVisible: false,
            visibleType: '',
        });
    };

    return (
        <>
            <InfoPopupModal
                infoLink={infoLink || property?.infoLink}
                infoText={infoText || property?.infoText}
                isVisible={state.isVisible}
                visibleType={state.visibleType}
                modalStyle={modalStyle}
                onHide={onHide}
                onMouseOverModal={() => {
                    clearTimeout(timeoutHideModal);
                    if (state.visibleType === ModalVisibleType.hovered) {
                        // setTimeout(() => {
                        // onInfoHovered();
                        // });
                    }
                }}
                getModalHeight={onChangeModalHeight}
            />
            <div
                className="cursor-pointer px-1 flex items-start justify-end"
                id="InfoWithIcon"
                onClick={e => {
                    e.stopPropagation();
                    onInfoClicked();
                }}
                onMouseOver={e => {
                    e.stopPropagation();
                    onInfoHovered();
                }}
                onMouseOut={() => {
                    clearTimeout(hoverTimeout);
                }}
                onMouseLeave={() => {
                    clearTimeout(hoverTimeout);
                    if (state.visibleType === ModalVisibleType.hovered) {
                        timeoutHideModal = setTimeout(() => {
                            onHide();
                        }, 300);
                    }
                }}
            >
                <Icon name={IconTypes.info} size={24} ref={IconRef} />
            </div>
        </>
    );
};
