import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { AutoRefreshType, SelectOptionType } from '../../../../../models';

export const areaChartOptions: ApexOptions = {
    xaxis: {
        type: 'datetime',
        tickAmount: 5,
        labels: {
            rotate: 0,
            offsetY: -2,
            formatter: function (value, timestamp) {
                let date = format(new Date(timestamp ?? 0), 'dd MMMM').toString();
                return date;
            },
        },
    },
};

export const filterDaysOptions: SelectOptionType[] = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '20',
        value: '20',
    },
    {
        label: '30',
        value: '30',
    },
];

 export const autoRefreshValues: AutoRefreshType[] = [
    {
      label: "Off",
      value: '0',
    },
    {
      label: "Every 1 min",
      value: '1',
    },
    {
      label: "Every 3 min",
      value: "3",
    },
    {
      label: "Every 5 min",
      value: "5",
    },
 ]