import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export const datasetFormSchema = (t: TFunction<'translation', undefined>) => {
    return yup.object().shape({
        title: yup
            .string()
            .trim()
            .required(t('@validations.field.isRequired', { field: t('@common.title') })),
        dataTypeId: yup.number().moreThan(0, t('@validations.field.isRequired', { field: t('@dataType') })),
        objectiveId: yup.number().moreThan(0, t('@validations.field.isRequired', { field: t('@objective') })),
        objectPath: yup
            .string()
            .required(t('@validations.field.isRequired', { field: t('@object.storage.path') }))
            .when('isObjectPathFolder', {
                is: folder => !folder,
                then: yup.string().matches(/.*\.csv$/, {
                    message: t('@validations.select.csv.file', { defaultValue: 'Please select a Csv File!' }),
                }),
            }),
    });
};
