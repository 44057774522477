import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveCardComponent } from '.';
import { ErrorTextComponent } from '../../../../components/messages';
import { Loader } from '../../../../components/ui';
import {
    RecommendationEngineObjectiveListModel,
    RecommendationEngineObjectiveListResponseModel,
} from '../../../../models';
import axios from '../../../../services/axios';

interface RenderObjectiveCardsComponentProps {
    recommendationType: string;
    selectedObjectiveId: number;
    setSelectedObjectiveId: (id: number) => any;
    className?: string;
    errorMessage?: string;
    isEditing: boolean;
}
const getGridRowsByItemsLength = length => {
    return `grid-rows-${length} sm:grid-rows-${Math.round(length / 2)}`;
};

export const RenderObjectiveCardsComponent: React.FC<RenderObjectiveCardsComponentProps> = ({
    recommendationType,
    selectedObjectiveId,
    setSelectedObjectiveId,
    className,
    isEditing,
    errorMessage,
}) => {
    const { t } = useTranslation();

    const [objectives, setObjectives] = useState<RecommendationEngineObjectiveListModel[]>([]);
    const [objectivesLoading, setObjectivesLoading] = useState(false);

    useEffect(() => {
        fetchObjectives();
    }, [recommendationType]);

    const fetchObjectives = async () => {
        try {
            setObjectivesLoading(true);
            const response: RecommendationEngineObjectiveListResponseModel = await axios.get(
                `/recommendationEngineObjective/list?type=${recommendationType}`
            );
            let objectives = [...response.models].reverse();
            setObjectives(objectives);
            if (!isEditing && objectives.length > 0) {
                setSelectedObjectiveId(objectives[0].id);
            }
        } catch (err) {
        } finally {
            setObjectivesLoading(false);
        }
    };

    if (objectivesLoading) {
        return (
            <div className="flex w-full justify-center items-center p-10.5">
                <Loader />
            </div>
        );
    }
    return (
        <div className={`w-full ${className}`}>
            <span className="text-blueMainText text-sm font-medium">
                {t('@recommendation.objectives', { defaultValue: 'Objectives' })}
            </span>
            <div
                className={`w-full mt-4 flex flex-col items-center sm:grid sm:grid-flow-rows sm:grid-cols-2 sm:gap-2 sm:gap-y-4 ${getGridRowsByItemsLength(
                    objectives.length
                )}`}
            >
                {objectives.map(item => (
                    <ObjectiveCardComponent
                        key={item.id}
                        titleKey={item.nameResourceKey}
                        descriptionKey={item.descriptionResourceKey}
                        imageUrl={item.imageUrl}
                        isSelected={item.id === selectedObjectiveId}
                        onClickSelect={() => setSelectedObjectiveId(item.id)}
                        className="sm:mb-0 mb-4"
                    />
                ))}
            </div>
            <ErrorTextComponent message={errorMessage} />
        </div>
    );
};
