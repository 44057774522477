import { CSSProperties, useState } from 'react';
import { Loader } from '../ui';

export interface ProfileImageProps {
    imageUrl?: string;
    isLoading: boolean;
    firstCharacter?: string;
    className?: string;
    style?: CSSProperties;
}

export const ProfileImage: React.FC<ProfileImageProps> = ({
    isLoading,
    imageUrl,
    firstCharacter,
    className,
    style,
}) => {
    const [hasImageFailed, setHasImageFailed] = useState(false);
    return (
        <div className={`h-8 w-8 flex items-center justify-center ${className}`} style={style}>
            {isLoading ? (
                <Loader />
            ) : imageUrl && !hasImageFailed ? (
                <img
                    className="h-full w-full  rounded-full object-cover"
                    src={imageUrl}
                    onError={() => {
                        setHasImageFailed(true);
                    }}
                />
            ) : (
                <span className="h-full border border-secondaryText w-full font-bold rounded-full flex items-center justify-center text-secondaryText bg-white">
                    {firstCharacter?.toUpperCase()}
                </span>
            )}
        </div>
    );
};
