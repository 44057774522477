import { PaymentMethod } from '@stripe/stripe-js';
import { format } from 'date-fns';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../components/dropdown';
import { ActionsButton, Loader } from '../../../../components/ui';

export interface PaymentMethodItemProps {
    item: PaymentMethod;
    className?: string;
    style?: CSSProperties;
    isPrimary?: boolean;
    onDelete?: () => any;
    onSetAsPrimary?: () => any;
    actionLoading?: string;
}

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({
    item,
    className,
    style,
    isPrimary,
    onDelete,
    onSetAsPrimary,
    actionLoading,
}) => {
    const { t } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dotSeparator = <span className="mx-2 font-bold">•</span>;
    return (
        <div
            className={`w-full flex rounded ${
                isPrimary ? 'border-mainBorder border' : 'bg-mainBackground'
            } p-4 ${className}`}
            style={style}
        >
            <div className="flex flex-col flex-1">
                <span className="text-blueMainText text-sm font-normal">
                    <span className="font-bold">
                        {item.billing_details.name} {dotSeparator}
                    </span>
                    xxxx-{item.card?.last4} {dotSeparator} {t(!!isPrimary ? 'card.primary' : 'card.backup')}
                </span>
                <span className="text-secondaryText font-medium text-xs mt-2">
                    Expires {item.card?.exp_month}/{item.card?.exp_year} • Added on{' '}
                    {format(new Date(item.created * 1000), 'dd MMM yyyy')}
                </span>
            </div>
            <div className="h-full flex flex-col items-center justify-center">
                <Dropdown
                    wrapperClassName="ml-2"
                    withArrow={false}
                    openProps={{
                        isOpen: isDropdownOpen,
                        onSetIsOpen: isOpen => setIsDropdownOpen(isOpen),
                    }}
                    childrenContainerClassName="w-52 pl-4"
                    triggerProps={{
                        children: <ActionsButton className={`${isDropdownOpen && 'bg-mainBackground'}`} />,
                    }}
                >
                    <div className="flex flex-col py-1 w-full bg-white rounded dropdown-shadow mt-2">
                        <span
                            className="px-3 hover:bg-greyWhite200 flex w-full py-2 text-sm text-deleteColor cursor-pointer"
                            onClick={onDelete}
                        >
                            {actionLoading === 'delete' ? <Loader /> : t('@common.delete')}
                        </span>
                        {!isPrimary && (
                            <span
                                className="px-3 hover:bg-greyWhite200 flex w-full py-2 text-sm text-blueMainText cursor-pointer"
                                onClick={onSetAsPrimary}
                            >
                                {actionLoading === 'primary' ? <Loader /> : t('set.as.primary')}
                            </span>
                        )}
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};
