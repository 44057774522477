import { useEffect, useRef } from 'react';
import { RefreshButton } from '../../../../../../components/buttons/RefreshButton';
import { StatusComponent } from '../../../../../../components/common';
import { PythonLoggingLevels } from '../../../../../../constants/python-logging-constants';
interface IRunDetailsVisibleProps {
    _id: string;
    displayName?: string;
    status: string;
    message: string;
    logs: string[];
    onRefresh?: (e?: any) => any;
}

export const RunDetailsVisible = ({ _id, displayName, status, message, logs, onRefresh }: IRunDetailsVisibleProps) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [logs]);

    const formatLogString = (log, index) => {
        const logString = log.split(' - '); 
        let logLevel = logString[1]
        return (
                <p style={{ marginLeft: index < 9 ? '.35rem' : '' }}>{logString.slice(0, 1)} - <span style={{color: PythonLoggingLevels[logLevel] }}>{logLevel}</span> - {logString.slice(2, logString.length)}</p>
                )
    }
    return (
        <div className="h-full flex flex-col">
            <div className="w-full flex items-center justify-between mb-0 p-4">
                <div className="flex items-center">
                    <StatusComponent
                        message={status === 'Succeeded' ? 'Succeeded' : message}
                        status={status}
                        iconSize={40}
                        className="mr-2"
                    />
                    <h2 className="text-lg font-normal ml-2 text-blueMainText mr-1">{displayName}</h2>
                </div>

                <div className="">
                    <RefreshButton className="flex items-center border" onRefresh={e => onRefresh && onRefresh(e)} />
                </div>
            </div>
            <div className="text-xs space-y-[5px] text-white overflow-y-auto grow" style={{background: '#000', padding: '1rem', fontFamily: 'monospace', color: '#fff'}}>
                {logs?.length === 0 && (
                    <div className="flex">
                        <p className="mr-5">1</p>
                        <p className="text-green-400">No logs yet</p>
                    </div>
                )}

                {logs.map((log, index) => {
                    const highlightLog = (index === logs.length - 1) ? {background: '#eee', color: '#000'} : {};
                    return(
                        <div className="flex" ref={ref} style={highlightLog}>
                        <p className="mr-5" >{index + 1}</p>
                        {formatLogString(log, index)}
                    </div>
                    )})}
            </div>
        </div>
    );
};
