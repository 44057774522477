import { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ACCOUNT_SETTINGS_PATH, BILLING_PATH } from '../../../constants';
import { useCurrentWidth } from '../../../hooks';
import { AccountDetailsResponseModel, UserDetailsResponseModel } from '../../../models';
import { selectors as accountDetailsSelectors } from '../../../redux/thunk/app/account/accountDetailsThunk';
import { selectors as userDetailsSelectors } from '../../../redux/thunk/app/user/userThunk';
import { actions as themeActions, selectors as themeSelectors } from '../../../redux/thunk/theme';
import { ItemProps, SidebarItem } from './SidebarItem';
export interface MainSidebarMenuProps {
    ulProps?: HTMLAttributes<HTMLUListElement>;
    list: ItemProps[];
    style?: CSSProperties;
    hideLinks?: boolean;
    disableLinks?: boolean;
}

export const MainSidebarMenu: FC<MainSidebarMenuProps> = ({ ulProps, list, style, disableLinks }) => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const currentWidth = useCurrentWidth();
    const location = useLocation();

    /**
     * Selectors
     */
    const account: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const user: UserDetailsResponseModel = useSelector(userDetailsSelectors.getResponse);
    const isMenuOpen = useSelector(themeSelectors.getIsMenuOpened);

    /**
     * Local state
     */
    const [activeListsOpen, setActiveListsOpen] = useState<string>('');
    const [activeTitle, setActiveTitle] = useState('');

    const onLinkClicked = () => {
        if (isMenuOpen && currentWidth < 900) dispatch(themeActions.closeMenu());
    };

    const toggleMenu = () => dispatch(themeActions.toggleMenu());

    useEffect(() => {
        const pathname = location.pathname;
        if (pathname && list.length > 0) {
            let activeTitle = '';

            for (let i = 0; i < list.length; i++) {
                const subList = list[i].nestedList;

                if (subList && subList.length > 0) {
                    for (let j = 0; j < subList.length; j++) {
                        if (subList[j].nestedList && (subList[j]?.nestedList?.length || 0) > 0) {
                            let subListChild = subList[j].nestedList || [];
                            for (let k = 0; k < subListChild.length; k++) {
                                if (pathname.includes(subListChild[k].to || '')) {
                                    activeTitle = list[i].titleKey;
                                }
                            }
                        } else if (pathname.includes(subList[j].to || '')) {
                            activeTitle = list[i].titleKey;
                        }
                    }
                }
            }

            setActiveTitle(activeTitle);
        }
    }, [location]);

    return (
        <ul className="pt-2" {...ulProps} style={{ ...style }}>
            {list.map(({ nestedList, shouldRenderLink, ...rest }, key) => {
                if (shouldRenderLink && !shouldRenderLink(account.model, user.model)) {
                    return null;
                }

                return (
                    <SidebarItem
                        disable={
                            !!disableLinks &&
                            !(rest.to?.includes(BILLING_PATH) || rest.to?.includes(ACCOUNT_SETTINGS_PATH))
                        }
                        key={key}
                        {...rest}
                        nestedList={nestedList}
                        onLinkClicked={onLinkClicked}
                        toggleMenu={toggleMenu}
                        isMenuOpen={isMenuOpen}
                        isListOpen={[activeTitle, activeListsOpen].includes(rest.titleKey)}
                        onToggleList={open => {
                            if (!open && activeTitle === rest.titleKey) {
                                setActiveTitle('');
                            } else if (open && nestedList?.map(x => x.to).includes(location.pathname)) {
                                setActiveTitle(rest.titleKey);
                            }

                            if (open) setActiveListsOpen(rest.titleKey);
                            else setActiveListsOpen('');
                        }}
                    />
                );
            })}
        </ul>
    );
};
