import { useQuery } from './useQuery';

/**
 *
 * @param shouldParseToInt boolean
 *
 * @returns accountID
 */
export function useAccountId() {
    const accountId = useQuery().get('accountId');
    return accountId ? parseInt(accountId) || 0 : 0;
}
export function useAccountIdString() {
    const accountId = useQuery().get('accountId');
    return accountId;
}
