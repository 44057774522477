import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { inputAnimationClassName } from '../../../constants';
import { SelectOptionType } from '../../../models';
import { StoragePopupOptions } from '../../../pages/data/storage';
import { CreateButton } from '../../buttons';
import { Icon, IconTypes } from '../../common';

interface ISelectStorageInputProps {
    disabled?: boolean;
    errorMessage?: string;
    onShowModal: () => any;
    isClearable?: boolean;
    selectedItems: SelectOptionType[];
    onChangeSelectedItems: (items: SelectOptionType[]) => any;
    showSelectedItemInsideInput?: boolean;
    placeholder?: string;
    options?: StoragePopupOptions;
}

export const SelectStorageInput = ({
    onChangeSelectedItems,
    onShowModal,
    selectedItems,
    disabled,
    errorMessage,
    isClearable,
    options,
    placeholder,
    showSelectedItemInsideInput,
}: ISelectStorageInputProps) => {
    const { t } = useTranslation();

    const commonClassName = useMemo(
        () =>
            `${disabled ? 'bg-disabledColor cursor-not-allowed' : `cursor-pointer ${inputAnimationClassName}`} ${
                errorMessage ? 'border-mainError transition-all duration-300 ease-in' : 'border-grey200'
            }`,
        [errorMessage, disabled]
    );

    const inputText = useMemo(() => {
        if (selectedItems.length < 1) return placeholder;

        if (showSelectedItemInsideInput && !options?.multiple) return selectedItems[0].label;

        return t('@files.selected', { count: selectedItems.length, defaultValue: '{{count}} File(s) selected!' });
    }, [options, placeholder, showSelectedItemInsideInput, selectedItems, t]);

    const onChangeInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        onChangeSelectedItems(value.length ? [{ label: value, value }] : []);
    };

    const inputValue = selectedItems.length ? selectedItems[0].value : '';
    if (options?.inputInside)
        return (
            <div className="w-full relative flex">
                <input
                    onChange={onChangeInput}
                    placeholder={placeholder}
                    value={inputValue}
                    className={`${commonClassName} !cursor-text text-sm text-blueMainText py-2 px-3 h-8 sm:h-10 rounded border w-full pr-[100px]`}
                />
                <CreateButton
                    type="button"
                    onClick={onShowModal}
                    className="absolute top-1.5 bottom-1.5 right-1.5 !text-[12px] !w-[80px] !h-7 "
                    title="Browse"
                />
            </div>
        );

    return (
        <button
            type="button"
            className={`${commonClassName} w-full bg-white rounded box-border flex flex-row items-center justify-between py-2 px-3 h-8 sm:h-10 border`}
            onClick={e => {
                e.stopPropagation();
                !disabled && onShowModal();
            }}
        >
            <span
                className={`truncate font-normal text-xs sm:text-sm ${
                    selectedItems.length ? 'text-blueMainText' : 'text-mainPlaceholder'
                }`}
            >
                {inputText}
            </span>
            {isClearable && selectedItems.length > 0 && (
                <Icon
                    name={IconTypes.close}
                    size={22}
                    onClick={e => {
                        e.stopPropagation();
                        onChangeSelectedItems && onChangeSelectedItems([]);
                    }}
                />
            )}
        </button>
    );
};
