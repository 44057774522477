import { AnimationComponent } from '../../../components/animation';
import { TimeLineNotificationItemLoader } from './TimeLineNotificationItemLoader';

export const TimeLineNotificationsInitialLoading = () => {
    return (
        <AnimationComponent show type="fade-top" delay={100} transform="transform-gpu" duration={300}>
            <div className="w-full flex flex-col">
                <div className="w-full animate-pulse duration-100 flex flex-row h-11 items-center">
                    <div className="w-4 mr-4 h-full">
                        <div className={`h-4 w-0.5 bg-none mx-auto`} />
                        <div className={`w-3 h-3 rounded-full bg-blueStroke mx-auto`} />
                        <div className="h-4 w-0.5 bg-blueStroke mx-auto" />
                    </div>
                    <div className="h-4 mt-1 w-28 rounded  bg-grey300" />
                </div>
                <div className="flex flex-row w-full">
                    <div className="flex flex-col w-4 mr-4  h-full justify-center items-center">
                        <div className="h-full w-0.5 bg-blueStroke" />
                    </div>
                    <div className="flex flex-col flex-1">
                        <TimeLineNotificationItemLoader className="mt-2" />
                        <TimeLineNotificationItemLoader className="mt-2" />
                        <TimeLineNotificationItemLoader className="mt-2" />
                        <TimeLineNotificationItemLoader className="mt-2" />
                    </div>
                </div>
            </div>
        </AnimationComponent>
    );
};
