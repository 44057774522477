import { WebStorageStateStore } from 'oidc-client';
import { UserManager } from 'oidc-react';

const redirectUri = window.location.origin;
const requestedUrl = sessionStorage.getItem('url_requested')?.toString();

export const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: localStorage }),
    authority: process.env.REACT_APP_AUTHORITY_API_URL,
    client_id: process.env.REACT_APP_AUTHORITY_CLIENT_ID,
    scope: process.env.REACT_APP_AUTHORITY_SCOPE,
    response_type: process.env.REACT_APP_AUTHORITY_RESPONSE_TYPE,
    redirect_uri: redirectUri,
    post_logout_redirect_uri: redirectUri,
    popup_redirect_uri: redirectUri,
    silent_redirect_uri: redirectUri,
    automaticSilentRenew: true,
});

export const oidcConfig = {
    onSignIn: async () => {
        userManager.clearStaleState();

        window.location.hash = '';
        window.location.replace(requestedUrl || redirectUri);
    },
    userManager: userManager,
};
