import { AnimationComponent } from '.';
import { ANIMATION_PAGE_DELAY, ANIMATION_PAGE_DURATION } from '../../constants/animation-constants';

export const PageAnimationContainer = ({ children }) => {
    return (
        <AnimationComponent show={true} delay={ANIMATION_PAGE_DELAY} duration={ANIMATION_PAGE_DURATION} type="fade-in">
            {children}
        </AnimationComponent>
    );
};
