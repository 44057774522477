import { format, getDate, getMonth, getYear } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Range } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { AlertInfoComponent } from '../../../components/alerts';
import { CreateButton } from '../../../components/buttons';
import { DateRangePickerInput } from '../../../components/inputs';
import { PriceLoadingShimmer, TransactionLoadingShimmer } from '../../../components/loaders';
import { toast } from '../../../components/ui';
import { billingPageContext } from '../../../contexts';
import { useAccountId } from '../../../hooks';
import { TransactionListModel, TransactionListResponseModel } from '../../../models';
import axios from '../../../services/axios';
import { CollapsibleRow } from '../components/CollapsableRow';
import { PayNowConfirmPopup } from './components';

const currentDate = new Date();

export const OverviewTab: React.FC = () => {
    const { t } = useTranslation();
    const accountId = useAccountId();

    /**
     * Local State
     */
    const [payNowLoading, setPayNowLoading] = useState(false);
    const [currentCostLoading, setCurrentCostLoading] = useState(false);
    const [transactionsLoading, setTransactionsLoading] = useState(false);
    const [transactions, setTransactions] = useState<TransactionListModel[][]>([]);
    const [transactionDateRanges, setTransactionDateRanges] = useState<Range[]>([
        {
            startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            endDate: currentDate,
            key: 'transactionDateRanges',
        },
    ]);
    const [isConfirmPayNowModalVisible, setIsConfirmPayNowModalVisible] = useState(false);

    /**
     * Redux Selectors
     */
    const { currentCost, setCurrentCost } = useContext(billingPageContext);

    useEffect(() => {
        fetchCurrentCost();
        fetchAllTransactions();
    }, [accountId]);

    const fetchAllTransactions = async () => {
        try {
            setTransactionsLoading(true);
            let _endDate = new Date(transactionDateRanges[0].endDate || '');
            _endDate?.setDate(_endDate.getDate() + 1);

            let query = {
                startDate: transactionDateRanges[0].startDate?.toISOString() || '',
                endDate: _endDate?.toISOString() || '',
                accountId: accountId?.toString(),
            };

            const response: TransactionListResponseModel = await axios.get(
                `/transaction?${new URLSearchParams(query)}`
            );
            setTransactions(response.models);
        } catch (err) {
        } finally {
            setTransactionsLoading(false);
        }
    };

    const fetchCurrentCost = async () => {
        try {
            setCurrentCostLoading(true);
            let response: string = await axios.get(`/stripe/subscription/currentCosts?accountId=${accountId}`);
            setCurrentCost(Number(response).toFixed(2));
        } catch (err) {
        } finally {
            setCurrentCostLoading(false);
        }
    };

    const onSubmitPayNow = async () => {
        try {
            setPayNowLoading(true);
            await axios.post(`/stripe/subscription?accountId=${accountId}`);
            setCurrentCost('0.00');
            toast.success(t('@payment.succeeded', { defaultValue: 'Payment has been succeeded!' }));
            setIsConfirmPayNowModalVisible(false);
        } catch (err) {
        } finally {
            setPayNowLoading(false);
        }
    };

    const renderTransactions = () => {
        if (transactionsLoading) {
            return (
                <>
                    <TransactionLoadingShimmer className="mt-2" />
                    <TransactionLoadingShimmer className="mt-2" />
                    <TransactionLoadingShimmer className="mt-2" />
                </>
            );
        }

        if (transactions.length < 1) {
            return (
                <div className="w-full p-10 justify-center items-center flex">
                    <span className="text-secondaryText text-base font-normal">
                        {t('@transactions.empty', { defaultValue: 'There are no transaction for this date range!' })}
                    </span>
                </div>
            );
        }

        return transactions.map((_transactionsArray, index) => {
            const title = _transactionsArray[0].entityName;

            return (
                <CollapsibleRow key={index} title={title}>
                    {_transactionsArray.map((_transaction, index) => {
                        let isFirst = index === 0;
                        let _duration = _transaction.duration / 3600;
                        let fixedDuration = _duration % 1 !== 0 ? Number(_duration).toFixed(3) : _duration.toString();

                        return (
                            <div
                                key={_transaction.instanceTypeId}
                                className={`flex w-full justify-between py-2 ${!isFirst ? 'mt-3' : 'mt-1'}`}
                            >
                                <div
                                    style={{ flex: 2 }}
                                    className="flex flex-2 justify-start text-secondaryText text-left font-medium text-xs"
                                >
                                    {_transaction.instanceTypeName || 'Auto'}
                                </div>
                                <div className="flex flex-1 justify-start">
                                    <span
                                        style={{ minWidth: 50 }}
                                        className="text-secondaryText text-center font-medium text-xs"
                                    >
                                        {_duration > 0 ? parseFloat(fixedDuration) : 0} hour
                                        {(_duration !== 1 && 's') || ''}
                                    </span>
                                </div>
                                <div className="flex flex-1 justify-end text-blueMainText text-center font-medium text-xs">
                                    {_transaction.amount} EUR
                                </div>
                            </div>
                        );
                    })}
                </CollapsibleRow>
            );
        });
    };

    const transactionDateFilters = useMemo(() => {
        if (transactionDateRanges[0].endDate && transactionDateRanges[0].startDate) {
            const startDate = transactionDateRanges[0].startDate;
            const endDate = transactionDateRanges[0].endDate;

            const [startDay, startMonth, startYear] = [getDate(startDate), getMonth(startDate), getYear(startDate)];
            const [endDay, endMonth, endYear] = [getDate(endDate), getMonth(endDate), getYear(endDate)];

            if (startMonth === endMonth && startYear === endYear && startDay === endDay) {
                return `${format(startDate, 'MMMM')} ${startDay}, ${format(startDate, 'yyyy')}`;
            } else if (startMonth === endMonth && startYear === endYear) {
                return `${format(startDate, 'MMMM')} ${startDay} - ${endDay}, ${format(startDate, 'yyyy')}`;
            } else if (startYear === endYear) {
                return `${format(startDate, 'MMMM')} ${startDay} - ${format(endDate, 'MMMM')} ${endDay}, ${format(
                    startDate,
                    'yyyy'
                )}`;
            } else {
                return `${format(startDate, 'MMMM')} ${startDay}, ${format(startDate, 'yyyy')} - ${format(
                    endDate,
                    'MMMM'
                )} ${endDay}, ${format(endDate, 'yyyy')}`;
            }
        }
        return '';
    }, [transactionDateRanges]);

    const totalTransactionPrice = useMemo(() => {
        let totalTransactionPrice = 0;

        if (transactions.length && !transactionsLoading) {
            totalTransactionPrice = transactions.flat().reduce((accumulator, item) => accumulator + item.amount, 0);
        }

        return Number(totalTransactionPrice).toFixed(2);
    }, [transactions, transactionsLoading]);

    const cardClassName = `flex flex-col w-full rounded p-2 sm:p-4 mt-4 border border-mainBorder`;
    return (
        <>
            <PayNowConfirmPopup
                isVisible={isConfirmPayNowModalVisible}
                onHide={() => {
                    setIsConfirmPayNowModalVisible(false);
                }}
                onSubmitLoading={payNowLoading}
                onSubmitPayNow={onSubmitPayNow}
            />
            <div className="flex flex-col flex-1 bg-white">
                <AlertInfoComponent text={t('billing.info.message')} />

                <div className={`${cardClassName}`} style={{ flexDirection: 'row' }}>
                    <div className="flex flex-col flex-1">
                        <span className="text-base font-normal text-blueMainText">
                            {t('estimated.cost.for.this.billing.period')}
                        </span>
                        <span className="mt-1.5 sm:mt-2 text-xs font-medium text-secondaryText">
                            {t('billing.current.costs.description')}
                        </span>

                        {currentCostLoading ? (
                            <PriceLoadingShimmer className="mt-2.5 sm:mt-4" />
                        ) : (
                            <span className="mt-2.5 sm:mt-4 text-2xl text-blueMainText font-normal">
                                {currentCost} EUR
                            </span>
                        )}
                    </div>
                    <div className="flex">
                        {parseFloat(currentCost) > 0 && !currentCostLoading && (
                            <CreateButton
                                loading={payNowLoading}
                                onClick={() => {
                                    setIsConfirmPayNowModalVisible(true);
                                }}
                                title={t('pay.now')}
                            />
                        )}
                    </div>
                </div>
                <div className={`${cardClassName}`}>
                    <div className="flex w-full justify-between items-start">
                        <span className="font-normal text-base text-blueMainText">
                            {t('month.to.date.summary')} {transactionDateFilters}
                        </span>
                        <DateRangePickerInput
                            onChange={item => setTransactionDateRanges([item['transactionDateRanges']])}
                            ranges={transactionDateRanges}
                            onFinish={() => {
                                fetchAllTransactions();
                            }}
                        />
                    </div>
                    <div className="flex flex-col w-full mt-6">
                        {renderTransactions()}
                        {!!transactions.length && !transactionsLoading && (
                            <div className="flex flex-1 mt-3">
                                <div className="flex flex-1" style={{ flex: 2 }} />
                                <div className="flex  flex-1 justify-start">
                                    <span
                                        className="text-secondaryText text-center font-medium text-sm"
                                        style={{ minWidth: 50 }}
                                    >
                                        {t('total')}
                                    </span>
                                </div>
                                <div className="flex flex-1  justify-end">
                                    <span className="text-blueMainText text-center font-medium text-sm">
                                        {totalTransactionPrice} EUR
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
