import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import { CreateVoucherModal } from '../../../components/modals';
import { NoDataImagesType } from '../../../components/tables';
import { toast } from '../../../components/ui';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
import { VoucherListModel, VoucherListResponseModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/voucher/vouchersThunk';
import { formatDateAndTime } from '../../../utils';
import { AdminTable } from '../components';

export const AdminVouchersPage = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility();

    /**
     * Redux Selectors
     */
    const response: VoucherListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    /**
     * Local State
     */
    const [selectedItemForEdit, setItemForEdit] = useState<VoucherListModel | null>(null);

    const requestList = (query: any = {}) => {
        dispatch(actions.request({ ...query, isFromAdmin: true }));
    };

    useEffect(() => {
        requestList({ page: 0 });
    }, []);

    const onRefresh = () => {
        !loading && requestList();
    };

    const onClickEdit = (row: VoucherListModel) => {
        setItemForEdit(row);
        onToggleCreateModal(true);
    };
    const onClickCreate = () => {
        onToggleCreateModal(true);
    };

    const onSuccessCreated = isEditing => {
        toast.success(
            isEditing
                ? t('@voucher.success.edited', { defaultValue: 'Voucher code has been edited!' })
                : t('@voucher.success.created', { defaultValue: 'Voucher code has been created!' })
        );
        setItemForEdit(null);
        onToggleCreateModal(false);
        onRefresh();
    };

    return (
        <PageAnimationContainer>
            <div className="">
                {shouldRenderCreateModal && (
                    <CreateVoucherModal
                        isVisible={isCreateModalVisible}
                        isEditing={!!selectedItemForEdit}
                        itemForEdit={selectedItemForEdit}
                        onHide={() => {
                            onToggleCreateModal(false);
                            setItemForEdit(null);
                        }}
                        onSuccess={onSuccessCreated}
                    />
                )}
                <AdminTable
                    hideIsActiveColumn
                    modelEndpoint="voucher"
                    requestList={requestList}
                    loading={loading}
                    response={response}
                    initLoading={initLoading}
                    onClickEdit={onClickEdit}
                    columns={[
                        {
                            name: 'Code',
                            selector: row => row?.code ?? '',
                        },
                        {
                            name: 'Account - id',
                            selector: row => row?.accountName ?? '',
                            cell: (row: VoucherListModel) => (
                                <span className="flex text-blueMainText">
                                    {row.accountId ? `${row?.accountName || ''} - ${row.accountId}` : 'N/A'}
                                </span>
                            ),
                        },
                        {
                            name: t('expiration.date'),
                            selector: row => row?.expirationDate ?? '',
                            cell: (row: VoucherListModel) => {
                                return (
                                    <span className="w-full flex text-center text-blueMainText">
                                        {row.expirationDate
                                            ? formatDateAndTime(row?.expirationDate || '')
                                            : 'Unlimited'}
                                    </span>
                                );
                            },
                        },
                    ]}
                    emptyDataProps={{
                        imageType: NoDataImagesType.Voucher,
                        actionMessage: t('@create.new.voucher', { defaultValue: 'Create new voucher' }),
                        onClickAction: onClickCreate,
                        emptyText: t('@pages.no-vouchers', { defaultValue: 'No vouchers has been added yet' }),
                    }}
                    onPageChanged={page => {
                        requestList({ page });
                    }}
                    headerButtonProps={{
                        onClickCreate,
                        onRefresh,
                    }}
                />
            </div>
        </PageAnimationContainer>
    );
};
