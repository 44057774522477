import { Dispatch } from 'redux';
import { breakpoints } from '../../../../constants';
import { InitialStateType, SubcatalogListResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import {
    actionCreator,
    formatDateAndTime,
    getFormattedQuery,
    getFormattedResponseForPayload,
    getSearchParams,
    getWidth,
} from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/catalog/subcatalogsThunk/REQUEST',
    SUCCESS: '@app/catalog/subcatalogsThunk/SUCCESS',
    FAILURE: '@app/catalog/subcatalogsThunk/FAILURE',
    SET_INITIAL_LOADING: '@app/catalog/subcatalogsThunk/SET_INITIAL_LOADING',
    SET_QUERY: '@app/catalog/subcatalogsThunk/SET_QUERY',
};

/**
 * Initial state
 */
const initialState: InitialStateType<SubcatalogListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    initialLoading: false,
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
        query: {},
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.subcatalogs.isLoading,
    getResponse: (state: RootState) => state.app.subcatalogs.response,
    getErrorMessage: (state: RootState) => state.app.subcatalogs.errorMessage,
    getInitialLoading: (state: RootState) => state.app.subcatalogs.initialLoading,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_INITIAL_LOADING, SET_QUERY } = actionTypes;

const Reducer = (state: InitialStateType<SubcatalogListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case SET_QUERY:
            return {
                ...state,
                response: {
                    ...state.response,
                    query: payload,
                },
            };
        case SET_INITIAL_LOADING:
            return {
                ...state,
                initialLoading: payload,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
                initialLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                initialLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query: any) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const isMobile = getWidth() <= breakpoints.sm;
                const previousQuery = getState().app.subcatalogs.response.query;

                if (query?.page === undefined) {
                    query.page = previousQuery?.page || 0;
                }

                if (isMobile && query.page === 0) dispatch(actionCreator(SET_INITIAL_LOADING, true));
                dispatch(actionCreator(REQUEST));

                const previousModels = isMobile && query.page > 0 ? getState().app.subcatalogs.response.models : [];

                let searchParams = getSearchParams(previousQuery, query);

                const response: SubcatalogListResponseModel = await axios.get(
                    `/catalog/list?${getFormattedQuery({ ...searchParams, catalog : false })}`
                );
                let _res = {
                    ...response,
                    models: response.models.map(_model => ({
                        ..._model,
                        subCatalogs: _model.subCatalogs.map(_subCatalog => ({
                            ..._subCatalog,
                            lastModified: formatDateAndTime(_subCatalog.lastModified),
                        })),
                    })),
                };
                let res = getFormattedResponseForPayload(_res, previousModels, query, searchParams);

                dispatch(actionCreator(SUCCESS, res));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
