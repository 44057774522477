import { FC, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { WorkflowModelListModel, WorkflowModelWrapper } from '../../../../models';
import { AutoHeightAnimation } from '../../../animation/AutoHeightAnimation';
import { TabChrome } from '../../../tabs';
import {
    ModelConfusionMatrix as ConfusionMatrix,
    ModelEvaluationMetrics as EvaluationMetrics,
    ModelFeatureImportance as FeatureImportance,
} from './model-components';

export interface ModelRowExpandComponent {
    data?: WorkflowModelListModel;
}

enum tabs {
    evaluationMetrics = 'EvaluationMetrics',
    featureImportance = 'FeatureImportance',
    confusionMatrix = 'ConfusionMatrix',
}

const getInitialTab = (wrapper: WorkflowModelWrapper) => {
    const { confusionMatrix, evaluationMetrics, featureImportance } = tabs;

    if (!wrapper) {
        return '';
    }

    if (wrapper.evaluationMetrics) return evaluationMetrics;
    if (wrapper.featureImportance) return featureImportance;
    if (wrapper.confusionMatrix?.length) return confusionMatrix;

    return '';
};

export const ModelRowExpandComponent: FC<ModelRowExpandComponent> = ({ data }) => {
    const wrapper = data?.wrapper;
    const [activeTab, setActiveTab] = useState<tabs | ''>(wrapper ? getInitialTab(wrapper) : '');

    const renderTab = () => {
        const { confusionMatrix, evaluationMetrics, featureImportance } = tabs;

        if (!data) {
            return;
        }

        switch (activeTab) {
            case evaluationMetrics:
                return <EvaluationMetrics model={data} />;
            case featureImportance:
                return <FeatureImportance model={data} />;
            case confusionMatrix:
                return <ConfusionMatrix model={data} />;
            default:
                return null;
        }
    };

    return (
        <AutoHeightAnimation>
            <div className="flex flex-col justify-center w-full items-center">
                <div className="w-full h-auto flex pt-2 flex-row bg-mainBackground px-4">
                    {wrapper?.evaluationMetrics && (
                        <TabChrome
                            style={{ boxShadow: 'none' }}
                            name={tabs.evaluationMetrics}
                            onClick={name => setActiveTab(name)}
                            isActive={tabs.evaluationMetrics === activeTab}
                            title={tabs.evaluationMetrics}
                        />
                    )}
                    {data?.wrapper?.featureImportance && (
                        <TabChrome
                            style={{ boxShadow: 'none' }}
                            name={tabs.featureImportance}
                            onClick={name => setActiveTab(name)}
                            isActive={tabs.featureImportance === activeTab}
                            title={tabs.featureImportance}
                        />
                    )}
                    {!!data?.wrapper?.confusionMatrix?.length && (
                        <TabChrome
                            style={{ boxShadow: 'none' }}
                            name={tabs.confusionMatrix}
                            onClick={name => setActiveTab(name)}
                            isActive={tabs.confusionMatrix === activeTab}
                            title={tabs.confusionMatrix}
                        />
                    )}
                </div>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        timeout={100}
                        key={activeTab}
                        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                        classNames="fade"
                    >
                        {!!data && renderTab()}
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </AutoHeightAnimation>
    );
};

export default ModelRowExpandComponent;
