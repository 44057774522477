import { ApexOptions } from 'apexcharts';
import deepmerge from 'deepmerge';
import { ReactNode, useMemo } from 'react';
import Chart, { Props } from 'react-apexcharts';
import { Loader } from '../ui';

const _options: ApexOptions = {
    chart: {
        height: 400,
        type: 'pie',
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
    },
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    theme: {
        monochrome: {
            enabled: true,
            color: '#2C61B9',
        },
    },
    title: {
        text: 'Monochrome Pie',
    },
    tooltip: {
        y: {
            formatter: val => val.toLocaleString('en-US'),
        },
    },
    legend: {
        show: true,
    },
};

interface IApexPieChartProps extends Props {
    title: string;
    loading?: boolean;
    noDataMessage: string | ReactNode;
    data?: any[][];
}

export const ApexPieChart = ({ data, height, loading, noDataMessage, ...props }: IApexPieChartProps) => {
    const options: ApexOptions = useMemo(() => {
        let _opts = deepmerge(_options, props.options || {});
        _opts.labels = data?.map(x => x[0] ?? '') ?? [];
        _opts.title.text = props.title;
        _opts.series = [
            {
                name: props.title,
                data: data?.map(x => parseInt(x[1] ?? '0') ?? []) ?? [],
                color: '#3A81F6',
            },
        ];
        return _opts;
    }, [props.options, data]);

    const seriesData = useMemo(() => data?.map(x => parseInt(x[1] ?? '0') ?? 0) ?? [], [data]);

    const { initialLoading, refreshLoading } = useMemo(
        () => ({
            initialLoading: loading && !data?.length,
            refreshLoading: loading && !!data?.length,
        }),
        [data?.length, loading]
    );

    return (
        <div className="w-full  h-100 p-6 relative">
            {refreshLoading && (
                <div className="absolute top-4 right-4">
                    <Loader size={34} />
                </div>
            )}
            {initialLoading ? (
                <div className="h-100 w-full flex justify-center items-center">
                    <Loader size={34} />
                </div>
            ) : !data?.length ? (
                typeof noDataMessage === 'string' ? (
                    <div className="w-full h-full flex flex-col">
                        <h3 className="text-greyBlack300 text-sm font-bold ">{props.title}</h3>
                        <div className="text-secondaryText text-base flex flex-1 justify-center items-center">
                            {noDataMessage}
                        </div>
                    </div>
                ) : (
                    noDataMessage
                )
            ) : (
                <Chart options={options} height="100%" width="100%" type="pie" series={seriesData} />
            )}
        </div>
    );
};
