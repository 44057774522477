import { useContext, useEffect, useState } from 'react';
import { AnimationComponent } from '../../../../components/animation';
import { DetailsLoader } from '../../../../components/details-entity';
import { customEndpointDetailsContext } from '../../../../contexts';

export const CustomEndpointLogsTab = () => {
    const { details } = useContext(customEndpointDetailsContext);
    const presignedUrl = details?.endpointDetails?.logPresignedUrl;

    const [content, setContent] = useState<string>('');
    const [contentLoading, setContentLoading] = useState(false);

    useEffect(() => {
        presignedUrl && fetchLogsContent(presignedUrl);
    }, [presignedUrl]);

    const fetchLogsContent = async (url: string) => {
        try {
            setContentLoading(true);
            const fileContentResponse = await fetch(url);
            const fileContent = await fileContentResponse.text();
            setContent(fileContent ?? '');
        } catch (err) {
        } finally {
            setContentLoading(false);
        }
    };

    return (
        <div className="flex flex-col w-full">
            {contentLoading && <DetailsLoader />}
            <AnimationComponent
                show={!contentLoading}
                type="fade-bottom"
                transform="transform-gpu"
                delay={50}
                duration={400}
            >
                <code className="w-full bg-blueStroke text-mainError whitespace-pre-wrap p-6 rounded text-sm font-medium">
                    {content}
                </code>
            </AnimationComponent>
        </div>
    );
};
