import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PropensityEnginePage } from '.';
import { PropensityEngineTypes } from '../../../constants';
import { useAccountId } from '../../../hooks';
import { actions } from '../../../redux/thunk/app/engines/propensity/propensityEnginesThunk';

export const ChurnPropensityEnginePage = () => {
    const accountId = useAccountId();
    const dispatch = useDispatch();

    useEffect(() => {
        const type = PropensityEngineTypes.churn;
        dispatch(actions.request({ accountId, type, page: 0, searchQuery: '' }));
    }, [accountId]);

    return <PropensityEnginePage propensityEngineType={PropensityEngineTypes.churn} />;
};
