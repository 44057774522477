import { PropensityEngineTypes } from './propensity-constants';
import { RecommendationEngineTypes } from './recommendation-constants';

export const AIHUB_BASE_PATH = '/';

export const DASHBOARD_PATH = '/dashboard';

export const ACCOUNT_NOT_ACTIVE_PATH = '/not-active';

export const DATA_SOURCES_PATH = '/data/data-sources';
export const DATA_SOURCE_DETAILS_PATH = '/data/data-sources/details/:id';
export const STORAGE_PATH = '/data/storage';
export const STORAGE_DYNAMIC_PATH = '/data/storage/:path+';

export const CATALOGS_PATH = '/data/catalogs';
export const CATALOG_SCHEMAS_PATH = '/catalogs/schemas';
export const CATALOG_DETAILS_PATH = '/data/catalogs/details/:id'
export const FILTERS_PATH = '/data/filters';
export const CREATE_FILTER_PATH = '/data/filters/create-new-filter';
export const EDIT_FILTER_PATH = '/data/filters/edit-filter/:id';

export const SCHEMAS_PATH = '/data/schemas';
export const ARTIFACTS_PATH = '/data/artifacts';
export const ARTIFACT_DETAILS_PATH = '/data/artifacts/details/:id';

export const INFORMATION_PATH = '/workflow/information';
export const INFORMATION_BASE_PATH = '/workflow';

export const NOTIFICATIONS_PATH = '/notifications';

/**
 * Dataset
 */
export const DATASETS_PATH = '/workflow/datasets';
export const CREATE_DATASET_PATH = '/workflow/datasets/create-new-dataset';
export const EDIT_DATASET_PATH = '/workflow/datasets/edit-dataset/:id';
export const DATASET_DETAILS_PATH = '/workflow/datasets/details/:id';

/**
 * Feature Engineering
 */
export const FEATURE_ENGINEERING_PATH = '/workflow/feature-engineering';
export const EDIT_FEATURE_ENGINEERING_PATH = '/workflow/feature-engineering/edit-flow/:id';
export const CREATE_FEATURE_ENGINEERING_PATH = '/workflow/feature-engineering/create-new-flow';

/**
 * Model
 */
export const MODELS_PATH = '/workflow/models';
export const EDIT_MODEL_PATH = '/workflow/models/edit-model/:id';
export const CREATE_MODEL_PATH = '/workflow/models/create-new-model';

export const DEPLOYMENTS_PATH = '/workflow/deployments';

/**
 * Recommendation Engine
 */
export const BASE_RECOMMENDATION_PATH = '/AI-engine/recommendation-engine';
export const RECOMMENDATION_ENGINE_PATH = `${BASE_RECOMMENDATION_PATH}/:type`;
export const ARTICLE_RECOMMENDATION_ENGINE_PATH = `${BASE_RECOMMENDATION_PATH}/${RecommendationEngineTypes.article}`;
export const VIDEO_RECOMMENDATION_ENGINE_PATH = `${BASE_RECOMMENDATION_PATH}/${RecommendationEngineTypes.video}`;
export const PRODUCT_RECOMMENDATION_ENGINE_PATH = `${BASE_RECOMMENDATION_PATH}/${RecommendationEngineTypes.product}`;
export const CLASSIFIED_AD_RECOMMENDATION_ENGINE_PATH = `${BASE_RECOMMENDATION_PATH}/${RecommendationEngineTypes.classifiedAd}`;
export const CREATE_RECOMMENDATION_ENGINE_PATH = `${BASE_RECOMMENDATION_PATH}/:type/create-new-recommendation`;
export const EDIT_RECOMMENDATION_ENGINE_PATH = `${BASE_RECOMMENDATION_PATH}/:type/edit-recommendation/:id`;
export const RECOMMENDATION_ENGINE_DETAILS_PATH = `${BASE_RECOMMENDATION_PATH}/:type/details/:id`;

/**
 * Propensity Engine
 */
export const BASE_PROPENSITY_PATH = '/AI-engine/propensity-engine';
export const PROPENSITY_ENGINE_PATH = `${BASE_PROPENSITY_PATH}/:type`;
export const CHURN_PROPENSITY_ENGINE_PATH = `${BASE_PROPENSITY_PATH}/${PropensityEngineTypes.churn}`;
export const PURCHASE_PROPENSITY_ENGINE_PATH = `${BASE_PROPENSITY_PATH}/${PropensityEngineTypes.purchase}`;
export const SUBSCRIBE_PROPENSITY_ENGINE_PATH = `${BASE_PROPENSITY_PATH}/${PropensityEngineTypes.subscribe}`;
export const CREATE_PROPENSITY_ENGINE_PATH = `${BASE_PROPENSITY_PATH}/:type/create-new-propensity`;

/**
 * Lookalike Engine
 */
export const LOOKALIKE_ENGINE_PATH = '/AI-engine/lookalike-engine';
export const CREATE_LOOKALIKE_ENGINE_PATH = `${LOOKALIKE_ENGINE_PATH}/create-new-lookalike`;
export const EDIT_LOOKALIKE_ENGINE_PATH = `${LOOKALIKE_ENGINE_PATH}/edit-lookalike/:id`;
export const LOOKALIKE_ENGINE_DETAILS_PAGE = `${LOOKALIKE_ENGINE_PATH}/details/:id`;

/**
 * Propensity Engine
 */
export const CHURN_ENGINE_PATH = '/AI-engine/propensity-engine/likelihood-to-churn';
export const CREATE_CHURN_ENGINE_PATH = '/AI-engine/propensity-engine/likelihood-to-churn/create-new';
export const PROPENSITY_ENGINE_SUBSCRIBE_PATH = '/AI-engine/propensity-engine/likelihood-to-subscribe';

export const ACCOUNT_SETTINGS_PATH = '/settings';
export const USER_SETTINGS_PATH = '/settings/user-management';

/**
 * Lab
 */
export const NOTEBOOK_SERVERS_PATH = '/lab/notebook-servers';
export const CUSTOM_ENDPOINTS_PATH = '/lab/endpoints';
export const CUSTOM_ENDPOINTS_DETAILS = `${CUSTOM_ENDPOINTS_PATH}/details/:id`;

/**
 * Tasks
 */
export const TASKS_PATH = '/lab/tasks';
export const CREATE_TASK_PATH = '/lab/tasks/create-new-task';
export const EDIT_TASK_PATH = '/lab/tasks/edit-task/:id';
export const TASK_DETAILS_PATH = '/lab/tasks/details/:id';

/**
 * Pipelines
 */
export const PIPELINES_PATH = '/lab/pipelines';
export const CREATE_PIPELINE_PATH = `${PIPELINES_PATH}/create-new-pipeline`;
export const EDIT_PIPELINE_PATH = `${PIPELINES_PATH}/edit-pipeline/:id`;
export const PIPELINE_DETAILS_PATH = `${PIPELINES_PATH}/details/:id`;

/**
 * PipelineJobs
 */
export const CREATE_PIPELINE_JOB_PATH = `${PIPELINES_PATH}/:pipelineId/create-new-pipeline-job`;
export const EDIT_PIPELINE_JOB_PATH = `${PIPELINES_PATH}/:pipelineId/edit-pipeline-job/:id`;
export const PIPELINE_JOB_DETAILS_PATH = `${PIPELINES_PATH}/:pipelineId/details/:id`;
export const PIPELINE_JOB_RUN_DETAILS_PATH = `${PIPELINES_PATH}/run-details/:id`;

export const BILLING_PATH = '/billing';

export const NOT_FOUND_PATH = '/not-found';

export const AUTH_CALLBACK = '/callback';
export const LOGOUT_PATH = '/callback-logout';
export const WELCOME_PATH = '/welcome';

/**
 * Admin ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const BASE_ADMIN_PATH = '/admin';
export const TEST_ADMIN_PATH = '/admin/test';

export const ADMIN_LOCALIZATION_PATH = '/admin/localization';
export const ADMIN_ACCOUNTS_PATH = '/admin/accounts';

//Instance types
export const ADMIN_MODELS_INSTANCE_TYPES = '/instance-types/models-instance-types';
export const ADMIN_FLOWS_INSTANCE_TYPES = '/instance-types/flow-instance-types';
export const ADMIN_DEPLOYMENTS_INSTANCE_TYPES = '/instance-types/deployments-instance-types';

// DATA SOURCE TYPE
export const ADMIN_DATA_SOURCE_TYPES_PATH = '/admin/data-source-types';
export const ADMIN_CREATE_DATA_SOURCE_TYPES_PATH = '/admin/data-source-types/create-data-source';
export const ADMIN_EDIT_DATA_SOURCE_TYPES_PATH = '/admin/data-source-types/edit-data-source-type/:id';

//FLOW ACTIONS
export const ADMIN_FLOW_ACTIONS_PATH = '/admin/flow-actions';
export const ADMIN_CREATE_FLOW_ACTION_PATH = '/admin/flow-actions/create-flow-action';
export const ADMIN_EDIT_FLOW_ACTION_PATH = '/admin/flow-actions/edit-flow-action/:id';

//ALGORITHM GROUP
export const ADMIN_ALGORITHM_GROUP_PATH = '/admin/algorithm-groups';

//Integration Types
export const ADMIN_INTEGRATION_TYPES_PATH = '/admin/integration-types';
export const ADMIN_CREATE_INTEGRATION_TYPES_PATH = '/admin/integration-types/create-new-integration-type';
export const ADMIN_EDIT_INTEGRATION_TYPES_PATH = '/admin/integration-types/edit-integration-type/:id';

//ALGORITHMS
export const ADMIN_ALGORITHMS_PATH = '/admin/algorithms';
export const ADMIN_CREATE_ALGORITHM_PATH = '/admin/algorithms/create-algorithm';
export const ADMIN_EDIT_ALGORITHM_PATH = '/admin/algorithms/edit-algorithm/:id';

export const ADMIN_CONFIGURATION_ITEMS_PATH = '/admin/configuration-items';

//SCHEMA TYPES
export const ADMIN_SCHEMA_TYPES_PATH = '/admin/schema-types';
export const ADMIN_CREATE_SCHEMA_TYPE_PATH = '/admin/schema-types/create-schema-type';
export const ADMIN_EDIT_SCHEMA_TYPE_PATH = '/admin/schema-types/edit-schema-type/:id';

export const ADMIN_VOUCHERS_PATH = '/admin/vouchers';

export const ADMIN_ARTIFACT_TYPES_PATH = '/admin/artifact-types';
export const ADMIN_INSTANCE_TYPE_PATH = '/admin/instance-types';
export const ADMIN_RUN_SCHEDULES_PATH = '/admin/run-schedules';
