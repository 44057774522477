import { nanoid } from 'nanoid';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { toast } from '../../../../../components/ui';
import { TASKS_PATH } from '../../../../../constants';
import { useAccountId } from '../../../../../hooks';
import {
    Nullable,
    PartialRecord,
    SelectOptionType,
    TaskDetailsResponseModel,
    TaskPostModel,
} from '../../../../../models';
import axios from '../../../../../services/axios';
import AXIOS from 'axios'
import { deleteErrorKey, queryParams } from '../../../../../utils';
import { onValidateWithYup, taskFormSchema } from '../../../../../validations';
import { taskInputTypes, taskInputTypesOptions } from '../../constants';

type TFormValues = {
    title: string;
    alias: string;
    scriptPath: Nullable<SelectOptionType>;
    requirementsPath: Nullable<SelectOptionType>;
    typeId: Nullable<SelectOptionType>;
    isGlobal: boolean;
};

type TErrors = PartialRecord<keyof TFormValues, string>;

export type TInput = {
    _id: string;
    id: number;
    name: string;
    inputType: Nullable<SelectOptionType>;
    required: boolean;
    dataType?: Nullable<SelectOptionType>;
    options: TInputOption[];
};

export type TOutput = {
    _id: string;
    id: number;
    name: string;
    dataType: Nullable<SelectOptionType>;
};

export type TInputOption = {
    _id: string;
    id: number;
    label: string;
    value: string;
};

export const useCreateTaskHook = () => {
    const { t } = useTranslation();
    const params: { id?: string } = useParams();
    const entityId = parseInt(params?.id ?? '0');
    const history = useHistory();
    const accountId = useAccountId();

    const [detailsLoading, setDetailsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<TErrors>({});
    const [inputs, setInputs] = useState<TInput[]>([]);
    const [outputs, setOutputs] = useState<TOutput[]>([]);
    const [formValues, setFormValues] = useState<TFormValues>({
        alias: '',
        requirementsPath: null,
        typeId: null,
        scriptPath: null,
        title: '',
        isGlobal: false,
    });

    const { REACT_APP_PYTHON_VALIDATOR_URL } = process.env;
    const Axios = AXIOS.create({
        baseURL: REACT_APP_PYTHON_VALIDATOR_URL
    });
    AXIOS.defaults.headers.post['Content-Type'] = 'application/json';


    useEffect(() => {
        if (entityId) fetchDetails();
    }, [entityId, accountId]);

    const onValidateScript = async (_value: any) => {
            setLoading(true);
            await Axios.post('/validate_task',{
                account_id: accountId,
                script_path: _value.value
            }).then(res => {
                if(res.data) {
                    mapInputsOutputs(res.data);
                }
            }).catch(err => {
                console.error({err})
                setErrors({scriptPath: typeof err.response.data.detail === 'object' ? `${Object.values(err.response.data.detail)[0]}` : err.response.data.detail || 'An error has occurred.'});
                setInputs([]);
                setOutputs([]);
            }).finally(() => {
                setLoading(false);
            })
    };


    const onChangeFormValues = useCallback(
        (key: keyof TFormValues, value: any) => {
            if(key === 'scriptPath'){
                onValidateScript(value);
            }
            deleteErrorKey(key, setErrors);
            setFormValues(prev => ({
                ...prev,
                [key]: value,
            }));
        },
        [setFormValues, setErrors]
    );

    const isFormValid = async (_formValues: TaskPostModel) => {
        const formSchema = taskFormSchema(t);
        const { errors: formErrors, isValid: isFormValid } = await onValidateWithYup(formSchema, _formValues);
        setErrors(formErrors);
        if (!isFormValid) return false;

        return true;
    };

    const mapInputsOutputs = (model) => {
        const _inputs: TInput[] = model.inputs.map(_input => ({
            _id: nanoid(),
            id: _input.id,
            inputType:
                taskInputTypesOptions.find(_taskInputType => _taskInputType.value === _input.inputType) ?? null,
            name: _input.name,
            required: _input.required,
            dataType: { label: _input.dataTypeId, value: _input.dataTypeId },
            options: _input.options.map(_option => ({
                _id: nanoid(),
                id: _option.id ?? 0,
                label: _option.label,
                value: _option.value,
            })),
        }));
        const _outputs: TOutput[] = model.outputs.map(x => ({
            _id: nanoid(),
            name: x.name,
            dataType: { label: x.dataType, value: x.dataType },
            id: x.id,
        }));
        setInputs(_inputs);
        setOutputs(_outputs);
    }

    const fetchDetails = async () => {
        try {
            setDetailsLoading(true);
            const { model }: TaskDetailsResponseModel = await axios.get(
                `/pipeline/task?id=${entityId}&accountId=${accountId}`
            );

            const _formValues: TFormValues = {
                alias: model.alias,
                requirementsPath: model.requirementsPath
                    ? { label: model.requirementsPath, value: model.requirementsPath }
                    : null,
                scriptPath: { label: model.scriptPath, value: model.scriptPath },
                title: model.title,
                isGlobal: !model.accountId,
                typeId: { label: model.typeName, value: model.typeId?.toString() },
            };;

            mapInputsOutputs(model);
            setFormValues(_formValues);
        } catch (err) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const payload: TaskPostModel = {
                accountId: formValues.isGlobal ? 0 : accountId,
                id: entityId,
                title: formValues.title,
                alias: formValues.alias,
                scriptPath: formValues.scriptPath?.value ?? '',
                requirementsPath: formValues.requirementsPath?.value,
                typeId: parseInt(formValues.typeId?.value ?? '0'),
            };

            const inputsPayload: TaskPostModel['inputs'] = inputs.map((_input, index) => ({
                id: _input.id,
                inputType: _input.inputType?.value,
                name: _input.name,
                required: _input.required,
                orderNumber: index,
                dataTypeId: _input.inputType?.value === 'switch' ? 'bool' : _input.dataType?.value,
                options: [taskInputTypes.dropdown].includes(_input.inputType?.value ?? '')
                    ? _input.options.map(x => ({
                          label: x.label,
                          value: x.value,
                          id: x.id,
                      }))
                    : [],
            }));
            const outputsPayload: TaskPostModel['outputs'] = outputs.map(x => ({
                dataType: x.dataType?.value ?? '',
                id: x.id,
                name: x.name,
            }));

            const isValid = await isFormValid(payload);
            if (!isValid) {
                toast.error(t('@form.errors', { defaultValue: 'Form validation errors!' }));
                return false;
            }

            payload.inputs = inputsPayload;
            payload.outputs = outputsPayload;

            setLoading(true);

            await axios.post('/pipeline/task', payload);
            history.push(TASKS_PATH + queryParams.formatForNavigation());
            toast.success(t(`@success.${entityId ? 'edit' : 'create'}.task`));
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    return {
        onSubmit,
        onChangeFormValues,
        setInputs,
        setOutputs,
        detailsLoading,
        inputs,
        outputs,
        formValues,
        loading,
        errors,
    };
};
