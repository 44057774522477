/**
 * Libraries
 */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Transition } from 'react-transition-group';
import Header from '../components/header';
import { MainSidebar } from '../components/sidebars';
import { ScrollToTopButton } from '../components/ui';
import { UploadingArtifactsDialog } from '../components/UploadingArtifactsDialog/UploadingArtifactsDialog';
import { UploadingObjectsDialog } from '../components/UploadingObjectsDialog';
import {
    ACCOUNT_NOT_ACTIVE_PATH,
    ACCOUNT_SETTINGS_PATH,
    BILLING_PATH,
    NOT_FOUND_PATH,
    WELCOME_PATH,
} from '../constants';
import { scrollBarContext } from '../contexts';
import { useAccountId } from '../hooks';
import { AccountDetailsResponseModel, UserDetailsResponseModel } from '../models/entity-models';
import {
    actions as accountDetailsAction,
    selectors as accountDetailsSelectors,
} from '../redux/thunk/app/account/accountDetailsThunk';
import { selectors as uploadArtifactsSelectors } from '../redux/thunk/app/artifact/uploadArtifactThunk';
import { actions as notificationsActions } from '../redux/thunk/app/notification/notificationsThunk';
import { selectors as uploadObjectsSelectors } from '../redux/thunk/app/storage/uploadObjectsThunk';
import { selectors as userSelectors } from '../redux/thunk/app/user/userThunk';
import { actions as biskoPropertiesActions } from '../redux/thunk/bisko/biskoPropertiesThunk';
import { actions as biskoSegmentsActions } from '../redux/thunk/bisko/biskoSegmentsThunk';
import { headerAnimationStyles } from '../styles/animationStyles';
import { queryParams } from '../utils';

export const MainLayout: React.FC = props => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const accountId = useAccountId();

    const [containerElement, setContainerElement] = useState<HTMLElement | null>(null);

    /**
     * Selectors
     */
    const accountDetailsError = useSelector(accountDetailsSelectors.getErrorMessage);
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const accountLoading: boolean = useSelector(accountDetailsSelectors.getIsLoading);
    const userDetailsResponse: UserDetailsResponseModel = useSelector(userSelectors.getResponse);
    const isArtifactsDialogHidden = useSelector(uploadArtifactsSelectors.getIsDialogHidden);
    const isObjectsDialogHidden = useSelector(uploadObjectsSelectors.getIsDialogHidden);

    /**
     * Local State
     */
    const [mounted, setMounted] = useState(false);
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);

    let scrollTimeoutId: any = null;
    const onScrollDetect = (container: HTMLElement) => {
        if (scrollTimeoutId) {
            clearTimeout(scrollTimeoutId);
        }
        scrollTimeoutId = setTimeout(() => {
            if (container.scrollTop > 400 && container.scrollHeight > container.clientHeight) {
                !showScrollTopButton && setShowScrollTopButton(true);
            } else {
                showScrollTopButton && setShowScrollTopButton(false);
            }
        }, 400);
    };

    useEffect(() => {
        initialRequest();
    }, []);

    useEffect(() => {
        const { pathname } = window.location;
        if (!accountDetails.isActive && accountDetails.id && !accountLoading) {
            if (
                pathname.includes(ACCOUNT_NOT_ACTIVE_PATH) ||
                pathname.includes(NOT_FOUND_PATH) ||
                pathname.includes(ACCOUNT_SETTINGS_PATH) ||
                pathname.includes(BILLING_PATH)
            ) {
                return;
            }
            history.push(ACCOUNT_NOT_ACTIVE_PATH + queryParams.formatForNavigation());
        }
    }, [accountDetails, accountLoading, location]);

    useEffect(() => {
        if (accountDetailsError) {
            const id = userDetailsResponse.model.defaultAccountId;
            if (id && accountId !== id) {
                history.replace(location.pathname + queryParams.setAccountId(id));
            }
        }
    }, [accountDetailsError]);

    useEffect(() => {
        if (accountId && mounted) {
            dispatch(accountDetailsAction.request(accountId));
            dispatch(biskoPropertiesActions.request());
            dispatch(biskoSegmentsActions.request());
        }
    }, [accountId, mounted]);

    useEffect(() => {
        containerElement && containerElement.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location, containerElement]);

    const initialRequest = async () => {
        try {
            const defaultAccountId =
                userDetailsResponse.model?.defaultAccountId || userDetailsResponse?.model?.defaultAccount?.id;

            if (defaultAccountId) {
                !accountId && history.replace(location.pathname + queryParams.setAccountId(defaultAccountId));
            } else history.replace(WELCOME_PATH);

            dispatch(notificationsActions.request({ userId: userDetailsResponse.model.id, accountId }));
        } catch (err: any) {
        } finally {
            setMounted(true);
        }
    };

    return (
        <div
            className="bg-gray-100 h-screen flex flex-col overflow-x-hidden overflow-y-hidden"
            style={{ height: '100vh !important' }}
        >
            <Transition in={true} unmountOnExit={false} timeout={300}>
                {state => <Header style={{ ...headerAnimationStyles[state] }} />}
            </Transition>
            <div className="w-full flex-row flex flex-1 overflow-y-hidden">
                <MainSidebar />
                <div
                    ref={_ref => setContainerElement(_ref)}
                    onScroll={e => onScrollDetect(e.currentTarget)}
                    className="w-full flex-1 flex flex-col bg-mainBackground overflow-x-hidden p-2 pt-3 sm:p-3 md:p-4 lg:p-6"
                    style={{
                        scrollbarGutter: 'stable',
                    }}
                >
                    <scrollBarContext.Provider value={{ containerElement }}>{props.children}</scrollBarContext.Provider>
                    {!isArtifactsDialogHidden && <UploadingArtifactsDialog />}
                    {!isObjectsDialogHidden && <UploadingObjectsDialog />}
                    <ScrollToTopButton
                        isVisible={showScrollTopButton}
                        containerElement={containerElement}
                        onScrollToTop={() => {
                            setShowScrollTopButton(false);
                            containerElement && containerElement.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
