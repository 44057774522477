import { Dispatch } from 'redux';
import { NotificationEntityTypes } from '../components/header/notifications/NotificationItem';
import { toast } from '../components/ui';
import i18n from '../config/i18n';
import {
    ACCOUNT_SETTINGS_PATH,
    ARTIFACTS_PATH,
    BASE_PROPENSITY_PATH,
    BASE_RECOMMENDATION_PATH,
    BILLING_PATH,
    CATALOGS_PATH,
    CUSTOM_ENDPOINTS_PATH,
    DATASETS_PATH,
    DATA_SOURCES_PATH,
    DEPLOYMENTS_PATH,
    FEATURE_ENGINEERING_PATH,
    FILTERS_PATH,
    LOOKALIKE_ENGINE_PATH,
    MODELS_PATH,
    NOTEBOOK_SERVERS_PATH,
    PIPELINES_PATH,
    TASKS_PATH,
} from '../constants';
import {
    DataSourceReaderDetailsResponseModel,
    NotificationEntityModel,
    PipelineJobDetailsResponseModel,
} from '../models';
import { actions as accountDetailsActions } from '../redux/thunk/app/account/accountDetailsThunk';
import { actions as accountInvoiceActions } from '../redux/thunk/app/account/accountInvoicesThunk';
import { actions as notebookServersActions } from '../redux/thunk/app/notebook/notebookServersThunk';
import { actions as artifactActions } from '../redux/thunk/app/artifact/artifactsThunk';
import { actions as catalogActions } from '../redux/thunk/app/catalog/catalogsThunk';
import { actions as customEndpointActions } from '../redux/thunk/app/custom-endpoint/customEndpointsThunk';
import { actions as dataSourceActions } from '../redux/thunk/app/dataSource/dataSourcesThunk';
import { actions as dataSourceReadersActions } from '../redux/thunk/app/dataSource/dataSourceReadersThunk';
import { actions as lookalikeEngineRunsActions } from '../redux/thunk/app/engines/lookalike/lookalikeEngineRunsThunk';
import { actions as lookalikeActions } from '../redux/thunk/app/engines/lookalike/lookalikeEnginesThunk';
import { actions as propensityActions } from '../redux/thunk/app/engines/propensity/propensityEnginesThunk';
import { actions as pipelineActions } from '../redux/thunk/app/pipeline/pipelinesThunk';
import { actions as pipelineJobActions } from '../redux/thunk/app/pipeline-job/pipelineJobsThunk';
import { actions as recommendationEngineRunsActions } from '../redux/thunk/app/engines/recommendation/recommendationEngineRunsThunk';
import { actions as recommendationActions } from '../redux/thunk/app/engines/recommendation/recommendationEnginesThunk';
import { actions as filterActions } from '../redux/thunk/app/filter/filtersThunk';
import { actions as datasetActions } from '../redux/thunk/app/dataset/datasetsThunk';
import { actions as deploymentActions } from '../redux/thunk/app/deployment/deploymentsThunk';
import { actions as flowActions } from '../redux/thunk/app/flow/flowsThunk';
import { actions as modelActions } from '../redux/thunk/app/model/modelsThunk';
import { actions as taskActions } from '../redux/thunk/app/task/tasksThunk';
import axios from '../services/axios';
import { getAccountIdFromQuery } from '../utils';

const {
    Account,
    Artifact,
    Catalog,
    CustomEndpoint,
    Dataset,
    Datasource,
    Deployment,
    Filter,
    Flow,
    LookalikeEngine,
    Model,
    PropensityEngine,
    Pipeline,
    PipelineJob,
    RecommendationEngine,
    Notebook,
    Invoice,
    DataSourceReader,
    PipelineTask,
} = NotificationEntityTypes;

const entityMapping: Record<
    string,
    {
        path: string;
        actions: any;
        state?: any;
        getPath?: (entityName: string, entity?: NotificationEntityModel) => string;
        getPathAsync?: (entity: NotificationEntityModel) => Promise<string>;
        customAction?: (dispatch: any, entity?: NotificationEntityModel, isFromOnClick?: boolean) => void;
    }
> = {
    [Account]: {
        path: ACCOUNT_SETTINGS_PATH,
        actions: accountDetailsActions,
    },
    [Artifact]: {
        path: ARTIFACTS_PATH,
        actions: artifactActions,
    },
    [Catalog]: {
        path: CATALOGS_PATH,
        actions: catalogActions,
    },
    [CustomEndpoint]: {
        path: CUSTOM_ENDPOINTS_PATH,
        actions: customEndpointActions,
    },
    [Dataset]: {
        path: DATASETS_PATH,
        actions: datasetActions,
    },
    [Datasource]: {
        path: DATA_SOURCES_PATH,
        actions: dataSourceActions,
    },
    [DataSourceReader]: {
        path: '',
        actions: dataSourceReadersActions,
        getPathAsync: async entity => {
            try {
                const query = new URLSearchParams({
                    id: entity?.entityId?.toString(),
                    accountId: getAccountIdFromQuery(),
                });

                const response: DataSourceReaderDetailsResponseModel = await axios.get(`/dataSourceReader?${query}`);

                let parentId = response.model.dataSourceId;
                return parentId ? `${DATA_SOURCES_PATH}/details/${parentId}` : '';
            } catch (err: any) {
                if (err?.response?.status === 404) {
                    toast.dismiss();
                    toast.error(
                        i18n.t('@dataSourceReader.not.found', { defaultValue: 'Data Source Reader not found!' })
                    );
                }
                return '';
            }
        },
    },
    [Deployment]: {
        path: DEPLOYMENTS_PATH,
        actions: deploymentActions,
    },
    [Filter]: {
        path: FILTERS_PATH,
        actions: filterActions,
    },
    [Flow]: {
        path: FEATURE_ENGINEERING_PATH,
        actions: flowActions,
    },
    [LookalikeEngine]: {
        path: LOOKALIKE_ENGINE_PATH,
        actions: lookalikeActions,
        customAction: (dispatch, entity, isFromOnClick) => {
            if (!isFromOnClick) {
                const { pathname } = window.location;
                let isInCurrentDetailsPage =
                    pathname.startsWith(LOOKALIKE_ENGINE_PATH) && pathname.endsWith(`details/${entity?.entityId}`);

                isInCurrentDetailsPage && dispatch(lookalikeEngineRunsActions.request({ page: 0 }));
            }
        },
    },
    [Model]: {
        path: MODELS_PATH,
        actions: modelActions,
    },
    [PropensityEngine]: {
        path: '',
        actions: propensityActions,
        getPath: entityName => {
            let type = entityName.split('-').slice(1).join('-') || '';
            if (type) {
                return `${BASE_PROPENSITY_PATH}/${type}`;
            }
            return '';
        },
    },
    [Pipeline]: {
        path: PIPELINES_PATH,
        actions: pipelineActions,
    },
    [PipelineJob]: {
        path: '',
        actions: pipelineJobActions,
        getPathAsync: async entity => {
            try {
                const query = new URLSearchParams({
                    id: entity?.entityId?.toString(),
                    accountId: getAccountIdFromQuery(),
                });

                const response: PipelineJobDetailsResponseModel = await axios.get(`/pipeline/job?${query}`);
                let parentId = response?.model?.pipelineId;
                return parentId ? `${PIPELINES_PATH}/details/${parentId}` : '';
            } catch (err: any) {
                if (err?.response?.status === 404) {
                    toast.dismiss();
                    toast.error(i18n.t('@pipelineJob.not.found', { defaultValue: 'Pipeline Job not found!' }));
                }
                return '';
            }
        },
    },
    [RecommendationEngine]: {
        path: '',
        actions: recommendationActions,
        customAction: (dispatch, entity, isFromOnClick) => {
            if (!isFromOnClick) {
                const { pathname } = window.location;
                let isInCurrentDetailsPage =
                    pathname.startsWith(BASE_RECOMMENDATION_PATH) && pathname.endsWith(`details/${entity?.entityId}`);

                isInCurrentDetailsPage &&
                    dispatch(recommendationEngineRunsActions.request({ recommendationEngineId: entity?.entityId }));
            }
        },
        getPath: entityName => {
            let type = entityName.split('-').slice(1).join('-') || '';
            if (type) {
                return `${BASE_RECOMMENDATION_PATH}/${type}`;
            }
            return '';
        },
    },
    [Notebook]: {
        path: NOTEBOOK_SERVERS_PATH,
        actions: notebookServersActions,
    },
    [Invoice]: {
        path: BILLING_PATH,
        actions: accountInvoiceActions,
        state: { tab: 'Invoices' },
    },
    [PipelineTask]: {
        path: TASKS_PATH,
        actions: taskActions,
    },
};

/**
 * onNotificationNavigate
 */
export type OnNavigateParameters = {
    accountId: string;
    dispatch: Dispatch<any>;
    entity: NotificationEntityModel;
    history?: any;
};
export const onNotificationNavigate = async ({
    dispatch,
    accountId,
    entity,
    history: _history,
}: OnNavigateParameters) => {
    let [_entityString, ...entityName]: string[] = entity.entityType?.split(':') || ['', ''];
    let mappingKey = _entityString.includes('-') ? _entityString.split('-')[0] || '' : _entityString;
    const entityMappingObject = entityMapping[mappingKey];

    const currentAccountId = getAccountIdFromQuery()?.toString();
    if (!entityMappingObject) {
        return;
    }

    let pathname = entityMappingObject.path;

    if (mappingKey === DataSourceReader && entityMappingObject.getPathAsync) {
        pathname = await entityMappingObject.getPathAsync(entity);
    } else if (entityMappingObject.getPath) {
        pathname = entityMappingObject.getPath(_entityString, entity);
    }

    if (!pathname) return;

    let searchParams = new URLSearchParams({ accountId });
    const currentLocation = window.location;

    let url = `${pathname}${currentAccountId === accountId ? currentLocation.search : '?' + searchParams}`;

    try {
        if (entityMappingObject.actions?.setQuery && entity.status?.toLocaleLowerCase() !== 'deleted') {
            dispatch(
                entityMappingObject.actions.setQuery({
                    id: {
                        label: entityName,
                        value: entity.entityId,
                    },
                })
            );
        }
        if (
            currentLocation.pathname === pathname &&
            currentAccountId === accountId &&
            entity.status?.toLocaleLowerCase() !== 'deleted' &&
            !entityMappingObject.state
        ) {
            if (entityMappingObject?.actions?.request) {
                dispatch(
                    entityMappingObject.actions?.request({
                        id: {
                            label: entityName,
                            value: entity.entityId,
                        },
                    })
                );
            }
            return;
        }

        if (entityMappingObject.state) {
            if (currentLocation.pathname === pathname && currentAccountId === accountId) {
                dispatch(
                    entityMappingObject.actions?.request({
                        id: {
                            label: entityName,
                            value: entity.entityId,
                        },
                    })
                );
            }
            _history.replace(url, entityMappingObject.state);
        } else if (_history?.push) {
            _history?.push(url, entityMappingObject.state);
        } else currentLocation.assign(url);
    } catch (err: any) {}
};

/**
 *  onReceiveMessageRefreshList
 */
type OnReceiveMessageRefreshListParameters = {
    entityModel: NotificationEntityModel | null;
    dispatch: Dispatch<any>;
    accountId: string;
};

let receiveNotificationTimeoutId: any = null;
export const onReceiveMessageRefreshList = ({
    dispatch,
    entityModel,
    accountId,
}: OnReceiveMessageRefreshListParameters) => {
    if (!entityModel) {
        return;
    }
    const entityName = entityModel.entityType?.split(':')[0] || '';
    const currentAccountId = getAccountIdFromQuery()?.toString();
    const mappingKey = entityName.includes('-') ? entityName.split('-')[0] : entityName;
    const entityMappingObject = entityMapping[mappingKey];

    if (!entityMappingObject) return;

    try {
        if (entityMappingObject.customAction) {
            entityMappingObject.customAction(dispatch, entityModel, false);
        }

        const { actions } = entityMappingObject;
        let path = entityMappingObject.getPath ? entityMappingObject.getPath(entityName) : entityMappingObject.path;

        if (!window.location.pathname.includes(path) || accountId !== currentAccountId) return;

        let query = {};

        if (receiveNotificationTimeoutId) {
            clearTimeout(receiveNotificationTimeoutId);
        }
        receiveNotificationTimeoutId = setTimeout(() => {
            dispatch(actions.request(query));
        }, 1000);
    } catch (err: any) {}
};
