import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AnimationComponent } from '../../../components/animation';
import { NotificationEntityTypes } from '../../../components/header/notifications/NotificationItem';
import { NotificationItem } from '../../../components/header/notifications/NotificationItem';
import { NotificationListModel } from '../../../models';
import { actions as notificationsActions } from '../../../redux/thunk/app/notification/notificationsThunk';
import { onNotificationNavigate } from '../../../utils';

interface ITimeLineNotificationItemProps {
    notification: NotificationListModel;
    filterByCurrentAccount: boolean;
}

export const CustomNotificationEntities: NotificationEntityTypes[] = [
    NotificationEntityTypes.Invoice,
    NotificationEntityTypes.PaymentMethod,
];

export const TimeLineNotificationItem = ({ notification, filterByCurrentAccount }: ITimeLineNotificationItemProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onClick = () => {
        try {
            !notification.isRead &&
                dispatch(notificationsActions.readNotification({ accountId: null, id: notification.id }));

            onNotificationNavigate({
                accountId: notification.notification.accountId?.toString(),
                dispatch,
                entity: notification.entity,
                history,
            });
        } catch (err: any) {}
    };

    return (
        <AnimationComponent show type="fade-top" delay={100} transform="transform-gpu" duration={300}>
            <NotificationItem
                showAccountName={!filterByCurrentAccount}
                notification={notification}
                isFromSeeAll
                onClick={onClick}
            />
        </AnimationComponent>
    );
};
