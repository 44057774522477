import { useTranslation } from 'react-i18next';
import { SectionContainerForCreatePages } from '.';
import { breakpoints, runSchedules } from '../../constants';
import { useCurrentWidth } from '../../hooks';
import { SelectOptionType } from '../../models';
import { SelectField } from '../inputs';
import { InfoIconWithModal } from './dynamic-inputs/InfoIconWithModal';
import { HeaderForCreatePages } from './HeaderForCreatePages';

export interface RunScheduleComponentProps {
    scheduleSelectedOption?: any;
    errors?: any;
    scheduleError?: string;
    scheduleFrequencyError?: string;
    instanceTypeError?: string;
    onChangeScheduleSelected: (value: any) => any;
    runScheduleData?: any;
    onChangeRunScheduleData: (key: string, e: any) => any;
    width?: 'small' | 'large';
    instanceTypeProps?: {
        onChange: (value: any) => any;
        value: SelectOptionType | null;
        options: SelectOptionType[];
        loading: boolean;
    };
    runSchedulesOptions?: SelectOptionType[];
}

export const RunScheduleComponent: React.FC<RunScheduleComponentProps> = ({
    errors,
    scheduleSelectedOption,
    onChangeScheduleSelected,
    runScheduleData,
    onChangeRunScheduleData,
    instanceTypeProps,
    width = 'large',
    instanceTypeError,
    scheduleError,
    scheduleFrequencyError,
    runSchedulesOptions,
}) => {
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();

    return (
        <div className="w-full flex flex-col items-center">
            <HeaderForCreatePages title={t('@common.run-schedule')} className="mt-10" />
            <SectionContainerForCreatePages width={width} className="p-6 base-card-style card-shadow">
                {/* <Card className="p-6" style={{ minWidth: currentWidth < 1120 ? '100%' : 835 }}> */}
                <div className="flex w-full flex-col sm:flex-row items-end">
                    <SelectField
                        label="Schedule"
                        options={runSchedulesOptions ?? runSchedules}
                        value={scheduleSelectedOption}
                        onChange={item => {
                            onChangeScheduleSelected(item);
                            onChangeRunScheduleData('scheduleFrequency', '');
                        }}
                        errorMessage={scheduleError ?? errors?.schedule}
                        required
                    />
                    {scheduleSelectedOption?.inputs && (
                        <SelectField
                            options={scheduleSelectedOption?.inputs[0]?.options}
                            value={
                                scheduleSelectedOption?.inputs[0]?.options?.find(
                                    x => x.value === runScheduleData?.scheduleFrequency?.toString()
                                ) || null
                            }
                            required
                            label="Every"
                            className="sm:ml-3 mt-2 sm:mt-0"
                            style={{
                                minWidth: currentWidth >= breakpoints.md ? 160 : '100%',
                                width: currentWidth >= breakpoints.md ? 160 : '100%',
                            }}
                            onChange={item => onChangeRunScheduleData('scheduleFrequency', item?.value)}
                            errorMessage={scheduleFrequencyError ?? errors?.scheduleFrequency}
                        />
                    )}
                </div>
                {instanceTypeProps && (
                    <div className="w-full">
                        <SelectField
                            required
                            label={t('instance.type')}
                            labelRight={
                                instanceTypeProps.value && (
                                    <InfoIconWithModal infoText={instanceTypeProps.value?.description} />
                                )
                            }
                            placeholder={t('choose.an.instanceType')}
                            isLoading={instanceTypeProps.loading}
                            options={instanceTypeProps.options}
                            onChange={instanceTypeProps.onChange}
                            value={instanceTypeProps.value}
                            className="mt-2 sm:mt-4"
                            errorMessage={instanceTypeError ?? errors?.instanceType}
                        />
                    </div>
                )}
            </SectionContainerForCreatePages>
            {/* </Card> */}
        </div>
    );
};
