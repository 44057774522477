import { SelectOptionType } from '../models';

export enum dateTimeDataOperatorTypes {
    Equals = 'Equals',
    NotEquals = 'Not equals',
    Before = 'Before',
    After = 'After',
    TheLast = 'last',
}

export enum artifactContentTypes {
    tabular = 1,
    textual = 2,
}

export const inputTypes = {
    input: 'input',
    dropdown: 'dropdown',
    dropdownOpen: 'dropdown-open',
    dropdownMultiSelect: 'dropdown-multiselect',
    inputQuery: 'input-query',
    password: 'password',
    switch: 'switch',
    artifact: 'artifact',
    dataSource: 'dataSource',
    algorithm: 'algorithm',
    model: 'model',
    catalog: 'catalog',
    schema: 'schema',
    datePicker: 'datePicker',
};

export const statusOptionTypes: SelectOptionType[] = [
    { label: 'Succeeded', value: 'Succeeded' },
    { label: 'Failed', value: 'Failed' },
    { label: 'Running', value: 'Running' },
    { label: 'Pending', value: 'Pending' },
];

export const customInputTypes = [
    inputTypes.artifact,
    inputTypes.dataSource,
    inputTypes.algorithm,
    inputTypes.model,
    inputTypes.catalog,
    inputTypes.schema,
];

export const dataTypes = {
    date: 'date',
    string: 'string',
    number: 'number',
    array: 'array',
};

export const dataTypesOptions = Object.entries(dataTypes).map(keyValues => ({
    label: keyValues[1],
    value: keyValues[1],
}));

export const dataOperators: SelectOptionType[] = [
    {
        label: 'Less than',
        value: '<',
        dataTypes: [dataTypes.number],
    },
    {
        label: 'Less than or Equal',
        value: '<=',
        dataTypes: [dataTypes.number],
    },
    {
        label: 'Greater',
        value: '>',
        dataTypes: [dataTypes.number],
    },
    {
        label: 'Greater or Equal',
        value: '>=',
        dataTypes: [dataTypes.number],
    },
    {
        label: 'Equals',
        value: '=',
        dataTypes: [dataTypes.string, dataTypes.number],
    },
    {
        label: 'Not Equals',
        value: '!=',
        dataTypes: [dataTypes.string, dataTypes.number],
    },
    {
        label: 'Contains',
        value: 'contains',
        dataTypes: [dataTypes.string, dataTypes.array],
    },
    {
        label: 'Not contains',
        value: 'notContains',
        dataTypes: [dataTypes.string, dataTypes.array],
    },
    {
        label: 'Starts with',
        value: 'startsWith',
        dataTypes: [dataTypes.string],
    },
    {
        label: 'Ends with',
        value: 'endsWith',
        dataTypes: [dataTypes.string],
    },
    {
        label: dateTimeDataOperatorTypes.Equals,
        value: '=',
        dataTypes: [dataTypes.date],
    },
    {
        label: dateTimeDataOperatorTypes.Before,
        value: '<',
        dataTypes: [dataTypes.date],
    },
    {
        label: dateTimeDataOperatorTypes.After,
        value: '>',
        dataTypes: [dataTypes.date],
    },
    {
        label: dateTimeDataOperatorTypes.NotEquals,
        value: '!=',
        dataTypes: [dataTypes.date],
    },
    {
        label: 'The last',
        value: dateTimeDataOperatorTypes.TheLast,
        dataTypes: [dataTypes.date],
    },
];

export const everyTypesOptions: SelectOptionType[] = [
    {
        label: 'Hours',
        value: 'Hours',
    },
    {
        label: 'Days',
        value: 'Days',
    },
    {
        label: 'Weeks',
        value: 'Weeks',
    },
    {
        label: 'Months',
        value: 'Months',
    },
    {
        label: 'Years',
        value: 'Years',
    },
];

export const supportedArtifactContentTypes = ['Tabular', 'Textual'];

export const artifactSupportedFiles = '.txt, .csv, .json';

export const artifactReaderTypes = {
    uploadFile: '1',
    readFromDataSource: '2',
};
