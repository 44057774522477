import { Clickable, StatusComponent } from '../../../../../../components/common';
import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { getInterval } from '../../../../../../utils';

interface IStep {
    _id: string | '';
    outputReferenceName: any;
    displayName?: string;
    status: string;
    start: string;
    end: string;
    message: string;
    logs: string[];
}

interface IPipelineJobRunCardProps {
    index: number;
    _id: string;
    displayName: string;
    isSelected: boolean;
    onSelect: () => void;
    status: string;
    message: string;
    step: IStep;
}

export const PipelineJobRunCard = ({
    _id,
    index,
    displayName,
    isSelected,
    status,
    message,
    onSelect,
    step,
}: IPipelineJobRunCardProps) => {
    return (
        <div
            onClick={onSelect}
            className={`group w-full  cursor-pointer border-b border-mainBorder p-4 mt-2 flex items-center rounded transition duration-300 
            ${isSelected ? 'bg-primaryBlue100' : 'bg-white'}`}
        >
            <StatusComponent
                message={status === 'Succeeded' ? 'Succeeded' : message}
                status={status}
                className="mr-2"
            />
            <div className="flex flex-col justify-center flex-1 ml-4">
                <h2 className="text-blueMainText text-sm font-normal">{displayName || '-'}</h2>
            </div>
            <div className="flex items-center">
                <div
                    className={`h-6 w-6 mr-2 text-white flex justify-center items-center rounded-full border transition duration-300 ${
                        isSelected
                            ? 'bg-primaryBlue500 border-white opacity-100'
                            : `bg-white border-mainGrayText opacity-0 group-hover:opacity-100`
                    } `}
                >
                    <GjirafaIcon name="Check" />
                </div>
                <Clickable
                    className={`transition-opacity duration-300 group-hover:opacity-100 ${
                        isSelected ? 'opacity-100' : 'opacity-0'
                    }`}
                    children={undefined}
                />
                {status !== 'Pending' && <p>{getInterval(step.start, step.end)}</p>}
            </div>
        </div>
    );
};
