import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlgorithmGroupDetailsResponseModel } from '../../models';
import axios from '../../services/axios';
import { getAccountIdFromQuery, getFormattedQuery } from '../../utils';
import { InputField, SwitchInput } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateAlgorithmGroupModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    algorithmGroupForEdit?: any;
    onSuccess: (isEditing: boolean) => any;
}
export const CreateAlgorithmGroupModal: React.FC<CreateAlgorithmGroupModalProps> = ({
    isVisible,
    onHide,
    algorithmGroupForEdit,
    isEditing,
    onSuccess,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();

    /**
     * Local State
     */
    const [loading, setLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [fieldValues, setFieldValues] = useState({
        name: '',
        pipelineId: '',
        isActive: 'false',
    });

    useEffect(() => {
        if (isEditing && !!algorithmGroupForEdit) {
            fetchDetails(algorithmGroupForEdit?.id);
        }
    }, [isEditing, algorithmGroupForEdit]);

    const fetchDetails = async id => {
        try {
            setDetailsLoading(true);
            const query = {
                accountId: getAccountIdFromQuery(),
                id,
            };
            const res: AlgorithmGroupDetailsResponseModel = await axios.get(
                `/algorithmGroup?${getFormattedQuery(query)}`
            );
            setFieldValues({
                name: res.model.name,
                pipelineId: res.model.pipelineId,
                isActive: res.model?.isActive?.toString() || 'false',
            });
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setLoading(true);
            const payload = {
                id: isEditing ? algorithmGroupForEdit?.id : 0,
                ...fieldValues,
                isActive: fieldValues.isActive === 'true',
            };
            await axios.post('/algorithmGroup', payload);
            onSuccess(!!isEditing);
        } catch (err: any) {
        } finally {
            setLoading(false);
        }
    };
    const onChangeInputs = (key, value) => {
        setFieldValues(prev => ({ ...prev, [key]: value }));
    };

    const { formId, title, buttonText } = useMemo(() => {
        return {
            formId: isEditing ? 'editGroupForm' : 'addGroupForm',
            title: isEditing ? 'Edit Group' : 'Create Group',
            buttonText: isEditing ? t('@common.edit') : t('@common.create'),
        };
    }, [isEditing]);

    return (
        <>
            <SideModal
                loading={loading}
                isVisible={isVisible}
                onHide={onHide}
                title={title}
                buttonText={buttonText}
                formId={formId}
            >
                <form onSubmit={handleSubmit} id={formId} className="flex flex-col h-full border-solid px-4 py-6">
                    {detailsLoading ? (
                        <div className="py-12 w-full flex items-center justify-center">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <InputField
                                required
                                label={t('@common.name')}
                                placeholder={t('@common.enter-name')}
                                className="mb-6"
                                value={fieldValues.name}
                                onChange={e => onChangeInputs('name', e.target.value)}
                            />
                            <InputField
                                required
                                label="Pipeline Id"
                                placeholder={t('@common.write.here')}
                                className="mb-6"
                                value={fieldValues.pipelineId}
                                onChange={e => onChangeInputs('pipelineId', e.target.value)}
                                maxLength={50}
                            />
                            <SwitchInput
                                label={t('@common.is-active')}
                                value={fieldValues.isActive === 'true'}
                                onChange={value => onChangeInputs('isActive', value ? 'true' : 'false')}
                            />
                        </>
                    )}
                </form>
            </SideModal>
        </>
    );
};
