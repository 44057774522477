import { ReactNode, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    HeaderButtonsProps,
    NoDataComponentProps,
    Table,
    TableActionRowProps,
    TableFilters,
    TableMobile,
} from '../../../components/tables';
import { breakpoints } from '../../../constants';
import { useCurrentWidth } from '../../../hooks';
import { BaseListModel, ListResponseModel, UpdateRowActionProps } from '../../../models';
import axios from '../../../services/axios';
import { ToggleActiveComponent } from './ToggleActiveComponent';

type IDataTableColumn = TableColumn<any>;
export interface AdminTableProps {
    headerButtonProps?: HeaderButtonsProps;
    requestList: (query: any) => any;
    reduxActions?: any;
    className?: string;
    emptyDataProps?: NoDataComponentProps;
    modelEndpoint: string;
    columns: Array<IDataTableColumn>;
    onClickEdit?: (row: any) => any;
    onClickDelete?: (row: any) => any;
    response: ListResponseModel<any>;
    loading: boolean;
    initLoading: boolean;
    onPageChanged: (page: number) => any;
    hideDelete?: boolean;
    hideEdit?: boolean;
    showCreateButton?: boolean;
    hideActions?: boolean;
    tableFiltersChildren?: ReactNode;
    hideIsActiveColumn?: boolean;
    toggleActivate?: (isActive: boolean, row: any) => any;
    columnsBeforeActions?: Array<IDataTableColumn>;
    overrideColumns?: boolean;
}

export const AdminTable: React.FC<AdminTableProps> = ({
    className,
    headerButtonProps,
    response,
    columns,
    modelEndpoint,
    onPageChanged,
    reduxActions,
    hideActions,
    hideIsActiveColumn = false,
    tableFiltersChildren,
    onClickEdit,
    requestList,
    loading,
    emptyDataProps,
    hideDelete,
    hideEdit,
    columnsBeforeActions = [],
    initLoading,
    overrideColumns,
}) => {
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const dispatch = useDispatch();

    const tableActions = useMemo<TableActionRowProps[]>(() => {
        let actions: TableActionRowProps[] = [];
        if (!hideEdit)
            actions.push({
                action: 'edit',
                onClick: onClickEdit,
            });

        if (!hideDelete)
            actions.push({
                action: 'delete',
            });

        return hideActions ? [] : actions;
    }, []);

    const paginationProps = useMemo(
        () => ({
            totalPages: response.totalPages,
            currentPage: response.currentPage,
            pageItems: response.models.length,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const _columns = [
        ...columns,
        {
            selector: row => row?.lastModified ?? '',
            name: t('@common.last-modified'),
            sortable: true,
            width: '150px',
            cell: (row: any) => {
                return <span className="flex w-full justify-center">{row?.lastModified}</span>;
            },
        },
        {
            selector: row => row?.modifiedBy ?? '',
            name: t('@common.modified-by'),
            sortable: true,
            width: '180px',
            cell: (row: any) => {
                return <span className="flex w-full justify-start pl-2">{row?.modifiedBy}</span>;
            },
        },
        ...columnsBeforeActions,
    ];

    const onActivateOrDeactivate = async (isActive, row: BaseListModel) => {
        try {
            let payload = {
                id: row.id,
                patch: [
                    {
                        op: 'replace',
                        path: 'isActive',
                        value: isActive,
                    },
                ],
            };
            await axios.patch(`/${modelEndpoint}`, payload);
            if (reduxActions && reduxActions?.updateRow) {
                let updateRowProps: UpdateRowActionProps = {
                    keyValues: [
                        {
                            key: 'isActive',
                            value: isActive,
                        },
                    ],
                    rowId: row?.id,
                };
                dispatch(reduxActions.updateRow(updateRowProps));
            }
            return true;
        } catch (err: any) {
            return false;
        }
    };

    const commonTableProps = {
        requestList,
        modelEndpoint,
        headerButtonProps,
        selectableRows: !hideDelete,
        showHeaderButtons: true,
        response,
        loading,
        emptyDataProps,
        isFromSelectPopup: false,
    };

    return (
        <div className={`flex flex-col ${className}`}>
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    pagination
                    actions={tableActions}
                    paginationProps={paginationProps}
                    showHeader={false}
                    subHeader
                    headerComponent={
                        <TableFilters
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, isFromAdmin: true, page: 0 });
                            }}
                        >
                            {tableFiltersChildren}
                        </TableFilters>
                    }
                    columns={
                        overrideColumns
                            ? columns
                            : hideIsActiveColumn
                            ? _columns
                            : [
                                  ..._columns,
                                  {
                                      selector: row => row?.isActive ?? '',
                                      name: t('@common.is-active'),
                                      sortable: true,
                                      width: '120px',
                                      cell: (row: any) => {
                                          let isActive = !!row?.isActive;
                                          return (
                                              <ToggleActiveComponent
                                                  isActive={isActive}
                                                  onToggleActivate={val => onActivateOrDeactivate(val, row)}
                                              />
                                          );
                                      },
                                  },
                              ]
                    }
                />
            )}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="name"
                    initialLoading={initLoading}
                    actions={tableActions}
                    columns={
                        hideIsActiveColumn
                            ? _columns
                            : [
                                  ..._columns,
                                  {
                                      selector: row => row?.isActive ?? '',
                                      name: t('@common.is-active'),
                                      sortable: true,
                                      cell: (row: any) => {
                                          let isActive = !!row?.isActive;
                                          return (
                                              <ToggleActiveComponent
                                                  isActive={isActive}
                                                  onToggleActivate={val => onActivateOrDeactivate(val, row)}
                                              />
                                          );
                                      },
                                  },
                              ]
                    }
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
