import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { RunStatusComponent } from '../../../../components/common';
import { NoDataImagesType, Table, TableFilters } from '../../../../components/tables';
import { PIPELINES_PATH, statusOptionTypes } from '../../../../constants';
import * as hooks from '../../../../hooks';
import { PipelineJobListModel, PipelineJobListResponseModel, SelectOptionType } from '../../../../models';
import { actions, selectors } from '../../../../redux/thunk/app/pipeline-job/pipelineJobsThunk';
import axios from '../../../../services/axios';
import { formatArrayForSelectOptions, queryParams, showFiltersHeader } from '../../../../utils';
import { Loader } from '../../../../components/ui';
import { SelectField } from '../../../../components/inputs';
import { SelectedOption } from '../../../../components/inputs/components';
import { OptionTypeBase } from 'react-select';

export const PipelineJobsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = hooks.useAccountId();
    const history = useHistory();
    const loading = useSelector(selectors.getIsLoading);
    const response: PipelineJobListResponseModel = useSelector(selectors.getResponse);
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);

    const params: any = useParams();
    const entityId = parseInt(params?.id);

    useEffect(() => {
        requestList({ searchQuery: '', pipelineId: entityId });
    }, [accountId]);

    useEffect(() => {
        if (response.query?.status) {
            setStatusTypeSelected(response.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [response.query]);

    const requestList = (query: any, resetQuery?: boolean) =>
        dispatch(actions.request({ accountId, ...query }, resetQuery));

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestList({ page: 0, status: status });
    };

    const onPageChanged = page => dispatch(actions.request({ page }));

    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    /**
     * Handle onClick events
     */

    const onClickCreate = () =>
        history.push(`${PIPELINES_PATH}/${params?.id}/create-new-pipeline-job${queryParams.formatForNavigation()}`);

    const onRowClicked = (row: PipelineJobListModel) => {
        history.push(`${PIPELINES_PATH}/${params?.id}/details/${row.id}${queryParams.formatForNavigation()}`);
    };

    const onClickEdit = (row: PipelineJobListModel) => {
        history.push(
            `${PIPELINES_PATH}/${params?.id}/edit-pipeline-job/${row.id}${queryParams.formatForNavigation()}`
        );
    };

    const onClickRun = async row => {
        try {
            await axios.post('/pipeline/job/run', { id: row.id, accountId });
            toast.success(t('@pipelineJob.started.running', { defaultValue: 'PipelineJob started running!' }));
            requestList({});
        } catch (err: any) {}
    };

    const onClickTerminate = async row => {
        try {
            await axios.post('/pipeline/job/terminate', { id: row.id, accountId });
            toast.success(t('@pipelineJob.started.terminating', { defaultValue: 'PipelineJob started terminating!' }));
            requestList({});
        } catch (err: any) {}
    };

    const onRefresh = () => requestList({});

    return (
        <>
            <div className="w-full">
                {loading ? (
                    <div className="flex justify-center align-center p-20">
                        <Loader />
                    </div>
                ) : (
                    <Table
                        highlightOnHover
                        selectableRows
                        showHeaderButtons
                        modelEndpoint={'pipeline/job'}
                        loading={loading}
                        response={response}
                        isFromSelectPopup={false}
                        requestList={requestList}
                        onRowClicked={onRowClicked}
                        emptyDataProps={{
                            emptyText: t('@pages.no-pipelineJob'),
                            actionMessage: `+ ${t('@pages.create-new-pipelineJob')}`,
                            imageType: NoDataImagesType.PipelineJob,
                            onClickAction: onClickCreate,
                        }}
                        headerButtonProps={{
                            onRefresh,
                            onClickCreate,
                            createTitle: t('@pages.create-new-pipelineJob', { defaultValue: 'Create new PipelineJob' }),
                        }}
                        pagination
                        paginationProps={paginationProps}
                        subHeader={showFiltersHeader(response)}
                        headerComponent={
                            <TableFilters
                                onChangeQuery={(key, value) => {
                                    requestList({
                                        [key]: value,
                                    });
                                }}
                                response={response}
                                onSearch={searchQuery => {
                                    requestList({ searchQuery, page: 0 });
                                }}
                            >
                                <SelectField
                                    disabled={false}
                                    isClearable
                                    label="Status"
                                    className="m-2"
                                    placeholder={t('@common.choose-status')}
                                    style={{ width: 240 }}
                                    onChange={onChangeStatusType}
                                    options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                                    value={selectedStatusType}
                                    isLoading={loading}
                                />
                            </TableFilters>
                        }
                        actions={[
                            {
                                action: 'details',
                                displayName: t('@view.details', { defaultValue: 'View details' }),
                                onClick: onRowClicked,
                            },
                            {
                                action: 'edit',
                                onClick: onClickEdit,
                            },
                            {
                                action: row => (row?.runStatus === 'Running' ? 'Terminate' : 'run'),
                                onClick: async (row: PipelineJobListModel) =>
                                    row?.runStatus === 'Running' ? onClickTerminate(row) : onClickRun(row),
                                displayName: (row: PipelineJobListModel) =>
                                    row?.runStatus === 'Running' ? 'Terminate' : 'Run',
                                disable: (row: PipelineJobListModel) => row?.runStatus === 'Terminating',
                            },
                            {
                                action: 'delete',
                            },
                        ]}
                        columns={[
                            {
                                name: t('@common.title'),
                                selector: (row: PipelineJobListModel) => row.title,
                            },
                            {
                                name: t('@common.run-status'),
                                selector: (row: PipelineJobListModel) => row?.runStatus ?? '',
                                sortable: true,
                                width: '140px',
                                cell: row => <RunStatusComponent row={row} />,
                            },
                            {
                                name: t('@common.last-modified'),
                                width: '180px',
                                sortable: true,
                                selector: row => row?.lastModified ?? '',
                                cell: row => <span>{row?.lastModified}</span>,
                            },
                            {
                                name: t('@common.modified-by'),
                                selector: row => row?.modifiedBy ?? '',
                                width: '140px',
                                cell: row => <span>{row?.modifiedBy}</span>,
                            },
                        ]}
                    />
                )}
            </div>
        </>
    );
};
