import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/ui';

interface IMarkAllAsReadProps {
    onClick: () => Promise<boolean>;
}

export const MarkAllAsRead = ({ onClick }: IMarkAllAsReadProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const _onClick = async () => {
        if (loading) return;

        try {
            setLoading(true);
            await onClick();
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className={`flex items-center px-3 py-2 ${loading ? 'cursor-default' : 'cursor-pointer'}`}
            onClick={_onClick}
        >
            {loading && <Loader className="mr-2" size={24} />}
            <p className={`text-secondaryText text-sm ${loading && 'opacity-80'}`}>{t('mark.all.as.read')}</p>
        </div>
    );
};
