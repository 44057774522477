// import { nanoid } from 'nanoid';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderForCreatePages, SectionContainerForCreatePages } from '../../../../../../components/common';
import { InputField, SelectField } from '../../../../../../components/inputs';
import { taskFilePathDataTypesOptions, taskInputDataTypesOptions } from '../../../constants';
import { TOutput } from '../../hooks';
// import { AddButton } from '../AddButton';
// import { RemoveButton } from '../RemoveButton';

const inputsClassName = 'w-[220px] sm:mr-4';

interface ITaskOutputsProps {
    outputs: TOutput[];
    setOutputs: Dispatch<SetStateAction<TOutput[]>>;
}

export const TaskOutputs = React.memo(({ outputs, setOutputs }: ITaskOutputsProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const onChange = (_id: string, key: keyof TOutput, value: any) => {
        setOutputs(prevOutputs => {
            const newOutputs = prevOutputs.map(_output =>
                _output._id === _id
                    ? {
                          ..._output,
                          [key]: value,
                      }
                    : _output
            );
            return newOutputs;
        });
    };

    // const removeOutput = (_id: string) => () => {
    //     setOutputs(prev => prev.filter(x => x._id !== _id));
    // };

    // const addOutput = () => {
    //     setOutputs(prev => [...prev, { _id: nanoid(), dataType: taskInputDataTypesOptions[0], id: 0, name: '' }]);
    //     setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
    // };

    return (
        <>
            <HeaderForCreatePages title={t('@outputs', { defaultValue: 'Outputs' })} className="flex flex-col mt-8" />
            <SectionContainerForCreatePages width="large">
                <div className={`flex flex-col w-full py-4 rounded-lg bg-white card-shadow-sm px-6`}>
                    {outputs.map(({ _id, dataType, name }) => (
                        <div key={_id} className="flex w-full  py-2">
                            <InputField
                                required
                                className={`${inputsClassName}`}
                                label={t('@common.name')}
                                placeholder={t('@common.write.here')}
                                value={name}
                                onChange={e => onChange(_id, 'name', e.target.value)}
                                disabled
                            />
                            <SelectField
                                disabled
                                required
                                className={`${inputsClassName}`}
                                label={t('@dataType')}
                                placeholder={t('@select.dataType')}
                                value={dataType}
                                options={[...taskInputDataTypesOptions, ...taskFilePathDataTypesOptions]}
                                onChange={item => onChange(_id, 'dataType', item)}
                            />
                            {/* <RemoveButton className="ml-2 mt-8" onClick={removeOutput(_id)} /> */}
                        </div>
                    ))}
                    <div ref={ref} />
                    {/* <AddButton
                        className={`${outputs.length ? 'mt-2' : ''}`}
                        title={t('@add.new.output')}
                        onClick={addOutput}
                    /> */}
                    {outputs.length === 0 ? (<div className="text-gray-500">No Outputs</div>) : ''}
                </div>
            </SectionContainerForCreatePages>
        </>
    );
});
