import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/thunk/app/notification/notificationsThunk';
import { deviceLocalStorage, LocalStorageKeys } from '../../../services/deviceLocalStorage';
import { Dropdown } from '../../dropdown';
import { SwitchInput } from '../../inputs';
import { ActionsButton } from '../../ui';

/**
 * Constants
 */
const actionItemClassName: string =
    'flex w-full px-4 h-auto cursor-pointer justify-between items-center hover:bg-mainBackground';
const textClassName: string = 'text-blueMainText font-medium text-sm';
const actionItemStyle: CSSProperties = { minHeight: 40 };

export const NotificationActionsComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [areNotificationsMuted, setAreNotificationsMuted] = useState(false);

    useEffect(() => {
        setAreNotificationsMuted(!!deviceLocalStorage.getItem(LocalStorageKeys.MuteNotifications));
    }, [isDropdownOpen]);

    const onToggleMuteNotifications = () => {
        if (areNotificationsMuted) {
            deviceLocalStorage.removeItem(LocalStorageKeys.MuteNotifications);
            setAreNotificationsMuted(false);
            return;
        }
        deviceLocalStorage.setItem(LocalStorageKeys.MuteNotifications, 'true');
        setAreNotificationsMuted(true);
    };

    const onMarkAllAsRead = () => {
        setIsDropdownOpen(false);
        dispatch(actions.onMarkAllAsRead());
    };

    return (
        <Dropdown
            wrapperClassName="ml-2 z-40"
            withArrow={false}
            openProps={{
                isOpen: isDropdownOpen,
                onSetIsOpen: isOpen => setIsDropdownOpen(isOpen),
            }}
            childrenContainerClassName="w-68 sm:w-84 pl-4"
            triggerProps={{
                children: <ActionsButton className={`${isDropdownOpen && 'bg-mainBackground'}`} />,
            }}
        >
            <div className="bg-white flex-col flex dropdown-shadow rounded-lg py-1 mt-2 w-full ">
                <div onClick={onToggleMuteNotifications} className={`${actionItemClassName}`} style={actionItemStyle}>
                    <span className={`${textClassName}`}>{t('notification.sound')}</span>
                    <SwitchInput hideLabel size="small" value={!areNotificationsMuted} />
                </div>
                <div className={`${actionItemClassName}`} onClick={onMarkAllAsRead} style={actionItemStyle}>
                    <span className={`${textClassName}`}>{t('mark.all.as.read')}</span>
                </div>
            </div>
        </Dropdown>
    );
};
