import { TableColumn } from 'react-data-table-component';
import { Dispatch } from 'redux';
import { breakpoints } from '../../../../constants';
import {
    AdminLanguageListModel,
    AdminLanguageListResponseModel,
    AdminLanguagesStateType,
    AdminLanguageStateListModel,
    LanguageRecourseListModel,
} from '../../../../models';
import axios from '../../../../services/axios';
import {
    actionCreator,
    getFormattedQuery,
    getFormattedResponseForPayload,
    getSearchParams,
    getWidth,
} from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/language/adminLanguagesThunk/REQUEST',
    SUCCESS: '@app/language/adminLanguagesThunk/SUCCESS',
    FAILURE: '@app/language/adminLanguagesThunk/FAILURE',
    SET_INITIAL_LOADING: '@app/language/adminLanguagesThunk/SET_INITIAL_LOADING',
    SET_COLUMNS: '@app/language/adminLanguagesThunk/SET_COLUMNS',
    UPDATE_ROW_BY_KEY: '@app/language/adminLanguagesThunk/UPDATE_ROW_BY_KEY',
};

/**
 * Initial state
 */

const initialState: AdminLanguagesStateType = {
    isLoading: false,
    errorMessage: '',
    columns: [],
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
        query: {},
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.adminLanguages.isLoading,
    getInitialLoading: (state: RootState) => state.app.adminLanguages.initialLoading,
    getResponse: (state: RootState) => state.app.adminLanguages.response,
    getErrorMessage: (state: RootState) => state.app.adminLanguages.errorMessage,
    getColumns: (state: RootState) => state.app.adminLanguages.columns,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_INITIAL_LOADING, SET_COLUMNS, UPDATE_ROW_BY_KEY } = actionTypes;

const Reducer = (state: AdminLanguagesStateType = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_ROW_BY_KEY:
            return {
                ...state,
                response: {
                    ...state.response,
                    models: state.response.models.map(x => (x.key === payload.key ? payload.row : x)),
                },
            };
        case SET_COLUMNS:
            return {
                ...state,
                columns: payload,
            };
        case SET_INITIAL_LOADING:
            return {
                ...state,
                initialLoading: payload,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                initialLoading: false,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                initialLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query: any, resetQuery = false) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const isMobile = getWidth() <= breakpoints.sm;
                const previousQuery = getState().app.adminLanguages.response.query;

                if (query?.page === undefined) {
                    query.page = previousQuery?.page || 0;
                }

                if (isMobile && query.page === 0) dispatch(actionCreator(SET_INITIAL_LOADING, true));
                dispatch(actionCreator(REQUEST));

                const previousModels = isMobile && query.page > 0 ? getState().app.adminLanguages.response.models : [];
                let searchParams = getSearchParams(previousQuery, query, resetQuery);

                const response: AdminLanguageListResponseModel = await axios.get(
                    `/language/list/languageResources?${getFormattedQuery(searchParams)}`
                );

                let models: AdminLanguageStateListModel[] = [];
                const columns: TableColumn<any>[] = [
                    {
                        name: 'Key',
                        selector: row => row?.key ?? '',
                    },
                ];

                let languages: Record<string, LanguageRecourseListModel[]> = {};
                for (let i = 0; i < response.models.length; i++) {
                    let currentModel: AdminLanguageListModel = response.models[i];
                    languages[currentModel.name] = currentModel.resources;
                    columns.push({
                        name: currentModel.name,
                        selector: () => `${currentModel.name}.value`,
                    });
                }

                if (response.models.length > 0) {
                    let length = languages[response.models[0].name].length;
                    for (let j = 0; j < length; j++) {
                        const currentResource = languages[response.models[0].name][j];

                        let _finalModelItem: AdminLanguageStateListModel = {
                            key: currentResource.key,
                            id: currentResource.id,
                            lastModified: currentResource.lastModified,
                            modifiedBy: currentResource.modifiedBy,
                        };

                        Object.entries(languages).forEach(([key, value]) => {
                            _finalModelItem[key] = value[j];
                        });

                        models.push(_finalModelItem);
                    }
                }
                let res = getFormattedResponseForPayload({ ...response, models }, previousModels, query, searchParams);
                dispatch(actionCreator(SET_COLUMNS, columns));
                dispatch(actionCreator(SUCCESS, res));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
    updateRowByKey: (key: string, row: AdminLanguageStateListModel) => actionCreator(UPDATE_ROW_BY_KEY, { key, row }),
};
