import React, { CSSProperties, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeoChart } from '../../../../../../components/charts';
import { SelectField } from '../../../../../../components/inputs';
import { Loader } from '../../../../../../components/ui';
import { SelectOptionType } from '../../../../../../models';

interface RequestsPerCountryProps {
    className?: string;
    style?: CSSProperties;
    geoChartData: [
        {
            v: string;
            f: string;
        },
        number
    ][];
    loading: boolean;
    noDataMessage?: string | ReactNode;
}

const getCountryWidth = (maxRequest: number, currentRequest: number) => {
    let maxWidth = 250;
    return (currentRequest / maxRequest) * maxWidth;
};

const gradientColors = ['#C5DAFC', '#C6DBFD', '#C7DBFD', '#C8DCFD', '#C8DCFD'];

type countryDataType = { name: string; value: number };

const regionsOptions: SelectOptionType[] = [
    {
        label: 'All world',
        value: 'world',
    },
    {
        label: 'Europe',
        value: '150',
    },
    {
        label: 'North America',
        value: '021',
    },
    {
        label: 'South America',
        value: '005',
    },
    {
        label: 'Asia',
        value: '142',
    },
    {
        label: 'Africa',
        value: '002',
    },
    {
        label: 'Oceania',
        value: '009',
    },
];

export const RequestsPerCountry: React.FC<RequestsPerCountryProps> = ({
    className,
    style,
    loading,
    geoChartData,
    noDataMessage = 'There is no data!',
}) => {
    const { t } = useTranslation();

    const [regionSelected, setRegionSelected] = useState<SelectOptionType | null>(null);

    const { countries, maxRequests }: { countries: countryDataType[]; maxRequests: number } = useMemo(() => {
        let countries = geoChartData
            .map(([name, value]) => ({ name: name.f, value: parseInt(value?.toString()) ?? 0 }))
            .sort((itemA, itemB) => (itemA.value < itemB.value ? 1 : itemA.value > itemB.value ? -1 : 0));

        return {
            countries,
            maxRequests: countries[0]?.value ?? 0,
        };
    }, [geoChartData]);

    const { initialLoading, refreshLoading } = useMemo(
        () => ({ initialLoading: loading && !geoChartData.length, refreshLoading: loading && !!geoChartData.length }),
        [loading, geoChartData.length]
    );

    return (
        <div
            className={`w-full flex flex-col p-6 mt-8 border-mainBorder rounded border bg-white ${className}`}
            style={style}
        >
            <div className="relative">
                <h3 className="text-blueDark font-bold text-xl">
                    {t('@requests.per.country', { defaultValue: 'Requests per Country' })}
                </h3>
                {refreshLoading && (
                    <div className="absolute top-2 right-4">
                        <Loader size={34} />
                    </div>
                )}
            </div>

            <div className="flex flex-col lg:flex-row w-full mt-10">
                <div className="w-full lg:w-1/2">
                    <SelectField
                        label="Regions"
                        options={regionsOptions}
                        value={regionSelected}
                        onChange={item => setRegionSelected(item)}
                        placeholder="Select region"
                        style={{ width: 200 }}
                        isClearable
                        className="mb-2"
                    />
                    <GeoChart
                        options={{ region: regionSelected ? regionSelected.value : 'world' }}
                        data={[['Country', 'Popularity'], ...geoChartData]}
                    />
                </div>
                <div className="w-full mt-6 lg:w-1/2 md:px-8 lg:px-10 flex justify-center">
                    <div className="w-full max-w-md flex flex-col">
                        {initialLoading ? (
                            <div className="h-68 w-full justify-center items-center flex">
                                <Loader size={34} />
                            </div>
                        ) : !geoChartData.length ? (
                            typeof noDataMessage === 'string' ? (
                                <div className="flex w-full flex-1 justify-center items-center p-10">
                                    <p className="text-secondaryText text-base text-center">{noDataMessage}</p>
                                </div>
                            ) : (
                                noDataMessage
                            )
                        ) : (
                            <>
                                <span className="text-secondaryText text-xs md:text-sm font-medium">
                                    {t('@top.5.countries', { defaultValue: 'Top 5 countries' })}
                                </span>
                                {countries.slice(0, 5).map((country, i, arr) => (
                                    <div
                                        key={i}
                                        className={`w-full flex items-center pb-2 ${
                                            i < arr.length - 1 ? 'border-b' : ''
                                        } border-mainBorder mt-2`}
                                    >
                                        <div className="w-4 h-3 bg-mainBorder" />
                                        <span className="ml-2 text-blueMainText font-normal text-sm">
                                            {country.name}
                                        </span>
                                        <div className="flex flex-1 justify-end">
                                            <div
                                                className="h-9 flex justify-end pr-6 items-center"
                                                style={{
                                                    background: `linear-gradient(90deg, ${
                                                        gradientColors[i] ?? '#C5DAFC'
                                                    } 0%, #FFFFFF 100%)`,
                                                    maxWidth: getCountryWidth(maxRequests, country.value),
                                                    width: getCountryWidth(maxRequests, country.value),
                                                }}
                                            >
                                                <span className="text-blueMainText text-sm">
                                                    {country.value.toLocaleString('en-US')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
