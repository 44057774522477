import { Dispatch } from 'redux';
import { breakpoints } from '../../../../../constants';
import { InitialStateType, PropensityEngineListResponseModel } from '../../../../../models';
import axios from '../../../../../services/axios';
import {
    actionCreator,
    getFormattedQuery,
    getFormattedResponseForPayload,
    getSearchParams,
    getWidth,
} from '../../../../../utils';
import { RootState } from '../../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/engines/propensity/propensityEnginesThunk/REQUEST',
    SUCCESS: '@app/engines/propensity/propensityEnginesThunk/SUCCESS',
    FAILURE: '@app/engines/propensity/propensityEnginesThunk/FAILURE',
    SET_INITIAL_LOADING: '@app/engines/propensity/propensityEnginesThunk/SET_INITIAL_LOADING',
    SET_QUERY: '@app/engines/propensity/propensityEnginesThunk/SET_QUERY',
    OVERRIDE_RESPONSE: '@app/engines/propensity/propensityEnginesThunk/OVERRIDE_RESPONSE',
};

/**
 * Initial state
 */
const initialState: InitialStateType<PropensityEngineListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    initialLoading: false,
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
        query: {},
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.propensityEngines.isLoading,
    getResponse: (state: RootState) => state.app.propensityEngines.response,
    getErrorMessage: (state: RootState) => state.app.propensityEngines.errorMessage,
    getInitialLoading: (state: RootState) => state.app.propensityEngines.initialLoading,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_INITIAL_LOADING, SET_QUERY, OVERRIDE_RESPONSE } = actionTypes;

const Reducer = (state: InitialStateType<PropensityEngineListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case OVERRIDE_RESPONSE:
            return {
                ...state,
                response: {
                    ...state.response,
                    ...payload,
                },
            };
        case SET_QUERY:
            return {
                ...state,
                response: {
                    ...state.response,
                    query: payload,
                },
            };
        case SET_INITIAL_LOADING:
            return {
                ...state,
                initialLoading: payload,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
                initialLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                initialLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    setEmptyResponse: () =>
        actionCreator(OVERRIDE_RESPONSE, {
            success: false,
            message: '',
            models: [],
            currentPage: 0,
            totalPages: 1,
            hasNextPage: false,
        }),
    request: function (query: any, resetQuery = false) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const isMobile = getWidth() <= breakpoints.sm;
                const previousQuery = getState().app.propensityEngines.response.query;

                if (query?.page === undefined) {
                    query.page = previousQuery?.page || 0;
                }

                if (isMobile && query.page === 0) dispatch(actionCreator(SET_INITIAL_LOADING, true));
                dispatch(actionCreator(REQUEST));

                const previousModels =
                    isMobile && query.page > 0 ? getState().app.propensityEngines.response.models : [];

                let searchParams = getSearchParams(previousQuery, query, resetQuery);

                const response: PropensityEngineListResponseModel = await axios.get(
                    `/propensityEngine/list?${getFormattedQuery(searchParams)}`
                );

                let res = getFormattedResponseForPayload(response, previousModels, query, searchParams);

                dispatch(actionCreator(SUCCESS, res));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
    setQuery: function (query: any) {
        return (dispatch, getState: () => RootState) => {
            const previousQuery = getState().app.propensityEngines.response.query;
            dispatch(actionCreator(SET_QUERY, { ...previousQuery, ...query }));
        };
    },
    removeNotificationQuery: function () {
        return (dispatch, getState: () => RootState) => {
            const previousQuery = { ...getState().app.propensityEngines.response.query };
            if (previousQuery?.id) {
                delete previousQuery.id;
                dispatch(actionCreator(SET_QUERY, { ...previousQuery }));
            }
        };
    },
};
