import React, { CSSProperties, ReactNode, TextareaHTMLAttributes, useEffect, useState } from 'react';
import { inputAnimationClassName } from '../../constants';
import { ErrorTextComponent } from '../messages';

export interface TextareaFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    label?: string;
    hideLabel?: boolean;
    className?: string;
    style?: CSSProperties;
    labelClassName?: string;
    labelRight?: ReactNode;
    textareaClassName?: string;
    isAutoSize?: boolean;
}

export const TextareaField: React.FC<TextareaFieldProps> = ({
    label,
    errorClassName,
    errorMessage,
    errorStyle,
    hideLabel,
    className,
    style,
    labelClassName,
    labelRight,
    textareaClassName,
    isAutoSize,
    required,
    ...rest
}) => {
    const [textareaHeight, setTextAreaHeight] = useState(40);
    const textareaId = label + 'TextArea';

    const onKeyPress = e => {
        if (isAutoSize) {
            if (e.key === 'Enter') {
                const textarea = document.getElementById(textareaId);
                if (textarea?.offsetHeight) {
                    setTextAreaHeight(textarea.offsetHeight + 20);
                }
            }
        }
    };

    useEffect(() => {
        if (rest.value && isAutoSize) {
            let str = rest?.value?.toString().trim();
            let split = str?.split('\n');
            let height = split.length * 20;
            const textarea = document.getElementById(textareaId);
            if (textarea?.offsetHeight) {
                if (height > textarea?.offsetHeight) {
                    setTextAreaHeight(height + 20);
                    textarea.style.height = height + 20 + 'px';
                }
            }
        }
    }, [rest.value]);

    const borderTransitionClassName = `transition-colors duration-300 ease-in  ${
        errorMessage ? 'border-mainError transition-colors' : 'border-grey200 transition-colors'
    }`;

    return (
        <div
            className={`w-full flex flex-col ${!isAutoSize && 'max-h-60'} ${className}`}
            style={{ minHeight: 50, ...style }}
        >
            {!hideLabel && (
                <div
                    style={{
                        minHeight: 24,
                    }}
                    className={`flex mb-1  flex-row justify-between items-center text-left ${labelClassName}`}
                >
                    <label className="text-xs sm:text-sm text-greyBlack100 font-normal">
                        {label} {(required || required) && <span className="text-mainError text-base">{' *'}</span>}
                    </label>
                    {labelRight && labelRight}
                </div>
            )}
            <textarea
                id={textareaId}
                style={{
                    minHeight: 36,
                    height: textareaHeight,
                    maxHeight: 'auto',
                }}
                className={`w-full border rounded h-20 box-border py-2 px-3 placeholder-mainPlaceholder font-normal text-xs sm:text-sm ${textareaClassName} ${inputAnimationClassName} ${borderTransitionClassName}`}
                onKeyPress={onKeyPress}
                {...rest}
            />
            {errorMessage && <ErrorTextComponent message={errorMessage} />}
        </div>
    );
};
