import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import axios from '../../../../services/axios';
import { trim, trimStart } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Button, CreateButton } from '../../../../components/buttons';
import { InputField } from '../../../../components/inputs';
import { toast } from '../../../../components/ui';
import { StorageObjectListModel, StorageObjectListResponseModel } from '../../../../models';
import { selectors as objectsSelectors } from '../../../../redux/thunk/app/storage/storageObjectsThunk';
import { modalFadeTopAnimationStyle, modalOverlayAnimationStyles } from '../../../../styles/animationStyles';
import { getAccountIdFromQuery, removeDuplicatesCharsInside, showServerErrors } from '../../../../utils';
import { getNameFromKey } from '../storage.helper';
import { MoveObjectsSelectFromList, SelectFolderType } from './MoveObjectsSelectFromList';

interface MoveObjectsModalProps {
    objects: StorageObjectListModel[];
    isVisible: boolean;
    onHide: () => void;
    onSuccess: () => void;
    paramsPath: string;
}

export const getCurrentLevel = (path: string) => {
    let splitArr = path.split('/').filter(x => x.length);
    if (!path.endsWith('/')) {
        return splitArr.slice(0, splitArr.length - 1).join('/') + '/';
    }
    return splitArr.join('/') + '/';
};

export const moveUpToOneLevel = (path: string) => {
    let splitArr = path.split('/').filter(x => x.length);
    return splitArr.slice(0, splitArr.length - 1).join('/');
};

export const MoveObjectsModal: React.FC<MoveObjectsModalProps> = ({
    isVisible,
    onHide,
    onSuccess,
    paramsPath,
    objects,
}) => {
    const currentPath = getCurrentLevel(paramsPath);
    const { t } = useTranslation();

    /**
     * Selectors
     */
    const {
        model: { objectListModel: models },
    }: StorageObjectListResponseModel = useSelector(objectsSelectors.getResponse);

    const folders = useMemo(() => {
        let _folder: SelectFolderType[] = [];
        const objectsKeys = objects.map(x => x.key);
        const canMoveUp = !!trim(currentPath, '/').length;

        if (canMoveUp) {
            _folder.push({
                key: moveUpToOneLevel(currentPath),
                cell: () => (
                    <>
                        <div className="px-2 pr-4">
                            <GjirafaIcon name="ArrowUpAlt" size={22} />
                        </div>
                        <span>{t('@move.up.one.level', { defaultValue: 'Up one level' })}</span>
                    </>
                ),
            });
        }

        models.forEach(({ key, isFolder }) => {
            if (isFolder && !objectsKeys.includes(key))
                _folder.push({
                    key,
                    cell: () => (
                        <>
                            <div className="px-2 pr-4">
                                <GjirafaIcon name="Folder" size={22} />
                            </div>
                            <span>{getNameFromKey(key)}</span>
                        </>
                    ),
                });
        });

        return _folder;
    }, [models, objects, currentPath]);

    /**
     * Local state
     */
    const [moveState, setMoveState] = useState<'from_path' | 'from_list'>(folders.length ? 'from_list' : 'from_path');
    const [folderSelected, setFolderSelected] = useState<string | null>(null);
    const [shouldMoveUp, setShouldMoveUp] = useState(false);
    const [pathFromUser, setPathFromUser] = useState(currentPath);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitUserPath = e => {
        e.preventDefault();
        let path = trim(pathFromUser, '/');
        onMove(removeDuplicatesCharsInside(path.length ? path + '/' : ''));
    };

    const onMove = async (path: string) => {
        let basePath = path.endsWith('/') ? path : path + '/';

        try {
            setIsLoading(true);
            const payload = {
                accountId: getAccountIdFromQuery(),
                keys: {},
                prefixes: {},
            };

            objects.forEach(({ key, isFolder }) => {
                if (isFolder)
                    payload.prefixes = {
                        ...payload.prefixes,
                        [key]: trimStart(`${basePath}${getNameFromKey(key)}/`, '/'),
                    };
                else
                    payload.keys = {
                        ...payload.keys,
                        [key]: trimStart(`${basePath}${getNameFromKey(key)}`, '/'),
                    };
            });

            await axios.put(`/storage/object`, payload);

            setIsLoading(false);
            toast.success(t('@objects.moved.successfully', { defaultValue: 'Object(s) moved successfully!' }));
            onSuccess();
            onHide();
        } catch (err) {
            showServerErrors(err);
            setIsLoading(false);
        }
    };

    const submitDisabled = useMemo(
        () => (moveState === 'from_list' ? folderSelected === null : false),
        [moveState, shouldMoveUp, folderSelected]
    );

    return (
        <CSSTransition in={isVisible} timeout={50} unmountOnExit={false}>
            {state => (
                <div
                    onClick={e => {
                        e.stopPropagation();
                        onHide();
                    }}
                    className={`fixed z-50 inset-0 overflow-y-auto bg-mainOverlay bg-opacity-50 flex justify-center items-center px-0 sm:px-6 py-10`}
                    style={{
                        transition: 'opacity 500ms ease-in-out',
                        opacity: 0,
                        ...modalOverlayAnimationStyles[state],
                    }}
                >
                    <div
                        className="z-50 bg-white rounded w-full  md:w-2/3 lg:w-2/3 xl:w-2/3 h-auto flex flex-col w-"
                        style={{ ...modalFadeTopAnimationStyle[state], width: 510, minHeight: 340 }}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="flex w-full px-3 py-3 justify-between items-center border-b border-mainBorder">
                            <span className="text-blueMainText font-medium text-base">{t('@move')}</span>

                            <div className="p-1 cursor-pointer" onClick={onHide}>
                                <GjirafaIcon name="Close" size={22} />
                            </div>
                        </div>
                        <div className="flex flex-1 flex-col w-full">
                            <SwitchTransition>
                                <CSSTransition
                                    key={moveState}
                                    addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                                    classNames="switchTop"
                                >
                                    {moveState === 'from_list' ? (
                                        <MoveObjectsSelectFromList
                                            folderSelected={folderSelected}
                                            setFolderSelected={_folder => setFolderSelected(_folder)}
                                            folders={folders}
                                        />
                                    ) : (
                                        <form
                                            id="moveToPathForm"
                                            className="p-4"
                                            onSubmit={onSubmitUserPath}
                                            autoComplete="off"
                                        >
                                            <InputField
                                                onChange={e => {
                                                    shouldMoveUp && setShouldMoveUp(false);
                                                    setPathFromUser(e.target.value);
                                                }}
                                                value={pathFromUser}
                                                label={t('@move.to', { defaultValue: 'Move to' })}
                                                placeholder={t('@enter.path.here', {
                                                    defaultValue: 'Enter path here...',
                                                })}
                                            />
                                        </form>
                                    )}
                                </CSSTransition>
                            </SwitchTransition>
                        </div>
                        <div className="px-3 py-4 flex w-full justify-between items-center border-t border-mainBorder">
                            {folders.length > 0 && (
                                <span
                                    onClick={() => setMoveState(p => (p === 'from_list' ? 'from_path' : 'from_list'))}
                                    className="text-mainBlue text-sm font-medium hover:underline cursor-pointer"
                                >
                                    {moveState === 'from_list'
                                        ? t('@enter.path', { defaultValue: 'Enter path' })
                                        : t('@select.from.list', { defaultValue: 'Select from list' })}
                                </span>
                            )}
                            <div className="flex flex-1 justify-end">
                                <Button
                                    onClick={onHide}
                                    title={t('@common.cancel')}
                                    className="mr-2 !text-blueMainText"
                                />
                                <CreateButton
                                    form="moveToPathForm"
                                    onClick={() => {
                                        if (moveState === 'from_list' && folderSelected !== null) {
                                            onMove(folderSelected);
                                        }
                                    }}
                                    title={t('@move')}
                                    disabled={isLoading || submitDisabled}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </CSSTransition>
    );
};
