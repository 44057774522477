export const scaleAnimationClassName = 'transition-all ease-linear duration-300 delay-100 hover:scale-102 transform';

export const englishVowels = ['a', 'e', 'i', 'o', 'u', 'y'];

export const buttonHoverClassName = 'transition-all ease-linear duration-300 hover:border-blueMainText ';
export const primaryButtonHoverClassName = 'transition-all ease-linear duration-300 hover:bg-primaryBlue600';
export const outlineButtonHoverClassName =
    'transition-all ease-linear duration-300 hover:border-primaryBlue500 hover:text-primaryBlue500';

export const inputAnimationClassName =
    'transition-all duration-300 ease-in focus:ring-1  focus:ring-primaryBlue500 hover:border-blueDark300';
