import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { NoDataImagesType, Table } from '../../../components/tables';
import { CREATE_DATASET_PATH } from '../../../constants';
import { queryParams } from '../../../utils';

export const DatasetPage = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const onClickCreate = () => history.push(CREATE_DATASET_PATH + queryParams.formatForNavigation());

    return (
        <div className="">
            <Table
                emptyDataProps={{
                    emptyText: t('@pages.workflow.no-datasets'),
                    actionMessage: `+ ${t('@pages.workflow.create-new-dataset')}`,
                    imageType: NoDataImagesType.Dataset,
                    onClickAction: onClickCreate,
                }}
                showHeaderButtons
                headerButtonProps={{
                    onClickCreate,
                }}
                columns={[]}
                modelEndpoint=""
                response={{
                    message: '',
                    models: [],
                    success: false,
                }}
            />
        </div>
    );
};
