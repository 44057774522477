import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
/**
 * Components
 */
import { UploadButton } from '../../../components/buttons';
import { RefreshButton } from '../../../components/buttons/RefreshButton';
import { Icon, IconTypes } from '../../../components/common';
import { SelectWithPopupField, SelectWithPopupTypes } from '../../../components/inputs';
import { DropzoneCardContainer } from '../../../components/cards';
import { Error404 } from '../../../components/errors';
import { NoDataImagesType, Table, TableActionRowProps, TableMobile } from '../../../components/tables';
import { toast } from '../../../components/ui';
import { artifactSupportedFiles, ARTIFACTS_PATH, breakpoints, SPECIAL_SPLIT_CHAR } from '../../../constants';
import { useAccountId, useCurrentWidth, useDetailsError } from '../../../hooks';
import {
    ArtifactDetailsModel,
    ArtifactDetailsResponseModel,
    ArtifactVersionListModel,
    ArtifactVersionListResponseModel,
    DetailsResponseModel,
} from '../../../models';
import { actions as artifactActions } from '../../../redux/thunk/app/artifact/artifactsThunk';
/**
 * Redux
 */
import {
    actions as artifactVersionsActions,
    selectors as artifactVersionsSelectors,
} from '../../../redux/thunk/app/artifact/artifactVersionsThunk';
import { actions as uploadArtifactActions } from '../../../redux/thunk/app/artifact/uploadArtifactThunk';
import axios from '../../../services/axios';
import {
    getFileExtension,
    getFormattedQuery,
    onDownloadFileFromUrl,
    queryParams,
    shouldUploadVersionToArtifact,
} from '../../../utils';
import { SetDefaultVersionPopup } from './components/SetDefaultVersionPopup';

export const ArtifactVersionsPage = ({}) => {
    const accountId = useAccountId();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const currentWidth = useCurrentWidth();
    const { id } = useParams<{ id: string }>();
    const { detailsError, handleError } = useDetailsError();

    /**
     * Redux Selector
     */
    const loading = useSelector(artifactVersionsSelectors.getIsLoading);
    const initLoading = useSelector(artifactVersionsSelectors.getInitialLoading);
    const response: ArtifactVersionListResponseModel = useSelector(artifactVersionsSelectors.getResponse);

    /**
     * Local State
     */
    const [versionSelected, setVersionSelected] = useState<ArtifactVersionListModel | null>(null);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [selectedArtifact, setSelectedArtifact] = useState<ArtifactDetailsModel | null>(null);

    const requestVersions = (query: any, defaultVersionId?: number, isPermanent?: boolean) => {
        dispatch(
            artifactVersionsActions.request(
                {
                    artifactId: id,
                    accountId,
                    ...query,
                },
                defaultVersionId || selectedArtifact?.defaultVersionId,
                isPermanent
            )
        );
    };

    useEffect(() => {
        dispatch(artifactActions.request({ accountId }));
    }, []);

    useEffect(() => {
        fetchVersions();
    }, [id, location, accountId]);

    const fetchVersions = async () => {
        try {
            setDetailsLoading(true);
            let { model }: ArtifactDetailsResponseModel = await axios.get(`/artifact?${getFormattedQuery({ id })}`);
            setSelectedArtifact(model);
            requestVersions({ page: 0 }, model.defaultVersionId, model.isPermanent);
        } catch (err: any) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onChangeArtifact = artifact => {
        history.push(`/data/artifacts/artifact-versions/${artifact?.value}` + queryParams.formatForNavigation());
    };

    const onRefresh = () => {
        !loading &&
            requestVersions(
                { page: response.currentPage },
                selectedArtifact?.defaultVersionId,
                selectedArtifact?.isPermanent
            );
    };

    const onPageChanged = page => requestVersions({ page });

    const onDownloadFile = async (row: ArtifactVersionListModel) => {
        try {
            let query: any = {
                accountId,
                artifactId: id?.split(SPECIAL_SPLIT_CHAR)[0],
                artifactVersionId: row.id,
            };

            let { model: presignedArtifactVersionUrl }: DetailsResponseModel<string> = await axios.get(
                `/artifact/presignedUrl?${getFormattedQuery(query)}`
            );

            onDownloadFileFromUrl(presignedArtifactVersionUrl);
        } catch (err: any) {}
    };

    const onUploadNewVersion = (files: File[] | FileList) => {
        if (!files.length || !selectedArtifact) {
            return;
        }

        let onSuccess = (artifact: ArtifactDetailsResponseModel) => {
            onSuccessSetDefault(artifact.model.defaultVersionId, artifact.model.isPermanent);
        };
        dispatch(
            uploadArtifactActions.upload(
                {
                    fileContents: files[0],
                    accountId,
                },
                {
                    id: selectedArtifact.id,
                    title: selectedArtifact.title,
                },
                onSuccess
            )
        );
    };

    const onSuccessSetDefault = (versionId: number, isPermanent: boolean) => {
        if (selectedArtifact) {
            let artifact: ArtifactDetailsModel = {
                ...selectedArtifact,
                defaultVersionId: versionId,
                isPermanent,
            };
            setSelectedArtifact(artifact);
            requestVersions({ page: response.currentPage }, versionId, isPermanent);
        }
    };

    const emptyDataProps = useMemo(
        () => ({
            imageType: NoDataImagesType.Artifact,
            emptyText: 'No versions for this artifact!',
            actionMessage: 'Go to artifacts',
            onClickAction: () => {
                history.push(ARTIFACTS_PATH + queryParams.formatForNavigation());
            },
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'download',
                tooltipMessage: 'Download file',
                onClick: onDownloadFile,
            },
            {
                action: 'default',
                tooltipMessage: 'Make default',
                onClick: (row: ArtifactVersionListModel) => {
                    setVersionSelected(row);
                },
            },
        ],
        []
    );

    if (detailsError.status === 404) {
        return <Error404 model="artifact" />;
    }

    return (
        <DropzoneCardContainer
            options={{
                multiple: false,
                accept: selectedArtifact ? '.' + getFileExtension(selectedArtifact.url) : artifactSupportedFiles,
                onDropRejected: files => {
                    if (files.length > 1) {
                        toast.error('Cannot upload multiple versions, please upload single file!');
                    } else {
                        toast.error(`"${files[0].file.type}" File type is not allowed!`);
                    }
                },
            }}
            onUploadFiles={files => {
                if (!selectedArtifact) {
                    return;
                }
                if (!shouldUploadVersionToArtifact(selectedArtifact?.url, files[0].name)) {
                    toast.error(`Cannot upload this file because is not ${getFileExtension(selectedArtifact.url)}!`);
                    return;
                }
                onUploadNewVersion(files);
            }}
            className="flex flex-col flex-1"
            cardOverlayProps={{
                message: 'Drop file to upload new version of artifact!',
            }}
        >
            <SetDefaultVersionPopup
                isPermanent={!!selectedArtifact?.isPermanent}
                artifactVersion={versionSelected}
                isVisible={!!versionSelected}
                onHide={() => setVersionSelected(null)}
                artifactId={selectedArtifact?.id}
                onSuccess={onSuccessSetDefault}
            />

            {/* <div className="flex flex-row w-full h-auto justify-between mb-6 flex-wrap-reverse">
                <div className="flex items-start flex-wrap-reverse">
                    <SelectWithPopupField
                        loading={detailsLoading}
                        disabled={detailsLoading}
                        selectedItem={
                            selectedArtifact
                                ? { value: selectedArtifact.id?.toString(), label: selectedArtifact.title }
                                : null
                        }
                        onChangeItem={onChangeArtifact}
                        fetchDataOnMount={false}
                        label="Artifact"
                        className="mr-2 mt-2"
                        style={{ width: 300 }}
                        placeholder={t('@common.choose-artifact')}
                        tableType={SelectWithPopupTypes.artifact}
                    />
                    <UploadButton
                        icon={<Icon name={IconTypes.upload} className="mr-2" />}
                        title={t('@common.artifact-upload-new-version')}
                        className="mt-2"
                        accept={
                            selectedArtifact ? '.' + getFileExtension(selectedArtifact.url) : artifactSupportedFiles
                        }
                        handleUpload={e => {
                            const files = e.target.files;
                            if (!files || files.length < 1 || !selectedArtifact) {
                                return;
                            }
                            if (!shouldUploadVersionToArtifact(selectedArtifact?.url, files[0].name)) {
                                toast.error(
                                    `Cannot upload this file because is not ${getFileExtension(selectedArtifact.url)}!`
                                );
                                return;
                            }

                            onUploadNewVersion(files);
                            e.target.value = '';
                        }}
                    />
                </div>

                <RefreshButton onRefresh={onRefresh} className="mb-0" style={{ margin: 0 }} />
            </div> */}
            {currentWidth > breakpoints.sm && (
                <Table
                    modelEndpoint="artifactVersions"
                    loading={loading || detailsLoading}
                    columns={[
                        {
                            selector: row => row?.url ?? '',
                            name: 'Url',
                            sortable: true,
                            cell: row => <a className="truncate">{row?.url}</a>,
                        },
                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.isDefault ?? '',
                            name: '',
                            width: '120px',
                            cell: (row: ArtifactVersionListModel) => {
                                if (row.isDefault) {
                                    return (
                                        <span className="text-mainBlue text-sm font-medium flex flex-col justify-center items-center w-full">
                                            default
                                            {row.isPermanent && (
                                                <span className="text-blueMainText text-xs">(permanent)</span>
                                            )}
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                    ]}
                    actions={tableActions}
                    response={response}
                    emptyDataProps={emptyDataProps}
                    pagination
                    paginationProps={paginationProps}
                />
            )}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    initialLoading={initLoading}
                    modelEndpoint="artifactVersions"
                    mainKeyProperty="url"
                    columns={[
                        {
                            selector: row => row?.url ?? '',
                            name: 'Url',
                            cell: row => (
                                <a
                                    data-tag="allowRowEvents"
                                    className="truncate mt-4 text-blueMainText"
                                    href={row?.url}
                                    target="_blank" rel="noreferrer"
                                >
                                    {row?.url}
                                </a>
                            ),
                        },
                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                        },
                        {
                            selector: row => row?.isDefault ?? '',
                            name: '',
                            width: '120px',
                            cell: (row: ArtifactVersionListModel) => {
                                if (row.isDefault) {
                                    return (
                                        <span className="text-mainBlue text-sm font-medium flex flex-col justify-center items-center w-full">
                                            default
                                            {row.isPermanent && (
                                                <span className="text-blueMainText text-xs">(permanent)</span>
                                            )}
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                    ]}
                    response={response}
                    actions={tableActions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </DropzoneCardContainer>
    );
};
