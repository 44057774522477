import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../../components/animation';
import {
    AliasInput,
    FooterButtons,
    HeaderForCreatePages,
    SectionContainerForCreatePages,
} from '../../../../components/common';
import { DetailsLoader } from '../../../../components/details-entity';
import { InputField, SelectField, SelectWithStoragePopupField, SwitchInput } from '../../../../components/inputs';
import { SelectOptionType, TaskTypeListResponseModel, UserDetailsResponseModel } from '../../../../models';
import { selectors as userSelectors } from '../../../../redux/thunk/app/user/userThunk';
import axios from '../../../../services/axios';
import { TaskInputs, TaskTypeSelectOption } from './components';
import { TaskOutputs } from './components/outputs/TaskOutputs';
import { useCreateTaskHook } from './hooks';

const commonStorageInputsProps = {
    showSelectedItemInsideInput: true,
    className: 'mt-4',
    options: {
        isFolder: false,
        multiple: false,
        inputInside: true,
    },
};

const formId = 'createNewTaskForm';

export const CreateTaskPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const isEditing = !!useParams<{ id: string }>().id;

    const [types, setTypes] = useState<SelectOptionType[]>([]);
    const [typesLoading, setTypesLoading] = useState(false);
    const {
        errors,
        formValues,
        loading,
        detailsLoading,
        inputs,
        outputs,
        setInputs,
        setOutputs,
        onChangeFormValues,
        onSubmit,
    } = useCreateTaskHook();

    const {
        model: { isAdmin },
    }: UserDetailsResponseModel = useSelector(userSelectors.getResponse);

    useEffect(() => {
        fetchTypes();
    }, []);

    const fetchTypes = async () => {
        try {
            setTypesLoading(true);
            const response: TaskTypeListResponseModel = await axios.get('/pipeline/task/type/list');
            setTypes(
                response.models.map(({ name, id, ...x }) => ({
                    label: name,
                    value: id.toString(),
                    ...x,
                }))
            );
        } catch (err) {
        } finally {
            setTypesLoading(false);
        }
    };

    const onClickCancel = () => history.goBack();

    if (detailsLoading) {
        return (
            <PageAnimationContainer>
                <DetailsLoader />
            </PageAnimationContainer>
        );
    }

    return (
        <PageAnimationContainer>
            <div className="flex flex-col">
                <form id={formId} className="flex flex-col items-center" onSubmit={onSubmit}>
                    <HeaderForCreatePages title={t('@common.basic-information')} className="flex flex-col" />
                    <SectionContainerForCreatePages className="flex flex-col">
                        <InputField
                            required
                            label={t('@common.title')}
                            name="taskTitle"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeFormValues('title', e.target.value)}
                            value={formValues.title}
                            errorMessage={errors['title']}
                        />
                        <SelectField
                            className="mt-4"
                            required
                            components={{ Option: TaskTypeSelectOption }}
                            options={types}
                            value={formValues.typeId}
                            onChange={item => onChangeFormValues('typeId', item)}
                            isLoading={typesLoading}
                            label={t('@task.type', { defaultValue: 'Task type' })}
                            errorMessage={errors['typeId']}
                            placeholder={t('@choose.task.type', { defaultValue: 'Choose Task Type' })}
                        />
                        <AliasInput
                            errorMessage={errors.alias}
                            isEditing={isEditing}
                            endpoint="/pipeline/task"
                            className="mt-4"
                            value={formValues.alias}
                            onChange={e => onChangeFormValues('alias', e.target.value)}
                        />
                        <SelectWithStoragePopupField
                            required
                            errorMessage={errors.scriptPath}
                            onChangeSelectedItems={items => onChangeFormValues('scriptPath', items?.[0])}
                            selectedItems={formValues?.scriptPath ? [formValues.scriptPath] : []}
                            label={t('@python.script', { defaultValue: 'Python Script' })}
                            placeholder={t('@choose.python.file', { defaultValue: 'Choose a python file...' })}
                            {...commonStorageInputsProps}
                        />
                        <SelectWithStoragePopupField
                            isClearable
                            onChangeSelectedItems={items => onChangeFormValues('requirementsPath', items?.[0])}
                            selectedItems={formValues.requirementsPath ? [formValues.requirementsPath] : []}
                            errorMessage={errors.requirementsPath}
                            label={t('@requirements.text.file', { defaultValue: 'Requirements Text File' })}
                            placeholder={t('@choose.text.file', { defaultValue: 'Choose a text file..' })}
                            {...commonStorageInputsProps}
                        />
                        {isAdmin && (
                            <SwitchInput
                                label={t('@global.task', { defaultValue: 'Global task' })}
                                className="mt-4"
                                value={formValues.isGlobal}
                                onChange={isGlobal => onChangeFormValues('isGlobal', isGlobal)}
                            />
                        )}
                    </SectionContainerForCreatePages>

                    <TaskInputs isEditing={isEditing} inputs={inputs} setInputs={setInputs} />
                    <TaskOutputs outputs={outputs} setOutputs={setOutputs} />

                    <HeaderForCreatePages title="" className="mt-10" hideIcon />
                    <FooterButtons
                        hideSave
                        onClickCancel={onClickCancel}
                        thirdButtonProps={{
                            type: 'submit',
                            form: formId,
                            loading: loading,
                            disabled: loading,
                        }}
                        thirdButtonTitle={t(`@${isEditing ? 'edit' : 'create'}.task`)}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
