import { CSSProperties, FC, HTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentWidth } from '../../../hooks';
import { actions as themeActions, selectors as themeSelectors } from '../../../redux/thunk/theme';
import { ItemProps, SidebarItem } from './SidebarItem';
export interface AdminSidebarMenuProps {
    ulProps?: HTMLAttributes<HTMLUListElement>;
    list: ItemProps[];
    style?: CSSProperties;
}

export const AdminSidebarMenu: FC<AdminSidebarMenuProps> = ({ list, style, ulProps }) => {
    const dispatch = useDispatch();
    const currentWidth = useCurrentWidth();

    const isMenuOpen = useSelector(themeSelectors.getIsMenuOpened);

    const onLinkClicked = () => {
        if (isMenuOpen && currentWidth < 900) {
            dispatch(themeActions.closeMenu());
        }
    };

    const toggleMenu = () => {
        dispatch(themeActions.toggleMenu());
    };

    return (
        <ul className="pt-2" {...ulProps} style={{ ...style }}>
            {list.map(({ nestedList, shouldRenderLink, ...rest }, key) => (
                <SidebarItem
                    key={key}
                    {...rest}
                    nestedList={nestedList}
                    onLinkClicked={onLinkClicked}
                    toggleMenu={toggleMenu}
                    isMenuOpen={isMenuOpen}
                />
            ))}
        </ul>
    );
};
