import { Dispatch } from 'redux';
import { NotificationListModel, NotificationListResponseModel, NotificationsStateType } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator, getAccountIdFromQuery, getFormattedQuery, getSearchParams } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/notification/notificationsThunk/REQUEST',
    SUCCESS: '@app/notification/notificationsThunk/SUCCESS',
    FAILURE: '@app/notification/notificationsThunk/FAILURE',
    READ_NOTIFICATION: '@app/notification/notificationsThunk/READ_NOTIFICATION',
    MARK_ALL_AS_READ: '@app/notification/notificationsThunk/MARK_ALL_AS_READ',
    SET_LAST_NOTIFICATION_RECEIVED: '@app/notification/notificationsThunk/SET_LAST_NOTIFICATION_RECEIVED',
};

/**
 * Initial state
 */

const initialState: NotificationsStateType = {
    isLoading: false,
    errorMessage: '',
    lastNotificationReceived: null,
    response: {
        success: false,
        message: '',
        models: [],
        query: {},
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.notifications.isLoading,
    getResponse: (state: RootState) => state.app.notifications.response,
    getErrorMessage: (state: RootState) => state.app.notifications.errorMessage,
    getLastNotificationReceived: (state: RootState) => state.app.notifications.lastNotificationReceived,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, READ_NOTIFICATION, MARK_ALL_AS_READ, SET_LAST_NOTIFICATION_RECEIVED } = actionTypes;

const Reducer = (state: NotificationsStateType = initialState, { type, payload }) => {
    switch (type) {
        case MARK_ALL_AS_READ:
            return {
                ...state,
                response: {
                    ...state.response,
                    models: state.response.models.map(x => ({ ...x, isRead: true })),
                },
            };
        case READ_NOTIFICATION:
            return {
                ...state,
                response: {
                    ...state.response,
                    models: state.response.models.map(model =>
                        model.id === payload.id
                            ? { ...model, isRead: payload?.isRead !== undefined ? payload?.isRead : true }
                            : model
                    ),
                },
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
                isInfiniteLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isInfiniteLoading: false,

                isLoading: false,
            };
        case SET_LAST_NOTIFICATION_RECEIVED:
            return {
                ...state,
                lastNotificationReceived: payload,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query?: any, resetQuery = false) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                dispatch(actionCreator(REQUEST));

                const previousQuery = getState().app.notifications.response.query ?? {};

                if (query?.page === undefined) {
                    query.page = previousQuery?.page || 0;
                }

                let searchParams = getSearchParams(previousQuery, query, resetQuery);

                const response: NotificationListResponseModel = await axios.get(
                    `/notification/user/list?${getFormattedQuery(searchParams)}`
                );
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
    appendNewNotification: function (item: NotificationListModel | null) {
        return (dispatch, getState: () => RootState) => {
            try {
                if (!item) return;

                dispatch(actionCreator(SET_LAST_NOTIFICATION_RECEIVED, item));

                const notificationResponse: NotificationListResponseModel = getState().app.notifications.response;
                let modifiedModels: NotificationListModel[] = [...notificationResponse.models];

                if (modifiedModels.length > 9) {
                    modifiedModels.pop();
                }
                modifiedModels.unshift(item);

                let response: NotificationListResponseModel = {
                    ...notificationResponse,
                    models: modifiedModels,
                };
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {}
        };
    },
    onMarkAllAsRead: function (callApi = true) {
        return async dispatch => {
            try {
                dispatch(actionCreator(MARK_ALL_AS_READ));
                if (callApi) {
                    await axios.patch('/notification/user/markAllAsRead?markAllAsRead=true');
                }
            } catch (err: any) {}
        };
    },
    readNotification: function ({ id, accountId }) {
        return async dispatch => {
            try {
                dispatch(actionCreator(READ_NOTIFICATION, { id }));
                let payload = {
                    id,
                    accountId: accountId ?? getAccountIdFromQuery(),
                    patch: [
                        {
                            op: 'replace',
                            path: 'isRead',
                            value: true,
                        },
                    ],
                };
                await axios.patch(`/notification/user`, payload);
            } catch (err: any) {
                dispatch(actionCreator(READ_NOTIFICATION, { id, isRead: false }));
            }
        };
    },
};
