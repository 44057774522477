import React, { forwardRef } from 'react';
import { Checkbox, RadioButton } from '../../buttons';

export interface SelectableRowComponentProps {
    componentType?: 'checkbox' | 'radio';
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    onClick?: any;
}

export const SelectableRowComponent: React.FC<SelectableRowComponentProps> = forwardRef(
    ({ componentType = 'checkbox', ...props }, ref) => {
        const isChecked = props?.checked;

        if (props?.disabled) {
            return null;
        }

        return (
            <div
                className="flex w-full h-full justify-center items-center cursor-pointer"
                onClick={() => {
                    props?.onClick && props.onClick();
                }}
            >
                {componentType === 'checkbox' ? (
                    <Checkbox isChecked={!!isChecked} />
                ) : componentType === 'radio' ? (
                    <RadioButton isChecked={!!isChecked} />
                ) : (
                    <></>
                )}
            </div>
        );
    }
);
