import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusImage } from '../../../../assets/icons/plus-main-color.svg';

type AddPaymentCardComponentProps = {
    onClick?: (e: any) => any;
    title?: string;
    className?: string;
    style?: CSSProperties;
};

export const AddPaymentCardComponent: React.FC<AddPaymentCardComponentProps> = ({
    onClick,
    style,
    className,
    title,
}) => {
    const { t } = useTranslation();

    return (
        <div
            onClick={onClick}
            className={`border border-mainBorder rounded flex w-full justify-center items-center py-4 bg-white cursor-pointer transition-colors duration-500 hover:bg-greyWhite200 ${className}`}
            style={style}
        >
            <PlusImage />
            <span className="text-blueMainText text-base ml-2 font-normal">{title || t('add.primary.card')}</span>
        </div>
    );
};
