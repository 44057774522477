import React from 'react';
import { RefreshButton } from '../buttons/RefreshButton';

// type TEntity = "customEndpoint" | "recommendationEngine" | "lookalikeEngine" | "dataSource";

interface IDetailsEmpty {
    onRefresh?: () => any;
    message?: string;
    title?: string;
}

export const DetailsEmpty = ({ title, message, onRefresh }: IDetailsEmpty) => {
    return (
        <div className="w-full h-auto flex flex-col items-center mt-4">
            <h3 className="text-blueMainText text-base mt-2 font-medium">{!title ? "Coudn't fetch data!" : title}</h3>
            {message && <p className="text-sm text-secondaryText mt-4">{message}</p>}
            {onRefresh && <RefreshButton className="mt-4" onRefresh={onRefresh} />}
        </div>
    );
};
