import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';

interface IAddButtonProps {
    title: string;
    onClick: () => void;
    className?: string;
}

export const AddButton = ({ onClick, title, className }: IAddButtonProps) => {
    return (
        <button type="button" onClick={onClick} className={`flex py-2 w-fit pr-2 ${className}`}>
            <span className="text-mainBlue pr-1">
                <GjirafaIcon name="Plus" size={20} />
            </span>
            <h2 className="text-mainBlue text-sm font-medium">{title}</h2>
        </button>
    );
};
