import { CSSProperties, DOMAttributes, ReactNode } from 'react';

interface IClickableProps {
    onClick?: DOMAttributes<HTMLDivElement>['onClick'];
    className?: string;
    style?: CSSProperties;
    children: ReactNode;
}

export const Clickable = ({ onClick, children, className, style, ...props }: IClickableProps) => (
    <div onClick={onClick} className={`cursor-pointer ${className}`} style={style} {...props}>
        {children}
    </div>
);
