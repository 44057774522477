import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAccountId } from '../../hooks';
import { AccountDetailsModel, AccountPostResponseModel } from '../../models';
/**
 * Redux
 */
import { actionTypes as accountDetailsTypes } from '../../redux/thunk/app/account/accountDetailsThunk';
import { actions as accountsActions } from '../../redux/thunk/app/account/accountsThunk';
import axios from '../../services/axios';
import { actionCreator, deleteErrorKey } from '../../utils';
import { UploadAccountImage } from '../account/UploadAccountImage';
/**
 * Components
 */
import { Button, CreateButton } from '../buttons';
import { AddressFormInputsType, BillingAddressForm } from '../forms';
import { InputField } from '../inputs';
import { Loader, toast } from '../ui';

export interface EditAccountProps {
    isLoading?: boolean;
    errorMessage?: string;
    account?: AccountDetailsModel;
}

type FieldValuesType = {
    name: string;
    relativeUrl: string;
};

const defaultAddressValue = {
    addressLine1: '',
    city: '',
    country: '',
    zipCode: '',
    addressLine2: '',
    region: '',
};

export const EditAccountCard: React.FC<EditAccountProps> = ({ isLoading, account }) => {
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [fieldValues, setFieldValues] = useState<FieldValuesType>({
        relativeUrl: '',
        name: '',
    });
    const [addressValues, setAddressValues] = useState<AddressFormInputsType>({
        ...defaultAddressValue,
    });
    const [startedEditing, setStartedEditing] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (account) {
            setFieldValues({
                name: account.name,
                relativeUrl: account.imageUrl,
            });
            const accountAddress = account.address;
            if (accountAddress) {
                const { country, city, addressLine2, state, addressLine1, postalCode } = accountAddress;
                setAddressValues({
                    addressLine1,
                    addressLine2,
                    country,
                    city,
                    zipCode: postalCode,
                    region: state,
                });
            } else {
                setAddressValues({ ...defaultAddressValue });
            }
        }
    }, [account]);

    const onChangeFieldValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
        !startedEditing && setStartedEditing(true);
    };

    const onChangeAddressValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setAddressValues(prev => ({
            ...prev,
            [key]: value,
        }));
        !startedEditing && setStartedEditing(true);
    };

    const isValid = () => {
        let valid = true;
        let _errors: any = {};

        if (!fieldValues.name) {
            _errors.name = t('@validations.field.isRequired', { field: t('@common.name') });
            valid = false;
        }

        setErrors(_errors);
        return valid;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!startedEditing || !isValid() || editLoading) return;

        try {
            setEditLoading(true);

            const { relativeUrl, name } = fieldValues;
            const { addressLine1, region, addressLine2, zipCode, country, city } = addressValues;

            let payload: any = {
                id: accountId?.toString(),
                name,
                address: {
                    id: account?.address?.id || 0,
                    accountId,
                    addressLine1,
                    addressLine2: addressLine2 || '',
                    city,
                    country,
                    postalCode: zipCode,
                    state: region || '',
                },
            };
            if (account?.stripeCustomerId) payload.stripeCustomerId = account.stripeCustomerId;

            if (relativeUrl) {
                let _url = relativeUrl?.split('.tech')[1] || '';
                if (_url) payload.relativeUrl = _url;
            }

            const response: AccountPostResponseModel = await axios.post('/account', payload);

            toast.success(
                t('@toast-messages.success-edit-account', {
                    defaultValue: 'Account has been Edited!',
                })
            );
            dispatch(actionCreator(accountDetailsTypes.SUCCESS, response));
            dispatch(accountsActions.request());
            setStartedEditing(false);
        } catch (err) {
        } finally {
            setEditLoading(false);
        }
    };

    const formId = 'EditAccount';

    return (
        <>
            {isLoading ? (
                <div className="w-full flex justify-center items-center" style={{ height: 60 }}>
                    <Loader color="#1D79F2" />
                </div>
            ) : (
                <form id={formId} className="border border-mainBorder rounded" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap">
                        <div className={`flex flex-1 flex-col justify-between sm:pr-2 sm:pl-6 p-4`}>
                            <UploadAccountImage
                                onChangeRelativeUrl={url => onChangeFieldValues('relativeUrl', url)}
                                relativeUrl={fieldValues.relativeUrl}
                                className="mt-4 sm:pt-0"
                            />
                            <div className="flex flex-1 flex-col mt-3">
                                <InputField
                                    label={t('@common.name')}
                                    required
                                    className="mt-4"
                                    maxLength={40}
                                    value={fieldValues.name}
                                    onChange={e => onChangeFieldValues('name', e.target.value)}
                                    placeholder={t('@common.write.here')}
                                    errorMessage={errors?.name}
                                />
                            </div>
                        </div>

                        <div className=" border-grey200 sm:pr-6 sm:pl-2 p-4 flex-1">
                            <span className="text-sm text-blueMainText font-medium">Address Information</span>
                            <BillingAddressForm
                                errors={errors}
                                areFieldsRequired={false}
                                formValues={addressValues}
                                onChangeFormInputs={onChangeAddressValues}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            padding: 24,
                            borderTop: '1px solid #EAEAEA',
                            height: 82,
                        }}
                        className="min-h-82 h-82 max-h-auto flex flex-row justify-end items-center"
                    >
                        {startedEditing ? (
                            <CreateButton
                                form={formId}
                                htmlType="submit"
                                disabled={isLoading || editLoading}
                                onClick={handleSubmit}
                                loading={editLoading}
                                title={t('@common.save-changes')}
                            />
                        ) : (
                            <Button
                                title={t('@common.save-changes')}
                                disabled
                                className="cursor-not-allowed"
                                onClick={() => {}}
                            />
                        )}
                    </div>
                </form>
            )}
        </>
    );
};
