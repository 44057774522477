import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { trimEnd, trimStart } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Icon, IconTypes } from '../../../../components/common';
import { Loader } from '../../../../components/ui';
import { removeDuplicatesCharsInside } from '../../../../utils';

interface SearchKeysComponentProps {
    isLoading: boolean;
    onSubmit: (key: string) => any;
    paramsPath: string;
}

const formId = 'changeKeyForm';

export const SearchKeysComponent: React.FC<SearchKeysComponentProps> = ({ isLoading = true, onSubmit, paramsPath }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);

    const keys = paramsPath.split('/').filter(x => x.length) || [];
    let mouseOverHideTimeout: any = null;

    const { register, handleSubmit, setValue } = useForm<{ keyValue: string }>();

    const onClickStartEditing = () => {
        if (isLoading) {
            return;
        }
        setValue('keyValue', paramsPath || '');
        setIsEditing(true);
    };

    const onCancelEditing = () => setIsEditing(false);

    const onClickSpecificKey = async (_key: string) => {
        let keyIndex = keys.indexOf(_key);
        let submitKey = keys.slice(0, keyIndex + 1).join('/');
        onSubmit(submitKey.endsWith('/') ? submitKey : submitKey + '/');
    };

    const onFormSubmit = async (data: { keyValue: string }) => {
        let _key = trimStart(data.keyValue, '/');

        if (_key.endsWith('/')) {
            _key = trimEnd(_key, '/') + '/';
        }

        onSubmit(removeDuplicatesCharsInside(_key));
        setIsEditing(false);
    };

    const renderKeys = () => {
        if (keys.length < 1) {
            return <span className={`text-greyBlack300 mr-2 text-sm cursor-text`}>...</span>;
        }
        return keys.map((currentKey, index) => {
            let isLast = index === keys.length - 1;

            return (
                <div className="flex" key={currentKey + index}>
                    <span
                        onClick={e => {
                            e.stopPropagation();
                            if (isLoading) return;
                            return isLast ? onClickStartEditing() : onClickSpecificKey(currentKey);
                        }}
                        className={`text-sm ${
                            isLast
                                ? `text-greyBlack300 mr-2 cursor-text ${isMouseOver && 'underline'}`
                                : 'text-mainBlue cursor-pointer'
                        }`}
                    >
                        {currentKey}
                    </span>
                    {!isLast && <Icon name={IconTypes['chevron-right']} className="" />}
                </div>
            );
        });
    };

    return (
        <div
            className="flex flex-1 items-center text-sm py-1 px-2 pr-10 hover:cursor-text flex-wrap"
            onClick={onClickStartEditing}
            onMouseOver={() => {
                if (isLoading) return;

                clearTimeout(mouseOverHideTimeout);
                !isMouseOver && setIsMouseOver(true);
            }}
            onMouseOut={() => {
                mouseOverHideTimeout = setTimeout(() => setIsMouseOver(false), 400);
            }}
        >
            {isEditing ? (
                <form autoComplete="off" id={formId} onSubmit={handleSubmit(onFormSubmit)} className="flex flex-1">
                    <input
                        {...register('keyValue')}
                        className="flex flex-1 text-sm text-greyBlack300 h-5 outline-none"
                        form={formId}
                        autoFocus
                        onBlur={onCancelEditing}
                        placeholder="..."
                    />
                </form>
            ) : (
                <>
                    {renderKeys()} {isMouseOver && <GjirafaIcon name="Edit" size={18} />}
                </>
            )}
            {isLoading && <Loader size={20} className="ml-2" />}
        </div>
    );
};
