import { addDays } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VoucherDetailsResponseModel, VoucherListModel } from '../../models';
import axios from '../../services/axios';
import { deleteErrorKey, getFormattedQuery } from '../../utils';
import { InfoIconWithModal } from '../common/dynamic-inputs/InfoIconWithModal';
import { InputField, TextareaField } from '../inputs';
import { DatePickerInput } from '../inputs/DatePickerInput';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateVoucherModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    itemForEdit?: VoucherListModel | null;
    onSuccess: (isEditing: boolean) => any;
}

type fieldValuesType = {
    code: string;
    description: string;
    expirationDate?: string;
};

export const CreateVoucherModal: React.FC<CreateVoucherModalProps> = ({
    isVisible,
    onHide,
    isEditing,
    onSuccess,
    itemForEdit,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();

    /**
     * Local State
     */
    const [loading, setLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [fieldValues, setFieldValues] = useState<fieldValuesType>({
        code: '',
        description: '',
        expirationDate: '',
    });

    useEffect(() => {
        if (isEditing && itemForEdit) {
            fetchDetails(itemForEdit.id);
        }
    }, [isEditing, itemForEdit]);

    const fetchDetails = async id => {
        try {
            setDetailsLoading(true);
            let query = {
                id,
            };
            const {
                model: { code, description, expirationDate },
            }: VoucherDetailsResponseModel = await axios.get(`/voucher?${getFormattedQuery(query)}`);
            setFieldValues({
                code,
                description,
                expirationDate,
            });
        } catch (err: any) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const { isValid } = onValidateForm();
            if (!isValid) {
                return;
            }

            setLoading(true);
            const { expirationDate, description, code } = fieldValues;

            let payload: any = {
                id: isEditing ? itemForEdit?.id : 0,
                code,
                expirationDate,
                description,
            };

            if (isEditing && itemForEdit && itemForEdit.accountId) {
                payload.accountId = itemForEdit.accountId;
            }

            await axios.post('/voucher', payload);
            onSuccess(!!isEditing);
        } catch (err: any) {
        } finally {
            setLoading(false);
        }
    };

    const onChangeInputs = (key, value) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({ ...prev, [key]: value }));
    };

    const onValidateForm = () => {
        return { isValid: true };
    };

    const { formId, title, buttonText } = useMemo(() => {
        return {
            formId: isEditing ? 'editVoucher' : 'addVoucher',
            title: isEditing ? 'Edit Voucher' : 'Create Voucher',
            buttonText: isEditing ? t('@common.edit') : t('@common.create'),
        };
    }, [isEditing]);

    return (
        <>
            <SideModal
                loading={loading}
                isVisible={isVisible}
                onHide={onHide}
                title={title}
                buttonText={buttonText}
                formId={formId}
            >
                <form onSubmit={handleSubmit} id={formId} className="flex flex-col h-full border-solid px-4 py-6">
                    {detailsLoading ? (
                        <div className="py-12 w-full flex items-center justify-center">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <InputField
                                disabled={isEditing}
                                label="Code"
                                placeholder={t('@common.write.here')}
                                maxLength={10}
                                value={fieldValues.code}
                                onChange={e => !isEditing && onChangeInputs('code', e.target.value)}
                                errorMessage={errors?.code}
                                labelRight={<InfoIconWithModal infoLink="" infoText="Maximum 10 characters" />}
                            />

                            <TextareaField
                                label="Description"
                                placeholder={t('@common.write.here')}
                                className="mt-4"
                                value={fieldValues.description}
                                onChange={e => onChangeInputs('description', e.target.value)}
                                errorMessage={errors?.description}
                            />
                            <DatePickerInput
                                onChange={date => onChangeInputs('expirationDate', date)}
                                value={fieldValues.expirationDate}
                                className="mt-4"
                                label={t('expiration.date')}
                                placeholder={t('pick.date')}
                                minDate={addDays(new Date(), 1)}
                                showTimeInput={false}
                                showTimeSelect={false}
                                errorMessage={errors?.description}
                                dateFormat="dd MMMM yyyy"
                            />
                        </>
                    )}
                </form>
            </SideModal>
        </>
    );
};
