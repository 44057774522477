import { Dispatch } from 'redux';
import { toast } from '../../../components/ui';
import { BiskoInitialStateType, BiskoPropertyListDataModel, BiskoPropertyListResponseModel } from '../../../models';
import axios from '../../../services/axios';
import { actionCreator, formatBiskoError, getAccountIdFromQuery } from '../../../utils';
import { RootState } from '../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@bisko/biskoPropertiesThunk/REQUEST',
    SUCCESS: '@bisko/biskoPropertiesThunk/SUCCESS',
    FAILURE: '@bisko/biskoPropertiesThunk/FAILURE',
};

/**
 * Initial state
 */
const initialState: BiskoInitialStateType<BiskoPropertyListDataModel> = {
    isLoading: false,
    error: null,
    data: {
        canAddProperty: false,
        organizationCode: '',
        organizationId: 0,
        organizationName: '',
        properties: [],
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.bisko.properties.isLoading,
    getData: (state: RootState) => state.bisko.properties.data,
    getError: (state: RootState) => state.bisko.properties.error,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (state: BiskoInitialStateType<BiskoPropertyListDataModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const accountId = getAccountIdFromQuery();
                const response: BiskoPropertyListResponseModel = await axios.get(
                    `/bridge/bisko/property/list?accountId=${accountId}`
                );

                if (!response?.success) {
                    toast.error(response.message);
                    dispatch(actionCreator(FAILURE, { status: response.statusCode, message: response.message }));
                    return;
                }

                dispatch(actionCreator(SUCCESS, response.data));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, formatBiskoError(err)));
            }
        };
    },
};
