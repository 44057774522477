import { format } from 'date-fns';
import { date } from 'yup';

export const formatDateAndTime = (date: string, formatter = 'dd.MM.yyyy - HH:mm') => {
    try {
        let _date = new Date(date);
        return format(_date, formatter);
    } catch (err: any) {
        return date;
    }
};

export const formatDateAndTime2 = (date: string, formatter = 'HH:mm:ss') => {
    try {
        let _date = new Date(date);
        return format(_date, formatter);
    } catch (err: any) {
        return date;
    }
};

export const getInterval = (firstDate: string, secondDate: string) => {
    try {
        if (firstDate === '') return '<1s';

        let _firstDate = new Date(firstDate);

        if (secondDate === '') return new Date().getDate() - _firstDate.getDate();

        let _secondDate = new Date(secondDate);

        var difference = Math.floor(_secondDate.getTime() / 1000) - Math.floor(_firstDate.getTime() / 1000);
        return fancyTimeFormat(difference);
    } catch (err: any) {}
};

function fancyTimeFormat(duration) {
    var hours = ~~(duration / 3600);
    var minutes = ~~((duration % 3600) / 60);
    var seconds = ~~duration % 60;
    var response = '';

    if (hours > 0) {
        response += `${hours}h `;
    }
    if (minutes > 0) {
        response += `${minutes}m `;
    }
    if (seconds === 0) {
        response += `<1s`;
    } else {
        response += `${seconds}s`;
    }

    return response;
}

export const getUnixTimestamp = () => Math.floor(new Date().getTime() / 1000);
