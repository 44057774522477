import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CreateButton } from '../../buttons';
import { RefreshButton } from '../../buttons/RefreshButton';
import { Icon, IconTypes } from '../../common';

export interface HeaderButtonsProps {
    showCreate?: boolean;
    showEdit?: boolean;
    showDelete?: boolean;
    showIsActive?: boolean;
    showRefresh?: boolean;
    multiSelectActive?: boolean;
    onClickCreate?: (e: any) => any;
    onClickEdit?: (e: any) => any;
    onClickDelete?: (e: any) => any;
    onClickActive?: (e: any) => any;
    createTitle?: string;
    onRefresh?: (e?: any) => any;
    rightButtons?: ReactNode;
    leftButtons?: ReactNode;
    style?: CSSProperties;
    className?: string;
    leftButtonsAfterCreate?: boolean;
}

export const HeaderButtons: React.FC<HeaderButtonsProps> = ({
    createTitle,
    showCreate = true,
    showDelete = false,
    showEdit = true,
    showRefresh = true,
    multiSelectActive,
    rightButtons,
    onClickCreate,
    onClickDelete,
    onClickEdit,
    onRefresh,
    onClickActive,
    showIsActive,
    leftButtons,
    leftButtonsAfterCreate,
    className,
    style,
}) => {
    const { t } = useTranslation();

    const buttonStyles = useMemo(() => {
        return {
            marginRight: 8,
        };
    }, []);

    return (
        <div className={`flex flex-row w-full justify-between items-start flex-wrap ${className}`} style={{ ...style }}>
            <div className="flex flex-row flex-1 justify-start items-start flex-wrap mb-3 ">
                {showCreate && (
                    <CreateButton
                        style={buttonStyles}
                        icon={<Icon name={IconTypes.plus} className="mr-2" size={18} />}
                        title={createTitle}
                        onClick={e => onClickCreate && onClickCreate(e)}
                        className="mb-3"
                    />
                )}
                {!!leftButtonsAfterCreate && leftButtons}
                {showEdit && (
                    <Button
                        style={buttonStyles}
                        title={t('@common.edit')}
                        onClick={e => onClickEdit && onClickEdit(e)}
                        className="mb-3"
                    />
                )}
                {showDelete && (
                    <Button
                        style={{ ...buttonStyles, cursor: !multiSelectActive ? 'default' : 'pointer' }}
                        disabled={!multiSelectActive}
                        title={t('@common.delete')}
                        onClick={e => onClickDelete && onClickDelete(e)}
                        className={`mb-3 transition-all duration-300 ${
                            !multiSelectActive ? 'opacity-50' : '!text-blueMainText'
                        }`}
                    />
                )}
                {showIsActive && (
                    <Button
                        style={{ ...buttonStyles, cursor: !multiSelectActive ? 'default' : 'pointer' }}
                        disabled={!multiSelectActive}
                        title={t('@common.delete')}
                        onClick={e => onClickActive && onClickActive(e)}
                        className={`mb-3 transition-all duration-300 ${
                            !multiSelectActive ? 'opacity-50' : '!text-blueMainText'
                        }`}
                    />
                )}
                {!leftButtonsAfterCreate && leftButtons}
            </div>
            <div className="flex flex-row items-start">
                {rightButtons}
                {showRefresh && <RefreshButton onRefresh={e => onRefresh && onRefresh(e)} />}
            </div>
        </div>
    );
};
