export enum LocalStorageKeys {
    MuteNotifications = 'MuteNotifications',
    Language = '@language',
    ApiKey = 'apiKey',
    Token = 'oidc.user:porta:aihub_react',
}

export const deviceLocalStorage = {
    setItem: (key: LocalStorageKeys, value: string) => {
        localStorage.setItem(key, value);
    },
    getItem: (key: LocalStorageKeys) => localStorage.getItem(key),
    removeItem: (key: LocalStorageKeys) => localStorage.removeItem(key),
};
