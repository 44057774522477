import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../config/i18n';
import { useAccountId } from '../../hooks';
import {
    BaseInstanceTypeListModel,
    CustomEndpointDetailsResponseModel,
    CustomEndpointInstanceTypeListModel,
    CustomEndpointPostModel,
    Nullable,
    SelectOptionType,
} from '../../models';
import axios from '../../services/axios';
import AXIOS from 'axios'
import { deleteErrorKey, formatArrayForSelectOptions, isValidNumber } from '../../utils';
import { customEndpointFormSchema, onValidateWithYup } from '../../validations';
import { AliasInput } from '../common/AliasInput';
import { InfoIconWithModal } from '../common/dynamic-inputs/InfoIconWithModal';
import { InputField, SelectField, SelectWithStoragePopupField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader, toast } from '../ui';

type TFormFields = {
    title: string;
    scriptPath: Nullable<SelectOptionType>;
    requirementsPath?: Nullable<SelectOptionType>;
    instanceTypeId: Nullable<SelectOptionType>;
    instanceCount: string;
    alias: string;
};
interface ICreateCustomEndpointModalProps {
    isVisible: boolean;
    onHide: () => any;
    idForEdit?: number | null;
    onSuccess?: () => any;
    instanceTypes: CustomEndpointInstanceTypeListModel[];
    instanceTypesLoading: boolean;
}

const commonStorageInputProps = {
    showSelectedItemInsideInput: true,
    options: {
        isFolder: false,
        multiple: false,
        inputInside: true,
    },
};

export const CreateCustomEndpointModal = ({
    isVisible,
    onHide,
    idForEdit,
    onSuccess,
    instanceTypesLoading,
    instanceTypes,
}: ICreateCustomEndpointModalProps) => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const isEditing = !!idForEdit;
    const SchemaValidation = useMemo(
        () => i18n.isInitialized && !!t && customEndpointFormSchema(t),
        [i18n.isInitialized, t]
    );

    const [errors, setErrors] = useState<any>({});
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formFields, setFormFields] = useState<TFormFields>({
        title: '',
        scriptPath: null,
        requirementsPath: null,
        instanceTypeId: null,
        instanceCount: '1',
        alias: '',
    });

    useEffect(() => {
        if (!instanceTypesLoading && idForEdit) fetchDetails();
    }, [idForEdit, accountId, instanceTypesLoading]);

    const { REACT_APP_PYTHON_VALIDATOR_URL } = process.env;
    const Axios = AXIOS.create({
        baseURL: REACT_APP_PYTHON_VALIDATOR_URL
    });
    AXIOS.defaults.headers.post['Content-Type'] = 'application/json';

    const fetchDetails = async () => {
        try {
            setDetailsLoading(true);

            const {
                model: { alias, instanceCount, instanceTypeId, scriptPath, requirementsPath, title },
            }: CustomEndpointDetailsResponseModel = await axios.get(
                `/customEndpoint?id=${idForEdit}&accountId=${accountId}`
            );

            const instanceType: Nullable<BaseInstanceTypeListModel> =
                instanceTypes.find(x => x.id === instanceTypeId) ?? null;

            setFormFields({
                title,
                scriptPath: { label: scriptPath, value: scriptPath },
                requirementsPath: requirementsPath ? { label: requirementsPath, value: requirementsPath } : null,
                instanceCount: instanceCount?.toString(),
                alias,
                instanceTypeId: instanceType ? { label: instanceType.name, value: instanceType.id?.toString() } : null,
            });
        } catch (err) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!SchemaValidation || formLoading) {
            return;
        }

        try {
            const payload: CustomEndpointPostModel = {
                id: isEditing ? idForEdit : 0,
                alias: formFields.alias,
                accountId,
                title: formFields.title,
                instanceCount: parseInt(formFields.instanceCount) || 0,
                instanceTypeId: parseInt(formFields.instanceTypeId?.value ?? '0'),
                scriptPath: formFields.scriptPath?.value ?? '',
                requirementsPath: formFields.requirementsPath?.value,
            };

            const { errors, isValid } = await onValidateWithYup(SchemaValidation, { ...payload });
            setErrors(errors);

            if (!isValid) return;
            setFormLoading(true);

            await axios.post('/customEndpoint', payload);

            toast.success(
                t(`@toast-messages.success-${isEditing ? 'edit' : 'create'}-endpoint`, {
                    defaultValue: `Endpoint has been ${isEditing ? 'updated' : 'created'}!`,
                })
            );

            onSuccess && onSuccess();
            onHide();
        } catch (err) {
            setFormLoading(false);
        }
    };

    const onValidateScript = async (_value: any) => {
            await Axios.post('/validate_serve',{
                account_id: accountId,
                script_path: _value.value
            }).then(res => {
                if(res.data) {
                    console.log(res.data);
                }
            }).catch(err => {
                console.log({err})
                setErrors({scriptPath: typeof err.response.data.detail === 'object' ? `${Object.values(err.response.data.detail)[0]}` : err.response.data.detail || 'An error has occurred.'});
            });
    };


    const onChangeFormValues = (key: keyof TFormFields, value: any) => {
        deleteErrorKey(key, setErrors);
        if(key === 'scriptPath'){
            onValidateScript(value);
        }
        setFormFields(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const formId = isEditing ? 'editCustomEndpointForm' : 'createCustomEndpointForm';
    const title = t(`@pages.${isEditing ? 'edit' : 'create-new'}-customEndpoint`);
    const buttonText = t(`@common.${isEditing ? 'edit' : 'create'}`);
    const instanceTypesOptions = useMemo(
        () => formatArrayForSelectOptions(instanceTypes, 'name', 'id', true),
        [instanceTypes]
    );

    return (
        <SideModal
            onHide={onHide}
            isVisible={isVisible}
            title={title}
            buttonText={buttonText}
            formId={formId}
            loading={formLoading}
        >
            <form id={formId} onSubmit={handleSubmit} className="px-4 py-6">
                {detailsLoading ? (
                    <div className="py-12 w-full flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <InputField
                            required
                            name="endpoint_title"
                            label={t('@common.title')}
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeFormValues('title', e.target.value)}
                            errorMessage={errors?.title}
                            value={formFields.title}
                        />

                        <AliasInput
                            isEditing={isEditing}
                            className="mt-4"
                            endpoint="/customEndpoint"
                            onChange={e => onChangeFormValues('alias', e.target.value)}
                            value={formFields.alias}
                        />

                        <SelectWithStoragePopupField
                            required
                            className="mt-4"
                            label={t('@python.script', { defaultValue: 'Python Script' })}
                            placeholder={t('@choose.python.file', { defaultValue: 'Choose a python file...' })}
                            onChangeSelectedItems={items => onChangeFormValues('scriptPath', items[0] ?? null)}
                            selectedItems={formFields.scriptPath ? [formFields.scriptPath] : []}
                            errorMessage={errors['scriptPath']}
                            {...commonStorageInputProps}
                            />

                        <SelectWithStoragePopupField
                            className="mt-4"
                            isClearable
                            label={t('@requirements.text.file', { defaultValue: 'Requirements Text File' })}
                            placeholder={t('@choose.text.file', { defaultValue: 'Choose a text file..' })}
                            onChangeSelectedItems={items => onChangeFormValues('requirementsPath', items[0] ?? null)}
                            selectedItems={formFields.requirementsPath ? [formFields.requirementsPath] : []}
                            errorMessage={errors?.requirementsPath}
                            {...commonStorageInputProps}
                        />

                        <SelectField
                            required
                            labelRight={
                                formFields.instanceTypeId?.description ? (
                                    <InfoIconWithModal infoText={formFields.instanceTypeId?.description} />
                                ) : null
                            }
                            value={formFields.instanceTypeId}
                            onChange={item => onChangeFormValues('instanceTypeId', item)}
                            label={t('instance.type')}
                            placeholder={t('choose.an.instanceType')}
                            errorMessage={errors['instanceTypeId']}
                            className="mt-4"
                            options={instanceTypesOptions}
                            isLoading={instanceTypesLoading}
                        />
                        <InputField
                            required
                            className="mt-4"
                            value={formFields.instanceCount}
                            onChange={({ target: { value } }) =>
                                isValidNumber(value) && onChangeFormValues('instanceCount', value)
                            }
                            placeholder={t('@common.write.here')}
                            label={t('number.of.instances')}
                            errorMessage={errors['instanceCount']}
                        />
                    </>
                )}
            </form>
        </SideModal>
    );
};
