import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { FixedLoader } from '../../components/ui';

interface ILoginLoadingProps {
    text: string;
}

export const LoginLoading = ({ text }: ILoginLoadingProps) => {
    const history = useHistory();

    const reloadPage = () => {
        history.replace('/');
        window.location.reload();
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            reloadPage();
        }, 12000);

        return () => clearTimeout(timer);
    }, [history]);

    useEffect(() => {
        console.log(window.location);
        if (window.location.search.includes('error=')) {
            reloadPage();
        }
    }, [window.location.search]);

    return (
        <FixedLoader>
            <div className="mt-2 text-blueMainText font-normal text-lg">{text}</div>
        </FixedLoader>
    );
};
