import { useTranslation } from 'react-i18next';
import { Button, CreateButton } from '../../../../components/buttons';
import { BasePopup } from '../../../../components/modals';

export const DeleteCardConfirmPopup = ({
    cardIdToDelete,
    onHide,
    isDeletingLastCard,
    currentCost,
    deleteLoading,
    onSubmitPayAndDelete,
}) => {
    const { t } = useTranslation();

    return (
        <BasePopup isVisible={!!cardIdToDelete} onHide={onHide}>
            <div
                className="bg-white p-6 rounded flex flex-col"
                style={{ minWidth: '500px', width: '500px', maxWidth: '100%' }}
            >
                <span className="text-greyBlack300 text-base font-medium mb-6">{t('@confirm-delete.card')}</span>

                {isDeletingLastCard && parseFloat(currentCost) > 0 && (
                    <span className="text-sm text-secondaryText font-medium">You have {currentCost} EUR debt!</span>
                )}
                <span className="text-sm text-secondaryText font-medium">
                    {isDeletingLastCard
                        ? `Deleting your last card will deactivate your Account if you don't have voucher code!`
                        : 'Are you sure you want to delete this card!'}
                </span>
                <div className="w-full flex justify-end items-center mt-6">
                    <Button
                        title={t('@common.cancel')}
                        className="mr-2"
                        onClick={() => {
                            onHide();
                        }}
                    />
                    <CreateButton
                        title={
                            isDeletingLastCard
                                ? `${parseFloat(currentCost) > 0 ? `${t('pay.now')} ${t('and')} ` : ''}${t(
                                      'deactivate.account'
                                  )}`
                                : t('@common.delete')
                        }
                        disabled={deleteLoading}
                        loading={deleteLoading}
                        className="w-auto bg-deleteColor text-white"
                        onClick={onSubmitPayAndDelete}
                        style={{
                            backgroundColor: '#E34850',
                        }}
                    />
                </div>
            </div>
        </BasePopup>
    );
};
