import React, { ReactNode } from 'react';

interface FieldLabelProps {
    className?: string;
    label?: string;
    labelRight?: string | ReactNode;
    required?: boolean;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({ className, labelRight, label, required }) => {
    return (
        <div
            style={{
                minHeight: 24,
            }}
            className={`flex mb-1.5 flex-row justify-between items-center text-left ${className}`}
        >
            <label className="text-xs sm:text-sm text-secondaryText font-normal">
                {label}
                {required && <span className="text-mainError text-sm">{' *'}</span>}
            </label>
            {labelRight && labelRight}
        </div>
    );
};
