import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { inputTypes } from '../../constants';
import { useAccountId } from '../../hooks';
import {
    BaseEntityProperty,
    IntegrationDetailsModel,
    IntegrationDetailsResponseModel,
    IntegrationListResponseModel,
    IntegrationPostModel,
    IntegrationTypeDetailsResponseModel,
    IntegrationTypeListResponseModel,
    SelectOptionType,
} from '../../models';
import { selectors as accountIntegrationsSelectors } from '../../redux/thunk/app/account/accountIntegrationsThunk';
import { selectors as integrationTypeSelectors } from '../../redux/thunk/app/integrationType/adminIntegrationTypesThunk';
import axios from '../../services/axios';
import { deleteErrorKey } from '../../utils';
import { DynamicInputs, shouldDynamicInputRender } from '../common';
import { SelectField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';
interface CreateAccountIntegrationModalProps {
    isVisible: boolean;
    onHide: () => any;
    onSuccess: (integration: IntegrationDetailsModel) => any;
}

const formId = 'createAccountIntegrationForm';

type FieldValuesStateType = {
    integrationType: SelectOptionType | null;
};

export const CreateAccountIntegrationModal: React.FC<CreateAccountIntegrationModalProps> = ({
    isVisible,
    onHide,
    onSuccess,
}) => {
    const { t } = useTranslation();
    const accountId = useAccountId();

    const [errors, setErrors] = useState<any>({});
    const [formLoading, setFormLoading] = useState(false);
    const [dynamicInputsLoading, setDynamicInputsLoading] = useState(false);
    const [dynamicInputs, setDynamicInputs] = useState<BaseEntityProperty[]>([]);
    const [fieldValues, setFieldValues] = useState<FieldValuesStateType>({ integrationType: null });

    const integrationTypesResponse: IntegrationTypeListResponseModel = useSelector(
        integrationTypeSelectors.getResponse
    );
    const integrationTypesLoading = useSelector(integrationTypeSelectors.getIsLoading);
    const accountIntegrationsResponse: IntegrationListResponseModel = useSelector(
        accountIntegrationsSelectors.getResponse
    );

    const fetchInputs = async id => {
        if (!id) return;

        try {
            setDynamicInputsLoading(true);
            const response: IntegrationTypeDetailsResponseModel = await axios.get(
                `/bridge/integrationType?accountId=${accountId}&&id=${id}`
            );
            let inputs: BaseEntityProperty[] = response.model?.properties?.map(el => {
                if (el.inputType === inputTypes.dropdown || el.inputType === inputTypes.dropdownOpen) {
                    return {
                        ...el,
                        value: el.defaultValue ? el?.options?.find(x => x.value === el?.defaultValue) : null,
                        inputName: el.name,
                    };
                }
                return {
                    ...el,
                    value: el?.defaultValue || '',
                    inputName: el.name,
                };
            });

            setDynamicInputs(inputs);
        } catch (err) {
        } finally {
            setDynamicInputsLoading(false);
        }
    };

    const onSubmit = async e => {
        e.preventDefault();
        try {
            if (!isFormValid() || dynamicInputsLoading) return;
            setFormLoading(true);

            const { integrationType } = fieldValues;

            let payload: IntegrationPostModel = {
                accountId,
                id: 0,
                integrationTypeId: parseInt(integrationType?.value || '0'),
                values: dynamicInputs
                    .filter(x => shouldDynamicInputRender(x, dynamicInputs))
                    .map(x => ({
                        id: 0,
                        propertyId: x.id,
                        value: x?.value?.value || x?.value,
                    })),
            };

            let response: IntegrationDetailsResponseModel = await axios.post('/bridge/integration', payload);

            onSuccess(response.model);
            setFormLoading(true);
        } catch (err) {
        } finally {
            setFormLoading(false);
        }
    };

    const onChangeDynamicInputs = (inputName: string, value: any) => {
        deleteErrorKey(inputName, setErrors);
        setDynamicInputs(prev => prev.map(x => (x.inputName === inputName ? { ...x, value } : x)));
    };

    const onChangeFieldValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const isFormValid = () => {
        let isValid = true;
        let _errors: any = {};
        const { integrationType } = fieldValues;

        if (!integrationType) {
            isValid = false;
            _errors.integrationType = t('@validations.field.isRequired', { field: 'Integration type' });
        }

        dynamicInputs.forEach(prop => {
            if (shouldDynamicInputRender(prop, dynamicInputs) && prop.required && !prop.value) {
                isValid = false;
                _errors[prop.inputName] = t('@validations.field.isRequired', { field: prop.inputName });
            }
        });

        setErrors(_errors);
        return isValid;
    };

    const integrationTypesOptions: SelectOptionType[] = useMemo(() => {
        const usedIntegrationTypesIds = accountIntegrationsResponse.models.map(x => x.integrationTypeId);

        return integrationTypesResponse.models
            .filter(x => !usedIntegrationTypesIds.includes(x.id))
            .map(item => ({
                label: (
                    <div className="flex w-full justify-start items-center px-2 h-full">
                        <img
                            src={item.imageUrl}
                            className="object-contain h-7 w-7 border border-mainBorder rounded mr-4"
                        />
                        <span>{item.name}</span>
                    </div>
                ),
                value: item.id?.toString(),
            }));
    }, [integrationTypesResponse.models, accountIntegrationsResponse.models]);

    return (
        <SideModal
            isVisible={isVisible}
            onHide={onHide}
            title={t('@create.new.accountIntegration', { defaultValue: 'Add new Integration' })}
            buttonText={t('@common.add')}
            formId={formId}
            loading={formLoading}
        >
            <form id={formId} onSubmit={onSubmit} className="py-6 px-4">
                <SelectField
                    required
                    options={integrationTypesOptions}
                    label={t('@integration.type', { defaultValue: 'Integration type' })}
                    placeholder={t('@choose.integrationType', { defaultValue: 'Choose an integration type...' })}
                    value={fieldValues.integrationType}
                    onChange={item => {
                        onChangeFieldValues('integrationType', item);
                        fetchInputs(item?.value);
                    }}
                    errorMessage={errors?.integrationType}
                    isLoading={integrationTypesLoading}
                />
                {dynamicInputsLoading ? (
                    <div className="p-10 flex w-full justify-center items-center">
                        <Loader />
                    </div>
                ) : (
                    <DynamicInputs
                        className="mt-4"
                        errors={errors}
                        onChangeInput={onChangeDynamicInputs}
                        properties={dynamicInputs}
                    />
                )}
            </form>
        </SideModal>
    );
};
