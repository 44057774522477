import { CSSProperties, FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface FullWrapperModalProps {
    isVisible: boolean;
    onHide: () => void;
    hideOnEsc?: boolean;
    className?: string;
    style?: CSSProperties;
    childrenStyle?: CSSProperties;
    childrenClassName?: string;
}

export const FullWrapperModal: FC<FullWrapperModalProps> = ({
    children,
    isVisible,
    onHide,
    hideOnEsc = true,
    childrenClassName,
    childrenStyle,
    className,
    style,
}) => {
    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (hideOnEsc) {
            document.addEventListener('keydown', onKeyPress);
            return () => document.removeEventListener('keydown', onKeyPress);
        }
    }, []);

    const onKeyPress = e => e?.key === 'Escape' && onHide && onHide();

    useEffect(() => {
        if (isVisible)
            setTimeout(() => {
                setShowChildren(true);
            }, 200);
        else setShowChildren(false);
    }, [isVisible]);

    return createPortal(
        <div
            onClick={e => {
                e.stopPropagation();
                onHide && onHide();
            }}
            className={`fixed z-50 inset-0 p-0 md:p-4 lg:p-6 xl:p-8 2xl:p-10 transform-gpu bg-mainOverlay/50 transition-all duration-350 delay-0 ${
                isVisible ? 'opacity-100' : 'opacity-0'
            } ${className}`}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            style={{
                ...style,
            }}
        >
            <div
                onClick={e => e.stopPropagation()}
                className={`h-full w-full flex overflow-y-auto bg-white rounded-none md:rounded flex-col transform-gpu transition-all duration-350 delay-0 ${
                    isVisible
                        ? ' opacity-100 translate-y-0'
                        : ' opacity-0 -translate-y-0 md:-translate-y-4 lg:-translate-y-6 xl:-translate-y-8 2xl:-translate-y-10'
                }  ${childrenClassName}`}
                style={{
                    ...childrenStyle,
                }}
            >
                {showChildren && children}
            </div>
        </div>,
        document.body
    );
};
