import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SwitchInput } from '../../../components/inputs';
import { ADMIN_CREATE_ALGORITHM_PATH, breakpoints } from '../../../constants';
import { AlgorithmListModel, AlgorithmListResponseModel } from '../../../models';
/**
 * redux
 */
import { actions, selectors } from '../../../redux/thunk/app/algorithm/algorithmsThunk';
/**
 * services - constants
 */
import axios from '../../../services/axios';
import { getWidth } from '../../../utils';
/**
 * components
 */
import { AdminTable } from '../components';

export const AdminAlgorithmsPage = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    /**
     * Redux Selectors
     */
    const response: AlgorithmListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    const requestList = (query: any = {}) => {
        dispatch(actions.request({ ...query, isFromAdmin: true }));
    };
    useEffect(() => {
        requestList({ page: 0 });
    }, []);

    const onRefresh = () => {
        !loading && requestList();
    };

    const onClickEdit = (row: AlgorithmListModel) => {
        history.push(`/admin/algorithms/edit-algorithm/${row?.id}`);
    };
    const onClickCreate = () => {
        history.push(ADMIN_CREATE_ALGORITHM_PATH);
    };
    const toggleHasArtifact = async (value, row) => {
        try {
            let payload = {
                id: row.id,
                patch: [
                    {
                        op: 'replace',
                        path: 'hasArtifact',
                        value,
                    },
                ],
            };
            await axios.patch(`/algorithm`, payload);
            onRefresh();
        } catch (err: any) {}
    };

    return (
        <div className="">
            <AdminTable
                modelEndpoint="algorithm"
                requestList={requestList}
                reduxActions={actions}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row?.name ?? '',
                    },
                    {
                        name: 'Code',
                        selector: row => row?.code ?? '',
                    },
                    {
                        selector: row => row?.groupName ?? '',
                        name: t('@common.group-name'),
                        sortable: true,
                    },
                ]}
                columnsBeforeActions={[
                    {
                        selector: row => row?.hasArtifact ?? '',
                        name: t('@common.has-artifact'),
                        sortable: true,
                        width: '100px',
                        cell: (row: AlgorithmListModel) => {
                            let hasArtifact = !!row?.hasArtifact;

                            return (
                                <div className="flex  flex-row justify-start w-full items-center">
                                    {getWidth() <= breakpoints.sm && (
                                        <span className="mr-2">{t('@common.has-artifact')}</span>
                                    )}
                                    <SwitchInput
                                        hideLabel
                                        value={hasArtifact}
                                        onChange={value => toggleHasArtifact(value, row)}
                                    />
                                </div>
                            );
                        },
                    },
                ]}
                response={response}
                loading={loading}
                initLoading={initLoading}
                onClickEdit={onClickEdit}
                onPageChanged={page => {
                    requestList({ page });
                }}
                headerButtonProps={{
                    onClickCreate,
                    onRefresh,
                }}
            />
        </div>
    );
};
