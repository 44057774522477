import { AccountDetailsResponseModel, InitialStateType } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/account/accountDetailsThunk/REQUEST',
    SUCCESS: '@app/account/accountDetailsThunk/SUCCESS',
    FAILURE: '@app/account/accountDetailsThunk/FAILURE',
    SET_LOOKALIKE_ENGINE_ID: '@app/account/accountDetailsThunk/SET_LOOKALIKE_ENGINE_ID',
};

/**
 * Initial state
 */

const initialState: InitialStateType<AccountDetailsResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        success: false,
        message: '',
        model: { id: 0, imageUrl: '', name: '', users: [], isAdmin: false, lookalikeEngineId: 0 },
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.accountDetails.isLoading,
    getResponse: (state: RootState) => state.app.accountDetails.response,
    getErrorMessage: (state: RootState) => state.app.accountDetails.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_LOOKALIKE_ENGINE_ID } = actionTypes;

const Reducer = (state: InitialStateType<AccountDetailsResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                model: null,
            };
        case SET_LOOKALIKE_ENGINE_ID:
            return {
                ...state,
                response: {
                    ...state.response,
                    model: {
                        ...state.response.model,
                        lookalikeEngineId: payload,
                    },
                },
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (accountId: number) {
        return async dispatch => {
            try {
                dispatch(actionCreator(REQUEST));
                let response: AccountDetailsResponseModel = await axios.get(`/account?id=${accountId}`);
                response.model.imageUrl = `${response.model.imageUrl}?h=100`;
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
    updateResponse: (response: AccountDetailsResponseModel) => actionCreator(SUCCESS, response),
    setLookalikeEngineId: id => {
        return actionCreator(SET_LOOKALIKE_ENGINE_ID, id);
    },
    removeLookalikeEngine: () => {},
};
