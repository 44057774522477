import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PageAnimationContainer } from '../../../../components/animation';
import { FooterButtons, HeaderForCreatePages, SectionContainerForCreatePages } from '../../../../components/common';
import { InputField } from '../../../../components/inputs';
import { AddDataComponent, DataTypesComponent, ObjectivesComponent } from './components';
import { useCreateDatasetHooks } from './hooks/useCreateDatasetHook';

const formId = 'createOrEditDataset';

export const CreateDatasetPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { errors, formLoading, fieldValues, onChangeFieldValues, onSubmit } = useCreateDatasetHooks();

    const onCancel = () => history.goBack();

    return (
        <PageAnimationContainer>
            <div>
                <form onSubmit={onSubmit} className="flex flex-col items-center" id={formId}>
                    <HeaderForCreatePages title={t('@common.basic-information')} className="flex flex-col" />
                    <SectionContainerForCreatePages className="flex flex-col">
                        <InputField
                            required
                            label={t('@common.title')}
                            name="datasetTitle"
                            value={fieldValues.title}
                            onChange={({ target: { value: title } }) => onChangeFieldValues({ title })}
                            placeholder={t('@common.write.here')}
                            errorMessage={errors['title']}
                        />
                        <DataTypesComponent
                            onSelect={dataTypeId => onChangeFieldValues({ dataTypeId, isObjectPathFolder: false })}
                            selectedId={fieldValues.dataTypeId}
                            errorMessage={errors?.dataTypeId}
                        />
                    </SectionContainerForCreatePages>

                    <HeaderForCreatePages hideIcon title={t('@dataset.objectives', { defaultValue: 'Objectives' })} />
                    <SectionContainerForCreatePages>
                        <ObjectivesComponent
                            dataTypeId={fieldValues.dataTypeId}
                            objectiveId={fieldValues.objectiveId}
                            onChangeObjectiveId={objectiveId => onChangeFieldValues({ objectiveId })}
                            errorMessage={errors?.objectiveId}
                        />
                    </SectionContainerForCreatePages>

                    <HeaderForCreatePages hideIcon title="Add data" />
                    <SectionContainerForCreatePages>
                        <AddDataComponent
                            errors={errors}
                            dataTypeId={fieldValues.dataTypeId}
                            isObjectPathFolder={fieldValues.isObjectPathFolder}
                            objectPath={fieldValues.objectPath}
                            onChangeFields={onChangeFieldValues}
                        />
                    </SectionContainerForCreatePages>

                    <HeaderForCreatePages title="" className="mt-10" hideIcon />
                    <FooterButtons
                        hideSave
                        onClickCancel={onCancel}
                        thirdButtonProps={{
                            type: 'submit',
                            form: formId,
                            loading: formLoading,
                            disabled: formLoading,
                            title: t('@common.create'),
                        }}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
