export const SPECIAL_SPLIT_CHAR = '_&_';
export const SPECIAL_STRING = '&_&';
export const ADMIN_SPLIT_CHAR = '$$';
export const COUNTER_TABLE_SELECTOR = '___index___';

export * from './types';
export * from './recommendation-constants';
export * from './route-names';
export * from './static-options';
export * from './information-constants';
export * from './dimensions';
export * from './query-constants';
export * from './classnames';
export * from './tooltip-constants';
export * from './propensity-constants';
export * from './query-params-constants';
