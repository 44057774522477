import React, { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '../../../../components/buttons';

export type SelectFolderType = {
    key: string;
    displayName?: string;
    cell?: () => ReactNode;
};

interface MoveObjectsSelectFromListProps {
    style?: CSSProperties;
    className?: string;
    folders: SelectFolderType[];
    folderSelected: string | null;
    setFolderSelected: (folder: string) => any;
}

const firstColClass = 'flex flex-1 p-1',
    secondColClass = 'flex flex-8 p-1 pl-2',
    headerRowClass = 'flex w-full bg-mainBackground',
    headerFirstCell = `${firstColClass}  border-r-0.5 border-mainBorder`,
    headerSecondCell = `${secondColClass}  border-mainBorder text-secondaryText text-sm font-medium`,
    dataRowClass = `flex w-full cursor-pointer transition-all hover:text-mainBlue text-sm hover:bg-primaryBlue50 text-blueMainText`,
    dataFirstCell = `${firstColClass}  items-center`,
    dataSecondCell = `${secondColClass}`;

export const MoveObjectsSelectFromList: React.FC<MoveObjectsSelectFromListProps> = ({
    style,
    className,
    folders,
    setFolderSelected,
    folderSelected,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`flex flex-col w-full h-auto ${className}`} style={style}>
            <div className={headerRowClass}>
                <div className={headerFirstCell}></div>
                <div className={headerSecondCell}>{t('@folder', { defaultValue: 'Folder' })}</div>
            </div>
            {folders.map(({ key, ..._folder }) => {
                const isChecked = key === folderSelected;

                return (
                    <div
                        key={key}
                        className={`${dataRowClass} ${isChecked && 'bg-primaryBlue50'}`}
                        onClick={() => !isChecked && setFolderSelected(key)}
                    >
                        <div className={`${dataFirstCell} justify-center`}>
                            <RadioButton isChecked={isChecked} className="" />
                        </div>
                        <div className={dataSecondCell}>
                            {_folder.cell ? _folder.cell() : _folder.displayName ?? key}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
