import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { UploadButton } from '../../../components/buttons';
import { Icon, IconTypes } from '../../../components/common';
import { DetailsInfoOverview } from '../../../components/details-entity';
import { CreateArtifactModal } from '../../../components/modals';
import { CustomTabContainer } from '../../../components/tabs/custom-tab/CustomTabContainer';
import { artifactSupportedFiles } from '../../../constants';
import { useAccountId, useDetailsError, useToggleVisibility } from '../../../hooks';
import {
    ArtifactDetailsModel,
    ArtifactDetailsResponseModel,
    ArtifactVersionListResponseModel,
    Nullable,
} from '../../../models';
import {
    actions as artifactVersionsActions,
    selectors as artifactVersionsSelectors,
} from '../../../redux/thunk/app/artifact/artifactVersionsThunk';
import { actions as uploadArtifactActions } from '../../../redux/thunk/app/artifact/uploadArtifactThunk';
import axios from '../../../services/axios';
import { formatDateAndTime, getFileExtension, shouldUploadVersionToArtifact } from '../../../utils';
import { ArtifactVersionsPage } from './ArtifactVersionsPage';
import { MoreOptionsDropdown } from './components/MoreOptionsDropdown';
import { ArtifactDetailsTablePreview } from './tabs/ArtifactDetailsTablePreview';
import { ArtifactDetailsTablesSummary } from './tabs/ArtifactDetailsTableSummary';

enum ArtifactsDetailTab {
    preview = 'Preview',
    tabularDataSummary = 'Tabular Data Summary',
    versions = 'Versions',
}

export const ArtifactDetailsPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params: any = useParams();
    const accountId = useAccountId();
    const { handleError, resetError } = useDetailsError();
    const entityId = parseInt(params?.id);

    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });

    const [details, setDetails] = useState<Nullable<ArtifactDetailsModel>>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const [artifactSelectedForEdit, setArtifactSelectedForEdit] = useState<any>(null);
    const response: ArtifactVersionListResponseModel = useSelector(artifactVersionsSelectors.getResponse);
    const [selectedArtifact, setSelectedArtifact] = useState<ArtifactDetailsModel | null>(null);

    useEffect(() => {
        fetchDetails();
    }, [accountId, entityId]);

    const fetchDetails = async () => {
        try {
            resetError();
            setDetailsLoading(true);
            const response: ArtifactDetailsResponseModel = await axios.get(
                `/artifact?accountId=${accountId}&id=${entityId}`
            );
            setDetails(response.model);
            setSelectedArtifact(response.model);
        } catch (err) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };
    const onHideArtifactModal = () => {
        onToggleCreateModal(false);
        setArtifactSelectedForEdit(null);
    };

    const onClickEdit = async details => {
        if (details?.dataSource) {
            onToggleCreateModal(true);
            setArtifactSelectedForEdit(details);
            return;
        }
    };

    const onSuccessCreated = (artifact, edited) => {
        toast.success(
            edited ? t('@components.modals.success-edit-artifact') : t('@components.modals.success-create-artifact')
        );
    };

    const DetailsOverviewData = {
        '@common.title': details?.title ?? '',
        '@createdAt': formatDateAndTime(details?.createdAt ?? ''),
        '@createdBy': details?.createdBy ?? '',
        '@common.last-modified': formatDateAndTime(details?.lastModified ?? ''),
        '@modifiedBy': details?.modifiedBy ?? '',
    };

    const buildDetailsInfoOverviewData = () => {
        const data = { ...DetailsOverviewData };

        Object.keys(data).forEach(n => {
            if (!data[n]) {
                delete data[n];
            }
        });
        return data;
    };

    const onUploadNewVersion = (files: File[] | FileList) => {
        if (!files.length || !selectedArtifact) {
            return;
        }
        let onSuccess = (artifact: ArtifactDetailsResponseModel) => {
            onSuccessSetDefault(artifact.model.defaultVersionId, artifact.model.isPermanent);
        };
        dispatch(
            uploadArtifactActions.upload(
                {
                    fileContents: files[0],
                    accountId,
                },
                {
                    id: selectedArtifact.id,
                    title: selectedArtifact.title,
                },
                onSuccess
            )
        );
    };

    const onSuccessSetDefault = (versionId: number, isPermanent: boolean) => {
        if (selectedArtifact) {
            let artifact: ArtifactDetailsModel = {
                ...selectedArtifact,
                defaultVersionId: versionId,
                isPermanent,
            };
            setSelectedArtifact(artifact);
            fetchDetails();
            requestVersions({ page: response.currentPage }, versionId, isPermanent);
        }
    };

    const requestVersions = (query: any, defaultVersionId?: number, isPermanent?: boolean) => {
        dispatch(
            artifactVersionsActions.request(
                {
                    artifactId: entityId,
                    accountId,
                    ...query,
                },
                defaultVersionId || selectedArtifact?.defaultVersionId,
                isPermanent
            )
        );
    };

    return (
        <>
            {shouldRenderCreateModal && (
                <CreateArtifactModal
                    isVisible={isCreateModalVisible}
                    onHide={onHideArtifactModal}
                    isEditing={!!artifactSelectedForEdit}
                    artifact={artifactSelectedForEdit}
                    onSuccess={onSuccessCreated}
                />
            )}
            <DetailsInfoOverview
                data={details ? { ...buildDetailsInfoOverviewData() } : {}}
                loading={detailsLoading}
                title={t('@artifacts.overview', { defaultValue: 'Artifacts overview' })}
                titleRightComponent={<MoreOptionsDropdown item={details} onClickEdit={() => onClickEdit(details)} />}
                style={{ minHeight: 200 }}
            />

            <div className="flex flex-1 flex-col my-4">
                {details
                    ? details.title.search('- output') === -1 && (
                          <UploadButton
                              icon={<Icon name={IconTypes.upload} className="mr-2 mt-1" size={18} />}
                              title={t('@common.artifact-upload-new-version')}
                              className="mb-4 w-48"
                              accept={
                                  selectedArtifact
                                      ? '.' + getFileExtension(selectedArtifact.url)
                                      : artifactSupportedFiles
                              }
                              handleUpload={e => {
                                  const files = e.target.files;

                                  if (!files || files.length < 1 || !selectedArtifact) {
                                      return;
                                  }
                                  if (!shouldUploadVersionToArtifact(selectedArtifact?.url, files[0].name)) {
                                      toast.error(
                                          `Cannot upload this file because is not ${getFileExtension(
                                              selectedArtifact.url
                                          )}!`
                                      );
                                      return;
                                  }
                                  onUploadNewVersion(files);
                                  e.target.value = '';
                              }}
                          />
                      )
                    : ''}
                <CustomTabContainer
                    style={{ minHeight: 500 }}
                    animationEnabled={false}
                    defaultActiveTab={ArtifactsDetailTab.preview}
                    tabs={[
                        // details?.artifactPreview ?
                        {
                            component: <ArtifactDetailsTablePreview data={details} key={ArtifactsDetailTab.preview} />,
                            displayName: t('@artifactDetails.tab.csvFile', { defaultValue: 'Preview Csv File' }),
                            key: ArtifactsDetailTab.preview,
                        },
                        {
                            component: <ArtifactDetailsTablesSummary data={details} />,
                            displayName: t('@artifactDetails.tab.tabularDataSummary', {
                                defaultValue: 'Tabular Data Summary',
                            }),
                            key: ArtifactsDetailTab.tabularDataSummary,
                        },
                        {
                            component: <ArtifactVersionsPage />,
                            displayName: t('@artifactDetails.tab.versions', { defaultValue: 'Versions' }),
                            key: ArtifactsDetailTab.versions,
                        },
                    ]}
                />
            </div>
        </>
    );
};
