import { ValidationError, AnyObjectSchema } from 'yup';
import { AnyObject, ValidateOptions } from 'yup/lib/types';
import { YupSchemaType } from '../types';

export const onValidateInputs = async (schema: YupSchemaType, values: any, options?: ValidateOptions<AnyObject>) => {
    let isValid = true;
    let _errors = {};

    try {
        await schema.validate(values, {
            abortEarly: false,
            ...options,
        });
    } catch (err: any) {
        isValid = false;
        let validationErrors: ValidationError = { ...err };

        if (validationErrors.inner)
            validationErrors.inner.forEach(item => {
                if (item.path) _errors[item.path] = item.errors[0] || item.message;
            });
    } finally {
        return { isValid, _errors };
    }
};

export const onValidateWithYup = async (schema: AnyObjectSchema, data: any, validateOptions?: ValidateOptions) => {
    let isValid = true;
    let _errors: any = {};
    try {
        await schema.validate(data, {
            abortEarly: false,
            ...validateOptions,
        });
    } catch (err: any) {
        isValid = false;
        let validationErrors: ValidationError = { ...err };

        if (validationErrors.inner)
            validationErrors.inner.forEach(item => {
                if (item.path) _errors[item.path] = item.errors[0] || item.message;
            });
    } finally {
        return { isValid, errors: _errors };
    }
};
