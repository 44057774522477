export const tabularQueryArtifacts = {
    artifactContentTypeId: {
        label: 'Tabular',
        value: '1',
    },
};

export const artifactContentTypeSupportedFile = {
    tabular: '.csv',
    textual: '.txt',
};
