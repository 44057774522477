import axios, { AxiosError } from 'axios';
import { BaseListModel, ListResponseModel, StateErrorType } from '../models';
import { formatDateAndTime } from './date-formatters';

export interface ActionCreatorProps {
    type: string;
    payload?: object;
}

export const actionCreator = (type: string, payload?: any) => ({
    type,
    payload,
});

export const getRowUpdated = (row: any, keyValues: { key: string; value: any }[]) => {
    let _row = { ...row };
    keyValues.forEach(({ key, value }) => {
        if (_row[key] !== undefined && _row[key] !== null) {
            _row[key] = value;
        }
    });
    return _row;
};

export function getFormattedResponseForPayload<ListModel extends BaseListModel = BaseListModel>(
    response: ListResponseModel<any>,
    previousModels: ListModel[],
    query: any,
    searchParams?: any,
    options?: {
        dontFormatDate?: boolean;
    }
) {
    return {
        ...response,
        models: [
            ...previousModels,
            ...response.models.map((el: ListModel) => ({
                ...el,
                lastModified: options?.dontFormatDate ? el.lastModified : formatDateAndTime(el.lastModified),
            })),
        ],
        currentPage: query?.page || 0,
        hasNextPage: !!response.totalPages && (query?.page || 0) < response.totalPages - 1,
        query: searchParams ? searchParams : query,
    };
}

export const getSearchParams = (previousQuery: any, query, resetQuery = false) => {
    let searchParams: any = {};
    if (resetQuery) searchParams = { ...query };
    else searchParams = { ...previousQuery, ...query };

    return searchParams;
};

export const formatBiskoError = (error: Error | AxiosError) => {
    let _err: StateErrorType = null;

    if (axios.isAxiosError(error)) {
        _err = {
            status: error.response?.status ?? 0,
            message: error.response?.data?.message ?? error.message,
        };
    }

    return _err;
};
