import { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { primaryButtonHoverClassName } from '../../constants';
import { Loader } from '../ui';

export interface CreateButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title?: string;
    onClick?: (e: any) => any;
    className?: string;
    style?: CSSProperties;
    icon?: ReactNode;
    htmlType?: string;
    disabled?: boolean;
    loading?: boolean;
}

export const CreateButton: React.FC<CreateButtonProps> = ({
    title,
    onClick,
    className,
    style,
    icon,
    htmlType,
    loading,
    disabled,
    ...props
}) => {
    const { t } = useTranslation();
    const _title = title ?? t('@create.new', { defaultValue: 'Create new' });

    return (
        <button
            {...props}
            onClick={e => !disabled && onClick && onClick(e)}
            disabled={disabled || loading}
            className={`${
                !disabled && primaryButtonHoverClassName
            } bg-primaryBlue500 h-8 sm:h-10 p-3 pr-4 rounded truncate text-white text-xs sm:text-sm flex flex-row justify-between items-center white cursor-pointer w-auto ${className} ${
                disabled && 'opacity-50'
            }`}
            style={{
                minWidth: 'auto',
                maxWidth: 'fit-content',
                width: 'auto',
                cursor: loading || disabled ? 'not-allowed' : 'pointer',
                ...style,
            }}
        >
            {!!loading ? (
                <div className="flex justify-center w-full">
                    <Loader color="white" size={24} />
                </div>
            ) : (
                <>
                    {icon}
                    {_title}
                </>
            )}
        </button>
    );
};
