import { nanoid } from 'nanoid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { PipelineJobRunCard } from './components/PipelineJobRunCard';
import { RunDetailsVisible } from './components/RunDetailsVisible';
import * as hooks from '../../../../../hooks';
import axios from '../../../../../services/axios';
import { DetailsEmpty, DetailsLoader } from '../../../../../components/details-entity';

interface IStep {
    _id: string | '';
    outputReferenceName: any;
    displayName?: string;
    status: string;
    start: string;
    end: string;
    message: string;
    logs: string[];
}
interface IStepsProps {
    [key: string]: IStep;
}

export const PipelineJobRunDetailsPage = () => {
    const params = useParams<{ id: any }>();
    const entityId = params.id || 0;
    const accountId = hooks.useAccountId();
    const timeoutId: any = useRef(null);
    const fetchLogId: any = useRef(null);

    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const [steps, setSteps] = useState<IStep[]>([]);
    const [stepSelected, setStepSelected] = useState('');
    const [rightPartVisible, setRightPartVisible] = useState('');

    useEffect(() => {
        fetchRunLogs(entityId, true);
    }, [accountId, entityId]);

    useEffect(() => {
        var oneOfStepsFailed = !!steps.find(step => step.status === 'Failed');
        var everyStepSucceeded = steps.every(step => step.status === 'Succeeded');

        if ((!oneOfStepsFailed && !everyStepSucceeded) || !steps.length) {
            clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(() => fetchRunLogs(entityId, false), 5000);
        }
    });

    useEffect(() => {
        var stepToBeSelected = stepSelected !== '' ? steps[stepSelected]._id : '';
        if (steps.length > 0) {
            if (steps.length > 0 && stepToBeSelected === '') {
                var runningSteps = steps.filter(x => x.status === 'Running');
                var pendingSteps = steps.filter(x => x.status === 'Pending');
                if (runningSteps.length > 0) {
                    stepToBeSelected = runningSteps[0]._id;
                } else if (pendingSteps.length > 0) {
                    stepToBeSelected = pendingSteps[0]._id;
                } else {
                    stepToBeSelected = steps[steps?.length - 1]._id;
                }
            }
        }
        setRightPartVisible(stepToBeSelected);
        clearTimeout(fetchLogId.current);
        fetchLogId.current = setTimeout(() => setDetailsLoading(false), 1000);
    }, [steps]);

    const _idSelected = useMemo(() => {
        if (rightPartVisible === '' && stepSelected === '') {
            return '';
        }

        return stepSelected === '' ? rightPartVisible : steps[stepSelected]._id;
    }, [rightPartVisible, stepSelected]);

    const fetchRunLogs = async (runId, loading: boolean) => {
        var initialSteps: IStep[] = [];
        try {
            if (loading) {
                setDetailsLoading(true);
                setStepSelected('');
            }

            const query = {
                accountId: `${accountId}`,
                runId: `${runId}`,
            };

            const presignedUrl: string = await axios.get(`/pipeline/jobRun/logs?${new URLSearchParams(query)}`);

            const fileContentResponse = await fetch(presignedUrl);
            const fileContent = await fileContentResponse.text();
            var jsonFileContent: IStepsProps = JSON.parse(fileContent);

            (Object.keys(jsonFileContent) as (keyof typeof jsonFileContent)[]).map(item => {
                var step: IStep = jsonFileContent[item];
                step.outputReferenceName = item;
                step._id = nanoid();
                initialSteps.push(step);
            });
        } catch (err) {
        } finally {
            setSteps(initialSteps);
        }
    };

    const onRefresh = () => fetchRunLogs(entityId, true);

    return (
        <div className="flex flex-col md:flex-row w-full h-[600px] md:border md:border-mainBorder flex-1 card-shadow rounded bg-white table-body-global-style relative">
            <div
                className="flex flex-2 flex-col overflow-y-auto scroll md:border-r md:border-mainBorder p-4"
                style={{ scrollbarGutter: 'stable' }}
            >
                <div className="w-full flex items-center justify-between pb-7 pt-3 mb-4 top-0 md:border-b md:border-mainBorder">
                    <h2 className="text-base text-blueMainText font-medium text-lg">Run details</h2>
                    {/* <p className="text-xs font-medium text-secondaryText">Run: {entityId}</p> */}
                </div>

                {detailsLoading ? (
                    <DetailsLoader />
                ) : steps?.length === 0 ? (
                    <DetailsEmpty title={'Pending execution'} onRefresh={() => fetchRunLogs(entityId, true)} />
                ) : (
                    <>
                        {steps.map((x, index) => (
                            <PipelineJobRunCard
                                // onSelect={() => setRightPartVisible(x._id)}
                                onSelect={() => setStepSelected(`${index}`)}
                                isSelected={_idSelected === x._id}
                                displayName={x.displayName || ''}
                                index={index}
                                _id={x._id}
                                key={x._id}
                                status={x.status}
                                message={x.message}
                                step={x}
                            />
                        ))}
                    </>
                )}
            </div>
            <div className="flex flex-3 flex-col h-full " style={{ scrollbarGutter: 'stable' }}>
                {detailsLoading ? (
                    <DetailsLoader />
                ) : (
                    <>
                        {steps?.length > 0 && (_idSelected === '' || rightPartVisible === '') ? (
                            <DetailsEmpty onRefresh={() => setRightPartVisible(steps[0]._id)} />
                        ) : _idSelected !== '' ? (
                            <RunDetailsVisible
                                _id={_idSelected}
                                displayName={steps.find(x => x._id === _idSelected)?.displayName}
                                status={steps.find(x => x._id === _idSelected)?.status || ''}
                                message={steps.find(x => x._id === _idSelected)?.message || ''}
                                logs={steps.find(x => x._id === _idSelected)?.logs ?? []}
                                onRefresh={() => onRefresh()}
                            />
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};
