import { CSSProperties, FC } from 'react';
import bg_image from '../../assets/images/information_background.png';
export interface InformationCardProps {
    title?: string;
    className?: string;
    showBackButton?: boolean;
    onClickBack?: (e?: any) => any;
    style?: CSSProperties;
}

export const InformationCard: FC<InformationCardProps> = ({ children, title, className, showBackButton, style }) => {
    return (
        <div className={`flex flex-col base-card-style card-shadow h-auto w-full ${className}`} style={{ ...style }}>
            <div
                className="flex px-4 md:px-6 lg:px-8 xl:px-10 flex-row h-20 w-full items-center justify-start bg-gradient-to-t bg-no-repeat bg-cover"
                style={{
                    backgroundImage: `linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%), url(${bg_image})`,
                }}
            >
                <span
                    className={`text-blueMainText text-base  sm:lg md:text-xl lg:text-2xl font-normal ${
                        !showBackButton && 'ml-6 md:ml-0'
                    }`}
                >
                    {title}
                </span>
            </div>
            {children}
        </div>
    );
};
