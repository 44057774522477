import merge from 'deepmerge';
import React from 'react';
import { Chart, ChartWrapperOptions, ReactGoogleChartProps } from 'react-google-charts';

const _options: ChartWrapperOptions['options'] = {
    colors: ['#3A81F6'],
    pointsVisible: true,
    tooltip: {
        isHtml: true,
        textStyle: {
            color: '#7483A1',
            fontName: 'Inter',
            fontSize: '6px',
        },
        trigger: 'focus',
    },
    curveType: 'function',
    lineWidth: 4,
    pointSize: 10,
    showZoomOut: true,
};

interface GeoChartProps extends Omit<ReactGoogleChartProps, 'chartType'> {}

// const _data = [
//     ['Country', 'Popularity'],
//     ['Germany', 200],
//     ['United States', 300],
//     ['Brazil', 400],
//     ['Canada', 500],
//     ['France', 600],
//     ['RU', 700],
// ];

export const GeoChart: React.FC<GeoChartProps> = ({ chartEvents, options, width, height, data, ...props }) => {
    return (
        <Chart
            chartEvents={chartEvents}
            options={merge({ ..._options }, { ...options })}
            chartType="GeoChart"
            width="100%"
            height="400px"
            data={data}
            {...props}
        />
    );
};
