import { nanoid } from 'nanoid';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { toast } from '../../../../../components/ui/toast';
import { PIPELINES_PATH, runSchedules } from '../../../../../constants';
import { useAccountId } from '../../../../../hooks';

import {
    BaseInstanceTypeListModel,
    Nullable,
    PartialRecord,
    PipelineDetailsResponseModel,
    PipelineJobDetailsResponseModel,
    PipelineJobPostModel,
    PipelineJobValuesPostModel,
    SelectOptionType,
} from '../../../../../models';
import axios from '../../../../../services/axios';
import { deleteErrorKey, getFormattedQuery, queryParams } from '../../../../../utils';
import { onValidateWithYup, pipelineJobFormSchema } from '../../../../../validations';

type TFormValues = {
    title: string;
    pipeline: SelectOptionType | null;
    description: string;
    instanceTypeId: Nullable<SelectOptionType>;
    instanceCount: string;
    schedule?: any;
    scheduleFrequency?: string;
    values?: Nullable<PipelineJobValuesPostModel[]>;
};


export const useCreatePipelineJobHook = ({
    instanceTypesLoading,
    instanceTypes,
    scheduleSelectedOption,
    setScheduleSelectedOption,
    runScheduleData,
    setRunScheduleData,
}) => {
    const { t } = useTranslation();
    const params: { id?: string, pipelineId?: string } = useParams();
    const entityId = parseInt(params?.id ?? '0');
    const pipelineId = parseInt(params?.pipelineId ?? '0');

    const history = useHistory();
    const accountId = useAccountId();

    const [detailsLoading, setDetailsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [formValues, setFormValues] = useState<TFormValues>({
        title: '',
        pipeline: null,
        description: '',
        instanceTypeId: null,
        instanceCount: '1',
        schedule: runSchedules[0],
        scheduleFrequency: '',
        values: null,
    });

    useEffect(() => {
        if (!instanceTypesLoading && entityId) fetchDetails();
        if(!instanceTypesLoading && !entityId && pipelineId) fetchPipelineValues(pipelineId);
    }, [pipelineId, entityId, accountId, instanceTypesLoading]);


    const onChangeFormValues = useCallback(
        (key: keyof TFormValues | string, value: any) => {
            deleteErrorKey(key, setErrors);
            setFormValues(prev => ({
                ...prev,
                [key]: value,
            }));

            key === 'pipeline' && fetchPipelineValues(value?.value);
        },
        [setFormValues, setErrors]
    );

    const onChangeValues = (name: string, value: any) => {
        setFormValues(formValues => ({
            ...formValues,
            values: formValues.values?.map(_value => (_value.name === name ? { ..._value, value } : _value)),
        }));
    };

    const fetchPipelineValues = async id => {
        // debugger;
        try {
            setLoading(true);
            const query = {
                id,
                accountId,
            };

            const pipelineResponse: PipelineDetailsResponseModel = await axios.get(
                `/pipeline?${getFormattedQuery(query)}`
            );

            let values =
            pipelineResponse.model?.variables
            ?.filter(x => x.settableAtQueueTime)
                    .map(variable => {
                        const _id = nanoid();
                        return {
                            _id,
                            id: 0,
                            name: variable.name,
                            value: variable.value,
                        };
                    }) ?? [];
            setFormValues(prev => ({
                ...prev,
                pipeline: { label: pipelineResponse.model.title, value: pipelineResponse.model.id?.toString() },
                values,
            }));
        } catch (err: any) {
            setFormValues(prev => ({
                ...prev,
                values: null,
            }));
        } finally {
            setLoading(false);
        }
    };

    const isFormValid = async (_formValues: PipelineJobPostModel) => {
        const formSchema = pipelineJobFormSchema(t);
        const { errors: formErrors, isValid: isFormValid } = await onValidateWithYup(formSchema, _formValues);
        setErrors(formErrors);
        if (!isFormValid) return false;

        return true;
    };

    const fetchDetails = async () => {
        try {
            setDetailsLoading(true);
            const {
                model: {
                    title,
                    pipeline,
                    values,
                    instanceTypeId,
                    instanceCount,
                    scheduleId,
                    scheduleFrequency,
                    description,
                },
            }: PipelineJobDetailsResponseModel = await axios.get(`/pipeline/job?id=${entityId}&accountId=${accountId}`);

            // debugger;
            var settableAtQueueTimeVariables = pipeline?.variables?.filter(x => x.settableAtQueueTime) ?? [];

            let oldValues: PipelineJobValuesPostModel[] = [];
            let newValues: PipelineJobValuesPostModel[] = [];

            values.map(value => {
                if (settableAtQueueTimeVariables.find(x => x.name === value.name)) {
                    oldValues.push(value);
                }
            });
            settableAtQueueTimeVariables.map(variable => {
                var valueExists = !!values.find(x => x.name === variable.name);
                if (!valueExists) {
                    const _id = nanoid();
                    newValues.push({
                        _id,
                        id: 0,
                        name: variable.name,
                        value: variable.value,
                    });
                }
            });

            const instanceType: Nullable<BaseInstanceTypeListModel> =
                instanceTypes.find(x => x.id === instanceTypeId) ?? null;

            setFormValues({
                title,
                pipeline: pipeline ? { label: pipeline.title, value: pipeline.id?.toString() } : null,
                description: description || '',
                instanceTypeId: instanceType ? { label: instanceType.name, value: instanceType.id?.toString() } : null,
                instanceCount: instanceCount?.toString() || '1',
                schedule: runSchedules.find(y => y.value === scheduleId?.toString()) || null,
                scheduleFrequency: scheduleFrequency?.toString() || '',
                values: [...oldValues, ...newValues],
            });

            setScheduleSelectedOption(runSchedules.find(x => x.value === scheduleId.toString()) || null);
            setRunScheduleData({
                scheduleFrequency: scheduleFrequency,
                instanceType: instanceType
                    ? {
                          label: instanceType?.name,
                          value: instanceType?.id?.toString(),
                      }
                    : null,
            });
        } catch (err) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const { scheduleFrequency, instanceType } = runScheduleData;
            const payload: PipelineJobPostModel = {
                id: entityId,
                accountId,
                title: formValues.title,
                // pipelineId: parseInt(formValues.pipeline?.value || '0'),
                pipelineId: pipelineId,
                instanceCount: parseInt(formValues.instanceCount) || 0,
                instanceTypeId: instanceType?.value,
                runImmediately: true,
                scheduleId: scheduleSelectedOption?.value,
                scheduleFrequency: !!scheduleFrequency ? scheduleFrequency : 0,
                description: formValues.description,
                values: formValues.values ?? [],
            };

            const isValid = await isFormValid(payload);
            if (!isValid) {
                toast.error(t('@form.errors', { defaultValue: 'Form validation errors!' }));
                return false;
            }

            setLoading(true);

            await axios.post('/pipeline/job', payload);
            history.push(PIPELINES_PATH + `/details/${pipelineId}` + queryParams.formatForNavigation());
            toast.success(t(`@success.${entityId ? 'edit' : 'create'}.pipelineJob`));
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    return {
        onSubmit,
        onChangeFormValues,
        onChangeValues,
        formValues,
        detailsLoading,
        loading,
        errors,
        setErrors,
        runScheduleData,
        scheduleSelectedOption,
    };
};
