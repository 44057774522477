import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { getTimeFromSeconds, getTotalSecondsFromTime } from '../../../utils';

interface RunDurationProps {
    runStatus: string;
    runDuration: string;
    className?: string;
    style?: CSSProperties;
}

export const RunDuration: React.FC<RunDurationProps> = ({ runDuration, runStatus, className, style }) => {
    const [runningSeconds, setRunningSeconds] = useState<number>(getTotalSecondsFromTime(runDuration));

    let interval: any = null;

    useEffect(() => {
        if (runStatus === 'Running' || runStatus === 'Terminating') {
            interval = setInterval(() => {
                setRunningSeconds(seconds => seconds + 1);
            }, 1000);

            return () => {
                interval && clearInterval(interval);
            };
        }

        return () => {
            interval && clearInterval(interval);
        };
    }, [runStatus]);

    useEffect(() => {
        if (runDuration) {
            setRunningSeconds(getTotalSecondsFromTime(runDuration?.toString()));
        }
    }, [runDuration]);

    const { duration } = useMemo(() => {
        return {
            duration: getTimeFromSeconds(runningSeconds),
        };
    }, [runningSeconds, runDuration]);

    return (
        <span className={className} style={style}>
            {duration}
        </span>
    );
};
