import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
interface GoBackRowComponentProps {
    onClick: () => void;
    className?: string;
}

export const GoBackRowComponent: React.FC<GoBackRowComponentProps> = ({ onClick, className }) => (
    <div
        onClick={e => {
            e.stopPropagation();
            onClick();
        }}
        className={`flex flex-1 h-full items-center ${className}`}
    >
        <GjirafaIcon name="ArrowUpAlt" size={24} />
        <span className="ml-1 text-sm font-medium">..</span>
    </div>
);
