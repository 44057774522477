import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountId } from '../../../../../src/hooks';
import { TableActionRowProps } from '../../../../../src/components/tables/components';
import { NoDataImagesType, Table } from '../../../../components/tables';
import { useToggleVisibility } from '../../../../hooks/useToggleVisibility';
import { CatalogListModel, SubcatalogListResponseModel } from '../../../../models';
import { actions as subcatalogActions, selectors as subcatalogSelectors } from '../../../../redux/thunk/app/catalog/subcatalogsThunk';
import { useParams } from 'react-router';
import { CreateCatalogFilterModal } from '../../../../components/modals';
import { toast } from '../../../../components/ui';
import { formatDateAndTime } from '../../../../utils';
import { OutlineButton } from '../../../../components/buttons';
import { Icon, IconTypes } from '../../../../components/common';

export const CatalogDetailsTableSubcatalog: React.FC = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const dispatch = useDispatch();
  const accountId = useAccountId();
  const parentId = parseInt(params?.id);

  /**
  * Redux Selector
  */
  const loading = useSelector(subcatalogSelectors.getIsLoading);
  const subcatalogsResponse: SubcatalogListResponseModel = useSelector(subcatalogSelectors.getResponse);  

  const requestSubcatalogs = (query: any) => {
    dispatch(subcatalogActions.request(
      {
        accountId,
        parentId,
        ...query
      }
    ))
  }

  const [selectedCatalogForFilter, setSelectedCatalogForFilter] = useState<CatalogListModel | null>(null);
  const [isCatalogFilterEditing, setIsCatalogFilterEditing] = useState(false);
  const [isCreateCatalogFilterModalVisible, shouldRenderCreateCatalogFilterModal, onToggleCreateCatalogFilterModal] =
    useToggleVisibility({
      initialValue: false,
  });

  const onSuccessCatalogFilter = () => {
    toast.success(t('@success.edit.catalogFilter', { defaultValue: 'Catalog Filter has been edited!' }));
    requestSubcatalogs({});
  };

  const onClickEdit = (row: CatalogListModel) => {
    setSelectedCatalogForFilter(row);
    onToggleCreateCatalogFilterModal(true);
    setIsCatalogFilterEditing(true)
  };

  const onRefresh = () => {
    requestSubcatalogs({})
  }

  useEffect(() => {
    requestSubcatalogs({accountId, parentId});
  }, [accountId, parentId]);

  const onPageChanged = page => requestSubcatalogs({page})

  const { tableActions, columns } = useMemo<{ tableActions: TableActionRowProps[]; columns: any }>(
    () => ({
      tableActions: [
        {
          action: 'edit',
          displayName: t('@common.edit'),
          onClick: onClickEdit,
        },
        {
          action: 'delete',
          displayName: t('@common.delete'),
        },
      ],
      columns: [
        {
          selector: row => row?.name ?? '',
          cell: row => <span>{row?.name}</span>,
          name: t('@common.name'),
          sortable: true,
        },
        {
          selector: row => row?.artifact ?? '',
          name: t('@common.artifact'),
          sortable: true,
        },
        {
          selector: row => row?.schemaType ?? '',
          name: t('@common.schema-type'),
          sortable: true,
        },
        {
          name: t('@common.last-modified'),
          width: '180px',
          selector: row => row?.lastModified ?? '',
          cell: row => <span>{formatDateAndTime(row?.lastModified)}</span>,
          sortable: true,
        },
        {
          name: t('@common.modified-by'),
          selector: row => row?.modifiedBy ?? '',
          width: '140px',
          cell: row => <span>{row?.modifiedBy}</span>,
        },
      ],
    }),[]
  );

  const emptyDataProps = {
    imageType: NoDataImagesType.Catalog,
    emptyText: 'No subcatalogs created',
    actionMessage: '',
  }

  const paginationProps = useMemo(
    () => ({
        currentPage: subcatalogsResponse.currentPage || 0,
        pageItems: subcatalogsResponse.models.length,
        totalPages: subcatalogsResponse.totalPages,
        totalItems: subcatalogsResponse.totalItems,
        onChangePage: onPageChanged,
    }),
    [subcatalogsResponse]
);

  return (
    <>
      <div className="w-full h-full top-0 left-0 bottom-0 right-0" style={{ height: '100%' }}>
      <div className="flex flex-1 justify-end">
        <OutlineButton
          onClick={() => {
            onRefresh && onRefresh();
          }}
          icon={<Icon name={IconTypes.refresh} className="mr-2" />}
          title={t('@common.refresh')}
          className="bg-white mb-3"
        />
      </div>
        <Table
          tableAnimation={false}
          headerButtonsAnimation={false}
          modelEndpoint="catalog"
          requestList={requestSubcatalogs}
          keyField="name"
          className="w-full "
          style={{ overflowX: 'unset' }}
          expandableRows={false}
          response={subcatalogsResponse}
          loading={loading}
          emptyDataProps={emptyDataProps}
          columns={columns}
          actions={tableActions}
          isFromSelectPopup={false}
          pagination
          paginationProps={{
            ...paginationProps
          }}
          deleteProps={{
            deleteQuery: 'catalog=false',
            messageModelKey: 'catalogFilter'
          }}
        />

        {shouldRenderCreateCatalogFilterModal && (
          <CreateCatalogFilterModal
            onSuccess={onSuccessCatalogFilter}
            parentCatalog={selectedCatalogForFilter}
            isVisible={isCreateCatalogFilterModalVisible}
            onHide={() => {
              onToggleCreateCatalogFilterModal(false);
              setSelectedCatalogForFilter(null);
              isCatalogFilterEditing && setIsCatalogFilterEditing(false);
            }}
            idForEdit={selectedCatalogForFilter ? selectedCatalogForFilter?.id : 0}
            isEditing={isCatalogFilterEditing}
          />
        )}

      </div>
    </>
  );
};
