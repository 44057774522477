import { inputTypes } from '../../../../constants';
import { SelectOptionType } from '../../../../models';

export const taskInputDataTypesOptions: SelectOptionType[] = [
    {
        label: 'string',
        value: 'string',
    },
    {
        label: 'float',
        value: 'float',
    },
    {
        label: 'int',
        value: 'int',
    },
];

export const taskFilePathDataTypesOptions: SelectOptionType[] = [
    {
        label: 'ANY',
        value: 'ANY',
    },
    {
        label: 'CSV',
        value: 'CSV',
    },
    {
        label: 'XML',
        value: 'XML',
    },
    {
        label: 'JSON',
        value: 'JSON',
    },
];

export const taskSwitchDataTypesOptions: SelectOptionType[] = [
    {
        label: 'bool',
        value: 'bool',
    },
];

const { input, dropdown } = inputTypes;

export const taskInputTypes = {
    input,
    dropdown,
    switch: 'switch',
    filePath: 'filePath',
};
export const taskInputTypesOptions: SelectOptionType[] = Object.entries(taskInputTypes).map(([key, value]) => ({
    label: value,
    value: value,
}));
