import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateConfigurationItemModal } from '../../../components/modals/CreateConfigurationItemModal';
import { toast } from '../../../components/ui';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
import { ConfigurationItemListModel, ConfigurationItemListResponseModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/configurationItemsThunk';
import { AdminTable } from '../components';

export const AdminConfigurationItemsPage: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({});

    const response: ConfigurationItemListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    const [selectedItemForEdit, setItemForEdit] = useState<any>(null);

    const requestList = (query: any, resetQuery?: boolean) => {
        dispatch(actions.request({ ...query }, resetQuery));
    };

    useEffect(() => {
        requestList({ page: 0 });
    }, []);

    const onRefresh = () => {
        !loading && requestList({});
    };

    const onClickEdit = (row: ConfigurationItemListModel) => {
        onToggleCreateModal(true);
        setItemForEdit(row);
    };
    const onClickCreate = () => {
        onToggleCreateModal(true);
        setItemForEdit(null);
    };
    const hideCreateModal = () => {
        onToggleCreateModal(false);
        !!selectedItemForEdit && setItemForEdit(null);
    };

    const onSuccess = isEditing => {
        toast.success(
            isEditing
                ? t('@configuration.item.success.edited', { defaultValue: 'Configuration item has been edited!' })
                : t('@configuration.item.success.created', { defaultValue: 'Configuration item has been created!' })
        );
        hideCreateModal();
        onRefresh();
    };

    return (
        <div className="">
            {shouldRenderCreateModal && (
                <CreateConfigurationItemModal
                    isVisible={isCreateModalVisible}
                    onHide={hideCreateModal}
                    onSuccess={onSuccess}
                    isEditing={!!selectedItemForEdit}
                    configurationItemForEdit={selectedItemForEdit}
                />
            )}
            <AdminTable
                modelEndpoint="configurationItems"
                requestList={requestList}
                hideIsActiveColumn
                columns={[
                    {
                        name: 'Key',
                        selector: row => row?.key ?? '',
                    },
                    {
                        name: 'Value',
                        selector: row => row?.value ?? '',
                    },
                ]}
                hideDelete
                response={response}
                loading={loading}
                initLoading={initLoading}
                onClickEdit={onClickEdit}
                onPageChanged={page => {
                    dispatch(actions.request({ page }));
                }}
                emptyDataProps={{
                    onClickAction: onClickCreate,
                }}
                headerButtonProps={{
                    onClickCreate,
                    onRefresh,
                    showDelete: false,
                }}
            />
        </div>
    );
};
