import React from 'react';
import { NotificationListModel } from '../../../models';
import { TimeLineNotificationItem } from './TimeLineNotificationItem';

interface ITimeLineNotificationGroup {
    notifications: NotificationListModel[];
    date: string;
    index: number;
    filterByCurrentAccount: boolean;
}

export const TimeLineNotificationGroup = React.memo(
    ({ date, notifications, index, filterByCurrentAccount }: ITimeLineNotificationGroup) => {
        return (
            <div className="w-full flex flex-col transition-all">
                <div className="w-full flex flex-row h-11 items-center bg-mainBackground z-1 sticky -top-6 transition-all duration-350">
                    <div className="w-4 mr-4 h-full">
                        <div className={`h-4 w-0.5 ${index > 0 ? 'bg-blueStroke' : 'bg-none'} mx-auto`} />
                        <div className={`w-3 h-3 rounded-full bg-blueStroke mx-auto`} />
                        <div className="h-4 w-0.5 bg-blueStroke mx-auto" />
                    </div>
                    <label className=" text-secondaryText leading-none text-sm font-medium">{date}</label>
                </div>
                <div className="flex flex-row w-full">
                    <div className="flex flex-col w-4 mr-4  h-full justify-center items-center">
                        <div className="h-full w-0.5 bg-blueStroke" />
                    </div>
                    <div className="flex flex-col flex-1">
                        {notifications.map(_notification => (
                            <TimeLineNotificationItem
                                filterByCurrentAccount={filterByCurrentAccount}
                                notification={_notification}
                                key={_notification.id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    },
    propsAreEqual
);

function propsAreEqual(prevProps: ITimeLineNotificationGroup, nextProps: ITimeLineNotificationGroup) {
    if (prevProps.notifications.length !== nextProps.notifications.length || prevProps.date !== nextProps.date) {
        return false;
    }

    return true;
}
