import React, { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UploadingRow } from './UploadingRow';

import { selectors, actions, ArtifactUploadingType } from '../../redux/thunk/app/artifact/uploadArtifactThunk';
import { toast } from '../ui';

export interface UploadingArtifactsRowListProps {
    isVisible?: boolean;
    className?: string;
    style?: CSSProperties;
}
export const UploadingArtifactsRowList: React.FC<UploadingArtifactsRowListProps> = ({
    isVisible = true,
    className,
    style,
}) => {
    const dispatch = useDispatch();
    const uploadArtifacts: ArtifactUploadingType[] = useSelector(selectors.getUploadArtifacts);

    const onCancelSingleUpload = (_upload: ArtifactUploadingType) => {
        _upload.cancelToken.cancel();
        dispatch(actions.closeSingleUpload(_upload.id));
        setTimeout(() => {
            const _message = `${_upload.title} has been canceled!`;
            toast.info(_message);
        }, 10);
    };

    const renderUploadingArtifacts = () => {
        return uploadArtifacts.map(_upload => (
            <UploadingRow
                key={_upload.id}
                id={_upload.id}
                errorMessage={_upload.errorMessage}
                onCancelRequest={() => onCancelSingleUpload(_upload)}
                title={_upload.title}
                progress={_upload.uploadProgress}
            />
        ));
    };
    return (
        <div
            className={`bg-white transition-all duration-500 rounded-b ${className} ${
                isVisible ? 'visible transition-all' : 'hidden transition-all'
            }`}
            style={{
                maxHeight: '60vh',
                overflowY: 'auto',
                ...style,
            }}
        >
            {renderUploadingArtifacts()}
        </div>
    );
};
