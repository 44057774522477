import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ReactComponent as CalendarImage } from '../../assets/icons/calendar-edit.svg';
import { inputAnimationClassName } from '../../constants';
import { CreateButton } from '../buttons';

export interface DateRangePickerInputProps {
    ranges: Range[];
    onChange: (item: RangeKeyDict) => any;
    onFinish?: () => void;
    maxDate?: Date;
}

export const DateRangePickerInput: React.FC<DateRangePickerInputProps> = ({ onChange, onFinish, ranges, maxDate }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<any>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (event.target instanceof HTMLElement) {
                if (!ref.current || ref.current.contains(event.target)) return;
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', (event: any) => listener(event));
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', (event: any) => listener(event));
            document.removeEventListener('touchstart', listener);
        };
    }, [ref]);

    return (
        <div ref={ref} className="relative">
            <button
                onClick={e => {
                    e.stopPropagation();
                    setIsOpen(prev => !prev);
                }}
                className={`p-2 border flex items-center border-mainBorder rounded text-blueMainText font-medium text-sm ${inputAnimationClassName}`}
            >
                <CalendarImage className="mr-2" />
                Filter dates
            </button>
            {!!isOpen && (
                <div className="absolute p-2 right-0 mt-2 z-50 bg-white  rounded dropdown-shadow">
                    <DateRangePicker
                        onChange={onChange}
                        className="max-w-full"
                        moveRangeOnFirstSelection={true}
                        editableDateInputs
                        months={1}
                        classNames={{
                            inputRanges: 'hidden opacity-0 border bg-mainBlue',
                            inputRangeInput: 'hidden opacity-0',
                        }}
                        color="#1D79F2"
                        ranges={ranges}
                        fixedHeight
                        maxDate={maxDate}
                        direction="horizontal"
                        calendarFocus="forwards"
                    />
                    <CreateButton
                        onClick={() => {
                            setIsOpen(false);
                            onFinish && onFinish();
                        }}
                        title="Done"
                        className="px-10  ml-auto "
                    />
                </div>
            )}
        </div>
    );
};
