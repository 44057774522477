import React, { CSSProperties, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '.';
import { conditionsOperators, inputAnimationClassName } from '../../constants';
import { useToggleVisibility } from '../../hooks';
import { Nullable, SelectOptionType } from '../../models';
import { Icon, IconTypes } from '../common';
import { ErrorTextComponent } from '../messages';
import { FullWrapperModal } from '../modals';
import { CommonTableProps, FiltersTable } from '../tables';
import { Loader } from '../ui';
import { RenderSelectedItemsComponent } from './components/RenderSelectedItemsComponent';

interface SelectWithFilterPopupFieldProps {
    label?: string;
    loading?: boolean;
    labelClassName?: string;
    labelStyle?: CSSProperties;
    className?: string;
    style?: CSSProperties;
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    required?: boolean;
    placeholder?: string;
    onChangeOperator: (item: Nullable<SelectOptionType>) => any;
    operatorSelected: Nullable<SelectOptionType>;
    selectedItems: SelectOptionType[];
    onChangeSelectedItems: (items: SelectOptionType[]) => any;
    disabled?: boolean;
    isClearable?: boolean;
    labelRight?: ReactNode;
    multiple?: boolean;
    showSelectedItemInsideInput?: boolean;
    renderSelectedItemsType?: 'auto-width' | 'full-width';
    commonTablePros?: CommonTableProps;
}

export const SelectWithFilterPopupField: React.FC<SelectWithFilterPopupFieldProps> = ({
    onChangeSelectedItems,
    selectedItems,
    className,
    disabled,
    errorMessage,
    isClearable,
    label,
    labelClassName,
    labelRight,
    onChangeOperator,
    operatorSelected,
    loading,
    multiple,
    placeholder,
    required,
    renderSelectedItemsType,
    showSelectedItemInsideInput,
    style,
    commonTablePros,
}) => {
    const { t } = useTranslation();
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility();

    const onHideModal = useCallback(() => onToggleVisibility(false), []);
    const onShowModal = useCallback(() => onToggleVisibility(true), []);

    const inputText = useMemo(() => {
        if (selectedItems.length < 1) return placeholder;

        if (showSelectedItemInsideInput && !multiple) return selectedItems[0].label;

        return t('@filters.selected', { count: selectedItems.length, defaultValue: '{{count}} Filter(s) selected!' });
    }, [multiple, placeholder, showSelectedItemInsideInput, selectedItems, t]);

    return (
        <>
            <div style={{ ...style }} className={`flex flex-col w-full ${className}`}>
                <div className="flex w-full justify-between">
                    <div className="flex flex-1 flex-col">
                        {label && (
                            <div
                                style={{
                                    minHeight: 24,
                                }}
                                className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                            >
                                <label className="text-xs sm:text-sm text-secondaryText font-normal">
                                    {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                                </label>
                                {labelRight && labelRight}
                            </div>
                        )}
                        <button
                            type="button"
                            className={`
                        ${disabled ? 'bg-disabledColor cursor-not-allowed' : 'cursor-pointer'} 
                        w-full bg-white rounded box-border flex flex-row items-center justify-between py-2 px-3 h-8 sm:h-10 border 
                        ${errorMessage ? 'border-mainError transition-all duration-300 ease-in' : 'border-grey200'}
                        ${!disabled && inputAnimationClassName}
                    `}
                            onClick={e => {
                                e.stopPropagation();
                                !disabled && onShowModal();
                            }}
                        >
                            <span
                                className={`truncate font-normal text-xs sm:text-sm ${
                                    selectedItems.length ? 'text-blueMainText' : 'text-mainPlaceholder'
                                }`}
                            >
                                {loading ? <Loader /> : inputText}
                            </span>
                            {isClearable && selectedItems.length > 0 && (
                                <Icon
                                    name={IconTypes.close}
                                    size={22}
                                    onClick={e => {
                                        e.stopPropagation();
                                        onChangeSelectedItems && onChangeSelectedItems([]);
                                    }}
                                />
                            )}
                        </button>
                    </div>
                    {selectedItems.length > 1 && (
                        <div>
                            <SelectField
                                options={conditionsOperators}
                                onChange={item => onChangeOperator(item)}
                                value={operatorSelected}
                                className="ml-4"
                                style={{ width: 130 }}
                                label={t('@any.or.all', { defaultValue: 'Any or All' })}
                            />
                        </div>
                    )}
                </div>
                <ErrorTextComponent message={errorMessage} />
                {(!showSelectedItemInsideInput || multiple) && (
                    <RenderSelectedItemsComponent
                        entity="filter"
                        onChangeSelectedItems={onChangeSelectedItems}
                        selectedItems={selectedItems}
                        operatorSelected={operatorSelected}
                        renderType={renderSelectedItemsType}
                    />
                )}
                {/* <div className="flex flex-row items-center flex-wrap mt-1 -ml-1">
                    {(!showSelectedItemInsideInput || multiple) &&
                        selectedItems.map((item, index) => (
                            <>
                                {index > 0 && (
                                    <div className="text-secondaryText font-semibold text-xs px-1">
                                        {operatorSelected?.value.toUpperCase()}
                                    </div>
                                )}
                                <SelectedOption
                                    label={item.label}
                                    onRemove={() =>
                                        onChangeSelectedItems(selectedItems.filter(x => x.value !== item.value))
                                    }
                                />
                            </>
                        ))}
                </div> */}
            </div>

            {shouldRenderModal && (
                <FullWrapperModal isVisible={isModalVisible} onHide={onHideModal}>
                    <div className="text-xl flex flex-row justify-between items-center px-4 mb-2 md:pl-6 pt-3 z-10 font-semibold text-secondaryText  top-0 w-full bg-white">
                        Filters
                        <span className="cursor-pointer p-2.5" onClick={() => onHideModal()}>
                            <Icon name={IconTypes.close} size={30} />
                        </span>
                    </div>
                    <FiltersTable
                        selectPopupProps={{
                            onChangeSelectedRows: items => {
                                onChangeSelectedItems(
                                    items.map(x => ({ label: x.title, value: x.id.toString(), ...x }))
                                );
                                onHideModal();
                            },
                        }}
                        fetchDataOnMount={false}
                        isFromSelectPopup
                        multiple
                        className="px-4 flex-1"
                        {...commonTablePros}
                    />
                </FullWrapperModal>
            )}
        </>
    );
};
