import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OutlineButton } from '../../../components/buttons';
import { Icon, IconTypes } from '../../../components/common';
import { AddNewUserModal } from '../../../components/modals';
import { NoDataComponentProps, NoDataImagesType, Table, TableFilters, TableMobile } from '../../../components/tables';
/**
 * Components
 */
import { toast } from '../../../components/ui';
import { breakpoints } from '../../../constants';
/**
 * hooks - utils - services
 */
import { useAccountId, useCurrentWidth } from '../../../hooks';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
/**
 * models - constants
 */
import { AccountUserListModel, AccountUserListResponseModel } from '../../../models';
/**
 * Redux
 */
import {
    actions as accountUsersActions,
    selectors as accountUsersSelectors,
} from '../../../redux/thunk/app/account/accountUsersThunk';
import { showFiltersHeader } from '../../../utils';

export const UserManagementTab = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accountId = useAccountId();
    const currentWidth = useCurrentWidth();
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });

    /**
     * Redux Selectors
     */
    const loading = useSelector(accountUsersSelectors.getIsLoading);
    const response: AccountUserListResponseModel = useSelector(accountUsersSelectors.getResponse);
    const initLoading = useSelector(accountUsersSelectors.getInitialLoading);

    /**
     * Local State
     */
    const [userForEdit, setUserForEdit] = useState<AccountUserListModel | null>(null);

    const requestList = (payload: object, reset?: boolean) => {
        dispatch(accountUsersActions.request({ accountId, ...payload }, reset));
    };

    const onHideAddUserModal = () => {
        onToggleCreateModal(false);
        setUserForEdit(null);
    };
    const onClickCreate = () => onToggleCreateModal(true);

    const onClickEditUser = row => {
        onToggleCreateModal(true);
        setUserForEdit(row);
    };

    const onRefresh = () => {
        requestList({});
    };

    const onPageChanged = page => {
        dispatch(accountUsersActions.request({ accountId, page }));
    };

    const onSuccess = edited => {
        onHideAddUserModal();
        toast.success(
            !!edited
                ? t('@components.modals.add-user.success-edit-user')
                : t('@components.modals.add-user.success-add-user')
        );
        dispatch(accountUsersActions.request({ accountId }));
    };

    const emptyDataProps: NoDataComponentProps = useMemo(
        () => ({
            emptyText: t('@pages.settings.no-user'),
            actionMessage: '+ ' + t('@pages.settings.add-user'),
            onClickAction: onClickCreate,
            imageType: NoDataImagesType.User,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage,
            pageItems: response.models.length,
            totalItems: response.totalItems,
            totalPages: response.totalPages,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const commonTableProps = {
        requestList,
        headerButtonProps: {
            createTitle: t('@pages.settings.add-user'),
            onClickCreate,
            onRefresh,
            showEdit: false,
            showRefresh: false,
        },
        modelEndpoint: 'accountUser',
        selectableRows: true,
        isFromSelectPopup: false,
        showHeaderButtons: true,
        emptyDataProps,
        loading,
        response,
    };

    return (
        <div className={`${currentWidth <= breakpoints.sm && 'p-2'}`}>
            {shouldRenderCreateModal && (
                <AddNewUserModal
                    onSuccess={onSuccess}
                    isVisible={isCreateModalVisible}
                    onHide={onHideAddUserModal}
                    userForEdit={userForEdit}
                    isEditing={!!userForEdit}
                />
            )}
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    style={{ boxShadow: 'none' }}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                            className="flex-row-reverse justify-between"
                        >
                            <div className="flex flex-1 justify-end">
                                <OutlineButton
                                    onClick={() => {
                                        onRefresh && onRefresh();
                                    }}
                                    icon={<Icon name={IconTypes.refresh} className="mr-2" />}
                                    title={t('@common.refresh')}
                                    className="bg-white mb-3"
                                />
                            </div>
                        </TableFilters>
                    }
                    pagination
                    paginationProps={paginationProps}
                    className="w-full mt-0 border border-mainBorder rounded"
                    columns={[
                        {
                            name: t('@common.user'),
                            selector: row => row?.user ?? '',
                            sortable: true,
                        },
                        {
                            name: t('@common.role'),
                            selector: row => row?.role ?? '',
                            sortable: true,
                            right: true,
                            width: '100px !important',
                            minWidth: 'auto !important',
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={[
                        {
                            action: 'edit',
                            onClick: onClickEditUser,
                        },
                        {
                            action: 'delete',
                        },
                    ]}
                />
            )}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="user"
                    initialLoading={initLoading}
                    columns={[
                        {
                            name: t('@common.user'),
                            selector: row => row?.user ?? '',
                        },
                        {
                            name: t('@common.role'),
                            selector: row => row?.role ?? '',
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                        },
                        {
                            name: t('@common.last-modified'),
                            selector: row => row?.lastModified ?? '',
                        },
                    ]}
                    actions={[
                        {
                            action: 'edit',
                            onClick: onClickEditUser,
                        },
                        {
                            action: 'delete',
                        },
                    ]}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
