import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CatalogsTable } from '../../../components/tables/CatalogsTable';
import { actions } from '../../../redux/thunk/app/catalog/catalogsThunk';

export const CatalogsPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(actions.removeNotificationQuery());
        };
    }, []);

    return (
        <div className="w-full">
            <CatalogsTable isFromSelectPopup={false} fetchDataOnMount />
        </div>
    );
};
