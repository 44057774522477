import { getAccountIdFromQuery } from './query-utils';

const getBaseQueryObject = () => {
    let accountId = getAccountIdFromQuery();
    return new URLSearchParams(accountId ? `accountId=${accountId}` : '');
};

export const queryParams = {
    formatForNavigation: () => {
        return '?' + getBaseQueryObject().toString();
    },
    appendNewKey: (key: string, value: string) => {
        let query = getBaseQueryObject();
        query.append(key, value);
        return '?' + query.toString();
    },
    setAccountId: (accountId: number) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('accountId');
        return `?accountId=${accountId}${searchParams?.toString() ? '&' + searchParams : ''}`;
    },
};
