import { Dispatch } from 'redux';
import { InitialStateType, WorkflowModelListResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator, getFormattedQuery } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/model/allModelsThunk/REQUEST',
    SUCCESS: '@app/model/allModelsThunk/SUCCESS',
    FAILURE: '@app/model/allModelsThunk/FAILURE',
};

/**
 * Initial state
 */
const initialState: InitialStateType<WorkflowModelListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        message: '',
        success: false,
        models: [],
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.allModels.isLoading,
    getResponse: (state: RootState) => state.app.allModels.response,
    getErrorMessage: (state: RootState) => state.app.allModels.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (state: InitialStateType<WorkflowModelListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query: any) {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                query.page = -1;
                const response: WorkflowModelListResponseModel = await axios.get(
                    `/model/list?${getFormattedQuery(query)}`
                );
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
