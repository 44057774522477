import { CSSProperties, ReactNode } from 'react';
import CreateSelect, { Props } from 'react-select/creatable';
import { SelectOptionType } from '../../models';
import { ErrorTextComponent } from '../messages';

export interface SelectCreatableProps extends Props<SelectOptionType, false> {
    label?: string;
    labelClassName?: string;
    labelStyle?: CSSProperties;
    hideLabel?: boolean;
    labelRight?: ReactNode;
    className?: string;
    style?: CSSProperties;
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    required?: boolean;
    options?: SelectOptionType[];
    value?: SelectOptionType | null;
    disabled?: boolean;
}

export const SelectCreatableField: React.FC<SelectCreatableProps> = ({
    children,
    hideLabel,
    label,
    labelClassName,
    labelStyle,
    labelRight,
    className,
    style,
    errorClassName,
    errorMessage,
    errorStyle,
    required,
    options,
    disabled,
    ...selectProps
}) => {
    return (
        <div className={`w-full flex flex-col ${className} ${disabled && 'cursor-not-allowed'}`} style={style}>
            {!hideLabel && (
                <div
                    style={{
                        minHeight: 24,
                    }}
                    className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                >
                    <label className="text-sm text-secondaryText font-normal">
                        {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                    </label>
                    {labelRight && labelRight}
                </div>
            )}
            <CreateSelect
                className="h-9"
                styles={{
                    clearIndicator: base => ({
                        ...base,
                    }),
                    placeholder: base => ({
                        ...base,
                        color: '#A3B0C2',
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        fontSize: 14,
                        fontWeight: 'normal',
                    }),
                    dropdownIndicator: base => ({
                        ...base,
                    }),
                    group: base => ({
                        ...base,
                    }),
                    groupHeading: base => ({
                        ...base,
                    }),
                    indicatorSeparator: () => ({
                        display: 'none',
                    }),
                    indicatorsContainer: base => ({
                        ...base,
                    }),
                    valueContainer: base => ({
                        ...base,
                        height: '40px !important',
                        minHeight: '40px !important',
                        maxHeight: '40px !important',
                        '@media only screen and (max-width: 640px)': {
                            height: '32px !important',
                            minHeight: '32px !important',
                            maxHeight: '32px !important',
                            paddingTop: '0px !important',
                        },
                    }),
                    singleValue: base => ({
                        ...base,
                        fontSize: 14,
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                    }),
                    option: (b, state) => ({
                        ...b,
                        fontSize: 14,
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        color: '#052D61',
                        fontWeight: 'normal',
                        backgroundColor: state.isSelected ? '#caebfa' : state.isFocused ? '#F6F7F9' : 'white',
                        ':active': {
                            backgroundColor: '#caebfa',
                        },
                        cursor: 'pointer',
                    }),
                    container: base => ({
                        ...base,
                        height: '40px !important',
                        minHeight: '40px !important',
                        maxHeight: '40px !important',
                        '@media only screen and (max-width: 640px)': {
                            height: '32px !important',
                            minHeight: '32px !important',
                            maxHeight: '32px !important',
                        },
                    }),
                    control: base => ({
                        ...base,
                        border: `1px solid ${errorMessage ? '#ff0033' : '#E2E2E2'}`,
                        height: '40px !important',
                        minHeight: '40px !important',
                        maxHeight: '40px !important',
                        '@media only screen and (max-width: 640px)': {
                            height: '32px !important',
                            minHeight: '32px !important',
                            maxHeight: '32px !important',
                        },
                        cursor: 'pointer',
                    }),
                    input: base => ({
                        ...base,
                        color: '#052D61',
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        fontSize: 14,
                        fontWeight: 'normal',
                    }),
                }}
                // noOptionsMessage={selectProps?.noOptionsMessage ? selectProps.noOptionsMessage : t('@common.no-options-dropdown')}
                {...selectProps}
                isDisabled={disabled}
                options={options}
            />
            <ErrorTextComponent message={errorMessage} />
            {children}
        </div>
    );
};
