import { createContext } from 'react';
import { StorageObjectListModel } from '../models';

export type storagePageContextProps = {
    selectedItemForRename: StorageObjectListModel | null;
    setSelectedItemForRename: React.Dispatch<React.SetStateAction<StorageObjectListModel | null>> | null;
};

export const storagePageContext = createContext<storagePageContextProps>({
    selectedItemForRename: null,
    setSelectedItemForRename: null,
});
