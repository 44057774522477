import { CSSProperties, FC, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { pipeline } from 'stream';
import { inputAnimationClassName } from '../../constants';
import { useAccountId } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { DetailsResponseModel, SelectOptionType } from '../../models';
import axios from '../../services/axios';
import { getFormattedQuery, upperCaseFirstCharacter } from '../../utils';
import { Icon, IconTypes } from '../common';
import { ErrorTextComponent } from '../messages';
import { FullWrapperModal } from '../modals';
import { AlgorithmsTable, ArtifactsTable, DataSourceTable, FiltersTable, ModelsTable } from '../tables';
import { CatalogsTable } from '../tables/CatalogsTable';
import { PipelinesTable } from '../tables/PipelinesTable';
import { Loader } from '../ui';

type selectItem = {
    value: string;
    label: string;
};

export enum SelectWithPopupTypes {
    artifact = 'artifact',
    dataSource = 'dataSource',
    algorithm = 'algorithm',
    model = 'model',
    catalog = 'catalog',
    schema = 'schema',
    filter = 'filter',
    pipeline = 'pipeline',
}
export interface TablePopupProps {
    onSelect?: (item: selectItem) => any;
    selectedItem?: any;
    selectedId?: string | number;
}
export interface SelectWithPopupFieldProps {
    label?: string;
    loading?: boolean;
    labelClassName?: string;
    labelStyle?: CSSProperties;
    hideLabel?: boolean;
    className?: string;
    style?: CSSProperties;
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    required?: boolean;
    selectedItem?: { value: string; label: string } | null;
    onChangeItem: (item: SelectOptionType | null) => any;
    placeholder?: string;
    modalChildren?: ReactNode;
    tableType?: SelectWithPopupTypes;
    disabled?: boolean;
    fetchDataOnMount?: boolean;
    popupKeyTitle?: string;
    query?: any;
    hideFilters?: boolean;
    disableFilters?: boolean;
    disableSpecificFilter?: any;
    isClearable?: boolean;
    selectedId?: string | number;
    labelRight?: ReactNode;
    id?: string;
}

export const SelectWithPopupField: FC<SelectWithPopupFieldProps> = ({
    className,
    errorMessage,
    hideLabel,
    required,
    label,
    labelClassName,
    onChangeItem,
    selectedItem,
    selectedId,
    style,
    placeholder,
    tableType,
    disabled,
    fetchDataOnMount = true,
    popupKeyTitle,
    id,
    query,
    disableFilters,
    disableSpecificFilter,
    hideFilters,
    isClearable,
    labelRight,
    loading,
}) => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility();

    const onHideModal = () => onToggleVisibility(false);
    const onShowModal = () => onToggleVisibility(true);

    useEffect(() => {
        if (selectedId)
            if (typeof selectedId === 'string' || typeof selectedId === 'number') fetchSelectedItem(selectedId);
    }, [selectedId]);

    const fetchSelectedItem = async id => {
        let selectedItem: SelectOptionType | null = null;

        let query = {
            id,
            accountId,
        };

        let pathAndQuery = `/${tableType}?${getFormattedQuery(query)}`;
        let { model: item }: DetailsResponseModel<any> = await axios.get(pathAndQuery);
        selectedItem = {
            label: item?.name || item?.title,
            value: id?.toString(),
            ...item,
        };
        onChangeItem && onChangeItem(selectedItem);
    };
    const tableProps = useMemo(() => {
        return {
            tablePopupProps: {
                onSelect: item => {
                    onChangeItem && onChangeItem({ ...item });
                    onHideModal();
                },
                selectedItem,
                selectedId,
            },
            isFromSelectPopup: true,
            showHeaderButtons: true,
            fetchDataOnMount,
            className: 'pb-4 px-4 md:px-6',
            query,
            disableFilters,
            disableSpecificFilter,
            hideFilters,
        };
    }, [selectedItem]);

    const renderTablePage = () => {
        const { algorithm, artifact, dataSource, model, catalog, filter, pipeline } = SelectWithPopupTypes;

        switch (tableType) {
            case artifact:
                return <ArtifactsTable {...tableProps} />;
            case dataSource:
                return <DataSourceTable {...tableProps} />;
            case algorithm:
                return <AlgorithmsTable {...tableProps} />;
            case model:
                return <ModelsTable {...tableProps} />;
            case catalog:
                return <CatalogsTable {...tableProps} />;
            case filter:
                return <FiltersTable {...tableProps} />;
            case pipeline:
                return <PipelinesTable {...tableProps} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div style={{ ...style }} className={`flex flex-col w-full ${className}`} id={id}>
                {!hideLabel && (
                    <div
                        style={{
                            minHeight: 24,
                        }}
                        className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                    >
                        <label className="text-xs sm:text-sm text-secondaryText font-normal">
                            {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                        </label>
                        {labelRight && labelRight}
                    </div>
                )}
                <button
                    type="button"
                    className={`
                        ${disabled ? 'bg-disabledColor cursor-not-allowed' : 'cursor-pointer'} 
                        w-full bg-white rounded box-border flex flex-row items-center justify-between py-2 px-3 h-8 sm:h-10 border 
                        ${errorMessage ? 'border-mainError transition-all duration-300 ease-in' : 'border-grey200'}
                        ${!disabled && inputAnimationClassName}
                    `}
                    onClick={e => {
                        e.stopPropagation();
                        !disabled && onShowModal();
                    }}
                >
                    <span
                        className={`truncate font-normal text-xs sm:text-sm ${
                            selectedItem ? 'text-blueMainText' : 'text-mainPlaceholder'
                        }`}
                    >
                        {loading ? <Loader /> : selectedItem ? selectedItem.label : placeholder}
                    </span>
                    {isClearable && !!selectedItem && (
                        <Icon
                            name={IconTypes.close}
                            size={22}
                            onClick={e => {
                                e.stopPropagation();
                                onChangeItem && onChangeItem(null);
                            }}
                        />
                    )}
                </button>
                <ErrorTextComponent message={errorMessage} />
            </div>
            {shouldRenderModal && (
                <FullWrapperModal isVisible={isModalVisible} onHide={onHideModal}>
                    <span className="text-xl flex flex-row justify-between items-center pb-4 px-4 md:pl-6 pt-3 z-10 font-semibold text-secondaryText sticky top-0 w-full bg-white">
                        {popupKeyTitle
                            ? t(popupKeyTitle)
                            : `${t('@common.select')} ${upperCaseFirstCharacter(tableType)}`}
                        <span className="cursor-pointer p-2.5" onClick={() => onHideModal()}>
                            <Icon name={IconTypes.close} size={30} />
                        </span>
                    </span>
                    {renderTablePage()}
                </FullWrapperModal>
            )}
        </>
    );
};
