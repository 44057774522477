import { actionCreator } from '../../../../utils';
import { ArtifactContentTypesListResponseModel } from '../../../../models';
import { InitialStateType } from '../../../../models';
import { RootState } from '../../rootReducer';
import { Dispatch } from 'redux';
import axios from '../../../../services/axios';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/artifact/artifactContentTypesThunk/REQUEST',
    SUCCESS: '@app/artifact/artifactContentTypesThunk/SUCCESS',
    FAILURE: '@app/artifact/artifactContentTypesThunk/FAILURE',
};

/**
 * Initial state
 */
const initialState: InitialStateType<ArtifactContentTypesListResponseModel> = {
    isLoading: false,
    initialLoading: false,
    errorMessage: '',
    response: {
        success: false,
        message: '',
        models: [],
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.artifactContentTypes.isLoading,
    getResponse: (state: RootState) => state.app.artifactContentTypes.response,
    getErrorMessage: (state: RootState) => state.app.artifactContentTypes.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (state: InitialStateType<ArtifactContentTypesListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                initialLoading: false,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                initialLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const response: ArtifactContentTypesListResponseModel = await axios.get(
                    `/artifactContentType/list?page=-1`
                );
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
