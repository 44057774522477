import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
/**
 * components
 */
import { HeaderForCreatePages } from '../../../components/common';
import DynamicInputsForm, { SingleItemInputsType } from '../../../components/common/dynamic-inputs/DynamicInputsForm';
import { InputField, SwitchInput } from '../../../components/inputs';
import { LoadingModal } from '../../../components/modals';
import { toast } from '../../../components/ui';
/**
 * constants - models
 */
import { ADMIN_DATA_SOURCE_TYPES_PATH, dataTypesOptions, inputTypeOptions } from '../../../constants';
/**
 * hooks - services
 */
import { useCurrentWidth } from '../../../hooks';
import { DataSourceTypeDetailsResponseModel, ExternalSourceTypeListResponseModel } from '../../../models';
import { selectors as externalSourceSelectors } from '../../../redux/thunk/app/externalSourceTypesThunk';
import axios from '../../../services/axios';
import { formatArrayForSelectOptions, getFormattedQuery } from '../../../utils';
import { AdminFooterButtons } from '../components/AdminFooterButtons';

type fieldValuesType = {
    name: string;
    isActive: boolean;
};

export const AdminCreateDataSourceTypePage = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const history = useHistory();
    const params: any = useParams();

    /**
     * constants - types - ref
     */
    const isEditing = params?.id;
    type inputsRefType = React.ElementRef<typeof DynamicInputsForm>;
    const inputsRef = useRef<inputsRefType>(null);
    const propertiesRef = useRef<inputsRefType>(null);

    const { models: externalSources }: ExternalSourceTypeListResponseModel = useSelector(
        externalSourceSelectors.getResponse
    );
    const externalSourcesLoading = useSelector(externalSourceSelectors.getIsLoading);

    /**
     * Local State
     */
    const [loadingModal, setLoadingModal] = useState(false);
    const [fieldValues, setFieldValues] = useState<fieldValuesType>({
        name: '',
        isActive: false,
    });

    useEffect(() => {
        if (isEditing && !externalSourcesLoading) {
            const id = params?.id;
            fetchDataSourceTypeDetails(id);
        }
    }, [params, externalSourcesLoading]);

    const fetchDataSourceTypeDetails = async id => {
        try {
            setLoadingModal(true);
            const response: DataSourceTypeDetailsResponseModel = await axios.get(
                `/dataSourceType?${getFormattedQuery({ id })}`
            );
            mapDetailsInState(response);
        } catch (err: any) {
        } finally {
            setLoadingModal(false);
        }
    };

    const mapDetailsInState = (response: DataSourceTypeDetailsResponseModel) => {
        const {
            model: { properties: _properties, inputs: _inputs, name, isActive },
        } = response;

        let inputs: SingleItemInputsType[] = [];
        let properties: SingleItemInputsType[] = [];

        setFieldValues({ name, isActive });

        if (_properties) {
            properties = _properties?.map((_property, i) => ({
                ..._property,
                _id: i + 1,
                dataTypeId:
                    dataTypesOptions.find(_dataTypeOption => _property.dataTypeId === _dataTypeOption.value) || null,
                options: _property?.options.map((_propertyOption, i) => ({
                    id: _propertyOption.id || 0,
                    _id: i + 1,
                    ..._propertyOption,
                })),
                inputType:
                    inputTypeOptions?.find(_inputTypeOption => _inputTypeOption.value === _property.inputType) || null,
                externalSourceTypeId: formatArrayForSelectOptions(externalSources, 'title', 'id').find(
                    (_externalSource: any) => _externalSource?.value === _property.externalSourceTypeId?.toString()
                ),
            }));
        }
        if (_inputs) {
            inputs = _inputs?.map((_input, i) => ({
                ..._input,
                isRepeatable: _input.isRepeatable,
                dataTypeId:
                    dataTypesOptions.find(_dataTypeOption => _dataTypeOption.value === _input.dataTypeId) || null,
                _id: i + 1,
                options: _input?.options.map((_inputOption, i) => ({
                    id: _inputOption.id || 0,
                    _id: i + 1,
                    ..._inputOption,
                })),
                inputType:
                    inputTypeOptions?.find(_inputTypeOption => _inputTypeOption.value === _input.inputType) || null,
                externalSourceTypeId: formatArrayForSelectOptions(externalSources, 'title', 'id').find(
                    (_externalSource: any) => _externalSource?.value === _input.externalSourceTypeId?.toString()
                ),
            }));
        }

        if (inputsRef.current?.setInputs) {
            inputsRef.current?.setInputs(inputs);
        }
        if (propertiesRef?.current?.setInputs) {
            propertiesRef?.current?.setInputs(properties);
        }
    };

    const onChangeInputs = (key, value) => {
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleCreate = async (save?: boolean) => {
        try {
            let inputs: SingleItemInputsType[] = inputsRef.current?.getInputs() || [];
            let properties: SingleItemInputsType[] = propertiesRef.current?.getInputs() || [];

            setLoadingModal(true);

            let payload: {
                id: number;
                name: string;
                isActive: boolean;
                properties: any[];
                inputs: any[];
            } = {
                id: isEditing ? params?.id : 0,
                name: fieldValues.name,
                isActive: fieldValues.isActive,
                properties: properties.map(x => ({
                    id: x.id,
                    dataTypeId: x.dataTypeId?.value,
                    defaultValue: x.defaultValue,
                    inputType: x.inputType?.value || '',
                    name: x.name,
                    options: x.options.map(x => ({
                        label: x.label,
                        value: x.value,
                        id: x.id,
                    })),
                    required: x.required,
                    orderNumber: x.orderNumber,
                    isColumn: x.isColumn,
                    conditionalId: x.conditionalId || null,
                    conditionalValue: x.conditionalValue,
                    infoText: x.infoText,
                    infoLink: x.infoLink,
                    externalSourceTypeId: x?.externalSourceTypeId?.value || null,
                    isRepeatable: x.isRepeatable,
                })),
                inputs: inputs.map(x => ({
                    id: x.id,
                    dataTypeId: x.dataTypeId?.value,
                    defaultValue: x.defaultValue,
                    inputType: x.inputType?.value || '',
                    name: x.name,
                    options: x.options.map(x => ({
                        label: x.label,
                        value: x.value,
                        id: x.id,
                    })),
                    required: x.required,
                    orderNumber: x.orderNumber,
                    isColumn: x.isColumn,
                    conditionalId: x.conditionalId || null,
                    conditionalValue: x.conditionalValue,
                    infoText: x.infoText,
                    infoLink: x.infoLink,
                    externalSourceTypeId: x?.externalSourceTypeId?.value || null,
                    isRepeatable: x.isRepeatable,
                })),
            };

            let res: DataSourceTypeDetailsResponseModel = await axios.post('/dataSourceType', payload);
            if (save) {
                toast.success(`Data Source Type Saved Successfully!`);
                if (isEditing) mapDetailsInState(res);
                else history.push(`/admin/data-source-types/edit-data-source-type/${res.model.id}`);
            } else {
                toast.success(`Data Source Type ${isEditing ? 'Edited ' : 'Created'} Successfully!`);
                history.push(ADMIN_DATA_SOURCE_TYPES_PATH);
            }
        } catch (err: any) {
            console.log({ err });
        } finally {
            setLoadingModal(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleCreate();
    };

    return (
        <PageAnimationContainer>
            <div>
                <form className="flex flex-col w-full items-center pb-4" onSubmit={handleSubmit}>
                    {loadingModal && <LoadingModal isVisible />}
                    <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                    <div className="flex flex-col" style={{ width: currentWidth < 900 ? '100%' : 546 }}>
                        <InputField
                            name="dataSourceTypeName"
                            label="Name"
                            className="w-full"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('name', e.target.value)}
                            value={fieldValues.name}
                            required
                        />
                        <SwitchInput
                            value={fieldValues.isActive}
                            onChange={val => onChangeInputs('isActive', val)}
                            label={t('@common.is-active')}
                            className="mt-4"
                        />
                    </div>

                    <div className="flex flex-wrap flex-col md:flex-row w-full mt-4">
                        <div className="flex flex-1 flex-col mt-2 md:mr-2">
                            <HeaderForCreatePages hideIcon title="Properties" />
                            <DynamicInputsForm
                                containerStyle={{ padding: 0 }}
                                innerStyle={{ padding: 12 }}
                                ref={propertiesRef}
                                isEditing={isEditing}
                            />
                        </div>
                        <div className={`flex flex-1 flex-col mt-2 md:ml-2"`}>
                            <HeaderForCreatePages hideIcon title="Inputs" />
                            <DynamicInputsForm
                                containerStyle={{ padding: 0 }}
                                innerStyle={{ padding: 12 }}
                                ref={inputsRef}
                                isEditing={isEditing}
                            />
                        </div>
                    </div>

                    <HeaderForCreatePages hideIcon title="" />
                    <AdminFooterButtons
                        submitTitle={isEditing ? 'Edit Data Source Type' : 'Create Data Source Type'}
                        onClickSave={() => handleCreate(true)}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
