import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import { Bisko403ErrorAlert } from '../../../components/bisko';
/**
 * Components
 */
import { InformationCard } from '../../../components/cards';
import { Loader } from '../../../components/ui';
import { ACCOUNT_SETTINGS_PATH, NOT_FOUND_PATH, PropensityEngineTypes, QueryParamsKeys } from '../../../constants';
import { useAccountId } from '../../../hooks';
import {
    AccountDetailsResponseModel,
    PropensityEngineProductTypeListResponseModel,
    StateErrorType,
} from '../../../models';
import { selectors as accountDetailsSelectors } from '../../../redux/thunk/app/account/accountDetailsThunk';
import { actions as propensityEngineActions } from '../../../redux/thunk/app/engines/propensity/propensityEnginesThunk';
import {
    actions as propensityEnginesProductTypesActions,
    selectors as propensityEnginesProductTypesSelectors,
} from '../../../redux/thunk/app/engines/propensity/propensityProductTypesThunk';
/**
 * redux
 */
import { selectors as biskoPropertiesSelectors } from '../../../redux/thunk/bisko/biskoPropertiesThunk';
import { queryParams, upperCaseFirstCharacter } from '../../../utils';
import { settingsTabs } from '../../settings';
import { PropensityEngineInformation } from './components';
import { PropensityEngineTable } from './components/PropensityEngineTable';

export interface PropensityEnginePageProps {
    propensityEngineType: PropensityEngineTypes;
}

export const PropensityEnginePage: React.FC<PropensityEnginePageProps> = ({ propensityEngineType }) => {
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    /**
     * Redux Selectors
     */
    const biskoPropertiesError: StateErrorType = useSelector(biskoPropertiesSelectors.getError);
    const biskoPropertiesLoading = useSelector(biskoPropertiesSelectors.getIsLoading);
    const accountLoading = useSelector(accountDetailsSelectors.getIsLoading);
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const { models: propensityEngineProductTypes }: PropensityEngineProductTypeListResponseModel = useSelector(
        propensityEnginesProductTypesSelectors.getResponse
    );

    useEffect(() => {
        if (!propensityEngineType || !location.pathname.includes(propensityEngineType)) {
            history.push(NOT_FOUND_PATH + location.search);
        }
    }, [location.pathname]);

    useEffect(() => {
        return () => {
            dispatch(propensityEngineActions.setEmptyResponse());
            dispatch(propensityEngineActions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        propensityEngineProductTypes.length < 1 && dispatch(propensityEnginesProductTypesActions.request());
    }, [accountId]);

    const renderTable = () => {
        if (accountLoading || biskoPropertiesLoading) {
            return (
                <div className="flex w-full justify-center items-center p-10">
                    <Loader />
                </div>
            );
        }
        if (biskoPropertiesError?.status === 404) {
            return (
                <div className="flex w-full flex-col md:flex-row">
                    <div className="flex flex-1 md:mr-5"></div>
                    <span className=" flex-1 md:ml-5 text-deleteColor text-sm font-normal mt-2 whitespace-pre-wrap pb-10">
                        {t('@to.use.propensityEngine.add.bisko.integration', {
                            defaultValue: 'To use Propensity engine you should Add a Bisko Integration!',
                        })}
                        {'\n'}
                        {accountDetails.isAdmin && (
                            <Link
                                to={{
                                    pathname: ACCOUNT_SETTINGS_PATH,
                                    search: queryParams.appendNewKey(
                                        QueryParamsKeys.tab_key,
                                        settingsTabs.accountIntegrations
                                    ),
                                }}
                                className="text-mainBlue font-medium mt-1 underline cursor-pointer"
                            >
                                {t('@go.to.integrationsTab', { defaultValue: 'Go to Integrations Tab' })}
                            </Link>
                        )}
                    </span>
                </div>
            );
        }

        if (biskoPropertiesError?.status === 403)
            return (
                <div className="flex w-full flex-col md:flex-row">
                    <div className="flex flex-1 md:mr-5" />
                    <span className=" flex-1 md:ml-5 text-deleteColor text-sm font-normal mt-2 whitespace-pre-wrap pb-10">
                        {t('@error.bisko.user.access', {
                            defaultValue: `Bisko Error: You don't have access to bisko organization!`,
                        })}
                    </span>
                </div>
            );

        return <PropensityEngineTable propensityEngineType={propensityEngineType} />;
    };

    return (
        <PageAnimationContainer>
            <div className="">
                <Bisko403ErrorAlert error={biskoPropertiesError} className="mb-4" />
                <InformationCard
                    title={`Propensity Engine - ${upperCaseFirstCharacter(propensityEngineType)}`}
                    showBackButton={false}
                    style={{ height: 'auto', flex: 1 }}
                    className="items-center"
                >
                    <div className="flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 w-full">
                        <PropensityEngineInformation />
                        {renderTable()}
                    </div>
                </InformationCard>
            </div>
        </PageAnimationContainer>
    );
};
