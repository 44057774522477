import { CSSProperties, forwardRef, ImgHTMLAttributes } from 'react';
import check from '../../assets/icons/check.svg';
import chevron_left from '../../assets/icons/chevron-left.svg';
import chevron_right from '../../assets/icons/chevron-right.svg';
import chevron_up_white from '../../assets/icons/chevron-up-white.svg';
import chevron_up from '../../assets/icons/chevron-up.svg';
import close_bold from '../../assets/icons/close-bold.svg';
import close_white from '../../assets/icons/close-white.svg';
import close from '../../assets/icons/close.svg';
import { default as datasource, default as dataSourceReader } from '../../assets/icons/datasource.svg';
import default_disabled from '../../assets/icons/default-disabled.svg';
import _default from '../../assets/icons/default.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import deploy from '../../assets/icons/deploy.svg';
import details from '../../assets/icons/details.svg';
import download_disabled from '../../assets/icons/download-disabled.svg';
import download from '../../assets/icons/download.svg';
import edit from '../../assets/icons/edit.svg';
import eye_off from '../../assets/icons/eye-off.svg';
import eye from '../../assets/icons/eye.svg';
import failed from '../../assets/icons/failed.svg';
import filter_blue from '../../assets/icons/filter-blue.svg';
import info from '../../assets/icons/info.svg';
import icon_logo from '../../assets/icons/logo-icon.svg';
import menu from '../../assets/icons/menu.svg';
import notification from '../../assets/icons/notification.svg';
import pending from '../../assets/icons/pending.svg';
import plus_main_color from '../../assets/icons/plus-main-color.svg';
import plus from '../../assets/icons/plus.svg';
import refresh from '../../assets/icons/refresh.svg';
import run_disabled from '../../assets/icons/run-disabled.svg';
import run from '../../assets/icons/run.svg';
import running from '../../assets/icons/running.gif';
import success from '../../assets/icons/success.svg';
import Terminating from '../../assets/icons/Terminating.svg';
import upload_blue from '../../assets/icons/upload-blue.svg';
import upload from '../../assets/icons/upload.svg';
import versions from '../../assets/icons/versions.svg';
import versions_disabled from '../../assets/icons/versions_disabled.svg';
import warning from '../../assets/icons/warning.svg';

export enum IconTypes {
    details = 'details',
    dataSourceReader = 'dataSourceReader',
    default = 'default',
    'default-disabled' = 'default-disabled',
    close = 'close',
    'close-white' = 'close-white',
    'close-bold' = 'close-bold',
    upload = 'upload',
    'upload-blue' = 'upload-blue',
    Terminating = 'Terminating',
    Terminated = 'Terminated',
    Terminate = 'Terminate',
    edit = 'edit',
    delete = 'delete',
    'logo-icon' = 'logo-icon',
    notification = 'notification',
    plus = 'plus',
    'plus-main-color' = 'plus-main-color',
    info = 'info',
    check = 'check',
    'chevron-up' = 'chevron-up',
    'chevron-up-white' = 'chevron-up-white',
    'chevron-left' = 'chevron-left',
    'chevron-right' = 'chevron-right',
    eye = 'eye',
    'eye-off' = 'eye-off',
    datasource = 'datasource',
    'Running' = 'Running',
    'Succeeded' = 'Succeeded',
    'Failed' = 'Failed',
    'Error' = 'Error',
    'Saved' = 'Saved',
    'Pending' = 'Pending',
    run = 'run',
    'versions-disabled' = 'versions-disabled',
    'run-disabled' = 'run-disabled',
    deploy = 'deploy',
    'deploy-disabled' = 'deploy-disabled',
    warning = 'warning',
    refresh = 'refresh',
    menu = 'menu',
    versions = 'versions',
    'filter-blue' = 'filter-blue',
    download = 'download',
    'download-disabled' = 'download-disabled',
}

export interface IconProps extends ImgHTMLAttributes<HTMLImageElement> {
    name: IconTypes;
    style?: CSSProperties;
    className?: string;
    height?: number;
    width?: number;
    size?: number;
}

export const icons = {
    close,
    'close-white': close_white,
    'upload-blue': upload_blue,
    upload,
    edit,
    download,
    delete: deleteIcon,
    'logo-icon': icon_logo,
    notification,
    plus,
    'plus-main-color': plus_main_color,
    info,
    Terminating,
    Terminate: Terminating,
    Terminated: Terminating,
    check,
    'filter-blue': filter_blue,
    'chevron-up': chevron_up,
    'chevron-up-white': chevron_up_white,
    'chevron-left': chevron_left,
    'chevron-right': chevron_right,
    eye,
    'eye-off': eye_off,
    datasource,
    Running: running,
    Succeeded: success,
    details,
    Failed: failed,
    Error: failed,
    Saved: pending,
    Pending: pending,
    run,
    'run-disabled': run_disabled,
    deploy,
    'deploy-disabled': deploy,
    warning,
    refresh,
    menu,
    versions,
    'versions-disabled': versions_disabled,
    'close-bold': close_bold,
    default: _default,
    'default-disabled': default_disabled,
    'download-disabled': download_disabled,
    dataSourceReader,
};

export type Ref = HTMLImageElement;

export const Icon = forwardRef<Ref, IconProps>(
    ({ name, style, className, height = 18, width = 18, size, ...props }, ref) => {
        if (IconTypes[name]) {
            return (
                <img
                    ref={ref}
                    alt={name}
                    src={icons[name]}
                    style={{
                        ...style,
                        height: size || height,
                        minHeight: size || height,
                        maxHeight: size || height,
                        maxWidth: size || width,
                        width: size || width,
                        opacity: name?.includes('disabled') ? 0.5 : 1,
                    }}
                    height={height || size}
                    width={width || size}
                    className={`object-cover ${className}`}
                    {...props}
                />
            );
        }
        return <span>{name}</span>;
    }
);
