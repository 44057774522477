import { useSelector } from 'react-redux';
import { EditAccountCard } from '../../../components/cards';
import { AccountDetailsResponseModel } from '../../../models';
import { selectors } from '../../../redux/thunk/app/account/accountDetailsThunk';

export const EditAccountTab = () => {
    const accountDetails: AccountDetailsResponseModel = useSelector(selectors.getResponse);
    const isAccountDetailsLoading = useSelector(selectors.getIsLoading);

    return <EditAccountCard account={accountDetails.model} isLoading={isAccountDetailsLoading} />;
};
