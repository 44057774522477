import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { inputTypes } from '../../constants';
/**
 * constants - hooks - services - utils
 */
import { useAccountIdString } from '../../hooks';
/**
 * Models
 */
import {
    BaseEntityValue,
    DataSourceDetailsResponseModel,
    DataSourceTypeDetailsResponseModel,
    DataSourceTypeListResponseModel,
    DataSourceTypeProperties,
    SelectOptionType,
} from '../../models';
/**
 * Redux
 */
import { selectors as dataSourceTypesSelectors } from '../../redux/thunk/app/dataSource/dataSourceTypesThunk';
import axios from '../../services/axios';
import {
    addNewOptionToDynamicInputs,
    deleteErrorKey,
    formatArrayForSelectOptions,
    getFormattedQuery,
} from '../../utils';
import { DynamicInputs, shouldDynamicInputRender } from '../common';
/**
 * Components
 */
import { InputField, SelectField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateDataSourceModalProps {
    isVisible: boolean;
    onHide: () => any;
    isEditing?: boolean;
    dataSourceId?: any;
    onSuccess?: (item: any, isEditing: boolean) => any;
}

export const CreateDataSourceModal: React.FC<CreateDataSourceModalProps> = ({
    isVisible,
    onHide,
    isEditing,
    dataSourceId,
    onSuccess,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const accountId = useAccountIdString();

    /**
     * Local State
     */
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [typeSelected, setType] = useState<SelectOptionType | null>(null);
    const [name, setName] = useState('');
    const [inputs, setInputs] = useState<DataSourceTypeProperties[]>([]);
    const [propertiesLoading, setPropertiesLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [formLoading, setFormLoading] = useState(false);

    /**
     * Redux Selectors
     */
    const dataSourceTypeResponse: DataSourceTypeListResponseModel = useSelector(dataSourceTypesSelectors.getResponse);
    const dataSourceTypeLoading = useSelector(dataSourceTypesSelectors.getIsLoading);

    /**
     * Effects
     */
    useEffect(() => {
        if (isEditing && dataSourceId) {
            fetchDetails(dataSourceId);
        }
    }, [isEditing, dataSourceId]);

    const fetchDetails = async dataSourceId => {
        try {
            setDetailsLoading(true);
            const dataSourceDetailsQuery = {
                id: dataSourceId,
                accountId,
            };
            const dataSourceDetailsResponse: DataSourceDetailsResponseModel = await axios.get(
                `/dataSource?${getFormattedQuery(dataSourceDetailsQuery)}`
            );

            const { dataSourceTypeId, name, values } = dataSourceDetailsResponse.model;

            const dataSourceTypeQuery = {
                id: dataSourceTypeId,
                accountId,
            };
            const dataSourceTypeDetails: DataSourceTypeDetailsResponseModel = await axios.get(
                `/dataSourceType?${getFormattedQuery(dataSourceTypeQuery)}`
            );

            let inputs: DataSourceTypeProperties[][] = dataSourceTypeDetails.model.properties.map(
                (property: DataSourceTypeProperties) => {
                    let _inputs: BaseEntityValue[] | undefined =
                        values.filter(el => el.propertyId === property.id) || [];

                    if (_inputs.length < 1) {
                        _inputs = [
                            {
                                id: 0,
                                propertyId: property.id,
                                propertyName: property.name,
                                value: property.defaultValue,
                            },
                        ];
                    }

                    let _arr = _inputs?.map(input => {
                        let options = property.options;

                        let value: any = null;
                        if (
                            property.inputType === inputTypes.dropdown ||
                            property.inputType === inputTypes.dropdownOpen
                        ) {
                            value = input?.value
                                ? property?.options?.find(x => x.value === input?.value)
                                : property?.options?.find(x => x.value === property?.defaultValue) || null;
                            if (!value && input?.value) {
                                let newOption = {
                                    label: input?.value || '',
                                    value: input?.value || '',
                                };
                                options.push(newOption);
                                value = newOption;
                            }
                        } else {
                            value = input?.value || property?.defaultValue || '';
                        }

                        return {
                            ...property,
                            options,
                            value,
                            inputName: property.isRepeatable ? `${property.name} ${nanoid()}` : property.name,
                            valueId: input?.id || 0,
                        };
                    });

                    return _arr;
                }
            );
            setName(name);
            setType({
                label: dataSourceTypeDetails.model.name,
                value: dataSourceTypeDetails.model.id.toString(),
            });
            setInputs(inputs.flat());
        } catch (err: any) {
            console.log({ err });
        } finally {
            setDetailsLoading(false);
        }
    };

    const onValidateForm = () => {
        let isValid = true;
        let _errors: any = {};

        if (!name) {
            _errors.name = 'Name is a required field!';
            isValid = false;
        }
        if (!typeSelected) {
            _errors.type = 'Type is required!';
            isValid = false;
        }

        inputs.forEach(inp => {
            if (shouldDynamicInputRender(inp, inputs) && inp.required && !inp.value) {
                _errors[inp.inputName] = 'This field is required';
                isValid = false;
            }
        });

        setErrors(_errors);
        return isValid;
    };

    const handleSubmit = async e => {
        e.stopPropagation();
        e.preventDefault();

        if (!onValidateForm()) return;

        try {
            setFormLoading(true);

            let payload = {
                id: isEditing ? dataSourceId : 0,
                accountId,
                name,
                dataSourceTypeId: typeSelected?.value,
                values: inputs
                    .filter(x => shouldDynamicInputRender(x, inputs))
                    .map(inp => ({
                        id: isEditing ? inp?.valueId : 0,
                        propertyId: inp.id,
                        value: inp?.value?.value || inp?.value,
                    })),
            };
            const response: any = await axios.post('/dataSource', payload);
            setFormLoading(false);
            onSuccess && onSuccess(response.model, !!isEditing);
            onHide();
        } catch (err) {
            setFormLoading(false);
        }
    };

    const { buttonText, title, formId } = useMemo(() => {
        return {
            title: isEditing ? t('@components.modals.edit-data-source') : t('@components.modals.create-data-source'),
            buttonText: isEditing ? t('@common.edit') : t('@common.create'),
            formId: isEditing ? 'editDataSource' : 'createDataSource',
        };
    }, []);

    const onChangeType = item => {
        if (item.value === typeSelected?.value) {
            return;
        }
        deleteErrorKey('type', setErrors);
        setType(item);
        fetchInputs(item?.value);
    };

    const fetchInputs = async dataSourceTypeId => {
        try {
            setPropertiesLoading(true);
            const query = {
                id: dataSourceTypeId,
                accountId,
            };

            const inputsResponse: DataSourceTypeDetailsResponseModel = await axios.get(
                `/dataSourceType?${getFormattedQuery(query)}`
            );
            let inputs = inputsResponse.model?.properties?.map(el => {
                if (el.inputType === inputTypes.dropdown || el.inputType === inputTypes.dropdownOpen)
                    return {
                        ...el,
                        inputName: el?.name,
                        value: el.defaultValue ? el?.options?.find(x => x.value === el?.defaultValue) : null,
                    };

                return {
                    ...el,
                    inputName: el?.name,
                    value: el?.defaultValue || '',
                };
            });
            setInputs(inputs);
        } catch (err: any) {
            console.log({ err });
        } finally {
            setPropertiesLoading(false);
        }
    };

    const onChangeInput = (key, value) => {
        deleteErrorKey(key, setErrors);
        setInputs(prev => prev.map(inp => (inp.inputName === key ? { ...inp, value } : inp)));
    };

    const onChangeName = e => {
        deleteErrorKey('name', setErrors);
        setName(e.target.value);
    };

    const handleCreateOption = (inputName: string, value: string) => {
        setInputs(addNewOptionToDynamicInputs(inputs, inputName, value));
    };

    const renderProperties = () => {
        if (propertiesLoading)
            return (
                <div className="h-auto w-full p-10 flex items-center justify-center">
                    <Loader />
                </div>
            );

        return (
            <DynamicInputs
                onChangeInputsList={_inputs => {
                    setInputs(_inputs);
                }}
                handleCreateOption={handleCreateOption}
                properties={inputs}
                onChangeInput={onChangeInput}
                className="mt-4"
                errors={errors}
            />
        );
    };

    return (
        <>
            <SideModal
                onHide={() => {
                    setErrors({});
                    onHide();
                }}
                isVisible={isVisible}
                title={title}
                buttonText={buttonText}
                formId={formId}
                loading={formLoading}
            >
                <form onSubmit={handleSubmit} id={formId} className="px-4 py-6">
                    {detailsLoading ? (
                        <div className="py-12 w-full flex items-center justify-center">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <InputField
                                label={t('@common.name')}
                                name="datasource"
                                id="datasource"
                                placeholder={t('@common.enter-name')}
                                required
                                maxLength={50}
                                value={name}
                                onChange={onChangeName}
                                errorMessage={errors['name']}
                                type="string"
                            />
                            <SelectField
                                id="DataSourceType"
                                label={t('@common.type')}
                                className="mt-4"
                                placeholder={t('@components.modals.choose-data-source-type')}
                                onChange={onChangeType}
                                value={typeSelected}
                                isLoading={dataSourceTypeLoading}
                                required
                                errorMessage={errors['type']}
                                options={formatArrayForSelectOptions(dataSourceTypeResponse.models)}
                            />
                            {renderProperties()}
                        </>
                    )}
                </form>
            </SideModal>
        </>
    );
};
