import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditableCellComponent } from '../../../components/tables/components/EditableCellComponent';
import { Loader } from '../../../components/ui';
import { useAccountId } from '../../../hooks';
import {
    AdminLanguageStateListModel,
    AdminLanguageStateListResponseModel,
    LanguageRecourseListModel,
} from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/language/adminLanguagesThunk';
import axios from '../../../services/axios';
import { getWidth } from '../../../utils';
import { AdminTable } from '../components';

export const AdminLocalizationsPage = () => {
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    /**
     * Selectors
     */
    const response: AdminLanguageStateListResponseModel = useSelector(selectors.getResponse);
    const initialLoading = useSelector(selectors.getInitialLoading);
    const loading = useSelector(selectors.getIsLoading);

    const [columns, setColumns] = useState<TableColumn<any>[]>([]);

    useEffect(() => {
        dispatch(actions.request({ accountId, page: 0 }));
    }, [accountId]);

    useEffect(() => {
        if (response.models.length) {
            const { id, lastModified, modifiedBy, key, ...languages }: AdminLanguageStateListModel = response.models[0];
            let _cols: TableColumn<any>[] = [];
            Object.entries(languages).forEach(([key]) => {
                _cols.push({
                    name: key,
                    selector: () => `${key}.value`,
                    grow: 1.5,
                    sortable: true,
                    cell: (row: any) => {
                        const value = row[key]?.value || '';
                        return (
                            <EditableCellComponent
                                value={value}
                                onSave={newValue => onSaveLanguage(row[key], newValue, key, row)}
                            />
                        );
                    },
                });
            });

            setColumns(_cols);
        }
    }, [response]);

    const onSaveLanguage = async (resourceItem: LanguageRecourseListModel, value: string, languageKey, row: any) => {
        try {
            const payload = {
                id: resourceItem.id,
                languageId: resourceItem.languageId,
                keyId: resourceItem.keyId,
                value,
            };
            await axios.post('/languageResource', payload);
            await i18next.reloadResources(i18n.language);
            const _row = { ...row };
            _row[languageKey].value = value;
            dispatch(actions.updateRowByKey(row.key, _row));
            return true;
        } catch (err) {
            return false;
        }
    };

    const requestList = (payload, resetQuery?: boolean) => {
        dispatch(actions.request({ accountId, ...payload }, resetQuery));
    };

    return (
        <>
            {columns.length ? (
                <AdminTable
                    columns={[
                        {
                            name: 'Key',
                            selector: row => row?.key ?? '',
                            width: getWidth() > 1300 ? 'auto' : '200px',
                            cell: (row: any) => {
                                return <span className="text-sm font-medium">{row?.key}</span>;
                            },
                        },
                        ...columns,
                    ]}
                    overrideColumns
                    response={response}
                    initLoading={initialLoading}
                    loading={loading}
                    modelEndpoint="language/list/languageResources"
                    onPageChanged={page => requestList({ page })}
                    requestList={requestList}
                    hideDelete
                    hideActions
                    headerButtonProps={{
                        onRefresh: () => requestList({}),
                        showDelete: false,
                        showCreate: false,
                    }}
                />
            ) : loading ? (
                <div className="w-full flex min-h-full flex-1 justify-center items-center">
                    <Loader />
                </div>
            ) : null}
        </>
    );
};
