import { createContext } from 'react';
import { BaseEntityProperty, TaskOutputDetailsModel, TaskTypeDetailsModel } from '../../../../../models';

export type TTaskVersion = { id: number; versionNumber: string };
export type TTasksVersions = Record<number, TTaskVersion[]>;

export type TPipelineStepState = {
    id: number;
    _id: string;
    pipelineId: number;
    taskId: number;
    outputReferenceName: string;
    outputReferenceNameDisabled: boolean;
    taskTitle: string;
    displayName: string;
    taskType: TaskTypeDetailsModel;
    versionId: number;
};

export type TPipelineVariableState = {
    _id: string;
    id: number;
    pipelineId?: number;
    name: string;
    value: string;
    settableAtQueueTime: boolean;
    locked: boolean;
    isOutput: boolean;
    error: string;
};

export type TPipelineStepInputState = {
    _id: string;
    inputs: BaseEntityProperty[];
};
export type TPipelineStepOutputState = {
    _id: string;
    outputs: TaskOutputDetailsModel[];
};

export interface ICreatePipelineContext {
    steps: TPipelineStepState[];
    stepIdSelected: string;
    variables: TPipelineVariableState[];
    tasksVersions: TTasksVersions;
    stepsInputs: TPipelineStepInputState[];
    stepsOutputs: TPipelineStepOutputState[];
    setStepIdSelected: React.Dispatch<React.SetStateAction<string>>;
    setStepsInputs: React.Dispatch<React.SetStateAction<TPipelineStepInputState[]>>;
    setStepsOutputs: React.Dispatch<React.SetStateAction<TPipelineStepOutputState[]>>;
    setSteps: React.Dispatch<React.SetStateAction<TPipelineStepState[]>>;
    setVariables: React.Dispatch<React.SetStateAction<TPipelineVariableState[]>>;
    setTasksVersions: React.Dispatch<React.SetStateAction<TTasksVersions>>;
}

export const createPipelineContext = createContext<ICreatePipelineContext>({
    setStepIdSelected: () => {},
    setSteps: () => {},
    setVariables: () => {},
    setTasksVersions: () => {},
    setStepsInputs: () => {},
    setStepsOutputs: () => {},
    stepIdSelected: '',
    steps: [],
    variables: [],
    stepsInputs: [],
    stepsOutputs: [],
    tasksVersions: {},
});
