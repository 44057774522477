export const informationPageConstants = {
    datasets: {
        title: `Datasets`,
        description: `
        To train a model first you need data, and the data can come from different places, it can come from a database or from a file that is saved in you local storage. Therefore to make it easier to create datasets from different sources you have the ability to create datasets. 
        \n
        Dataset is the first step in the AI Hub Machine Learning Workflow, you can easily create datasets from different data sources and artifacts.
        `,
    },
    featureEngineering: {
        title: `Feature Engineering`,
        description: `To improve model training you need to perform some actions in datasets that you have created in the previous step. In the Feature Engineering section, you can easily transform the dataset based on your needs, remove a column, perform one-hot encoding or fill in missing values.`,
    },
    models: {
        title: `Models`,
        description: `To train a model, you need an algorithm. The algorithm you choose is based on many factors, for a quick out-of-the-box solution you can choose one of the built-in algorithms that AI Hub provides.`,
    },
    deployments: {
        title: `Deployments`,
        description: `Depending on the size of your model and how quickly you need the results, you can use resources ranging from different instance types and different instance numbers.`,
    },
};
