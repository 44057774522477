import {
    actionCreator,
    getFormattedQuery,
    getFormattedResponseForPayload,
    getRowUpdated,
    getSearchParams,
    getWidth,
} from '../../../../utils';
import { IntegrationTypeListResponseModel, UpdateRowActionProps } from '../../../../models';
import { InitialStateType } from '../../../../models';
import { RootState } from '../../rootReducer';
import { Dispatch } from 'redux';
import axios from '../../../../services/axios';
import { breakpoints } from '../../../../constants';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/integrationType/adminIntegrationTypesThunk/REQUEST',
    SUCCESS: '@app/integrationType/adminIntegrationTypesThunk/SUCCESS',
    FAILURE: '@app/integrationType/adminIntegrationTypesThunk/FAILURE',
    SET_INITIAL_LOADING: '@@app/integrationType/adminIntegrationTypesThunk/SET_INITIAL_LOADING',
    UPDATE_ROW_BY_ID: '@app/integrationType/adminIntegrationTypesThunk/UPDATE_ROW_BY_ID',
};

/**
 * Initial state
 */
const initialState: InitialStateType<IntegrationTypeListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    initialLoading: false,
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
        query: {},
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.adminIntegrationTypes.isLoading,
    getInitialLoading: (state: RootState) => state.app.adminIntegrationTypes.initialLoading,
    getResponse: (state: RootState) => state.app.adminIntegrationTypes.response,
    getErrorMessage: (state: RootState) => state.app.adminIntegrationTypes.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_INITIAL_LOADING, UPDATE_ROW_BY_ID } = actionTypes;

const Reducer = (state: InitialStateType<IntegrationTypeListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case SET_INITIAL_LOADING:
            return {
                ...state,
                initialLoading: payload,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
                initialLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                initialLoading: false,
            };
        case UPDATE_ROW_BY_ID:
            return {
                ...state,
                response: {
                    ...state.response,
                    models: state.response.models.map(x => (x?.id === payload?.row?.id ? payload?.row : x)),
                },
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query: any = {}, resetQuery = false) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const isMobile = getWidth() <= breakpoints.sm;
                const previousQuery = getState().app.adminIntegrationTypes.response.query;

                if (query?.page === undefined) {
                    query.page = previousQuery ? previousQuery?.page : 0;
                }

                if (isMobile && query.page === 0) dispatch(actionCreator(SET_INITIAL_LOADING, true));
                dispatch(actionCreator(REQUEST));

                const previousModels =
                    isMobile && query.page > 0 ? getState().app.adminIntegrationTypes.response.models : [];

                let searchParams = getSearchParams(previousQuery, query, resetQuery);

                const response: IntegrationTypeListResponseModel = await axios.get(
                    `/bridge/integrationType/list?${getFormattedQuery(searchParams)}`
                );
                let res = getFormattedResponseForPayload(response, previousModels, query, searchParams);

                dispatch(actionCreator(SUCCESS, res));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
    updateRow: function ({ rowId, keyValues }: UpdateRowActionProps) {
        return (dispatch, getState: () => RootState) => {
            const response: IntegrationTypeListResponseModel = getState().app.adminIntegrationTypes.response;
            let row = response.models.find(x => x.id === rowId) || null;

            if (row) {
                dispatch(actionCreator(UPDATE_ROW_BY_ID, { row: getRowUpdated(row, keyValues) }));
            }
        };
    },
};
