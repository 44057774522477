import type { OptionProps } from 'react-select';
import { SelectOptionType } from '../../../../../models';

export const TaskTypeSelectOption = ({ isSelected, label, data, innerProps }: OptionProps<SelectOptionType, false>) => (
    <div
        {...innerProps}
        className={`py-3 px-4 flex items-center w-full cursor-pointer transition-colors ${
            isSelected ? 'bg-primaryBlue100' : 'bg-white hover:bg-grey300/50'
        }`}
    >
        <div className="h-12 w-12 rounded bg-mainBackground flex justify-center items-center">
            <img src={data?.imageUrl} alt="type" />
        </div>
        <div className="flex flex-col ml-4">
            <h2 className="text-blueDark text-sm font-medium">{label}</h2>
            <p className="mt-0.5 text-secondaryText text-xs">{data?.description}</p>
        </div>
    </div>
);
