import React from 'react';
import { useTranslation } from 'react-i18next';
import { runSchedules } from '../../constants';
import { SelectField } from '../inputs';

interface RunScheduleComponentForModalProps {
    fieldsState: { schedule?: any; scheduleFrequency?: string };
    onChangeState: (key: string, value: any) => any;
    errors: any;
}

export const RunScheduleComponentForModal: React.FC<RunScheduleComponentForModalProps> = ({
    fieldsState,
    onChangeState,
    errors,
}) => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-between items-start w-full mt-4">
            <div className="flex flex-1 ">
                <SelectField
                    required
                    id="schedule"
                    label={t('@common.schedule')}
                    options={runSchedules.filter(run => run.value !== '5')}
                    value={fieldsState.schedule}
                    onChange={item => {
                        onChangeState('scheduleFrequency', '');
                        onChangeState('schedule', item);
                    }}
                    errorMessage={errors['schedule']}
                />
            </div>

            {fieldsState.schedule?.inputs && (
                <div className="flex flex-1 ml-4">
                    <SelectField
                        options={fieldsState.schedule?.inputs[0]?.options}
                        value={
                            fieldsState.schedule?.inputs[0]?.options?.find(
                                option => option.value === fieldsState?.scheduleFrequency?.toString()
                            ) || null
                        }
                        required
                        label="Every"
                        onChange={item => onChangeState('scheduleFrequency', item?.value)}
                        errorMessage={errors['scheduleFrequency']}
                    />
                </div>
            )}
        </div>
    );
};
