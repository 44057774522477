import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationComponent } from '../../components/animation';
import { NotificationEmptyList } from '../../components/header/notifications/NotificationEmptyList';
import { SearchInput } from '../../components/inputs/SearchInput';
import { CustomTab, CustomTabType } from '../../components/tabs';
import { useOnScreen } from '../../hooks';
import {
    TimeLineNotificationGroup,
    TimeLineNotificationItemLoader,
    TimeLineNotificationsInitialLoading,
} from './components';
import { MarkAllAsRead } from './components/MarkAllAsRead';
import { useNotificationsPageHook } from './hooks/useNotificationsPageHook';

const accountFilterKeys = {
    current: 'current',
    all: 'all',
};

export const NotificationsPage = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, { threshold: 0.3 });
    const { hasMore, initialLoading, loadNextPage, loading, timeLines, requestNotifications, onMarkAllAsRead } =
        useNotificationsPageHook();

    let tabTimeout: any = null;
    const [filterByAccount, setFilterByAccount] = useState(false);

    useEffect(() => {
        if (isVisible && !loading && hasMore && !initialLoading) {
            loadNextPage();
        }
    }, [isVisible]);

    const onSearch = (value: string) => !initialLoading && requestNotifications({ searchQuery: value });

    const onChangeTab = (tab: CustomTabType) => {
        const filterByAccount = tab.key === accountFilterKeys.current;
        setFilterByAccount(filterByAccount);

        clearTimeout(tabTimeout);
        tabTimeout = setTimeout(() => {
            requestNotifications({ filterByAccount });
        }, 300);
    };

    const timeLinesArray = Object.entries(timeLines);
    return (
        <div className="flex flex-col w-full transition-all delay-100 duration-500 sm:max-w-screen-sm md:max-w-screen-sm lg:max-w-screen-sm xl:max-w-screen-md 2xl:max-w-screen-lg mx-auto">
            <AnimationComponent show type="fade-top" delay={50} transform="transform-gpu" duration={300}>
                <div className="flex w-full justify-between items-center flex-col sm:flex-row flex-wrap pb-4 bg-mainBackground">
                    <h3 className="text-blueDark font-bold text-base mr-auto sm:mr-0">
                        {t('notifications', { defaultValue: 'Notifications' })}
                    </h3>
                    <div className="flex flex-1 sm:w-auto w-full mt-2 sm:flex-row flex-row-reverse sm:mt-0 flex-wrap justify-end items-center">
                        <MarkAllAsRead onClick={onMarkAllAsRead} />
                        <SearchInput onSearch={onSearch} />
                    </div>
                </div>
            </AnimationComponent>

            <CustomTab
                tabs={[
                    {
                        displayName: t('@all.accounts', { defaultValue: 'All Accounts' }),
                        key: accountFilterKeys.all,
                    },
                    {
                        displayName: t('@current.account', { defaultValue: 'Current Account' }),
                        key: accountFilterKeys.current,
                    },
                ]}
                tabItemStyle={{ paddingLeft: 0, paddingRight: 24 }}
                activeTabKey={filterByAccount ? accountFilterKeys.current : accountFilterKeys.all}
                onChangeTab={onChangeTab}
                className="mb-6"
            />
            {initialLoading ? (
                <TimeLineNotificationsInitialLoading />
            ) : timeLinesArray.length ? (
                timeLinesArray.map((timeLine, index) => (
                    <TimeLineNotificationGroup
                        filterByCurrentAccount={filterByAccount}
                        key={timeLine[0]}
                        index={index}
                        date={timeLine[0]}
                        notifications={timeLine[1] ?? []}
                    />
                ))
            ) : (
                <AnimationComponent show type="fade-top" delay={100} transform="transform-gpu" duration={500}>
                    <NotificationEmptyList className="flex flex-1 py-10 rounded" />
                </AnimationComponent>
            )}
            <div ref={ref} className="w-full flex">
                {hasMore && !initialLoading && <TimeLineNotificationItemLoader showTimeLine className="mt-2" />}
            </div>
        </div>
    );
};
