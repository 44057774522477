import React, { CSSProperties } from 'react';
import { Loader } from '../ui';

interface DetailsLoaderProps {
    className?: string;
    style?: CSSProperties;
    loaderClassName?: string;
}

export const DetailsLoader: React.FC<DetailsLoaderProps> = ({ children, loaderClassName, className, style }) => (
    <div className={`flex w-full justify-center items-center p-10 ${className}`} style={{ ...style }}>
        <Loader className={loaderClassName} size={40} />
        {children}
    </div>
);
