import { Dispatch } from 'redux';
import { DatasetJoinTypesResponseModel, InitialStateType } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/dataset/datasetJoinTypesThunk/REQUEST',
    SUCCESS: '@app/dataset/datasetJoinTypesThunk/SUCCESS',
    FAILURE: '@app/dataset/datasetJoinTypesThunk/FAILURE',
};

/**
 * Initial state
 */
const initialState: InitialStateType<DatasetJoinTypesResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        message: '',
        success: false,
        models: [],
        currentPage: 0,
        totalPages: 1,
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.datasetJoinTypes.isLoading,
    getResponse: (state: RootState) => state.app.datasetJoinTypes.response,
    getErrorMessage: (state: RootState) => state.app.datasetJoinTypes.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (state: InitialStateType<DatasetJoinTypesResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const response: DatasetJoinTypesResponseModel = await axios.get(`/datasetJoinType/list`);
                dispatch(
                    actionCreator(SUCCESS, {
                        ...response,
                        models: response.models.reverse(),
                    })
                );
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
