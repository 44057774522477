import React, { useEffect, useState } from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';

export interface AutoHeightAnimation extends AnimateHeightProps {}

export const AutoHeightAnimation: React.FC<AutoHeightAnimation> = ({ children, ...rest }) => {
    const [height, setHeight] = useState<number | 'auto'>(0);

    useEffect(() => {
        setHeight('auto');
    }, [children]);

    return (
        <AnimateHeight duration={500} height={height} className="Auto" {...rest}>
            {children}
        </AnimateHeight>
    );
};
