import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Loader } from '../../../../components/ui';
import { selectors } from '../../../../redux/thunk/app/storage/storageObjectsThunk';

interface StoragePaginationComponentProps {
    totalItems: number;
    onLoadMore: () => any;
}

export const StoragePaginationComponent: React.FC<StoragePaginationComponentProps> = ({ totalItems, onLoadMore }) => {
    const { t } = useTranslation();
    const isLoadingMore = useSelector(selectors.getIsLoadingMore);
    const showLoadMore = useSelector(selectors.getShowLoadMore);

    return (
        <div className="flex w-full flex-col">
            {showLoadMore && (
                <div
                    className={`${
                        isLoadingMore ? 'opacity-80 cursor-default' : 'cursor-pointer'
                    } py-3 pl-16 text-blueMainText text-sm w-full hover:bg-mainBackground hover:text-mainBlue flex`}
                    onClick={() => !isLoadingMore && onLoadMore()}
                >
                    {t('@load.more', { defaultValue: 'Load more...' })}
                    {isLoadingMore && <Loader className="ml-3" size={20} />}
                </div>
            )}
            <div className="py-4 pl-6 text-sm border-t border-mainBorder w-full text-blueMainText">
                {t('@storage.total.items', { defaultValue: '{{number}} items', number: totalItems })}
            </div>
        </div>
    );
};
