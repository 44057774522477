import { combineReducers } from 'redux';
import accountApiKeysReducer from './account/accountApiKeysThunk';
/**
 * Account
 */
import accountDetailsReducer from './account/accountDetailsThunk';
import accountIntegrationsReducer from './account/accountIntegrationsThunk';
import accountInvoicesReducer from './account/accountInvoicesThunk';
import accountReducer from './account/accountsThunk';
/**
 * AccountUser
 */
import accountUsersReducer from './account/accountUsersThunk';
/**
 * algorithm
 */
import algorithmGroupsReducer from './algorithm/algorithmGroupsThunk';
import algorithmsReducer from './algorithm/algorithmsThunk';
/**
 * artifacts
 */
import artifactContentTypesReducer from './artifact/artifactContentTypesThunk';
import artifactsReducer from './artifact/artifactsThunk';
import artifactVersionsReducer from './artifact/artifactVersionsThunk';
import uploadArtifactReducer from './artifact/uploadArtifactThunk';
/**
 * Catalogs
 */
import catalogsReducer from './catalog/catalogsThunk';
import subcatalogsReducer from './catalog/subcatalogsThunk'
import configurationItemsReducer from './configurationItemsThunk';
import customEndpointsReducer from './custom-endpoint/customEndpointsThunk';
import customEndpointVersionsReducer from './custom-endpoint/customEndpointVersionsThunk';
import datasetJoinTypesReducer from './dataset/datasetJoinTypesThunk';
/**
 * Dataset
 */
import datasetReducer from './dataset/datasetsThunk';
import adminDataSourceTypesReducer from './dataSource/adminDataSourceTypesThunk';
import dataSourceReadersReducer from './dataSource/dataSourceReadersThunk';
/**
 * DataSources
 */
import dataSourcesReducer from './dataSource/dataSourcesThunk';
/**
 * DataSourceTypes
 */
import dataSourceTypesReducer from './dataSource/dataSourceTypesThunk';
/**
 * instance types
 */
import deploymentsInstanceTypesReducer from './deployment/deploymentInstanceTypesThunk';
/**
 * Deployments
 */
import deploymentsReducer from './deployment/deploymentsThunk';
/**
 * Engines
 */
import lookalikeEngineDetailsReducer from './engines/lookalike/lookalikeEngineDetailsThunk';
import lookalikeEngineRunsReducer from './engines/lookalike/lookalikeEngineRunsThunk';
import lookalikeEnginesReducer from './engines/lookalike/lookalikeEnginesThunk';
import propensityEnginesReducer from './engines/propensity/propensityEnginesThunk';
import propensityEngineProductTypesReducer from './engines/propensity/propensityProductTypesThunk';
import recommendationEngineRunsReducer from './engines/recommendation/recommendationEngineRunsThunk';
import recommendationEnginesReducer from './engines/recommendation/recommendationEnginesThunk';
import externalSourceTypesReducer from './externalSourceTypesThunk';
import filtersReducer from './filter/filtersThunk';
/**
 * Flow Actions
 */
import flowActionsReducer from './flow/flowActionsThunk';
import flowInstanceTypesReducer from './flow/flowInstanceTypesThunk';
/**
 *  Workflow
 */
import featureEngineeringFlowsReducer from './flow/flowsThunk';
/**
 * Integration Types
 */
import adminIntegrationTypesReducer from './integrationType/adminIntegrationTypesThunk';
import adminLanguagesReducer from './language/adminLanguagesThunk';
/**
 * Languages - Localizations
 */
import languagesReducer from './language/languagesThunk';
import allModelsReducer from './model/allModelsThunk';
import modelInstanceTypesReducer from './model/modelInstanceTypesThunk';
import workflowModelsReducer from './model/modelsThunk';
/**
 * Lab
 */
import notebookInstanceTypesReducer from './notebook/notebookInstanceTypesThunk';
import notebookServersReducer from './notebook/notebookServersThunk';

import tasksReducer from './task/tasksThunk';
import taskVersionsReducer from './task/taskVersionsThunk';
import pipelinesReducer from './pipeline/pipelinesThunk';
import pipelineJobsReducer from './pipeline-job/pipelineJobsThunk';
import pipelineJobRunsReducer from './pipeline-job/pipelineJobRunsThunk';

/**
 * Notifications
 */
import notificationsReducer from './notification/notificationsThunk';
import paymentMethodsReducer from './payment/paymentMethodsThunk';
/**
 * Schemas
 */
import adminSchemaTypesReducer from './schemaType/adminSchemaTypesThunk';
/**
 * Schema types
 */
import schemaTypesReducer from './schemaType/schemaTypesThunk';
/**
 * Storage
 */
import storageObjectsReducer from './storage/storageObjectsThunk';
import uploadObjectReducer from './storage/uploadObjectsThunk';
/**
 * User
 */
import userReducer from './user/userThunk';
import voucherReducer from './voucher/vouchersThunk';

export const appReducer = combineReducers({
    //Account
    accounts: accountReducer,
    accountDetails: accountDetailsReducer,
    accountInvoices: accountInvoicesReducer,
    accountApiKeys: accountApiKeysReducer,
    accountIntegrations: accountIntegrationsReducer,
    accountUsers: accountUsersReducer,

    //DataSources
    dataSources: dataSourcesReducer,
    dataSourceReaders: dataSourceReadersReducer,

    //Catalogs
    catalogs: catalogsReducer,

    //Subcatalogs
    subcatalogs: subcatalogsReducer,

    //Filters
    filters: filtersReducer,

    //SchemaTypes
    schemaTypes: schemaTypesReducer,
    adminSchemaTypes: adminSchemaTypesReducer,

    //DataSourceTypes
    dataSourceTypes: dataSourceTypesReducer,
    adminDataSourceTypes: adminDataSourceTypesReducer,

    //Integration Types
    adminIntegrationTypes: adminIntegrationTypesReducer,

    //Artifacts
    artifacts: artifactsReducer,
    uploadArtifact: uploadArtifactReducer,
    artifactVersions: artifactVersionsReducer,
    artifactContentTypes: artifactContentTypesReducer,

    //Storage
    storageObjects: storageObjectsReducer,
    uploadObject: uploadObjectReducer,

    //Models
    workflowModels: workflowModelsReducer,
    allModels: allModelsReducer,
    modelInstanceTypes: modelInstanceTypesReducer,

    //Deployments
    deployments: deploymentsReducer,
    deploymentsInstanceTypes: deploymentsInstanceTypesReducer,

    //Feature Engineering
    featureEngineeringFlows: featureEngineeringFlowsReducer,
    flowInstanceTypes: flowInstanceTypesReducer,

    //Dataset
    datasets: datasetReducer,
    datasetJoinTypes: datasetJoinTypesReducer,

    //Algorithm
    algorithms: algorithmsReducer,
    algorithmGroups: algorithmGroupsReducer,

    //FlowActions
    flowActions: flowActionsReducer,

    //Engines
    recommendationEngines: recommendationEnginesReducer,
    recommendationEngineRuns: recommendationEngineRunsReducer,
    lookalikeEngines: lookalikeEnginesReducer,
    lookalikeEngineRuns: lookalikeEngineRunsReducer,
    lookalikeEngineDetails: lookalikeEngineDetailsReducer,
    propensityEngines: propensityEnginesReducer,
    propensityEngineProductTypes: propensityEngineProductTypesReducer,

    //Notebooks
    notebookServers: notebookServersReducer,
    notebookInstanceTypes: notebookInstanceTypesReducer,

    customEndpoints: customEndpointsReducer,
    customEndpointVersions: customEndpointVersionsReducer,

    tasks: tasksReducer,
    taskVersions: taskVersionsReducer,

    pipelines: pipelinesReducer,

    pipelineJobs: pipelineJobsReducer,
    pipelineJobRuns: pipelineJobRunsReducer,

    //User
    user: userReducer,

    //config
    configurationItems: configurationItemsReducer,
    externalSourceTypes: externalSourceTypesReducer,

    //Notifications
    notifications: notificationsReducer,

    //Vouchers
    vouchers: voucherReducer,

    //Language - Localizations
    languages: languagesReducer,
    adminLanguages: adminLanguagesReducer,

    //Payment - billing
    paymentMethods: paymentMethodsReducer,
});
