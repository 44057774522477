import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountImage } from '../../../components/account/AccountImage';
import { AccountListModel, AccountListResponseModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/account/accountsThunk';
import { AdminTable } from '../components';

export const AdminAccountsPage = () => {
    const dispatch = useDispatch();

    const response: AccountListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    const requestList = (query: any = {}) => {
        dispatch(actions.request({ ...query, isFromAdmin: true }, false, false));
    };
    useEffect(() => {
        requestList({ page: 0 });
    }, []);

    const onRefresh = () => {
        !loading && requestList();
    };

    return (
        <div className="">
            <AdminTable
                modelEndpoint="account"
                showCreateButton={false}
                hideEdit
                hideDelete
                requestList={requestList}
                reduxActions={actions}
                columns={[
                    {
                        selector: row => row?.id ?? '',
                        name: 'Id',
                        width: '70px',
                        cell: row => (
                            <span className="flex w-full justify-start text-xs text-blueMainText font-regular">
                                {row?.id}
                            </span>
                        ),
                    },
                    {
                        width: '100px',
                        selector: row => row?.imageUrl ?? '',
                        name: '',
                        cell: (row: AccountListModel) => (
                            <div className="w-full flex justify-center">
                                <AccountImage className="w-10 h-10" account={row.name} url={row.imageUrl} />
                            </div>
                        ),
                    },
                    {
                        name: 'Name',
                        selector: row => row?.name ?? '',
                    },
                ]}
                response={response}
                loading={loading}
                initLoading={initLoading}
                onPageChanged={page => {
                    requestList({ page });
                }}
                headerButtonProps={{
                    onRefresh,
                    showDelete: false,
                    showCreate: false,
                }}
            />
        </div>
    );
};
