import React, { CSSProperties, ReactNode } from 'react';
import Select, { OptionsType, Props } from 'react-select';
import { SelectOptionType } from '../../models';
import { ErrorTextComponent } from '../messages';

export interface MultiSelectFieldProps extends Omit<Props<SelectOptionType, true>, 'value'> {
    options: SelectOptionType[];
    label?: string;
    labelClassName?: string;
    labelStyle?: CSSProperties;
    hideLabel?: boolean;
    labelRight?: ReactNode;
    className?: string;
    style?: CSSProperties;
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    required?: boolean;
    maxMenuHeight?: number;
    value: string;
    onChange: (value: string) => any;
    disabled?: boolean;
}

export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
    onChange,
    value,
    errorMessage,
    label,
    labelStyle,
    labelClassName,
    hideLabel,
    labelRight,
    className,
    style,
    errorClassName,
    errorStyle,
    required,
    maxMenuHeight,
    isMulti,
    children,
    options,
    disabled,
    ...props
}) => {
    const formatValue = () => {
        if (!value) {
            return [];
        }
        let valueArr: string[] = value.split(',');
        let finalValues: SelectOptionType[] = valueArr.map(x => {
            let option = options?.find(y => y.value === x);

            return {
                label: option?.label || x || '',
                value: x,
            };
        });

        return finalValues;
    };

    const _onChange = (items: OptionsType<SelectOptionType>) => {
        let value = items.map(x => x.value).join(',');
        onChange(value);
    };

    const values = formatValue();
    return (
        <div className={`w-full flex flex-col ${className} ${disabled && 'cursor-not-allowed'}`} style={style}>
            {!hideLabel && (
                <div
                    style={{
                        minHeight: 24,
                    }}
                    className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                >
                    <label className="text-xs sm:text-sm text-secondaryText font-normal">
                        {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                    </label>
                    {labelRight && labelRight}
                </div>
            )}
            <Select
                isMulti
                className="h-8 sm:h-10"
                onChange={_onChange}
                value={values}
                styles={{
                    clearIndicator: base => ({
                        ...base,
                    }),
                    placeholder: base => ({
                        ...base,
                        color: '#A3B0C2',
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        fontSize: 14,
                        fontWeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }),
                    input: base => ({
                        ...base,
                        color: '#052D61',
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        fontSize: 14,
                        fontWeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }),
                    container: base => ({
                        ...base,
                        height: 'fit-content',
                        minHeight: '40px !important',
                        '@media only screen and (max-width: 640px)': {
                            height: 'fit-content',
                            minHeight: '32px !important',
                        },
                    }),
                    control: base => ({
                        ...base,
                        border: `1px solid ${errorMessage ? '#ff0033' : '#E2E2E2'}`,
                        height: 'fit-content',
                        minHeight: '40px !important',
                        '@media only screen and (max-width: 640px)': {
                            height: 'fit-content',
                            minHeight: '32px !important',
                        },
                        cursor: 'pointer',
                    }),
                    dropdownIndicator: base => ({
                        ...base,
                    }),
                    group: base => ({
                        ...base,
                    }),
                    groupHeading: base => ({
                        ...base,
                    }),
                    indicatorSeparator: () => ({
                        display: 'none',
                    }),
                    indicatorsContainer: base => ({
                        ...base,
                    }),
                    option: (b, state) => ({
                        ...b,
                        fontSize: 14,
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        color: '#052D61',
                        fontWeight: 'normal',
                        backgroundColor: state.isSelected ? '#caebfa' : state.isFocused ? '#F6F7F9' : 'white',
                        ':active': {
                            backgroundColor: '#caebfa',
                        },
                        cursor: 'pointer',
                    }),
                    menuList: b => ({
                        ...b,
                        maxHeight: maxMenuHeight || 400 + 'px !important',
                    }),
                    singleValue: () => ({
                        color: '#052D61',
                        fontSize: 14,
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        overflow: 'hidden',
                        width: 'auto',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }),
                    menu: b => ({
                        ...b,
                    }),
                    valueContainer: base => ({
                        ...base,
                        height: 'fit-content',
                        minHeight: '40px !important',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        '@media only screen and (max-width: 640px)': {
                            height: 'fit-content',
                            minHeight: '32px !important',
                            paddingTop: '0px !important',
                        },
                    }),
                    multiValue: base => ({
                        ...base,
                        background: '#fff',
                        border: '1px solid #e0e5eb;',
                        borderRadius: '20px',
                        maxWidth: '200px'
                    }),
                    multiValueRemove: base=> ({
                        ...base,
                        "&:hover": {
                            borderRadius: '0 20px 20px 0'
                        }
                    })
                }}
                menuPlacement="auto"
                menuShouldScrollIntoView={true}
                options={options}
                closeMenuOnSelect={false}
                isDisabled={disabled}
                {...props}
            />
            <ErrorTextComponent message={errorMessage} />
            {children}
        </div>
    );
};
