import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { toast } from '../components/ui';
import { getToken, removeToken, showServerErrors } from '../utils';
import { deviceLocalStorage, LocalStorageKeys } from './deviceLocalStorage';
const { REACT_APP_AUTHORITY_API_URL } = process.env;

export const authorizeConfig = (config: AxiosRequestConfig) => {
    const access_token = getToken();
    const apiKey = deviceLocalStorage.getItem(LocalStorageKeys.ApiKey);

    if (apiKey) {
        config.headers = {
            ...config.headers,
            ApiKey: `${apiKey}`,
            Accept: 'application/json',
        };
    } else if (access_token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${access_token}`,
            Accept: 'application/json',
        };
    }
    return config;
};

export const shouldDisplayError = (config: AxiosError['config']) => {
    const disabledEndpoints = ['/validateAlias'];
    return !disabledEndpoints.some(x => config.url?.includes(x));
};

const checkMaximumLoginAttempts = () => {
    let tries = parseInt(sessionStorage.getItem('@login_attempts') ?? '0') ?? 0;
    tries++;
    sessionStorage.setItem('@login_attempts', tries.toString());
    return tries;
};

export const handle401StatusCodeError = async (error: any) => {
    let tries = checkMaximumLoginAttempts();
    if (tries > 1) {
        toast.dismiss();
        showServerErrors(error);
        return;
    }

    removeToken();
    const url = sessionStorage.getItem('url_requested')?.toString();

    const userManager = new UserManager({
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        client_id: 'aihub_react',
        authority: REACT_APP_AUTHORITY_API_URL,
        response_type: 'code',
        scope: 'ai_hub',
        automaticSilentRenew: true,
        redirect_uri: url,
    });

    try {
        await userManager.signinSilent();
        const access_token = getToken();
        error.config.headers = {
            Authorization: `Bearer ${access_token}`,
            Accept: 'application/json',
        };
        sessionStorage.setItem(
            'url_requested',
            `${window.location.origin}${window.location.pathname}${window.location.search}`
        );

        return (await Axios.request(error.config)).data;
    } catch (err: any) {
        window.location.reload();
    }
};
