import React, { CSSProperties } from 'react';

export interface CounterDisplayProps {
    number: number;
    className?: string;
    style?: CSSProperties;
}

export const CounterDisplay: React.FC<CounterDisplayProps> = ({ number, className, style }) => {
    return (
        <div
            style={style}
            className={`${
                number > 99 ? 'h-5 w-8' : number > 9 ? 'h-5 w-6' : 'h-5 w-5'
            } text-xs absolute border justify-center items-center flex -top-1.5 right-0.5 rounded-full bg-mainBlue text-white ${className}`}
        >
            {number > 99 ? '99+' : number}
        </div>
    );
};
