import { trimEnd } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from '../../../../components/ui';
import { STORAGE_PATH } from '../../../../constants';
import { useAccountId } from '../../../../hooks';
import { actions, selectors } from '../../../../redux/thunk/app/storage/storageObjectsThunk';
import { queryParams } from '../../../../utils';
import { storageEventTypeOptions } from '../components';
import { getParamsPath, goBackKey, isAnyObjectPending } from '../storage.helper';

type useStorageLogicProps = {
    isFromSelectPopup: boolean;
    onClearSelectedRows: () => void;
};
export function useStorageHook({ isFromSelectPopup, onClearSelectedRows }: useStorageLogicProps) {
    const dispatch = useDispatch();
    const response = useSelector(selectors.getResponse);
    const accountId = useAccountId();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    let refreshListTimeout: any = null;

    const notFoundMessage = useMemo(
        () => t('@folder.not.found', { defaultValue: 'Folder not found!' }),
        [t, i18n.language]
    );
    const [localParamsPath, setLocalParamsPath] = useState<string>('');

    const paramsPath = useMemo(
        () => decodeURI(isFromSelectPopup ? localParamsPath : getParamsPath()),
        [isFromSelectPopup, window.location.pathname, localParamsPath]
    );

    useEffect(() => {
        //this effect is only when user is from select popup
        if (!isFromSelectPopup) return;

        let key = localParamsPath ?? '';
        let isFolder = localParamsPath.includes('/');
        onClearSelectedRows();

        dispatch(
            actions.request(
                {
                    key,
                    onError: handleNotFoundError,
                    isFolder,
                    options: { notFoundMessage },
                },
                history
            )
        );
    }, [localParamsPath, isFromSelectPopup, notFoundMessage]);

    useEffect(() => {
        if (isFromSelectPopup) return;

        onClearSelectedRows();
        const _paramsPath = getParamsPath();
        let requestKey = _paramsPath && _paramsPath?.endsWith('/') ? `${trimEnd(_paramsPath, '/')}/` : _paramsPath;
        let key = requestKey ?? '',
            isFolder = _paramsPath.includes('/');

        dispatch(
            actions.request(
                {
                    key,
                    isFolder,
                    options: { notFoundMessage },
                },
                history
            )
        );
    }, [window.location.pathname, isFromSelectPopup]);

    useEffect(() => {
        //this effect is only when user is not from select popup
        if (isFromSelectPopup) return;

        let paramsPath = getParamsPath();
        if (response.query && response.query?.accountId) {
            let prevAccount = parseInt(response.query.accountId) ?? 0;

            if (prevAccount !== accountId && paramsPath.length > 0)
                history.push(STORAGE_PATH + queryParams.formatForNavigation());
            else if (prevAccount !== accountId) onRefresh();
        }
    }, [accountId, isFromSelectPopup]);

    useEffect(() => {
        clearTimeout(refreshListTimeout);

        if (!!response.model.objectListModel.length) {
            const { DeleteFolder, DeleteObject } = storageEventTypeOptions;
            let eventTypes = [DeleteFolder, DeleteObject];
            let pending = isAnyObjectPending(response.model.objectListModel, eventTypes);

            if (pending) {
                refreshListTimeout = setTimeout(() => {
                    onRefresh(false);
                }, 7000);
            }
        }
    }, [response]);

    const handleNotFoundError = () => {
        toast.error(notFoundMessage);
        setLocalParamsPath(goBackKey(localParamsPath));
    };

    const onRefresh = useCallback(
        (clearSelected = true) => {
            clearSelected && onClearSelectedRows();

            clearTimeout(refreshListTimeout);

            let key = paramsPath;
            let isFolder = key?.includes('/');

            dispatch(
                actions.request(
                    {
                        key,
                        isFolder,
                        onError: isFromSelectPopup ? handleNotFoundError : undefined,
                        options: {
                            notFoundMessage: t('@folder.not.found', { defaultValue: 'Folder not found!' }),
                        },
                    },
                    history
                )
            );
        },
        [isFromSelectPopup, paramsPath]
    );

    const onRequestKey = useCallback(
        (key: string) => {
            if (isFromSelectPopup) {
                let requestKey = key?.endsWith('/') ? `${trimEnd(key, '/')}/` : key;
                setLocalParamsPath(requestKey);
            } else {
                let currentPath = getParamsPath();
                if (key === currentPath) onRefresh();
                else history.push(`/data/storage${key.length ? `/${key}` : ''}` + queryParams.formatForNavigation());
            }
        },
        [isFromSelectPopup]
    );

    const onLoadMore = useCallback(() => {
        const continuationToken = response.model.objectListModel[response.model.objectListModel.length - 1].key || '';
        const payload = {
            key: response?.query?.key ?? '',
            continuationToken,
            isFolder: !!response.query?.key?.includes('/'),
            options: { notFoundMessage },
        };
        dispatch(actions.request(payload, history));
    }, [response]);

    return {
        paramsPath,
        onRequestKey,
        onRefresh,
        onLoadMore,
    };
}
