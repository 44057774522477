import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CommonTableProps } from '.';
import { breakpoints } from '../../constants';
import { useAccountId, useCurrentWidth } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { AccountApiKeyListModel, AccountApiKeyListResponseModel } from '../../models';
import { actions as apiKeyActions, selectors } from '../../redux/thunk/app/account/accountApiKeysThunk';
import { getWidth, showFiltersHeader } from '../../utils';
import { CopyButton } from '../buttons';
import { RefreshButton } from '../buttons/RefreshButton';
import { CreateAccountApiKeyModal } from '../modals';
import { toast } from '../ui';
import { NoDataImagesType, TableActionRowProps, TableFilters } from './components';
import { Table } from './Table';
import { TableMobile } from './TableMobile';

export interface AccountApiKeysTableProps extends CommonTableProps {
    actions?: TableActionRowProps[];
}
export const AccountApiKeysTable: React.FC<AccountApiKeysTableProps> = ({
    className,
    fetchDataOnMount,
    isFromSelectPopup,
    tablePopupProps,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });

    /**
     * Redux Selector
     */
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);
    const response: AccountApiKeyListResponseModel = useSelector(selectors.getResponse);

    /**
     * Local State
     */
    const [idForEdit, setIdForEdit] = useState<number>(0);

    const requestList = (payload: object) => {
        dispatch(apiKeyActions.request({ accountId, ...payload }));
    };

    useEffect(() => {
        if (fetchDataOnMount) {
            requestList({ page: 0, searchQuery: '' });
        }
    }, [accountId]);

    const onPageChanged = page => dispatch(apiKeyActions.request({ page }));
    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : response?.currentPage || 0;
        !loading && requestList({ page });
    };

    /**
     * onClick Events
     */
    const onClickCreate = () => onToggleCreateModal(true);

    const onClickEdit = (row: AccountApiKeyListModel) => {
        onToggleCreateModal(true);
        setIdForEdit(row?.id);
    };

    const onSelectRow = (row: AccountApiKeyListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.name,
                value: row.id.toString(),
            });
        }
    };

    const hideModal = () => {
        onToggleCreateModal(false);
        idForEdit && setIdForEdit(0);
    };

    const onSuccess = (item, isEditing) => {
        hideModal();
        requestList({ page: 0 });
        onSelectRow(item);
        toast.success(isEditing ? 'Api key has been updated!' : 'New Api key has been created!');
    };

    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'edit',
                onClick: onClickEdit,
            },
            {
                action: 'delete',
            },
        ],
        []
    );
    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.no-api-keys'),
            actionMessage: `+ ${t('@pages.create-new-api-key')}`,
            imageType: NoDataImagesType.ApiKey,
            onClickAction: onClickCreate,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models?.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const commonTableProps = {
        requestList,
        headerButtonProps: {
            createTitle: t('@common.add') + ' ' + t('@common.key'),
            onClickCreate,
            onRefresh,
            showEdit: false,
            showRefresh: false,
        },
        modelEndpoint: 'accountApiKey',
        selectableRows: true,
        isFromSelectPopup,
        showHeaderButtons: true,
        emptyDataProps,
        loading,
        response,
    };

    return (
        <div className={`flex flex-col ${className} ${currentWidth <= breakpoints.sm && 'p-2'}`}>
            {shouldRenderCreateModal && (
                <CreateAccountApiKeyModal
                    isVisible={isCreateModalVisible}
                    onHide={hideModal}
                    idForEdit={idForEdit}
                    onSuccess={onSuccess}
                    isEditing={!!idForEdit}
                />
            )}
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    tableAnimation
                    subHeader={showFiltersHeader(response)}
                    style={{ boxShadow: 'none' }}
                    headerComponent={
                        <TableFilters
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                            className="flex-row-reverse justify-between"
                        >
                            <div className="flex flex-1 justify-end">
                                <RefreshButton onRefresh={() => onRefresh()} />
                            </div>
                        </TableFilters>
                    }
                    pagination
                    className="w-full mt-0 border border-mainBorder rounded"
                    selectedRow={tablePopupProps && tablePopupProps.selectedItem}
                    onRowClicked={isFromSelectPopup ? onSelectRow : undefined}
                    paginationProps={paginationProps}
                    columns={[
                        {
                            selector: row => row?.name ?? '',
                            name: t('@common.name'),
                            sortable: true,
                            grow: 0.8,
                        },
                        {
                            selector: row => row?.key ?? '',
                            name: t('@common.key'),
                            style: {
                                display: 'flex',
                                justifyContent: 'center',
                                width: 'auto',
                                flexDirection: 'row',
                                alignItems: 'center',
                            },
                            width: 'auto',
                            cell: row => (
                                <div className="w-full justify-center flex">
                                    <CopyButton
                                        successCopyMessage="Api key has been copied to clipboard!"
                                        textForCopy={row?.key}
                                        title="Copy key"
                                    />
                                </div>
                            ),
                            grow: 1,
                        },
                        {
                            selector: row => row?.role ?? '',
                            name: t('@common.role'),
                            grow: 0.8,
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={isFromSelectPopup ? [] : tableActions}
                />
            )}

            {currentWidth <= breakpoints.sm && (
                <>
                    <TableMobile
                        {...commonTableProps}
                        mainKeyProperty="name"
                        initialLoading={initLoading}
                        onClickItem={onSelectRow}
                        selectedItem={tablePopupProps && tablePopupProps.selectedItem}
                        columns={[
                            {
                                selector: row => row?.name ?? '',
                                name: t('@common.name'),
                            },
                            {
                                selector: row => row?.key ?? '',
                                name: t('@common.key'),
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 'auto',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                },
                                width: 'auto',
                                cell: row => (
                                    <div className="flex-1 justify-start flex">
                                        <CopyButton
                                            successCopyMessage="Api key has been copied to clipboard!"
                                            textForCopy={row?.key}
                                            title="Copy key"
                                        />
                                    </div>
                                ),
                                grow: 1,
                            },
                            {
                                selector: row => row?.role ?? '',
                                name: t('@common.role'),
                            },
                            {
                                selector: row => row?.lastModified ?? '',
                                name: t('@common.last-modified'),
                            },
                            {
                                selector: row => row?.modifiedBy ?? '',
                                name: t('@common.modified-by'),
                            },
                        ]}
                        actions={isFromSelectPopup ? [] : tableActions}
                        paginationProps={{
                            ...paginationProps,
                            hasNextPage: !!response.hasNextPage,
                        }}
                    />
                </>
            )}
        </div>
    );
};
