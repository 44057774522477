import { CSSProperties, ButtonHTMLAttributes, ReactNode } from 'react';
import { buttonHoverClassName } from '../../constants';
import { Loader } from '../ui';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title?: string;
    onClick?: (e: any) => any;
    style?: CSSProperties;
    className?: string;
    htmlType?: any;
    loading?: boolean;
    name?: string;
    shouldLoadingOnClick?: boolean;
    icon?: ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
    onClick,
    className,
    htmlType = 'button',
    name,
    loading,
    style,
    title,
    icon,
    shouldLoadingOnClick,
    ...props
}) => {
    return (
        <button
            type={htmlType}
            onClick={e => {
                !props.disabled && onClick && onClick(e);
            }}
            disabled={loading || props.disabled}
            className={`bg-white text-xs sm:text-sm flex h-8 sm:h-10 items-center text-mainGrayText border-grey200 border-solid border justify-center ${
                loading || props.disabled ? 'cursor-default' : 'cursor-pointer'
            } ${!props.disabled && buttonHoverClassName} ${className}`}
            style={{
                paddingLeft: 12,
                borderRadius: 4,
                paddingRight: 12,
                ...style,
            }}
            {...props}
        >
            {loading ? (
                <Loader size={24} />
            ) : (
                <>
                    {icon}
                    {title}
                </>
            )}
        </button>
    );
};
