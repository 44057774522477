import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { actions, actionTypes, ObjectUploadingType, selectors } from '../../redux/thunk/app/storage/uploadObjectsThunk';
import { rotateAnimationStyles } from '../../styles/animationStyles';
import { actionCreator } from '../../utils';
import { Icon, IconTypes } from '../common';
import { ConfirmationPopupModal } from '../modals';
import { toast } from '../ui';
import { UploadingObjectsRowList } from './UploadingObjectsRowList';

export interface UploadingObjectsDialogProps {
    style?: CSSProperties;
    className?: string;
    isFromHeader?: boolean;
}

export const UploadingObjectsDialog: React.FC<UploadingObjectsDialogProps> = ({ style, className, isFromHeader }) => {
    const dispatch = useDispatch();
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });
    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(true);

    const objectsUploading: ObjectUploadingType[] = useSelector(selectors.getObjectsUploading);
    const objectsFailed: string[] = useSelector(selectors.getObjectsFailed);
    const objectsSucceeded: string[] = useSelector(selectors.getObjectsSucceeded);

    const { shouldDisplay, title } = useMemo(() => {
        let _objectsUploading = objectsUploading.filter(x => !x.errorMessage);
        let _objectsFailed = objectsUploading.filter(x => !!x.errorMessage);

        let _title = `${
            _objectsUploading.length
                ? `${_objectsUploading.length} Object${_objectsUploading.length > 1 ? 's' : ''} uploading...\n`
                : ''
        }${
            _objectsFailed.length
                ? `${_objectsFailed.length} Object${_objectsFailed.length > 1 ? 's' : ''} failed!`
                : ''
        }`;

        return {
            shouldDisplay: !!objectsUploading.length,
            title: _title,
        };
    }, [objectsUploading]);

    useEffect(() => {
        if (objectsSucceeded.length > 0) {
            let timeout = setTimeout(() => {
                const isMoreThanOne = objectsSucceeded.length !== 1;
                const options: any = {
                    defaultValue: isMoreThanOne ? `{{count}} files has been uploaded!` : 'File has been uploaded!',
                };
                if (isMoreThanOne) options.count = objectsSucceeded.length;

                toast.success(t(`@file${isMoreThanOne ? 's' : ''}.uploaded.successfully`, options));
                dispatch(actions.resetSucceededObjects());
            }, 1500);

            return () => clearTimeout(timeout);
        }
    }, [objectsSucceeded]);

    useEffect(() => {
        if (objectsFailed.length > 0) {
            let timeout = setTimeout(() => {
                const isMoreThanOne = objectsFailed.length !== 1;
                const options: any = {
                    defaultValue: isMoreThanOne ? `{{count}} files failed upload!` : 'File failed to upload!',
                };
                if (isMoreThanOne) options.count = objectsFailed.length;

                toast.error(t(`@file${isMoreThanOne ? 's' : ''}.failed.uploading`, options));
                dispatch(actions.resetFailedObjects());
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [objectsFailed]);

    const onClickCloseAll = () => {
        onToggleVisibility(false);
        objectsUploading.forEach(_objectUploading => {
            _objectUploading.cancelToken.cancel();
        });
        toast.info(`${objectsUploading.length} Objects has been canceled!`);
        dispatch(actions.setObjectsUploading([]));
    };

    const onClickMinimize = () => {
        setIsExpanded(true);
        dispatch(actionCreator(actionTypes.SET_IS_DIALOG_HIDDEN, true));
    };

    if (!shouldDisplay) {
        return null;
    }

    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    isVisible={isModalVisible}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    onConfirm={() => {
                        onClickCloseAll();
                    }}
                    confirmTitle="Yes"
                    cancelTitle="No"
                    title="Confirm Cancellation"
                    description="Are you sure you want to cancel all uploads?"
                />
            )}
            <div
                className={`fixed bottom-0 right-0 mt-12 sm:bottom-6 sm:right-6 z-50 flex flex-col w-full sm:w-96 dropdown-shadow rounded-b ${className}`}
                style={{ zIndex: 60, ...style }}
            >
                <div
                    className={`w-full flex flex-row h-auto pl-4 pr-2 py-2 justify-between items-center bg-lightBlack sm:rounded-t  ${
                        !isExpanded && 'sm:rounded-b'
                    }`}
                >
                    <span className="flex flex-1 justify-start items-center text-white font-normal text-sm whitespace-pre-wrap">
                        {title}
                    </span>
                    <div className="flex flex-row w-auto">
                        <div className="h-10 w-10 mr-0.5 rounded-full transition-all duration-500 bg-lightBlack hover:bg-mainOverlay flex justify-center items-center">
                            {!isFromHeader && (
                                <Transition in={isExpanded} timeout={100}>
                                    {state => (
                                        <div
                                            className="cursor-pointer flex justify-center"
                                            onClick={() => setIsExpanded(p => !p)}
                                        >
                                            <Icon
                                                name={IconTypes['chevron-up-white']}
                                                height={24}
                                                width={24}
                                                style={{ ...rotateAnimationStyles[state] }}
                                            />
                                        </div>
                                    )}
                                </Transition>
                            )}
                        </div>
                        {!isFromHeader && (
                            <div
                                onClick={() => {
                                    onClickMinimize();
                                }}
                                className="h-10 w-10 mr-0.5 cursor-pointer rounded-full transition-all duration-500 bg-lightBlack hover:bg-mainOverlay flex justify-center items-center"
                            >
                                <div className="w-4 h-0.5 rounded bg-white" />
                            </div>
                        )}
                        <div
                            onClick={() => {
                                onToggleVisibility(true);
                            }}
                            className="h-10 w-10 cursor-pointer rounded-full transition-all duration-500 bg-lightBlack hover:bg-mainOverlay flex justify-center items-center"
                        >
                            <Icon name={IconTypes['close-white']} height={24} width={24} />
                        </div>
                    </div>
                </div>
                <UploadingObjectsRowList isVisible={isExpanded} />
            </div>
        </>
    );
};
