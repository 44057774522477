import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { CSSProperties, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Transition } from 'react-transition-group';
import { rotateAnimationStyles } from '../../../styles/animationStyles';

export type CollapsibleRowProps = {
    title: string;
    defaultOpened?: boolean;
    className?: string;
    style?: CSSProperties;
    onCollapsedChange?: (collapsed: boolean) => any;
};

export const CollapsibleRow: React.FC<CollapsibleRowProps> = ({
    title,
    children,
    className,
    defaultOpened,
    style,
    onCollapsedChange,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(!defaultOpened);

    useEffect(() => {
        if (defaultOpened !== undefined) {
            setIsCollapsed(!defaultOpened);
        }
    }, [defaultOpened]);

    const onCollapse = () => {
        onCollapsedChange && onCollapsedChange(!isCollapsed);
        setIsCollapsed(prev => !prev);
    };

    return (
        <div className="border-b border-mainBorder py-3">
            <div
                onClick={onCollapse}
                className={` items-center cursor-pointer w-full flex justify-between  ${className}`}
                style={{ ...style }}
            >
                <span className="text-blueMainText text-sm font-normal">{title}</span>
                <Transition timeout={100} in={!isCollapsed}>
                    {state => (
                        <div className="cursor-pointer p-1 rounded-full" style={{ ...rotateAnimationStyles[state] }}>
                            <GjirafaIcon size={22} name="ArrowDown" />
                        </div>
                    )}
                </Transition>
            </div>
            <AnimateHeight duration={250} delay={50} height={isCollapsed ? 0 : 'auto'}>
                {children}
            </AnimateHeight>
        </div>
    );
};
