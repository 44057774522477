import React, { CSSProperties, MouseEvent, useEffect, useRef, useState } from 'react';
import { AnimationComponent } from '../../animation';
export interface ToggleActionButtonProps {
    isDropdownOpen?: boolean;
    setIsDropdownOpen?: (open: boolean) => any;
}

export const ToggleActionButton: React.FC<ToggleActionButtonProps> = ({
    children,
    isDropdownOpen,
    setIsDropdownOpen,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (event.target instanceof HTMLElement) {
                if (!ref.current || ref.current.contains(event.target)) return;
                setIsDropdownOpen ? setIsDropdownOpen(false) : setOpen(false);
            }
        };
        document.addEventListener('mousedown', (event: any) => listener(event));
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', (event: any) => listener(event));
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, setOpen, setIsDropdownOpen]);

    const onTriggerClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setIsDropdownOpen ? setIsDropdownOpen(!isDropdownOpen) : setOpen(!open);
    };

    const dropdownStyle: CSSProperties = ref.current
        ? {
              position: 'fixed',
              zIndex: 40,
              right: ref.current?.offsetWidth + ref.current?.clientWidth,
              marginTop: -ref.current.clientHeight,
          }
        : {};

    return (
        <div ref={ref}>
            <button
                className={`relative flex justify-center items-center text-sm hover:bg-mainBorder focus:outline-none outline-none z-30 transition-all rounded ${
                    isDropdownOpen ?? open ? 'bg-mainBorder' : ''
                }`}
                onClick={onTriggerClick}
            >
                <span className="flex justify-center items-center px-4 pb-2 text-base">...</span>
            </button>
            <AnimationComponent
                show={isDropdownOpen ?? open}
                type="fade-top"
                transform="transform-gpu"
                duration={600}
                delay={100}
            >
                <div
                    className="bg-white w-52 rounded z-50 overflow-visible"
                    style={{
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
                        ...dropdownStyle,
                    }}
                >
                    {children}
                </div>
            </AnimationComponent>
        </div>
    );
};
