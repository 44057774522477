import { CSSProperties } from 'react';
import Select, { GroupTypeBase, OptionTypeBase, Props, Styles } from 'react-select';
import { ErrorTextComponent } from '../messages';
import { ReactNode } from 'react';
export interface SelectFieldProps {
    label?: string;
    labelClassName?: string;
    labelStyle?: CSSProperties;
    hideLabel?: boolean;
    labelRight?: ReactNode;
    className?: string;
    style?: CSSProperties;
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    required?: boolean;
    maxMenuHeight?: number;
    disabled?: boolean;
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const selectFieldStyles: Styles<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>> = {};

export const SelectField: React.FC<Props & SelectFieldProps> = ({
    children,
    hideLabel,
    label,
    labelClassName,
    labelStyle,
    labelRight,
    className,
    style,
    errorClassName,
    errorMessage,
    errorStyle,
    required,
    maxMenuHeight,
    isMulti,
    disabled,
    ...selectProps
}) => {
    const formatGroupLabel = data => (
        <div style={groupStyles} className="flex justify-between items-center py-1">
            <span className="text-secondaryText">{data.label} :</span>
            <span className="text-secondaryText bg-mainBackground p-1 text-center rounded-full h-6 w-6">
                {data.options.length}
            </span>
        </div>
    );

    return (
        <div className={`w-full flex flex-col ${className} ${disabled && 'cursor-not-allowed'}`} style={style}>
            {!hideLabel && (
                <div
                    style={{
                        minHeight: 24,
                    }}
                    className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                >
                    <label className="text-xs sm:text-sm text-secondaryText font-normal">
                        {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                    </label>
                    {labelRight && labelRight}
                </div>
            )}
            <Select
                className="h-8 sm:h-10"
                styles={{
                    clearIndicator: base => ({
                        ...base,
                    }),
                    placeholder: base => ({
                        ...base,
                        color: '#A3B0C2',
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        fontSize: 14,
                        fontWeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }),
                    input: base => ({
                        ...base,
                        color: '#052D61',
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        fontSize: 14,
                        fontWeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }),
                    container: base => ({
                        ...base,
                        height: '40px !important',
                        minHeight: '40px !important',
                        maxHeight: '40px !important',
                        '@media only screen and (max-width: 640px)': {
                            height: '32px !important',
                            minHeight: '32px !important',
                            maxHeight: '32px !important',
                        },
                    }),
                    control: base => ({
                        ...base,
                        border: `1px solid ${errorMessage ? '#ff0033' : '#E2E2E2'}`,
                        height: '40px !important',
                        minHeight: '40px !important',
                        maxHeight: '40px !important',
                        '@media only screen and (max-width: 640px)': {
                            height: '32px !important',
                            minHeight: '32px !important',
                            maxHeight: '32px !important',
                        },
                        cursor: 'pointer',
                    }),
                    dropdownIndicator: base => ({
                        ...base,
                    }),
                    group: base => ({
                        ...base,
                    }),
                    groupHeading: base => ({
                        ...base,
                    }),
                    indicatorSeparator: () => ({
                        display: 'none',
                    }),
                    indicatorsContainer: base => ({
                        ...base,
                    }),
                    option: (b, state) => ({
                        ...b,
                        fontSize: 14,
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        color: '#052D61',
                        fontWeight: 'normal',
                        backgroundColor: state.isSelected ? '#caebfa' : state.isFocused ? '#F6F7F9' : 'white',
                        ':active': {
                            backgroundColor: '#caebfa',
                        },
                        cursor: 'pointer',
                    }),
                    menuList: b => ({
                        ...b,
                    }),
                    singleValue: () => ({
                        color: '#052D61',
                        fontSize: 14,
                        '@media only screen and (max-width: 640px)': {
                            fontSize: 12,
                        },
                        overflow: 'hidden',
                        width: 'auto',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }),
                    menu: b => ({
                        ...b,
                        zIndex: 40,
                    }),
                    valueContainer: base => ({
                        ...base,
                        height: '100%',
                        minHeight: '100%',
                        maxHeight: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        '@media only screen and (max-width: 640px)': {
                            height: '100%',
                            minHeight: '100%',
                            maxHeight: '100%',
                            paddingTop: '0px !important',
                        },
                    }),
                }}
                menuPlacement="auto"
                // menuShouldBlockScroll={false}
                menuShouldScrollIntoView={true}
                formatGroupLabel={formatGroupLabel}
                isDisabled={disabled}
                {...selectProps}
            />
            <ErrorTextComponent message={errorMessage} />
            {children}
        </div>
    );
};
