import { nanoid } from 'nanoid';
import { useContext, useEffect, useMemo, useState } from 'react';
import { RefreshButton } from '../../../../../components/buttons/RefreshButton';
import { InfiniteScroll } from '../../../../../components/common';
import { DetailsLoader } from '../../../../../components/details-entity';
import { SearchInput } from '../../../../../components/inputs/SearchInput';
import { CustomTab, CustomTabType } from '../../../../../components/tabs';
import { Loader } from '../../../../../components/ui';
import { inputTypes } from '../../../../../constants';
import { useAccountId } from '../../../../../hooks';
import {
    TaskListModel,
    TaskListResponseModel,
    TaskTypeListModel,
    TaskTypeListResponseModel,
    TaskVersionDetailsResponseModel,
    TaskVersionListResponseModel,
} from '../../../../../models';
import axios from '../../../../../services/axios';
import { getSearchParams } from '../../../../../utils';
import { taskInputTypes } from '../../../tasks/constants';
import {
    createPipelineContext,
    TPipelineStepInputState,
    TPipelineStepOutputState,
    TPipelineStepState,
} from '../contexts';
import { Task } from './Task';

interface IAddTaskProps {}

type tasksQueryType = {
    searchQuery: string;
    typeId: number;
    page: number;
};
const initialQuery: tasksQueryType = {
    searchQuery: '',
    page: 0,
    typeId: 0,
};

type TTasksResponse = {
    query: tasksQueryType;
    models: TaskListModel[];
    hasNextPage: boolean;
};

export const AddTask = ({}: IAddTaskProps) => {
    const accountId = useAccountId();
    const { tasksVersions, setSteps, setTasksVersions, setStepsInputs, setStepsOutputs } =
        useContext(createPipelineContext);

    const [types, setTypes] = useState<TaskTypeListModel[]>([]);
    const [typesLoading, setTypesLoading] = useState(false);

    const [tasksResponse, setTasksResponse] = useState<TTasksResponse>({
        models: [],
        query: initialQuery,
        hasNextPage: false,
    });
    const [tasksLoading, setTasksLoading] = useState(false);
    const [activeTypeTab, setActiveTypeTab] = useState('0');

    useEffect(() => {
        fetchTypes();
        fetchTasks({ page: 0 });
    }, [accountId]);

    const fetchTypes = async () => {
        try {
            setTypesLoading(true);
            const response: TaskTypeListResponseModel = await axios.get('/pipeline/task/type/list');
            setTypes(response.models);
        } catch (err) {
        } finally {
            setTypesLoading(false);
        }
    };

    const fetchTasks = async (query: Partial<tasksQueryType>, resetQuery?: boolean) => {
        try {
            setTasksLoading(true);
            const previousQuery = { ...tasksResponse.query };

            const searchParams = getSearchParams(previousQuery, query, resetQuery);

            const response: TaskListResponseModel = await axios.get(
                `/pipeline/task/list?${new URLSearchParams(searchParams)}`
            );
            setTasksResponse(prev => ({
                hasNextPage: searchParams.page < (response.totalPages || 1) - 1,
                models: searchParams.page > 0 ? [...prev.models, ...response.models] : response.models,
                query: searchParams,
            }));
        } catch (err) {
        } finally {
            setTasksLoading(false);
        }
    };

    const onChangeTab = (tab: CustomTabType) => {
        setActiveTypeTab(tab.key);
        fetchTasks({ typeId: tab.key, page: 0 });
    };

    const onRefreshTasks = () => fetchTasks({});

    const onClickAdd = (task: TaskListModel) => () => {
        const _id = nanoid();

        const newStep: TPipelineStepState = {
            _id,
            displayName: '',
            id: 0,
            pipelineId: 0,
            taskTitle: task.title,
            taskType: task.type,
            outputReferenceName: '',
            versionId: task.lastVersion.id,
            taskId: task.id,
            outputReferenceNameDisabled: false,
        };
        setSteps(prev => [...prev, newStep]);

        addStepValues(_id, newStep.versionId);
        tryAddVersions(task.id);
    };

    const addStepValues = async (_id: string, taskVersionId: number) => {
        try {
            const response: TaskVersionDetailsResponseModel = await axios.get(
                `/pipeline/task/version?id=${taskVersionId}`
            );
            const newStepInput: TPipelineStepInputState = {
                _id,
                inputs: response.model.inputs.map(x => ({
                    ...x,
                    inputType:
                        x.inputType === inputTypes.input || x.inputType === taskInputTypes.filePath
                            ? inputTypes.dropdownOpen
                            : x.inputType,
                    inputName: x.name + x.id,
                    value: '',
                    valueId: 0,
                })),
            };

            const newStepOutput: TPipelineStepOutputState = {
                _id,
                outputs: response.model.outputs,
            };

            setStepsInputs(prev => [...prev, newStepInput]);
            setStepsOutputs(prev => [...prev, newStepOutput]);
        } catch (err) {}
    };

    const tryAddVersions = async (taskId: number) => {
        if (taskId in tasksVersions) {
            return;
        }
        try {
            const response: TaskVersionListResponseModel = await axios.get(
                `/pipeline/task/version/list?taskId=${taskId}`
            );
            setTasksVersions(prev => {
                prev[taskId] = response.models.map(x => ({
                    id: x.id,
                    versionNumber: x.versionNumber,
                }));
                return prev;
            });
        } catch (err) {}
    };

    const onSearch = (value: string) => fetchTasks({ searchQuery: value });

    const onLoadMore = () => fetchTasks({ page: tasksResponse.query.page + 1 });

    const tasksInitialLoading = useMemo(
        () => tasksLoading && tasksResponse.query.page === 0,
        [tasksResponse.query.page, tasksLoading]
    );

    return (
        <div className="w-full overflow-y-auto overflow-x-auto" style={{ scrollbarGutter: 'stable' }}>
            <div className="flex items-center justify-between  py-2 pr-2">
                <div className="flex flex-wrap  items-center pl-4">
                    <h2 className="text-base text-blueMainText font-normal">Add Task</h2>
                    <div className="w-[1px] h-5 bg-mainBorder ml-4" />
                    <RefreshButton onRefresh={onRefreshTasks} className="!mb-0 !border-none !text-blueMainText" />
                </div>
                <SearchInput triggerSearchType="onchange+onenter" className="" onSearch={onSearch} />
            </div>
            {typesLoading ? (
                <Loader className="mt-4 mx-auto" />
            ) : (
                <>
                    <CustomTab
                        activeTabKey={activeTypeTab}
                        onChangeTab={onChangeTab}
                        tabs={[
                            {
                                displayName: 'All',
                                key: '0',
                            },
                            ...types.map(x => ({
                                displayName: x.name,
                                key: x.id,
                            })),
                        ]}
                    />
                    {tasksInitialLoading ? (
                        <DetailsLoader />
                    ) : (
                        <InfiniteScroll
                            loading={tasksLoading}
                            currentPage={tasksResponse.query.page}
                            hasMore={tasksResponse.hasNextPage}
                            loadMore={onLoadMore}
                            className="mt-2"
                        >
                            {tasksResponse.models.map(x => (
                                <Task key={x.id} title={x.title} type={x.type} onClickAdd={onClickAdd(x)} />
                            ))}
                        </InfiniteScroll>
                    )}
                </>
            )}
        </div>
    );
};
