import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CommonTableProps, NoDataImagesType, Table, TableFilters } from '.';
import { PIPELINES_PATH, CREATE_PIPELINE_PATH } from '../../constants';
import { useAccountId, useCurrentWidth } from '../../hooks';
import { PipelineListResponseModel, PipelineListModel } from '../../models';
import { selectors, actions } from '../../redux/thunk/app/pipeline/pipelinesThunk';
import { queryParams, showFiltersHeader } from '../../utils';
import { CopyTextIcon } from '../common';

export interface PipelinesTableProps extends CommonTableProps {}

export const PipelinesTable: FC<PipelinesTableProps> = ({
    fetchDataOnMount,
    isFromSelectPopup,
    tablePopupProps,
    className,
    disableFilters,
    query,
    hideFilters,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const history = useHistory();

    const loading = useSelector(selectors.getIsLoading);
    const response: PipelineListResponseModel = useSelector(selectors.getResponse);

    useEffect(() => {
        requestList({ searchQuery: '' });
    }, [accountId]);

    const requestList = (query: any, resetQuery?: boolean) =>
        dispatch(actions.request({ accountId, ...query }, resetQuery));

    const onRefresh = () => requestList({});

    const onRowClicked = (row: PipelineListModel) =>
        history.push(`${PIPELINES_PATH}/details/${row.id}${queryParams.formatForNavigation()}`);

    const onClickEdit = (row: PipelineListModel) =>
        history.push(`${PIPELINES_PATH}/edit-pipeline/${row.id}${queryParams.formatForNavigation()}`);

    const onClickCreate = () => history.push(CREATE_PIPELINE_PATH + queryParams.formatForNavigation());

    const onClickCreatePipelineJob = (row: PipelineListModel) => history.push(`${PIPELINES_PATH}/${row.id}/create-new-pipeline-job/${queryParams.formatForNavigation()}`);

    const onSelectRow = (row: PipelineListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.title,
                value: row.id.toString(),
                ...row,
            });
        }
    };
    const onPageChanged = page => dispatch(actions.request({ page }));
    
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    return (
        <div className={`flex flex-col ${className}`}>
            <Table
                highlightOnHover
                selectableRows
                showHeaderButtons
                loading={loading}
                emptyDataProps={{
                    emptyText: t('@pages.no-pipelines'),
                    actionMessage: `+ ${t('@pages.create-new-pipeline')}`,
                    imageType: NoDataImagesType.Task,
                    onClickAction: onClickCreate,
                }}
                pagination
                paginationProps={paginationProps}
                modelEndpoint="pipeline"
                response={response}
                requestList={requestList}
                onRowClicked={isFromSelectPopup ? onSelectRow : onRowClicked}
                headerButtonProps={{
                    onRefresh,
                    onClickCreate,
                    createTitle: t('@pages.create-new-pipeline', { defaultValue: 'Create new Pipeline' }),
                }}
                subHeader={showFiltersHeader(response)}
                headerComponent={
                    <TableFilters
                        onChangeQuery={(key, value) => {
                            requestList({
                                [key]: value,
                            });
                        }}
                        response={response}
                        onSearch={searchQuery => {
                            requestList({ searchQuery, page: 0 });
                        }}
                    />
                }
                actions={[
                    {
                        action: 'details',
                        displayName: t('@view.details', { defaultValue: 'View details' }),
                        onClick: onRowClicked,
                    },
                    {
                        action: 'edit',
                        onClick: onClickEdit,
                    },
                    {
                        action: 'plus-main-color',
                        displayName: t('@view.create.pipeline.job', { defaultValue: 'Create Pipeline Job' }),
                        onClick: onClickCreatePipelineJob,
                    },
                    {
                        action: 'delete',
                    },
                ]}
                columns={[
                    {
                        name: t('@common.title'),
                        selector: (row: PipelineListModel) => row.title,
                    },
                    {
                        name: t('@alias'),
                        selector: (row: PipelineListModel) => row.alias,
                        cell: (row: PipelineListModel) => (
                            <div className="flex w-full items-center group">
                                {row.alias}
                                <CopyTextIcon
                                    textToCopy={row.alias}
                                    tooltipId="copyPipelineAlias"
                                    tooltipContent={t('@copy.alias', { defaultValue: 'Copy alias' })}
                                    className="ml-2"
                                />
                            </div>
                        ),
                    },
                    {
                        name: t('@common.last-modified'),
                        width: '180px',
                        selector: row => row?.lastModified ?? '',
                        cell: row => <span>{row?.lastModified}</span>,
                        sortable: true,
                    },
                    {
                        name: t('@common.modified-by'),
                        selector: row => row?.modifiedBy ?? '',
                        width: '140px',
                        cell: row => <span>{row?.modifiedBy}</span>,
                    },
                ]}
            />
        </div>
    );
};
