import {
    ACCOUNT_SETTINGS_PATH,
    ADMIN_ACCOUNTS_PATH,
    ADMIN_ALGORITHMS_PATH,
    ADMIN_ALGORITHM_GROUP_PATH,
    ADMIN_CONFIGURATION_ITEMS_PATH,
    ADMIN_DATA_SOURCE_TYPES_PATH,
    ADMIN_FLOW_ACTIONS_PATH,
    ADMIN_INTEGRATION_TYPES_PATH,
    ADMIN_LOCALIZATION_PATH,
    ADMIN_SCHEMA_TYPES_PATH,
    ADMIN_VOUCHERS_PATH,
    ARTICLE_RECOMMENDATION_ENGINE_PATH,
    ARTIFACTS_PATH,
    BASE_ADMIN_PATH,
    BASE_PROPENSITY_PATH,
    BASE_RECOMMENDATION_PATH,
    BILLING_PATH,
    CATALOGS_PATH,
    CHURN_PROPENSITY_ENGINE_PATH,
    CLASSIFIED_AD_RECOMMENDATION_ENGINE_PATH,
    CUSTOM_ENDPOINTS_PATH,
    // DASHBOARD_PATH,
    DATA_SOURCES_PATH,
    DEPLOYMENTS_PATH,
    FEATURE_ENGINEERING_PATH,
    FILTERS_PATH,
    INFORMATION_PATH,
    LOOKALIKE_ENGINE_PATH,
    MODELS_PATH,
    NOTEBOOK_SERVERS_PATH,
    PIPELINES_PATH,
    PRODUCT_RECOMMENDATION_ENGINE_PATH,
    PURCHASE_PROPENSITY_ENGINE_PATH,
    STORAGE_PATH,
    SUBSCRIBE_PROPENSITY_ENGINE_PATH,
    TASKS_PATH,
    VIDEO_RECOMMENDATION_ENGINE_PATH,
} from '../../constants';
import { AccountDetailsModel, UserDetailsModel } from '../../models';

// to?: string;
// icon?: { name: string; size?: number };
// title: string;
// arrowIconNames?: { first: string; second: string };
// openList?: string | null;
// setOpenList?: (val: string | null) => void;
// nestedList?: ItemProps[];

export enum SidebarTagTypes {
    coming_soon = 'Coming soon',
    beta = 'Beta',
    alpha = 'Alpha',
}

interface commonSidebarLinksType {
    titleKey: string;
    to?: string;
    icon?: {
        name: string;
        size?: number;
    };
    activePath?: string;
    checkIsPathActive?: (Location: Location) => boolean;
    shouldRenderLink?: (account: AccountDetailsModel, user: UserDetailsModel) => boolean;
    isPathDisabled?: () => boolean;
    disabledTooltip?: string;
    nestedList?: commonSidebarLinksType[];
    tagType?: SidebarTagTypes;
}
interface SidebarLinksType extends commonSidebarLinksType {}

export const mainSidebarLinks: SidebarLinksType[] = [
    // {
    //     titleKey: 'Dashboard',
    //     icon: {
    //         name: 'Dashboard',
    //     },
    //     to: DASHBOARD_PATH,
    //     checkIsPathActive: (location: Location) => location.pathname === '/' || location.pathname === DASHBOARD_PATH,
    //     tagType: SidebarTagTypes.coming_soon,
    // },
    {
        titleKey: 'AI Engine',
        icon: {
            name: 'Share',
        },
        activePath: '/AI-engine',
        nestedList: [
            {
                titleKey: 'Recommendation Engine',
                activePath: BASE_RECOMMENDATION_PATH,
                nestedList: [
                    {
                        titleKey: 'Article',
                        to: ARTICLE_RECOMMENDATION_ENGINE_PATH,
                        activePath: ARTICLE_RECOMMENDATION_ENGINE_PATH,
                    },
                    {
                        titleKey: 'Product',
                        to: PRODUCT_RECOMMENDATION_ENGINE_PATH,
                        activePath: PRODUCT_RECOMMENDATION_ENGINE_PATH,
                    },
                    {
                        titleKey: 'Video',
                        to: VIDEO_RECOMMENDATION_ENGINE_PATH,
                        activePath: VIDEO_RECOMMENDATION_ENGINE_PATH,
                    },
                    {
                        titleKey: 'Classified Ad',
                        to: CLASSIFIED_AD_RECOMMENDATION_ENGINE_PATH,
                        activePath: CLASSIFIED_AD_RECOMMENDATION_ENGINE_PATH,
                    },
                ],
            },
            {
                titleKey: 'Lookalike Engine',
                to: LOOKALIKE_ENGINE_PATH,
            },
            {
                titleKey: 'Propensity Engine',
                activePath: BASE_PROPENSITY_PATH,
                nestedList: [
                    {
                        titleKey: 'Churn',
                        to: CHURN_PROPENSITY_ENGINE_PATH,
                        activePath: CHURN_PROPENSITY_ENGINE_PATH,
                    },
                    {
                        titleKey: 'Purchase',
                        to: PURCHASE_PROPENSITY_ENGINE_PATH,
                        activePath: PURCHASE_PROPENSITY_ENGINE_PATH,
                    },
                    {
                        titleKey: 'Subscribe',
                        to: SUBSCRIBE_PROPENSITY_ENGINE_PATH,
                        activePath: SUBSCRIBE_PROPENSITY_ENGINE_PATH,
                        tagType: SidebarTagTypes.coming_soon,
                    },
                ],
            },
        ],
    },
    {
        titleKey: 'Data',
        icon: {
            name: 'Layers',
        },
        activePath: '/data/',
        nestedList: [
            {
                titleKey: 'Artifacts',
                to: ARTIFACTS_PATH,
            },
            {
                titleKey: 'Storage',
                to: STORAGE_PATH,
            },
            {
                titleKey: 'Data Sources',
                to: DATA_SOURCES_PATH,
            },
            {
                titleKey: 'Catalogs',
                to: CATALOGS_PATH,
            },
            {
                titleKey: 'Filters',
                to: FILTERS_PATH,
            },
        ],
    },
    {
        titleKey: 'Lab',
        activePath: '/lab',
        nestedList: [
            {
                titleKey: 'Notebook Servers',
                to: NOTEBOOK_SERVERS_PATH,
            },
            {
                titleKey: 'Endpoints',
                to: CUSTOM_ENDPOINTS_PATH,
                tagType: SidebarTagTypes.beta,
            },
            {
                titleKey: 'Tasks',
                to: TASKS_PATH,
                tagType: SidebarTagTypes.alpha,
            },
            {
                titleKey: 'Pipelines',
                to: PIPELINES_PATH,
                tagType: SidebarTagTypes.alpha,
            },
        ],
        icon: {
            name: 'Terminal',
        },
    },
    {
        titleKey: 'Workflow',
        icon: {
            name: 'Share',
        },
        activePath: '/workflow',
        nestedList: [
            {
                titleKey: 'Information',
                to: INFORMATION_PATH,
            },
            // {
            //     titleKey: 'Datasets',
            //     to: DATASETS_PATH,
            // },
            {
                titleKey: 'Feature Engineering',
                to: FEATURE_ENGINEERING_PATH,
            },
            {
                titleKey: 'Models',
                to: MODELS_PATH,
            },
            {
                titleKey: 'Deployments',
                to: DEPLOYMENTS_PATH,
            },
        ],
    },
    {
        titleKey: 'Settings',
        to: ACCOUNT_SETTINGS_PATH,
        icon: {
            name: 'Settings',
        },
        shouldRenderLink: (account, user) => {
            return account.isAdmin || user.isAdmin;
        },
    },
    {
        titleKey: 'Billing',
        to: BILLING_PATH,
        icon: {
            name: 'Payment',
        },
        shouldRenderLink: (account, user) => {
            return account.isAdmin || user.isAdmin;
        },
    },
];

export const adminSidebarLinks: SidebarLinksType[] = [
    {
        titleKey: 'Admin Dashboard',
        to: BASE_ADMIN_PATH,
        icon: {
            name: 'Dashboard',
        },
        checkIsPathActive: (location: Location) => location.pathname === BASE_ADMIN_PATH,
    },
    {
        titleKey: 'Localization',
        to: ADMIN_LOCALIZATION_PATH,
        icon: {
            name: 'Language',
        },
    },
    {
        titleKey: 'Accounts',
        to: ADMIN_ACCOUNTS_PATH,
    },
    {
        titleKey: 'Integration Types',
        to: ADMIN_INTEGRATION_TYPES_PATH,
    },
    {
        titleKey: 'Configuration Items',
        to: ADMIN_CONFIGURATION_ITEMS_PATH,
    },
    {
        titleKey: 'Vouchers',
        to: ADMIN_VOUCHERS_PATH,
    },
    {
        titleKey: 'Datasource Type',
        to: ADMIN_DATA_SOURCE_TYPES_PATH,
        activePath: '/data-source-types',
    },
    {
        titleKey: 'Flow actions',
        to: ADMIN_FLOW_ACTIONS_PATH,
        activePath: '/flow-actions',
    },
    {
        titleKey: 'Algorithms',
        to: ADMIN_ALGORITHMS_PATH,
        activePath: '/algorithms',
    },
    {
        titleKey: 'Algorithm Groups',
        to: ADMIN_ALGORITHM_GROUP_PATH,
        checkIsPathActive: ({ pathname }: Location) => pathname === ADMIN_ALGORITHM_GROUP_PATH,
    },
    {
        titleKey: 'Schema Types',
        to: ADMIN_SCHEMA_TYPES_PATH,
        activePath: '/schema-types',
    },
];
