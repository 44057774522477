import { InitialStateType, AccountUserListResponseModel } from '../../../../models';
import { RootState } from '../../rootReducer';
import { Dispatch } from 'redux';
import axios from '../../../../services/axios';
import {
    getWidth,
    actionCreator,
    getFormattedQuery,
    getFormattedResponseForPayload,
    getSearchParams,
} from '../../../../utils';
import { breakpoints } from '../../../../constants';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/account/accountUsersThunk/REQUEST',
    SUCCESS: '@app/account/accountUsersThunk/SUCCESS',
    FAILURE: '@app/account/accountUsersThunk/FAILURE',
    SET_INITIAL_LOADING: '@app/account/accountUsersThunk/SET_INITIAL_LOADING',
};

/**
 * Initial state
 */

const initialState: InitialStateType<AccountUserListResponseModel> = {
    isLoading: false,
    initialLoading: false,
    errorMessage: '',
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
        query: {},
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.accountUsers.isLoading,
    getResponse: (state: RootState) => state.app.accountUsers.response,
    getErrorMessage: (state: RootState) => state.app.accountUsers.errorMessage,
    getInitialLoading: (state: RootState) => state.app.accountUsers.initialLoading,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_INITIAL_LOADING } = actionTypes;

const Reducer = (state: InitialStateType<AccountUserListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case SET_INITIAL_LOADING:
            return {
                ...state,
                initialLoading: payload,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
                initialLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
                initialLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query: any, resetQuery = false) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const isMobile = getWidth() <= breakpoints.sm;
                const previousQuery = getState().app.accountUsers.response.query;

                if (query?.page === undefined) {
                    query.page = previousQuery?.page || 0;
                }

                if (isMobile && query.page === 0) dispatch(actionCreator(SET_INITIAL_LOADING, true));
                dispatch(actionCreator(REQUEST));

                const previousModels = isMobile && query.page > 0 ? getState().app.accountUsers.response.models : [];

                let searchParams = getSearchParams(previousQuery, query, resetQuery);

                const response: AccountUserListResponseModel = await axios.get(
                    `/accountUser/list?${getFormattedQuery(searchParams)}`
                );
                const res = getFormattedResponseForPayload(response, previousModels, query, searchParams);

                dispatch(actionCreator(SUCCESS, res));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
