import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAccountId } from '../../hooks';
import {
    NotebookInstanceTypeListResponseModel,
    NotebookServerDetailsResponseModel,
    NotebookServerPostModel,
    SelectOptionType,
} from '../../models';
import { selectors as notebookInstanceTypeSelectors } from '../../redux/thunk/app/notebook/notebookInstanceTypesThunk';
import axios from '../../services/axios';
import { deleteErrorKey } from '../../utils';
import { InfoIconWithModal } from '../common/dynamic-inputs/InfoIconWithModal';
import { InputField, SelectField, TextareaField } from '../inputs';
import { SideModal } from '../SideModal';
import { Loader } from '../ui';

export interface CreateNotebookServerModalProps {
    isVisible: boolean;
    onHide: () => any;
    idForEdit?: number | null;
    onSuccess?: (item: any, isEditing?: boolean) => any;
}

type formFieldsType = {
    title: string;
    description?: string;
    password?: string;
    instanceType: SelectOptionType | null;
};

export const CreateNotebookServerModal: React.FC<CreateNotebookServerModalProps> = ({
    isVisible,
    onHide: _onHide,
    idForEdit,
    onSuccess,
}) => {
    const { t } = useTranslation();
    const isEditing = !!idForEdit;
    const accountId = useAccountId();

    /**
     * Redux Selectors
     */
    const { models: instanceTypes }: NotebookInstanceTypeListResponseModel = useSelector(
        notebookInstanceTypeSelectors.getResponse
    );
    const instanceTypesLoading = useSelector(notebookInstanceTypeSelectors.getIsLoading);
    const instanceTypesOptions: SelectOptionType[] = instanceTypes.map(x => ({
        label: x.name,
        value: x.id?.toString(),
        ...x,
    }));

    /**
     * Local State
     */
    const [errors, setErrors] = useState<any>({});
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formFields, setFormFields] = useState<formFieldsType>({
        title: '',
        description: '',
        password: '',
        instanceType: null,
    });

    const onHide = () => {
        if (isEditing) {
            onClearValues();
        }
        _onHide();
    };

    useEffect(() => {
        if (isVisible && !instanceTypesLoading && instanceTypesOptions && instanceTypesOptions.length > 0) {
            const instanceType = instanceTypesOptions.find(x => x.value === '1') || instanceTypesOptions[0];
            setFormFields(prev => ({ ...prev, instanceType }));
        }
    }, [isVisible, instanceTypesLoading]);

    useEffect(() => {
        if (idForEdit) {
            fetchDetails(idForEdit);
        }
    }, [idForEdit]);

    const fetchDetails = async (id: number) => {
        try {
            setDetailsLoading(true);
            const query = {
                id: id?.toString(),
                accountId: accountId?.toString(),
            };
            const { model }: NotebookServerDetailsResponseModel = await axios.get(
                `/notebook?${new URLSearchParams(query)}`
            );
            setFormFields({
                instanceType: {
                    value: model.instanceType.id?.toString(),
                    label: model.instanceType.name,
                },
                title: model.title,
                description: model.description,
            });
        } catch (err) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            if (!onValidateForm()) {
                return;
            }
            setFormLoading(true);

            const { instanceType, title, description } = formFields;
            const payload: NotebookServerPostModel = {
                id: isEditing ? idForEdit : 0,
                accountId,
                instanceCount: 1,
                instanceTypeId: parseInt(instanceType?.value || '0') || 0,
                password: formFields.password || '',
                title,
                description,
            };
            const response: NotebookServerDetailsResponseModel = await axios.post('/notebook', payload);
            onSuccess && onSuccess(response, isEditing);
            onClearValues();
            onHide();
        } catch (err) {
        } finally {
            setFormLoading(false);
        }
    };

    const onChangeFormValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFormFields(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const onClearValues = () => {
        setErrors({});
        setFormFields({
            instanceType: null,
            password: '',
            title: '',
            description: '',
        });
    };

    const onValidateForm = () => {
        let isValid = true;
        let _errors: any = {};

        const { title, password, instanceType } = formFields;

        if (!title) {
            _errors.title = 'Title is required!';
            isValid = false;
        }
        if (!password && !isEditing) {
            _errors.password = 'Password is required!';
            isValid = false;
        }
        if (!instanceType) {
            _errors.instanceType = 'Instance Type is required!';
            isValid = false;
        }

        setErrors(_errors);
        return isValid;
    };

    const { buttonText, formId, title } = (() => ({
        title: t(`${isEditing ? 'edit' : 'create'}-notebookServer`),
        buttonText: t(`@common.${isEditing ? 'edit' : 'create'}`),
        formId: isEditing ? 'editNotebookServerForm' : 'createNotebookServerFrom',
    }))();

    return (
        <SideModal
            onHide={() => {
                setErrors({});
                onHide();
            }}
            isVisible={isVisible}
            title={title}
            buttonText={buttonText}
            formId={formId}
            loading={formLoading}
        >
            <form id={formId} onSubmit={handleSubmit} className="px-4 py-6">
                {detailsLoading ? (
                    <div className="py-12 w-full flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <InputField
                            required
                            name="notebookServers_title"
                            label={t('@common.title')}
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeFormValues('title', e.target.value)}
                            errorMessage={errors?.title}
                            value={formFields.title}
                        />
                        <TextareaField
                            name="notebookServers_description"
                            label={t('@common.description')}
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeFormValues('description', e.target.value)}
                            errorMessage={errors?.description}
                            value={formFields.description}
                            className="mt-4"
                        />
                        {!isEditing && (
                            <InputField
                                required
                                type="password"
                                label={t('password')}
                                placeholder={t('enter.password.here')}
                                value={formFields.password}
                                onChange={e => onChangeFormValues('password', e.target.value)}
                                errorMessage={errors?.password}
                                className="mt-4"
                            />
                        )}
                        <SelectField
                            labelRight={
                                formFields.instanceType?.description ? (
                                    <InfoIconWithModal infoText={formFields.instanceType?.description} />
                                ) : null
                            }
                            required
                            value={formFields.instanceType}
                            onChange={item => onChangeFormValues('instanceType', item)}
                            label={t('instance.type')}
                            placeholder={t('choose.an.instanceType')}
                            errorMessage={errors?.instanceType}
                            className="mt-4"
                            options={instanceTypesOptions}
                            isLoading={instanceTypesLoading}
                        />
                    </>
                )}
            </form>
        </SideModal>
    );
};
