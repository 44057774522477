import React from 'react';
import { SelectOptionType } from '../../../models';
import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { SelectedOption } from '.';
import { useTranslation } from 'react-i18next';

interface RenderSelectedItemsComponentProps {
    selectedItems: SelectOptionType[];
    operatorSelected?: SelectOptionType | null;
    onChangeSelectedItems: (items: SelectOptionType[], toDelete?: Boolean) => any;
    renderType?: 'full-width' | 'auto-width';
    entity?: string;
}

export const RenderSelectedItemsComponent: React.FC<RenderSelectedItemsComponentProps> = ({
    selectedItems,
    operatorSelected,
    onChangeSelectedItems,
    renderType = 'auto-width',
    entity,
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row items-center flex-wrap mt-1">
            {renderType !== 'auto-width' && selectedItems.length > 0 && (
                <span className="mb-2 text-secondaryText text-sm mt-2">
                    {t(`@selected.${entity}s`, { defaultValue: `Selected ${entity}s` })}
                </span>
            )}
            {selectedItems.map((item, index) => {
                if (renderType === 'auto-width')
                    return (
                        <>
                            {!!operatorSelected && index > 0 && (
                                <div className="text-secondaryText font-semibold text-xs px-1">
                                    {operatorSelected?.value.toUpperCase()}
                                </div>
                            )}
                            <SelectedOption
                                label={item.label}
                                onRemove={() =>
                                    onChangeSelectedItems(selectedItems.filter(x => x.value !== item.value))
                                }
                            />
                        </>
                    );

                return (
                    <div
                        className={`flex ${
                            index > 0 && 'mt-1'
                        } items-center justify-between w-full h-10 pl-3 border-mainBorder bg-mainBorder rounded`}
                    >
                        {!!operatorSelected && index > 0 && (
                            <div className="w-10 -ml-12 text-secondaryText font-semibold text-sm">
                                {operatorSelected?.value.toUpperCase()}
                            </div>
                        )}
                        <div className="flex w-full justify-between items-center">
                            <span className="text-blueMainText text-sm">{item.label}</span>
                            <div
                                onClick={() => onChangeSelectedItems(selectedItems.filter(x => x.value !== item.value), true)}
                                className="cursor-pointer items-center justify-center flex h-full px-3 text-secondaryText"
                            >
                                <GjirafaIcon name="Close" size={24} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
