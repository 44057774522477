import ReactTooltip, { TooltipProps as ReactTooltipsProps } from 'react-tooltip';

export interface TooltipProps extends ReactTooltipsProps {}

export const Tooltip: React.FC<TooltipProps> = ({ className, ...rest }) => {
    return (
        <ReactTooltip
            className={className}
            delayShow={200}
            delayHide={100}
            effect="solid"
            globalEventOff="click"
            {...rest}
        />
    );
};
