import React from 'react';
import { WorkflowModelListModel } from '../../../../../models';
import { InputField } from '../../../../inputs';

export interface ModelEvaluationMetricsProps {
    model: WorkflowModelListModel;
}

export const ModelEvaluationMetrics: React.FC<ModelEvaluationMetricsProps> = ({ model }) => {
    const { wrapper } = model;

    if (!wrapper?.evaluationMetrics) {
        return (
            <div className="flex w-full h-auto p-10 justify-center items-center">
                <span className="text-blueMainText font-medium text-base">No Evaluation Metrics for this model!</span>
            </div>
        );
    }
    const { evaluationMetrics } = wrapper;

    return (
        <div className="flex p-4 justify-start w-full">
            <div className="flex flex-col flex-wrap items-start justify-start mt-1 w-full">
                {Object.entries(evaluationMetrics).map(([key, value]) => (
                    <div key={key} className="flex flex-row items-center justify-start mt-2 mr-4 w-full">
                        <InputField
                            hideLabel
                            readOnly
                            disabled
                            value={key}
                            inputClassName="bg-blueStroke"
                            className="mr-4"
                            style={{ maxWidth: 160 }}
                        />
                        <div
                            className="w-auto h-10 px-3 flex flex-row items-center justify-start border rounded border-mainBorder"
                            style={{ minWidth: 300 }}
                        >
                            <span className="text-sm text-blueMainText">
                                {(typeof value === 'string' || typeof value === 'number') && value}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
