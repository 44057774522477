import React, { useEffect, useState } from 'react';
import { CreateButton } from '../../../../components/buttons';
import { Icon, IconTypes } from '../../../../components/common';
import { SwitchInput } from '../../../../components/inputs';
import { BasePopup } from '../../../../components/modals';
import { toast } from '../../../../components/ui';
import { useAccountId } from '../../../../hooks';
import { ArtifactVersionListModel } from '../../../../models';
import axios from '../../../../services/axios';

export interface SetDefaultVersionPopup {
    isVisible: boolean;
    onHide: () => any;
    artifactId?: number;
    onSuccess?: (versionId: number, isPermanent: boolean) => any;
    isPermanent: boolean;
    artifactVersion: ArtifactVersionListModel | null;
}

export const SetDefaultVersionPopup: React.FC<SetDefaultVersionPopup> = ({
    isVisible,
    onHide,
    artifactId,
    isPermanent,
    artifactVersion,
    onSuccess,
}) => {
    const accountId = useAccountId();
    const [permanent, setPermanent] = useState(isPermanent);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setPermanent(isPermanent);
    }, [isPermanent]);

    const onSubmit = async () => {
        if (!artifactVersion) {
            return;
        }
        try {
            setLoading(true);

            let payload = {
                id: artifactId,
                accountId,
                versionId: artifactVersion.id,
                patch: [
                    {
                        op: 'replace',
                        path: 'isPermanent',
                        value: permanent,
                    },
                    // {
                    //     op: 'replace',
                    //     path: 'defaultVersionUrl',
                    //     value: `/${urlArr[urlArr.length - 1]}`,
                    // },
                ],
            };
            await axios.patch(`/artifact`, payload);
            toast.success('Version has been set as default!');
            onHide();
            onSuccess && onSuccess(artifactVersion.id, permanent);
        } catch (err: any) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <BasePopup isVisible={isVisible} onHide={onHide}>
            <div className="flex flex-col bg-white h-auto rounded-lg" style={{ width: 340 }}>
                <span className="text-blueMainText flex justify-between font-medium text-base border-b p-4 px-6 border-mainBorder">
                    Default Version
                    <Icon name={IconTypes.close} className="cursor-pointer" onClick={onHide} />
                </span>
                <div className="flex flex-row justify-end py-5 px-6 items-center text-sm font-medium text-blueMainText">
                    Permanent :
                    <SwitchInput hideLabel className="ml-2" value={permanent} onChange={val => setPermanent(val)} />
                </div>
                <div className="flex w-full justify-end pb-4 px-6">
                    <CreateButton
                        loading={loading}
                        disabled={loading}
                        onClick={onSubmit}
                        title="Set Default"
                        className="px-4"
                    />
                </div>
            </div>
        </BasePopup>
    );
};
