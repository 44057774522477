import { useEffect } from 'react';
import { useAccountId } from '../../../hooks';
import { ComingSoonPage } from '../../ComingSoonPage';

export const SubscribePropensityEnginePage = () => {
    const accountId = useAccountId();

    useEffect(() => {
        // const type = PropensityEngineTypes.subscribe;
        // dispatch(actions.request({ accountId, type, page: 0, searchQuery: '' }));
    }, [accountId]);

    // return <PropensityEnginePage propensityEngineType={PropensityEngineTypes.subscribe} />;
    return <ComingSoonPage />;
};
