import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ReactComponent as LookalikeImage } from '../../../assets/images/empty/empty-lookalike.svg';
import { DetailsEmpty, DetailsInfoOverview, DetailsInfoOverviewDataType } from '../../../components/details-entity';
import { Error404 } from '../../../components/errors';
import { CustomTabContainer } from '../../../components/tabs/custom-tab/CustomTabContainer';
import { Loader, toast } from '../../../components/ui';
import { LOOKALIKE_ENGINE_PATH } from '../../../constants';
import { useAccountId, useBiskoIntegrationError, useDetailsError, useToggleVisibility } from '../../../hooks';
import { LookalikeEngineDetailsModel, LookalikeEngineDetailsResponseModel } from '../../../models';
import { selectors as biskoSegmentsSelectors } from '../../../redux/thunk/bisko/biskoSegmentsThunk';
import axios from '../../../services/axios';
import { formatDateAndTime, queryParams } from '../../../utils';
import { LookalikeMetricsTab } from './details-tabs/metrics/LookalikeMetricsTab';
import { LookalikeRunsTab } from './details-tabs/runs/LookalikeRunsTab';
import { ToggleActionButton, TableActionRow } from '../../../components/tables';
import { ConfirmationPopupModal } from '../../../components/modals';

enum LookalikeEngineTabs {
    metrics = 'metrics',
    runs = 'runs',
}

export const LookalikeEngineDetailsPage = () => {
    /**
     * Hooks
     */
    const params: { id: string } = useParams();
    const { detailsError, handleError, resetError } = useDetailsError();
    const { t } = useTranslation();
    const accountId = useAccountId();
    const history = useHistory();

    useBiskoIntegrationError({
        selectors: biskoSegmentsSelectors,
        path: LOOKALIKE_ENGINE_PATH,
    });

    /**
     * Local State
     */
    const [details, setDetails] = useState<LookalikeEngineDetailsModel | null>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const [defaultActiveTab, setDefaultActiveTab] = useState<string>(LookalikeEngineTabs.metrics)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    useEffect(() => {
        if (params?.id) {
            fetchDetails();
        }
    }, [params?.id, accountId]);

    const fetchDetails = async () => {
        try {
            resetError();
            setDetailsLoading(true);
            const searchParams = new URLSearchParams({ accountId: accountId?.toString(), id: params?.id ?? '' });
            const response: LookalikeEngineDetailsResponseModel = await axios.get(`/lookalikeEngine?${searchParams}`);
            setDetails(response.model);
        } catch (err) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onClickEdit = () => {
        if (details) {
            history.push(`${LOOKALIKE_ENGINE_PATH}/edit-lookalike/${details.id}${queryParams.formatForNavigation()}`);
        }
    };

    const onClickTerminate = async () => {
        try {
            await axios.post('/lookalikeEngine/terminate', { id: params?.id, accountId });
            fetchDetails()
            toast.success(
                t('@lookalikeEngine.started.terminating', {
                    defaultValue: 'Lookalike Engine started terminating!',
                })
            );
        } catch (err) {
            setDefaultActiveTab(LookalikeEngineTabs.runs)
            fetchDetails()
        }
    };

    const onClickRun = async () => {
        try {
            await axios.post('/lookalikeEngine/run', { id: params?.id, accountId });
            fetchDetails()
            toast.success(t('@lookalikeEngine.started.running', { defaultValue: 'Lookalike Engine started running!' }));
        } catch (err) {
            setDefaultActiveTab(LookalikeEngineTabs.runs)
            fetchDetails()
        }
    };

    const onClickDelete = () => {
        onToggleVisibility(true)
    }

    const handleDelete = async () => {
        try {
            setDeleteLoading(true)
            await axios.delete(`lookalikeEngine?id=${params?.id}&accountId=${accountId}`)
            toast.success('Lookalike Engine has been deleted!');
            history.push(`${LOOKALIKE_ENGINE_PATH}${queryParams.formatForNavigation()}`)
        } catch (err) {
            setDeleteLoading(false)
            onToggleVisibility(false)
            handleError(err)
        }
    }

    const actions = [
        {
            action: 'edit',
            onClick: onClickEdit
        },
        (
            details?.runs[0]?.status === 'Running'
                ? { action: 'Terminate', onClick: onClickTerminate, displayName: 'Terminate'}
                : { action: 'run', onClick: onClickRun, displayName: 'Run'}
        ),
        {
            action: 'delete',
            onClick: onClickDelete
        }
    ]

    const commonDetailsInfoData: DetailsInfoOverviewDataType = details
        ? {
              '@common.title': {
                  value: details?.title,
              },
              '@createdAt': { value: formatDateAndTime(details?.createdAt ?? '') },
              '@createdBy': { value: details?.createdBy ?? '' },
              '@common.last-modified': { value: formatDateAndTime(details?.lastModified ?? '') },
              '@modifiedBy': { value: details?.modifiedBy ?? '' },
          }
        : {};

    if (detailsError.status === 404) {
        return <Error404 model="lookalikeEngine" />;
    }

    return (
        <div className="flex flex-col flex-1">
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    title={t(`@confirm-delete.lookalike-engine`, { defaultValue: 'Lookalike Engine delete confirmation' })}
                    isVisible={isModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteLoading}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    description={'Are you sure you want to delete this item'}
                />
            )}
            <DetailsInfoOverview
                data={commonDetailsInfoData}
                loading={detailsLoading}
                titleIconComponent={<LookalikeImage className="h-7 w-7 mr-4 ml-2" />}
                title={t('@lookalikeEngine.overview', { defaultValue: 'Lookalike Engine overview' })}
                titleRightComponent={
                    <ToggleActionButton>
                        <>
                            {actions.map((_action, i) => {
                                return (
                                    <TableActionRow
                                        key={i}
                                        onClickAction={_action.onClick || (() => {})}
                                        {..._action}
                                        row={i}
                                    />
                                );
                            })}
                        </>
                    </ToggleActionButton>
                }
            />

            {detailsLoading ? (
                <div className="w-full items-center justify-center flex flex-1 p-10 mt-6">
                    <Loader size={40} />
                </div>
            ) : !details ? (
                <DetailsEmpty onRefresh={fetchDetails} />
            ) : (
                <div className="flex flex-col flex-1">
                    <CustomTabContainer
                        scrollToTopTabs={[LookalikeEngineTabs.metrics]}
                        animationEnabled={false}
                        className="mt-6"
                        defaultActiveTab={defaultActiveTab}
                        tabs={[
                            {
                                component: <LookalikeMetricsTab lookalikeEngineId={details?.id ?? 0} />,
                                displayName: t('@lookalike.tab.metrics', { defaultValue: 'Metrics' }),
                                key: LookalikeEngineTabs.metrics,
                            },
                            {
                                component: <LookalikeRunsTab lookalikeEngineId={details?.id ?? 0} />,
                                displayName: t('@lookalike.tab.runs', { defaultValue: 'Runs' }),
                                key: LookalikeEngineTabs.runs,
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    );
};
