import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ReactComponent as Notfound } from '../../assets/images/404.svg';
import { AIHUB_BASE_PATH } from '../../constants';
import { queryParams } from '../../utils';
import { CreateButton, OutlineButton } from '../buttons';

export const RouteNotFound = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="flex flex-1 items-center flex-col justify-center h-full pb-4 px-4">
            <Notfound title="Requested route not found!" className="box-border flex max-w-full flex-1" />
            <span className="text-blueMainText text-2xl font-bold">{t('page.not.found')}</span>
            <span className="text-blueMainText mt-4 font-medium text-lg">{t('requested.url.not.found')}</span>
            <div className="flex mt:4 sm:mt-10">
                <OutlineButton
                    title={t('refresh.page')}
                    onClick={() => {
                        history.length > 2 && history.goBack();
                    }}
                    className="mr-4"
                />
                <CreateButton
                    onClick={() => {
                        history.push(AIHUB_BASE_PATH + queryParams.formatForNavigation());
                    }}
                    title={t('return.to.the.homepage')}
                />
            </div>
        </div>
    );
};
