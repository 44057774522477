import { SelectOptionType } from '../models';
import { artifactReaderTypes, artifactContentTypes, inputTypes } from './types';

export const roles = [
    { label: 'Admin', value: '1' },
    { label: 'Editor', value: '2' },
    { label: 'Viewer', value: '3' },
];

export const conditionsOperators = [
    {
        label: 'Any',
        value: 'or',
    },
    {
        label: 'All',
        value: 'and',
    },
];

export const runSchedules = [
    {
        value: '1',
        label: 'Once',
    },
    {
        label: 'On every Artifact update',
        value: '5',
    },
    {
        label: 'Hourly',
        value: '3',
        inputs: [
            {
                label: 'Every',
                type: inputTypes.dropdown,
                options: [
                    {
                        value: '1',
                        label: '1 hour',
                    },
                    {
                        value: '2',
                        label: '2 hours',
                    },
                    {
                        value: '3',
                        label: '3 hours',
                    },
                    {
                        value: '4',
                        label: '4 hours',
                    },
                    {
                        value: '6',
                        label: '6 hours',
                    },
                    {
                        value: '12',
                        label: '12 hours',
                    },
                ],
            },
        ],
    },
    {
        label: 'Daily',
        value: '4',
    },
    {
        label: 'Weekly',
        value: '6',
    },
    {
        label: 'Monthly',
        value: '7',
    },
];

export const evaluationOperators = [
    {
        label: 'Greater than',
        value: '>',
    },
    {
        label: 'Less than',
        value: '<',
    },
];

export const DATASET_READERS = {
    artifact: '1',
    dataSource: '2',
};

export const datasetReaderOptions = [
    {
        value: DATASET_READERS.artifact,
        label: 'Artifact Reader',
    },
    {
        value: DATASET_READERS.dataSource,
        label: 'Data Source Reader',
    },
];

export const artifactContentTypesOptions = [
    {
        value: 'csv',
        label: 'CSV',
    },
    {
        value: 'txt',
        label: 'Text',
    },
];

export const actionTypeOptions = [
    {
        label: 'Tabular',
        value: artifactContentTypes.tabular.toString(),
    },
    {
        label: 'Textual',
        value: artifactContentTypes.textual.toString(),
    },
];

export const artifactReadersOptions = [
    {
        value: artifactReaderTypes.uploadFile,
        label: 'Upload file',
    },
    {
        value: artifactReaderTypes.readFromDataSource,
        label: 'Read from data source',
    },
];

export const inputTypeOptions: SelectOptionType[] = Object.entries(inputTypes).map(keyValues => ({
    label: keyValues[1],
    value: keyValues[1],
}));
