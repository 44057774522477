import { HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { AuthProvider } from 'oidc-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as AppRouter, Route, Switch } from 'react-router-dom';
import { oidcConfig } from '../config/auth.config';
import { WELCOME_PATH } from '../constants';
import { NotificationListModel } from '../models';
import { WelcomePage } from '../pages/auth/WelcomePage';
import { actions as notificationsActions } from '../redux/thunk/app/notification/notificationsThunk';
import { actions as userActions } from '../redux/thunk/app/user/userThunk';
import { LocalStorageKeys, deviceLocalStorage } from '../services/deviceLocalStorage';
import { getToken, onReceiveMessageRefreshList } from '../utils';
import { AdminRoutes } from './AdminRoutes';
import { MainRoutes } from './MainRoutes';
import { Protected } from './Protected';

declare global {
    interface Window {
        bsk(...args: any[]): void;
    }
}

const Router: React.FC = () => {
    const dispatch = useDispatch();
    let notifyTimeoutId: any = null;

    useEffect(() => {
        listenNotification();
    }, []);

    const listenNotification = async () => {
        try {
            const connection = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_BASE_URL}/notificationhub`, {
                    accessTokenFactory: () => getToken(),
                } as IHttpConnectionOptions)
                .withAutomaticReconnect()
                .build();

            await connection.start();

            connection.on('ReceiveNotification', (message: string) => {
                let _message: NotificationListModel | null = JSON.parse(message)[0] || null;

                clearTimeout(notifyTimeoutId);
                notifyTimeoutId = setTimeout(() => {
                    let areMuted = !!deviceLocalStorage.getItem(LocalStorageKeys.MuteNotifications);
                    if (!areMuted) {
                        onPlaySound();
                    }
                }, 3000);

                dispatch(userActions.onReceivedNotification());

                onReceiveMessageRefreshList({
                    dispatch,
                    accountId: _message?.notification.accountId?.toString() || '',
                    entityModel: _message?.entity ?? null,
                });
                dispatch(notificationsActions.appendNewNotification(_message));
            });

            connection.onclose(err => console.log({ err }));
        } catch (err: any) {
            console.info(`SignalR ERROR`, err);
        }
    };

    const onPlaySound = () => {
        try {
            const sound = new Audio('https://gjirafatech-ai.blob.gjirafa.tech/notification.mp3');

            sound.play().catch(err => {
                console.log({ err });
            });
        } catch (err: any) {}
    };

    return (
        <AppRouter>
            {!!deviceLocalStorage.getItem(LocalStorageKeys.ApiKey) ? (
                <AuthProvider>
                    <Switch>
                        <Route exact path={WELCOME_PATH} component={WelcomePage} />
                        <Route path="/admin">
                            <AdminRoutes />
                        </Route>
                        <Route path="/">
                            <MainRoutes />
                        </Route>
                    </Switch>
                </AuthProvider>
            ) : (
                <AuthProvider {...oidcConfig}>
                    <Protected>
                        <Switch>
                            <Route exact path={WELCOME_PATH} component={WelcomePage} />
                            <Route path="/admin">
                                <AdminRoutes />
                            </Route>
                            <Route path="/">
                                <MainRoutes />
                            </Route>
                        </Switch>
                    </Protected>
                </AuthProvider>
            )}
        </AppRouter>
    );
};

export default Router;
