import { TableStyles } from 'react-data-table-component';
import { customTableStyles } from '../../../components/tables/styles/styles';

export const hoverRowStyle = {
    '&:hover': {
        backgroundColor: '#F6F7F9 !important',
        // color: '#1D79F2',
        cursor: 'pointer',
        span: {
            color: '#1D79F2',
        },
    },
};

export const storageTableCustomStyle: TableStyles = {
    ...customTableStyles,
    rows: {
        ...customTableStyles.rows,
        style: {
            ...customTableStyles.rows?.style,
            ...hoverRowStyle,
        },
    },
};

export const storageTableStyle: any = {
    minWidth: '100% !important',
    maxWidth: 'auto !important',
    width: 'auto !important',
    overflowY: 'visible',
};
