import { format, subDays } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { AnimationComponent } from '../../../../../components/animation';
import { StatisticsOverview } from '../../../../../components/common';
import { SelectField } from '../../../../../components/inputs';
import { useAccountId } from '../../../../../hooks';
import { AutoRefreshType, Nullable, RunnableEntityMetricsResponseModel, SelectOptionType } from '../../../../../models';
import {
    RecommendationEngineMetricsModel,
    RecommendationEngineMetricsResponseModel,
} from '../../../../../models/entity-models/api/recommendation-engine/recommendation-engine-metrics';
import axios from '../../../../../services/axios';
import { getLastItem } from '../../../../../utils';
import { autoRefreshValues, filterDaysOptions } from './RecommendationMetricsTab.constants';
import {
    MetricsStateType,
    RecommendationMetricsTabProps,
    StatisticMetricsStateType,
} from './RecommendationMetricsTab.types';

export const RecommendationMetricsTab: React.FC<RecommendationMetricsTabProps> = ({ recommendationEngineId }) => {
    const accountId = useAccountId();

    const [metrics, setMetrics] = useState<RecommendationEngineMetricsModel | null>(null);
    const [metricsLoading, setMetricsLoading] = useState(false);
    const [statisticMetrics, setStatisticMetrics] = useState<StatisticMetricsStateType>({
        data: null,
        loading: false,
    });
    const [lastDays, setLastDays] = useState<Nullable<SelectOptionType>>(getLastItem(filterDaysOptions));
    const [autoRefresh, setAutoRefresh] = useState<Nullable<AutoRefreshType>>(getLastItem(autoRefreshValues));

    const embedId = 'da4da4d4-cc20-48b6-af49-c64203ca7886';

    const calculateLastDays = (selectedDays: Nullable<SelectOptionType>) => {
        const daysNumber = parseInt((selectedDays?.value || lastDays?.value) as string);
        const today = new Date();
        const result: Array<string> = [];

        for (let i = 0; i < daysNumber; i++) {
            const date = subDays(today, i);
            result.push(format(date, 'yyyy-MM-dd'));
        }
        const startDate = result[result.length - 1];
        const endDate = result[0];

        if (selectedDays) {
            (window as any).visualizer.embeds[embedId].updateDate(startDate, endDate);
        } else {
            renderEmbed(startDate, endDate);
        }
    };

    const updateEmbedAutoRefresh = (item: Nullable<AutoRefreshType>) => {
        (window as any).visualizer.embeds[embedId].updateAutoRefresh(parseInt(item?.value as string));
    };

    const changeAutoRefresh = (item: Nullable<AutoRefreshType>) => {
        setAutoRefresh(item);
        updateEmbedAutoRefresh(item);
    };

    const onChangeLastDays = (item: Nullable<SelectOptionType>) => {
        setLastDays(item);
        calculateLastDays(item);
    };

    const renderEmbed = (startDate: string, endDate: string) => {
        const embedConfig = {
            targetId: 'embedContainer',
            hashEmbedUrl: embedId,
            organizationId: 'aihub',
            allowFullscreen: true,
            autoRefreshMinutes: 0,
            width: '100%',
            height: '600px',
            config: {
                filters: [
                    {
                        column: 'EngineId',
                        operator: 'equal',
                        values: [`${recommendationEngineId}`],
                    },
                ],
                logicalOperator: 'All',
                startDate,
                dateRange: 8,
                endDate,
                properties: [],
            },
        };

        (window as any)?.visualizer.BVConfigs.push(embedConfig);
        (window as any)?.visualizer.initiateEmbed();
    };

    useEffect(() => {
        fetchAllMetrics();
    }, [accountId, lastDays, recommendationEngineId]);

    useEffect(() => {
        calculateLastDays(null);
    }, []);

    useEffect(() => {
        fetchStatisticMetrics();
    }, [recommendationEngineId, accountId]);

    const fetchStatisticMetrics = async () => {
        try {
            setStatisticMetrics(p => ({ ...p, loading: true }));
            const response: RunnableEntityMetricsResponseModel = await axios.get(
                `/recommendationEngine/metrics?accountId=${accountId}&&id=${recommendationEngineId}`
            );
            setStatisticMetrics({
                data: response.model,
                loading: false,
            });
        } catch (err) {
            setStatisticMetrics(p => ({ ...p, loading: false }));
        }
    };

    const fetchAllMetrics = async () => {
        try {
            setMetricsLoading(true);
            console.count('fetch metrics');
            const query = {
                days: lastDays?.value ?? '30',
                engineId: recommendationEngineId?.toString(),
                accountId: accountId?.toString(),
            };
            const response: RecommendationEngineMetricsResponseModel = await axios.get(
                `/analytics/recommendation?${new URLSearchParams(query)}`
            );
            setMetrics(response.model);
        } catch (err) {
        } finally {
            setMetricsLoading(false);
        }
    };

    const { totalRequests }: MetricsStateType = useMemo(() => {
        if (metrics !== null && !metricsLoading) {
            let dailyRequests: Record<string, number> = {};

            metrics.dailyRequests.forEach(item => (dailyRequests[item.partitionDate] = item.total));

            return {
                totalRequests: metrics.total,
                browserRequests: metrics.browserRequests.slice(0, 5).map(x => [x.browser, x.total]),
                deviceRequests: metrics.deviceRequests.slice(0, 5).map(x => [x.deviceType, x.total]),
                geoRequests: metrics.countryRequests.map(x => [
                    { v: x.countryCode ?? '', f: x.countryName ?? x.countryCode ?? '' },
                    x.total,
                ]),
                dailyRequests,
            };
        }

        return {
            browserRequests: [],
            dailyRequests: {},
            deviceRequests: [],
            geoRequests: [],
            totalRequests: 0,
        };
    }, [metrics, metricsLoading]);

    return (
        <div className="w-full h-auto">
            <AnimationComponent show type="fade-bottom" transform="transform-gpu" delay={0} duration={200}>
                <StatisticsOverview
                    className="card-shadow"
                    loading={statisticMetrics.loading}
                    data={[
                        {
                            title: 'Total Training Hours',
                            value: statisticMetrics.data?.totalTrainingHours.toFixed(2) + 'h' ?? '',
                        },
                        { title: 'Total Number of Requests', value: totalRequests.toLocaleString('en-US') },
                        {
                            title: 'Succeeded Executions',
                            value: `${statisticMetrics.data?.succeededExecutions ?? ''}`,
                            valueStyle: { color: '#36B37E' },
                        },
                        {
                            title: 'Failed Executions',
                            value: `${statisticMetrics.data?.failedExecutions ?? ''}`,
                            valueStyle: { color: '#E34850' },
                        },
                    ]}
                />
            </AnimationComponent>
            <div className="flex justify-between items-end pt-4 pb-1 mb-2">
                <div className="flex pb-2 items-center">
                    <label className="text-secondaryText font-medium text-sm">Last</label>
                    <SelectField
                        hideLabel
                        placeholder="30"
                        className="ml-2"
                        style={{ width: 78 }}
                        options={filterDaysOptions}
                        value={lastDays}
                        onChange={onChangeLastDays}
                        noOptionsMessage={() => '...'}
                    />
                    <label className="text-blueMainText font-medium text-sm ml-2">Days</label>
                </div>
                <SelectField
                    hideLabel
                    placeholder="30"
                    className="ml-2"
                    style={{ width: 200 }}
                    options={autoRefreshValues}
                    value={autoRefresh}
                    onChange={changeAutoRefresh}
                    noOptionsMessage={() => '...'}
                />
            </div>
            <div id="embedContainer"></div>
        </div>
    );
};
