import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
    AccountDetailsResponseModel,
    AccountListModel,
    AccountListResponseModel,
    UserDetailsResponseModel,
} from '../../models';
import { selectors as accountDetailsSelectors } from '../../redux/thunk/app/account/accountDetailsThunk';
import {
    actions as accountsActions,
    selectors as accountsSelectors,
} from '../../redux/thunk/app/account/accountsThunk';
import { actionTypes as userActionTypes, selectors as userSelectors } from '../../redux/thunk/app/user/userThunk';
import axios from '../../services/axios';
import { actionCreator } from '../../utils';
import { SideModal } from '../SideModal';
import { InfiniteScroll } from '../common';
import { InputField } from '../inputs';
import { Loader } from '../ui';
import { AccountItem } from './AccountItem';
import CreateAccount from './CreateAccount';

export interface SwitchAccProps {
    hide: () => any;
    isVisible: boolean;
}

const SEARCH_INPUT_NAME = 'searchAccount';

const SwitchAcc: React.FC<SwitchAccProps> = props => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { handleSubmit, setValue, control } = useForm({
        shouldUnregister: false,
    });

    /**
     * Selectors
     */
    const accountsResponse: AccountListResponseModel = useSelector(accountsSelectors.getResponse);
    const accountsInitialLoading = useSelector(accountsSelectors.getInitialLoading);
    const accountsLoading = useSelector(accountsSelectors.getIsLoading);
    const { model: currentAccountModel }: AccountDetailsResponseModel = useSelector(
        accountDetailsSelectors.getResponse
    );
    const currentAccountLoading = useSelector(accountDetailsSelectors.getIsLoading);
    const { model: userModel }: UserDetailsResponseModel = useSelector(userSelectors.getResponse);

    const accounts = accountsResponse.models
        ? accountsResponse.models.filter(
              (el: AccountListModel) => el.id !== currentAccountModel.id && el.id !== userModel?.defaultAccount?.id
          )
        : [];

    /**
     * Local state
     */
    const [loadingDefaultAccount, setLoadingDefaultAccount] = useState(false);

    const requestAccounts = (query?: any, reset?: boolean) => {
        dispatch(accountsActions.request({ ...query }, reset));
    };

    useEffect(() => {
        !accounts.length && requestAccounts({ page: 0 });

        if (accountsResponse.query?.searchQuery) {
            requestAccounts({ page: 0, searchQuery: '' });
            setValue(SEARCH_INPUT_NAME, '');
        }
    }, []);

    const onSubmitSearch = data => requestAccounts({ searchQuery: data[SEARCH_INPUT_NAME], page: 0 });

    const onSwitchAccount = selectedAccountId => {
        history.push(`/?accountId=${selectedAccountId}`);
    };

    const setDefaultAccount = async id => {
        try {
            setLoadingDefaultAccount(true);
            const response: UserDetailsResponseModel = await axios.patch('/user', { accountId: id });
            dispatch(actionCreator(userActionTypes.SUCCESS, response));
        } catch (err: any) {
        } finally {
            setLoadingDefaultAccount(false);
        }
    };

    const loadMoreAccounts = () => {
        if (!accountsLoading) {
            let page = accountsResponse.currentPage || 0;
            requestAccounts({ page: page + 1 });
        }
    };

    const renderAccounts = () => {
        if (accountsInitialLoading) {
            return (
                <div className="w-full p-10 flex items-center justify-center">
                    <Loader />
                </div>
            );
        }

        return (
            <>
                {userModel?.defaultAccount && currentAccountModel.id !== userModel?.defaultAccount?.id && (
                    <AccountItem
                        isLoading={false}
                        account={userModel.defaultAccount?.name}
                        isCurrentAccount={false}
                        isDefaultAccount
                        imageUrl={userModel?.defaultAccount?.imageUrl}
                        onClick={() => onSwitchAccount(userModel?.defaultAccountId)}
                    />
                )}
                {accounts.map((acc: AccountListModel, i: number) => (
                    <AccountItem
                        key={acc.id}
                        account={acc.name}
                        isCurrentAccount={false}
                        isDefaultAccount={acc.id === userModel?.defaultAccountId}
                        imageUrl={acc.imageUrl}
                        onClick={() => onSwitchAccount(acc.id)}
                        style={i === accounts.length - 1 ? { borderBottom: 'none' } : {}}
                    />
                ))}
            </>
        );
    };

    return (
        <SideModal
            isVisible={props.isVisible}
            onHide={props.hide}
            title={t('@components.account.switch-account')}
            buttonText={t('@components.account.switch')}
            formId="switch-acc"
            showCreateButton={false}
            showFooter={false}
        >
            <div>
                <div className="flex p-4 justify-between w-full border-b border-grey200">
                    <form className="flex flex-1" onSubmit={handleSubmit(onSubmitSearch)}>
                        <Controller
                            name={SEARCH_INPUT_NAME}
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                                <InputField
                                    className="w-auto flex-1"
                                    placeholder={t('@components.account.search-accounts')}
                                    hideLabel
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </form>
                    <CreateAccount />
                </div>
                <h4 className="text-greyBlack100 font-medium mt-4 pl-4 text-sm">
                    {t('@components.account.current-account')}
                </h4>
                {currentAccountModel && (
                    <AccountItem
                        isLoading={currentAccountLoading}
                        account={currentAccountModel.name}
                        setDefaultLoading={loadingDefaultAccount}
                        isCurrentAccount
                        isDefaultAccount={currentAccountModel.id === userModel?.defaultAccountId}
                        imageUrl={currentAccountModel.imageUrl}
                        onClickSetDefault={() => setDefaultAccount(currentAccountModel.id)}
                    />
                )}
                <h4 className="text-greyBlack100 text-sm font-medium mt-4 pl-4 mb-2">
                    {t('@components.account.other-accounts')}
                </h4>
                <InfiniteScroll
                    className={`flex h-auto box-border flex-col w-full pb-4`}
                    hasMore={!!accountsResponse.hasNextPage}
                    loadMore={loadMoreAccounts}
                    currentPage={accountsResponse.currentPage}
                    isInitialLoading={accountsInitialLoading}
                    loading={accountsLoading}
                    showMoreClassName="mx-4 my-4 w-auto"
                    displayShowMore
                >
                    {renderAccounts()}
                </InfiniteScroll>
            </div>
        </SideModal>
    );
};

export default SwitchAcc;
