import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
    DetailsEmpty,
    DetailsInfoOverview,
    DetailsInfoOverviewDataType,
    DetailsLoader,
} from '../../../../components/details-entity';
import { Error404 } from '../../../../components/errors';
import { useAccountId, useDetailsError, useToggleVisibility } from '../../../../hooks';
import { Nullable, PipelineJobDetailsModel, PipelineJobDetailsResponseModel } from '../../../../models';
import { actions as pipelineJobRunsActions } from '../../../../redux/thunk/app/pipeline-job/pipelineJobRunsThunk';
import axios from '../../../../services/axios';
import { formatDateAndTime, queryParams } from '../../../../utils';
import { useDispatch } from 'react-redux';
import { PIPELINES_PATH } from '../../../../constants';
import { ReactComponent as RecommendationIcon } from '../../../../assets/images/empty/empty-recommendation.svg';
import { CustomTabContainer } from '../../../../components/tabs/custom-tab/CustomTabContainer';
import Icon from 'react-syntax-highlighter/dist/esm/languages/prism/icon';
import { Tooltip } from '../../../../components/ui';
import { PipelineJobRunsTab } from './tabs';
import { ToggleActionButton, TableActionRow } from '../../../../components/tables';
import { toast } from '../../../../components/ui';
import { ConfirmationPopupModal } from '../../../../components/modals';

enum PipelineJobTab {
    runs = 'runs',
    metrics = 'metrics',
}

export const PipelineJobDetailsPage = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const { detailsError, handleError, resetError } = useDetailsError();

    /**
     * Local State
     */
    const [details, setDetails] = useState<PipelineJobDetailsModel | null>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const entityId = parseInt(params?.id);
    const pipelineId = parseInt(params?.pipelineId);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    useEffect(() => {
        return () => {
            dispatch(pipelineJobRunsActions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        if (entityId) {
            fetchDetails(entityId);
        }
    }, [accountId, entityId]);

    const fetchDetails = async id => {
        try {
            resetError();
            setDetailsLoading(true);
            const response: PipelineJobDetailsResponseModel = await axios.get(
                `/pipeline/job?id=${id}&&accountId=${accountId}`
            );
            setDetails(response.model);
        } catch (err: any) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onClickEdit = () => {
        history.push(`${PIPELINES_PATH}/${pipelineId}/edit-pipeline-job/${entityId}${queryParams.formatForNavigation()}`);
    };

    const onClickDelete = () => {
        onToggleVisibility(true)
    }

    const handleDelete = async () => {
        try {
            setDeleteLoading(true)
            await axios.delete(`pipeline/job?id=${entityId}&accountId=${accountId}`)
            toast.success('Pipeline job has been deleted!');
            history.push(`${PIPELINES_PATH}/details/${pipelineId}${queryParams.formatForNavigation()}`);
        } catch (err) {
            setDeleteLoading(false)
            onToggleVisibility(false)
            handleError(err)
        }
    }

    const detailsInfoData = useMemo<Nullable<DetailsInfoOverviewDataType>>(() => {
        if (!details) return null;

        const { createdAt, createdBy, modifiedBy, lastModified, title, pipeline } = details;

        return {
            '@common.title': title,
            '@common.pipeline': pipeline?.title,
            '@createdAt': formatDateAndTime(createdAt),
            '@createdBy': createdBy,
            '@common.last-modified': formatDateAndTime(lastModified),
            '@modifiedBy': modifiedBy,
        };
    }, [details]);

    const { endpointStatusMessage, endpointStatus, tabsDisabled } = useMemo(() => {
        let endpointStatus: any = details?.endpointDetails?.status ?? '';

        return {
            endpointStatus,
            endpointStatusMessage: details?.endpointDetails?.message ?? details?.endpointDetails?.status ?? '',
            tabsDisabled: endpointStatus === 'Pending' || endpointStatus === 'Failed',
        };
    }, [details?.endpointDetails]);

    const onClickTerminate = async () => {
        try {
            await axios.post('/pipeline/job/terminate', { id: entityId, accountId });
            toast.success(t('@pipelineJob.started.terminating', { defaultValue: 'PipelineJob started terminating!' }));
            fetchDetails(entityId)
        } catch (err: any) {
            fetchDetails(entityId)
        }
    };

    const onClickRun = async () => {
        try {
            await axios.post('/pipeline/job/run', { id: entityId, accountId });
            toast.success(t('@pipelineJob.started.running', { defaultValue: 'PipelineJob started running!' }));
            fetchDetails(entityId)
        } catch (err: any) {
            fetchDetails(entityId)
        }
    };

    const actions = [
        {
            action: 'edit',
            onClick: onClickEdit,
        },
        (
            details?.runs[0]?.status === 'Running'
                ? { action: 'Terminate', onClick: () => onClickTerminate(), displayName: 'Terminate'}
                : { action: 'run', onClick: () => onClickRun(), displayName: 'Run'}
        ),
        {
            action: 'delete',
            onClick: onClickDelete
        }
    ]

    if (detailsError.status === 404) {
        return (
            <Error404
                model="pipelineJob"
                onClickButton={() => {
                    history.push(`${PIPELINES_PATH}/${window.location.search}`);
                }}
            />
        );
    }

    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    title={t(`@confirm-delete.pipelines/job`, { defaultValue: 'Pipeline Job delete confirmation'})}
                    isVisible={isModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteLoading}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    description={'Are you sure you want to delete this item'}
                />
            )}
            <div className="flex flex-col flex-1">
                <DetailsInfoOverview
                    data={detailsInfoData}
                    loading={detailsLoading}
                    titleIconComponent={<RecommendationIcon className="h-6 w-6 mr-4 ml-2" />}
                    titleRightComponent={
                        <ToggleActionButton>
                            <>
                                {actions.map((_action, i) => {
                                    return (
                                        <TableActionRow
                                            key={i}
                                            onClickAction={_action.onClick || (() => {})}
                                            {..._action}
                                            row={i}
                                        />
                                    );
                                })}
                            </>
                        </ToggleActionButton>
                    }
                    title={t('@pipelineJob.overview', { defaultValue: 'PipelineJob overview' })}
                />

                {detailsLoading ? (
                    <DetailsLoader />
                ) : !details ? (
                    <DetailsEmpty onRefresh={() => fetchDetails(entityId)} />
                ) : (
                    <div className="flex flex-1 flex-col">
                        <CustomTabContainer
                            className="mt-6"
                            style={{ minHeight: 500 }}
                            animationEnabled={false}
                            defaultActiveTab={PipelineJobTab.runs}
                            tabHeaderProps={{
                                rightComponent: endpointStatus ? (
                                    <div
                                        className="flex w-auto justify-end  items-center mr-6"
                                        data-for="endpointStatus"
                                        data-tip={endpointStatusMessage}
                                    >
                                        {/* RunStatus icon  */}
                                        <Icon size={28} className="cursor-pointer" name={endpointStatus} />
                                        <Tooltip id="endpointStatus" className="min-w-auto border border-mainBlue" />
                                    </div>
                                ) : undefined,
                            }}
                            tabs={[
                                {
                                    component: <PipelineJobRunsTab pipelineJobId={details?.id ?? 0} />,
                                    displayName: t('@pipelineJob.tab.runs', { defaultValue: 'Runs' }),
                                    key: PipelineJobTab.runs,
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
