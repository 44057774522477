import Axios from 'axios';
import { toast } from '../components/ui';
import { getAccountIdFromQuery, getToken, showServerErrors } from '../utils';
import { authorizeConfig, handle401StatusCodeError, shouldDisplayError } from './axios-helpers';

const { REACT_APP_BASE_URL } = process.env;
const axios = Axios.create({
    baseURL: REACT_APP_BASE_URL,
});
axios.defaults.baseURL = `${REACT_APP_BASE_URL}/api`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Request interceptor for API calls
axios.interceptors.request.use(async config => {
    authorizeConfig(config);
    let accountId = getAccountIdFromQuery();

    if (!!accountId && !config.url?.includes('accountId=')) {
        config.params = {
            ...config.params,
            accountId,
        };
    }
    return config;
});

axios.interceptors.response.use(
    async response => response.data,
    async function (error) {
        const requestUrl = error?.config?.url;
        const access_token = getToken();

        if (error.response && error.response.status === 401) {
            return handle401StatusCodeError(error);
        } else if (requestUrl.includes('/bridge/bisko') || requestUrl.includes('/storage/')) {
        } else {
            if (shouldDisplayError(error.config)) {
                toast.dismiss();
                access_token && showServerErrors(error);
            }
        }
        return Promise.reject(error);
    }
);

export default axios;
