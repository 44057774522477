import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PageAnimationContainer } from '../../components/animation/PageAnimationContainer';
import { CustomTabContainer } from '../../components/tabs/custom-tab/CustomTabContainer';
import { billingPageContext } from '../../contexts';
import { useAccountId } from '../../hooks';
import { AccountDetailsResponseModel } from '../../models';
import { selectors as accountDetailsSelectors } from '../../redux/thunk/app/account/accountDetailsThunk';
import { actions as paymentActions } from '../../redux/thunk/app/payment/paymentMethodsThunk';
import { CardSettingsTab, InvoicesTab, OverviewTab } from './billing-tabs';

enum billingTabs {
    overview = 'Overview',
    invoices = 'Invoices',
    paymentSettings = 'Payment settings',
}

export const BillingPage = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * Selectors
     */
    const accountDetailsResponse: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const locationState: any = history.location.state;

    const [currentCost, setCurrentCost] = useState('0.00');

    useEffect(() => {
        if (
            accountDetailsResponse &&
            accountDetailsResponse.model.id &&
            accountDetailsResponse.model.stripeCustomerId
        ) {
            dispatch(paymentActions.request(accountId));
        } else {
            dispatch(
                paymentActions.updateResponse({
                    success: true,
                    message: '',
                    model: { defaultPaymentMethodId: '', paymentMethods: [] },
                })
            );
        }
    }, [accountDetailsResponse]);

    return (
        <billingPageContext.Provider value={{ currentCost, setCurrentCost }}>
            <PageAnimationContainer>
                <div className="flex flex-col flex-1">
                    <CustomTabContainer
                        defaultActiveTab={locationState?.tab || billingTabs.overview}
                        tabs={[
                            {
                                component: <OverviewTab />,
                                displayName: t('billing.overview.tab'),
                                key: billingTabs.overview,
                            },
                            {
                                component: <InvoicesTab />,
                                displayName: t('billing.invoices.tab'),
                                key: billingTabs.invoices,
                            },
                            {
                                component: <CardSettingsTab />,
                                displayName: t('billing.settings.tab'),
                                key: billingTabs.paymentSettings,
                            },
                        ]}
                    />
                </div>
            </PageAnimationContainer>
        </billingPageContext.Provider>
    );
};
