import React, { createRef, CSSProperties } from 'react';
import { OutlineButtonProps } from '.';
import { OutlineButton } from './OutlineButton';

export interface UploadButtonProps extends Omit<OutlineButtonProps, 'onClick'> {
    maxFiles?: number;
    accept?: string;
    multiple?: boolean;
    handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => any;
    title?: string;
    inputId?: string;
    disabled?: boolean;
    htmlType?: string;
    className?: string;
    style?: CSSProperties;
    icon?: any;
    childrenContainerClassName?: string;
    childrenContainerStyle?: CSSProperties;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
    inputId,
    title,
    accept,
    multiple,
    handleUpload,
    disabled,
    htmlType = 'button',
    style,
    icon,
    className,
    children,
    childrenContainerStyle,
    childrenContainerClassName,
    ...props
}) => {
    const inputRef: React.RefObject<HTMLInputElement> = createRef();

    const onClickUpload = () => inputRef?.current?.click();

    return (
        <>
            {children ? (
                <div
                    onClick={onClickUpload}
                    className={`cursor-pointer ${childrenContainerClassName}`}
                    style={childrenContainerStyle}
                >
                    {children}
                </div>
            ) : (
                <OutlineButton
                    icon={icon}
                    htmlType={htmlType}
                    onClick={onClickUpload}
                    title={title}
                    style={style}
                    className={className}
                    disabled={disabled}
                    {...props}
                />
            )}
            <input
                id={inputId}
                ref={inputRef}
                accept={accept}
                style={{ display: 'none', width: 0, height: 0 }}
                type="file"
                multiple={multiple}
                disabled={disabled}
                onClick={event => {
                    event.currentTarget.value = '';
                }}
                onChange={handleUpload}
            />
        </>
    );
};
