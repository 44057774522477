import { forwardRef, useState, useImperativeHandle } from 'react';

/**
 * Components
 */
import { InputField } from '../../../../components/inputs';
import { Icon, IconTypes } from '../../../../components/common';
import { OutlineButton } from '../../../../components/buttons';
export interface DataTypeFormProps {
    className?: string;
    isEditing?: boolean;
}
export interface SingleDataTypeFormType {
    name: string;
    id: number;
    _id: number;
}

const initialDataType: SingleDataTypeFormType = {
    name: '',
    id: 0,
    _id: 0,
};

type DataTypeRef = {
    getDataTypes: () => SingleDataTypeFormType[];
    setDataTypes: (items: SingleDataTypeFormType[]) => any;
};

export const DataTypeForm: React.ForwardRefRenderFunction<DataTypeRef, DataTypeFormProps> = ({ className }, ref) => {
    let [items, setItems] = useState<SingleDataTypeFormType[]>([]);

    useImperativeHandle(ref, () => ({
        getDataTypes: () => {
            return items;
        },
        setDataTypes: (items: SingleDataTypeFormType[]) => {
            setItems(items);
        },
    }));

    const onAddNew = () => {
        setItems(prev => {
            let length = prev.length;
            return [
                ...prev,
                {
                    ...initialDataType,
                    _id: length > 0 ? prev[length - 1]._id + 1 : 1,
                },
            ];
        });
    };

    const onDelete = dataTypeId => {
        setItems(prev => prev.filter(x => x._id !== dataTypeId));
    };

    const onChangeInputs = (dataTypeId, key, value) => {
        setItems(prev =>
            prev.map(x => {
                if (x._id === dataTypeId) {
                    return {
                        ...x,
                        [key]: value,
                    };
                }
                return x;
            })
        );
    };

    const renderOutputs = () => {
        let commonProps = {
            style: { width: 240 },
            placeHolder: 'Write Here...',
        };

        return items.map((dataType, i) => {
            let id = dataType._id;

            return (
                <div
                    key={id}
                    className={`flex flex-row w-full justify-start items-end bg-white p-4 ${i > 0 && 'mt-4'}`}
                >
                    <InputField
                        value={dataType.name}
                        label="Name"
                        onChange={e => onChangeInputs(id, 'name', e.target.value)}
                        {...commonProps}
                        className="mr-2"
                    />
                    <div className="flex justify-start items-center h-10">
                        <Icon
                            name={IconTypes.delete}
                            size={25}
                            className="cursor-pointer ml-4"
                            onClick={() => onDelete(id)}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={`flex flex-col w-full h-auto p-4 ${className}`}>
            {renderOutputs()}
            <div className="flex w-full justify-center items-center mt-4">
                <span className="text-secondaryText font-medium text-base mr-4">total : {items.length}</span>
                <OutlineButton onClick={onAddNew} title="+ Add New Data Type" />
            </div>
        </div>
    );
};

export default forwardRef(DataTypeForm);
