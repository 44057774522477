export const codeDocumentation = {
    request: `function getRecommendations() {
    let body = {
        userId: localStorage.getItem("bisko-sid"),
        domain: window.location.host,
        currentId: "", // put content id here,
        size:12
    };
    
    fetch("https://isport-recommendation.gjirafa.tech/recommend", {
        method: "POST",
        headers: {
          "ApiKey": "cqzW6U6T3YanqWCg",
          "Content-Type": "application/json",
        },
        body,
    })
    .then(response => response.json())
    .then(results => {
        console.log("results",results);
    });
}`,
    result: `{ \n  results:[\n \t   { itemId:"string", title:"string", url:"string"},\n \t   { itemId:"string", title:"string", url:"string"} \n   ]  \n}`,
};

export const recommendationApiKey = 'cqzW6U6T3YanqWCg';

export enum RecommendationEngineTypes {
    article = 'article',
    product = 'product',
    video = 'video',
    classifiedAd = 'classified-ad',
}
