import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router';
import { Button, CreateButton, OutlineButton } from '../../../components/buttons';

export interface AdminFooterButtons {
    submitTitle: string;
    onClickCancel?: (e?: any) => any;
    onClickSave?: (e?: any) => any;
    className?: string;
    style?: CSSProperties;
}
export const AdminFooterButtons: React.FC<AdminFooterButtons> = ({
    submitTitle,
    onClickCancel,
    onClickSave,
    className,
    style,
}) => {
    const history = useHistory();

    return (
        <div className={`flex flex-row justify-center ${className}`} style={style}>
            <Button
                onClick={() => (onClickCancel && onClickCancel()) || history.goBack()}
                title="cancel"
                className="mr-4 !text-greyBlack300"
            />
            <CreateButton title={submitTitle} type="submit" />
            <OutlineButton
                onClick={onClickSave ? onClickSave : () => {}}
                className="ml-10"
                type="button"
                title="Save"
            />
        </div>
    );
};
