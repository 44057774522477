import { RecommendationEngineTypes } from '../constants';

export const formatRecommendationType = (type: RecommendationEngineTypes) => {
    const { article, classifiedAd, video, product } = RecommendationEngineTypes;
    switch (type) {
        case article:
            return 'Article';
        case classifiedAd:
            return 'Classified Ad';
        case product:
            return 'Product';
        case video:
            return 'Video';
        default:
            return '';
    }
};

export const showTimeDecay = (type: RecommendationEngineTypes) => {
    const { article, classifiedAd, video } = RecommendationEngineTypes;
    const supportedTypes = [article, classifiedAd, video];

    return supportedTypes.includes(type);
};
