import { useSelector } from 'react-redux';
import { selectors } from '../../../redux/thunk/app/storage/uploadObjectsThunk';
import { Icon, IconTypes } from '../../common';
import { Dropdown } from '../../dropdown';
import { UploadingObjectsDialog } from '../../UploadingObjectsDialog';
import { CounterDisplay } from './CounterDisplay';

export const ObjectsUploadingDialogHeader = () => {
    const uploadObjects = useSelector(selectors.getObjectsUploading);
    const isDialogHidden = useSelector(selectors.getIsDialogHidden);

    if (isDialogHidden && uploadObjects.length > 0) {
        return (
            <Dropdown
                wrapperClassName="z-40 mr-2"
                withArrow={false}
                triggerProps={{
                    children: (
                        <>
                            <div className="h-10 w-10 flex justify-center items-center rounded-full bg-greyWhite200 hover:bg-grey300">
                                <Icon name={IconTypes['upload-blue']} />
                                <CounterDisplay number={uploadObjects?.length} />
                            </div>
                        </>
                    ),
                }}
            >
                <UploadingObjectsDialog
                    className="mt-2"
                    isFromHeader
                    style={{ position: 'unset', zIndex: 0, margin: 0 }}
                />
            </Dropdown>
        );
    }
    return null;
};
