import { actionCreator } from '../../../utils';
import { ExternalSourceTypeListResponseModel } from '../../../models';
import { InitialStateType } from '../../../models';
import { RootState } from '../rootReducer';
import { Dispatch } from 'redux';
import axios from '../../../services/axios';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/externalSourceTypesThunk/REQUEST',
    SUCCESS: '@app/externalSourceTypesThunk/SUCCESS',
    FAILURE: '@app/externalSourceTypesThunk/FAILURE',
};

/**
 * Initial state
 */

const initialState: InitialStateType<ExternalSourceTypeListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        message: '',
        success: false,
        models: [],
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.externalSourceTypes.isLoading,
    getResponse: (state: RootState) => state.app.externalSourceTypes.response,
    getErrorMessage: (state: RootState) => state.app.externalSourceTypes.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (state: InitialStateType<ExternalSourceTypeListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (props?: { endpoint?: string }) {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const response: ExternalSourceTypeListResponseModel = await axios.get(
                    `${props?.endpoint ? props.endpoint : '/externalSourceType/list'}`
                );

                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
