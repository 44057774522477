import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CommonTableProps } from '.';
/**
 * constants - hooks - models
 */
import {
    artifactContentTypeSupportedFile,
    artifactSupportedFiles as supportedFiles,
    breakpoints,
    statusOptionTypes,
    supportedArtifactContentTypes,
} from '../../constants';
import { useAccountId, useCurrentWidth } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import {
    ArtifactContentTypesListResponseModel,
    ArtifactListModel,
    ArtifactListResponseModel,
    DetailsResponseModel,
    SelectOptionType,
} from '../../models';
import {
    actions as artifactContentTypesActions,
    selectors as artifactContentTypesSelectors,
} from '../../redux/thunk/app/artifact/artifactContentTypesThunk';
import { ARTIFACTS_PATH } from '../../constants';
/**
 * Redux
 */
import { actions as artifactActions, selectors } from '../../redux/thunk/app/artifact/artifactsThunk';
import { actions as uploadArtifactActions } from '../../redux/thunk/app/artifact/uploadArtifactThunk';
import axios from '../../services/axios';
/**
 * utils - services
 */
import {
    formatArrayForSelectOptions,
    getFormattedQuery,
    getWidth,
    onDownloadFileFromUrl,
    queryParams,
    shouldUploadVersionToArtifact,
    showFiltersHeader,
} from '../../utils';
import { UploadButton } from '../buttons';
import { DropzoneCardContainer } from '../cards';
import { Icon, IconTypes, RunStatusComponent } from '../common';
import { SelectField } from '../inputs';
/**
 * Components
 */
import { CreateArtifactModal } from '../modals';
import { toast } from '../ui';
import { NoDataImagesType, TableActionRowProps } from './components';
// import { ArtifactExpandComponent } from './components/expand-row-components/ArtifactExpandComponent';
import { TableFilters } from './components/TableFilters';
import { Table } from './Table';
import { TableMobile } from './TableMobile';

export interface ArtifactsTableProps extends CommonTableProps {
    onClickCreateArtifact?: (e: any) => any;
    actions?: TableActionRowProps[];
}

export const ArtifactsTable: FC<ArtifactsTableProps> = ({
    isFromSelectPopup = false,
    onClickCreateArtifact,
    tablePopupProps,
    fetchDataOnMount = false,
    className,
    disableFilters,
    hideFilters,
    query,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });

    /**
     * Local State
     */
    const [selectedType, setTypeSelected] = useState<any>(null);
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);
    const [artifactSelectedForEdit, setArtifactSelectedForEdit] = useState<ArtifactListModel | null>(null);

    /**
     * Redux Selector
     */
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);
    const response: ArtifactListResponseModel = useSelector(selectors.getResponse);
    const artifactContentTypesResponse: ArtifactContentTypesListResponseModel = useSelector(
        artifactContentTypesSelectors.getResponse
    );
    const artifactContentTypesLoading = useSelector(artifactContentTypesSelectors.getIsLoading);

    const artifactSupportedFiles = query?.artifactContentTypeId
        ? artifactContentTypeSupportedFile[query?.artifactContentTypeId?.label?.toLocaleLowerCase()]
        : supportedFiles;

    const requestList = (query, resetQuery?: boolean) => {
        dispatch(artifactActions.request({ accountId, ...query }, resetQuery));
    };

    useEffect(() => {
        if (artifactContentTypesResponse.models.length < 1) dispatch(artifactContentTypesActions.request());
        if (fetchDataOnMount) {
            let _query = { page: 0, artifactContentTypeId: null, searchQuery: '', ...query };
            requestList(_query);
        }
    }, [accountId]);

    useEffect(() => {
        // For artifactContentTypeId
        if (response.query?.artifactContentTypeId) {
            setTypeSelected(response.query.artifactContentTypeId);
        } else {
            setTypeSelected(null);
        }

        //For status
        if (response.query?.status) {
            setStatusTypeSelected(response.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [response.query]);

    const onPageChanged = page => dispatch(artifactActions.request({ page }));

    const onShowArtifactModal = () => onToggleCreateModal(true);
    const onHideArtifactModal = () => {
        onToggleCreateModal(false);
        setArtifactSelectedForEdit(null);
    };

    const onCreateArtifact = e => {
        if (onClickCreateArtifact) {
            onClickCreateArtifact(e);
        } else {
            onShowArtifactModal();
        }
    };

    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : response?.currentPage || 0;
        requestList({ page });
    };

    const onSelectRow = (row: ArtifactListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.title,
                value: row.id.toString(),
                ...row,
            });
        }
    };

    const onClickEdit = (row: ArtifactListModel) => {
        if (row.dataSource) {
            onToggleCreateModal(true);
            setArtifactSelectedForEdit(row);
            return;
        }
    };

    const onSuccessCreated = (artifact, edited) => {
        requestList({ page: 0 });
        toast.success(
            edited ? t('@components.modals.success-edit-artifact') : t('@components.modals.success-create-artifact')
        );
        onSelectRow(artifact);
    };

    const onChangeType = (type: SelectOptionType | null) => {
        setTypeSelected(type);
        requestList({ page: 0, artifactContentTypeId: type });
    };

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestList({ page: 0, status: status });
    };

    const onClickRunArtifact = async ({ id }: ArtifactListModel) => {
        try {
            await axios.post('/artifact/run', { id, accountId });
            onRefresh();
            toast.success(t('@artifact.started.running', { defaultValue: 'Artifact has started running!' }));
        } catch (err: any) {}
    };

    const onClickTerminateArtifact = async (row: ArtifactListModel) => {
        try {
            await axios.post('/artifact/terminate', { id: row.id, accountId });
            toast.success('Artifact is terminating');
            onRefresh();
        } catch (err: any) {}
    };
    const onDownloadFile = async (row: ArtifactListModel) => {
        try {
            let query: any = {
                accountId,
                artifactId: row.id,
            };

            let { model: presignedArtifactUrl }: DetailsResponseModel<string> = await axios.get(
                `/artifact/presignedUrl?${getFormattedQuery(query)}`
            );
            onDownloadFileFromUrl(presignedArtifactUrl);
        } catch (err: any) {}
    };

    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.artifacts.no-artifacts'),
            actionMessage: `+ ${t('@pages.artifacts.create-new-artifact')}`,
            imageType: NoDataImagesType.Artifact,
            onClickAction: onCreateArtifact,
            childrenNode: isFromSelectPopup ? null : (
                <span className="text-mainBlue text-sm mt-0.5 flex flex-col items-center font-medium">
                    <span>or</span>
                    <span className="text-base mt-0.5">Drop files to upload them as artifacts</span>
                </span>
            ),
        }),
        []
    );

    const onClickDetails = (row) => {
        history.push(`${ARTIFACTS_PATH}/details/${row.id}${window.location.search}`);
    };


    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const includesOutputSuffix = (value: string): boolean => value.slice(value.length - ('- output').length, value.length) === '- output'

    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'details',
                displayName: t('@view.details', { defaultValue: 'View details' }),
                onClick: onClickDetails,
            },
            {
                action: 'edit',
                cell: (row: ArtifactListModel) => {
                    if (row.dataSource) {
                        return (
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                    onClickEdit(row);
                                }}
                                className={`cursor-pointer transition-colors hover:bg-mainBackground px-4 py-2 flex flex-row items-center w-auto`}
                            >
                                <Icon name={IconTypes.edit} size={18} />
                                <span className="text-sm text-blueMainText font-regular pl-4">Edit</span>
                            </div>
                        );
                    } else if (!includesOutputSuffix(row.title)) {
                        return (
                            <UploadButton
                                key={row.id}
                                inputId="uploadArtifactFromActions"
                                accept={artifactSupportedFiles}
                                multiple={false}
                                handleUpload={({ target }) => {
                                    if (!target.files || target.files.length < 1) {
                                        return;
                                    }
    
                                    if (!shouldUploadVersionToArtifact(row.url, target.files[0].name)) {
                                        toast.error(`Cannot upload this file because is not ${row.artifactContentType}!`);
                                        return;
                                    }
    
                                    onUploadFiles(target.files, { id: row.id, title: row.title });
                                    target.value = '';
                                }}
                                title={t('@common.artifact-upload-new-version')}
                                style={{
                                    outline: 'none',
                                    width: '100%',
                                    border: 'none',
                                    marginLeft: 0,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    borderRadius: 0,
                                    color: '#052d61'
                                }}
                                disableScaleAnimation
                                className="outline-none border-none hover:bg-mainBackground"
                                icon={<Icon name={IconTypes.upload} className="mr-3 to-mainBlue" size={18} />}
                            />
                        );
                    }
                },
            },
            {
                action: row => (row?.runStatus === 'Running' ? 'Terminate' : 'run'),
                shouldDisplay: (row: ArtifactListModel) => !!row.dataSource,
                onClick: async row =>
                    row?.runStatus && row?.runStatus === 'Running'
                        ? onClickTerminateArtifact(row)
                        : onClickRunArtifact(row),
                displayName: (row: ArtifactListModel) =>
                    row?.runStatus && row.runStatus === 'Running' ? 'Terminate' : 'Run',
                disable: (row: ArtifactListModel) => !row.dataSource || row?.runStatus === 'Terminating',
            },
            {
                action: 'download',
                onClick: onDownloadFile,
                disable: (row: ArtifactListModel) => !row.versionsCount,
            },
            {
                action: 'delete',
            },
        ],
        []
    );

    const onUploadFiles = (files: File[] | FileList, artifact?: { id: number; title: string }) => {
        if (!files.length) {
            return;
        }

        if (artifact) {
            dispatch(
                uploadArtifactActions.upload(
                    {
                        fileContents: files[0],
                        accountId,
                    },
                    artifact
                )
            );
            return;
        }

        for (let i = 0; i < files.length; i++) {
            dispatch(
                uploadArtifactActions.upload({
                    fileContents: files[i],
                    accountId,
                })
            );
        }
    };

    const commonTableProps = {
        requestList,
        headerButtonProps: {
            onClickCreate: onShowArtifactModal,
            createTitle: t('@pages.artifacts.create-new-artifact'),
            onRefresh,
            leftButtons: (
                <UploadButton
                    accept={artifactSupportedFiles}
                    multiple
                    handleUpload={({ target }) => {
                        if (target.files && target.files.length > 0) {
                            onUploadFiles(target.files);
                            target.value = '';
                        }
                    }}
                    title={t('@common.upload-artifact')}
                    className="mr-2"
                    icon={<Icon name={IconTypes.upload} className="mr-2 mt-1 to-mainBlue" size={18} />}
                />
            ),
            leftButtonsAfterCreate: true,
        },
        modelEndpoint: 'artifact',
        selectableRows: !isFromSelectPopup,
        isFromSelectPopup,
        showHeaderButtons: true,
        emptyDataProps,
        loading,
        response,
    };

    return (
        <DropzoneCardContainer
            onUploadFiles={files => onUploadFiles(files)}
            className={`flex flex-col flex-1 pb-4  ${className}`}
            options={{
                onDropRejected: () => {
                    toast.error(`Unsupported file type, the supported file types are : ${artifactSupportedFiles}`);
                },
                accept: artifactSupportedFiles,
            }}
        >
            {shouldRenderCreateModal && (
                <CreateArtifactModal
                    isVisible={isCreateModalVisible}
                    onHide={onHideArtifactModal}
                    isEditing={!!artifactSelectedForEdit}
                    artifact={artifactSelectedForEdit}
                    onSuccess={onSuccessCreated}
                />
            )}
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestList({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                            hideFilters={hideFilters}
                        >
                            <SelectField
                                disabled={disableFilters}
                                isClearable
                                label="Artifact type"
                                className="m-2"
                                placeholder={t('@common.choose-artifact-type')}
                                style={{ width: 240 }}
                                onChange={onChangeType}
                                options={formatArrayForSelectOptions(artifactContentTypesResponse.models, 'name', 'id')}
                                value={selectedType}
                                isLoading={artifactContentTypesLoading}
                            />
                            <SelectField
                                disabled={false}
                                isClearable
                                label="Status"
                                className="m-2"
                                placeholder={t('@common.choose-status')}
                                style={{ width: 240 }}
                                onChange={onChangeStatusType}
                                options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                                value={selectedStatusType}
                                isLoading={loading}
                            />
                        </TableFilters>
                    }
                    selectedRow={tablePopupProps && tablePopupProps.selectedItem}
                    // expandableRows
                    onRowClicked={isFromSelectPopup ? onSelectRow : onClickDetails}
                    pagination
                    showHeader={false}
                    // shouldRowExpandCallback={(row: ArtifactListModel) =>
                    //     supportedArtifactContentTypes.includes(row.artifactContentType) && !!row.versionsCount
                    // }
                    paginationProps={paginationProps}
                    // expandOnRowClicked={!isFromSelectPopup}
                    // expandableRowsComponent={ArtifactExpandComponent}
                    columns={
                        isFromSelectPopup
                            ? [
                                  {
                                      selector: row => row?.title ?? '',
                                      name: t('@common.title'),
                                      sortable: true,
                                  },
                                  {
                                      selector: row => row?.artifactContentType ?? '',
                                      name: t('@common.type'),
                                      sortable: true,
                                      grow: 0.5,
                                  },
                                  {
                                      selector: row => row?.dataSource ?? '',
                                      name: t('@common.data-source'),
                                      cell: row => (
                                          <span className="w-full flex justify-center">{row.dataSource || 'N/A'}</span>
                                      ),
                                      sortable: true,
                                  },
                                  {
                                      selector: row => row?.lastModified ?? '',
                                      name: t('@common.last-modified'),
                                      sortable: true,
                                  },
                                  {
                                      selector: row => row?.modifiedBy ?? '',
                                      name: t('@common.modified-by'),
                                      sortable: true,
                                  },
                              ]
                            : [
                                  {
                                      selector: row => row?.title ?? '',
                                      name: t('@common.title'),
                                      sortable: true,
                                      grow: 0.5,
                                      cell: row => <a>{row?.title}</a>,
                                  },
                                  {
                                      selector: row => row?.artifactContentType ?? '',
                                      name: 'Type',
                                      sortable: true,
                                      grow: 0.5,
                                  },
                                  {
                                      selector: row => row?.dataSource ?? '',
                                      name: t('@common.data-source'),
                                      sortable: true,
                                      cell: row => (
                                          <span className="w-full flex justify-center">{row.dataSource || 'N/A'}</span>
                                      ),
                                      grow: 0.5,
                                  },
                                  {
                                      name: t('@common.run-status'),
                                      selector: row => row?.runStatus ?? '',
                                      sortable: true,
                                      width: '140px',
                                      cell: row => <RunStatusComponent key={row?.id} row={row} />,
                                  },
                                  {
                                      name: t('@common.last-modified'),
                                      width: '180px',
                                      selector: row => row?.lastModified ?? '',
                                      cell: row => <span>{row?.lastModified}</span>,
                                      sortable: true,
                                  },
                                  {
                                      name: t('@common.modified-by'),
                                      selector: row => row?.modifiedBy ?? '',
                                      width: '140px',
                                      cell: row => <span>{row?.modifiedBy}</span>,
                                  },
                              ]
                    }
                    actions={isFromSelectPopup ? [] : tableActions}
                />
            )}
            {/* Table displayed on mobile */}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="title"
                    initialLoading={initLoading}
                    onClickItem={onClickDetails}
                    selectedItem={tablePopupProps?.selectedItem}
                    columns={
                        isFromSelectPopup
                            ? [
                                  {
                                      selector: row => row?.title ?? '',
                                      name: t('@common.title'),
                                      sortable: true,
                                  },
                                  {
                                      selector: row => row?.lastModified ?? '',
                                      name: t('@common.last-modified'),
                                      sortable: true,
                                  },
                                  {
                                      selector: row => row?.modifiedBy ?? '',
                                      name: t('@common.modified-by'),
                                      sortable: true,
                                  },
                              ]
                            : [
                                  {
                                      selector: row => row?.title ?? '',
                                      name: t('@common.title'),
                                  },
                                  {
                                      selector: row => row?.artifactContentType ?? '',
                                      name: 'Type',
                                      sortable: true,
                                      grow: 0.5,
                                  },
                                  {
                                      selector: row => row?.dataSource ?? '',
                                      name: t('@common.data-source'),
                                      sortable: true,
                                      cell: row => (
                                          <span className="w-full flex justify-center">{row.dataSource || 'N/A'}</span>
                                      ),
                                      grow: 0.5,
                                  },
                                  {
                                      name: t('@common.run-status'),
                                      selector: row => row?.runStatus ?? '',
                                      sortable: true,
                                      width: '140px',
                                      cell: row => (
                                          <RunStatusComponent key={row?.id} row={row} showTooltip={!!row?.dataSource} />
                                      ),
                                  },
                                  {
                                      selector: row => row?.lastModified ?? '',
                                      name: t('@common.last-modified'),
                                      cell: row => <span>{row?.lastModified}</span>,
                                  },
                                  {
                                      selector: row => row?.modifiedBy ?? '',
                                      name: t('@common.modified-by'),
                                      cell: row => <span>{row?.modifiedBy}</span>,
                                  },
                              ]
                    }
                    actions={isFromSelectPopup ? [] : tableActions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </DropzoneCardContainer>
    );
};
