import { useTranslation } from 'react-i18next';
import { CreateButton } from '../../../../../components/buttons';
import { TaskTypeListModel } from '../../../../../models';
import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';

interface ITaskProps {
    title: string;
    type: TaskTypeListModel;
    onClickAdd: () => void;
}

export const Task = ({ title, type, onClickAdd }: ITaskProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center mt-2 w-full delay-75 cursor-pointer rounded transition duration-300 bg-white hover:bg-primaryBlue100 p-4 group">
            <img className="h-8 w-8 rounded" src={type.imageUrl} />
            <div className="ml-4 flex flex-col justify-center">
                <h2 className="text-sm text-blueMainText font-normal">{title}</h2>
                <p className="text-secondaryText text-xs mt-1 font-medium">{type.description}</p>
            </div>

            <CreateButton
                type="button"
                onClick={onClickAdd}
                className="mb-auto ml-auto !delay-75 opacity-0 group-hover:opacity-100 gap-2"
                title={t('@common.add')}
                icon={<GjirafaIcon name="Plus" size={18} />}
            />
        </div>
    );
};
