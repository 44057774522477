import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import type { Identifier, XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { ReactComponent as DragIcon } from '../../../../../assets/icons/drag-icon.svg';
import { Clickable } from '../../../../../components/common';

interface IPipelineTaskCardProps {
    index: number;
    _id: string;
    taskName: string;
    displayName: string;
    imageUrl: string;
    isSelected: boolean;
    onSelect: () => void;
    errorMessage?: string;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
}
export const ItemTypes = {
    CARD: 'card',
};
interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const PipelineTaskCard = ({
    _id,
    index,
    moveCard,
    displayName,
    imageUrl,
    isSelected,
    taskName,
    errorMessage,
    onSelect,
}: IPipelineTaskCardProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: ItemTypes.CARD,
        collect: monitor => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (
                (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
                (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
            )
                return;

            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => ({ id: _id, index }),
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));
    return (
        <div
            ref={ref}
            onClick={onSelect}
            data-handler-id={handlerId}
            className={`group w-full  cursor-pointer border-b border-mainBorder p-4 mt-2 flex items-center rounded transition duration-300 ${
                isSelected ? 'bg-primaryBlue100' : 'bg-white'
            } ${isDragging ? 'opacity-0' : 'opacity-100'} `}
        >
            <img className="h-8 w-8 rounded" src={imageUrl} />
            <div className="flex flex-col justify-center flex-1 ml-4">
                <h2 className="text-blueMainText text-sm font-normal">{displayName || '-'}</h2>
                <p
                    className={`transition ${
                        errorMessage ? 'text-mainError' : 'text-secondaryText'
                    } text-xs font-medium mt-0.5`}
                >
                    {errorMessage ? errorMessage : taskName}
                </p>
            </div>
            <div className="flex items-center">
                <div
                    className={`h-6 w-6 mr-2 text-white flex justify-center items-center rounded-full border transition duration-300 ${
                        isSelected
                            ? 'bg-primaryBlue500 border-white opacity-100'
                            : `bg-white border-mainGrayText opacity-0 group-hover:opacity-100`
                    } `}
                >
                    <GjirafaIcon name="Check" />
                </div>
                <Clickable
                    className={`transition-opacity duration-300 group-hover:opacity-100 ${
                        isSelected ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <DragIcon height={30} width={30} />
                </Clickable>
            </div>
        </div>
    );
};
