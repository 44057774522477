export const getAccountIdFromQuery: () => string = () => {
    let query = new URLSearchParams(window.location.search);
    return query.get('accountId') || '';
};

export const getFormattedQuery = (query: any = {}) => {
    const accountId = getAccountIdFromQuery();

    if (accountId !== undefined && accountId !== '') {
        query.accountId = accountId;
    }
    let finalQuery: any = {};

    Object.entries(query).forEach(([key, value]: [string, any]) => {
        if (finalQuery[key]) {
            return;
        }

        if (value !== undefined && value !== null) {
            if (typeof value === 'object') {
                finalQuery[key] = value?.value?.toString() || '';
            } else {
                finalQuery[key] = value?.toString() || '';
            }
        }
    });
    return new URLSearchParams(finalQuery);
};

export const isQueryEmpty = query => {
    let countKey = 0;
    if (!query) {
        return countKey < 1;
    }
    Object.entries(query).forEach(([key, value]) => {
        if (key !== 'accountId' && key !== 'page') {
            if (!!value) {
                countKey++;
            }
        }
    });

    return countKey < 1;
};

export const getQuerySearchParams = () => {
    let query: Record<string, string> = {};

    const params: any = new URLSearchParams(window.location.search);

    for (const param of params) {
        const [key, value] = param;

        if (key !== 'accountId') {
            query[key] = value;
        }
    }

    return query;
};

export const getLocationSearchReset = () => {
    let querySearch = {
        accountId: getAccountIdFromQuery(),
    };

    return new URLSearchParams(querySearch);
};
