import { trimEnd, trimStart } from 'lodash';
import { StoragePopupOptions } from '.';
import { StorageObjectListModel } from '../../../models';
import { FileStateType, storageEventTypeOptions } from './components';

export const isItemDeleting = (objects: StorageObjectListModel[]) => {
    let eventTypesList = objects.map(x => x.eventType);
    const { DeleteObject, DeleteFolder } = storageEventTypeOptions;
    return eventTypesList.includes(DeleteObject) || eventTypesList.includes(DeleteFolder);
};

export const isAnyObjectPending = (objects: StorageObjectListModel[], eventTypes: number[]) => {
    let isPending = false;

    if (!eventTypes.length) return isPending;

    for (let i = 0; i < objects.length; i++) {
        const currentObject = objects[i];
        if (eventTypes.includes(currentObject.eventType)) {
            isPending = true;
            break;
        }
    }
    return isPending;
};

export const goBackKey = (key: string) => {
    if (key.includes('/')) {
        let split = key.split('/').filter(x => x.length);
        let finalKey = split.slice(0, split.length - 1).join('/');
        return finalKey.length > 0 ? finalKey + '/' : finalKey;
    }
    return '';
};

export const formatFileName = (path: string, name: string) => {
    const _path = trimStart(path, '/');
    if (path.includes('/')) {
        const splitPath = _path.split('/');
        return splitPath.slice(0, splitPath.length - 1).join('/') + '/' + name;
    }
    return name;
};

export const getNameFromKey = (key: string) => {
    let splitArr = key.split('/').filter(x => x.length);
    return splitArr[splitArr.length - 1] ?? '';
};

export const splitPathAndFileNameFromKey = (key: string) => {
    let splitArr = key.split('/');
    let path = splitArr.slice(0, splitArr.length - 1).join('/');
    let name = getNameFromKey(key);

    return { path: path.endsWith('/') ? path : path + '/', name };
};

export const formatFileNamesBeforeUpload = (uploadedFiles: FileStateType[]) => {
    return uploadedFiles.map(x => ({
        ...x,
        name: x.file.path ? formatFileName(x.file.path, x.name) : x.name,
    }));
};

export const renameObject = (prevName: string, newName: string) => {
    const _newName = trimStart(newName, '/')
        .split('/')
        .filter(x => x.length > 0)
        .join('/');

    const _prevName = trimStart(trimEnd(prevName, '/'), '/');
    if (_prevName.includes('/')) {
        let splitName = _prevName.split('/');
        return `${splitName.slice(0, splitName.length - 1).join('/')}/${_newName}`;
    }

    return _newName;
};

export const getParamsPath = () => window.location.pathname.split('/data/storage/').slice(1).join();

export const storageGoBackRowData: StorageObjectListModel = {
    goBack: true,
    key: '',
    bucket: '',
    size: '',
    isFolder: false,
    url: '',
    eventType: 0,
    status: 0,
    lastModified: '',
};

export const getSelectMessageKeyForSelectPopup = (options?: StoragePopupOptions) => {
    if (options) {
        const { isFolder, multiple } = options;
        return `@select.${isFolder ? 'folder' : 'file'}${multiple ? 's' : ''}`;
    }
    return '@select';
};

export const isSelectableRowDisabled = (row: StorageObjectListModel, options?: StoragePopupOptions) => {
    let shouldDisable = !!row.goBack;

    if (options) {
        shouldDisable = shouldDisable || (options.isFolder ? !row.isFolder : row.isFolder);
    }
    return shouldDisable;
};
