import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
/**
 * components
 */
import { Icon, IconTypes, SingleDynamicInput } from '../../../../components/common';
import { SelectField } from '../../../../components/inputs';
import { Loader } from '../../../../components/ui';
/**
 * hooks - utils - models
 */
import { useCurrentWidth } from '../../../../hooks';
import { BaseEntityProperty, FlowActionProperty, SelectOptionType } from '../../../../models';
import { splitArrayIntoChunks } from '../../../../utils';

export interface FlowStepProps {
    errors?: any;
    title?: string;
    actionOptions?: any;
    isStepActive?: boolean;
    style?: CSSProperties;
    onChangeAction?: (item: any) => any;
    actionSelected?: any;
    isActionDisabled?: boolean;
    areActionsLoading?: boolean;
    tooltipMessage?: string;
    inputs?: FlowActionProperty[];
    onChangeInput: (key: string, e: any) => any;
    onChangeInputsList: (_inputs: BaseEntityProperty[]) => any;
    areInputsLoading?: boolean;
    onDeleteStep?: (e?: any) => any;
    showDeleteButton?: boolean;
    onAddNewOption?: (inputName: string, value: string) => any;
    onChangeSourceColumn?: (item: any) => any;
    sourceColumnSelected?: SelectOptionType | null;
    sourceColumnOptions?: SelectOptionType[] | [];
    areSourceColumnsLoading?: boolean;
}

const FlowStep: FC<FlowStepProps> = ({
    actionOptions,
    title,
    isStepActive,
    style,
    errors,
    actionSelected,
    onChangeAction,
    isActionDisabled,
    onChangeInput,
    onChangeInputsList,
    inputs,
    areInputsLoading,
    onDeleteStep,
    showDeleteButton,
    onAddNewOption,
    areActionsLoading,
    onChangeSourceColumn,
    sourceColumnOptions,
    sourceColumnSelected,
    areSourceColumnsLoading,
}) => {
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();

    const renderInputs = () => {
        if (areInputsLoading)
            return (
                <div className="w-full p-6 flex items-center justify-center">
                    <Loader color="#1D79F2" />
                </div>
            );

        let chunkedArray = splitArrayIntoChunks(inputs, 2);
        return chunkedArray?.map((el, i) => {
            const [firstInput, secondInput]: [FlowActionProperty, FlowActionProperty] = el;

            const commonProps = {
                properties: inputs || [],
                className: 'sm:mr-2',
                disabled: !isStepActive,
                errors,
                handleCreateOption: onAddNewOption,
                onChangeInputsList,
                onChangeInput,
            };
            return (
                <div key={i} className="flex flex-col sm:flex-row justify-between w-full mt-4 items-start">
                    {el.length > 1 ? (
                        <>
                            <SingleDynamicInput {...commonProps} property={firstInput} />
                            <SingleDynamicInput {...commonProps} property={secondInput} />
                        </>
                    ) : (
                        <SingleDynamicInput {...commonProps} property={firstInput} />
                    )}
                </div>
            );
        });
    };

    return (
        <div
            className={`flex flex-col items-center transition-all ${!isStepActive && 'cursor-not-allowed'}`}
            style={style}
        >
            <div
                className="flex flex-col h-auto rounded card-shadow bg-white"
                style={{
                    width: currentWidth < 1120 ? '100%' : 835,
                    maxWidth: '100%',
                }}
            >
                <div className="h-auto flex flex-row px-6 py-4 w-full border-b border-mainBorder justify-between items-center">
                    <span className="text-blueMainText text-sm sm:text-base font-normal text-left">{title}</span>

                    {showDeleteButton && (
                        <div
                            onClick={() => {
                                isStepActive && onDeleteStep && onDeleteStep();
                            }}
                            className={`flex flex-row h-full items-center cursor-pointer ${
                                !isStepActive && 'cursor-not-allowed'
                            }`}
                        >
                            <Icon name={IconTypes.delete} className="mr-2" />
                            <span className="text-mainGrayText">Delete</span>
                        </div>
                    )}
                </div>
                <div className="p-6">
                    <div className="flex w-full flex-col sm:flex-row justify-between">
                        <div className="flex-1 flex">
                            <SelectField
                                errorMessage={errors?.sourceColumnSelected}
                                required
                                disabled={isActionDisabled || !isStepActive}
                                label={t('@common.source-column')}
                                placeholder={t('@common.choose-source-column')}
                                options={sourceColumnOptions}
                                onChange={option =>
                                    isStepActive && onChangeSourceColumn && onChangeSourceColumn(option)
                                }
                                value={sourceColumnSelected}
                                isLoading={areSourceColumnsLoading}
                                className={`${sourceColumnSelected && 'sm:mr-2'}`}
                            />
                        </div>

                        {sourceColumnSelected && (
                            <SelectField
                                required
                                errorMessage={errors?.actionSelected}
                                disabled={isActionDisabled || !isStepActive}
                                label={t('@common.action')}
                                placeholder={t('@common.choose-action')}
                                options={areActionsLoading ? [] : actionOptions}
                                onChange={option => isStepActive && onChangeAction && onChangeAction(option)}
                                value={actionSelected}
                                isLoading={areActionsLoading}
                                className="sm:ml-2 flex flex-1"
                            />
                        )}
                    </div>
                    <div className="flex flex-col w-full h-auto">{renderInputs()}</div>
                </div>
            </div>
            <div
                style={{
                    height: isStepActive ? 40 : 24,
                    width: 1,
                    marginTop: 1,
                    border: '1px dashed #A3B0C2',
                    transform: 'rotate(180deg)',
                }}
            />
        </div>
    );
};

export default React.memo(FlowStep, arePropsEqual);

function arePropsEqual(prev, next) {
    if (JSON.stringify(prev) === JSON.stringify(next)) {
        return true;
    }
    return false;
}
