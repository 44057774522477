import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { useTranslation } from 'react-i18next';
import { toast, Tooltip } from '../ui';
import { Clickable } from './Clickable';

interface ICopyTextIconProps {
    tooltipId: string;
    tooltipContent: string;
    textToCopy: string;
    className?: string;
    successMessage?: string;
}

export const CopyTextIcon = ({
    textToCopy,
    tooltipContent,
    tooltipId,
    className,
    successMessage,
}: ICopyTextIconProps) => {
    const { t } = useTranslation();

    const onCopy = e => {
        e?.stopPropagation();
        navigator.clipboard.writeText(textToCopy);
        toast.info(successMessage ?? t('@text.copied', { defaultValue: 'Copied to clipboard!' }), { autoClose: 1500 });
    };
    return (
        <>
            <Tooltip id={tooltipId} />
            <Clickable
                data-tip={tooltipContent}
                data-for={tooltipId}
                style={{ color: '#3A81F6 !important' }}
                onClick={onCopy}
                className={`p-1 transition opacity-0 group-hover:opacity-100 text-primaryBlue500 ${className}`}
            >
                <GjirafaIcon name="Copy" size={24} />
            </Clickable>
        </>
    );
};
