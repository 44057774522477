import { nanoid } from 'nanoid';
import React, { useContext } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionContainerForCreatePages } from '../../../../../components/common';
import { InputField, SwitchInput } from '../../../../../components/inputs';
import { AddButton } from '../../../tasks/create/components/AddButton';
import { RemoveButton } from '../../../tasks/create/components/RemoveButton';
import { createPipelineContext } from '../contexts';

export type TVariable = {
    _id: string;
    id: number;
    name: string;
    value: string;
    locked: boolean;
    settableAtQueueTime: boolean;
    isOutput: boolean;
};

const inputsClassName = 'w-[220px] sm:mr-4';

export const VariablesTab = React.memo(() => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const { variables, setVariables } = useContext(createPipelineContext);

    const onChange = (_id: string, key: keyof TVariable, value: any) => {
        setVariables(prevVariables => {
            const newVariables = prevVariables.map(_variable => {
                if (_variable._id === _id) {
                    var nameExists = !!variables.find(x => x.name === value && x._id !== _id);
                    if (key.toString() === 'name') {
                        if (nameExists) {
                            _variable.error = 'Variable name must be unique.';
                        } else {
                            _variable.error = '';
                        }
                    }
                    return {
                        ..._variable,
                        [key]: value,
                    };
                } else {
                    return _variable;
                }
            });
            return newVariables;
        });
    };

    const removeVariable = (_id: string) => () => {
        setVariables(prev => prev.filter(x => x._id !== _id));
    };

    const addVariable = () => {
        setVariables(prev => [
            ...prev,
            {
                _id: nanoid(),
                id: 0,
                name: '',
                value: '',
                locked: false,
                settableAtQueueTime: false,
                isOutput: false,
                error: '',
            },
        ]);
        setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
    };

    return (
        <div className="p-6 mb-4">
            <SectionContainerForCreatePages width="large">
                <div className={`flex flex-col w-full py-4 rounded-lg bg-white card-shadow-sm px-6 mt-5`}>
                    {variables.map(({ _id, name, value, locked, settableAtQueueTime, isOutput, error }) => (
                        <div key={_id} className="flex w-full  py-2 px-2">
                            <InputField
                                disabled={isOutput}
                                required
                                className={`${inputsClassName}`}
                                label={t('@common.name')}
                                placeholder={t('@common.write.here')}
                                value={name}
                                onChange={e => onChange(_id, 'name', e.target.value)}
                                errorMessage={error}
                            />
                            <InputField
                                disabled={isOutput}
                                required
                                type={locked ? 'password' : 'text'}
                                className={`${inputsClassName} ml-2`}
                                label={t('@common.value')}
                                placeholder={t('@common.write.here')}
                                value={value}
                                onChange={e => onChange(_id, 'value', e.target.value)}
                            />
                            <SwitchInput
                                disabled={isOutput}
                                label={t('@common.locked', { defaultValue: 'Locked' })}
                                className="flex flex-col justify-between mr-5"
                                value={locked}
                                onChange={e => {
                                    onChange(_id, 'locked', e);
                                    onChange(_id, 'settableAtQueueTime', !e);
                                }}
                            />
                            <SwitchInput
                                disabled={isOutput}
                                label={t('@settable.queue.time', { defaultValue: 'Settable at queue time' })}
                                className="flex flex-col justify-between mr-5"
                                value={settableAtQueueTime}
                                onChange={e => {
                                    onChange(_id, 'settableAtQueueTime', e);
                                    onChange(_id, 'locked', !e);
                                }}
                            />
                            {!isOutput && <RemoveButton className="ml-2 mt-8" onClick={removeVariable(_id)} />}
                        </div>
                    ))}
                    <div ref={ref} />
                    <AddButton
                        className={`${variables.length ? 'mt-2' : ''}`}
                        title={t('@add.new.variable')}
                        onClick={addVariable}
                    />
                </div>
            </SectionContainerForCreatePages>
        </div>
    );
});
