import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldLabel } from '../../../../../components/inputs/components/FieldLabel';
import { ErrorTextComponent } from '../../../../../components/messages';
import { ObjectiveCardComponent } from '../../../../ai-engine/recommendation/components';
import { dataTypes } from './DataTypesComponent';

interface IObjectivesComponent {
    dataTypeId: number;
    objectiveId: number;
    onChangeObjectiveId: (id: number) => any;
    errorMessage?: string;
}

type ObjectiveType = {
    id: number;
    titleKey: string;
    descriptionKey: string;
    imageUrl: string;
    dataTypeIds: number[];
    comingSoon?: boolean;
};

const objectives: ObjectiveType[] = [
    {
        id: 1,
        titleKey: '@textual.classification.singleLabel',
        descriptionKey: '@recommendation.timeOnPage.description',
        imageUrl: 'https://www.gstatic.com/pantheon/images/automl/objective_nl_single.png',
        dataTypeIds: [dataTypes.textual],
    },
    {
        id: 6,
        titleKey: '@text.classification.multiLabel',
        descriptionKey: '@recommendation.timeOnPage.description',
        imageUrl: 'https://www.gstatic.com/pantheon/images/automl/objective_nl_multi.png',
        dataTypeIds: [dataTypes.textual],
    },
    {
        id: 10,
        titleKey: '@image.classification.singleLabel',
        descriptionKey: '@recommendation.timeOnPage.description',
        imageUrl: 'https://www.gstatic.com/pantheon/images/automl/objective_vision_single.png',
        dataTypeIds: [dataTypes.image],
    },
    {
        id: 2,
        titleKey: '@image.classification.multiLabel',
        descriptionKey: '@recommendation.timeOnPage.description',
        imageUrl: 'https://www.gstatic.com/pantheon/images/automl/objective_vision_multi.png',
        dataTypeIds: [dataTypes.image],
    },

    {
        id: 3,
        titleKey: '@classification',
        descriptionKey: '@recommendation.timeOnPage.description',
        imageUrl: 'https://www.gstatic.com/pantheon/images/automl/objective_nl_single.png',
        dataTypeIds: [dataTypes.tabular],
    },
    {
        id: 4,
        titleKey: '@regression',
        descriptionKey: '@recommendation.timeOnPage.description',
        imageUrl: 'https://www.gstatic.com/pantheon/images/automl/template_tables_basic_4.gif',
        dataTypeIds: [3],
    },
    {
        id: 5,
        titleKey: '@forecasting',
        descriptionKey: '@recommendation.timeOnPage.description',
        imageUrl: 'https://www.gstatic.com/pantheon/images/automl/template_tables_forecasting_3.gif',
        dataTypeIds: [3],
        comingSoon: true,
    },
];

const getGridRowsByItemsLength = length => {
    return `grid-rows-${length} sm:grid-rows-${Math.round(length / 2)}`;
};

export const ObjectivesComponent = ({
    dataTypeId,
    objectiveId,
    onChangeObjectiveId,
    errorMessage,
}: IObjectivesComponent) => {
    const { t } = useTranslation();

    const dataTypeObjectives: ObjectiveType[] = useMemo(
        () => objectives.filter(x => x.dataTypeIds.includes(dataTypeId)),
        [dataTypeId]
    );

    useEffect(() => onChangeObjectiveId(dataTypeObjectives[0]?.id), [dataTypeId]);

    return (
        <div className="flex w-full flex-col">
            <FieldLabel label={t('@select.dataTypeObjective')} />
            <div
                className={`w-full flex flex-col items-center sm:grid sm:grid-flow-rows sm:grid-cols-2 sm:gap-2 sm:gap-y-4 transition-all transform-gpu duration-350 ${getGridRowsByItemsLength(
                    dataTypeObjectives.length
                )}`}
            >
                {dataTypeObjectives.map(x => (
                    <ObjectiveCardComponent
                        key={x.id}
                        descriptionKey={x.descriptionKey}
                        titleKey={x.titleKey}
                        imageUrl={x.imageUrl}
                        tag={x.comingSoon ? 'comingSoon' : undefined}
                        isSelected={x.id === objectiveId}
                        onClickSelect={() => onChangeObjectiveId(x.id)}
                        disabled={x.comingSoon}
                    />
                ))}
            </div>
            <ErrorTextComponent message={errorMessage} />
        </div>
    );
};
