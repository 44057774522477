import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '../../../../components/buttons';

interface ObjectiveCardComponent {
    titleKey: string;
    descriptionKey: string;
    imageUrl: string;
    isSelected: boolean;
    onClickSelect: () => void;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    tag?: 'comingSoon';
}

export const ObjectiveCardComponent: React.FC<ObjectiveCardComponent> = ({
    descriptionKey,
    imageUrl,
    isSelected,
    onClickSelect,
    titleKey,
    className,
    style,
    disabled,
    tag,
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={`${
                disabled ? 'opacity-50' : 'cursor-pointer'
            } bg-white border border-white box-shadow overflow-hidden p-2 rounded transition-all duration-300 ${className}`}
            onClick={() => {
                !disabled && !isSelected && onClickSelect();
            }}
            style={{ width: 265, height: 221, ...style }}
        >
            <div className="h-1/2 flex items-center relative justify-center rounded bg-mainBackground w-full">
                <img src={imageUrl} className="object-cover h-24" />
            </div>
            <div className="h-1/2 mt-4 w-full box-border">
                <div className="h-8 w-8 pr-2.5">
                    <RadioButton isChecked={isSelected} />
                </div>
                <div className="w-full pl-8 -mt-8 box-border">
                    <div
                        style={{
                            overflowWrap: 'break-word',
                        }}
                        className="text-blueMainText text-sm font-medium box-border flex"
                    >
                        {t(titleKey)}
                        {tag === 'comingSoon' ? (
                            <div className="flex font-medium text-xs ml-2 text-center justify-center items-center px-1.5 py-0.5 text-primaryBlue500 bg-mainBlue bg-opacity-20 rounded-full">
                                Coming soon
                            </div>
                        ) : null}
                    </div>
                    <span className="text-secondaryText font-medium text-xs mt-1">{t(descriptionKey)}</span>
                </div>
            </div>
        </div>
    );
};
