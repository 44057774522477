import React, { CSSProperties } from 'react';

export interface NotificationLoaderProps {
    className?: string;
    style?: CSSProperties;
}

export const NotificationLoader: React.FC<NotificationLoaderProps> = ({ className, style }) => {
    return (
        <div
            style={style}
            className={`flex w-full flex-row flex-nowrap p-2 bg-white rounded-lg animate-pulse duration-100 ${className}`}
        >
            <div className="h-8 w-8 rounded-full bg-grey300" />
            <div className="flex flex-1 flex-col pl-4">
                <div className="bg-grey300 w-full rounded-lg h-6" />
                <div className="w-full flex items-center mt-3">
                    <div className="h-8 w-8 rounded-full bg-grey300 mr-4" />

                    <div className="bg-grey300 flex flex-1  rounded-lg h-6" />
                </div>
            </div>
        </div>
    );
};
