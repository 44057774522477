import { CSSProperties, FC } from 'react';

export interface TextInfoCardProps {
    title?: string;
    description?: string;
    style?: CSSProperties;
    className?: string;
}

export const TextInfoCard: FC<TextInfoCardProps> = ({ title, description, className, style }) => {
    return (
        <div className={`flex flex-col w-auto h-auto ${className}`} style={{ ...style }}>
            <span className="text-mainBlue font-medium text-lg text-left">{title}</span>
            <span className="text-left font-normal text-blueMainText text-sm mt-4">{description}</span>
        </div>
    );
};
