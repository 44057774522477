import React, { useMemo } from 'react';
import { Icon, IconTypes } from '../../common';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

export interface PaginationComponentProps {
    currentPage?: number;
    totalPages?: number;
    onChangePage?: (page: number) => any;
    totalItems?: number;
    pageItems?: number;
    className?: string;
    paginateProps?: ReactPaginateProps;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
    totalPages = 1,
    currentPage,
    onChangePage,
    totalItems,
    paginateProps,
    className,
}) => {
    const page = useMemo(() => {
        return currentPage ? currentPage + 1 : 1;
    }, [currentPage]);

    const pagesArray = useMemo(() => {
        let pages = [1];

        for (let i = 2; i <= totalPages; i++) pages.push(i);

        return pages;
    }, [totalPages]);

    return (
        <span
            className={`text-secondaryText w-full h-auto py-4 px-6 flex flex-wrap flex-row items-center justify-start border-mainBorder border-t ${className}`}
        >
            <ReactPaginate
                containerClassName="flex items-center"
                pageCount={totalPages}
                onPageChange={({ selected }: { selected: number }) => {
                    onChangePage && onChangePage(selected);
                }}
                previousLabel={
                    <Icon
                        name={IconTypes['chevron-left']}
                        size={26}
                        className={`cursor-pointer border border-white hover:border-mainBorder rounded ${
                            page <= 1 && 'cursor-not-allowed'
                        }`}
                    />
                }
                nextLabel={
                    <Icon
                        name={IconTypes['chevron-right']}
                        className={`cursor-pointer border border-white hover:border-mainBorder rounded ${
                            page >= pagesArray.length && 'cursor-not-allowed'
                        }`}
                    />
                }
                forcePage={currentPage}
                breakClassName="text-secondaryText mr-1 w-6"
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                pageClassName="mr-1"
                previousClassName="mr-1"
                pageLinkClassName="h-full w-full flex items-center justify-center rounded select-none focus:outline-none h-6 px-2 hover:text-primaryBlue500"
                activeClassName="text-mainBlue border border-mainBlue rounded"
                previousLinkClassName="focus:outline-none select-none"
                nextLinkClassName="focus:outline-none select-none"
                {...paginateProps}
            />
            <span className="text-secondaryText text-sm font-medium ml-4">
                Viewing {page * 10 - 9} to {page === totalPages ? totalItems : page * 10} of {totalItems} items
            </span>
        </span>
    );
};
