import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import {
    FooterButtons,
    HeaderForCreatePages,
    RunScheduleComponent,
    SectionContainerForCreatePages,
} from '../../../components/common';
import { DetailsLoader } from '../../../components/details-entity';
import { Error404 } from '../../../components/errors';
import { InputField, SelectField } from '../../../components/inputs';
import { toast } from '../../../components/ui';
import i18n from '../../../config/i18n';
import { LOOKALIKE_ENGINE_PATH, runSchedules } from '../../../constants';
import { useAccountId, useBiskoIntegrationError, useCurrentWidth, useDetailsError } from '../../../hooks';
import {
    BiskoSegmentListDataModel,
    BiskoSegmentListModel,
    LookalikeEngineDetailsResponseModel,
    LookalikeEnginePostModel,
    SelectOptionType,
} from '../../../models';
import { selectors as biskoSegmentsSelectors } from '../../../redux/thunk/bisko/biskoSegmentsThunk';
import axios from '../../../services/axios';
import { deleteErrorKey, formatArrayForSelectOptions, queryParams } from '../../../utils';
import { lookalikeEngineFormSchema, onValidateWithYup } from '../../../validations';

type FieldValuesType = {
    title: string;
    seedSegment: SelectOptionType | null;
    callbackUrl?: string;
};

const runSchedulesOptions = runSchedules.filter(x => x.value !== '5');

export const CreateLookalikeEnginePage = () => {
    /**
     * Hooks
     */
    const params = useParams<{ id?: string }>();
    const isEditing = params?.id;
    const history = useHistory();
    const accountId = useAccountId();
    const { detailsError, handleError, resetError } = useDetailsError();
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const SchemaValidation = useMemo(
        () => i18n.isInitialized && !!t && lookalikeEngineFormSchema(t),
        [i18n.isInitialized, t]
    );

    useBiskoIntegrationError({ selectors: biskoSegmentsSelectors, path: LOOKALIKE_ENGINE_PATH });

    /**
     * Selectors
     */
    const biskoSegmentsLoading = useSelector(biskoSegmentsSelectors.getIsLoading);
    const { basicSegments }: BiskoSegmentListDataModel = useSelector(biskoSegmentsSelectors.getData);

    /**
     * Local State
     */
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [runScheduleData, setRunScheduleData] = useState({
        scheduleFrequency: '',
    });
    const [scheduleSelectedOption, setScheduleSelectedOption] = useState<SelectOptionType | null>(null);
    const [fieldValues, setFieldValues] = useState<FieldValuesType>({
        title: '',
        seedSegment: null,
        callbackUrl: '',
    });

    useEffect(() => {
        if (params?.id && !biskoSegmentsLoading) {
            let timeout = setTimeout(() => {
                fetchDetails();
            }, 100);

            return () => clearTimeout(timeout);
        }
    }, [params?.id, accountId, biskoSegmentsLoading]);

    const fetchDetails = async () => {
        try {
            resetError();
            setDetailsLoading(true);

            const searchParams = new URLSearchParams({ accountId: accountId?.toString(), id: params?.id ?? '' });
            const response: LookalikeEngineDetailsResponseModel = await axios.get(`/lookalikeEngine?${searchParams}`);
            const { title, scheduleFrequency, scheduleId, callbackUrl, seedSegmentId } = response.model;
            const segment: BiskoSegmentListModel | null = basicSegments.find(x => x.id === seedSegmentId) ?? null;

            setFieldValues({
                seedSegment: segment ? { label: segment.name, value: segment.id?.toString() } : null,
                title,
                callbackUrl,
            });
            setRunScheduleData({
                scheduleFrequency: scheduleFrequency?.toString() || '',
            });
            setScheduleSelectedOption(runSchedulesOptions.find(y => y.value === scheduleId?.toString()) || null);
        } catch (err: any) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!SchemaValidation || formLoading) {
            return;
        }

        try {
            const payload: LookalikeEnginePostModel = {
                accountId,
                id: isEditing ? parseInt(params?.id ?? '0') : 0,
                title: fieldValues.title,
                seedSegmentId: parseInt(fieldValues.seedSegment?.value ?? '0') ?? 0,
                callbackUrl: fieldValues.callbackUrl,
                scheduleId: scheduleSelectedOption ? parseInt(scheduleSelectedOption?.value) : 0,
                scheduleFrequency: parseInt(runScheduleData.scheduleFrequency) || 0,
                runImmediately: true,
            };

            const { isValid, errors } = await onValidateWithYup(SchemaValidation, { ...payload });
            setErrors(errors);
            if (!isValid) return;

            setFormLoading(true);

            await axios.post('/lookalikeEngine', payload);

            toast.success(
                t(`@toast-messages.success-${isEditing ? 'edit' : 'create'}-lookalikeEngine`, {
                    defaultValue: `Lookalike engine has been ${isEditing ? 'updated' : 'created'}!`,
                })
            );
            history.push(`${LOOKALIKE_ENGINE_PATH}${queryParams.formatForNavigation()}`);
        } catch (err) {
            setFormLoading(false);
        }
    };

    const onChangeFieldValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const onChangeRunScheduleData = (key, value) => {
        deleteErrorKey(key, setErrors);
        setRunScheduleData(prev => ({ ...prev, [key]: value }));
    };

    const handleCancel = () => history.goBack();

    const { formId, buttonTitle } = useMemo(
        () => ({
            formId: isEditing ? 'editLookalikeEngine' : 'createLookalikeEngine',
            buttonTitle: t(`@common.${isEditing ? 'edit' : 'create'}`),
        }),
        [isEditing]
    );

    if (isEditing && detailsError.status === 404) {
        return <Error404 model="lookalikeEngine" />;
    }

    return (
        <PageAnimationContainer>
            <div className="flex flex-col">
                {detailsLoading ? (
                    <DetailsLoader />
                ) : (
                    <form className="flex flex-col items-center" id={formId} onSubmit={handleSubmit}>
                        <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                        <SectionContainerForCreatePages className="flex flex-col">
                            <InputField
                                disabled={formLoading}
                                required
                                label={t('@common.title')}
                                name="title-lookalike"
                                value={fieldValues.title}
                                onChange={e => onChangeFieldValues('title', e.target.value)}
                                placeholder={t('@common.write.here')}
                                errorMessage={errors?.title}
                            />
                            <SelectField
                                required
                                className="mt-4"
                                label={t('@seed.segment', { defaultValue: 'Seed segment' })}
                                disabled={formLoading}
                                placeholder={t('@common.choose')}
                                onChange={value => onChangeFieldValues('seedSegment', value)}
                                value={fieldValues.seedSegment}
                                options={formatArrayForSelectOptions(basicSegments, 'name', 'id')}
                                errorMessage={errors?.seedSegmentId}
                                isLoading={biskoSegmentsLoading}
                            />
                            <InputField
                                className="mt-4"
                                label={t('@callbackUrl', { defaultValue: 'Callback Url' })}
                                placeholder={t('@common.write.here')}
                                value={fieldValues.callbackUrl}
                                onChange={e => onChangeFieldValues('callbackUrl', e.target.value)}
                                name="callbackUrl"
                            />
                        </SectionContainerForCreatePages>
                        <RunScheduleComponent
                            onChangeRunScheduleData={onChangeRunScheduleData}
                            onChangeScheduleSelected={value => {
                                deleteErrorKey('scheduleId', setErrors);
                                setScheduleSelectedOption(value);
                            }}
                            runScheduleData={runScheduleData}
                            scheduleSelectedOption={scheduleSelectedOption}
                            runSchedulesOptions={runSchedulesOptions}
                            scheduleError={errors?.scheduleId}
                            scheduleFrequencyError={errors?.scheduleFrequency}
                            width="small"
                        />
                        <HeaderForCreatePages title="" className="mt-10" hideIcon />
                        <FooterButtons
                            hideSave
                            onClickCancel={handleCancel}
                            onClickThirdButton={handleSubmit}
                            thirdButtonProps={{
                                type: 'submit',
                                form: formId,
                                loading: formLoading,
                                disabled: formLoading,
                            }}
                            thirdButtonTitle={buttonTitle}
                            style={{
                                minWidth: currentWidth < 900 ? '100%' : 546,
                            }}
                        />
                    </form>
                )}
            </div>
        </PageAnimationContainer>
    );
};
