import { CSSProperties, FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
    ARTICLE_RECOMMENDATION_ENGINE_PATH,
    ARTIFACTS_PATH,
    BASE_RECOMMENDATION_PATH,
    CHURN_PROPENSITY_ENGINE_PATH,
    INFORMATION_PATH,
    NOTEBOOK_SERVERS_PATH,
    STORAGE_PATH,
    PIPELINES_PATH
} from '../../../constants';
import { queryParams } from '../../../utils';
import { Icon, IconTypes } from '../../common';

export interface HeaderTitleNavigationProps {
    className?: string;
    style?: CSSProperties;
}
type pathnameType = {
    displayName?: string;
    pathTo?: string;
    isLast?: boolean;
};

export const HeaderTitleNavigation: FC<HeaderTitleNavigationProps> = ({ className, style }) => {
    const location = useLocation();
    const [pathnames, setPathnames] = useState<pathnameType[]>([]);
    const history = useHistory();

    useEffect(() => {
        let pathname = location.pathname;

        if (pathname === '/') {
            setPathnames([{ displayName: 'Dashboard', isLast: true, pathTo: '' }]);
            return;
        }

        if (pathname.includes(STORAGE_PATH)) {
            pathname = STORAGE_PATH;
        }

        let pathIds: number[] = []
        let splitPathname = pathname
            .split('/')
            .slice(1)
            .filter(el => {
                if (parseInt(el)) pathIds.push(parseInt(el))
                return !parseInt(el)
            });
        let finalArray: pathnameType[] = [];

        splitPathname.forEach((el, i) => {
            let displayName = el
                .split('-')
                .map(x => x.charAt(0).toUpperCase() + x.slice(1))
                .join(' ');
            let pathTo = '';
            for (let j = 0; j <= i; j++) {
                pathTo += '/' + splitPathname[j];
            }
            finalArray.push({
                displayName,
                pathTo,
                isLast: i === splitPathname.length - 1,
            });
        });

        if (pathname.includes(PIPELINES_PATH) && pathIds.length === 2) {
            // job details and edit
            const pipelineJobPath = [
                {displayName: 'Lab', isLast: false, pathTo: '/lab'},
                {displayName: 'Pipelines', isLast: false, pathTo: '/lab/pipelines'},
                {displayName: 'Pipeline Jobs', isLast: false, pathTo: `/lab/pipelines/details/${pathIds[0]}`}
            ]
            if (pathname.includes('edit')) {
                pipelineJobPath.push({displayName: 'Edit Job', isLast: true, pathTo: ''})
            } else {
                pipelineJobPath.push({displayName: 'Details', isLast: true, pathTo: ''})
            }

            setPathnames(pipelineJobPath)
            return
        }

        setPathnames(finalArray);
    }, [location.pathname]);

    const onClickPathTo = pathTo => {
        if (pathTo === '/workflow') {
            if (location.pathname === INFORMATION_PATH) return;
            history.push(INFORMATION_PATH + queryParams.formatForNavigation());
            return;
        }

        if (pathTo === BASE_RECOMMENDATION_PATH || pathTo === '/AI-engine') {
            if (location.pathname === BASE_RECOMMENDATION_PATH) return;
            history.push(ARTICLE_RECOMMENDATION_ENGINE_PATH + queryParams.formatForNavigation());
            return;
        }

        if (pathTo === '/AI-engine/propensity-engine') {
            if (location.pathname === CHURN_PROPENSITY_ENGINE_PATH) return;
            history.push(CHURN_PROPENSITY_ENGINE_PATH + queryParams.formatForNavigation());
            return;
        }

        if (pathTo === '/data') {
            if (location.pathname === ARTIFACTS_PATH) return;
            history.push(ARTIFACTS_PATH + queryParams.formatForNavigation());
            return;
        }

        if (pathTo === '/lab') {
            if (location.pathname === NOTEBOOK_SERVERS_PATH) return;
            history.push(NOTEBOOK_SERVERS_PATH + queryParams.formatForNavigation());
            return;
        }

        history.push(pathTo + queryParams.formatForNavigation());
    };

    const renderPaths = () => {
        return pathnames?.map((path: pathnameType) => {
            const shouldNavigate = !path.isLast;
            return (
                <span
                    onClick={() => shouldNavigate && onClickPathTo(path.pathTo)}
                    key={path.pathTo}
                    className={`flex flex-row items-center justify-start text-xs sm:text-sm font-medium ${
                        path.isLast ? 'text-mainBlue ' : 'text-secondaryText cursor-pointer'
                    }`}
                >
                    {path?.displayName} {!path?.isLast && <Icon name={IconTypes['chevron-right']} className="" />}
                </span>
            );
        });
    };

    return (
        <div
            className={`flex w-auto flex-col items-start justify-center ${className}`}
            style={{ minHeight: 50, ...style }}
        >
            <span className="text-blueMainText text-xs sm:text-sm xl:text-base font-normal">
                {pathnames[pathnames.length - 1]?.displayName}
            </span>
            {pathnames.length > 1 && (
                <div className="flex flex-row flex-wrap justify-between items-center">{renderPaths()}</div>
            )}
        </div>
    );
};
