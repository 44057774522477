import React, { CSSProperties, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconTypes } from '..';
import { dataTypesOptions, inputTypeOptions, inputTypes } from '../../../constants';
import { ExternalSourceTypeListResponseModel, SelectOptionType } from '../../../models';
import { selectors as externalSourceTypesSelectors } from '../../../redux/thunk/app/externalSourceTypesThunk';
import { formatArrayForSelectOptions } from '../../../utils';
/**
 * Components
 */
import { OutlineButton } from '../../buttons';
import { InputField, MultiSelectField, SelectField, SwitchInput, TextareaField } from '../../inputs';
import { DynamicOptionForm } from './DynamicOptionForm';

export interface SingleOptionType extends SelectOptionType {
    id: number;
    _id: number;
}
const singleOption: SingleOptionType = {
    _id: 1,
    id: 0,
    label: '',
    value: '',
};
export interface SingleItemInputsType {
    _id: number;
    id: number;
    name: string;
    dataTypeId: SelectOptionType | null;
    inputType: SelectOptionType | null;
    required: boolean;
    defaultValue: string;
    options: SingleOptionType[];
    isColumn?: boolean;
    isRepeatable?: boolean;
    orderNumber?: number;
    conditionalId?: number;
    conditionalValue?: any;
    infoText?: string;
    infoLink?: string;
    externalSourceTypeId?: SelectOptionType | null;
}
export interface DynamicInputsFormProps {
    isEditing?: boolean;
    containerClassName?: string;
    containerStyle?: CSSProperties;
    innerStyle?: CSSProperties;
    innerClassName?: string;
}

const singleItemObject: SingleItemInputsType = {
    _id: 0,
    id: 0,
    name: '',
    dataTypeId: null,
    inputType: null,
    required: false,
    defaultValue: '',
    isColumn: false,
    orderNumber: 0,
    conditionalId: 0,
    conditionalValue: '',
    infoText: '',
    infoLink: '',
    options: [],
    externalSourceTypeId: null,
};

type InputRefType = {
    getInputs: () => SingleItemInputsType[];
    setInputs: (inputs: SingleItemInputsType[]) => any;
};
const DynamicInputsForm: React.ForwardRefRenderFunction<InputRefType, DynamicInputsFormProps> = (
    { containerClassName, containerStyle, innerClassName, innerStyle, isEditing },
    ref
) => {
    const externalSourceTypesResponse: ExternalSourceTypeListResponseModel = useSelector(
        externalSourceTypesSelectors.getResponse
    );
    const externalSourceTypeLoading = useSelector(externalSourceTypesSelectors.getIsLoading);

    const [items, setItems] = useState<SingleItemInputsType[]>([]);

    useImperativeHandle(ref, () => ({
        getInputs: () => {
            return items;
        },
        setInputs: (inputs: SingleItemInputsType[]) => {
            setItems(inputs);
        },
    }));

    const onAddNewItem = () => {
        setItems(prev => {
            let [length, lastItem] = [prev.length, prev[prev.length - 1]];

            return [
                ...prev,
                {
                    ...singleItemObject,
                    orderNumber: length > 0 && lastItem?.orderNumber !== undefined ? lastItem?.orderNumber + 1 : 0,
                    _id: length > 0 ? lastItem._id + 1 : 1,
                },
            ];
        });
    };

    const onDeleteItem = id => {
        setItems(prev => prev.filter(el => el._id !== id));
    };

    const onChangeInputsInItem = (itemId, key, value) => {
        setItems(prev => {
            return prev.map(x => {
                if (x._id === itemId)
                    return {
                        ...x,
                        [key]: value,
                    };
                return x;
            });
        });
    };

    const onAddNewOption = itemId => {
        setItems(prev =>
            prev.map(x => {
                if (x._id === itemId) {
                    let length = x.options.length;
                    return {
                        ...x,
                        options: [
                            ...x.options,
                            {
                                ...singleOption,
                                _id: length > 0 ? x.options[length - 1]._id + 1 : 1,
                            },
                        ],
                    };
                }

                return x;
            })
        );
    };

    const onDeleteOption = (itemId, optionId) => {
        setItems(prev =>
            prev.map(x => {
                if (x._id === itemId)
                    return {
                        ...x,
                        options: x.options.filter(x => x._id !== optionId),
                    };
                return x;
            })
        );
    };

    const onChangeOptionInputs = (itemId, optionId, key, value) => {
        setItems(prev =>
            prev.map(x => {
                if (x._id === itemId)
                    return {
                        ...x,
                        options: x.options.map(opt => {
                            if (opt._id === optionId)
                                return {
                                    ...opt,
                                    [key]: value,
                                };

                            return opt;
                        }),
                    };

                return x;
            })
        );
    };

    const renderDefaultValueInput = (item: SingleItemInputsType, inputFieldProps) => {
        const _inputType = item.inputType?.value;
        const _options = item.options || [];
        const _onChange = val => onChangeInputsInItem(item._id, 'defaultValue', val);
        const _commonProps = {
            label: 'Default Value',
        };

        if (_inputType === inputTypes.switch) {
            return (
                <SwitchInput
                    value={item?.defaultValue === 'true'}
                    onChange={val => _onChange(val ? 'true' : 'false')}
                    label="Default Value"
                    {...inputFieldProps}
                    style={{
                        width: 'auto',
                    }}
                />
            );
        }
        if (_inputType === inputTypes.dropdown || _inputType === inputTypes.dropdownOpen) {
            return (
                <SelectField
                    {..._commonProps}
                    value={_options.find(x => x.value.length > 0 && x.value === item.defaultValue) || null}
                    options={_options}
                    onChange={value => _onChange(value?.value)}
                    {...inputFieldProps}
                />
            );
        }
        if (_inputType === inputTypes.dropdownMultiSelect) {
            return (
                <MultiSelectField
                    value={item.defaultValue}
                    onChange={value => _onChange(value)}
                    options={_options}
                    {..._commonProps}
                    {...inputFieldProps}
                />
            );
        }
        return (
            <InputField
                label="Default Value"
                value={item?.defaultValue}
                onChange={e => onChangeInputsInItem(item._id, 'defaultValue', e.target.value)}
                {...inputFieldProps}
            />
        );
    };

    const renderItems = () => {
        let inputFieldProps = {
            placeholder: 'Write Here...',
            style: { width: 230 },
            className: 'm-2',
        };

        return items.map((el, i) => {
            const itemId = el?._id;
            return (
                <div
                    key={itemId}
                    className={`flex flex-col bg-white p-4 ${i > 0 && 'mt-4'} ${innerClassName}`}
                    style={innerStyle}
                >
                    {isEditing && !!el?.id && (
                        <label className="text-sm text-blueMainText m-2 font-medium">Input Id : {el?.id}</label>
                    )}
                    <div className={`flex flex-1 flex-row border-mainBorder`}>
                        <div className="flex flex-row flex-1 flex-wrap items-start">
                            <InputField
                                label="Name"
                                required
                                value={el?.name}
                                onChange={e => onChangeInputsInItem(itemId, 'name', e.target.value)}
                                {...inputFieldProps}
                            />

                            <SelectField
                                label="Input type"
                                required
                                value={el?.inputType}
                                options={inputTypeOptions}
                                onChange={item => onChangeInputsInItem(itemId, 'inputType', item)}
                                {...inputFieldProps}
                            />

                            <SelectField
                                disabled={
                                    !(
                                        (el.inputType?.value === inputTypes.dropdown && el.isColumn) ||
                                        el.inputType?.value === inputTypes.input
                                    )
                                }
                                label="Data Type"
                                isClearable
                                value={el?.dataTypeId}
                                options={dataTypesOptions}
                                onChange={value => onChangeInputsInItem(itemId, 'dataTypeId', value)}
                                {...inputFieldProps}
                            />

                            {renderDefaultValueInput(el, inputFieldProps)}
                            <InputField
                                label="Order Number"
                                value={el?.orderNumber}
                                onChange={e => onChangeInputsInItem(itemId, 'orderNumber', e.target.value)}
                                htmlType="number"
                                {...inputFieldProps}
                            />

                            <InputField
                                label="Conditional Id"
                                value={el?.conditionalId}
                                htmlType="number"
                                onChange={e => onChangeInputsInItem(itemId, 'conditionalId', e.target.value)}
                                {...inputFieldProps}
                            />
                            <InputField
                                label="Conditional Value"
                                value={el?.conditionalValue}
                                onChange={e => onChangeInputsInItem(itemId, 'conditionalValue', e.target.value)}
                                {...inputFieldProps}
                            />
                            <InputField
                                label="Info Link"
                                value={el?.infoLink}
                                onChange={e => onChangeInputsInItem(itemId, 'infoLink', e.target.value)}
                                {...inputFieldProps}
                            />
                            <div className="flex w-full flex-row flex-wrap">
                                <SwitchInput
                                    value={el?.isColumn || false}
                                    onChange={val => onChangeInputsInItem(itemId, 'isColumn', val)}
                                    label="Is Column"
                                    {...inputFieldProps}
                                    style={{
                                        width: 'auto',
                                    }}
                                />
                                <SwitchInput
                                    value={el?.required || false}
                                    onChange={val => onChangeInputsInItem(itemId, 'required', val)}
                                    label="Required"
                                    {...inputFieldProps}
                                    style={{
                                        width: 'auto',
                                    }}
                                />
                                <SwitchInput
                                    label="Is Repeatable"
                                    value={!!el?.isRepeatable}
                                    onChange={val => onChangeInputsInItem(itemId, 'isRepeatable', val)}
                                    {...inputFieldProps}
                                    style={{
                                        width: 'auto',
                                    }}
                                />
                            </div>

                            <div className="flex w-full">
                                <TextareaField
                                    label="Info Text"
                                    value={el?.infoText}
                                    onChange={e => onChangeInputsInItem(itemId, 'infoText', e.target.value)}
                                    {...inputFieldProps}
                                    isAutoSize
                                    style={{
                                        width: 400,
                                    }}
                                />
                            </div>
                            {(el?.inputType?.value === inputTypes?.dropdown ||
                                el?.inputType?.value === inputTypes?.dropdownMultiSelect ||
                                el?.inputType?.value === inputTypes?.dropdownOpen) && (
                                <div className="flex flex-row flex-wrap xl:flex-nowrap w-full justify-between items-start">
                                    <DynamicOptionForm
                                        className={inputFieldProps.className}
                                        itemId={itemId}
                                        onAddNewOption={onAddNewOption}
                                        onChangeOptionInputs={onChangeOptionInputs}
                                        onDeleteOption={onDeleteOption}
                                        options={el.options}
                                    />
                                    <span className="flex text-sm text-blueMainText font-medium pt-0 mt-5 xl:pt-8 h-full justify-center pr-8  2xl:pr-12">
                                        Or
                                    </span>
                                    <SelectField
                                        className="mt-5"
                                        label="External Source"
                                        isLoading={externalSourceTypeLoading}
                                        required={false}
                                        isClearable
                                        value={el.externalSourceTypeId}
                                        style={{ minWidth: 130 }}
                                        onChange={val => onChangeInputsInItem(itemId, 'externalSourceTypeId', val)}
                                        options={formatArrayForSelectOptions(
                                            externalSourceTypesResponse.models,
                                            'title',
                                            'id'
                                        )}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="flex justify-end ">
                            <Icon
                                name={IconTypes.delete}
                                className="cursor-pointer"
                                onClick={() => onDeleteItem(itemId)}
                                size={28}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={`w-full h-auto p-4 ${containerClassName}`} style={containerStyle}>
            {renderItems()}
            <div className="flex w-full justify-center items-center mt-4">
                <span className="text-secondaryText font-medium text-base mr-4">total : {items.length}</span>
                <OutlineButton onClick={onAddNewItem} title="+ Add New Input" />
            </div>
        </div>
    );
};

export default React.forwardRef(DynamicInputsForm);
