import React, { CSSProperties } from 'react';

export interface PropensityEngineInformationProps {
    className?: string;
    style?: CSSProperties;
}

export const PropensityEngineInformation: React.FC<PropensityEngineInformationProps> = ({ className }) => {
    return (
        <div className={`pb-10 flex flex-col md:flex-row w-full ${className}`}>
            <div className="flex flex-col mt-5 sm:mt-10 md:mt-20 flex-1 md:mr-5">
                <span className="text-blueMainText font-normal text-sm text-left">
                    <br />
                    <br />
                    Propensity modelling (PM) is a set of machine learning (ML) methods used to model and estimate the
                    likelihood of users performing certain types of behavior (e.g. subscribing to a certain service or
                    making the purchase of a product).
                    <br />
                    Currently we support two propensity models, namely likelihood to churn and likelihood to purchase.
                </span>
            </div>
            <div className="mt-5 sm:mt-10 md:mt-20 flex flex-col items-start flex-1 md:ml-5">
                <span className="text-blueMainText font-normal text-sm text-left whitespace-pre-wrap">
                    Key Features
                    <br />
                    <br /> • Propensity modeling using a broad set of data sources (e.g., content consumption, site
                    behavior, and offline data).{'\n'} • Rich set of ML solutions stacked on top of each other to
                    facilitate state of the art propensity modeling.{'\n'} • Automatic monitoring of the propensity
                    modeling precision to guarantee the highest possible quality.
                </span>
            </div>
        </div>
    );
};
