import React, { CSSProperties, ReactNode, useCallback } from 'react';
import { useToggleVisibility } from '../../hooks';
import { SelectOptionType } from '../../models';
import { StoragePage, StoragePopupOptions } from '../../pages/data/storage';
import { Icon, IconTypes } from '../common';
import { ErrorTextComponent } from '../messages';
import { FullWrapperModal } from '../modals';
import { SelectedOption, SelectStorageInput } from './components';

interface SelectWithStoragePopupFieldProps {
    label?: string;
    loading?: boolean;
    labelClassName?: string;
    labelStyle?: CSSProperties;
    className?: string;
    style?: CSSProperties;
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    required?: boolean;
    placeholder?: string;
    selectedItems: SelectOptionType[];
    onChangeSelectedItems: (items: SelectOptionType[]) => any;
    disabled?: boolean;
    isClearable?: boolean;
    labelRight?: ReactNode;
    options?: StoragePopupOptions;
    showSelectedItemInsideInput?: boolean;
}
export const SelectWithStoragePopupField: React.FC<SelectWithStoragePopupFieldProps> = ({
    className,
    disabled,
    errorMessage,
    isClearable,
    label,
    labelClassName,
    labelRight,
    placeholder,
    required,
    style,
    selectedItems,
    onChangeSelectedItems,
    options,
    showSelectedItemInsideInput,
}) => {
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility();

    const onHideModal = useCallback(() => onToggleVisibility(false), []);
    const onShowModal = useCallback(() => onToggleVisibility(true), []);

    return (
        <>
            <div style={{ ...style }} className={`flex flex-col w-full ${className}`}>
                {label && (
                    <div
                        style={{
                            minHeight: 24,
                        }}
                        className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                    >
                        <label className="text-xs sm:text-sm text-secondaryText font-normal">
                            {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                        </label>
                        {labelRight && labelRight}
                    </div>
                )}
                <SelectStorageInput
                    {...{
                        onChangeSelectedItems,
                        onShowModal,
                        selectedItems,
                        disabled,
                        errorMessage,
                        isClearable,
                        options,
                        placeholder,
                        showSelectedItemInsideInput,
                    }}
                />

                <ErrorTextComponent message={errorMessage} />
                <div className="flex flex-row flex-wrap mt-1 -ml-1">
                    {(!showSelectedItemInsideInput || options?.multiple) &&
                        selectedItems.map(item => (
                            <SelectedOption
                                label={item.label}
                                onRemove={() =>
                                    onChangeSelectedItems(selectedItems.filter(x => x.value !== item.value))
                                }
                            />
                        ))}
                </div>
            </div>

            {shouldRenderModal && (
                <FullWrapperModal isVisible={isModalVisible} onHide={onHideModal}>
                    <div className="text-xl flex flex-row justify-between items-center px-4 mb-2 md:pl-6 pt-3 z-10 font-semibold text-secondaryText  top-0 w-full bg-white">
                        Storage
                        <span className="cursor-pointer p-2.5" onClick={() => onHideModal()}>
                            <Icon name={IconTypes.close} size={30} />
                        </span>
                    </div>
                    <StoragePage
                        selectPopupProps={{
                            onChangeSelectedRows: rows => {
                                onChangeSelectedItems(rows.map(x => ({ value: x.key, label: x.key, ...x })));
                                onHideModal();
                            },
                        }}
                        options={options}
                        isFromSelectPopup
                    />
                </FullWrapperModal>
            )}
        </>
    );
};
