import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import { Bisko403ErrorAlert } from '../../../components/bisko';
import { InformationCard } from '../../../components/cards';
import { Loader } from '../../../components/ui';
import { ACCOUNT_SETTINGS_PATH, NOT_FOUND_PATH, QueryParamsKeys, RecommendationEngineTypes } from '../../../constants';
import { AccountDetailsResponseModel, StateErrorType, UserDetailsResponseModel } from '../../../models';
import { selectors as accountDetailsSelectors } from '../../../redux/thunk/app/account/accountDetailsThunk';
import { actions as recommendationActions } from '../../../redux/thunk/app/engines/recommendation/recommendationEnginesThunk';
import { selectors as biskoPropertiesSelectors } from '../../../redux/thunk/bisko/biskoPropertiesThunk';
import { formatRecommendationType, queryParams } from '../../../utils';
import { settingsTabs } from '../../settings';
import { RecommendationInformation, RecommendationTable } from './components';
import { selectors as userSelectors } from '../../../redux/thunk/app/user/userThunk';

export interface RecommendationEnginePageProps {
    recommendationEngineType: RecommendationEngineTypes;
}

export const RecommendationEnginePage: React.FC<RecommendationEnginePageProps> = ({ recommendationEngineType }) => {
    /**
     * hooks
     */
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    /**
     * Selectors
     */
    const accountLoading = useSelector(accountDetailsSelectors.getIsLoading);
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const biskoPropertiesLoading = useSelector(biskoPropertiesSelectors.getIsLoading);
    const biskoPropertiesError: StateErrorType = useSelector(biskoPropertiesSelectors.getError);
    const { model: userDetails }: UserDetailsResponseModel = useSelector(userSelectors.getResponse);

    useEffect(() => {
        if (!recommendationEngineType || !location.pathname.includes(recommendationEngineType)) {
            history.push(NOT_FOUND_PATH + location.search);
        }
    }, [location.pathname]);

    useEffect(() => {
        return () => {
            dispatch(recommendationActions.setEmptyResponse());
            dispatch(recommendationActions.removeNotificationQuery());
        };
    }, []);

    const renderRecommendationTable = () => {
        if (accountLoading || biskoPropertiesLoading) {
            return (
                <div className="flex w-full justify-center items-center p-10">
                    <Loader />
                </div>
            );
        }
        if (biskoPropertiesError?.status === 404) {
            return (
                <div className="flex w-full flex-col md:flex-row">
                    <div className="flex flex-1 md:mr-5"></div>
                    <span className=" flex-1 md:ml-5 text-deleteColor text-sm font-normal mt-2 whitespace-pre-wrap pb-10">
                        {t('@to.use.recommendationEngine.add.bisko.integration', {
                            defaultValue: 'To use Recommendation engine you should Add a Bisko Integration!',
                        })}
                        {'\n'}
                        {(accountDetails.isAdmin || userDetails.isAdmin) && (
                            <Link
                                to={{
                                    pathname: ACCOUNT_SETTINGS_PATH,
                                    search: queryParams.appendNewKey(
                                        QueryParamsKeys.tab_key,
                                        settingsTabs.accountIntegrations
                                    ),
                                }}
                                className="text-mainBlue font-medium mt-1 underline cursor-pointer"
                            >
                                {t('@go.to.integrationsTab', { defaultValue: 'Go to Integrations Tab' })}
                            </Link>
                        )}
                    </span>
                </div>
            );
        }

        if (biskoPropertiesError?.status === 403) {
            return (
                <div className="flex w-full flex-col md:flex-row">
                    <div className="flex flex-1 md:mr-5" />
                    <span className=" flex-1 md:ml-5 text-deleteColor text-sm font-normal mt-2 whitespace-pre-wrap pb-10">
                        {t('@error.bisko.user.access', {
                            defaultValue: `Bisko Error: You don't have access to bisko organization!`,
                        })}
                    </span>
                </div>
            );
        }

        return <RecommendationTable recommendationEngineType={recommendationEngineType} />;
    };

    return (
        <PageAnimationContainer>
            <div className="">
                <Bisko403ErrorAlert className="mb-4" error={biskoPropertiesError} />
                <InformationCard
                    title={`Recommendation Engine - ${formatRecommendationType(recommendationEngineType)}`}
                    showBackButton={false}
                    style={{ height: 'auto', flex: 1 }}
                    className="items-center"
                >
                    <div className="flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 w-full">
                        <RecommendationInformation recommendationEngineType={recommendationEngineType} />
                        {renderRecommendationTable()}
                    </div>
                </InformationCard>
            </div>
        </PageAnimationContainer>
    );
};
