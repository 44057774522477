import { inputTypes } from '../../../../../constants';
import { SelectOptionType } from '../../../../../models';

type TFormatValueForDynamicInputsProps = {
    inputType: string;
    value: any;
    options: SelectOptionType[];
};

export function formatValueForDynamicInputs({ inputType, options, value: _value }: TFormatValueForDynamicInputsProps) {
    let value: any = null;

    if (inputType === inputTypes.dropdown || inputType === inputTypes.dropdownOpen) {
        value = _value ? options?.find(x => x.value === _value) : null;
        if (!value && _value) {
            let newOption = {
                label: _value || '',
                value: _value || '',
            };
            options.push(newOption);
            value = newOption;
        }
    } else {
        value = _value ?? '';
    }

    return value;
}
