import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useAccountId, useDetailsError } from "../../../hooks";
import axios from '../../../services/axios';
import { Nullable, CatalogDetailsModel, CatalogDetailsResponseModel, CatalogListModel } from '../../../models';
import { TableActionRowProps, TableActionRow } from '../../../components/tables';
import { DetailsInfoOverview } from '../../../components/details-entity';
import { CustomTabContainer } from "../../../components/tabs/custom-tab/CustomTabContainer";
import { formatDateAndTime, queryParams } from '../../../utils';
import { CatalogDetailsTableSubcatalog } from './tabs/CatalogDetailsTableSubcatalog'
import { ToggleActionButton } from '../../../components/tables';
import { useToggleVisibility } from '../../../hooks';
import { CreateCatalogModal, CreateCatalogFilterModal, ConfirmationPopupModal } from '../../../components/modals';
import { toast } from '../../../components/ui';
import { actions as subcatalogActions } from '../../../redux/thunk/app/catalog/subcatalogsThunk';
import { CATALOGS_PATH } from '../../../constants';

enum CatalogDetailTab {
  subcatalog = 'Subcatalog'
}

export const CatalogDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleError, resetError } = useDetailsError();
  const accountId = useAccountId();
  const entityId = parseInt(params?.id);

  const [details, setDetails] = useState<Nullable<CatalogDetailsModel>>(null);
  const [catalogLoading, setCatalogLoading] = useState<boolean>(false);
  const [catalogIdForEdit, setCatalogIdForEdit] = useState<number>(0);
  const [selectedCatalogForFilter, setSelectedCatalogForFilter] = useState<CatalogListModel | null>(null);
  const [isCatalogFilterEditing, setIsCatalogFilterEditing] = useState(false);
  const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

  const [isCreateCatalogModalVisible, shouldRenderCreateCatalogModal, onToggleCreateCatalogModal] =
  useToggleVisibility({
      initialValue: false,
  });

  const [isCreateCatalogFilterModalVisible, shouldRenderCreateCatalogFilterModal, onToggleCreateCatalogFilterModal] =
  useToggleVisibility({
      initialValue: false,
  });

  useEffect(() => {
    fetchDetails();
  }, [accountId, entityId]);

  const fetchDetails = async () => {
    try {
      resetError();
      setCatalogLoading(true);
      const response: CatalogDetailsResponseModel = await axios.get(`/catalog?accountId=${accountId}&id=${entityId}`);
      setDetails(response.model);
    } catch (err) {
      handleError(err);
    } finally {
      setCatalogLoading(false);
    }
  };

  const DetailsOverviewData = {
    '@common.title': details?.name ?? '',
    '@createdAt': formatDateAndTime(details?.createdAt ?? ''),
    '@createdBy': details?.createdBy ?? '',
    '@common.last-modified': formatDateAndTime(details?.lastModified ?? ''),
    '@modifiedBy': details?.modifiedBy ?? '',
  }

  const buildDetailsInfoOverviewData = () => {
    const data = { ...DetailsOverviewData };

    Object.keys(data).forEach((n) => {
      if (!data[n]) {
        delete data[n];
      }
    })
    return data;
  };

  const fetchSubcatalogs = () => {
    dispatch(subcatalogActions.request(
      {
        accountId,
        parentId: entityId,
      }
    ))
  }

  const onSuccessCatalog = () => {
    fetchDetails();
    fetchSubcatalogs();
    toast.success(t('@success.edit.catalog', { defaultValue: 'Catalog has been updated!' }));
  };

  const onSuccessCatalogFilter = isEditing => {
    toast.success(
      isEditing
      ? t('@success.edit.catalogFilter', { defaultValue: 'Catalog Filter has been edited!' })
      : t('@success.create.catalogFilter', { defaultValue: 'Catalog Filter has been created!' })
    );
    fetchSubcatalogs();
  };

  const onClickEdit = () => {
    onToggleCreateCatalogModal(true);
    setCatalogIdForEdit(entityId);
  }

  const onClickApplyFilter = () => {
    onToggleCreateCatalogFilterModal(true);
    const catalogObject: CatalogListModel = {
      name: details?.name || '',
      schemaType: details?.schemaType?.name || '',
      schemaTypeId: details?.schemaType?.id || 0,
      artifact: details?.artifact?.title || '',
      schemaTypeCode: details?.schemaTypeCode || '',
      id: details?.id || 0,
      lastModified: details?.lastModified || '',
      modifiedBy: details?.modifiedBy || '',
      account: '',
      subCatalogs: []
    }
    setSelectedCatalogForFilter(catalogObject)
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await axios.delete(`/catalog?id=${entityId}&accountId=${accountId}`)
      toast.success(t('@success.delete.catalog', { defaultValue: 'Catalog has been deleted' }));
      history.push(CATALOGS_PATH + queryParams.formatForNavigation());
    } catch (error) {
      handleError(error);
      setDeleteLoading(false);
    }
  };

  const onClickDelete = () => {
    onToggleVisibility(true);
  };
    
  const actions: TableActionRowProps [] = [
    {
      action: 'edit',
      displayName: t('@common.edit'),
      onClick: onClickEdit,
    },
    {
      action: 'filter-blue',
      displayName: 'Filter',
      onClick: onClickApplyFilter,
    },
    {
      action: 'delete',
      displayName: t('@common.delete'),
      onClick: onClickDelete
    }
  ]

  return (
    <>
      {shouldRenderModal && (
          <ConfirmationPopupModal
              title={t(`@confirm-delete.catalog`)}
              isVisible={isModalVisible}
              onConfirm={() => handleDelete()}
              loading={deleteLoading}
              onCancel={() => {
                  onToggleVisibility(false);
              }}
              description={'Are you sure you want to delete this item'}
          />
      )}
      {shouldRenderCreateCatalogModal && (
        <CreateCatalogModal
          isVisible={isCreateCatalogModalVisible}
          onHide={() => {
            setCatalogIdForEdit(0);
            onToggleCreateCatalogModal(false);
          }}
          onSuccess={onSuccessCatalog}
          isEditing={!!catalogIdForEdit}
          catalogIdForEdit={catalogIdForEdit}
        />
      )}

      {shouldRenderCreateCatalogFilterModal && (
        <CreateCatalogFilterModal
          onSuccess={onSuccessCatalogFilter}
          parentCatalog={selectedCatalogForFilter}
          isVisible={isCreateCatalogFilterModalVisible}
          onHide={() => {
            onToggleCreateCatalogFilterModal(false);
            setSelectedCatalogForFilter(null);
            isCatalogFilterEditing && setIsCatalogFilterEditing(false);
          }}
          idForEdit={selectedCatalogForFilter ? selectedCatalogForFilter?.id : 0}
          isEditing={isCatalogFilterEditing}
        />
      )}

      <DetailsInfoOverview
        data={details ? { ...buildDetailsInfoOverviewData() } : {}}
        loading={catalogLoading}
        title={t('@catalog.overview', { defaultValue: 'Catalog overview' })}
        titleRightComponent={
        <ToggleActionButton>
          <>
            {actions.map((_action, i) => {
              return (
                <TableActionRow
                  key={i}
                  onClickAction={_action.onClick || (() => {})}
                  {..._action}
                  row={i}
                />
              );
            })}
          </>
        </ToggleActionButton>
        }
        style={{ minHeight: 200 }}
      />

      <CustomTabContainer
        style={{ minHeight: 500, marginTop: 20 }}
        animationEnabled={false}
        defaultActiveTab={CatalogDetailTab.subcatalog}
        tabs={[
          {
            component: <CatalogDetailsTableSubcatalog />,
            displayName: t('@catalogDetails.tab.subcatalogs', { defaultValue: 'Subcatalogs' }),
            key: CatalogDetailTab.subcatalog
          }
        ]}
      />
    </>
  )
}
