import { applyMiddleware, createStore, compose } from 'redux';
import rootReducer from '../thunk/rootReducer';

import thunk from 'redux-thunk';
import { env } from 'process';

const composeEnhancers =
    (env.NODE_ENV !== 'production' &&
        typeof window !== 'undefined' &&
        (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose)) ||
    compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
