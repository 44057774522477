import AXIOS, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { nanoid } from 'nanoid';
import { toast } from '../../../../components/ui';
import { ArtifactDetailsResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import { uploadToS3 } from '../../../../services/s3-services';
import { actionCreator } from '../../../../utils';
import { RootState } from '../../rootReducer';
import { actions as artifactsActions } from './artifactsThunk';

/**
 * Types
 */
export const actionTypes = {
    SET_UPLOAD_ARTIFACT_BY_ID: '@app/artifact/uploadArtifactThunk/SET_UPLOAD_ARTIFACT_BY_ID',
    SET_UPLOAD_ARTIFACTS: '@app/artifact/uploadArtifactThunk/SET_UPLOAD_ARTIFACTS',
    SET_HIDE_ARTIFACT_BY_ID: '@app/artifact/uploadArtifactThunk/SET_HIDE_ARTIFACT_BY_ID',
    SET_IS_DIALOG_HIDDEN: '@app/artifact/uploadArtifactThunk/SET_IS_DIALOG_HIDDEN',
};

/**
 * Initial state
 */
export interface ArtifactUploadingType {
    id: string;
    uploadProgress: number;
    title: string;
    cancelToken: CancelTokenSource;
    errorMessage: string;
}

type initialStateType = {
    artifactsUploading: ArtifactUploadingType[];
    isDialogHidden: boolean;
};

const initialState: initialStateType = {
    artifactsUploading: [],
    isDialogHidden: false,
};

/**
 * selectors
 */
export const selectors = {
    getUploadArtifacts: (state: RootState) => state.app.uploadArtifact.artifactsUploading,
    getIsDialogHidden: (state: RootState) => state.app.uploadArtifact.isDialogHidden,
};

/**
 * Reducer
 */
const { SET_UPLOAD_ARTIFACTS, SET_UPLOAD_ARTIFACT_BY_ID, SET_HIDE_ARTIFACT_BY_ID, SET_IS_DIALOG_HIDDEN } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_UPLOAD_ARTIFACTS:
            return {
                ...state,
                artifactsUploading: payload,
            };
        case SET_UPLOAD_ARTIFACT_BY_ID:
            return {
                ...state,
                artifactsUploading: state.artifactsUploading.map(_artifact => {
                    if (_artifact.id === payload?.id) {
                        return {
                            ..._artifact,
                            ...payload?.item,
                        };
                    }
                    return _artifact;
                }),
            };
        case SET_HIDE_ARTIFACT_BY_ID:
            return {
                ...state,
                artifactsUploading: state.artifactsUploading.filter(x => x.id !== payload),
            };
        case SET_IS_DIALOG_HIDDEN:
            return {
                ...state,
                isDialogHidden: payload,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */

export const actions = {
    setArtifactsUploading: (arr: ArtifactUploadingType[]) => actionCreator(SET_UPLOAD_ARTIFACTS, arr),
    closeSingleUpload: (id: string) => actionCreator(SET_HIDE_ARTIFACT_BY_ID, id),
    upload: function (
        { fileContents, accountId }: { fileContents: File; accountId: number },
        artifactEdit?: { id: number; title: string },
        successCallback?: (artifact: ArtifactDetailsResponseModel) => void
    ) {
        return async (dispatch, getState: () => RootState) => {
            try {
                const { name: fileName } = fileContents;
                let state = getState();
                const {
                    app: { uploadArtifact },
                } = state;

                const CancelToken = AXIOS.CancelToken;
                const source = CancelToken.source();

                const _uploadArtifact: ArtifactUploadingType = {
                    id: nanoid(),
                    cancelToken: source,
                    title: fileName,
                    uploadProgress: 0,
                    errorMessage: '',
                };

                dispatch(actionCreator(SET_IS_DIALOG_HIDDEN, false));

                dispatch(actionCreator(SET_UPLOAD_ARTIFACTS, [...uploadArtifact.artifactsUploading, _uploadArtifact]));

                let axiosConfig: AxiosRequestConfig = {
                    cancelToken: _uploadArtifact.cancelToken.token,
                    headers: {
                        uploadId: _uploadArtifact.id,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': fileContents.type || '',
                        'x-amz-acl': 'private',
                    },
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(
                            actionCreator(SET_UPLOAD_ARTIFACT_BY_ID, {
                                id: _uploadArtifact.id,
                                item: {
                                    uploadProgress: percentCompleted === 100 ? 99 : percentCompleted,
                                },
                            })
                        );
                    },
                };

                let uploadToS3Props = {
                    fileContents,
                    accountId,
                    endpointForPresignedLUrl: '/artifact/presignedUrl/upload',
                    uploadId: _uploadArtifact.id,
                };
                let response = await uploadToS3(uploadToS3Props, axiosConfig);

                // artifact payload for create
                let artifactPayload = {
                    accountId,
                    id: artifactEdit ? artifactEdit.id : 0,
                    title: artifactEdit ? artifactEdit.title : fileName,
                    relativeUrl: response.relativeUrl,
                };

                let artifactResponse: ArtifactDetailsResponseModel = await axios.post('/artifact', artifactPayload, {
                    headers: { uploadId: _uploadArtifact.id },
                });

                if (artifactEdit) {
                    toast.success(`New Version of "${artifactEdit.title}" has been uploaded!`);
                } else {
                    toast.success(`"${fileName}" Artifact has been uploaded!`);
                }

                if (successCallback) {
                    successCallback(artifactResponse);
                } else {
                    dispatch(
                        artifactsActions.request({
                            page: 0,
                        })
                    );
                }

                dispatch(actionCreator(SET_HIDE_ARTIFACT_BY_ID, _uploadArtifact.id));
            } catch (err: any) {
                const artifactsUploading: ArtifactUploadingType[] = getState().app.uploadArtifact.artifactsUploading;
                let uploadId = err?.config?.headers?.uploadId;

                if (AXIOS.isCancel(err)) {
                    return;
                }

                let message = err?.response?.data?.message || err?.message;
                dispatch(
                    actionCreator(
                        SET_UPLOAD_ARTIFACTS,
                        artifactsUploading?.map((_artifactUploading: ArtifactUploadingType) => {
                            if (_artifactUploading?.id === uploadId) {
                                return {
                                    ..._artifactUploading,
                                    errorMessage: message,
                                };
                            }
                            return _artifactUploading;
                        })
                    )
                );
            }
        };
    },
};
