import * as CSV from 'csv-string';
import { useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { NoDataImagesType, Table } from '../../../../components/tables';
import { Loader } from '../../../../components/ui';
import { COUNTER_TABLE_SELECTOR } from '../../../../constants';
import { replaceDotCharsInString, upperCaseFirstCharacter } from '../../../../utils';

type IDataTableColumn = TableColumn<any>;

export interface ArtifactPreviewComponent {
    data?: any;
}

type csvFileType = {
    data: any;
    columns: Array<IDataTableColumn>;
};

export const ArtifactDetailsTablePreview: React.FC<ArtifactPreviewComponent> = ({ data }) => {
    const { t } = useTranslation();
    const [textFile, setTextFile] = useState<any>(null);
    const [fileLoading, setIsFileLoading] = useState(true);
    const [csvFile, setCsvFile] = useState<csvFileType>({
        data: [],
        columns: [],
    });

    useEffect(() => {
        requestPreview();
    }, [data]);

    const fileType = useMemo(() => {
        if (data) {
            const type = data?.artifactContentTypeId;
            if (type === 1) {
                return 'csv';
            } else {
                return 'text';
            }
        }
        return '';
    }, [data]);

    const requestPreview = async () => {
        try {
            setIsFileLoading(true);
            if (data) {
                const csvStringResponse: string = data.artifactPreview;

                if (fileType === 'text') setTextFile(csvStringResponse);
                else if (fileType === 'csv') {
                    let parsedCsv = CSV.parse(csvStringResponse);
                    let columns: IDataTableColumn[] = [];
                    let data: any = [];

                    parsedCsv[0].forEach(col => {
                        let key = (col?.length ? replaceDotCharsInString(col) : 'unnamed').toLocaleLowerCase();
                        columns.push({
                            selector: row => (key ? row[key] ?? '' : ''),
                            name: upperCaseFirstCharacter(key),
                            grow: 0.2,
                            maxWidth: '300px',
                            sortable: false,
                        });
                    });

                    parsedCsv.slice(1).forEach(el => {
                        let item: any = {};
                        el.forEach((value, index) => {
                            let currentColumn = columns[index];
                            let key: string | null = currentColumn.name?.toString().toLocaleLowerCase() ?? null;

                            if (typeof key === 'string') {
                                item[key] = value;
                            }
                        });
                        data.push(item);
                    });
                    setCsvFile({ columns, data });
                }
            }
        } catch (err: any) {
            console.log({ err });
        } finally {
            setIsFileLoading(false);
        }
    };

    const renderFile = () => {
        if (fileType === 'text') {
            return (
                <div className="w-full">
                    <span className="card-shadow base-card-style p-10 w-full flex flex-col whitespace-pre-wrap text-blueMainText font-normal text-base overflow-auto">
                        {fileLoading ? (
                            <div className="flex w-full p-10 items-center justify-center">
                                <Loader />
                            </div>
                        ) : textFile && textFile.length ? (
                            textFile
                        ) : (
                            'No data'
                        )}
                    </span>
                </div>
            );
        }

        if (fileType === 'csv') {
            return (
                <div className="flex flex-col flex-1 h-auto">
                    <Table
                        tableAnimation={false}
                        headerButtonsAnimation={false}
                        modelEndpoint=""
                        requestList={() => {}}
                        className="w-full"
                        columns={[
                            {
                                name: '',
                                selector: row => row[COUNTER_TABLE_SELECTOR] ?? '',
                                grow: 0.2,
                                width: '80px',
                            },
                            ...csvFile.columns,
                        ]}
                        response={{
                            success: true,
                            message: '',
                            models: csvFile.data.map((x, i) => ({
                                ...x,
                                [COUNTER_TABLE_SELECTOR]: i + 1,
                            })),
                            totalPages: 1,
                            currentPage: 0,
                            totalItems: 0,
                            hasNextPage: false,
                            query: {},
                        }}
                        loading={fileLoading}
                        emptyDataProps={{
                            style: {
                                padding: 0,
                                height: 'auto',
                                paddingBottom: 20,
                            },
                            imageType: NoDataImagesType.Artifact,
                            emptyText: t('@common.file-not-loaded'),
                            actionMessage: t('@common.try-again'),
                            onClickAction: () => {},
                        }}
                    />
                </div>
            );
        }
        return <span className="font-normal text-sm text-blueMainText">Unsupported file type!</span>;
    };

    return fileType ? (
        renderFile()
    ) : (
        <div className="flex w-full p-10 items-center justify-center">
            <Loader />
        </div>
    );
};
