import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export const lookalikeEngineFormSchema = (t: TFunction<'translation', undefined>) =>
    yup.object().shape({
        title: yup.string().required(t('@validations.field.isRequired', { field: t('@common.title') })),
        seedSegmentId: yup.number().moreThan(0, t('@validations.field.isRequired', { field: t('@seed.segment') })),
        scheduleId: yup.number().moreThan(0, t('@validations.field.isRequired', { field: t('@common.schedule') })),
        scheduleFrequency: yup.number().when('scheduleId', {
            is: scheduleId => scheduleId === 2 || scheduleId === 3,
            then: yup
                .number()
                .moreThan(0, t('@validations.this.field.isRequired', { defaultValue: 'This field is required!' })),
        }),
    });
