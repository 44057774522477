import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { Clickable } from '../../../../../components/common';

interface IRemoveButtonProps {
    onClick: () => void;
    className?: string;
}

export const RemoveButton = ({ onClick, className }: IRemoveButtonProps) => (
    <Clickable
        onClick={onClick}
        className={`p-1 h-fit text-secondaryText rounded-full border border-white transition-all duration-200 hover:border-mainBorder ${className}`}
    >
        <GjirafaIcon name="Close" size={22} />
    </Clickable>
);
