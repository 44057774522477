import React, { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccountIntegrationImage } from '../../../assets/images/empty/account-integration.svg';
import { ReactComponent as ApiKey } from '../../../assets/images/empty/api-key.svg';
import { ReactComponent as ArtifactImage } from '../../../assets/images/empty/empty-artifact.svg';
import { ReactComponent as Catalog } from '../../../assets/images/empty/empty-catalog.svg';
import { ReactComponent as DatasetImage } from '../../../assets/images/empty/empty-dataset.svg';
import { ReactComponent as DeploymentImage } from '../../../assets/images/empty/empty-deployment.svg';
import { ReactComponent as FilterImage } from '../../../assets/images/empty/empty-filter.svg';
import { ReactComponent as FlowImage } from '../../../assets/images/empty/empty-flow.svg';
import { ReactComponent as InvoiceImage } from '../../../assets/images/empty/empty-invoice.svg';
import { ReactComponent as LookalikeEngineImage } from '../../../assets/images/empty/empty-lookalike.svg';
import { ReactComponent as ModelImage } from '../../../assets/images/empty/empty-model.svg';
import { ReactComponent as NotebookServerImage } from '../../../assets/images/empty/empty-notebook.svg';
import { ReactComponent as PropensityEngineImage } from '../../../assets/images/empty/empty-propensityEngine.svg';
import { ReactComponent as RecommendationEngineImage } from '../../../assets/images/empty/empty-recommendation.svg';
import { ReactComponent as RecommendationRunImage } from '../../../assets/images/empty/empty-recommendationRun.svg';
import { ReactComponent as User } from '../../../assets/images/empty/empty-user.svg';
import { ReactComponent as VoucherCodeImage } from '../../../assets/images/empty/voucher-code.svg';

export enum NoDataImagesType {
    Dataset = 'dataset',
    DataSource = 'dataSource',
    Artifact = 'artifact',
    Model = 'model',
    FeatureEngineering = 'featureEngineering',
    Catalog = 'catalog',
    Schema = 'schema',
    User = 'user',
    ApiKey = 'apiKey',
    Recommendation = 'Recommendation',
    LookalikeEngine = 'LookalikeEngine',
    PropensityEngine = 'PropensityEngine',
    Filter = 'Filter',
    Deployment = 'Deployment',
    Voucher = 'Voucher',
    NotebookServer = 'NotebookServer',
    Invoice = 'Invoice',
    DataSourceReader = 'DataSourceReader',
    RecommendationRun = 'RecommendationRun',
    LookalikeEngineRun = 'LookalikeEngineRun',
    AccountIntegration = 'AccountIntegration',
    CustomEndpoint = 'CustomEndpoint',
    Pipeline = 'Pipeline',
    PipelineJob = 'PipelineJob',
    PipelineJobRun = 'PipelineJobRun',
    Task = 'Task',
}

export interface NoDataComponentProps {
    style?: CSSProperties;
    className?: string;
    emptyText?: string;
    actionMessage?: string | ReactNode;
    onClickAction?: (e: any) => any;
    imageType?: NoDataImagesType;
    childrenNode?: ReactNode;
    actionMessageComponent?: ReactNode;
}

export const NoDataComponent: React.FC<NoDataComponentProps> = ({
    actionMessage,
    className,
    emptyText = 'No items found',
    imageType = NoDataImagesType.Artifact,
    onClickAction,
    style,
    childrenNode,
    actionMessageComponent,
}) => {
    const { t } = useTranslation();
    const _actionMessage = actionMessage ?? t('@create.new.item', { defaultValue: 'Create new item' });

    let imageStyle: React.CSSProperties | undefined = {
        height: 45,
        width: 45,
        objectFit: 'contain',
    };

    const images: Record<string, ReactNode> = {
        [NoDataImagesType.Filter]: <FilterImage style={imageStyle} />,
        [NoDataImagesType.Dataset]: <DatasetImage style={imageStyle} />,
        [NoDataImagesType.DataSource]: <DatasetImage style={imageStyle} />, //TODO: change icon
        [NoDataImagesType.Artifact]: <ArtifactImage style={imageStyle} />,
        [NoDataImagesType.Model]: <ModelImage style={imageStyle} />,
        [NoDataImagesType.FeatureEngineering]: <FlowImage style={imageStyle} />,
        [NoDataImagesType.Catalog]: <Catalog style={imageStyle} />,
        [NoDataImagesType.Schema]: <Catalog style={imageStyle} />, //TODO: change icon
        [NoDataImagesType.ApiKey]: <ApiKey style={imageStyle} />,
        [NoDataImagesType.Recommendation]: <RecommendationEngineImage style={imageStyle} />,
        [NoDataImagesType.User]: <User style={imageStyle} />,
        [NoDataImagesType.PropensityEngine]: <PropensityEngineImage style={imageStyle} />,
        [NoDataImagesType.Deployment]: <DeploymentImage style={imageStyle} />,
        [NoDataImagesType.Voucher]: <VoucherCodeImage style={imageStyle} />,
        [NoDataImagesType.Invoice]: <InvoiceImage style={imageStyle} />,
        [NoDataImagesType.NotebookServer]: <NotebookServerImage style={imageStyle} />,
        [NoDataImagesType.Pipeline]: <NotebookServerImage style={imageStyle} />, // TODO: change icon
        [NoDataImagesType.PipelineJob]: <NotebookServerImage style={imageStyle} />, // TODO: change icon
        [NoDataImagesType.PipelineJobRun]: <NotebookServerImage style={imageStyle} />, // TODO: change icon
        [NoDataImagesType.CustomEndpoint]: <NotebookServerImage style={imageStyle} />, // TODO: change icon
        [NoDataImagesType.Task]: <VoucherCodeImage style={imageStyle} />,
        [NoDataImagesType.DataSourceReader]: <DatasetImage style={imageStyle} />,
        [NoDataImagesType.RecommendationRun]: <RecommendationRunImage style={imageStyle} />,
        [NoDataImagesType.LookalikeEngine]: <LookalikeEngineImage className=" fill-current" style={imageStyle} />,
        [NoDataImagesType.AccountIntegration]: <AccountIntegrationImage style={imageStyle} />,
        [NoDataImagesType.LookalikeEngineRun]: <LookalikeEngineImage style={imageStyle} />,
    };

    return (
        <div
            className={`w-full flex flex-col justify-center items-center bg-white ${className}`}
            style={{ height: 212, ...style }}
        >
            <div className="flex flex-col justify-between items-center" style={{ height: 'auto' }}>
                {images[imageType]}
                <div className="flex flex-col justify-center items-center mt-4">
                    {emptyText && <span className="font-normal text-center text-xs sm:text-sm">{emptyText}</span>}
                    {actionMessageComponent
                        ? actionMessageComponent
                        : _actionMessage && (
                              <span
                                  onClick={e => onClickAction && onClickAction(e)}
                                  className="text-mainBlue font-medium cursor-pointer text-sm sm:text-base hover:underline"
                                  style={{ marginTop: emptyText ? 8 : 0 }}
                              >
                                  {_actionMessage}
                              </span>
                          )}
                    {childrenNode}
                </div>
            </div>
        </div>
    );
};
