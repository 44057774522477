import { CSSProperties } from 'react';
import { Icon, IconProps, IconTypes } from '../common';

export interface CheckboxProps {
    isChecked: boolean;
    toggleCheck?: () => any;
    className?: string;
    style?: CSSProperties;
    iconProps?: IconProps;
}
export const Checkbox: React.FC<CheckboxProps> = ({ isChecked, toggleCheck, className, iconProps, style }) => {
    return (
        <div
            onClick={toggleCheck}
            className={`cursor-pointer transition-all duration-300 rounded border  flex justify-center items-center ${
                isChecked ? 'bg-mainBlue transition-all border-mainBlue' : 'border-mainGrayText'
            } ${className}`}
            style={{
                height: 18,
                width: 18,
                ...style,
            }}
        >
            {isChecked && <Icon name={IconTypes.check} size={10} {...iconProps} />}
        </div>
    );
};
