import { CSSProperties } from 'react';

interface ITimeLineNotificationItemLoaderProps {
    className?: string;
    style?: CSSProperties;
    showTimeLine?: boolean;
}

export const TimeLineNotificationItemLoader = ({
    className,
    style,
    showTimeLine = false,
}: ITimeLineNotificationItemLoaderProps) => (
    <div className="flex w-full">
        {showTimeLine && (
            <div className="flex flex-col w-4 mr-4  h-full justify-center items-center">
                <div className="h-full w-0.5 bg-blueStroke" />
            </div>
        )}
        <div
            className={`flex flex-row flex-1 items-center justify-center p-4 rounded bg-white animate-pulse duration-100 ${className}`}
            style={{ ...style }}
        >
            <div className="h-10 w-10 rounded-full bg-grey300 flex-none" />
            <div className="flex flex-col w-full ml-3 ">
                <div className="h-5 w-1/3 rounded bg-grey300" />
                <div className="h-4 mt-1 w-1/2 rounded  bg-grey300" />
            </div>
        </div>
    </div>
);
