import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { taskInputTypes } from '../../pages/lab/tasks/constants';

export const taskFormSchema = (t: TFunction<'translation', undefined>) =>
    yup.object().shape({
        title: yup.string().required(t('@validations.field.isRequired', { field: t('@common.title') })),
        alias: yup.string().required(t('@validations.field.isRequired', { field: t('@alias') })),
        typeId: yup
            .number()
            .required()
            .moreThan(0, t('@validations.field.isRequired', { field: t('@task.type') })),
        scriptPath: yup
            .string()
            .required(t('@validations.field.isRequired', { field: 'Python Script' }))
            .matches(
                new RegExp('^.*.py$'),
                t('@validations.invalid.file', { file: 'Python', defaultValue: 'Please select a {{file}} File!' })
            ),
        requirementsPath: yup
            .string()
            .optional()
            .matches(
                new RegExp('^.*.txt$'),
                t('@validations.invalid.file', { file: 'TXT', defaultValue: 'Please select a {{file}} File!' })
            ),
        // inputs: yup
        //     .array()
        //     .of(
        //         yup.object().shape({
        //             name: yup.string().required(t('@validations.field.isRequired', { field: 'Name' })),
        //         })
        //     )
        //     .compact(x => !x.name)
        //     .required(t('@validations.field.isRequired', { field: t('@common.name') })),
        // outputs: yup
        //     .array()
        //     .of(
        //         yup.object().shape({
        //             name: yup.string().required(t('@validations.field.isRequired', { field: 'Name' })),
        //         })
        //     )
        //     .compact(x => !x.name)
        //     .required(
        //         t('@validations.field.isRequired', {
        //             field: t('@common.name'),
        //         })
        //     ),
    });

export const taskFormInputSchema = (t: TFunction<'translation', undefined>) =>
    yup.object().shape({
        inputs: yup.array().of(
            yup.object().shape({
                name: yup.string().required(),
                inputType: yup.string().required(),
                dataTypeId: yup.string().when('inputType', {
                    is: (_inputType: string) => [taskInputTypes.dropdown].includes(_inputType),
                    then: yup.string().required(),
                }),
                options: yup.array().of(
                    yup.object().shape({
                        label: yup.string().required(),
                        value: yup.string().required(),
                    })
                ),
            })
        ),
    });
