/**
 * Libraries
 */
import { CSSProperties, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
/**
 * utils - hooks - styles
 */
import { useCurrentWidth } from '../../hooks';
/**
 * Redux
 */
import { actions as themeActions } from '../../redux/thunk/theme';
import { menuListAnimationStyles } from '../../styles/animationStyles';
import { getWidth } from '../../utils';
import { Icon, IconTypes } from '../common';
import { UploadDialogHeader } from './components';
/**
 * Components
 */
import { HeaderTitleNavigation } from './components/HeaderTitleNavigation';
import { ObjectsUploadingDialogHeader } from './components/ObjectsUploadingDialogHeader';
import { MyProfile } from './MyProfile';
import { NotificationDropdown } from './notifications';

export interface HeaderProps {
    style?: CSSProperties;
    showToggleMenu?: boolean;
}
const Header: React.FC<HeaderProps> = ({ style, showToggleMenu = true }) => {
    /**
     * Hooks
     */
    const location = useLocation();
    const dispatch = useDispatch();
    const currentWidth = useCurrentWidth();

    /**
     * Local State
     */
    const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(getWidth() > 900);

    useEffect(() => {
        if (currentWidth < 900) dispatch(themeActions.closeMenu());
        else setIsHeaderCollapsed(true);
    }, [currentWidth]);

    const onToggleMenu = () => {
        dispatch(themeActions.toggleMenu());
    };

    const onToggleHeader = () => {
        setIsHeaderCollapsed(prev => !prev);
    };

    return (
        <div
            className={`w-full h-auto pr-6 py-3 z-10 bg-white justify-between flex items-center  border-mainBorder sticky flex-col md:flex-row ${
                currentWidth < 900 && 'md:flex-col'
            }`}
            style={{ height: 'auto', maxHeight: 'fit-content', boxShadow: '0px -2px 12px #E0E5EB', ...style }}
        >
            <div
                className={`flex flex-row justify-between h-full w-full px-4 items-center md:pl-7 md:w-auto ${
                    currentWidth < 900 && 'md:w-full p-4 pr-0'
                }`}
            >
                <div className="flex items-center">
                    {showToggleMenu && (
                        <Icon name={IconTypes.menu} className="mr-6 cursor-pointer" onClick={onToggleMenu} />
                    )}
                    <Link
                        to={{ pathname: '/', search: location.search }}
                        className="flex flex-row items-center justify-start"
                    >
                        <Logo />
                    </Link>
                </div>
                {currentWidth < 900 && (
                    <div
                        className={`cursor-pointer flex justify-center transform-gpu transition-all duration-350 ${
                            isHeaderCollapsed ? 'rotate-0' : '-rotate-180'
                        }`}
                        onClick={onToggleHeader}
                    >
                        <Icon name={IconTypes['chevron-up']} height={30} width={30} />
                    </div>
                )}
            </div>

            <Transition unmountOnExit timeout={200} in={isHeaderCollapsed}>
                {state => (
                    <div
                        className="flex flex-row flex-1 flex-wrap justify-between  items-center pl-4 w-full md:flex-row md:pl-7"
                        style={{ ...menuListAnimationStyles[state] }}
                    >
                        <HeaderTitleNavigation />
                        <div className="flex flex-row justify-end flex-1 items-center py-4 pl-3 md:py-0 md:pl-0">
                            <ObjectsUploadingDialogHeader />

                            <UploadDialogHeader />

                            <NotificationDropdown />

                            <MyProfile />
                        </div>
                    </div>
                )}
            </Transition>
        </div>
    );
};

export default Header;
