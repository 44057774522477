import { SwitchTransition, Transition } from 'react-transition-group';
import { switchTabsAnimationStyles } from '../../../styles/animationStyles';

export const TabsAnimationContainer: React.FC<{ activeTab: string; animation: boolean; className?: string }> = ({
    children,
    activeTab,
    className,
    animation,
}) => {
    const _className = `bg-white p-2 sm:p-6 rounded flex flex-1 flex-col ${className}`;
    if (animation) {
        return (
            <SwitchTransition mode="out-in">
                <Transition
                    timeout={0}
                    key={activeTab}
                    addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                >
                    {state => (
                        <div className={_className} style={{ ...switchTabsAnimationStyles[state] }}>
                            {children}
                        </div>
                    )}
                </Transition>
            </SwitchTransition>
        );
    }

    return <div className={_className}>{children}</div>;
};
