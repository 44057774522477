import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PageAnimationContainer } from '../../components/animation/PageAnimationContainer';
/**
 * Components
 */
import { AccountApiKeysTable } from '../../components/tables';
import { CustomTabContainer } from '../../components/tabs/custom-tab/CustomTabContainer';
import { QueryParamsKeys } from '../../constants';
import { useAccountId, useQuery } from '../../hooks';
import { AccountDetailsResponseModel, UserDetailsResponseModel } from '../../models';
import { actions as accountApiKeysActions } from '../../redux/thunk/app/account/accountApiKeysThunk';
/**
 * Redux
 */
import { selectors as accountDetailsSelector } from '../../redux/thunk/app/account/accountDetailsThunk';
import { actions as accountUsersActions } from '../../redux/thunk/app/account/accountUsersThunk';
import { actions as integrationTypesActions } from '../../redux/thunk/app/integrationType/adminIntegrationTypesThunk';
import { selectors as userSelectors } from '../../redux/thunk/app/user/userThunk';
import { AccountIntegrationsTab, EditAccountTab, UserManagementTab } from './settings-tabs';

export enum settingsTabs {
    editAccount = 'editAccount',
    accountIntegrations = 'accountIntegrations',
    userManagement = 'userManagement',
    accountApiKey = 'accountApiKeys',
}

export const SettingsPage = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accountId = useAccountId();
    const query = useQuery();
    const tabKeyFromParams = query.get(QueryParamsKeys.tab_key);

    /**
     * Redux Selectors
     */
    const accountDetails: AccountDetailsResponseModel = useSelector(accountDetailsSelector.getResponse);
    const userDetails: UserDetailsResponseModel = useSelector(userSelectors.getResponse);

    /**
     * Effects
     */
    useEffect(() => {
        dispatch(integrationTypesActions.request({}));
    }, []);

    useEffect(() => {
        dispatch(accountUsersActions.request({ accountId, searchQuery: '' }));
        dispatch(accountApiKeysActions.request({ accountId, page: 0, searchQuery: '' }));
    }, [accountId]);

    useEffect(() => {
        if (userDetails.model?.id && accountDetails.model?.id) {
            if (!userDetails.model.isAdmin && !accountDetails.model.isAdmin) {
                window.location.replace(`/?accountId=${accountId}`);
            }
        }
    }, [userDetails, accountDetails]);

    return (
        <PageAnimationContainer>
            <div className="flex flex-col flex-1">
                <CustomTabContainer
                    defaultActiveTab={tabKeyFromParams || settingsTabs.editAccount}
                    tabs={[
                        {
                            displayName: t('@common.edit-account'),
                            key: settingsTabs.editAccount,
                            component: <EditAccountTab />,
                        },
                        {
                            displayName: t('@account.integrations', { defaultValue: 'Account Integrations' }),
                            component: <AccountIntegrationsTab />,
                            key: settingsTabs.accountIntegrations,
                        },
                        {
                            displayName: t('@pages.settings.user-management'),
                            key: settingsTabs.userManagement,
                            component: <UserManagementTab />,
                        },
                        {
                            displayName: t('account.api.keys'),
                            key: settingsTabs.accountApiKey,
                            component: <AccountApiKeysTable />,
                        },
                    ]}
                />
            </div>
        </PageAnimationContainer>
    );
};
