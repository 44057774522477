import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';

interface IUrlTableComponent {
    openInNewTab?: boolean;
    onClick?: () => any;
    displayText?: string;
    url: string;
    className?: string;
    style?: CSSProperties;
    withIcon?: boolean;
}
export const UrlTableComponent = ({
    url,
    className,
    displayText,
    onClick,
    openInNewTab = true,
    style,
    withIcon,
}: IUrlTableComponent) => {
    const { t } = useTranslation();

    const _onClick = () => !openInNewTab && onClick && onClick();

    return (
        <a
            href={openInNewTab ? url : undefined}
            className={`text-blueMainText h-full flex hover:text-primaryBlue500 underline items-center ${className}`}
            target="_blank"
            onClick={_onClick}
            style={style}
        >
            {displayText ?? t('@open.in.new.tab', { defaultValue: 'Open in new tab' })}
            {withIcon && (
                <div className="pl-1 hover:text-primaryBlue500">
                    <GjirafaIcon name="Redirect" size={20} />
                </div>
            )}
        </a>
    );
};
