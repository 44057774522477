import { CSSProperties, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export enum ModalVisibleType {
    hovered = 'hovered',
    clicked = 'clicked',
}

export interface InfoPopupModalProps {
    infoText?: string;
    infoLink?: string;
    overlayClassName?: string;
    isVisible: boolean;
    onHide: () => any;
    visibleType: ModalVisibleType | '';
    modalStyle?: CSSProperties;
    onMouseOverModal?: (e: any) => any;
    getModalHeight?: (height: number) => any;
}

export const InfoPopupModal: React.FC<InfoPopupModalProps> = ({
    infoLink,
    infoText,
    isVisible,
    visibleType,
    modalStyle,
    onHide,
    onMouseOverModal,
    getModalHeight,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isVisible) {
            if (childRef.current) {
                const className = 'opacity-100 duration-300 delay-200';
                childRef.current.className += className;
            }
            getModalHeight && getModalHeight(childRef.current?.getBoundingClientRect().height || 0);
        }
    }, [isVisible]);

    if (!isVisible) {
        return null;
    }

    return createPortal(
        <div
            ref={containerRef}
            className={`${
                visibleType === ModalVisibleType.hovered
                    ? ''
                    : 'flex flex-col h-screen w-full inset-0 z-50 fixed border bg-mainOverlay justify-center items-center bg-opacity-50'
            }`}
            onClick={e => {
                e.stopPropagation();
                onHide();
            }}
        >
            <div
                onMouseOver={onMouseOverModal}
                onMouseLeave={() => {
                    if (visibleType === ModalVisibleType.hovered) {
                        onHide();
                    }
                }}
                className="h-auto bg-white base-card-style border border-mainBorder dropdown-shadow fixed z-50 transition-all duration-300 delay-75 opacity-0"
                style={modalStyle}
                ref={childRef}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <div className="flex flex-col p-4 h-full justify-between">
                    <span className="text-base text-secondaryText whitespace-pre-wrap">{infoText}</span>
                    {infoLink && (
                        <div className="w-full flex justify-start">
                            <a
                                className="mt-4 text-sm py-2 font-semibold text-white rounded flex justify-center items-center border-mainBlue border w-40 bg-mainBlue"
                                href={infoLink}
                                target="_blank"
                            >
                                LEARN MORE
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>,
        document.body
    );
};
