import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from '../../../components/ui';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
/**
 * Components
 */
import { HeaderForCreatePages } from '../../../components/common';
import DynamicInputsForm, { SingleItemInputsType } from '../../../components/common/dynamic-inputs/DynamicInputsForm';
import { InputField, SelectField, SwitchInput } from '../../../components/inputs';
import { LoadingModal } from '../../../components/modals';
import { ADMIN_ALGORITHMS_PATH, dataTypesOptions, inputTypeOptions } from '../../../constants';
/**
 * hooks - axios - utils
 */
import { useCurrentWidth } from '../../../hooks';
/**
 * models - constants
 */
import {
    AlgorithmDetailsResponseModel,
    AlgorithmGroupDetailsResponseModel,
    AlgorithmGroupListResponseModel,
    AlgorithmGroupsListModel,
    ExternalSourceTypeListResponseModel,
    SelectOptionType,
} from '../../../models';
import { selectors as externalSourceSelectors } from '../../../redux/thunk/app/externalSourceTypesThunk';
import axios from '../../../services/axios';
import { formatArrayForSelectOptions, getFormattedQuery } from '../../../utils';
import { AdminFooterButtons } from '../components/AdminFooterButtons';
import DynamicOutputsForm, { SingleOutputItemType } from './components/DynamicOutputsForm';

type FieldValuesType = {
    name: string;
    categoryName: string;
    isActive: boolean;
    groupId: any;
    hasArtifact?: boolean;
    url?: string;
    code: string;
};

export const AdminCreateAlgorithmPage = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const history = useHistory();
    const params: any = useParams();

    /**
     * constants - types
     */
    const isEditing = params?.id;
    type inputsRefType = React.ElementRef<typeof DynamicInputsForm>;
    type outputsRefType = React.ElementRef<typeof DynamicOutputsForm>;

    /**
     * refs
     */
    const inputsRef = useRef<inputsRefType>(null);
    const outputsRef = useRef<outputsRefType>(null);

    const { models: externalSources }: ExternalSourceTypeListResponseModel = useSelector(
        externalSourceSelectors.getResponse
    );
    const externalSourcesLoading = useSelector(externalSourceSelectors.getIsLoading);

    /**
     * Local state
     */
    const [loadingModal, setLoadingModal] = useState(false);
    const [algorithmGroups, setAlgorithmGroups] = useState<AlgorithmGroupsListModel[]>([]);
    const [fieldValues, setFieldValues] = useState<FieldValuesType>({
        name: '',
        categoryName: '',
        isActive: false,
        groupId: null,
        hasArtifact: false,
        url: '',
        code: '',
    });

    useEffect(() => {
        fetchAlgorithmGroups();
    }, []);

    useEffect(() => {
        if (isEditing && !externalSourcesLoading) {
            const id = params?.id;
            fetchAlgorithmDetails(id);
        }
    }, [params, externalSourcesLoading]);

    const fetchAlgorithmDetails = async id => {
        try {
            setLoadingModal(true);
            const query = {
                id,
            };
            const response: AlgorithmDetailsResponseModel = await axios.get(`/algorithm?${getFormattedQuery(query)}`);
            mapDetailsInState(response);
        } catch (err: any) {
        } finally {
            setLoadingModal(false);
        }
    };

    const onChangeInputs = (key, value) => {
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const fetchAlgorithmGroups = async () => {
        try {
            const response: AlgorithmGroupListResponseModel = await axios.get(
                `/algorithmGroup/list?${getFormattedQuery({ page: -1 })}`
            );
            setAlgorithmGroups(response.models);
        } catch (err: any) {}
    };

    const mapDetailsInState = async (response: AlgorithmDetailsResponseModel) => {
        const {
            model: {
                url,
                inputs: _inputs,
                name,
                outputs: _outputs,
                categoryName,
                groupId: _groupId,
                isActive,
                hasArtifact,
                code,
            },
        } = response;

        const query = {
            id: _groupId,
        };

        const { model }: AlgorithmGroupDetailsResponseModel = await axios.get(
            `/algorithmGroup?${getFormattedQuery(query)}`
        );
        const groupId: SelectOptionType = {
            label: model.name,
            value: model.id?.toString(),
        };

        setFieldValues({
            name,
            groupId,
            isActive,
            categoryName,
            url,
            hasArtifact,
            code,
        });

        let outputs: SingleOutputItemType[] = _outputs.map((x, i) => ({
            _id: i + 1,
            dataTypeId: x.dataTypeId,
            id: x.id,
            name: x.name,
        }));
        let inputs: SingleItemInputsType[] = _inputs.map((_input, i) => ({
            ..._input,
            dataTypeId: dataTypesOptions.find(_dataTypeOption => _input.dataTypeId === _dataTypeOption.value) || null,
            _id: i + 1,
            options: _input.options.map((_inputOption, i) => ({
                id: _inputOption.id || 0,
                _id: i + 1,
                ..._inputOption,
            })),
            inputType: inputTypeOptions.find(_inputTypeOption => _input.inputType === _inputTypeOption.value) || null,
            externalSourceTypeId: formatArrayForSelectOptions(externalSources, 'title', 'id').find(
                (_externalSource: any) => _externalSource?.value === _input.externalSourceTypeId?.toString()
            ),
        }));

        if (inputsRef.current?.setInputs) {
            inputsRef.current?.setInputs(inputs);
        }
        if (outputsRef.current?.setOutputs) {
            outputsRef.current?.setOutputs(outputs);
        }
    };

    const handleCreate = async (save?: boolean) => {
        try {
            setLoadingModal(true);

            let inputs: SingleItemInputsType[] = inputsRef.current?.getInputs() || [];
            let outputs: SingleOutputItemType[] = outputsRef.current?.getOutputs() || [];
            const { isActive, name, categoryName, groupId, hasArtifact, url, code } = fieldValues;
            let payload: {
                id: number;
                name: string;
                isActive: boolean;
                groupId: number;
                hasArtifact?: boolean;
                categoryName: string;
                url?: string;
                inputs: any[];
                outputs: any[];
                code: string;
            } = {
                id: isEditing ? params?.id : 0,
                name,
                isActive,
                categoryName,
                hasArtifact,
                url,
                code,
                groupId: groupId?.value,
                inputs: inputs.map(x => ({
                    id: x.id,
                    dataTypeId: x.dataTypeId?.value,
                    defaultValue: x.defaultValue,
                    inputType: x.inputType?.value || '',
                    name: x.name,
                    options: x.options.map(x => ({
                        label: x.label,
                        value: x.value,
                        id: x.id,
                    })),
                    required: x.required,
                    orderNumber: x.orderNumber,
                    isColumn: x.isColumn,
                    conditionalId: x.conditionalId || null,
                    conditionalValue: x.conditionalValue,
                    infoText: x.infoText,
                    infoLink: x.infoLink,
                    externalSourceTypeId: x?.externalSourceTypeId?.value || null,
                    isRepeatable: x.isRepeatable,
                })),
                outputs: outputs.map(x => ({
                    id: x.id,
                    name: x.name,
                    dataTypeId: x.dataTypeId,
                })),
            };

            let res: AlgorithmDetailsResponseModel = await axios.post('/algorithm', payload);
            if (save) {
                toast.success(`Algorithm Saved Successfully!`);
                if (isEditing) mapDetailsInState(res);
                else history.push(`/admin/algorithms/edit-algorithm/${res.model.id}`);
            } else {
                toast.success(`Algorithm ${isEditing ? 'Edited ' : 'Created'} Successfully!`);
                history.push(ADMIN_ALGORITHMS_PATH);
            }
        } catch (err: any) {
            console.log({ err });
        } finally {
            setLoadingModal(false);
        }
    };
    const handleSubmit = async e => {
        e.preventDefault();
        handleCreate();
    };

    return (
        <PageAnimationContainer>
            <div>
                <form className="flex flex-col w-full items-center pb-4" onSubmit={handleSubmit}>
                    {loadingModal && <LoadingModal isVisible />}
                    <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                    <div className="flex flex-col" style={{ width: currentWidth < 900 ? '100%' : 546 }}>
                        <InputField
                            name="algorithmName"
                            label="Name"
                            className="w-full"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('name', e.target.value)}
                            value={fieldValues.name}
                            required
                        />
                        <InputField
                            name="algorithmCode"
                            label="Code"
                            placeholder={t('@common.write.here')}
                            className="mt-4"
                            onChange={e => onChangeInputs('code', e.target.value)}
                            value={fieldValues.code}
                        />
                        <InputField
                            name="algorithmUrl"
                            label="Url"
                            className="w-full mt-4"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('url', e.target.value)}
                            value={fieldValues.url}
                            required
                        />
                        <SelectField
                            options={formatArrayForSelectOptions(algorithmGroups, 'name', 'id')}
                            className="mt-4"
                            label="Algorithm Group"
                            placeholder={t('@common.choose')}
                            onChange={item => onChangeInputs('groupId', item)}
                            value={fieldValues.groupId}
                            required
                        />
                        <InputField
                            name="categoryName"
                            label="Category Name"
                            placeholder={t('@common.write.here')}
                            className="mt-4"
                            onChange={e => onChangeInputs('categoryName', e.target.value)}
                            value={fieldValues.categoryName}
                            required
                        />
                        <div className="flex w-full justify-start items-center">
                            <SwitchInput
                                value={fieldValues.isActive}
                                onChange={val => onChangeInputs('isActive', val)}
                                label={t('@common.is-active')}
                                className="m-3"
                            />
                            <SwitchInput
                                value={!!fieldValues.hasArtifact}
                                onChange={val => onChangeInputs('hasArtifact', val)}
                                label="Has Artifact"
                                className="m-3"
                            />
                        </div>
                    </div>

                    <HeaderForCreatePages hideIcon title="Inputs" />
                    <DynamicInputsForm ref={inputsRef} isEditing={isEditing} />

                    <HeaderForCreatePages hideIcon title="Outputs" />
                    <DynamicOutputsForm isEditing={isEditing} ref={outputsRef} />

                    <HeaderForCreatePages hideIcon title="" />

                    <AdminFooterButtons
                        submitTitle={isEditing ? 'Edit Algorithm' : 'Create Algorithm'}
                        onClickSave={() => handleCreate(true)}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
