import axios from 'axios';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOptionType } from '../../models';
import { InputField, SelectField } from '../inputs';

export interface BillingAddressFormProps {
    className?: string;
    style?: CSSProperties;
    formValues?: AddressFormInputsType;
    onChangeFormInputs?: (key: string, value: any) => any;
    areFieldsRequired?: boolean;
    errors?: any;
    disableInputs?: boolean;
}

export type AddressFormInputsType = {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    zipCode: string;
    country: string;
    region?: string;
};

export const onValidateAddressForm = (formValues: AddressFormInputsType, setErrors: React.Dispatch<any>) => {
    let isValid = true;
    let _errors: any = {};

    if (!formValues.addressLine1) {
        isValid = false;
        _errors.addressLine1 = 'Address is a required field!';
    }

    if (!formValues.country) {
        isValid = false;
        _errors.country = 'Country is required!';
    }

    if (!formValues.city) {
        isValid = false;
        _errors.city = 'City is required!';
    }

    if (!formValues.zipCode) {
        isValid = false;
        _errors.zipCode = 'ZIP/Postal code is required!';
    }

    setErrors(_errors);
    return isValid;
};

export const BillingAddressForm: React.FC<BillingAddressFormProps> = ({
    className,
    style,
    formValues,
    onChangeFormInputs,
    errors,
    areFieldsRequired = true,
    disableInputs,
}) => {
    const { t } = useTranslation();

    const [countries, setCountries] = useState<SelectOptionType[]>([]);
    const [countriesLoading, setCountriesLoading] = useState(false);

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = async () => {
        setCountriesLoading(true);
        try {
            let response: { name: string; alpha2Code: string }[] = (
                await axios.get('https://restcountries.com/v2/all?fields=name,alpha2Code')
            ).data;
            setCountries(response.map(x => ({ label: x.name, value: x.alpha2Code })));
            setCountriesLoading(false);
        } catch (err) {
            setCountriesLoading(false);
        }
    };

    const onChange = (key: string, value: any) => {
        onChangeFormInputs && onChangeFormInputs(key, value);
    };

    const countryValue = countries.find(x => x.value === formValues?.country) || null;
    const commonClassName = 'mt-4';
    return (
        <div style={style} className={`flex flex-col w-full ${className}`}>
            <InputField
                disabled={disableInputs}
                name="addressLine1"
                required={areFieldsRequired}
                label={t('address.line', { number: 1 })}
                placeholder={t('enter.address.line', { number: 1 })}
                value={formValues?.addressLine1}
                errorMessage={errors?.addressLine1}
                onChange={e => onChange('addressLine1', e.target.value)}
                className={commonClassName}
                autoComplete="address-line1"
            />
            <InputField
                disabled={disableInputs}
                name="addressLine2"
                label={t('address.line', { number: 2 })}
                placeholder={t('enter.address.line', { number: 2 })}
                value={formValues?.addressLine2}
                errorMessage={errors?.addressLine2}
                onChange={e => onChange('addressLine2', e.target.value)}
                className={commonClassName}
                autoComplete="address-line2"
            />
            <SelectField
                disabled={disableInputs}
                required={areFieldsRequired}
                label={t('country')}
                placeholder={t('@common.select')}
                errorMessage={errors?.country}
                onChange={item => onChange('country', item?.value)}
                options={countries}
                value={countryValue}
                className={commonClassName}
                isLoading={countriesLoading}
            />
            <InputField
                disabled={disableInputs}
                required={areFieldsRequired}
                label={t('city')}
                placeholder={t('@common.select')}
                errorMessage={errors?.city}
                onChange={e => onChange('city', e.target.value)}
                value={formValues?.city}
                className={commonClassName}
                autoComplete="address-level2"
                name="city"
            />
            <div className="flex w-full justify-between">
                <InputField
                    disabled={disableInputs}
                    name="state.or.province.or.region"
                    label={t('state.or.province.or.region')}
                    value={formValues?.region}
                    errorMessage={errors?.region}
                    onChange={e => onChange('region', e.target.value)}
                    className={`${commonClassName} mr-3`}
                    autoComplete="address-level1"
                />
                <InputField
                    disabled={disableInputs}
                    required={areFieldsRequired}
                    name="zip.or.postal.code"
                    label={t('zip.or.postal.code')}
                    value={formValues?.zipCode}
                    errorMessage={errors?.zipCode}
                    onChange={e => onChange('zipCode', e.target.value)}
                    className={`${commonClassName} ml-3`}
                    autoComplete="postal-code"
                />
            </div>
        </div>
    );
};
