import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RouteNotFound } from '../components/errors';
import {
    ACCOUNT_NOT_ACTIVE_PATH,
    ACCOUNT_SETTINGS_PATH,
    AIHUB_BASE_PATH,
    ARTICLE_RECOMMENDATION_ENGINE_PATH,
    ARTIFACTS_PATH,
    ARTIFACT_DETAILS_PATH,
    BILLING_PATH,
    CATALOGS_PATH,
    CATALOG_DETAILS_PATH,
    CHURN_PROPENSITY_ENGINE_PATH,
    CLASSIFIED_AD_RECOMMENDATION_ENGINE_PATH,
    CREATE_DATASET_PATH,
    CREATE_FEATURE_ENGINEERING_PATH,
    CREATE_FILTER_PATH,
    CREATE_LOOKALIKE_ENGINE_PATH,
    CREATE_MODEL_PATH,
    CREATE_PIPELINE_JOB_PATH,
    CREATE_PIPELINE_PATH,
    CREATE_PROPENSITY_ENGINE_PATH,
    CREATE_RECOMMENDATION_ENGINE_PATH,
    CREATE_TASK_PATH,
    CUSTOM_ENDPOINTS_DETAILS,
    CUSTOM_ENDPOINTS_PATH,
    DATASETS_PATH,
    DATASET_DETAILS_PATH,
    DATA_SOURCES_PATH,
    DATA_SOURCE_DETAILS_PATH,
    DEPLOYMENTS_PATH,
    EDIT_DATASET_PATH,
    EDIT_FEATURE_ENGINEERING_PATH,
    EDIT_FILTER_PATH,
    EDIT_LOOKALIKE_ENGINE_PATH,
    EDIT_MODEL_PATH,
    EDIT_PIPELINE_JOB_PATH,
    EDIT_PIPELINE_PATH,
    EDIT_RECOMMENDATION_ENGINE_PATH,
    EDIT_TASK_PATH,
    FEATURE_ENGINEERING_PATH,
    FILTERS_PATH,
    INFORMATION_BASE_PATH,
    INFORMATION_PATH,
    LOOKALIKE_ENGINE_DETAILS_PAGE,
    LOOKALIKE_ENGINE_PATH,
    MODELS_PATH,
    NOTEBOOK_SERVERS_PATH,
    NOTIFICATIONS_PATH,
    NOT_FOUND_PATH,
    PIPELINES_PATH,
    PIPELINE_DETAILS_PATH,
    PIPELINE_JOB_DETAILS_PATH,
    PIPELINE_JOB_RUN_DETAILS_PATH,
    PRODUCT_RECOMMENDATION_ENGINE_PATH,
    PROPENSITY_ENGINE_SUBSCRIBE_PATH,
    PURCHASE_PROPENSITY_ENGINE_PATH,
    RECOMMENDATION_ENGINE_DETAILS_PATH,
    STORAGE_DYNAMIC_PATH,
    STORAGE_PATH,
    SUBSCRIBE_PROPENSITY_ENGINE_PATH,
    TASKS_PATH,
    TASK_DETAILS_PATH,
    VIDEO_RECOMMENDATION_ENGINE_PATH,
} from '../constants';
import { MainLayout } from '../containers';
import { AccountNotActivePage, NotificationsPage } from '../pages';
import {
    ArticleRecommendationEnginePage,
    ChurnPropensityEnginePage,
    ClassifiedAdRecommendationEnginePage,
    CreateLookalikeEnginePage,
    CreatePropensityEnginePage,
    CreateRecommendationEnginePage,
    LikelihoodToSubscribePage,
    LookalikeEngineDetailsPage,
    LookalikeEnginePage,
    ProductRecommendationEnginePage,
    PurchasePropensityEnginePage,
    RecommendationEngineDetailsPage,
    SubscribePropensityEnginePage,
    VideoRecommendationEnginePage,
} from '../pages/ai-engine';
import {
    CreatePipelineJobPage,
    CreatePipelinePage,
    CreateTaskPage,
    CustomEndpointDetailsPage,
    CustomEndpointsPage,
    NotebookServersPage,
    PipelineDetailsPage,
    PipelineJobDetailsPage,
    PipelineJobRunDetailsPage,
    PipelinesPage,
    TaskDetailsPage,
    TasksPage,
} from '../pages/lab';

import {
    ArtifactDetailsPage,
    ArtifactsPage,
    CatalogsPage,
    CreateFilterPage,
    DataSourceDetailsPage,
    DataSourcesPage,
    FiltersPage,
    StoragePage,
} from '../pages/data';

import { BillingPage, SettingsPage } from '../pages/settings';
import {
    CreateDatasetPage,
    CreateFeatureEngineeringPage,
    CreateModelPage,
    DatasetDetailsPage,
    DatasetPage,
    DeploymentsPage,
    FeatureEngineeringPage,
    ModelsPage,
    WorkflowInformationPage,
} from '../pages/workflow';

import { CatalogDetailsPage } from '../pages/data/catalog/CatalogDetailsPage';
import { actions as languagesActions } from '../redux/thunk/app/language/languagesThunk';
import { queryParams } from '../utils';

export const MainRoutes: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        window.bsk('time');
        window.bsk('event', 'pageview');
    }, [location]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(languagesActions.request());
    }, []);

    return (
        <Route
            render={props => {
                const location = props.location;
                return (
                    <MainLayout>
                        <Switch location={location}>
                            <Route exact path={ACCOUNT_NOT_ACTIVE_PATH} component={AccountNotActivePage} />
                            <Route exact path={AIHUB_BASE_PATH}>
                                <Redirect to={ARTIFACTS_PATH + queryParams.formatForNavigation()} />
                            </Route>
                            <Route exact path={NOTIFICATIONS_PATH} component={NotificationsPage} />
                            {/* --------- DataSource --------- */}
                            <Route exact path={DATA_SOURCES_PATH} component={DataSourcesPage} />
                            <Route exact path={DATA_SOURCE_DETAILS_PATH} component={DataSourceDetailsPage} />
                            <Route exact path={[STORAGE_PATH, STORAGE_DYNAMIC_PATH]} component={StoragePage} />
                            <Route exact path={CATALOGS_PATH} component={CatalogsPage} />
                            <Route exact path={CATALOG_DETAILS_PATH} component={CatalogDetailsPage} />
                            {/* --------- Filters --------- */}
                            <Route exact path={FILTERS_PATH} component={FiltersPage} />
                            <Route exact path={[CREATE_FILTER_PATH, EDIT_FILTER_PATH]} component={CreateFilterPage} />
                            {/* --------- Artifacts --------- */}
                            <Route exact path={ARTIFACTS_PATH} component={ArtifactsPage} />
                            <Route exact path={ARTIFACT_DETAILS_PATH} component={ArtifactDetailsPage} />
                            {/* --------- LAB --------- */}
                            {/* --------- Notebooks --------- */}
                            <Route exact path={NOTEBOOK_SERVERS_PATH} component={NotebookServersPage} />
                            {/* --------- CustomEndpoints --------- */}
                            <Route exact path={CUSTOM_ENDPOINTS_PATH} component={CustomEndpointsPage} />
                            <Route exact path={CUSTOM_ENDPOINTS_DETAILS} component={CustomEndpointDetailsPage} />
                            {/* --------- Tasks --------- */}
                            <Route exact path={TASKS_PATH} component={TasksPage} />3
                            <Route exact path={[CREATE_TASK_PATH, EDIT_TASK_PATH]} component={CreateTaskPage} />
                            <Route exact path={TASK_DETAILS_PATH} component={TaskDetailsPage} />
                            {/* --------- Pipelines --------- */}
                            <Route exact path={PIPELINES_PATH} component={PipelinesPage} />
                            <Route exact path={PIPELINE_DETAILS_PATH} component={PipelineDetailsPage} />
                            <Route
                                exact
                                path={[CREATE_PIPELINE_PATH, EDIT_PIPELINE_PATH]}
                                component={CreatePipelinePage}
                            />
                            {/* --------- PipelineJobs --------- */}
                            <Route exact path={PIPELINE_JOB_DETAILS_PATH} component={PipelineJobDetailsPage} />
                            <Route
                                exact
                                path={[CREATE_PIPELINE_JOB_PATH, EDIT_PIPELINE_JOB_PATH]}
                                component={CreatePipelineJobPage}
                            />
                            <Route exact path={PIPELINE_JOB_RUN_DETAILS_PATH} component={PipelineJobRunDetailsPage} />
                            {/* --------- Settings + Billing  --------- */}
                            <Route exact path={ACCOUNT_SETTINGS_PATH} component={SettingsPage} />
                            <Route exact path={BILLING_PATH} component={BillingPage} />
                            <Route
                                exact
                                path={[INFORMATION_PATH, INFORMATION_BASE_PATH]}
                                component={WorkflowInformationPage}
                            />
                            {/* --------- Recommendation Engines --------- */}
                            <Route
                                exact
                                path={ARTICLE_RECOMMENDATION_ENGINE_PATH}
                                component={ArticleRecommendationEnginePage}
                            />
                            <Route
                                exact
                                path={VIDEO_RECOMMENDATION_ENGINE_PATH}
                                component={VideoRecommendationEnginePage}
                            />
                            <Route
                                exact
                                path={PRODUCT_RECOMMENDATION_ENGINE_PATH}
                                component={ProductRecommendationEnginePage}
                            />
                            <Route
                                exact
                                path={CLASSIFIED_AD_RECOMMENDATION_ENGINE_PATH}
                                component={ClassifiedAdRecommendationEnginePage}
                            />
                            <Route
                                exact
                                path={RECOMMENDATION_ENGINE_DETAILS_PATH}
                                component={RecommendationEngineDetailsPage}
                            />
                            <Route
                                exact
                                path={[CREATE_RECOMMENDATION_ENGINE_PATH, EDIT_RECOMMENDATION_ENGINE_PATH]}
                                component={CreateRecommendationEnginePage}
                            />
                            {/* --------- Propensity Engines --------- */}
                            <Route exact path={CHURN_PROPENSITY_ENGINE_PATH} component={ChurnPropensityEnginePage} />
                            <Route
                                exact
                                path={PURCHASE_PROPENSITY_ENGINE_PATH}
                                component={PurchasePropensityEnginePage}
                            />
                            <Route
                                exact
                                path={SUBSCRIBE_PROPENSITY_ENGINE_PATH}
                                component={SubscribePropensityEnginePage}
                            />
                            <Route exact path={CREATE_PROPENSITY_ENGINE_PATH} component={CreatePropensityEnginePage} />
                            <Route
                                exact
                                path={PROPENSITY_ENGINE_SUBSCRIBE_PATH}
                                component={LikelihoodToSubscribePage}
                            />
                            {/* --------- Lookalike engines --------- */}
                            <Route exact path={LOOKALIKE_ENGINE_PATH} component={LookalikeEnginePage} />
                            {/* <Route exact path={LOOKALIKE_ENGINE_PATH} component={_LookalikeEnginePage} /> */}
                            <Route
                                exact
                                path={[CREATE_LOOKALIKE_ENGINE_PATH, EDIT_LOOKALIKE_ENGINE_PATH]}
                                component={CreateLookalikeEnginePage}
                            />
                            <Route exact path={LOOKALIKE_ENGINE_DETAILS_PAGE} component={LookalikeEngineDetailsPage} />
                            {/* --------------- Datasets -------------- */}
                            <Route exact path={DATASETS_PATH} component={DatasetPage} />
                            <Route exact path={DATASET_DETAILS_PATH} component={DatasetDetailsPage} />
                            <Route
                                exact
                                path={[CREATE_DATASET_PATH, EDIT_DATASET_PATH]}
                                component={CreateDatasetPage}
                            />
                            {/* ------------ Models --------------- */}
                            <Route exact path={MODELS_PATH} component={ModelsPage} />
                            <Route exact path={[CREATE_MODEL_PATH, EDIT_MODEL_PATH]} component={CreateModelPage} />
                            {/* ----------- Feature Engineering -------- */}
                            <Route exact path={FEATURE_ENGINEERING_PATH} component={FeatureEngineeringPage} />
                            <Route
                                exact
                                path={[CREATE_FEATURE_ENGINEERING_PATH, EDIT_FEATURE_ENGINEERING_PATH]}
                                component={CreateFeatureEngineeringPage}
                            />
                            <Route exact path={DEPLOYMENTS_PATH} component={DeploymentsPage} />
                            <Route path={NOT_FOUND_PATH} component={RouteNotFound} />
                            <Redirect to={NOT_FOUND_PATH + queryParams.formatForNavigation()} />
                        </Switch>
                    </MainLayout>
                );
            }}
        />
    );
};
