import { actionCreator } from '../../../../../utils';
import { PropensityEngineProductTypeListResponseModel } from '../../../../../models';
import { InitialStateType } from '../../../../../models';
import { RootState } from '../../../rootReducer';
import { Dispatch } from 'redux';
import axios from '../../../../../services/axios';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/engines/propensity/propensityEngineProductTypesThunk/REQUEST',
    SUCCESS: '@app/engines/propensity/propensityEngineProductTypesThunk/SUCCESS',
    FAILURE: '@app/engines/propensity/propensityEngineProductTypesThunk/FAILURE',
};

/**
 * Initial state
 */
const initialState: InitialStateType<PropensityEngineProductTypeListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.propensityEngineProductTypes.isLoading,
    getResponse: (state: RootState) => state.app.propensityEngineProductTypes.response,
    getErrorMessage: (state: RootState) => state.app.propensityEngineProductTypes.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (
    state: InitialStateType<PropensityEngineProductTypeListResponseModel> = initialState,
    { type, payload }
) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const response: PropensityEngineProductTypeListResponseModel = await axios.get(
                    `/propensityEngineProductType/list?page=-1`
                );
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
