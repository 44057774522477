import Tippy from '@tippyjs/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Range } from 'react-range';
import 'tippy.js/dist/tippy.css';

interface ITimeDecayInputProps {
    onChange: (value: number) => void;
    value: number;
}

export const TimeDecayInput = ({ onChange, value }: ITimeDecayInputProps) => {
    const { t } = useTranslation();
    const timeout = useRef<NodeJS.Timeout>();
    const [_value, _setValue] = useState(value);

    useEffect(() => {
        _setValue(value);
    }, [value]);

    const _onChange = ([value]: number[]) => {
        _setValue(value);

        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => onChange(value), 500);
    };

    const renderVerticalLines = () => {
        let lines = [1, 2, 3, 4];
        return (
            <div className="flex absolute w-full -top-2.5">
                {lines.map(x => (
                    <div
                        className={`w-[1px] h-6 bg-primaryBlue100 border-primaryBlue100 border-r rounded-t rounded-b ml-[20%]`}
                    />
                ))}
            </div>
        );
    };

    const tooltipText = useMemo(() => {
        let range = Math.floor(_value / 20) * 20;
        return t(`@time.decay.range-${range}`);
    }, [_value, t]);

    return (
        <div className="flex flex-col mt-6">
            <label className="font-medium text-sm text-blueMainText">
                {t('@value.range', { defaultValue: 'Value Range' })}
            </label>
            <div className="flex justify-between w-full mt-[25px]">
                <label className="text-mainGrayText text-sm font-normal">
                    {t('@relevant', { defaultValue: 'Relevant' })}
                </label>
                <label className="text-mainGrayText text-sm font-normal">
                    {t('@recent', { defaultValue: 'Recent' })}
                </label>
            </div>

            <div className="w-full px-7">
                <div className="flex w-full mt-5 relative">
                    {renderVerticalLines()}
                    <Range
                        step={0.1}
                        min={0}
                        max={100}
                        values={[_value]}
                        onChange={_onChange}
                        renderTrack={({ props, children }) => (
                            <div className=" bg-primaryBlue100 rounded h-[5px] w-full" {...props} style={props.style}>
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <Tippy content={tooltipText} followCursor trigger="click mouseenter" delay={0}>
                                <div className="h-5 w-5 rounded-full bg-mainBlue outline-none" {...props} />
                            </Tippy>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
