import { Dispatch } from 'redux';
import { UserDetailsResponseModel, UserDetailsStateType } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator, timeout } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/user/userThunk/REQUEST',
    SUCCESS: '@app/user/userThunk/SUCCESS',
    FAILURE: '@app/user/userThunk/FAILURE',
    INCREMENT_NOTIFICATION_COUNT: '@app/user/userThunk/INCREMENT_NOTIFICATION_COUNT',
    RESET_NOTIFICATION_COUNT: '@app/user/userThunk/RESET_NOTIFICATION_COUNT',
    ANIMATE_ICON: '@app/notification/notificationsThunk/ANIMATE_ICON',
};

/**
 * Initial state
 */
const initialState: UserDetailsStateType = {
    isLoading: false,
    errorMessage: '',
    isAnimating: false,
    response: {
        success: false,
        message: '',
        model: {
            id: 0,
            email: '',
            fullName: '',
            imageUrl: '',
            isAdmin: false,
            defaultAccount: { id: 0, imageUrl: '', isAdmin: false, name: '', users: [], biskoOrganizationId: 0 },
            notificationCount: 0,
        },
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.user.isLoading,
    getResponse: (state: RootState) => state.app.user.response,
    getErrorMessage: (state: RootState) => state.app.user.errorMessage,
    getIsAnimating: (state: RootState) => state.app.user.isAnimating,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, INCREMENT_NOTIFICATION_COUNT, ANIMATE_ICON, RESET_NOTIFICATION_COUNT } = actionTypes;

const Reducer = (state: UserDetailsStateType = initialState, { type, payload }) => {
    switch (type) {
        case RESET_NOTIFICATION_COUNT:
            return {
                ...state,
                response: {
                    ...state.response,
                    model: {
                        ...state.response.model,
                        notificationCount: 0,
                    },
                },
            };
        case INCREMENT_NOTIFICATION_COUNT:
            return {
                ...state,
                response: {
                    ...state.response,
                    model: {
                        ...state.response.model,
                        notificationCount: state.response.model.notificationCount + 1,
                    },
                },
            };
        case ANIMATE_ICON:
            return {
                ...state,
                isAnimating: payload,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));
                const response: UserDetailsResponseModel = await axios.get('/user');
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
                console.log({ err });
            }
        };
    },
    onReceivedNotification: function () {
        return async dispatch => {
            dispatch(actionCreator(INCREMENT_NOTIFICATION_COUNT));

            dispatch(actionCreator(ANIMATE_ICON, true));
            await timeout(2500);
            dispatch(actionCreator(ANIMATE_ICON, false));
        };
    },
    resetNotificationCount: () => actionCreator(RESET_NOTIFICATION_COUNT),
    updateResponse: (response: UserDetailsResponseModel) => actionCreator(SUCCESS, response),
};
