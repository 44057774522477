import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { roles } from '../../constants';
import { useAccountId } from '../../hooks';
import { AccountUserDetailsResponseModel, AccountUserListModel } from '../../models';
import axios from '../../services/axios';
import { UserAccountFormSchema } from '../../validations';
import { InputField, SelectField } from '../inputs';
import { SideModal } from '../SideModal';

export interface AddUserModalProps {
    isVisible: boolean;
    onHide: () => any;
    onSuccess: (isEditing: boolean) => any;
    userForEdit?: AccountUserListModel | null;
    isEditing?: boolean;
}

export const AddNewUserModal: React.FC<AddUserModalProps> = ({
    isVisible,
    onHide,
    userForEdit,
    isEditing,
    onSuccess,
}) => {
    const { t } = useTranslation();
    const accountId = useAccountId();

    const [isFormLoading, setIsFormLoading] = useState(false);

    const { handleSubmit, setValue, control } = useForm({
        resolver: yupResolver(UserAccountFormSchema),
        reValidateMode: 'onChange',
        shouldUnregister: false,
        mode: 'onBlur',
    });

    useEffect(() => {
        if (isEditing && userForEdit) {
            fetchDetails();
        }
    }, [userForEdit, isEditing]);

    const fetchDetails = async () => {
        try {
            const response: AccountUserDetailsResponseModel = await axios.get(
                `/accountUser?accountId=${accountId}&id=${userForEdit?.id}`
            );
            setValue('email', userForEdit?.user);
            setValue('role', response.model.roleId.toString());
        } catch (err) {}
    };

    const onSubmit = async data => {
        try {
            setIsFormLoading(true);
            const { email, role } = data;
            const payload = {
                id: isEditing ? userForEdit?.id : 0,
                accountId,
                roleId: role,
                email: isEditing ? userForEdit?.user : email,
            };
            await axios.post('/accountUser', payload);
            setIsFormLoading(false);
            onSuccess(!!isEditing);
            onHide();
        } catch (err) {
            setIsFormLoading(false);
        }
    };

    const { buttonText, title, formId } = useMemo(() => {
        return {
            title: isEditing ? t('@pages.settings.edit-user') : t('@pages.settings.add-user'),
            buttonText: isEditing ? t('@common.edit') : t('@common.add'),
            formId: isEditing ? 'EditUser' : 'AddUser',
        };
    }, []);

    return (
        <>
            <SideModal
                loading={isFormLoading}
                title={title}
                buttonText={buttonText}
                onHide={onHide}
                isVisible={isVisible}
                formId={formId}
            >
                <form id={formId} className="py-6 px-4" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="email"
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <InputField
                                label={t('@common.email')}
                                placeholder={t('@common.write.here')}
                                required
                                name={name}
                                errorMessage={error?.message}
                                onChange={onChange}
                                value={value}
                                disabled={isEditing}
                                readOnly={isEditing}
                            />
                        )}
                    />

                    <Controller
                        name="role"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <SelectField
                                required
                                className="mt-6"
                                label={t('@common.role')}
                                placeholder={t('@common.select')}
                                options={roles}
                                errorMessage={error?.message}
                                onChange={el => onChange(el ? el.value : '')}
                                value={roles.find(x => x.value === value) || null}
                            />
                        )}
                    />
                </form>
            </SideModal>
        </>
    );
};
