import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RunStatusComponent } from '../../../components/common';
import {
    NoDataComponentProps,
    NoDataImagesType,
    Table,
    TableActionRowProps,
    TableFilters,
    TableMobile,
} from '../../../components/tables';
import { toast } from '../../../components/ui';
/**
 * Constants
 */
import { breakpoints, CREATE_FEATURE_ENGINEERING_PATH, statusOptionTypes } from '../../../constants';
import { useAccountId, useCurrentWidth } from '../../../hooks';
import { FlowListModel, FlowListResponseModel, SelectOptionType } from '../../../models';
import {
    actions as featureEngineeringFlowsActions,
    selectors as featureEngineeringFlowsSelectors,
} from '../../../redux/thunk/app/flow/flowsThunk';
import { actions as flowInstanceTypesActions } from '../../../redux/thunk/app/flow/flowInstanceTypesThunk';
/**
 * Services
 */
import axios from '../../../services/axios';
import { formatArrayForSelectOptions, getWidth, showFiltersHeader } from '../../../utils';
import { SelectField } from '../../../components/inputs';

export const FeatureEngineeringPage: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const currentWidth = useCurrentWidth();
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);

    /**
     * Selectors
     */
    const flowsResponse: FlowListResponseModel = useSelector(featureEngineeringFlowsSelectors.getResponse);
    const flowsLoading = useSelector(featureEngineeringFlowsSelectors.getIsLoading);
    const initLoading = useSelector(featureEngineeringFlowsSelectors.getInitialLoading);

    const requestFlows = (query, resetQuery?: boolean) => {
        dispatch(featureEngineeringFlowsActions.request({ accountId, ...query }, resetQuery));
    };
    /**
     * Effects
     */
    useEffect(() => {
        dispatch(flowInstanceTypesActions.request());
        requestFlows({
            page: 0,
            searchQuery: '',
        });
    }, [accountId]);

    useEffect(() => {
        return () => {
            dispatch(featureEngineeringFlowsActions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        if (flowsResponse.query?.status) {
            setStatusTypeSelected(flowsResponse.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [flowsResponse.query]);

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestFlows({ page: 0, status: status });
    };

    /**
     * Handle onClick events
     */
    const onClickCreate = () => {
        history.push(CREATE_FEATURE_ENGINEERING_PATH + location.search);
    };

    const onClickEdit = row => {
        history.push(`/workflow/feature-engineering/edit-flow/${row.id}${window.location.search}`);
    };

    const onClickRun = async row => {
        try {
            await axios.post('/flow/run', { id: row.id, accountId });
            toast.success(t('@flow.started.running', { defaultValue: 'Flow started running!' }));
            requestFlows({});
        } catch (err: any) {}
    };

    const onClickTerminate = async row => {
        try {
            await axios.post('/flow/terminate', { id: row.id, accountId });
            toast.success(t('@flow.started.terminating', { defaultValue: 'Flow started terminating!' }));
            requestFlows({});
        } catch (err: any) {}
    };

    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : flowsResponse?.currentPage || 0;
        !flowsLoading && requestFlows({ page });
    };

    /**
     * Handle Change event
     */
    const onPageChanged = page => requestFlows({ page });

    const paginationProps = useMemo(
        () => ({
            currentPage: flowsResponse.currentPage,
            totalItems: flowsResponse.totalItems,
            totalPages: flowsResponse.totalPages,
            pageItems: flowsResponse.models.length,
            onChangePage: onPageChanged,
        }),
        [flowsResponse]
    );
    const { tableActions, emptyDataProps } = useMemo<{
        tableActions: TableActionRowProps[];
        emptyDataProps: NoDataComponentProps;
    }>(() => {
        return {
            tableActions: [
                {
                    action: 'edit',
                    onClick: onClickEdit,
                },
                {
                    action: row => (row?.runStatus === 'Running' ? 'Terminate' : 'run'),
                    onClick: async (row: FlowListModel) =>
                        row?.runStatus === 'Running' ? onClickTerminate(row) : onClickRun(row),
                    displayName: (row: FlowListModel) => (row?.runStatus === 'Running' ? 'Terminate' : 'Run'),
                    disable: (row: FlowListModel) => row?.runStatus === 'Terminating',
                },
                {
                    action: 'delete',
                },
            ],
            emptyDataProps: {
                emptyText: t('@pages.workflow.no-feature.engineering-flows'),
                actionMessage: `+ ${t('@pages.workflow.create-new-feature.engineering')}`,
                imageType: NoDataImagesType.FeatureEngineering,
                onClickAction: onClickCreate,
            },
        };
    }, []);

    const commonTableProps = {
        showHeaderButtons: true,
        selectableRows: true,
        modelEndpoint: 'flow',
        requestList: requestFlows,
        loading: flowsLoading,
        response: flowsResponse,
        emptyDataProps,
        isFromSelectPopup: false,
        headerButtonProps: {
            onRefresh,
            onClickCreate,
        },
    };

    return (
        <div>
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    subHeader={showFiltersHeader(flowsResponse)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                dispatch(
                                    featureEngineeringFlowsActions.request({
                                        [key]: value,
                                    })
                                );
                            }}
                            response={flowsResponse}
                            onSearch={searchQuery => {
                                dispatch(
                                    featureEngineeringFlowsActions.request({
                                        searchQuery,
                                        page: 0,
                                    })
                                );
                            }}
                        >
                            <SelectField
                                disabled={false}
                                isClearable
                                label="Status"
                                className="m-2"
                                placeholder={t('@common.choose-status')}
                                style={{ width: 240 }}
                                onChange={onChangeStatusType}
                                options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                                value={selectedStatusType}
                                isLoading={flowsLoading}
                            />
                        </TableFilters>
                    }
                    pagination
                    paginationProps={paginationProps}
                    columns={[
                        {
                            name: t('@common.title'),
                            selector: row => row?.title ?? '',
                            sortable: true,
                        },
                        {
                            name: t('@common.run-status'),
                            selector: row => row?.runStatus ?? '',
                            sortable: true,
                            width: '140px',
                            cell: row => <RunStatusComponent row={row} />,
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={tableActions}
                />
            )}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="title"
                    initialLoading={initLoading}
                    columns={[
                        {
                            name: t('@common.title'),
                            selector: row => row?.title ?? '',
                        },
                        {
                            name: t('@common.run-status'),
                            selector: row => row?.runStatus ?? '',
                            cell: row => <RunStatusComponent row={row} />,
                        },
                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                        },
                    ]}
                    actions={tableActions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!flowsResponse.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
