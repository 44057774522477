import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
/**
 * Components
 */
import { CommonTableProps } from '.';
import { breakpoints, CREATE_FILTER_PATH } from '../../constants';
import { useAccountId, useCurrentWidth } from '../../hooks';
import {
    FilterListModel,
    FilterListResponseModel,
    SchemaTypeListResponseModel,
    UserDetailsResponseModel,
} from '../../models';
/**
 * Redux
 */
import { actions as filterActions, selectors } from '../../redux/thunk/app/filter/filtersThunk';
import {
    actions as schemaTypesActions,
    selectors as schemaTypesSelectors,
} from '../../redux/thunk/app/schemaType/schemaTypesThunk';
import { selectors as userSelectors } from '../../redux/thunk/app/user/userThunk';
import { formatArrayForSelectOptions, getWidth, queryParams, showFiltersHeader } from '../../utils';
import { CreateButton } from '../buttons';
import { SelectField } from '../inputs';
import { NoDataImagesType, TableActionRowProps } from './components';
import { TableFilters } from './components/TableFilters';
import { Table } from './Table';
import { TableMobile } from './TableMobile';

export interface FiltersTableProps extends CommonTableProps {
    actions?: TableActionRowProps[];
    multiple?: boolean;
    selectPopupProps?: {
        onChangeSelectedRows?: (items: FilterListModel[]) => any;
    };
}

export const FiltersTable: FC<FiltersTableProps> = ({
    className,
    fetchDataOnMount,
    headerButtonProps,
    isFromSelectPopup,
    tablePopupProps,
    query,
    disableFilters,
    hideFilters,
    selectPopupProps,
    multiple,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * Redux Selector
     */
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);
    const response: FilterListResponseModel = useSelector(selectors.getResponse);
    const { models: schemaTypes }: SchemaTypeListResponseModel = useSelector(schemaTypesSelectors.getResponse);
    const schemaTypesLoading = useSelector(schemaTypesSelectors.getIsLoading);
    const userResponse: UserDetailsResponseModel = useSelector(userSelectors.getResponse);

    /**
     * Local State
     */
    const [filtersValues, setFiltersValues] = useState<any>({});
    const [selectedRows, setSelectedRows] = useState<FilterListModel[]>([]);

    const requestFilters = (payload: object) => {
        dispatch(filterActions.request({ accountId, ...payload }));
    };

    useEffect(() => {
        schemaTypes.length < 1 && !isFromSelectPopup && dispatch(schemaTypesActions.request());
        if (fetchDataOnMount) {
            requestFilters({ page: 0, searchQuery: '', schemaTypeId: null, ...query });
        }
    }, [accountId]);

    useEffect(() => {
        const { query } = response;
        if (query) {
            setFiltersValues({
                schemaTypeId: query?.schemaTypeId || null,
            });
        }
    }, [response.query]);

    const onPageChanged = page => dispatch(filterActions.request({ page }));

    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : response?.currentPage || 0;
        !loading && requestFilters({ page });
    };

    /**
     * onClick Events
     */
    const onClickCreate = () => {
        history.push(CREATE_FILTER_PATH + queryParams.formatForNavigation());
    };
    const onClickEdit = (row: FilterListModel) => {
        history.push(`/data/filters/edit-filter/${row.id}${window.location.search}`);
    };

    const onSelectRow = (row: FilterListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.title,
                value: row.id.toString(),
                ...row,
            });
        }
    };

    const onChangeFilters = (key: string, value: any) => {
        setFiltersValues(prev => ({ ...prev, [key]: value }));
        requestFilters({ [key]: value });
    };

    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.no-filters'),
            actionMessage: !isFromSelectPopup ? `+ ${t('@pages.create-new-filter')}` : '',
            imageType: NoDataImagesType.Filter,
            onClickAction: !isFromSelectPopup ? onClickCreate : () => {},
        }),
        [isFromSelectPopup]
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'edit',
                onClick: onClickEdit,
            },
            {
                action: 'delete',
            },
        ],
        []
    );
    const commonTableProps = {
        modelEndpoint: 'filter',
        selectableRows: true,
        requestList: requestFilters,
        showHeaderButtons: true,
        isFromSelectPopup,
        emptyDataProps,
        loading,
        response,
        headerButtonProps: {
            onRefresh,
            onClickCreate,
            showCreate: !isFromSelectPopup,
            showDelete: !isFromSelectPopup,
            ...headerButtonProps,
        },
    };

    return (
        <>
            <div className={`flex flex-col ${className}`}>
                {currentWidth > breakpoints.sm && (
                    <Table
                        {...commonTableProps}
                        shouldHideActions={(row: FilterListModel) => {
                            return !row.accountId && !userResponse.model.isAdmin;
                        }}
                        selectableRowsNoSelectAll={isFromSelectPopup}
                        selectableRowsSingle={isFromSelectPopup ? !multiple : false}
                        onSelectedRowsChange={({ selectedRows: items }) => {
                            setSelectedRows(items);
                        }}
                        subHeader={showFiltersHeader(response)}
                        headerComponent={
                            <TableFilters
                                onChangeQuery={(key, value) => {
                                    requestFilters({
                                        [key]: value,
                                    });
                                }}
                                response={response}
                                hideFilters={hideFilters}
                                onSearch={searchQuery => {
                                    requestFilters({ searchQuery, page: 0 });
                                }}
                            >
                                <SelectField
                                    disabled={disableFilters || isFromSelectPopup}
                                    label={t('@common.schema-type')}
                                    placeholder={t('@components.modals.choose-schema-type')}
                                    onChange={value => onChangeFilters('schemaTypeId', value)}
                                    style={{ width: 240 }}
                                    isClearable
                                    className="m-2"
                                    isLoading={schemaTypesLoading}
                                    value={filtersValues?.schemaTypeId}
                                    options={formatArrayForSelectOptions(schemaTypes)}
                                />
                            </TableFilters>
                        }
                        pagination
                        selectedRow={tablePopupProps && tablePopupProps.selectedItem}
                        onRowClicked={isFromSelectPopup ? onSelectRow : undefined}
                        paginationProps={paginationProps}
                        showHeader={false}
                        columns={[
                            {
                                selector: row => row?.title ?? '',
                                name: t('@common.title'),
                                sortable: true,
                                cell: row => <span>{row?.title}</span>,
                            },
                            {
                                selector: row => row?.schemaTypeName ?? '',
                                name: t('@common.schema-type'),
                                sortable: true,
                            },
                            {
                                name: t('@common.last-modified'),
                                width: '180px',
                                selector: row => row?.lastModified ?? '',
                                cell: row => <span>{row?.lastModified}</span>,
                                sortable: true,
                            },
                            {
                                name: t('@common.modified-by'),
                                selector: row => row?.modifiedBy ?? '',
                                width: '140px',
                                cell: row => <span>{row?.modifiedBy}</span>,
                            },
                        ]}
                        actions={isFromSelectPopup ? [] : tableActions}
                    />
                )}

                {currentWidth <= breakpoints.sm && (
                    <TableMobile
                        {...commonTableProps}
                        onSelectedItemsChange={items => {
                            setSelectedRows(items);
                        }}
                        mainKeyProperty="title"
                        initialLoading={initLoading}
                        onClickItem={onSelectRow}
                        selectedItem={tablePopupProps && tablePopupProps.selectedItem}
                        columns={[
                            {
                                selector: row => row?.title ?? '',
                                name: t('@common.title'),
                            },
                            {
                                selector: row => row?.schemaTypeName ?? '',
                                name: t('@common.schema'),
                                sortable: true,
                            },
                            {
                                selector: row => row?.lastModified ?? '',
                                name: t('@common.last-modified'),
                            },
                            {
                                selector: row => row?.modifiedBy ?? '',
                                name: t('@common.modified-by'),
                            },
                        ]}
                        actions={isFromSelectPopup ? [] : tableActions}
                        paginationProps={{
                            ...paginationProps,
                            hasNextPage: !!response.hasNextPage,
                        }}
                    />
                )}
            </div>
            {isFromSelectPopup && (
                <div className="w-full sticky bottom-0 left-0 right-0 bg-white px-4 pb-2 pt-2">
                    <div className="flex w-full rounded border border-mainBorder p-2 justify-between items-center">
                        <span className="text-sm font-medium text-blueMainText">
                            {t(`@select.filter${multiple ? 's' : ''}`, { defaultValue: 'Select Filters' })}
                        </span>
                        <CreateButton
                            icon={<GjirafaIcon name="Selection" />}
                            disabled={selectedRows.length < 1}
                            title={t('@common.select')}
                            onClick={() => {
                                const onChangeSelectedRows = selectPopupProps?.onChangeSelectedRows;
                                onChangeSelectedRows && onChangeSelectedRows(selectedRows);
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
