import { ApexOptions } from 'apexcharts';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { WorkflowModelListModel } from '../../../../../models';

export interface ModelFeatureImportanceProps {
    model: WorkflowModelListModel;
}

export const ModelFeatureImportance: React.FC<ModelFeatureImportanceProps> = ({ model }) => {
    const { wrapper } = model;

    const options: ApexOptions = {
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                // columnWidth: "20%",
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + '%';
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        xaxis: {
            position: 'top',
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
            tooltip: {
                enabled: true,
            },
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                formatter: function (val) {
                    return val + '%';
                },
            },
        },
        title: {
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
                color: '#444',
            },
        },
    };

    const { data, categories } = useMemo(() => {
        let categories: string[] = [];
        let data: string[] = [];

        if (wrapper?.featureImportance) {
            const featureImportance = wrapper.featureImportance;
            let arr = Object.entries(featureImportance).sort((keyValues, keyValues2) => {
                return parseFloat(keyValues2[1]) * 100 - parseFloat(keyValues[1]) * 100;
            });
            arr.forEach(([key, value]) => {
                let _value: any = Number(parseFloat(value?.toString()) * 100).toFixed(2);
                categories.push(key);
                data.push(_value?.toString());
            });
        }

        return { data, categories };
    }, [wrapper]);

    if (!wrapper?.featureImportance) {
        return (
            <div className="flex w-full h-auto p-10 justify-center items-center">
                <span className="text-blueMainText font-medium text-base">No Feature Importance for this model!</span>
            </div>
        );
    }

    return (
        <div className="w-full h-auto p-4 box-border overflow-hidden max-w-full" id="chart">
            <ReactApexChart
                type="bar"
                options={{
                    ...options,
                    plotOptions: {
                        ...options.plotOptions,
                        bar: {
                            ...options.plotOptions?.bar,
                            columnWidth: data?.length >= 5 ? '50px' : data?.length > 2 ? '25%' : '20%',
                        },
                    },
                    xaxis: {
                        ...options.xaxis,
                        categories,
                    },
                }}
                series={[
                    {
                        name: 'Importance',
                        data: data.map(x => parseInt(x) ?? 0),
                    },
                ]}
                height={500}
                width="90%"
            />
        </div>
    );
};
