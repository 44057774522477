import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RefreshButton } from '../../../../../components/buttons/RefreshButton';
import { RunStatusComponent } from '../../../../../components/common';
import { NoDataImagesType, Table, TableFilters } from '../../../../../components/tables';
import { useAccountId } from '../../../../../hooks';
import { RecommendationEngineRunListModel, RecommendationEngineRunListResponseModel } from '../../../../../models';
import {
    actions,
    selectors,
} from '../../../../../redux/thunk/app/engines/recommendation/recommendationEngineRunsThunk';
import { showFiltersHeader } from '../../../../../utils';

interface RecommendationRunsTabProps {
    recommendationEngineId: number;
}

export const RecommendationRunsTab: React.FC<RecommendationRunsTabProps> = ({ recommendationEngineId }) => {
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const { t } = useTranslation();

    /**
     * Selectors
     */
    const response: RecommendationEngineRunListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);

    const requestList = (payload: object = {}, reset?: boolean) => {
        dispatch(actions.request({ accountId, recommendationEngineId, ...payload }, reset));
    };

    useEffect(() => {
        if (recommendationEngineId) {
            requestList({ recommendationEngineId });
        }
    }, [accountId, recommendationEngineId]);

    const onPageChanged = page => requestList({ page });

    return (
        <>
            {!!recommendationEngineId && (
                <Table
                    loading={loading}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onSearch={searchQuery => requestList({ searchQuery, page: 0 })}
                            response={response}
                            className="flex-row-reverse justify-between"
                        >
                            <div className="flex flex-1 justify-end">
                                <RefreshButton onRefresh={() => requestList({})} />
                            </div>
                        </TableFilters>
                    }
                    className="shadow-none border border-mainBorder rounded"
                    columns={[
                        {
                            selector: row => row?.message,
                            name: t('@message', { defaultValue: 'Message' }),
                            grow: 2,
                            cell: (row: RecommendationEngineRunListModel) => (
                                <div className="flex w-full justify-start items-center">
                                    <RunStatusComponent
                                        row={row}
                                        runStatusKey="status"
                                        runDurationKey="duration"
                                        style={{
                                            maxWidth: 50,
                                        }}
                                    />
                                    <span>{row.message || row.status}</span>
                                </div>
                            ),
                        },

                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                            cell: row => <span>{row?.lastModified}</span>,
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    emptyDataProps={{
                        actionMessage: '',
                        emptyText: t('@no.recommendationRuns.found', {
                            defaultValue: 'There are no Runs yet for this Recommendation Engine',
                        }),
                        imageType: NoDataImagesType.RecommendationRun,
                    }}
                    data={response.models}
                    response={response}
                    modelEndpoint="recommendationEngineRun"
                    isFromSelectPopup={false}
                    showHeaderButtons={false}
                    showHeader={false}
                    selectableRows={false}
                    pagination
                    paginationProps={{
                        currentPage: response.currentPage || 0,
                        pageItems: response.models.length,
                        totalPages: response.totalPages,
                        totalItems: response.totalItems,
                        onChangePage: onPageChanged,
                    }}
                />
            )}
        </>
    );
};
