import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '../../../../components/buttons';
import { CreateDataSourceReaderModal } from '../../../../components/modals/CreateDataSourceReaderModal';
import { toast } from '../../../../components/ui';
import { useToggleVisibility } from '../../../../hooks';

interface CreateDataSourceReaderButtonProps {
    isActive: boolean;
}

export const CreateDataSourceReaderButton: React.FC<CreateDataSourceReaderButtonProps> = ({ isActive }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility();

    const onSuccess = id => {
        toast.success(t('@success.create.dataSourceReader', { defaultValue: 'Data Source Reader has been created!' }));
        setTimeout(() => {
            history.push(`/data/data-sources/details/${id}${window.location.search}`);
        }, 450);
    };

    return (
        <>
            <Button
                disabled={!isActive}
                icon={
                    <div className="mr-2">
                        <GjirafaIcon name="Plus" size={18} />
                    </div>
                }
                onClick={() => onToggleVisibility(true)}
                title={t('@create.dataSourceReader', { defaultValue: 'Create Data Source Reader' })}
                className={`mb-3 mr-2 transition-all duration-300 ${!isActive ? 'opacity-50' : '!text-blueMainText'}`}
            />
            {shouldRender && (
                <CreateDataSourceReaderModal
                    isVisible={isVisible}
                    onHide={() => onToggleVisibility(false)}
                    onSuccess={onSuccess}
                />
            )}
        </>
    );
};
