import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useOnScreen } from '../../hooks';
import { Loader } from '../ui';
import { OutlineButton } from '../buttons';
import { useTranslation } from 'react-i18next';

export interface InfiniteScrollProps {
    hasMore: boolean;
    loadMore: () => void;
    intersectionOptions?: IntersectionObserverInit;
    className?: string;
    style?: CSSProperties;
    isInitialLoading?: boolean;
    loading?: boolean;
    currentPage?: number;
    loaderClassName?: string;
    showMoreClassName?: string;
    customLoader?: ReactNode;
    displayShowMore?: boolean;
}

export const InfiniteScroll: FC<InfiniteScrollProps> = ({
    children,
    className,
    hasMore,
    loadMore,
    style,
    intersectionOptions,
    isInitialLoading,
    loading,
    currentPage,
    loaderClassName,
    showMoreClassName,
    customLoader,
    displayShowMore,
}) => {
    const { t } = useTranslation();
    const loaderRef = useRef(null);
    const isVisible = useOnScreen(loaderRef, {
        rootMargin: '0px',
        threshold: 0.1,
        ...intersectionOptions,
    });
    const [shouldShowMore, setShouldShowMore] = useState(!displayShowMore);

    useEffect(() => {
        if (currentPage === 0) {
            shouldShowMore && setShouldShowMore(false);
        }
    }, [currentPage]);

    useEffect(() => {
        if (isVisible) {
            !isInitialLoading && !loading && hasMore && loadMore();
        }
    }, [isVisible]);

    return (
        <div className={`${className}`} style={{ ...style }}>
            {children}
            {displayShowMore && !shouldShowMore && !isInitialLoading && hasMore && (
                <OutlineButton
                    className={showMoreClassName}
                    onClick={() => setShouldShowMore(true)}
                    title={t('@common.show-more')}
                />
            )}
            <div
                ref={loaderRef}
                className={`w-full h-auto py-4 items-center justify-center ${
                    hasMore && !isInitialLoading && shouldShowMore ? 'flex' : 'hidden'
                } ${loaderClassName}`}
            >
                {customLoader || <Loader />}
            </div>
        </div>
    );
};
