import { actionCreator } from '../../../utils';
import { RootState } from '../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    TOGGLE_MENU: '@theme/TOGGLE_MENU',
    CLOSE_MENU: '@theme/CLOSE_MENU',
    OPEN_MENU: '@theme/OPEN_MENU',
    SET_CURRENT_WIDTH: '@theme/SET_CURRENT_WIDTH',
    SET_IS_LOGGING_OUT: '@theme/SET_IS_LOGGING_OUT',
};

/**
 * Initial state
 */
const initialState = {
    isMenuOpened: window.innerWidth < 900 ? false : true,
    currentWidth: window.innerWidth,
    isLoggingOut: false,
};

/**
 * selectors
 */
export const selectors = {
    getIsMenuOpened: (state: RootState) => state.theme.isMenuOpened,
    getCurrentWidth: (state: RootState) => state.theme.currentWidth,
    getIsLoggingOut: (state: RootState) => state.theme.isLoggingOut,
};

/**
 * Reducer
 */
const { TOGGLE_MENU, CLOSE_MENU, OPEN_MENU, SET_CURRENT_WIDTH, SET_IS_LOGGING_OUT } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_IS_LOGGING_OUT:
            return {
                ...state,
                isLoggingOut: payload,
            };
        case TOGGLE_MENU:
            return {
                ...state,
                isMenuOpened: !state.isMenuOpened,
            };
        case CLOSE_MENU:
            return {
                ...state,
                isMenuOpened: false,
            };
        case OPEN_MENU:
            return {
                ...state,
                isMenuOpened: true,
            };
        case SET_CURRENT_WIDTH:
            return {
                ...state,
                currentWidth: payload,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    toggleMenu: () => actionCreator(TOGGLE_MENU),
    closeMenu: () => actionCreator(CLOSE_MENU),
    openMenu: () => actionCreator(OPEN_MENU),
    setCurrentWidth: width => actionCreator(SET_CURRENT_WIDTH, width),
    setIsLoggingOut: (loggingOut: boolean) => actionCreator(SET_IS_LOGGING_OUT, loggingOut),
};
