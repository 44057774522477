export interface LoaderProps {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    size?: number;
}

export const Loader: React.FC<LoaderProps> = ({ color = '#1D79F2', className, height, width, size = 32 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || width}
            height={size || height}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            style={{ stroke: color }}
            className={`animate-spin ${className}`}
            fill={color}
            color={color}
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
            ></circle>
        </svg>
    );
};
