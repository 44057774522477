import { PaymentMethodsDetailsResponseModel, PaymentMethodsStateType } from '../../../../models';
import { actionCreator } from '../../../../utils';
import { RootState } from '../../rootReducer';
import axios from '../../../../services/axios';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/payment/paymentMethods/REQUEST',
    SUCCESS: '@app/payment/paymentMethods/SUCCESS',
    FAILURE: '@app/payment/paymentMethods/FAILURE',
    REMOVE_PAYMENT_FROM_LIST: '@app/payment/paymentMethods/REMOVE_PAYMENT_FROM_LIST',
    SET_PRIMARY: '@app/payment/paymentMethods/SET_PRIMARY',
};

/**
 * Initial state
 */
const initialState: PaymentMethodsStateType = {
    errorMessage: '',
    isLoading: false,
    response: null,
};

/**
 * selectors
 */
export const selectors = {
    getResponse: (state: RootState) => state.app.paymentMethods.response,
    getIsLoading: (state: RootState) => state.app.paymentMethods.isLoading,
    getErrorMessage: (state: RootState) => state.app.paymentMethods.errorMessage,
};

/**
 * Reducer
 */
const { FAILURE, REQUEST, SUCCESS, REMOVE_PAYMENT_FROM_LIST, SET_PRIMARY } = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PRIMARY:
            return {
                ...state,
                response: {
                    ...state.response,
                    model: {
                        ...state.response?.model,
                        defaultPaymentMethodId: payload,
                    },
                },
            };
        case REMOVE_PAYMENT_FROM_LIST:
            return {
                ...state,
                response:
                    state.response && state.response?.model?.paymentMethods
                        ? {
                              ...state.response,
                              model: {
                                  ...state.response.model,
                                  paymentMethods: state.response.model.paymentMethods.filter(x => x.id !== payload),
                              },
                          }
                        : state.response,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (accountId) {
        return async dispatch => {
            try {
                dispatch(actionCreator(REQUEST));
                const response: PaymentMethodsDetailsResponseModel = await axios.get(
                    `/stripe/customer/paymentMethods?accountId=${accountId}`
                );
                dispatch(actionCreator(SUCCESS, response));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
    updateResponse: (response: PaymentMethodsDetailsResponseModel) => actionCreator(SUCCESS, response),
    removePaymentMethodFromList: id => actionCreator(REMOVE_PAYMENT_FROM_LIST, id),
    setCardAsPrimary: id => actionCreator(SET_PRIMARY, id),
};
