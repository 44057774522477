import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { ChangeEvent, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Clickable, DynamicInputs } from '../../../../../components/common';
import { InfoIconWithModal } from '../../../../../components/common/dynamic-inputs/InfoIconWithModal';
import { DetailsLoader } from '../../../../../components/details-entity';
import { InputField, SelectField } from '../../../../../components/inputs';
import { FieldLabel } from '../../../../../components/inputs/components/FieldLabel';
import { Loader } from '../../../../../components/ui';
import { inputTypes } from '../../../../../constants';
import {
    BaseEntityProperty,
    Nullable,
    SelectOptionType,
    TaskOutputDetailsModel,
    TaskVersionDetailsResponseModel,
} from '../../../../../models';
import axios from '../../../../../services/axios';
import { addNewOptionToDynamicInputs } from '../../../../../utils';
import { taskInputTypes } from '../../../tasks/constants';
import { createPipelineContext, TPipelineStepState, TPipelineVariableState, TTaskVersion } from '../contexts';
import { deleteErrorKey } from '../../../../../utils';


interface ITaskDetailsVisibleProps {
    _id: string;
    onDeleted: () => void;
    errors: any;
    setErrors: any;
}

export const TaskDetailsVisible = ({ _id, onDeleted, errors, setErrors }: ITaskDetailsVisibleProps) => {
    const {
        stepsInputs,
        stepsOutputs,
        steps,
        tasksVersions,
        variables,
        setSteps,
        setStepsInputs,
        setStepsOutputs,
        setVariables,
    } = useContext(createPipelineContext);

    const { t } = useTranslation();
    const params = useParams<{ id: any }>();
    const pipelineId = params.id || 0;

    const timeoutId: any = useRef(null);
    const [newInputsLoading, setNewInputsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const stepDetails: Nullable<TPipelineStepState> = useMemo(() => {
        return steps.find(x => x._id === _id) ?? null;
    }, [_id, steps]);

    useEffect(() => {
        setError('');
    }, [_id]);

    const currentStepInputs: BaseEntityProperty[] = useMemo<BaseEntityProperty[]>(() => {
        const currentStep = stepsInputs.find(x => x._id === _id);
        currentStep?.inputs.map(input => {
            if (input.inputType === inputTypes.dropdownOpen) {
                variables
                    .filter(x => !x.isOutput)
                    .map(variable => {
                        !input.options.find(x => x.label === variable.name) &&
                            input.options.push({
                                id: variable.id,
                                label: variable.name,
                                value: variable.name,
                            });
                    });

                //Add previous steps outputVariables as options in dropdown-open input
                let currentStepIndex = steps.findIndex(step => {
                    return step._id === _id;
                });
                variables
                    .filter(x => x.isOutput)
                    .map(outputVariable => {
                        let variableOutputReferenceName = outputVariable.name.split('.')[0];
                        let stepIndexOfCurrentOutputVariable = steps.findIndex(step => {
                            return step.outputReferenceName === variableOutputReferenceName;
                        });
                        if (stepIndexOfCurrentOutputVariable < currentStepIndex) {
                            !input.options.find(
                                x => x.label === outputVariable.name || x.label === outputVariable.value
                            ) &&
                                input.options.push({
                                    id: outputVariable.id,
                                    label: outputVariable.name,
                                    value: outputVariable.name,
                                });
                        }
                    });
            }
        });

        return currentStep ? currentStep.inputs : [];
    }, [_id, stepsInputs, variables]);

    const { versionOptions, versionSelectedOption } = useMemo(() => {
        const versionList: TTaskVersion[] = tasksVersions[stepDetails?.taskId ?? 0];
        const versionOptions = (versionList ?? []).map(x => ({
            label: x.versionNumber,
            value: x.id?.toString(),
        }));

        return {
            versionOptions,
            versionSelectedOption: versionOptions.find(x => x.value === stepDetails?.versionId?.toString()),
        };
    }, [tasksVersions, stepDetails?.taskId, stepDetails?.versionId]);

    const onValidateOutputReferenceName = async (referenceName: string) => {
        let currentStepVariables: TPipelineVariableState[] = variables.filter(x => x._id === _id && !x.isOutput);
        let otherStepsVariables: TPipelineVariableState[] = variables.filter(x => x._id !== _id);
        let newPipelineVariables: TPipelineVariableState[] = [...otherStepsVariables, ...currentStepVariables];
        let stepsOutputReferenceNames: string[] = steps.map(step => step.outputReferenceName);
        let regex = new RegExp('^[a-zA-Z][a-zA-Z0-9_]*$');

        try {
            if (referenceName === '') {
                setError('Output reference name is not valid');
            } else if (!regex.test(referenceName)) {
                setError(
                    `Output reference name '${referenceName}' doesn't match pattern: Only alphanumerics, underscores and starting with a string character!`
                );
                referenceName = '';
            } else if (stepsOutputReferenceNames.includes(referenceName)) {
                setError(
                    `The step output reference name '${referenceName}' appears more than once. Output reference name must be unique within a pipeline!`
                );
                referenceName = '';
            } else {
                stepsOutputs
                    .find(x => x._id === _id)
                    ?.outputs.map(output => {
                        newPipelineVariables.push({
                            _id,
                            id: 0,
                            pipelineId: pipelineId,
                            name: `${referenceName}.${output.name}`,
                            locked: false,
                            settableAtQueueTime: false,
                            value: '',
                            isOutput: true,
                            error: '',
                        });
                    });
            }
        } catch (err: any) {
            setError('Output reference name is not valid');
            referenceName = '';
        } finally {
            setSteps(prev => prev.map(x => (x._id === _id ? { ...x, outputReferenceName: referenceName } : x)));
            setVariables(newPipelineVariables);
            setLoading(false);
        }
    };

    const onChangeDisplayName = ({ target: { value: displayName } }: ChangeEvent<HTMLInputElement>) => {
        setSteps(prev => prev.map(x => (x._id === _id ? { ...x, displayName } : x)));
    };

    const onChangeOutputReferenceName = ({ target: { value: outputReferenceName } }: ChangeEvent<HTMLInputElement>) => {
        if (stepDetails?.id !== 0) return;
        setError('');
        setLoading(true);

        setSteps(prev => prev.map(x => (x._id === _id ? { ...x, outputReferenceName: outputReferenceName } : x)));

        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => onValidateOutputReferenceName(outputReferenceName), 500);
    };

    const onChangeVersion = (item: Nullable<SelectOptionType>) => {
        const versionId = parseInt(item?.value ?? '0');
        setSteps(prev => prev.map(x => (x._id === _id ? { ...x, versionId: versionId || x.versionId } : x)));

        if (item?.value !== versionSelectedOption?.value) {
            fetchNewInputs(versionId);
        }
    };

    const onChangeDynamicInputs = (inputName: string, value: any) => {
        if (value?.value !== null) {
            let currentInput = currentStepInputs.find(x => x.inputName === inputName);
            if (currentInput?.inputType === inputTypes.switch) {
                if (stepDetails !== null) {
                    stepDetails.outputReferenceNameDisabled = true;
                }
                setSteps(steps);
            } else {
                var outputReferenceName = value.value.split('.')[0];

                var step = steps.find(step => step.outputReferenceName === outputReferenceName) ?? null;
                if (step !== null) {
                    step.outputReferenceNameDisabled = true;
                    if (stepDetails !== null) {
                        stepDetails.outputReferenceNameDisabled = true;
                    }
                    setSteps(steps);
                }
            }
        }

        setStepsInputs(prev => {
            return prev.map(x =>
                x._id === _id
                    ? {
                          ...x,
                          inputs: x.inputs.map(_input =>
                              _input.inputName === inputName ? { ..._input, value } : _input
                          ),
                      }
                    : x
            );
        });
    };

    const fetchNewInputs = async versionId => {
        try {
            setNewInputsLoading(true);
            const response: TaskVersionDetailsResponseModel = await axios.get(`/pipeline/task/version?id=${versionId}`);

            const newInputs = response.model.inputs.map(x => ({
                ...x,
                inputType:
                    x.inputType === inputTypes.input || x.inputType === taskInputTypes.filePath
                        ? inputTypes.dropdownOpen
                        : x.inputType,
                inputName: x.name + x.id,
                value: '',
                valueId: 0,
            }));

            setOutputsVariables(response);
            setStepsInputs(prev => prev.map(x => (x._id === _id ? { ...x, inputs: newInputs } : x)));
        } catch (err) {
        } finally {
            setNewInputsLoading(false);
        }

        function setOutputsVariables(response: TaskVersionDetailsResponseModel) {
            let outputReferenceName = stepDetails?.outputReferenceName;
            const newOutputs: TaskOutputDetailsModel[] = response.model.outputs.map(output => {
                var name = '';
                const outputName = output.name.split('.');
                outputName.length === 1
                    ? (name = `${outputReferenceName}.${output.name}`)
                    : (name = `${outputReferenceName}.${outputName.pop()}`);
                return { ...output, name };
            });
            setStepsOutputs(prev => prev.map(x => (x._id === _id ? { ...x, outputs: newOutputs } : x)));

            var newStepVariables: TPipelineVariableState[] = [];
            newOutputs?.map(output => {
                if (!variables.find(x => x.name === output.name))
                    newStepVariables.push({
                        _id,
                        id: 0,
                        pipelineId: pipelineId,
                        name: output.name,
                        locked: false,
                        settableAtQueueTime: false,
                        value: '',
                        isOutput: true,
                        error: '',
                    });
            });
            // setVariables([...variables.filter(x => !x.name.includes(outputReferenceName || '')), ...newStepVariables]);
            setVariables(variables);
        }
    };
    
    const validateInputOnAdd = (prop, value) => {
        let isValid = true;
        let _errors: any = {};
        if(prop.type === 'ANY') {return isValid}

        if(prop.type === 'string' && !isNaN(value)) {
            isValid = false;
            _errors[prop.inputName] = 'Input must be a string';
        } else if(prop.type !== 'string' && isNaN(value)){
            isValid = false;
            _errors[prop.inputName] = 'Input must be a number';
        }
        setErrors(_errors);
        return isValid;
    };

    const onRemove = () => {
        setSteps(prev => prev.filter(x => x._id !== _id));
        setStepsInputs(prev => prev.filter(x => x._id !== _id));
        setStepsOutputs(prev => prev.filter(x => x._id !== _id));
        setVariables(prev => prev.filter(x => !x.name.includes(`${stepDetails?.outputReferenceName}.` ?? '')));
        onDeleted();
    };

    const onChangeInputsList = (_inputs: BaseEntityProperty[]) => {
        onChangeCurrentStep([{ key: 'inputs', value: _inputs }]);
    };

    const onChangeCurrentStep = (keyValues: { key: string; value: any }[]) => {
        setStepsInputs(prev => {
            let currentStepInputs: any = prev.find(x => x._id === _id);
            keyValues.forEach(x => {
                currentStepInputs[x.key] = x.value;
            });
            return prev;
        });
    };

    const onAddNewVariable = (inputName: string, value: string) => {
        try {
            let currentInputs: BaseEntityProperty[] = stepsInputs.find(x => x._id === _id)?.inputs ?? [];
            var currentInputLabel = currentStepInputs.find(x => x.inputName === inputName)?.name;
            var optionToAdd = `${stepDetails?.outputReferenceName}.${currentInputLabel}`;
            var currentInput = currentInputs.find(input => input.inputName === inputName);
            var shouldAddNewOption = !currentInput?.options.find(option => option.label === optionToAdd);
            const newOption = currentInput?.options.find(option => option.label === optionToAdd)
            if (shouldAddNewOption) {
                let inputs = addNewOptionToDynamicInputs(currentInputs, inputName, optionToAdd);
                onChangeCurrentStep([{ key: 'inputs', value: inputs }]);
            } else {
                const newInputs = currentInputs.map(n => n.inputName === inputName ? { ...n, value: newOption } : n);
                onChangeCurrentStep([{ key: 'inputs', value: newInputs }]);
            }

            setVariables(prev => {
                var currentVariableName = `${stepDetails?.outputReferenceName}.${currentInputLabel}`;
                var variableExists = prev.find(x => x.name === currentVariableName);

                if (!!variableExists) {
                    variableExists.value = value;
                } else {
                    prev.push({
                        _id: nanoid(),
                        id: 0,
                        name: `${stepDetails?.outputReferenceName}.${currentInputLabel}`,
                        value: value,
                        locked: false,
                        settableAtQueueTime: false,
                        isOutput: false,
                        error: '',
                    });
                }

                return [...prev.filter(x => x.name !== '')];
            });

            setSteps(prev =>
                prev.map(step =>
                    step._id === _id
                        ? {
                              ...step,
                              outputReferenceNameDisabled: true,
                          }
                        : step
                )
            );
        } catch (err: any) {}
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(async() => await validateInputOnAdd({inputName:currentInput?.inputName ,type: currentInput?.dataTypeId}, value), 500);
        deleteErrorKey(currentInput?.inputName, setErrors);
    };

    return (
        <div className="w-full">
            <div className="w-full flex items-center justify-between">
                <div className="flex items-center">
                    <h2 className="text-lg font-normal ml-2 text-blueMainText mr-1">{stepDetails?.taskTitle}</h2>
                    <InfoIconWithModal infoLink="" infoText="" />
                </div>
                <div className="">
                    <Clickable
                        onClick={onRemove}
                        className="text-deleteColor items-center flex p-1 px-2 border border-white rounded transition hover:border-delete/20"
                    >
                        <GjirafaIcon name="Delete" size={26} />
                        <p className="ml-2 text-sm">Remove</p>
                    </Clickable>
                </div>
            </div>
            <div className="px-2 mt-4 w-full">
                <div className="flex items-center">
                    <FieldLabel label="Task Version" className="mr-2" />
                    <SelectField
                        hideLabel
                        className="!w-[160px]"
                        options={versionOptions}
                        onChange={onChangeVersion}
                        value={versionSelectedOption}
                    />
                </div>
                <div className="flex w-full relative">
                    <InputField
                        placeholder={t('@common.write.here')}
                        label={t('@output.reference.name', { defaultValue: 'Output Reference name' })}
                        required
                        className="mt-4"
                        disabled={stepDetails?.id !== 0 || stepDetails.outputReferenceNameDisabled}
                        value={stepDetails?.outputReferenceName}
                        onChange={onChangeOutputReferenceName}
                        errorMessage={error}
                    />
                    {loading && <Loader className="absolute  top-[52px] right-2" size={22} />}
                </div>

                {stepDetails?.outputReferenceName !== '' && (
                    <>
                        <InputField
                            placeholder={t('@common.write.here')}
                            label={t('@display.name', { defaultValue: 'Display name' })}
                            required
                            className="mt-4"
                            value={stepDetails?.displayName}
                            onChange={onChangeDisplayName}
                        />

                        {newInputsLoading ? (
                            <DetailsLoader />
                        ) : (
                            <DynamicInputs
                                onChangeInput={onChangeDynamicInputs}
                                onChangeInputsList={onChangeInputsList}
                                handleCreateOption={onAddNewVariable}
                                properties={currentStepInputs}
                                className=" h-full mt-4"
                                errors={errors}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
