import React, { CSSProperties, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import {
    actions,
    actionTypes,
    ArtifactUploadingType,
    selectors,
} from '../../redux/thunk/app/artifact/uploadArtifactThunk';
import { rotateAnimationStyles } from '../../styles/animationStyles';
import { actionCreator } from '../../utils';
import { Icon, IconTypes } from '../common';
import { ConfirmationPopupModal } from '../modals';
import { toast } from '../ui';
import { UploadingArtifactsRowList } from './UploadingArtifactsRowList';

export interface UploadingArtifactsDialogProps {
    style?: CSSProperties;
    className?: string;
    isFromHeader?: boolean;
}

export const UploadingArtifactsDialog: React.FC<UploadingArtifactsDialogProps> = ({
    style,
    className,
    isFromHeader,
}) => {
    const dispatch = useDispatch();
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    const [isExpanded, setIsExpanded] = useState(true);

    const uploadArtifacts: ArtifactUploadingType[] = useSelector(selectors.getUploadArtifacts);

    const { shouldDisplay, title } = useMemo(() => {
        let _artifactsUploading = uploadArtifacts.filter(x => !x.errorMessage);
        let _artifactsFailed = uploadArtifacts.filter(x => !!x.errorMessage);

        let _title = `${
            _artifactsUploading.length
                ? `${_artifactsUploading.length} Artifact${_artifactsUploading.length > 1 ? 's' : ''} uploading...\n`
                : ''
        }${
            _artifactsFailed.length
                ? `${_artifactsFailed.length} Artifact${_artifactsFailed.length > 1 ? 's' : ''} failed!`
                : ''
        }`;

        return {
            shouldDisplay: !!uploadArtifacts.length,
            title: _title,
        };
    }, [uploadArtifacts]);

    const onClickCloseAll = () => {
        onToggleVisibility(false);
        uploadArtifacts.forEach(_uploadArtifact => {
            _uploadArtifact.cancelToken.cancel();
        });
        dispatch(actions.setArtifactsUploading([]));
        setTimeout(() => {
            toast.info(`${uploadArtifacts.length} Artifacts has been canceled!`);
        }, 100);
    };

    const onClickMinimize = () => {
        setIsExpanded(true);
        dispatch(actionCreator(actionTypes.SET_IS_DIALOG_HIDDEN, true));
    };

    if (!shouldDisplay) {
        return null;
    }

    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    isVisible={isModalVisible}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    onConfirm={() => {
                        onClickCloseAll();
                    }}
                    confirmTitle="Yes"
                    cancelTitle="No"
                    title="Confirm Cancellation"
                    description="Are you sure you want to cancel all uploads?"
                />
            )}
            <div
                className={`fixed bottom-0 right-0 mt-12 sm:bottom-6 sm:right-6 z-50 flex flex-col w-full sm:w-96 dropdown-shadow rounded-b ${className}`}
                style={{ zIndex: 60, ...style }}
            >
                <div
                    className={`w-full flex flex-row h-auto pl-4 pr-2 py-2 justify-between items-center bg-lightBlack sm:rounded-t  ${
                        !isExpanded && 'sm:rounded-b'
                    }`}
                >
                    <span className="flex flex-1 justify-start items-center text-white font-normal text-sm whitespace-pre-wrap">
                        {title}
                    </span>
                    <div className="flex flex-row w-auto">
                        <div className="h-10 w-10 mr-0.5 rounded-full transition-all duration-500 bg-lightBlack hover:bg-mainOverlay flex justify-center items-center">
                            {!isFromHeader && (
                                <Transition in={isExpanded} timeout={100}>
                                    {state => (
                                        <div
                                            className="cursor-pointer flex justify-center"
                                            onClick={() => setIsExpanded(p => !p)}
                                        >
                                            <Icon
                                                name={IconTypes['chevron-up-white']}
                                                height={24}
                                                width={24}
                                                style={{ ...rotateAnimationStyles[state] }}
                                            />
                                        </div>
                                    )}
                                </Transition>
                            )}
                        </div>
                        {!isFromHeader && (
                            <div
                                onClick={() => {
                                    onClickMinimize();
                                }}
                                className="h-10 w-10 mr-0.5 cursor-pointer rounded-full transition-all duration-500 bg-lightBlack hover:bg-mainOverlay flex justify-center items-center"
                            >
                                <div className="w-4 h-0.5 rounded bg-white" />
                            </div>
                        )}
                        <div
                            onClick={() => {
                                onToggleVisibility(true);
                            }}
                            className="h-10 w-10 cursor-pointer rounded-full transition-all duration-500 bg-lightBlack hover:bg-mainOverlay flex justify-center items-center"
                        >
                            <Icon name={IconTypes['close-white']} height={24} width={24} />
                        </div>
                    </div>
                </div>
                <UploadingArtifactsRowList isVisible={isExpanded} />
            </div>
        </>
    );
};
