import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React from 'react';
import { FullScreenModal } from '../../../../../components/modals/FullScreenModal';
import { Tooltip } from '../../../../../components/ui';
import { recommendationApiKey } from '../../../../../constants';
import { useToggleVisibility } from '../../../../../hooks';

interface RecommendationSandBoxTabProps {
    endpoint: string | null;
    apiKey?: string;
}

export const RecommendationSandBoxTab: React.FC<RecommendationSandBoxTabProps> = ({ endpoint, apiKey }) => {
    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility({ durationHide: 300 });

    const onClickOpenNewTab = () => {
        window.open(`${endpoint}/sandbox`, '_blank');
    };

    return (
        <>
            {shouldRender && (
                <FullScreenModal onHide={() => onToggleVisibility(false)} isVisible={isVisible}>
                    <iframe
                        className="z-50 h-full"
                        src={`${endpoint}/sandbox?apiKey=${apiKey ?? recommendationApiKey}`}
                    />
                </FullScreenModal>
            )}
            <div className="flex flex-col flex-1 w-full h-auto relative">
                <div className="right-1/2 flex-none flex absolute">
                    <div
                        data-for="fullScreenModal"
                        data-tip="Open in fullscreen"
                        className="cursor-pointer absolute -ml-11 top-3 p-1.5 z-40 bg-white border-blueMainText text-blueMainText border rounded"
                        onClick={() => onToggleVisibility(true)}
                    >
                        <GjirafaIcon size={22} name="Fullscreen" />
                    </div>
                    <div
                        onClick={onClickOpenNewTab}
                        data-for="fullScreenModal"
                        data-tip="Open in new tab"
                        className="cursor-pointer absolute top-3 p-1.5 z-40 bg-white border-blueMainText text-blueMainText border rounded"
                    >
                        <GjirafaIcon size={22} name="Redirect" />
                    </div>
                    <Tooltip id="fullScreenModal" delayShow={400} />
                </div>
                {endpoint && (
                    <iframe
                        src={`${endpoint}/sandbox?apiKey=${apiKey ?? recommendationApiKey}`}
                        style={{ minHeight: 1000 }}
                        className="w-full"
                    />
                )}
            </div>
        </>
    );
};
