import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RunStatusComponent } from '../../../../components/common';
import { SelectField } from '../../../../components/inputs';
import {
    NoDataImagesType,
    Table,
    TableActionRowProps,
    TableFilters,
    TableMobile,
    UrlTableComponent,
} from '../../../../components/tables';
import { BASE_PROPENSITY_PATH, breakpoints, PropensityEngineTypes, statusOptionTypes } from '../../../../constants';
import { useAccountId, useCurrentWidth } from '../../../../hooks';
import { PropensityEngineListModel, PropensityEngineListResponseModel, SelectOptionType } from '../../../../models';
import { actions, selectors } from '../../../../redux/thunk/app/engines/propensity/propensityEnginesThunk';
import { formatArrayForSelectOptions, queryParams, showFiltersHeader } from '../../../../utils';

export interface PropensityEngineTableProps {
    propensityEngineType: PropensityEngineTypes;
}

export const PropensityEngineTable: React.FC<PropensityEngineTableProps> = ({ propensityEngineType }) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);

    /**
     * Local State
     */
    const response: PropensityEngineListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    useEffect(() => {
        if (response.query?.status) {
            setStatusTypeSelected(response.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [response.query]);

    useEffect(() => {
        return () => {
            dispatch(actions.setQuery({ status: null, page: 0, searchQuery: '' }))
        }
    }, [])

    const requestList = (query: any, resetQuery?: boolean) => {
        dispatch(actions.request({ accountId, type: propensityEngineType, ...query }, resetQuery));
    };

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestList({ page: 0, status: status });
    };

    const onClickCreate = () => {
        let link = `${BASE_PROPENSITY_PATH}/${propensityEngineType}/create-new-propensity`;
        history.push(link + queryParams.formatForNavigation());
    };

    const onPageChanged = page => {
        requestList({ page });
    };
    const onRefresh = () => requestList({});

    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.no-propensityEngines'),
            actionMessage: `+ ${t('@pages.create-new-propensity-engine')}`,
            imageType: NoDataImagesType.PropensityEngine,
            onClickAction: onClickCreate,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'delete',
            },
        ],
        []
    );
    const commonTableProps = {
        modelEndpoint: 'propensityEngine',
        selectableRows: true,
        requestList,
        showHeaderButtons: true,
        isFromSelectPopup: false,
        emptyDataProps,
        loading,
        response,
        deleteProps: {
            deleteQuery: {
                type: propensityEngineType,
            },
        },
        headerButtonProps: {
            onRefresh,
            onClickCreate,
        },
    };

    return (
        <div className="flex flex-col pb-4" style={{ minHeight: 320 }}>
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestList({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                        >
                            <SelectField
                                disabled={false}
                                isClearable
                                label="Status"
                                className="m-2"
                                placeholder={t('@common.choose-status')}
                                style={{ width: 240 }}
                                onChange={onChangeStatusType}
                                options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                                value={selectedStatusType}
                                isLoading={loading}
                            />
                        </TableFilters>
                    }
                    pagination
                    paginationProps={paginationProps}
                    showHeader={false}
                    columns={[
                        {
                            selector: row => row?.title ?? '',
                            name: t('@common.title'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.productTypeName ?? '',
                            name: t('@common.productType'),
                            sortable: true,
                        },
                        {
                            name: 'Endpoint',
                            selector: row => row?.endpoint ?? '',
                            minWidth: '220px',
                            maxWidth: '100%',
                            cell: (row: PropensityEngineListModel) => {
                                if (!row?.endpoint || row?.endpoint === '<pending>')
                                    return <span>{row?.endpoint || '<pending>'}</span>;

                                return <UrlTableComponent url={row.endpoint} />;
                            },
                        },
                        {
                            name: t('@common.run-status'),
                            selector: row => row?.runStatus ?? '',
                            sortable: true,
                            width: '140px',
                            cell: row => <RunStatusComponent row={row} />,
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={tableActions}
                />
            )}

            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="title"
                    initialLoading={initLoading}
                    columns={[
                        {
                            selector: row => row?.title ?? '',
                            name: t('@common.title'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.catalog ?? '',
                            name: t('@common.catalog'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                            sortable: true,
                        },
                    ]}
                    actions={tableActions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
