import React, { CSSProperties, ReactNode, useMemo, useState } from 'react';
import { isAsyncFunction, upperCaseFirstCharacter } from '../../../../utils';
import { Icon, IconTypes } from '../../../common';
import { Loader, Tooltip } from '../../../ui';

export interface TableActionRowProps {
    action: string | ((row: any) => string);
    displayName?: string | ((row: any) => string);
    onClick?: ((row: any) => any) | ((row: any) => Promise<any>);
    disable?: boolean | ((row: any) => boolean);
    tooltipMessage?: string | ((row: any) => string);
    disabledTooltipMessage?: string | ((row: any) => string);
    style?: CSSProperties;
    className?: string;
    iconStyle?: CSSProperties;
    cell?: (row: any) => ReactNode;
    hide?: boolean | ((row: any) => boolean);
}

export const TableActionRow: React.FC<TableActionRowProps & { row: any; onClickAction: (name: string) => any }> = ({
    action,
    className,
    disable,
    disabledTooltipMessage,
    displayName,
    iconStyle,
    onClick,
    style,
    tooltipMessage,
    onClickAction,
    row,
}) => {
    const [loading, setLoading] = useState(false);

    const isDisabled: boolean = useMemo(
        () => (disable ? (typeof disable === 'function' ? disable(row) : disable) : false) || loading,
        [disable, loading]
    );
    const _tooltipMessage = useMemo(() => {
        let message = isDisabled ? disabledTooltipMessage : tooltipMessage;
        return typeof message === 'function' ? message(row) : message;
    }, [disabledTooltipMessage, tooltipMessage, isDisabled]);

    const actionName = typeof action === 'function' ? action(row) : action;
    const _displayName =
        typeof displayName === 'function' ? displayName(row) : displayName || upperCaseFirstCharacter(actionName);

    const _onClick = async () => {
        if (isDisabled) {
            return;
        }

        if (!onClick) {
            onClickAction(actionName);
            return;
        }

        if (isAsyncFunction(onClick)) {
            setLoading(true);
            await onClick(row);
            setLoading(false);

            return;
        }

        onClick(row);
    };

    return (
        <div
            onClick={_onClick}
            className={`cursor-pointer transition-colors hover:bg-mainBackground px-4 py-2 flex flex-row items-center w-auto ${
                isDisabled && 'cursor-not-allowed'
            }`}
            style={style}
            data-tip={_tooltipMessage}
        >
            <Icon
                name={IconTypes[isDisabled ? `${actionName}-disabled` : actionName]}
                style={iconStyle}
                className={className}
                size={18}
            />
            {loading ? (
                <Loader className="ml-2" size={22} />
            ) : (
                <span className={`text-sm text-blueMainText font-regular pl-4 ${isDisabled && 'opacity-50'}`}>
                    {_displayName}
                </span>
            )}
            <Tooltip />
        </div>
    );
};
