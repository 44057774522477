import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Header from '../components/header';
import { AdminSidebar } from '../components/sidebars';
import { ScrollToTopButton } from '../components/ui';
import { UserDetailsResponseModel } from '../models';
import { actions as externalSourcesActions } from '../redux/thunk/app/externalSourceTypesThunk';
import { actions as notificationsActions } from '../redux/thunk/app/notification/notificationsThunk';
import { actions as userActions, selectors as userSelectors } from '../redux/thunk/app/user/userThunk';

export const AdminLayout: React.FC = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const userResponse: UserDetailsResponseModel = useSelector(userSelectors.getResponse);
    const [containerElement, setContainerElement] = useState<HTMLElement | null>(null);
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);

    useEffect(() => {
        fetchUserDetails();
        dispatch(externalSourcesActions.request());
    }, []);

    useEffect(() => {
        if (containerElement) {
            containerElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location, containerElement]);

    let scrollTimeoutId: any = null;
    const onScrollDetect = (container: HTMLElement) => {
        if (scrollTimeoutId) {
            clearTimeout(scrollTimeoutId);
        }
        scrollTimeoutId = setTimeout(() => {
            if (container.scrollTop > 400 && container.scrollHeight > container.clientHeight) {
                !showScrollTopButton && setShowScrollTopButton(true);
            } else {
                showScrollTopButton && setShowScrollTopButton(false);
            }
        }, 400);
    };

    const fetchUserDetails = async () => {
        try {
            dispatch(userActions.updateResponse(userResponse));
            dispatch(notificationsActions.request({ page: 0 }));
            if (userResponse?.model?.id) {
                let isAdmin = userResponse.model.isAdmin;
                if (!isAdmin) {
                    history.replace('/');
                }
            }
        } catch (err: any) {}
    };

    return (
        <div
            className="bg-gray-100 h-screen flex flex-col overflow-x-hidden overflow-y-hidden"
            style={{ minHeight: 600 }}
        >
            <Header showToggleMenu />
            <div className="w-full flex h-full flex-1 overflow-y-hidden">
                <AdminSidebar />
                <div
                    ref={_ref => {
                        setContainerElement(_ref);
                    }}
                    onScroll={e => onScrollDetect(e.currentTarget)}
                    className="w-full flex-1 flex flex-col bg-mainBackground overflow-x-hidden p-2 pt-3 sm:p-3 md:p-4 lg:p-6"
                >
                    {children}
                    <ScrollToTopButton
                        isVisible={showScrollTopButton}
                        containerElement={containerElement}
                        onScrollToTop={() => {
                            setShowScrollTopButton(false);
                            containerElement && containerElement.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
