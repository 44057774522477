import { AccountImage } from './AccountImage';
import { OutlineButton } from '../buttons';
import { CSSProperties } from 'react';
import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { Loader } from '../ui';
import { useTranslation } from 'react-i18next';

export interface AccountItemProps {
    isDefaultAccount: boolean;
    isCurrentAccount: boolean;
    account: string;
    imageUrl?: string;
    onClickSetDefault?: (e?: any) => any;
    onClick?: (e?: any) => any;
    className?: string;
    style?: CSSProperties;
    isLoading?: boolean;
    setDefaultLoading?: boolean;
}

export const AccountItem: React.FC<AccountItemProps> = ({
    account,
    isCurrentAccount,
    isDefaultAccount,
    imageUrl,
    onClickSetDefault,
    onClick,
    className,
    setDefaultLoading,
    isLoading,
    style,
}) => {
    const { t } = useTranslation();

    return (
        <div
            style={{ ...style, borderBottom: '1px solid #EAEAEA' }}
            className={`w-full h-auto cursor-pointer p-4 flex flex-row justify-start items-center duration-200 transition-all  ${
                !isCurrentAccount && 'hover:bg-grey200'
            } ${className}`}
            onClick={e => {
                e.stopPropagation();
                onClick && onClick(e);
            }}
        >
            {isLoading && (
                <div className="w-full h-full items-center flex justify-start ml-2">
                    <Loader size={28} />
                </div>
            )}
            <AccountImage className={`${isLoading ? 'opacity-0' : 'opacity-100'}`} account={account} url={imageUrl} />
            <span
                className={`flex flex-1 justify-between items-center ml-4 text-blueMainText font-medium text-sm ${
                    isLoading ? 'opacity-0' : 'opacity-100'
                }`}
            >
                {account}
            </span>
            {isDefaultAccount && !isLoading && (
                <span className="w-auto h-auto p-2 rounded font-semibold text-xs text-mainBlue justify-center items-center flex ml-1">
                    {t('@common.default', { defaultValue: 'Default' })}
                </span>
            )}
            {!isCurrentAccount && <GjirafaIcon name="ArrowRight" />}
            {isCurrentAccount && !isDefaultAccount && (
                <OutlineButton
                    title={t('@common.set-as-default')}
                    isLoading={setDefaultLoading}
                    onClick={e => {
                        e.stopPropagation();
                        onClickSetDefault && onClickSetDefault(e);
                    }}
                    className={`ml-1 ${isLoading ? 'opacity-0' : 'opacity-100'} flex-none`}
                />
            )}
        </div>
    );
};
