import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableActionRowProps } from '../../../../components/tables';
import { Loader, toast } from '../../../../components/ui';
import { useAccountId } from '../../../../hooks';
import { ArtifactListModel, DetailsResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import { Icon, IconTypes } from '../../../../components/common';
import { getFormattedQuery, onDownloadFileFromUrl } from '../../../../utils';



interface DetailsDropdownActionsProps {
    item: any;
    onClickEdit: () => any;
}

export const MoreOptionsDropdown: React.FC<DetailsDropdownActionsProps> = ({
    item,
    onClickEdit,
}) => {
    const { t } = useTranslation();
    const [actionLoading, setActionLoading] = useState<string>('');
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const accountId = useAccountId();

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (event.target instanceof HTMLElement) {
                if (!ref.current || ref.current.contains(event.target)) return;
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', (event: any) => listener(event));
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', (event: any) => listener(event));
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, setIsOpen]);

    //ACTIONS 
    const onDownloadFile = async (item) => {
        try {
            setActionLoading('Download');
            let query: any = {
                accountId,
                artifactId: item?.id,
            };

            let { model: presignedArtifactUrl }: DetailsResponseModel<string> = await axios.get(
                `/artifact/presignedUrl?${getFormattedQuery(query)}`
            );
            onDownloadFileFromUrl(presignedArtifactUrl);
            setActionLoading('');
        } catch (err: any) { 
            setActionLoading('');
        }
    };

    const onClickRunArtifact = async ({ id }: ArtifactListModel) => {
        try {
            await axios.post('/artifact/run', { id, accountId });
            toast.success(t('@artifact.started.running', { defaultValue: 'Artifact has started running!' }));
        } catch (err: any) { }
    };

    const onClickTerminateArtifact = async (details: ArtifactListModel) => {
        try {
            await axios.post('/artifact/terminate', { id: details.id, accountId });
            toast.success('Artifact is terminating');
        } catch (err: any) { }
    };

    const runDetails = async () => {
        if (item?.runStatus && item?.runStatus === 'Running') {
            onClickTerminateArtifact(item);
            return
        }
        onClickRunArtifact(item);
    }

    const actions = useMemo(() => {
        const _actions: TableActionRowProps[] = [
            {
                action: item?.runStatus === 'Running' ? 'terminate' : 'run',
                disable: !item?.dataSource || item?.runStatus === 'Terminating',
                displayName: item?.runStatus && item?.runStatus === 'Running' ? 'Terminate' : 'Run',
            },
            {
                action: 'edit',
                disable: item?.dataSource ? false : true,
                displayName: 'Edit',
            },
            {
                action: 'download',
                disable: !item?.versionsCount,
                displayName: 'Download',
            },
        ];
        return _actions;
    }, [t, item]);

    const handleAction = (action: TableActionRowProps) => {
        if (action.disable) return;
        switch (action.action) {
            case 'edit':
                onClickEdit();
                break;
            case 'download':
                onDownloadFile(item);
                break;
            case 'run':
                runDetails();
                break;
            case 'terminate':
                runDetails();
                break;
            default:
                break;
        }
        setIsOpen(false);
    };

    return (
        <div ref={ref}>
            <div>
                <button className="relative flex justify-center items-center text-sm hover:bg-mainBorder focus:outline-none outline-none z-30 transition-all rounded">
                    <span onClick={handleOpen} className="flex justify-center items-center px-4 pb-2 text-base cursor-pointer">
                        ...
                    </span>
                </button>
            </div>
            {isOpen && (
                <div className="more-options-dropdown-content bg-white w-52 rounded z-50 overflow-visible transform-gpu transition-all origin-center opacity-100 pointer-events-auto translate-y-0"
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 5px', position: 'fixed', right: '17px', marginTop: '0px' }}
                >
                    {actions.map((action, index) => (
                        <div
                            key={index}
                            className={`opacity-100 cursor-pointer transition-colors hover:bg-mainBackground px-4 py-2 text-sm font-regular text-blueMainText flex flex-row items-center w-auto hover:text-mainBlue 
                            ${action.disable ? 'cursor-not-allowed text-blueMainText hover:bg-mainBackground ' : ''}`}
                            onClick={() => handleAction(action)}
                        >   
                        {actionLoading === action.action ? (
                                <Loader size={14} />
                            ) : (
                            <>

                                {action.action === 'download' && (
                                    <span className={`mr-2 ${action.disable ? 'opacity-50' : ''}`}>
                                        <Icon
                                            name={IconTypes[action.action]}
                                            size={18}

                                        />
                                    </span>
                                )}
                                {action.action === 'edit' && (
                                    <span className={`mr-2 ${action.disable ? 'opacity-50' : ''}`}>
                                        <Icon
                                            name={IconTypes[action.action]}
                                            size={18}

                                        />
                                    </span>
                                )}
                                {action.action === 'run' && (
                                    <span className={`mr-2 ${action.disable ? 'opacity-50' : ''}`}>
                                        <Icon
                                            name={IconTypes[action.action]}
                                            size={18}

                                        />
                                    </span>
                                )}
                                {action.action === 'terminate' && (
                                    <span className={`mr-2 ${action.disable ? 'opacity-50' : ''}`}>
                                        <Icon
                                            name={IconTypes['run-disabled']}
                                            size={18}

                                        />
                                    </span>
                                )}
                                <span className={`more-options-dropdown-item-text ${action.disable ? 'opacity-50' : ''}`}>
                                    {action.displayName}
                                </span>
                            </>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
