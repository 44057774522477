import { memo, useState } from 'react';
import { RadioButton } from '../../../../../components/buttons';
import { InfoIconWithModal } from '../../../../../components/common/dynamic-inputs/InfoIconWithModal';
import { SelectWithStoragePopupField } from '../../../../../components/inputs';
import { FieldLabel } from '../../../../../components/inputs/components/FieldLabel';
import { Nullable, SelectOptionType } from '../../../../../models';
import { deleteErrorKey } from '../../../../../utils';
import { onChangeFieldValuesType } from '../hooks/useCreateDatasetHook';
import { dataTypes } from './DataTypesComponent';

interface IDataTypesComponent {
    dataTypeId: number;
    objectPath?: Nullable<SelectOptionType>;
    isObjectPathFolder?: boolean;
    onChangeFields: onChangeFieldValuesType;
    errors: any;
}

const RadioButtonRow = ({ onClick, isChecked, text, className }) => (
    <div className={`w-full flex cursor-pointer items-center ${className}`} onClick={onClick}>
        <RadioButton isChecked={isChecked} onCheck={onClick} />
        <p className="ml-2 text-blueMainText font-medium text-xs">{text}</p>
    </div>
);

const { image, textual } = dataTypes;
export const AddDataComponent = memo(
    ({ dataTypeId, onChangeFields, isObjectPathFolder, objectPath, errors }: IDataTypesComponent) => {
        const [localErrors, setLocalErrors] = useState<any>({});

        const onValidateCsvFile = (path: string) => {
            if (path.endsWith('.csv')) deleteErrorKey('objectPath', setLocalErrors);
            else setLocalErrors(p => ({ ...p, objectPath: 'Please Select an CSV file!' }));
        };

        const renderCsvFileInput = () => (
            <SelectWithStoragePopupField
                required
                labelRight={<InfoIconWithModal infoText={`Select or Upload your CSV file!`} />}
                options={{
                    fetchDataOnMount: true,
                    isFolder: false,
                    multiple: false,
                }}
                showSelectedItemInsideInput
                label="CSV file"
                selectedItems={objectPath ? [objectPath] : []}
                placeholder="Select or upload a CSV file"
                errorMessage={errors['objectPath'] || localErrors['objectPath']}
                onChangeSelectedItems={items => {
                    onValidateCsvFile(items[0]?.value ?? '');
                    onChangeFields({ objectPath: items[0] });
                }}
            />
        );

        const renderFolderInput = () => (
            <SelectWithStoragePopupField
                required
                labelRight={<InfoIconWithModal infoText={`Select or Upload Folder with your files inside!`} />}
                showSelectedItemInsideInput
                options={{
                    fetchDataOnMount: true,
                    isFolder: true,
                    multiple: false,
                }}
                selectedItems={objectPath ? [objectPath] : []}
                onChangeSelectedItems={items => onChangeFields({ objectPath: items[0] })}
                label="Folder"
                errorMessage={errors['objectPath']}
                placeholder={`Select folder with ${dataTypeId === image ? 'Images' : 'Text files'} inside`}
            />
        );

        return (
            <div className="flex flex-col w-full">
                {(dataTypeId === textual || dataTypeId === image) && (
                    <div className="flex flex-col w-full mb-4">
                        <FieldLabel label="Choose the way you want to add your data:" />
                        <RadioButtonRow
                            isChecked={!isObjectPathFolder}
                            text="Select/Upload CSV file with path of files!"
                            onClick={() =>
                                onChangeFields({
                                    objectPath: null,
                                    isObjectPathFolder: false,
                                })
                            }
                            className="mt-1"
                        />
                        <RadioButtonRow
                            isChecked={!!isObjectPathFolder}
                            text="Select/Upload Folder with containing files and we will generate a CSV for you!"
                            onClick={() =>
                                onChangeFields({
                                    objectPath: null,
                                    isObjectPathFolder: true,
                                })
                            }
                            className="mt-3"
                        />
                    </div>
                )}
                {isObjectPathFolder ? renderFolderInput() : renderCsvFileInput()}
            </div>
        );
    }
);
