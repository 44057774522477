import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OutlineButton } from '../../../../components/buttons';
import { customEndpointDetailsContext } from '../../../../contexts';
import axios from '../../../../services/axios';
import { getAccountIdFromQuery } from '../../../../utils';

interface IDefaultVersionColumn {
    versionId: number;
    buildStatus: string;
    isDefault: boolean;
    onSuccessDeployed: () => any;
}

export const DefaultVersionColumn = ({
    versionId,
    buildStatus,
    onSuccessDeployed,
    isDefault,
}: IDefaultVersionColumn) => {
    const { t } = useTranslation();

    const { details } = useContext(customEndpointDetailsContext);
    const [loading, setLoading] = useState(false);

    const onClickSetDefault = async () => {
        try {
            setLoading(true);
            const payload = {
                id: details?.id ?? 0,
                accountId: getAccountIdFromQuery(),
                versionId,
                patch: [],
            };
            await axios.patch('/customEndpoint', payload);
            onSuccessDeployed();
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    const canDeploy = buildStatus === 'Succeeded';
    const isDeploying = buildStatus === 'Running';

    if (isDeploying)
        return (
            <div className="w-full flex justify-end items-center h-full">
                <p className="text-greyBlue300 font-medium">{t('@deploying', { defaultValue: 'deploying...' })}</p>
            </div>
        );

    if (isDefault)
        return (
            <div className="flex items-center text-greenAccent font-medium w-full justify-end">
                <p className="text-greenAccent text-sm font-medium mr-2">
                    {t('@deployed', { defaultValue: 'Deployed' })}
                </p>
                <GjirafaIcon size={22} name="Check" />
            </div>
        );

    if (canDeploy)
        return (
            <div className="flex justify-end items-center h-full w-full group">
                <OutlineButton
                    noStyle
                    className="text-greyBlue300 transition-all transform-gpu ease-linear duration-300 border-greyBlue50 border rounded px-4 h-8 text-sm group-hover:border-primaryBlue200 group-hover:!text-primaryBlue500"
                    onClick={onClickSetDefault}
                    title={t('@deploy', { defaultValue: 'Deploy' })}
                    isLoading={loading}
                />
            </div>
        );

    return null;
};
