import { createContext } from 'react';

export interface IBillingPageContext {
    currentCost: string;
    setCurrentCost: React.Dispatch<React.SetStateAction<string>>;
}

export const billingPageContext = createContext<IBillingPageContext>({
    currentCost: '0.00',
    setCurrentCost: () => {},
});
