import i18n from 'i18next';
import { useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { ProfileItem } from '.';
import { LanguageListModel, LanguageListResponseModel } from '../../../models';
import { selectors } from '../../../redux/thunk/app/language/languagesThunk';
import axios from '../../../services/axios';
import { upperCaseFirstCharacter } from '../../../utils';
import { MenuLinkArrow } from '../../sidebars/components';

export const ProfileLanguages = () => {
    const currentLanguage = i18n.language;

    const { models: languages }: LanguageListResponseModel = useSelector(selectors.getResponse);
    const [isLanguageExpanded, setIsLanguageExpanded] = useState(false);

    const onChangeLanguage = async (isoCode: string) => {
        try {
            i18n.changeLanguage(isoCode);
            await axios.patch('/user', {
                languageIsoCode: isoCode,
            });
        } catch (err) {}
    };

    const selectedLanguage: LanguageListModel | null = useMemo(
        () => languages.find(x => x.isoCode === currentLanguage) || null,
        [currentLanguage, languages]
    );

    return (
        <>
            <ProfileItem
                title={selectedLanguage?.name || upperCaseFirstCharacter(currentLanguage)}
                className="border-t border-grey200 py-4 px-4"
                iconName="Language"
                onClick={() => {
                    setIsLanguageExpanded(prev => !prev);
                }}
                rightComponent={<MenuLinkArrow animationIn={!isLanguageExpanded} size={22} />}
            />
            <AnimateHeight height={isLanguageExpanded ? 'auto' : 0}>
                <div className="w-full h-auto">
                    {languages.map(_language => (
                        <ProfileItem
                            key={_language.id}
                            iconName={currentLanguage === _language.isoCode ? 'Check' : undefined}
                            className="border-grey200 py-4 px-4"
                            title={_language.name}
                            onClick={() => {
                                if (_language.isoCode !== currentLanguage) {
                                    onChangeLanguage(_language.isoCode);
                                }
                            }}
                        />
                    ))}
                </div>
            </AnimateHeight>
        </>
    );
};
