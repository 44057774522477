import axios from '../../../services/axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/buttons';
import { toast, Tooltip } from '../../../components/ui';
import { IntegrationListModel } from '../../../models';
import { useAccountId } from '../../../hooks';

interface DisconnectIntegrationComponentProps {
    row: IntegrationListModel;
    onSuccess: () => void;
}

export const DisconnectIntegrationComponent: React.FC<DisconnectIntegrationComponentProps> = ({ onSuccess, row }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const accountId = useAccountId();

    const onClick = async () => {
        try {
            setIsLoading(true);
            const query = {
                id: row.id?.toString(),
                accountId: accountId?.toString(),
            };

            await axios.delete(`/bridge/integration?${new URLSearchParams(query)}`);
            toast.success(
                t('@toast-messages.success-integration-disconnect', {
                    defaultValue: 'Integration has been disconnected!',
                })
            );
            onSuccess();
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full px-2">
            <Button
                data-for="DisconnectIntegrationComponent"
                data-tip="Disconnect from this integration!"
                title="Disconnect"
                disabled={isLoading}
                onClick={onClick}
                loading={isLoading}
                className="w-full text-blueMainText"
            />
            <Tooltip id="DisconnectIntegrationComponent" delayShow={400} />
        </div>
    );
};
