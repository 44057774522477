import { Icon, IconTypes } from '../../common';
import { Dropdown } from '../../dropdown';
import { selectors } from '../../../redux/thunk/app/artifact/uploadArtifactThunk';
import { UploadingArtifactsDialog } from '../../UploadingArtifactsDialog/UploadingArtifactsDialog';
import { useSelector } from 'react-redux';
import { CounterDisplay } from './CounterDisplay';

export const UploadDialogHeader = () => {
    const uploadArtifacts = useSelector(selectors.getUploadArtifacts);
    const isDialogHidden = useSelector(selectors.getIsDialogHidden);

    if (isDialogHidden && uploadArtifacts.length > 0) {
        return (
            <Dropdown
                wrapperClassName="z-40 mr-2"
                withArrow={false}
                triggerProps={{
                    children: (
                        <>
                            <div className="h-10 w-10 flex justify-center items-center rounded-full bg-greyWhite200 hover:bg-grey300">
                                <Icon name={IconTypes['upload-blue']} />
                                <CounterDisplay number={uploadArtifacts?.length} />
                            </div>
                        </>
                    ),
                }}
            >
                <UploadingArtifactsDialog
                    className="mt-2"
                    isFromHeader
                    style={{ position: 'unset', zIndex: 0, margin: 0 }}
                />
            </Dropdown>
        );
    }
    return null;
};
