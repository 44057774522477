import React, { CSSProperties } from 'react';

interface ToggleInputProps {
    isToggled: boolean;
    onToggle: () => any;
    className?: string;
    style?: CSSProperties;
}

export const ToggleInput: React.FC<ToggleInputProps> = ({ isToggled, onToggle, className, style }) => {
    return (
        <div
            onClick={() => onToggle()}
            style={{ width: 42, ...style }}
            className={`h-6 flex justify-start items-center cursor-pointer rounded-xl border transition-all duration-300 ease-linear ${
                isToggled ? 'border-primaryBlue500 pl-5' : 'border-mainGrayText pl-1'
            } ${className}`}
        >
            <div
                className={`h-4 w-4 rounded-full transition-all duration-300 ${
                    isToggled ? 'bg-primaryBlue500' : 'bg-mainGrayText'
                }`}
            />
        </div>
    );
};
