import { CSSProperties, FC, ReactNode } from 'react';

export type CustomTabType = {
    key: any;
    displayName: string;
    disabled?: boolean;
};

export interface CustomTabProps {
    tabs?: CustomTabType[];
    onChangeTab?: (tab: CustomTabType) => any;
    activeTabKey?: string;
    rightComponent?: ReactNode;

    style?: CSSProperties;
    className?: string;
    tabItemClassName?: string;
    tabItemStyle?: CSSProperties;
    activeTabItemStyle?: CSSProperties;
    activeTabItemClassName?: string;
}

export const CustomTab: FC<CustomTabProps> = ({
    onChangeTab,
    className,
    activeTabKey,
    rightComponent,
    tabs,
    style,
    activeTabItemClassName,
    activeTabItemStyle,
    tabItemClassName,
    tabItemStyle,
}) => {
    const renderTabItems = () => {
        return tabs
            ? tabs.map(({ displayName, key, disabled }, i) => {
                  let isActive = key === activeTabKey;

                  return (
                      <div
                          onClick={() => {
                              if (isActive || disabled) {
                                  return;
                              }
                              onChangeTab && onChangeTab({ displayName, key });
                          }}
                          key={key}
                          className={`flex flex-col justify-between ${
                              disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'
                          } items-center px-2 sm:px-3 transition-colors duration-300 delay-75 ${
                              isActive ? `${activeTabItemClassName}` : ''
                          } ${i === 0 ? 'pl-4 sm:pl-6' : ''} ${tabItemClassName}`}
                          style={isActive ? { ...tabItemStyle, ...activeTabItemStyle } : { ...tabItemStyle }}
                      >
                          <span
                              className={`text-xs font-medium py-4 sm:text-sm transition-all duration-300 ${
                                  disabled ? '' : 'hover:text-blueMainText'
                              } ${isActive ? 'text-blueMainText' : `text-secondaryText `}`}
                          >
                              {displayName}
                          </span>
                          <div
                              className={`h-0.5 rounded-lg  transition-all duration-300 delay-75 ${
                                  isActive ? 'bg-mainBlue w-full' : 'w-1'
                              }`}
                              style={{ marginBottom: -1 }}
                          />
                      </div>
                  );
              })
            : null;
    };

    return (
        <div className={`flex w-full border-b border-blueStroke h-auto ${className}`} style={style}>
            <div className="flex flex-1">{renderTabItems()}</div>
            {rightComponent}
        </div>
    );
};
