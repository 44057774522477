import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ADMIN_CREATE_SCHEMA_TYPE_PATH } from '../../../constants';
import { SchemaTypeListModel, SchemaTypeListResponseModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/schemaType/adminSchemaTypesThunk';
import { AdminTable } from '../components';

export const AdminSchemaTypesPage = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * Redux Selectors
     */
    const response: SchemaTypeListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    const requestList = (query: any = {}) => {
        dispatch(actions.request({ ...query, isFromAdmin: true }));
    };

    useEffect(() => {
        requestList({ page: 0 });
    }, []);

    const onRefresh = () => {
        !loading && requestList();
    };

    const onClickEdit = (row: SchemaTypeListModel) => {
        history.push(`/admin/schema-types/edit-schema-type/${row?.id}`);
    };
    const onClickCreate = () => {
        history.push(ADMIN_CREATE_SCHEMA_TYPE_PATH);
    };

    return (
        <div className="">
            <AdminTable
                modelEndpoint="schemaType"
                requestList={requestList}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row?.name ?? '',
                    },
                ]}
                reduxActions={actions}
                loading={loading}
                response={response}
                initLoading={initLoading}
                onClickEdit={onClickEdit}
                onPageChanged={page => {
                    requestList({ page });
                }}
                headerButtonProps={{
                    onClickCreate,
                    onRefresh,
                }}
            />
        </div>
    );
};
