import { CSSProperties, ReactNode, useMemo } from 'react';
import { Transition } from 'react-transition-group';
import { ErrorTextComponent } from '../messages';
export interface SwitchInputProps {
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    label?: string;
    name?: string;
    className?: string;
    style?: CSSProperties;
    inputClassName?: string;
    labelStyle?: CSSProperties;
    labelClassName?: string;
    hideLabel?: boolean;
    required?: boolean;
    value: boolean;
    disabled?: boolean;
    labelRight?: ReactNode;
    size?: 'small' | 'normal' | 'large';
    onChange?: (value: boolean) => any;
}

const sizeTypes: Record<string, any> = {
    small: {
        paddingLeft: 6,
        paddingLeftDifference: 14,
        width: 44,
    },
    normal: {
        paddingLeft: 4,
        paddingLeftDifference: 26,
        width: 52,
    },
    large: {
        paddingLeft: 6,
        paddingLeftDifference: 30,
        width: 60,
    },
};

export const SwitchInput: React.FC<SwitchInputProps> = ({
    onChange,
    value,
    className,
    errorMessage,
    inputClassName,
    required,
    label,
    hideLabel,
    labelClassName,
    labelRight,
    disabled,
    style,
    size = 'large',
}) => {
    const { switchInputAnimationStyle } = useMemo(() => {
        const paddingLeft = sizeTypes[size].paddingLeft || 0;
        const paddingLeftDiff = sizeTypes[size].paddingLeftDifference || 0;

        let switchInputAnimationStyle = {
            entering: {
                paddingLeft,
                background: '#E2E2E2',
            },
            entered: {
                transition: 'all 300ms linear',
                paddingLeft: paddingLeft + paddingLeftDiff,
                background: '#1D79F2',
            },
            exiting: {
                paddingLeft: paddingLeft + paddingLeftDiff,
                background: '#1D79F2',
            },
            exited: {
                transition: 'all 300ms linear',
                paddingLeft,
                background: '#E2E2E2',
            },
        };

        return { switchInputAnimationStyle };
    }, [size]);

    return (
        <div className={`flex flex-col ${className}`} style={style}>
            {!hideLabel && (
                <div
                    style={{
                        minHeight: 24,
                    }}
                    className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                >
                    <label className="text-xs sm:text-sm text-secondaryText font-normal">
                        {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                    </label>
                    {labelRight && labelRight}
                </div>
            )}
            <button
                type="button"
                onClick={() => !disabled && onChange && onChange(!value)}
                className="flex flex-row items-center h-8 sm:h-10"
            >
                <Transition in={value} timeout={100} unmountOnExit={false}>
                    {state => (
                        <div
                            className={`flex flex-row cursor-pointer items-center h-7 rounded-3xl ${inputClassName}`}
                            style={{ ...switchInputAnimationStyle[state], width: sizeTypes[size].width }}
                        >
                            <div className="bg-white shadow rounded-full" style={{ height: 18, width: 18 }} />
                        </div>
                    )}
                </Transition>
            </button>
            {errorMessage && <ErrorTextComponent style={{ marginTop: 0 }} message={errorMessage} />}
        </div>
    );
};
