import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CreateButton, CreateButtonProps, OutlineButton, OutlineButtonProps } from '../buttons';
import { Tooltip } from '../ui';

export interface FooterButtonsProps {
    onClickCancel?: (e: any) => any;
    onClickSave?: (e: any) => any;
    onClickThirdButton?: (e: any) => any;
    thirdButtonTitle?: string;
    hideThirdButton?: boolean;
    hideCancel?: boolean;
    hideSave?: boolean;
    tooltipMessage?: string;
    saveButtonProps?: OutlineButtonProps;
    thirdButtonProps?: CreateButtonProps;
    saveButtonLoading?: boolean;
    thirdButtonLoading?: boolean;
    className?: string;
    style?: CSSProperties;
}

export const FooterButtons: React.FC<FooterButtonsProps> = ({
    hideCancel,
    hideSave,
    hideThirdButton,
    onClickCancel,
    onClickSave,
    onClickThirdButton,
    thirdButtonTitle,
    saveButtonProps,
    thirdButtonProps,
    tooltipMessage,
    style,
    saveButtonLoading,
    thirdButtonLoading,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`flex flex-row items-start flex-wrap-reverse justify-end w-full`} style={{ ...style }}>
            {!hideCancel && (
                <Button
                    onClick={e => onClickCancel && onClickCancel(e)}
                    type="button"
                    title={t('@common.cancel')}
                    style={{ minWidth: 100 }}
                    className="font-medium !text-blueMainText mt-2"
                />
            )}
            <div data-tip={tooltipMessage} className="flex flex-row">
                {!hideSave && (
                    <OutlineButton
                        isLoading={saveButtonLoading}
                        className="ml-4 text-blue font-medium"
                        title={t('@common.save')}
                        type="button"
                        style={{ minWidth: 100 }}
                        onClick={e => onClickSave && onClickSave(e)}
                        {...saveButtonProps}
                    />
                )}
                {!hideThirdButton && (
                    <CreateButton
                        loading={thirdButtonLoading}
                        type="button"
                        className="ml-4 flex"
                        title={thirdButtonTitle}
                        style={{ minWidth: 100, justifyContent: 'center' }}
                        onClick={e => onClickThirdButton && onClickThirdButton(e)}
                        {...thirdButtonProps}
                    />
                )}
                <Tooltip />
            </div>
        </div>
    );
};
