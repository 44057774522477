import { toast as TOAST, ToastContent, ToastOptions } from 'react-toastify';

const { warn: _warn, success: _success, info: _info, error: _error, dismiss: _dismiss, ...restToastProps } = TOAST;

const options = {
    position: TOAST.POSITION.BOTTOM_RIGHT,
    newestOnTop: false,
    closeOnClick: true,
    draggable: true,
    autoClose: 3000,
};

const success = (message: any) => {
    _success(message, options);
};

const info = (message: any, _options?: ToastOptions) => {
    _info(message, { ...options, autoClose: 4000, ..._options });
};

const error = (message: any, _options?: ToastOptions) => {
    _error(message, { ...options, ..._options });
};

const warn = (content: ToastContent, _options?: ToastOptions) => {
    _warn(content, { ...options, ..._options });
};

const dismiss = (id?: string | number) => _dismiss(id);

export const toast = { success, error, info, dismiss, warn, ...restToastProps };
