import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';

export const NotificationDate: React.FC<{ date: string }> = ({ date }) => {
    const language = useTranslation()?.i18n?.language ?? 'en-US';
    const timeAgoLocalizationKey = {
        'en-US': 'en_US',
        en: 'en_US',
    };

    return (
        <TimeAgo
            className="text-xs text-secondaryText font-medium mr-2"
            datetime={date}
            locale={timeAgoLocalizationKey[language] ?? language}
        />
    );
};
