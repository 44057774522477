import { useCallback, useMemo, useState } from 'react';
import { Nullable, PartialRecord, SelectOptionType } from '../../../../../models';
import { getUnixTimestamp, queryParams } from '../../../../../utils';
import i18n from '../../../../../config/i18n';
import { useTranslation } from 'react-i18next';
import { datasetFormSchema, onValidateWithYup } from '../../../../../validations';
import { useHistory } from 'react-router';
import { toast } from '../../../../../components/ui';
import { DATASETS_PATH } from '../../../../../constants';

type FieldValuesType = {
    title: string;
    dataTypeId: number;
    objectiveId: number;
    objectPath?: Nullable<SelectOptionType>;
    isObjectPathFolder?: boolean;
};

export type onChangeFieldValuesType = (values: PartialRecord<keyof FieldValuesType, any>) => void;

export function useCreateDatasetHooks() {
    const { t } = useTranslation();
    const history = useHistory();

    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [fieldValues, setFieldValues] = useState<FieldValuesType>({
        title: 'untitled_' + getUnixTimestamp(),
        dataTypeId: 0,
        objectiveId: 0,
        objectPath: null,
        isObjectPathFolder: false,
    });

    const SchemaValidation = useMemo(() => i18n.isInitialized && !!t && datasetFormSchema(t), [i18n.isInitialized, t]);
    const onChangeFieldValues: onChangeFieldValuesType = useCallback(
        values => {
            let keys = Object.keys(values);
            if (keys.length) {
                setErrors(prev => {
                    keys.forEach(x => delete prev[x]);
                    return prev;
                });
            }

            setFieldValues(prev => ({
                ...prev,
                ...values,
            }));
        },
        [setFieldValues, setErrors]
    );

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (formLoading || !SchemaValidation) return;

        try {
            const payload = {
                title: fieldValues.title,
                dataTypeId: fieldValues.dataTypeId,
                objectiveId: fieldValues.objectiveId,
                objectPath: fieldValues.objectPath?.value,
                isObjectPathFolder: fieldValues.isObjectPathFolder,
            };

            const { isValid, errors } = await onValidateWithYup(SchemaValidation, { ...payload });
            setErrors(errors);
            if (!isValid) {
                toast.dismiss();
                toast.error('You have field errors!');
                return;
            }

            setFormLoading(true);
            //Make post request

            toast.success(t('@success.create.dataset', { defaultValue: 'Dataset has been created!' }));
            history.push(DATASETS_PATH + queryParams.formatForNavigation());
            setFormLoading(false);
        } catch (err) {
            setFormLoading(false);
        }
    };

    return {
        formLoading,
        errors,
        fieldValues,
        onChangeFieldValues,
        onSubmit,
    };
}
