import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import {
    ADMIN_ACCOUNTS_PATH,
    ADMIN_ALGORITHMS_PATH,
    ADMIN_ALGORITHM_GROUP_PATH,
    ADMIN_CONFIGURATION_ITEMS_PATH,
    ADMIN_CREATE_ALGORITHM_PATH,
    ADMIN_CREATE_DATA_SOURCE_TYPES_PATH,
    ADMIN_CREATE_FLOW_ACTION_PATH,
    ADMIN_CREATE_INTEGRATION_TYPES_PATH,
    ADMIN_CREATE_SCHEMA_TYPE_PATH,
    ADMIN_DATA_SOURCE_TYPES_PATH,
    ADMIN_EDIT_ALGORITHM_PATH,
    ADMIN_EDIT_DATA_SOURCE_TYPES_PATH,
    ADMIN_EDIT_FLOW_ACTION_PATH,
    ADMIN_EDIT_INTEGRATION_TYPES_PATH,
    ADMIN_EDIT_SCHEMA_TYPE_PATH,
    ADMIN_FLOW_ACTIONS_PATH,
    ADMIN_INTEGRATION_TYPES_PATH,
    ADMIN_LOCALIZATION_PATH,
    ADMIN_SCHEMA_TYPES_PATH,
    ADMIN_VOUCHERS_PATH,
    BASE_ADMIN_PATH,
} from '../constants';
import { AdminLayout } from '../containers';
import {
    AdminAccountsPage,
    AdminAlgorithmGroupsPage,
    AdminAlgorithmsPage,
    AdminConfigurationItemsPage,
    AdminCreateAlgorithmPage,
    AdminCreateDataSourceTypePage,
    AdminCreateFlowActionPage,
    AdminCreateSchemaTypePage,
    AdminDashboardPage,
    AdminDataSourceTypesPage,
    AdminFlowActionsPage,
    AdminLocalizationsPage,
    AdminSchemaTypesPage,
    AdminVouchersPage,
} from '../pages/admin';
import { AdminCreateIntegrationTypePage } from '../pages/admin/integration-types/AdminCreateIntegrationTypePage';
import { AdminIntegrationTypesPage } from '../pages/admin/integration-types/AdminIntegrationTypesPage';
import { actions as languagesActions } from '../redux/thunk/app/language/languagesThunk';

export const AdminRoutes: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        window.bsk('time');
        window.bsk('event', 'pageview');
    }, [location]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(languagesActions.request());
    }, []);

    return (
        <Route
            render={props => {
                const location = props.location;
                return (
                    <AdminLayout>
                        <Switch location={location}>
                            <Route exact path={BASE_ADMIN_PATH} component={AdminDashboardPage} />
                            <Route exact path={ADMIN_LOCALIZATION_PATH} component={AdminLocalizationsPage} />
                            <Route exact path={ADMIN_ACCOUNTS_PATH} component={AdminAccountsPage} />

                            {/* --------- Integration types --------- */}
                            <Route exact path={ADMIN_INTEGRATION_TYPES_PATH} component={AdminIntegrationTypesPage} />
                            <Route
                                exact
                                path={[ADMIN_CREATE_INTEGRATION_TYPES_PATH, ADMIN_EDIT_INTEGRATION_TYPES_PATH]}
                                component={AdminCreateIntegrationTypePage}
                            />

                            {/* --------- Data Source --------- */}
                            <Route exact path={ADMIN_DATA_SOURCE_TYPES_PATH} component={AdminDataSourceTypesPage} />
                            <Route
                                exact
                                path={[ADMIN_CREATE_DATA_SOURCE_TYPES_PATH, ADMIN_EDIT_DATA_SOURCE_TYPES_PATH]}
                                component={AdminCreateDataSourceTypePage}
                            />

                            {/* --------- Flows --------- */}
                            <Route exact path={ADMIN_FLOW_ACTIONS_PATH} component={AdminFlowActionsPage} />
                            <Route
                                exact
                                path={[ADMIN_CREATE_FLOW_ACTION_PATH, ADMIN_EDIT_FLOW_ACTION_PATH]}
                                component={AdminCreateFlowActionPage}
                            />

                            {/* ----------- Algorithms --------- */}
                            <Route exact path={ADMIN_ALGORITHMS_PATH} component={AdminAlgorithmsPage} />
                            <Route exact path={ADMIN_ALGORITHM_GROUP_PATH} component={AdminAlgorithmGroupsPage} />
                            <Route
                                exact
                                path={[ADMIN_CREATE_ALGORITHM_PATH, ADMIN_EDIT_ALGORITHM_PATH]}
                                component={AdminCreateAlgorithmPage}
                            />

                            <Route
                                exact
                                path={ADMIN_CONFIGURATION_ITEMS_PATH}
                                component={AdminConfigurationItemsPage}
                            />

                            {/* --------- Schema --------- */}
                            <Route exact path={ADMIN_SCHEMA_TYPES_PATH} component={AdminSchemaTypesPage} />
                            <Route
                                exact
                                path={[ADMIN_CREATE_SCHEMA_TYPE_PATH, ADMIN_EDIT_SCHEMA_TYPE_PATH]}
                                component={AdminCreateSchemaTypePage}
                            />
                            <Route exact path={ADMIN_VOUCHERS_PATH} component={AdminVouchersPage} />
                        </Switch>
                    </AdminLayout>
                );
            }}
        />
    );
};
