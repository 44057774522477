import React, { CSSProperties } from 'react';

export interface ErrorTextComponentProps {
    message?: string;
    style?: CSSProperties;
    className?: string;
}

export const ErrorTextComponent: React.FC<ErrorTextComponentProps> = ({ message, style, className }) => (
    <span
        style={{ ...style }}
        className={`text-mainError font-normal subpixel-antialiased ${className} ${
            message ? 'h-auto transition-all mt-1 ml-1 text-sm' : 'h-0 transition-all'
        }`}
    >
        {message}
    </span>
);
