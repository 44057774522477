import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
/**
 * Components
 */
import { HeaderForCreatePages } from '../../../components/common';
import DynamicInputsForm, { SingleItemInputsType } from '../../../components/common/dynamic-inputs/DynamicInputsForm';
import { InputField, SelectField, SwitchInput } from '../../../components/inputs';
import { LoadingModal } from '../../../components/modals';
import { toast } from '../../../components/ui';
import {
    actionTypeOptions,
    ADMIN_FLOW_ACTIONS_PATH,
    artifactContentTypes,
    dataTypesOptions,
    inputTypeOptions,
} from '../../../constants';
/**
 * hooks - axios
 */
import { useCurrentWidth } from '../../../hooks';
/**
 * Models - constants
 */
import { ExternalSourceTypeListResponseModel, FlowActionDetailsResponseModel, SelectOptionType } from '../../../models';
import { selectors as externalSourceSelectors } from '../../../redux/thunk/app/externalSourceTypesThunk';
import axios from '../../../services/axios';
import { formatArrayForSelectOptions, getFormattedQuery } from '../../../utils';
import { AdminFooterButtons } from '../components/AdminFooterButtons';
import DataTypeForm, { SingleDataTypeFormType } from './components/DataTypeForm';

export const AdminCreateFlowActionPage = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const history = useHistory();
    const params: any = useParams();

    const isEditing = params?.id;
    type inputsRefType = React.ElementRef<typeof DynamicInputsForm>;
    type dataTypesRefType = React.ElementRef<typeof DataTypeForm>;
    const inputsRef = useRef<inputsRefType>(null);
    const dataTypesRef = useRef<dataTypesRefType>(null);

    const { models: externalSources }: ExternalSourceTypeListResponseModel = useSelector(
        externalSourceSelectors.getResponse
    );
    const externalSourcesLoading = useSelector(externalSourceSelectors.getIsLoading);

    /**
     * Local State
     */
    const [loadingModal, setLoadingModal] = useState(false);
    const [fieldValues, setFieldValues] = useState<{
        name: string;
        isActive: boolean;
        code: string;
        actionType: SelectOptionType | null;
        orderNumber: number;
        isRepeatable: boolean;
    }>({
        name: '',
        code: '',
        isActive: false,
        actionType: null,
        isRepeatable: false,
        orderNumber: 0,
    });

    useEffect(() => {
        if (isEditing && !externalSourcesLoading) {
            const id = params?.id;
            fetchFlowActionDetails(id);
        }
    }, [params, externalSourcesLoading]);

    const fetchFlowActionDetails = async id => {
        try {
            setLoadingModal(true);
            const response: FlowActionDetailsResponseModel = await axios.get(
                `/flowAction?${getFormattedQuery({ id })}`
            );
            mapDetailsInState(response);
        } catch (err: any) {
        } finally {
            setLoadingModal(false);
        }
    };

    const onChangeInputs = (key, value) => {
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };
    const mapDetailsInState = (response: FlowActionDetailsResponseModel) => {
        const {
            model: {
                properties: _inputs,
                name,
                code,
                dataTypes: _dataTypes,
                isActive,
                actionType: _actionType,
                orderNumber,
                isRepeatable,
            },
        } = response;

        let actionType = actionTypeOptions.find(x => x.value === _actionType?.toString()) || null;
        let dataTypes: SingleDataTypeFormType[] = [];
        let inputs: SingleItemInputsType[] = [];

        setFieldValues({
            name,
            code,
            actionType,
            isActive,
            orderNumber,
            isRepeatable,
        });

        if (_dataTypes) {
            dataTypes = _dataTypes?.map((x, i) => ({
                _id: i + 1,
                id: x.id,
                name: x.name,
            }));
        }
        if (_inputs) {
            inputs = _inputs?.map((_input, i) => ({
                ..._input,
                dataTypeId:
                    dataTypesOptions.find(_dataTypesOption => _dataTypesOption.value === _input.dataTypeId) || null,
                _id: i + 1,
                options: _input?.options.map((_inputLOption, i) => ({
                    id: _inputLOption.id || 0,
                    _id: i + 1,
                    ..._inputLOption,
                })),
                inputType:
                    inputTypeOptions?.find(_inputTypeOption => _inputTypeOption.value === _input.inputType) || null,
                externalSourceTypeId: formatArrayForSelectOptions(externalSources, 'title', 'id').find(
                    (_externalSource: any) => _externalSource?.value === _input.externalSourceTypeId?.toString()
                ),
            }));
        }

        if (inputsRef.current?.setInputs) {
            inputsRef.current?.setInputs(inputs);
        }
        if (dataTypesRef.current?.setDataTypes) {
            dataTypesRef.current?.setDataTypes(dataTypes);
        }
    };
    const handleCreate = async (save?: boolean) => {
        try {
            let inputs: SingleItemInputsType[] = inputsRef.current?.getInputs() || [];
            let dataTypes: SingleDataTypeFormType[] = dataTypesRef.current?.getDataTypes() || [];
            const { isActive, name, code, actionType, orderNumber, isRepeatable } = fieldValues;
            setLoadingModal(true);

            let payload: {
                id: number;
                name: string;
                isActive: boolean;
                orderNumber: number;
                isRepeatable?: boolean;
                actionType: any;
                properties: any[];
                dataTypes: any[];
                code: string;
            } = {
                id: isEditing ? params?.id : 0,
                name,
                code,
                isActive,
                orderNumber,
                isRepeatable,
                actionType: parseInt(actionType?.value || '0'),
                properties: inputs.map(x => ({
                    id: x.id,
                    dataTypeId: x.dataTypeId?.value,
                    defaultValue: x.defaultValue,
                    inputType: x.inputType?.value || '',
                    name: x.name,
                    options: x.options.map(x => ({
                        label: x.label,
                        value: x.value,
                        id: x.id,
                    })),
                    required: x.required,
                    orderNumber: x.orderNumber,
                    isColumn: x.isColumn,
                    conditionalId: x.conditionalId || null,
                    conditionalValue: x.conditionalValue,
                    infoText: x.infoText,
                    infoLink: x.infoLink,
                    externalSourceTypeId: x?.externalSourceTypeId?.value || null,
                    isRepeatable: x.isRepeatable,
                })),
                dataTypes: dataTypes.map(x => ({
                    id: x.id,
                    name: x.name,
                })),
            };

            let res: FlowActionDetailsResponseModel = await axios.post('/flowAction', payload);
            if (save) {
                toast.success(`Flow action Saved Successfully!`);
                if (isEditing) mapDetailsInState(res);
                else history.push(`/admin/flow-actions/edit-flow-action/${res.model.id}`);
            } else {
                toast.success(`Flow action ${isEditing ? 'Edited ' : 'Created'} Successfully!`);
                history.push(ADMIN_FLOW_ACTIONS_PATH);
            }
        } catch (err: any) {
            console.log({ err });
        } finally {
            setLoadingModal(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleCreate();
    };

    return (
        <PageAnimationContainer>
            <div>
                <form className="flex flex-col w-full items-center pb-4" onSubmit={handleSubmit}>
                    {loadingModal && <LoadingModal isVisible />}
                    <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                    <div className="flex flex-col" style={{ width: currentWidth < 900 ? '100%' : 546 }}>
                        <InputField
                            name="flowActionName"
                            label="Name"
                            className="w-full"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('name', e.target.value)}
                            value={fieldValues.name}
                            required
                        />
                        <InputField
                            name="flowActionCode"
                            label="Code"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('code', e.target.value)}
                            value={fieldValues.code}
                            className="mt-4"
                        />
                        <SelectField
                            options={actionTypeOptions}
                            value={fieldValues.actionType}
                            label="Action type"
                            className="mt-4"
                            onChange={item => onChangeInputs('actionType', item)}
                        />
                        <InputField
                            label="Order Number"
                            placeholder="Write here"
                            htmlType="number"
                            className="mt-4"
                            value={fieldValues.orderNumber}
                            onChange={e => onChangeInputs('orderNumber', e.target.value)}
                        />
                        <div className="flex flex-row flex-wrap w-full">
                            <SwitchInput
                                value={fieldValues.isActive}
                                onChange={val => onChangeInputs('isActive', val)}
                                label={t('@common.is-active')}
                                className="my-4 mr-4"
                            />
                            {fieldValues.actionType?.value === artifactContentTypes.textual.toString() && (
                                <SwitchInput
                                    value={fieldValues.isRepeatable}
                                    onChange={val => onChangeInputs('isRepeatable', val)}
                                    label={t('@common.is-repeatable')}
                                    className="my-4 mr-4"
                                />
                            )}
                        </div>
                    </div>

                    <HeaderForCreatePages hideIcon title="Properties" />
                    <DynamicInputsForm ref={inputsRef} isEditing={isEditing} />

                    <HeaderForCreatePages hideIcon title="Data types" />
                    <DataTypeForm isEditing={isEditing} ref={dataTypesRef} />

                    <HeaderForCreatePages hideIcon title="" />
                    <AdminFooterButtons
                        submitTitle={isEditing ? 'Edit Flow Action' : 'Create Flow Action'}
                        onClickSave={() => handleCreate(true)}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
