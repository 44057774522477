import { PaymentMethod, SetupIntentResult } from '@stripe/stripe-js';
import { format } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AlertInfoComponent } from '../../../components/alerts';
import { CreateButton } from '../../../components/buttons';
import { InputField } from '../../../components/inputs';
import { Loader, toast } from '../../../components/ui';
import { billingPageContext } from '../../../contexts';
import { useAccountId } from '../../../hooks';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
import { AccountDetailsResponseModel, PaymentMethodsDetailsResponseModel } from '../../../models';
import {
    actions as accountActions,
    selectors as accountDetailsSelectors,
} from '../../../redux/thunk/app/account/accountDetailsThunk';
import {
    actions as paymentMethodsActions,
    selectors as paymentMethodsSelectors,
} from '../../../redux/thunk/app/payment/paymentMethodsThunk';
import axios from '../../../services/axios';
import { AddPaymentCardComponent, DeleteCardConfirmPopup, PaymentMethodItem } from './components';
import { AddPaymentCardModal } from './components/AddPaymentCardModal';

export const CardSettingsTab: React.FC = () => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });

    /**
     * Selectors
     */
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);
    const arePaymentMethodsLoading: boolean = useSelector(paymentMethodsSelectors.getIsLoading);
    const paymentMethodsResponse: PaymentMethodsDetailsResponseModel | null = useSelector(
        paymentMethodsSelectors.getResponse
    );
    const { currentCost } = useContext(billingPageContext);

    /**
     * Local state
     */
    const [actionLoading, setActionLoading] = useState('');
    const [voucherCode, setVoucherCode] = useState('');
    const [applyVoucherLoading, setApplyVoucherLoading] = useState(false);
    const [cardIdToDelete, setCardIdToDelete] = useState<any>(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const isDeletingLastCard = useMemo(() => {
        const paymentMethodsLength = paymentMethodsResponse?.model.paymentMethods?.length || 0;
        return !!cardIdToDelete && paymentMethodsLength <= 1;
    }, [cardIdToDelete, paymentMethodsResponse]);

    const onSubmitPayAndDelete = async () => {
        try {
            setDeleteLoading(true);

            if (isDeletingLastCard && parseFloat(currentCost) > 0) {
                await onPayNow();
            }

            await onDeleteCard(cardIdToDelete);
            if (!isDeletingLastCard) {
                const id =
                    paymentMethodsResponse?.model?.paymentMethods?.find(x => x.id !== cardIdToDelete)?.id || null;
                if (cardIdToDelete === paymentMethodsResponse?.model.defaultPaymentMethodId && id) {
                    await onSetCardAsPrimary(id);
                    dispatch(paymentMethodsActions.setCardAsPrimary(id));
                }
            }

            setCardIdToDelete(null);
            dispatch(paymentMethodsActions.removePaymentMethodFromList(cardIdToDelete));
            toast.success('Card has been deleted successfully!');

            if (isDeletingLastCard) {
                dispatch(accountActions.request(accountId));
            }
        } catch (err) {
        } finally {
            setDeleteLoading(false);
        }
    };

    const onClickDelete = (item: PaymentMethod) => {
        setCardIdToDelete(item.id);
    };

    const onPayNow = async () => {
        return await axios.post(`/stripe/subscription?accountId=${accountId}`);
    };

    const onDeleteCard = async (id: any) => {
        return await axios.delete(`/stripe/paymentMethod?accountId=${accountId}&paymentMethodId=${id}`);
    };

    const onSetCardAsPrimary = async id => {
        return await axios.patch(`/stripe/paymentMethod/default?accountId=${accountId}&&paymentMethodId=${id}`);
    };

    const onClickSetAsPrimary = async id => {
        try {
            setActionLoading('primary');
            await axios.patch(`/stripe/paymentMethod/default?accountId=${accountId}&&paymentMethodId=${id}`);
            dispatch(paymentMethodsActions.setCardAsPrimary(id));
        } catch (err: any) {
        } finally {
            setActionLoading('');
        }
    };

    const onSuccessCardCreated = async (setupIntentResult: SetupIntentResult) => {
        let shouldMakePrimary = false;
        try {
            if (
                !paymentMethodsResponse ||
                !paymentMethodsResponse.model.paymentMethods ||
                paymentMethodsResponse.model.paymentMethods.length < 1
            ) {
                shouldMakePrimary = true;
            } else if (paymentMethodsResponse.model.paymentMethods.length === 1) {
                const payment = paymentMethodsResponse.model.paymentMethods[0];
                shouldMakePrimary = payment.id !== paymentMethodsResponse.model.defaultPaymentMethodId;
            }

            if (shouldMakePrimary) {
                await axios.patch(
                    `/stripe/paymentMethod/default?accountId=${accountId}&&paymentMethodId=${setupIntentResult.setupIntent?.payment_method}`
                );
            }

            dispatch(paymentMethodsActions.request(accountId));

            if (!accountDetails.isActive) {
                toast.success('Your account has been activated!');
            } else {
                toast.success(`${shouldMakePrimary ? 'Primary' : 'Backup'} card has been added!`);
            }

            if (!accountDetails.stripeCustomerId) {
                let response: AccountDetailsResponseModel = await axios.get(`/account?id=${accountId}`);
                dispatch(accountActions.updateResponse(response));
            }
        } catch (err: any) {}
    };

    const onApplyVoucherCode = async e => {
        e.preventDefault();
        try {
            if (applyVoucherLoading || !voucherCode) {
                return;
            }
            setApplyVoucherLoading(true);
            const payload: any = {
                id: accountId,
                voucherCode,
                name: accountDetails.name,
                address: accountDetails.address,
            };

            if (accountDetails.stripeCustomerId) {
                payload.stripeCustomerId = accountDetails.stripeCustomerId;
            }
            if (accountDetails.biskoOrganizationId) {
                payload.biskoOrganizationId = accountDetails.biskoOrganizationId;
            }
            if (accountDetails.lookalikeEngineId) {
                payload.lookalikeEngineId = accountDetails.lookalikeEngineId;
            }

            const response: AccountDetailsResponseModel = await axios.post('/account', payload);
            if (!accountDetails.isActive) {
                toast.success('Your account has been activated!');
            }
            dispatch(accountActions.updateResponse(response));
        } catch (err: any) {
        } finally {
            setApplyVoucherLoading(false);
        }
    };

    const renderPaymentMethods = () => {
        if (!arePaymentMethodsLoading && paymentMethodsResponse && paymentMethodsResponse.model) {
            const {
                model: { paymentMethods, defaultPaymentMethodId },
            } = paymentMethodsResponse;

            return paymentMethods
                ?.sort((a, b) =>
                    a.id === paymentMethodsResponse.model.defaultPaymentMethodId
                        ? -1
                        : b.id === paymentMethodsResponse.model.defaultPaymentMethodId
                        ? 1
                        : 0
                )
                ?.map(item => (
                    <PaymentMethodItem
                        isPrimary={defaultPaymentMethodId === item.id}
                        key={item.id}
                        className="mt-2"
                        item={item}
                        onDelete={() => onClickDelete(item)}
                        onSetAsPrimary={() => onClickSetAsPrimary(item.id)}
                        actionLoading={actionLoading}
                    />
                ));
        }
        return null;
    };

    const { isAddingFirstCard, shouldAddMoreCards } = useMemo(() => {
        let isAddingFirstCard =
            paymentMethodsResponse && paymentMethodsResponse.model.paymentMethods
                ? paymentMethodsResponse?.model?.paymentMethods?.length < 1
                : true;
        return {
            isAddingFirstCard,
            shouldAddMoreCards: arePaymentMethodsLoading
                ? false
                : paymentMethodsResponse && paymentMethodsResponse.model.paymentMethods
                ? paymentMethodsResponse.model.paymentMethods.length < 2
                : true,
        };
    }, [paymentMethodsResponse, arePaymentMethodsLoading]);

    const cardClassName = `flex flex-col w-full rounded border p-2 sm:p-4 mt-4 flex flex-col w-full rounded border-mainBorder p-2 sm:p-4 mt-4`;
    return (
        <div className="flex flex-col flex-1 bg-white">
            <DeleteCardConfirmPopup
                cardIdToDelete={cardIdToDelete}
                currentCost={currentCost}
                deleteLoading={deleteLoading}
                isDeletingLastCard={isDeletingLastCard}
                onSubmitPayAndDelete={onSubmitPayAndDelete}
                onHide={() => {
                    setCardIdToDelete(null);
                }}
            />
            {shouldRenderCreateModal && (
                <AddPaymentCardModal
                    isVisible={isCreateModalVisible}
                    onHide={() => {
                        onToggleCreateModal(false);
                    }}
                    onSuccess={onSuccessCardCreated}
                />
            )}
            <AlertInfoComponent text={t('billing.info.message')} />

            <div className={cardClassName}>
                <span className="text-blueMainText text-base">{t('payment.methods')}</span>
                <span className="text-secondaryText font-medium text-xs mt-2">{t('cards.powered.by.stripe')}</span>
                <>
                    {arePaymentMethodsLoading && (
                        <div className="flex w-full items-center justify-center p-10">
                            <Loader />
                        </div>
                    )}
                    {renderPaymentMethods()}
                    {shouldAddMoreCards && (
                        <AddPaymentCardComponent
                            onClick={() => {
                                onToggleCreateModal(true);
                            }}
                            className="mt-2"
                            title={t(isAddingFirstCard ? 'add.primary.card' : 'add.backup.card')}
                        />
                    )}
                </>
            </div>
            <div className={`${cardClassName}`}>
                <span className="text-blueMainText text-base">{t('voucher.code')}</span>
                {!accountDetails.voucherCode && (
                    <span className="text-secondaryText font-medium text-xs mt-2">
                        {t('if.you.have.voucher.enter.it.below')}
                    </span>
                )}
                {!!accountDetails.voucherCode && (
                    <span className="text-xs mt-2 text-blueMainText font-medium">
                        {accountDetails.voucherExpirationDate ? (
                            <>
                                Expires at:
                                <span className="text-deleteColor">
                                    {' ' +
                                        format(
                                            new Date(accountDetails?.voucherExpirationDate || '2022/10/11'),
                                            'dd MMM yyyy'
                                        )}
                                </span>
                            </>
                        ) : (
                            t('voucher.has.no.expire.date', {
                                defaultValue: 'This voucher has No expiration date(lifetime)',
                            })
                        )}
                    </span>
                )}

                <form onSubmit={onApplyVoucherCode} className="flex w-full justify-between mt-4">
                    <InputField
                        autoComplete="voucher-code"
                        name="voucher-code"
                        value={accountDetails.voucherCode || voucherCode}
                        disabled={!!accountDetails.voucherCode || applyVoucherLoading}
                        onChange={e => {
                            setVoucherCode(e.target.value);
                        }}
                        placeholder={t('enter.voucher.code.here')}
                        hideLabel
                    />

                    <CreateButton
                        title={t('apply.code')}
                        style={{ paddingLeft: 24, paddingRight: 24 }}
                        className="px-6 ml-4 flex justify-center"
                        loading={applyVoucherLoading}
                        htmlType="submit"
                        disabled={!!accountDetails.voucherCode}
                    />
                </form>
            </div>
        </div>
    );
};
