import { ApexOptions } from 'apexcharts';

import merge from 'deepmerge';
import { CSSProperties, ReactNode, useMemo } from 'react';
import Chart, { Props } from 'react-apexcharts';
import { Loader } from '../ui';

const _options: ApexOptions = {
    chart: {
        height: 280,
        type: 'area',
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    series: [
        {
            name: 'Requests',
            data: [45, 52, 38, 45, 19, 23, 2],
        },
    ],
    grid: {
        strokeDashArray: 10,
        show: true,
        borderColor: '#DADFE7',
    },
    markers: {
        size: 6,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 11,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
            size: 8,
            sizeOffset: 10,
        },
    },
    fill: {
        type: 'gradient',
        colors: ['#3A81F6'],
        gradient: {
            opacityTo: 0.2,
        },
    },
    tooltip: {
        enabled: true,
        followCursor: false,
        intersect: false,
        marker: {
            show: false,
        },
        onDatasetHover: {
            highlightDataSeries: false,
        },
    },
    legend: {
        show: false,
    },
    yaxis: {
        labels: {
            formatter: val => val.toLocaleString('en-US'),
            style: {
                colors: '#7483A1',
            },
        },
    },
    xaxis: {
        tooltip: {
            enabled: false,
        },
        labels: {
            style: {
                colors: '#7483A1',
            },
            show: true,
            hideOverlappingLabels: true,
        },
        categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan'],
    },
};

interface AreaChartProps extends Props {
    title?: string;
    containerClassName?: string;
    containerStyle?: CSSProperties;
    data: Record<string, any> | null;
    labelName: string;
    loading?: boolean;
    noDataMessage?: string | ReactNode;
}

export const AreaChart: React.FC<AreaChartProps> = ({
    data,
    height,
    labelName,
    width,
    loading,
    title,
    containerClassName,
    containerStyle,
    noDataMessage,
    ...props
}) => {
    const { categories, series } = useMemo(() => {
        let categories: string[] = [],
            seriesData: any[] = [];
        let series: ApexOptions['series'] = [];

        if (data) {
            Object.entries(data ?? {}).forEach(([key, value]) => {
                categories.push(key);
                seriesData.push(value);
            });
        }

        series = [
            {
                name: labelName,
                color: '#3A81F6',
                data: seriesData,
            },
        ];

        return {
            categories,
            series,
        };
    }, [data]);

    const options = useMemo(() => {
        let _mergedOptions = merge({ ..._options }, { ...props.options });
        _mergedOptions.xaxis.categories = categories;
        return _mergedOptions;
    }, [props.options, categories]);

    const { initialLoading, refreshLoading } = useMemo(() => {
        const dataArray = Object.entries(data ?? {});
        return {
            initialLoading: loading && !dataArray.length,
            refreshLoading: loading && !!dataArray.length,
        };
    }, [loading, data]);

    return (
        <div className={`flex flex-col relative w-full h-64 bg-white ${containerClassName}`} style={containerStyle}>
            <h3 className="text-blueDark600 text-base font-bold ml-2 mb-1">{title}</h3>
            {refreshLoading && (
                <div className="absolute top-4 right-4">
                    <Loader size={34} />
                </div>
            )}
            {initialLoading ? (
                <div className="h-68 w-full justify-center items-center flex">
                    <Loader size={34} />
                </div>
            ) : (
                <Chart options={options} series={series} type="area" width="100%" height={'100%'} />
            )}
        </div>
    );
};
