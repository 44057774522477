import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAlgorithmGroupModal } from '../../../components/modals';
import { toast } from '../../../components/ui';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
import { AlgorithmGroupListResponseModel, AlgorithmGroupsListModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/algorithm/algorithmGroupsThunk';
import { AdminTable } from '../components';

export const AdminAlgorithmGroupsPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({});

    /**
     * Selectors
     */
    const response: AlgorithmGroupListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    /**
     * Local state
     */
    const [selectedItemForEdit, setItemForEdit] = useState<any>(null);

    const requestList = (query: any = {}) => {
        dispatch(actions.request({ ...query, isFromAdmin: true }));
    };
    useEffect(() => {
        requestList({ page: 0 });
    }, []);

    const onRefresh = () => {
        !loading && requestList();
    };

    const onClickEdit = (row: AlgorithmGroupsListModel) => {
        onToggleCreateModal(true);
        setItemForEdit(row);
    };
    const onClickCreate = () => {
        onToggleCreateModal(true);
        setItemForEdit(null);
    };
    const hideCreateModal = () => {
        onToggleCreateModal(false);
        !!selectedItemForEdit && setItemForEdit(null);
    };

    const onSuccess = isEditing => {
        toast.success(
            isEditing
                ? t('@algorithm.group.success.edited', {
                      defaultValue: 'Algorithm group has been edited!',
                  })
                : t('@algorithm.group.success.created', {
                      defaultValue: 'Algorithm group has been created!',
                  })
        );
        hideCreateModal();
        requestList({ page: 0 });
    };

    return (
        <>
            {shouldRenderCreateModal && (
                <CreateAlgorithmGroupModal
                    isVisible={isCreateModalVisible}
                    onHide={hideCreateModal}
                    onSuccess={onSuccess}
                    isEditing={!!selectedItemForEdit}
                    algorithmGroupForEdit={selectedItemForEdit}
                />
            )}
            <AdminTable
                modelEndpoint="algorithmGroup"
                requestList={requestList}
                reduxActions={actions}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row?.name ?? '',
                    },
                ]}
                response={response}
                loading={loading}
                initLoading={initLoading}
                onClickEdit={onClickEdit}
                onPageChanged={page => {
                    requestList({ page });
                }}
                headerButtonProps={{
                    onClickCreate,
                    onRefresh,
                }}
            />
        </>
    );
};
