import React from 'react';
import { InputField } from '../../../../components/inputs';
import { FlowActionListModel } from '../../../../models';
import axios from '../../../../services/axios';

export interface EditOrderComponentProps {
    flowAction: FlowActionListModel;
    onSuccess: () => any;
    disable?: boolean;
}

export const EditOrderComponent: React.FC<EditOrderComponentProps> = ({ flowAction, disable, onSuccess }) => {
    const { id, orderNumber } = flowAction;

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (disable) {
            return;
        }
        let value = e.currentTarget.elements['orderNumber']?.value || orderNumber;

        try {
            let payload = {
                id,
                patch: [
                    {
                        op: 'replace',
                        path: 'orderNumber',
                        value,
                    },
                ],
            };
            await axios.patch('/flowAction', payload);
            onSuccess();
        } catch (err: any) {}
    };

    return (
        <form onSubmit={onSubmit}>
            <InputField
                disabled={disable}
                name="orderNumber"
                htmlType="number"
                defaultValue={orderNumber}
                width="100%"
                placeholder="write here..."
                label="Order Number"
            />
        </form>
    );
};
