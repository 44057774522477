import { useEffect } from 'react';
import { CSSProperties, useState } from 'react';

export interface AccountImageProps {
    url?: string;
    account?: string;
    className?: string;
    style?: CSSProperties;
    classNameForNoImage?: string;
}
export const AccountImage: React.FC<AccountImageProps> = ({ account, className, style, url, classNameForNoImage }) => {
    const [displayFirstChar, setDisplayFirstChar] = useState(false);

    useEffect(() => {
        if (displayFirstChar) setDisplayFirstChar(false);
    }, [url]);

    return (
        <div className={`flex h-7 w-7 items-center justify-center ${className}`} style={style}>
            {displayFirstChar ? (
                <span
                    className={`w-full h-full rounded border border-grey200 flex items-center justify-center text-xs font-medium text-blueMainText ${classNameForNoImage}`}
                >
                    {account && account[0].toLocaleUpperCase()}
                </span>
            ) : url ? (
                <img
                    alt={account}
                    onError={() => {
                        setDisplayFirstChar(true);
                    }}
                    src={url}
                    className="w-full h-full rounded border border-grey200 object-cover"
                />
            ) : null}
        </div>
    );
};
