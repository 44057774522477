import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import { HeaderForCreatePages } from '../../../components/common';
import DynamicInputsForm, { SingleItemInputsType } from '../../../components/common/dynamic-inputs/DynamicInputsForm';
import { InputField } from '../../../components/inputs';
import { LoadingModal } from '../../../components/modals';
import { toast } from '../../../components/ui';
import { ADMIN_INTEGRATION_TYPES_PATH, dataTypesOptions, inputTypeOptions } from '../../../constants';
import { useCurrentWidth } from '../../../hooks';
import {
    ExternalSourceTypeListResponseModel,
    IntegrationTypeDetailsResponseModel,
    IntegrationTypePostModel,
} from '../../../models';
import {
    selectors as externalSourceSelectors,
    actions as externalSourceTypesActions,
} from '../../../redux/thunk/app/externalSourceTypesThunk';
import axios from '../../../services/axios';
import { formatArrayForSelectOptions, getFormattedQuery } from '../../../utils';
import { AdminFooterButtons } from '../components/AdminFooterButtons';

type fieldValuesType = {
    name: string;
    code: string;
    imageUrl: string;
    endpoint: string;
};

export const AdminCreateIntegrationTypePage = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const history = useHistory();
    const params: { id?: string } = useParams();
    const dispatch = useDispatch();

    /**
     * Selectors
     */
    const { models: externalSources }: ExternalSourceTypeListResponseModel = useSelector(
        externalSourceSelectors.getResponse
    );
    const externalSourcesLoading = useSelector(externalSourceSelectors.getIsLoading);

    /**
     * constants - refs
     */
    const isEditing = !!params?.id;
    type inputsRefType = React.ElementRef<typeof DynamicInputsForm>;
    const inputsRef = useRef<inputsRefType>(null);

    /**
     * Local State
     */
    const [loadingModal, setLoadingModal] = useState(false);
    const [fieldValues, setFieldValues] = useState<fieldValuesType>({
        name: '',
        code: '',
        imageUrl: '',
        endpoint: '',
    });

    useEffect(() => {
        dispatch(externalSourceTypesActions.request({ endpoint: '/bridge/externalSourceType/list' }));
    }, []);

    useEffect(() => {
        if (isEditing && !externalSourcesLoading) {
            const id = params?.id;
            fetchIntegrationTypeDetails(id);
        }
    }, [params, externalSourcesLoading]);

    const fetchIntegrationTypeDetails = async id => {
        try {
            setLoadingModal(true);
            const response: IntegrationTypeDetailsResponseModel = await axios.get(
                `/bridge/integrationType?${getFormattedQuery({ id })}`
            );
            mapDetailsInState(response);
        } catch (err: any) {
        } finally {
            setLoadingModal(false);
        }
    };

    const mapDetailsInState = (response: IntegrationTypeDetailsResponseModel) => {
        const {
            model: { properties: _inputs, endpoint, name, imageUrl, code },
        } = response;

        let inputs: SingleItemInputsType[] = [];

        setFieldValues({
            name,
            code,
            endpoint,
            imageUrl,
        });

        if (_inputs) {
            inputs = _inputs?.map((_input, i) => ({
                ..._input,
                dataTypeId:
                    dataTypesOptions.find(_dataTypesOption => _dataTypesOption.value === _input.dataTypeId) || null,
                _id: i + 1,
                options: _input?.options.map((_inputOption, i) => ({
                    id: _inputOption.id || 0,
                    _id: i + 1,
                    ..._inputOption,
                })),
                inputType:
                    inputTypeOptions?.find(_inputTypeOption => _inputTypeOption.value === _input.inputType) || null,
                externalSourceTypeId: formatArrayForSelectOptions(externalSources, 'title', 'id').find(
                    (_externalSource: any) => _externalSource?.value === _input.externalSourceTypeId?.toString()
                ),
            }));
        }

        if (inputsRef.current?.setInputs) {
            inputsRef.current?.setInputs(inputs);
        }
    };

    const onChangeInputs = (key, value) => {
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleCreate = async (save?: boolean) => {
        try {
            setLoadingModal(true);

            let inputs: SingleItemInputsType[] = inputsRef.current?.getInputs() || [];
            const { name, endpoint, code, imageUrl } = fieldValues;

            let payload: IntegrationTypePostModel = {
                id: isEditing ? parseInt(params?.id ?? '0') : 0,
                name,
                code,
                endpoint,
                imageUrl,
                properties: inputs.map(x => ({
                    id: x.id,
                    dataTypeId: x.dataTypeId?.value ?? '',
                    defaultValue: x.defaultValue,
                    inputType: x.inputType?.value || '',
                    name: x.name,
                    options: x.options.map(x => ({
                        label: x.label,
                        value: x.value,
                        id: x.id,
                    })),
                    required: x.required,
                    orderNumber: x.orderNumber,
                    isColumn: x.isColumn,
                    conditionalId: x.conditionalId || -1,
                    conditionalValue: x.conditionalValue,
                    infoText: x.infoText,
                    infoLink: x.infoLink,
                    externalSourceTypeId: parseInt(x?.externalSourceTypeId?.value || '0'),
                    isRepeatable: !!x.isRepeatable,
                    inputName: '',
                })),
            };

            let res: IntegrationTypeDetailsResponseModel = await axios.post('/bridge/integrationType', payload);

            toast.success(`Integrations Type ${isEditing ? 'Edited ' : 'Created'} Successfully!`);

            if (save) {
                if (isEditing) mapDetailsInState(res);
                else history.push(`${ADMIN_INTEGRATION_TYPES_PATH}/edit-integration-type/${res.model.id}`);
            } else {
                history.push(ADMIN_INTEGRATION_TYPES_PATH);
            }
        } catch (err: any) {
            console.log({ err });
        } finally {
            setLoadingModal(false);
        }
    };
    const handleSubmit = async e => {
        e.preventDefault();
        handleCreate();
    };

    return (
        <PageAnimationContainer>
            <div>
                <form className="flex flex-col w-full items-center pb-4" onSubmit={handleSubmit}>
                    {loadingModal && <LoadingModal isVisible />}
                    <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                    <div className="flex flex-col" style={{ width: currentWidth < 900 ? '100%' : 546 }}>
                        <InputField
                            name="type"
                            label="Name"
                            className="w-full"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('name', e.target.value)}
                            value={fieldValues.name}
                            required
                        />
                        <InputField
                            name="integrationTypeCode"
                            label="Code"
                            className="w-full mt-4"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('code', e.target.value)}
                            value={fieldValues.code}
                            required
                        />
                        <InputField
                            name="integrationTypeImageUrl"
                            label="Image Url"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('imageUrl', e.target.value)}
                            value={fieldValues.imageUrl}
                            required
                            className="mt-4"
                        />
                        <InputField
                            name="integrationTypeEndpoint"
                            label="Endpoint"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeInputs('endpoint', e.target.value)}
                            value={fieldValues.endpoint}
                            required
                            className="mt-4"
                        />
                    </div>

                    <HeaderForCreatePages hideIcon title="Properties" />
                    <DynamicInputsForm ref={inputsRef} isEditing={isEditing} />

                    <HeaderForCreatePages hideIcon title="" />
                    <AdminFooterButtons
                        submitTitle={isEditing ? 'Edit Integration Type' : 'Create Integration Type'}
                        onClickSave={() => handleCreate(true)}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
