import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface FullScreenModalProps {
    isVisible: boolean;
    onHide: () => void;
}

export const FullScreenModal: React.FC<FullScreenModalProps> = ({ isVisible, onHide, children }) => {
    useEffect(() => {
        document.addEventListener('keydown', onKeyPress);
        return () => document.removeEventListener('keydown', onKeyPress);
    }, []);

    const onKeyPress = e => e?.key === 'Escape' && onHide && onHide();

    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setShowChildren(isVisible);
            }, 350);
        } else {
            setShowChildren(false);
        }
    }, [isVisible]);

    return createPortal(
        <div
            onClick={() => onHide()}
            className={`inset-0 cursor-pointer absolute flex flex-col z-50 p-6 transition-all h-full ${
                isVisible ? 'bg-mainOverlay/40' : 'bg-none'
            } `}
        >
            <div
                onClick={e => e.stopPropagation()}
                className={`w-full bg-white flex transform-gpu rounded flex-col overflow-y-auto z-40 flex-1 transition-all duration-300 ${
                    isVisible ? 'opacity-100 scale-100 pointer-events-auto' : 'opacity-0 scale-50 pointer-events-none'
                }`}
            >
                {showChildren && children}
            </div>
        </div>,
        document.body
    );
};
