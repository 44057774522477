//@ts-nocheck
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useLocationBlocker() {
    const history = useHistory();
    useEffect(
        () => {
            if (history && history?.block) {
                history.block(
                    (location, action) =>
                        action !== 'PUSH' || getLocationId(location) !== getLocationId(history.location)
                );
            }
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );
}

function getLocationId({ pathname, search, hash }) {
    return pathname + (search ? '?' + search : '') + (hash ? '#' + hash : '');
}
