import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CommonFlowProps } from '..';
import { OutlineButton } from '../../../../components/buttons';
import {
    FooterButtons,
    HeaderForCreatePages,
    Icon,
    IconTypes,
    RunScheduleComponent,
    shouldDynamicInputRender,
} from '../../../../components/common';
import { Loader, toast } from '../../../../components/ui';
import { artifactContentTypes, inputTypes, runSchedules } from '../../../../constants';
import { scrollBarContext } from '../../../../contexts';
import { useAccountId, useCurrentWidth } from '../../../../hooks';
import {
    BaseEntityProperty,
    BaseEntityValue,
    FlowActionDetailsResponseModel,
    FlowActionListResponseModel,
    FlowActionProperty,
    FlowInstanceTypeListResponseModel,
    SelectOptionType,
    TabularDataSummary,
} from '../../../../models';
import { selectors as instanceTypesSelectors } from '../../../../redux/thunk/app/flow/flowInstanceTypesThunk';
import axios from '../../../../services/axios';
import {
    addNewOptionToDynamicInputs,
    deleteErrorKey,
    formatArrayForSelectOptions,
    getFormattedQuery,
    getLastObjectFromArray,
    replaceLastObjectInArray,
} from '../../../../utils';
import FlowStep from './FlowStep';

export interface CreateTabularFlowProps extends CommonFlowProps {
    initialTabularSummary: TabularDataSummary | null;
}
export interface FlowStepStateType {
    id: number;
    _id: string;
    sourceColumnSelected: SelectOptionType | null;
    actions: SelectOptionType[];
    actionSelected: SelectOptionType | null;
    inputs: BaseEntityProperty[];
    tabularSummary: TabularDataSummary | null;
    tabularSummaryLoading: boolean;
    errors?: any;
}
const initialStep: FlowStepStateType = {
    _id: nanoid(),
    id: 0,
    actionSelected: null,
    actions: [],
    inputs: [],
    sourceColumnSelected: null,
    tabularSummary: null,
    tabularSummaryLoading: false,
    errors: {},
};

type runScheduleDataType = {
    scheduleFrequency: any;
    instanceType: SelectOptionType | null;
};

export const CreateTabularFlow: React.FC<CreateTabularFlowProps> = ({
    basicInformation,
    onValidateBasicInformation,
    initialTabularSummary,
    flowEditResponseModel,
    formId,
    onCancel,
    onSuccess,
}) => {
    /**
     * Hooks
     */
    const accountId = useAccountId();
    const currentWidth = useCurrentWidth();
    const { t } = useTranslation();
    const isEditing = useMemo(() => !!flowEditResponseModel, [flowEditResponseModel]);
    const artifactId = parseInt(basicInformation.artifact?.value || '0');
    const { containerElement } = useContext(scrollBarContext);
    const lastStepRef = useRef<HTMLDivElement>(null);

    /**
     * Selectors
     */
    const instanceTypesResponse: FlowInstanceTypeListResponseModel = useSelector(instanceTypesSelectors.getResponse);
    const instanceTypesLoading = useSelector(instanceTypesSelectors.getIsLoading);
    const instanceTypesOptions: SelectOptionType[] = useMemo(
        () =>
            instanceTypesResponse.models
                ? instanceTypesResponse.models.map(x => ({ value: x.id?.toString(), label: x.name, ...x }))
                : [],
        [instanceTypesResponse]
    );

    /**
     * Local State
     */
    const [errors, setErrors] = useState<any>({});
    const [flowSteps, setFlowSteps] = useState<FlowStepStateType[]>([
        { ...initialStep, tabularSummary: initialTabularSummary },
    ]);
    const [formLoading, setFormLoading] = useState<{ runImmediately: boolean; loading: boolean }>({
        runImmediately: false,
        loading: false,
    });
    const [addStepLoading, setAddStepLoading] = useState(false);
    const [actionsLoading, setActionsLoading] = useState(false);
    const [inputsLoading, setInputsLoading] = useState(false);
    const [mapFlowLoading, setMapFlowLoading] = useState(false);
    const [runScheduleData, setRunScheduleData] = useState<runScheduleDataType>({
        scheduleFrequency: '',
        instanceType: null,
    });
    const [scheduleSelectedOption, setScheduleSelectedOption] = useState<SelectOptionType | null>(null);

    useEffect(() => {
        if (initialTabularSummary) {
            setFlowSteps([{ ...initialStep, tabularSummary: initialTabularSummary }]);
        }
    }, [initialTabularSummary]);

    useEffect(() => {
        if (flowEditResponseModel) {
            mapFlowDetails();
        }
    }, [flowEditResponseModel]);

    const onScrollToLastStep = () => {
        if (containerElement && lastStepRef.current) {
            let top = lastStepRef.current.offsetTop;
            containerElement.scrollTo({
                top,
                behavior: 'smooth',
            });
        }
    };

    /**
     * Methods for Edit
     */
    const mapInputs = (
        properties: BaseEntityProperty[],
        values: BaseEntityValue[],
        initialSummary?: TabularDataSummary,
        summaryForGroupBy?: TabularDataSummary
    ) => {
        return properties
            .map((property: BaseEntityProperty, index) => {
                let _inputs: BaseEntityValue[] | undefined = values.filter(el => el.propertyId === property.id) || [];

                if (_inputs.length < 1) {
                    _inputs = [
                        {
                            id: 0,
                            propertyId: property.id,
                            propertyName: property.name,
                            value: property.defaultValue,
                        },
                    ];
                }

                let _arr = _inputs.map(input => {
                    let options = property.options;
                    if (property.isColumn) {
                        options = formatArrayForSelectOptions(
                            index === 0
                                ? summaryForGroupBy?.columns || initialSummary?.columns || []
                                : initialSummary?.columns,
                            'name',
                            'name'
                        );
                    }

                    let value: any = null;
                    if (property.inputType === inputTypes.dropdown || property.inputType === inputTypes.dropdownOpen) {
                        value = input?.value
                            ? options?.find(x => x.value === input?.value)
                            : options?.find(x => x.value === property?.defaultValue) || null;

                        if (!value && input?.value) {
                            let newOption = {
                                label: input?.value || '',
                                value: input?.value || '',
                            };
                            options.push(newOption);
                            value = newOption;
                        }
                    } else {
                        value = input?.value || property?.defaultValue || '';
                    }

                    return {
                        ...property,
                        options,
                        value,
                        inputName: property?.isRepeatable ? `${property.name} ${nanoid()}` : property.name,
                        valueId: input?.id || 0,
                    };
                });
                return _arr;
            })
            .flat();
    };
    const mapFlowDetails = async () => {
        if (!flowEditResponseModel) return;

        try {
            setMapFlowLoading(true);
            const { scheduleId, scheduleFrequency, instanceType, steps } = flowEditResponseModel.model;
            setScheduleSelectedOption(runSchedules.find(x => x.value === scheduleId.toString()) || null);
            setRunScheduleData({
                scheduleFrequency: scheduleFrequency,
                instanceType: instanceType
                    ? {
                          label: instanceType?.name,
                          value: instanceType?.id?.toString(),
                      }
                    : null,
            });

            let _flowSteps: FlowStepStateType[] = [];

            let initialSummary: TabularDataSummary = await axios.post('/summary/tabular', {
                accountId,
                artifactId,
            });

            if (!initialSummary) {
                toast.error(`"${basicInformation?.artifact?.label}" artifact doesn't have a data summary!`);
                return;
            }

            for (let i = 0; i < steps.length; i++) {
                let previousStep: FlowStepStateType | null = i > 0 ? _flowSteps[i - 1] : null;
                let currentStep = steps[i];
                let newStep: FlowStepStateType | null = null;
                if (i > 0) {
                    const payload = {
                        accountId,
                        artifactId,
                        tabularSummary: previousStep?.tabularSummary,
                        step: {
                            id: previousStep?.id,
                            flowActionId: previousStep?.actionSelected?.value,
                            column: previousStep?.sourceColumnSelected?.value,
                            values: previousStep?.inputs.map(x => ({
                                id: x.valueId,
                                propertyId: x.id,
                                value: typeof x?.value === 'object' ? x.value?.value : x?.value,
                            })),
                        },
                    };
                    initialSummary = await axios.post('/summary/tabular', payload);
                }

                const query = {
                    id: currentStep?.flowActionId,
                    artifactId,
                };
                let { model }: FlowActionDetailsResponseModel = await axios.get(
                    `/flowAction?${getFormattedQuery(query)}`
                );

                let summaryForGroupBy: TabularDataSummary | null = null;
                if (model.name === 'GroupBy') {
                    summaryForGroupBy = await axios.post('/summary/tabular', {
                        accountId,
                        artifactId,
                        tabularSummary: previousStep?.tabularSummary || initialSummary,
                        step: {
                            id: 0,
                            flowActionId: model.id,
                            column: currentStep.column,
                            values: [],
                        },
                    });
                }

                let inputs: BaseEntityProperty[] = mapInputs(
                    model.properties,
                    currentStep.values,
                    initialSummary,
                    summaryForGroupBy || undefined
                );

                let dataType = initialSummary.columns.find(x => x.name === currentStep.column)?.dataType;
                const flowActionListQuery = {
                    page: -1,
                    dataType,
                    actionType: artifactContentTypes.tabular,
                };
                const response: FlowActionListResponseModel = await axios.get(
                    `/flowAction/list?${getFormattedQuery(flowActionListQuery)}`
                );

                let actions = formatArrayForSelectOptions(response.models, 'name', 'id');
                newStep = {
                    _id: nanoid(),
                    id: currentStep.id,
                    inputs,
                    tabularSummary: initialSummary,
                    tabularSummaryLoading: false,
                    actions,
                    errors: {},
                    actionSelected: {
                        label: currentStep.actionName,
                        value: currentStep.flowActionId.toString(),
                    },
                    sourceColumnSelected: { label: currentStep.column, value: currentStep.column },
                };

                newStep && _flowSteps.push(newStep);
            }

            setFlowSteps(_flowSteps);
        } catch (err) {
            console.log({ err });
        } finally {
            setMapFlowLoading(false);
        }
    };

    /**
     * Fetch methods
     */
    const fetchActions = async dataType => {
        try {
            setActionsLoading(true);
            const query = {
                page: -1,
                dataType,
                actionType: artifactContentTypes.tabular,
            };

            const response: FlowActionListResponseModel = await axios.get(
                `/flowAction/list?${getFormattedQuery(query)}`
            );
            let actions = formatArrayForSelectOptions(response.models, 'name', 'id');
            onChangeLastFlow([{ key: 'actions', value: actions }]);
        } catch (err: any) {
        } finally {
            setActionsLoading(false);
        }
    };
    const fetchInputs = async flowActionId => {
        try {
            setInputsLoading(true);
            const query = {
                id: flowActionId,
                artifactId,
            };
            const flowDetailsResponse: FlowActionDetailsResponseModel = await axios.get(
                `/flowAction?${getFormattedQuery(query)}`
            );
            let _customSummary: TabularDataSummary | null = null;

            const { tabularSummary, sourceColumnSelected }: FlowStepStateType = getLastObjectFromArray(flowSteps);
            if (flowDetailsResponse.model.name === 'GroupBy') {
                let payload = {
                    accountId,
                    artifactId: artifactId,
                    tabularSummary: tabularSummary,
                    step: {
                        id: 0,
                        flowActionId,
                        column: sourceColumnSelected?.value,
                        values: [],
                    },
                };
                _customSummary = await axios.post('/summary/tabular', payload);
            }

            let inputs = flowDetailsResponse.model.properties.map((el, i) => {
                let options = el.isColumn
                    ? formatArrayForSelectOptions(
                          i === 0 ? _customSummary?.columns || tabularSummary?.columns : tabularSummary?.columns,
                          'name',
                          'name'
                      )
                    : el?.options || [];

                if (
                    el.inputType === inputTypes.dropdown ||
                    el.inputType === inputTypes.dropdownOpen ||
                    el.inputType === inputTypes.dropdownMultiSelect
                ) {
                    return {
                        ...el,
                        value: !!el?.defaultValue
                            ? el.inputType === inputTypes.dropdownMultiSelect
                                ? el.defaultValue
                                : options?.find(x => x?.value === el?.defaultValue)
                            : null,
                        inputName: el.name,
                        options: options,
                    };
                }

                return {
                    ...el,
                    inputName: el.name,
                    value: el?.defaultValue || '',
                };
            });
            onChangeLastFlow([{ key: 'inputs', value: inputs }]);
        } catch (err: any) {
        } finally {
            setInputsLoading(false);
        }
    };

    /**
     * On Change Methods
     */
    const onChangeRunScheduleData = (key, value) => {
        deleteErrorKey(key, setErrors);
        setRunScheduleData(prev => ({ ...prev, [key]: value }));
    };
    const onChangeInputsList = (_inputs: BaseEntityProperty[]) => {
        onChangeLastFlow([{ key: 'inputs', value: _inputs }]);
    };

    const onChangeInputs = (key, value) => {
        let lastFlow: FlowStepStateType = getLastObjectFromArray(flowSteps);
        let inputs = lastFlow.inputs.map(x => (x.inputName === key ? { ...x, value } : x));

        let _errors = lastFlow.errors;
        if (_errors[key]) delete _errors[key];

        setFlowSteps(prev => {
            let step = getLastObjectFromArray(prev);
            step.inputs = inputs;
            step.errors = _errors;
            return replaceLastObjectInArray(prev, step);
        });
    };
    const onChangeLastFlow = (kyeValues: { key: string; value: any }[]) => {
        setFlowSteps(prev => {
            let step = getLastObjectFromArray(prev);
            kyeValues.forEach(x => {
                step[x.key] = x.value;
            });
            return replaceLastObjectInArray(prev, step);
        });
    };

    const onChangeSourceColumn = (column: any) => {
        fetchActions(column?.dataType);
        let lastFlow: FlowStepStateType = getLastObjectFromArray(flowSteps);

        const _errors = lastFlow.errors;
        if (_errors.sourceColumnSelected) delete _errors.sourceColumnSelected;

        onChangeLastFlow([
            { key: 'errors', value: _errors },
            { key: 'sourceColumnSelected', value: column },
            { key: 'actionSelected', value: null },
            {
                key: 'inputs',
                value: [],
            },
        ]);
    };

    const onChangeAction = (action: SelectOptionType) => {
        fetchInputs(action?.value);
        let lastFlow: FlowStepStateType = getLastObjectFromArray(flowSteps);

        const _errors = lastFlow.errors;
        if (_errors.actionSelected) delete _errors.actionSelected;

        onChangeLastFlow([
            { key: 'errors', value: _errors },
            { key: 'actionSelected', value: action },
        ]);
    };
    const addNewOptionToInput = (inputName: string, value: string) => {
        try {
            const lastFlow: FlowStepStateType = getLastObjectFromArray(flowSteps);
            let inputs = addNewOptionToDynamicInputs(lastFlow.inputs, inputName, value);
            onChangeLastFlow([{ key: 'inputs', value: inputs }]);
        } catch (err: any) {}
    };

    /**
     * On Click Methods
     */
    const onAddNewStep = async () => {
        try {
            if (flowSteps.length < 1) {
                setFlowSteps([{ ...initialStep, tabularSummary: initialTabularSummary }]);
                return;
            }
            if (!onValidateLastStep().isValid) {
                return;
            }
            setAddStepLoading(true);
            const { inputs, actionSelected, tabularSummary, sourceColumnSelected }: FlowStepStateType =
                getLastObjectFromArray(flowSteps);

            let payload = {
                accountId,
                artifactId: artifactId,
                step: {
                    id: 0,
                    flowActionId: actionSelected?.value,
                    column: sourceColumnSelected?.value,
                    values: inputs
                        ?.filter(x => shouldDynamicInputRender(x, inputs))
                        .map((inp: FlowActionProperty) => ({
                            id: 0,
                            propertyId: inp.id,
                            value: inp?.value?.value || inp?.value || '',
                        })),
                },
                tabularSummary: tabularSummary,
            };
            const response: TabularDataSummary = await axios.post('/summary/tabular', payload);
            setFlowSteps(prev => [...prev, { ...initialStep, _id: nanoid(), tabularSummary: response }]);
            setTimeout(() => {
                onScrollToLastStep();
            }, 100);
            toast.success(t('@toast-message.success-add-step'));
        } catch (err: any) {
            console.log({ err });
        } finally {
            setAddStepLoading(false);
        }
    };

    const onDeleteStep = () => {
        setFlowSteps(prev => {
            let _prev = [...prev];
            _prev.pop();
            return _prev;
        });
    };

    const handleSave = async (runImmediately = false) => {
        if (addStepLoading || mapFlowLoading || !onValidateForm()) return;

        try {
            setFormLoading({ runImmediately, loading: true });

            const { artifact, description, title } = basicInformation;
            const { scheduleFrequency, instanceType } = runScheduleData;
            const payload: any = {
                id: isEditing ? flowEditResponseModel?.model.id : 0,
                accountId,
                instanceTypeId: instanceType?.value,
                title,
                description,
                inputArtifactId: artifact?.value,
                runImmediately,
                scheduleId: scheduleSelectedOption?.value,
                scheduleFrequency: !!scheduleFrequency ? scheduleFrequency : 0,
                steps: flowSteps.map(flow => ({
                    id: flow.id,
                    flowActionId: flow.actionSelected?.value,
                    column: flow.sourceColumnSelected?.value,
                    values: flow.inputs.map(x => ({
                        id: isEditing ? x.valueId : 0,
                        propertyId: x.id,
                        value: typeof x.value === 'object' ? x.value?.value : x.value,
                    })),
                })),
            };
            await axios.post('/flow', payload);
            setFormLoading({ runImmediately, loading: false });
            onSuccess(isEditing, runImmediately);
        } catch (err) {
            setFormLoading({ runImmediately, loading: false });
        }
    };
    const handleSubmit = e => {
        e.preventDefault();
        handleSave();
    };

    /**
     * Helper methods
     */
    const onValidateForm = () => {
        toast.dismiss();
        if (!onValidateBasicInformation()) {
            toast.error('You have field errors at basic information!');
            return false;
        }

        if (!onValidateLastStep().isValid) {
            toast.error('You have field errors at last step!');
            return false;
        }

        if (!onValidateRunSchedule()) {
            toast.error('You have errors at Run schedule fields!');
            return false;
        }

        return true;
    };

    const onValidateLastStep = () => {
        let response = {
            message: '',
            isValid: true,
        };
        let _errors: any = {};
        const { actionSelected, sourceColumnSelected, inputs } = flowSteps[flowSteps.length - 1];

        if (!actionSelected) {
            response.isValid = false;
            _errors.actionSelected = 'Please choose an action';
        }

        if (!sourceColumnSelected) {
            response.isValid = false;
            _errors.sourceColumnSelected = 'Source column is required!';
        }

        inputs.forEach(x => {
            if (shouldDynamicInputRender(x, inputs) && x.required && !x.value) {
                _errors[x.inputName] = 'This field is required!';
                response.isValid = false;
            }
        });

        if (!response.isValid) {
            response.message = 'You have Errors on last step!';
        }
        onChangeLastFlow([{ key: 'errors', value: _errors }]);
        return response;
    };
    const onValidateRunSchedule = () => {
        let isValid = true;
        let _errors: any = {};

        if (!scheduleSelectedOption) {
            _errors.schedule = 'Please select a schedule!';
            isValid = false;
        }

        if (scheduleSelectedOption?.value === '2' || scheduleSelectedOption?.value === '3') {
            if (!runScheduleData?.scheduleFrequency) {
                _errors.scheduleFrequency = 'This field is required!';
                isValid = false;
            }
        }

        if (!runScheduleData.instanceType) {
            _errors.instanceType = 'Instance type is required!';
            isValid = false;
        }

        setErrors(_errors);
        return isValid;
    };

    const renderSteps = () => {
        return flowSteps.map((flow, index) => {
            let length = flowSteps.length;
            let isLastStep = length - 1 === index;

            return (
                <div key={flow._id}>
                    {isLastStep && <div ref={lastStepRef} />}
                    <FlowStep
                        errors={flow.errors}
                        title={`${t('@pages.workflow.feature.engineering-flow-step')} ${index + 1}`}
                        isStepActive={isLastStep && !addStepLoading}
                        actionOptions={flow.actions}
                        actionSelected={flow.actionSelected}
                        onChangeInput={onChangeInputs}
                        onChangeInputsList={onChangeInputsList}
                        areActionsLoading={isLastStep && actionsLoading}
                        isActionDisabled={!isLastStep}
                        showDeleteButton={isLastStep && length > 1}
                        areInputsLoading={inputsLoading && isLastStep}
                        areSourceColumnsLoading={false}
                        inputs={flow.inputs}
                        onAddNewOption={addNewOptionToInput}
                        onChangeAction={onChangeAction}
                        onChangeSourceColumn={onChangeSourceColumn}
                        onDeleteStep={onDeleteStep}
                        sourceColumnOptions={
                            flow.tabularSummary?.columns?.map(x => ({
                                label: x.name,
                                value: x.name,
                                ...x,
                            })) || []
                        }
                        sourceColumnSelected={flow.sourceColumnSelected}
                    />
                </div>
            );
        });
    };

    const saveButtonsDisabled = useMemo(
        () => addStepLoading || mapFlowLoading || formLoading.loading,
        [addStepLoading, mapFlowLoading, formLoading]
    );

    return (
        <form className="w-full" id={formId} onSubmit={handleSubmit}>
            <HeaderForCreatePages
                className="mt-10"
                title={t('@pages.workflow.feature.engineering-flows') + ' - Tabular'}
            />

            {mapFlowLoading ? (
                <div className="w-full items-center justify-center flex flex-col p-10">
                    <Loader size={40} />
                    <span className="mt-2 text-sm font-medium text-blueMainText">
                        Loading {flowEditResponseModel?.model.steps.length} steps
                    </span>
                </div>
            ) : (
                <div className={`w-full flex flex-col ${currentWidth > 1120 && 'items-center'} `}>
                    {renderSteps()}
                    <OutlineButton
                        isLoading={addStepLoading}
                        style={{ height: 42 }}
                        icon={<Icon name={IconTypes['plus-main-color']} className="mr-2" />}
                        onClick={() => !addStepLoading && onAddNewStep()}
                        title={t('@common.add-step')}
                    />
                </div>
            )}
            <RunScheduleComponent
                instanceTypeProps={{
                    loading: instanceTypesLoading,
                    onChange: value => {
                        onChangeRunScheduleData('instanceType', value);
                    },
                    options: instanceTypesOptions,
                    value: runScheduleData.instanceType,
                }}
                errors={errors}
                onChangeRunScheduleData={onChangeRunScheduleData}
                onChangeScheduleSelected={value => {
                    setScheduleSelectedOption(value);
                    deleteErrorKey('schedule', setErrors);
                }}
                runScheduleData={runScheduleData}
                scheduleSelectedOption={scheduleSelectedOption}
            />
            <HeaderForCreatePages title="" hideIcon className="mt-10" />
            <div className={`w-full flex flex-col ${currentWidth > 1120 && 'items-center'} `}>
                <FooterButtons
                    onClickCancel={onCancel}
                    saveButtonLoading={!formLoading.runImmediately && formLoading.loading}
                    saveButtonProps={{
                        form: formId,
                        type: 'submit',
                        disabled: saveButtonsDisabled,
                    }}
                    thirdButtonProps={{
                        type: 'button',
                        disabled: saveButtonsDisabled,
                    }}
                    thirdButtonLoading={formLoading.runImmediately && formLoading.loading}
                    onClickThirdButton={() => handleSave(true)}
                    thirdButtonTitle={t('@common.save-and-run')}
                />
            </div>
        </form>
    );
};
