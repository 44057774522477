import { Loader } from '.';

export interface FallbackLoaderProps {
    className?: string;
    style?: object;
}

export const FallbackLoader: React.FC<FallbackLoaderProps> = () => {
    return (
        <div className="flex flex-1 justify-center  items-center h-screen w-full">
            <Loader color="#1D79F2" />
        </div>
    );
};
