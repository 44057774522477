import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RefreshButton } from '../../../../../components/buttons/RefreshButton';
import { StatusComponent } from '../../../../../components/common';
import { NoDataImagesType, Table, TableFilters } from '../../../../../components/tables';
import { useAccountId } from '../../../../../hooks';
import { TaskListResponseModel, TaskVersionListModel } from '../../../../../models';
import { actions, selectors } from '../../../../../redux/thunk/app/task/taskVersionsThunk';
import { showFiltersHeader } from '../../../../../utils';

interface ITaskVersionTabProps {
    taskId: number;
}

export const TaskVersionsTab = ({ taskId }: ITaskVersionTabProps) => {
    const accountId = useAccountId();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const response: TaskListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);

    useEffect(() => {
        requestVersions({ accountId, taskId, page: 0 });
    }, [accountId, taskId]);

    const requestVersions = async (query: any) => dispatch(actions.request(query));

    const onChangePage = (page: number) => requestVersions({ page });

    return (
        <Table
            loading={loading}
            response={response}
            modelEndpoint="taskVersion"
            subHeader={showFiltersHeader(response)}
            headerComponent={
                <TableFilters
                    onSearch={searchQuery => requestVersions({ searchQuery, page: 0 })}
                    response={response}
                    className="flex-row-reverse justify-between"
                >
                    <div className="flex flex-1 justify-end">
                        <RefreshButton onRefresh={() => requestVersions({})} />
                    </div>
                </TableFilters>
            }
            className="shadow-none border border-mainBorder rounded"
            columns={[
                {
                    selector: (row: TaskVersionListModel) => row.versionNumber,
                    name: t('@version', { defaultValue: 'Version' }),
                },
                {
                    selector: (row: TaskVersionListModel) => row.buildStatus,
                    name: t('@build.status', { defaultValue: 'Build status' }),
                    cell: (row: TaskVersionListModel) => (
                        <div className="flex w-full justify-start items-center">
                            <StatusComponent
                                className="pr-2"
                                message={row.buildStatusMessage}
                                status={row.buildStatus}
                            />
                            <span>{row.buildStatus}</span>
                        </div>
                    ),
                },
                {
                    selector: row => row?.lastModified ?? '',
                    name: t('@common.last-modified'),
                    sortable: true,
                },
                {
                    selector: row => row?.modifiedBy ?? '',
                    name: t('@common.modified-by'),
                    sortable: true,
                },
            ]}
            emptyDataProps={{
                actionMessage: '',
                imageType: NoDataImagesType.Task,
                emptyText: t('@no.customEndpointVersions.found', { defaultValue: 'There are no Versions yet...' }),
            }}
            showHeaderButtons={false}
            showHeader={false}
            selectableRows={false}
            pagination
            paginationProps={{
                currentPage: response.currentPage,
                pageItems: response.models.length,
                totalPages: response.totalPages,
                totalItems: response.totalItems,
                onChangePage,
            }}
        />
    );
};
