import { forwardRef, useState, useImperativeHandle } from 'react';

import { InputField } from '../../../../components/inputs';
import { Icon, IconTypes } from '../../../../components/common';
import { OutlineButton } from '../../../../components/buttons';
export interface DynamicOutputsFormProps {
    className?: string;
    isEditing?: boolean;
}
export interface SingleOutputItemType {
    name: string;
    dataTypeId: number;
    id: number;
    _id: number;
}

const initialSingleOutput: SingleOutputItemType = {
    name: '',
    dataTypeId: 0,
    id: 0,
    _id: 0,
};

type InputRefType = {
    getOutputs: () => SingleOutputItemType[];
    setOutputs: (outputs: SingleOutputItemType[]) => any;
};

export const DynamicOutputsForm: React.ForwardRefRenderFunction<InputRefType, DynamicOutputsFormProps> = (
    { className },
    ref
) => {
    let [outputs, setOutputs] = useState<SingleOutputItemType[]>([]);

    useImperativeHandle(ref, () => ({
        getOutputs: () => {
            return outputs;
        },
        setOutputs: (outputs: SingleOutputItemType[]) => {
            setOutputs(outputs);
        },
    }));

    const onAddNew = () => {
        setOutputs(prev => {
            let length = prev.length;
            return [
                ...prev,
                {
                    ...initialSingleOutput,
                    _id: length > 0 ? prev[length - 1]._id + 1 : 1,
                },
            ];
        });
    };

    const onDelete = outputId => {
        setOutputs(prev => prev.filter(x => x._id !== outputId));
    };

    const onChangeInputs = (outputId, key, value) => {
        setOutputs(prev =>
            prev.map(x => {
                if (x._id === outputId) {
                    return {
                        ...x,
                        [key]: value,
                    };
                }
                return x;
            })
        );
    };

    const renderOutputs = () => {
        let commonProps = {
            style: { width: 240 },
            placeHolder: 'Write Here...',
        };
        return outputs.map((output, i) => {
            let id = output._id;

            return (
                <div
                    key={id}
                    className={`flex flex-row w-full justify-start items-end bg-white p-4 ${i > 0 && 'mt-4'}`}
                >
                    <InputField
                        value={output.name}
                        label="Name"
                        name="outputName"
                        required
                        onChange={e => onChangeInputs(id, 'name', e.target.value)}
                        {...commonProps}
                        className="mr-2"
                    />
                    <InputField
                        required
                        value={output.dataTypeId}
                        label="Data Type Id"
                        onChange={e => onChangeInputs(id, 'dataTypeId', e.target.value)}
                        className="mr-2"
                        name="outputDatatype"
                        htmlType="number"
                        {...commonProps}
                    />
                    <div className="flex justify-start items-center h-10">
                        <Icon
                            name={IconTypes.delete}
                            size={25}
                            className="cursor-pointer ml-4"
                            onClick={() => onDelete(id)}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={`flex flex-col w-full h-auto p-4 ${className}`}>
            {renderOutputs()}
            <div className="flex w-full justify-center items-center mt-4">
                <span className="text-secondaryText font-medium text-base mr-4">total : {outputs.length}</span>
                <OutlineButton onClick={onAddNew} title="+ Add New Output" />
            </div>
        </div>
    );
};

export default forwardRef(DynamicOutputsForm);
