import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RunStatusComponent } from '../../../components/common';
import { CreateNotebookServerModal } from '../../../components/modals';
import {
    NoDataImagesType,
    Table,
    TableActionRowProps,
    TableFilters,
    TableMobile,
    UrlTableComponent,
} from '../../../components/tables';
import { toast } from '../../../components/ui';
import { breakpoints } from '../../../constants';
import { useAccountId, useCurrentWidth } from '../../../hooks';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
import { NotebookServerListModel } from '../../../models';
import { actions as notebookInstanceTypesActions } from '../../../redux/thunk/app/notebook/notebookInstanceTypesThunk';
import { actions, selectors } from '../../../redux/thunk/app/notebook/notebookServersThunk';
import { showFiltersHeader } from '../../../utils';

export const NotebookServersPage = () => {
    const currentWidth = useCurrentWidth();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();

    /**
     * Local state
     */
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });
    const [idForEdit, setIdForEdit] = useState<number | null>(null);

    /**
     * Redux Selectors
     */
    const loading = useSelector(selectors.getIsLoading);
    const response = useSelector(selectors.getResponse);
    const initLoading = useSelector(selectors.getInitialLoading);

    useEffect(() => {
        return () => {
            dispatch(actions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        dispatch(notebookInstanceTypesActions.request());
        requestList({ searchQuery: '' });
    }, [accountId]);

    const requestList = (query: any, resetQuery?: boolean) => {
        dispatch(actions.request({ accountId, ...query }, resetQuery));
    };
    const onClickCreate = () => onToggleCreateModal(true);
    const onRefresh = () => requestList({});
    const onPageChanged = page => requestList({ page });

    const onSuccessCreated = () => {
        onRefresh();
        dispatch(notebookInstanceTypesActions.request());
        toast.success(t('@toast-messages.success-create-notebook'));
    };

    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.no-notebookServers'),
            actionMessage: `+ ${t('@pages.create-new-notebookServer')}`,
            imageType: NoDataImagesType.NotebookServer,
            onClickAction: onClickCreate,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const { tableActions, columns } = useMemo<{ tableActions: TableActionRowProps[]; columns: any }>(
        () => ({
            tableActions: [
                {
                    action: 'delete',
                },
            ],
            columns: [
                {
                    name: t('@common.title'),
                    cell: row => <span>{row?.title}</span>,
                    selector: row => row?.title ?? '',
                    sortable: true,
                },
                {
                    selector: row => row?.endpoint ?? '',
                    name: t('endpoint'),
                    sortable: true,
                    cell: (row: NotebookServerListModel) => <UrlTableComponent url={row.endpoint} withIcon />,
                },
                {
                    name: 'Status',
                    selector: row => row?.status ?? '',
                    width: '140px',
                    cell: row => <RunStatusComponent runStatusKey="status" row={row} />,
                },
                {
                    name: t('@common.last-modified'),
                    width: '180px',
                    selector: row => row?.lastModified ?? '',
                    cell: row => <span>{row?.lastModified}</span>,
                    sortable: true,
                },
                {
                    name: t('@common.modified-by'),
                    selector: row => row?.modifiedBy ?? '',
                    width: '140px',
                    cell: row => <span>{row?.modifiedBy}</span>,
                },
            ],
        }),
        []
    );

    const commonTableProps = {
        modelEndpoint: 'notebook',
        selectableRows: true,
        requestList: requestList,
        showHeaderButtons: true,
        isFromSelectPopup: false,
        emptyDataProps,
        loading,
        response,
        headerButtonProps: {
            onRefresh,
            onClickCreate,
        },
    };

    return (
        <div>
            {shouldRenderCreateModal && (
                <CreateNotebookServerModal
                    isVisible={isCreateModalVisible}
                    idForEdit={idForEdit}
                    onHide={() => {
                        onToggleCreateModal(false);
                        idForEdit && setIdForEdit(null);
                    }}
                    onSuccess={onSuccessCreated}
                />
            )}
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestList({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                        />
                    }
                    pagination
                    paginationProps={paginationProps}
                    showHeader={false}
                    columns={columns}
                    actions={tableActions}
                />
            )}

            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="name"
                    initialLoading={initLoading}
                    columns={columns}
                    actions={tableActions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
