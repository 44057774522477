import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../inputs';
import { Loader } from '../../ui';

export interface EditableCellComponentProps {
    value: string;
    onSave: (value: string) => Promise<boolean>;
    style?: CSSProperties;
    className?: string;
    inputStyle?: CSSProperties;
    inputClassName?: string;
    textClassName?: string;
    textStyle?: CSSProperties;
    customValueDisplay?: string;
}

const iconsCommonClassName = 'cursor-pointer h-full p-1 rounded-sm transition-all hover:bg-grey200 duration-300';

export const EditableCellComponent: React.FC<EditableCellComponentProps> = ({
    value,
    className,
    inputClassName,
    inputStyle,
    style,
    onSave,
    textClassName,
    textStyle,
    customValueDisplay,
}) => {
    const { t } = useTranslation();

    const { handleSubmit, setValue, control, watch } = useForm({
        shouldUnregister: false,
    });

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let blurTimeoutId: any = null;

    useEffect(() => setValue('value', value), [value]);

    const onStartEditing = () => setIsEditing(true);

    const onCancelEdit = () => {
        setValue('value', value);
        setIsEditing(false);
    };

    const onSubmit = async data => {
        setIsLoading(true);

        blurTimeoutId && clearTimeout(blurTimeoutId);
        let success = await onSave(data?.value);

        setIsLoading(false);
        success && setIsEditing(false);
    };

    const onBlurInput = () => {
        blurTimeoutId = setTimeout(() => {
            onCancelEdit();
        }, 300);
    };

    const onKeyPress = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter') handleSubmit(onSubmit)();
    };

    const onKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Escape') onCancelEdit();
    };

    const onClickInput = () => blurTimeoutId && clearTimeout(blurTimeoutId);

    return (
        <div
            onDoubleClick={() => !isEditing && onStartEditing()}
            onClick={() => !isEditing && onStartEditing()}
            className={`w-full min-h-full justify-start flex h-auto items-center  ${
                !isEditing && 'hover:underline'
            } cursor-text ${className}`}
            style={{ ...style }}
        >
            {!isEditing ? (
                <div className="min-h-8 sm:min-h-10 h-auto flex items-center justify-start px-3">
                    <span
                        className={`text-blueMainText  text-sm font-regular height-auto text-clip ${textClassName}`}
                        style={{ ...textStyle }}
                    >
                        {customValueDisplay ?? watch('value')}
                    </span>
                </div>
            ) : (
                <div className="flex w-full justify-between h-auto items-center">
                    <Controller
                        name="value"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                            <InputField
                                disabled={isLoading}
                                hideLabel
                                autoFocus
                                name={name}
                                value={value}
                                onChange={onChange}
                                className={`flex-1 ${inputClassName}`}
                                style={{ ...inputStyle }}
                                inputStyle={{ maxHeight: 30 }}
                                onKeyPress={onKeyPress}
                                onKeyDown={onKeyDown}
                                onClick={onClickInput}
                                onBlur={onBlurInput}
                                autoComplete="off"
                                placeholder={t('@common.write.here')}
                            />
                        )}
                    />

                    {isLoading ? (
                        <div className="w-16 flex justify-center items-center">
                            <Loader size={20} />
                        </div>
                    ) : (
                        <div className="w-16 flex justify-center h-full items-center py-1">
                            <span
                                onClick={() => handleSubmit(onSubmit)()}
                                className={`text-primaryBlue600 ${iconsCommonClassName}`}
                            >
                                <GjirafaIcon name="Check" size={20} />
                            </span>
                            <span onClick={onCancelEdit} className={`text-red500 ${iconsCommonClassName}`}>
                                <GjirafaIcon name="Close" size={20} />
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
