import { Loader } from '.';
import { AnimationComponent } from '../animation';

export const FixedLoader: React.FC = ({ children }) => {
    return (
        <AnimationComponent show={true} delay={50} duration={200} type="fade">
            <div className="flex flex-col flex-1 bg-white justify-center items-center h-screen w-full">
                <Loader color="#1D79F2" />
                {children}
            </div>
        </AnimationComponent>
    );
};
