import { nanoid } from 'nanoid';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
    AliasInput,
    FooterButtons,
    HeaderForCreatePages,
    SectionContainerForCreatePages,
    shouldDynamicInputRender,
} from '../../../../components/common';
import { DetailsLoader } from '../../../../components/details-entity';
import { InputField, TextareaField } from '../../../../components/inputs';
import { CustomTabContainer } from '../../../../components/tabs/custom-tab/CustomTabContainer';
import { toast } from '../../../../components/ui';
import { inputTypes, PIPELINES_PATH } from '../../../../constants';
import { useAccountId } from '../../../../hooks';
import { PipelineDetailsResponseModel, PipelinePostModel, TaskVersionListResponseModel } from '../../../../models';
import { PipelineStepValuePostModel } from '../../../../models/entity-models/api/pipeline/pipeline-step';
import axios from '../../../../services/axios';
import { deleteErrorKey, queryParams } from '../../../../utils';
import { onValidateWithYup, pipelineFormSchema } from '../../../../validations';
import { taskInputTypes } from '../../tasks/constants';
import {
    createPipelineContext as CreatePipelineContext,
    TPipelineStepInputState,
    TPipelineStepOutputState,
    TPipelineStepState,
    TPipelineVariableState,
    TTasksVersions,
} from './contexts';
import { formatValueForDynamicInputs } from './helpers';
import { TasksTab, VariablesTab } from './tabs';

enum tabs {
    tasks = 'tasks',
    variables = 'variables',
}

type TFieldValues = {
    title: string;
    alias: string;
    description?: string;
};
const formId = 'createPipelineForm';

export const CreatePipelinePage = () => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const isEditing = !!params.id;

    const [tasksVersions, setTasksVersions] = useState<TTasksVersions>({});
    const [steps, setSteps] = useState<TPipelineStepState[]>([]);
    const [stepIdSelected, setStepIdSelected] = useState<string>(
        steps.length > 0 ? `${steps.map(x => x._id)[steps.length - 1]}` : 'addTask'
    );
    const [variables, setVariables] = useState<TPipelineVariableState[]>([]);
    const [stepsInputs, setStepsInputs] = useState<TPipelineStepInputState[]>([]);
    const [stepsOutputs, setStepsOutputs] = useState<TPipelineStepOutputState[]>([]);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [fieldValues, setFieldValues] = useState<TFieldValues>({
        alias: '',
        title: '',
        description: '',
    });
    const [fieldsErrors, setFieldsErrors] = useState<Partial<TFieldValues>>({});
    const [taskErrors, setTaskErrors] = useState<any>({});

    useEffect(() => {
        if (params.id) {
            fetchDetails();
        }
    }, [accountId, params.id]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if(Object.keys(taskErrors).length) {
            return false;
        }

        try {
            const payload: PipelinePostModel = {
                id: isEditing ? parseInt(params.id) : 0,
                accountId,
                title: fieldValues.title,
                alias: fieldValues.alias,
                description: fieldValues.description,
            };

            const { errors, isValid } = await onValidateWithYup(pipelineFormSchema(t), payload);
            setFieldsErrors(errors);
            if (!isValid) {
                toast.error(t('@form.errors'));
                return;
            }
            setFormLoading(true);

            const variablesPayload: PipelinePostModel['variables'] = variables.map(_variable => {
                return {
                    id: _variable.id,
                    name: _variable.name,
                    locked: _variable.locked,
                    settableAtQueueTime: _variable.settableAtQueueTime,
                    value: _variable.value,
                    isOutput: _variable.isOutput,
                };
            });

            const stepsPayload: PipelinePostModel['steps'] = steps.map((_step, index) => {
                const stepInputs = stepsInputs.find(x => x._id === _step._id)?.inputs ?? null;
                const values: PipelineStepValuePostModel[] = stepInputs
                    ? stepInputs
                          .filter(x => shouldDynamicInputRender(x, stepInputs))
                          .map(x => ({
                              id: x.valueId,
                              inputId: x.id,
                              value:
                                  x.inputType === inputTypes.dropdownOpen && x.value !== ''
                                      ? `$(${x.value?.value})` ?? `$(${x.value})`
                                      : x.value?.value ?? x.value,
                              isRequired: x.required ?? false,
                          }))
                    : [];

                const outputReferenceName = `${_step.outputReferenceName}`;
                const currentStepOutputs =
                    stepsOutputs
                        .find(x => x._id === _step._id)
                        ?.outputs.filter(x => x.name.includes(outputReferenceName)) ?? [];

                currentStepOutputs?.map(_currentOutput => {
                    if (!variablesPayload.find(x => x.name === _currentOutput.name))
                        variablesPayload?.push({
                            id: 0,
                            name: _currentOutput.name,
                            value: '',
                            locked: false,
                            settableAtQueueTime: false,
                            isOutput: true,
                        });
                });

                return {
                    id: _step.id,
                    taskVersionId: _step.versionId,
                    displayName: _step.displayName,
                    order: index,
                    outputReferenceName: _step.outputReferenceName,
                    values,
                };
            });

            payload.steps = stepsPayload;
            payload.variables = variablesPayload;

            await axios.post(`/pipeline`, payload);

            if(isEditing) {
                history.push(PIPELINES_PATH + `/details/${params.id}` + queryParams.formatForNavigation());
            } else {
                history.push(PIPELINES_PATH + queryParams.formatForNavigation());
            }
            toast.success(t(`@success.${isEditing ? 'edit' : 'create'}.pipeline`));
        } catch (err) {
        } finally {
            setFormLoading(false);
        }
    };

    const fetchDetails = async () => {
        try {
            setDetailsLoading(true);

            const { model }: PipelineDetailsResponseModel = await axios.get(`/pipeline?id=${params.id}`);
            const tasksIds = model.steps.map(x => x.taskVersion?.taskId);
            fetchTasksVersions(tasksIds);

            setFieldValues({
                alias: model.alias,
                title: model.title,
                description: model.description,
            });

            const _variables: TPipelineVariableState[] = model.variables.map(_currentVariable => {
                const _id = nanoid();

                return {
                    _id,
                    id: _currentVariable.id,
                    pipelineId: _currentVariable.pipelineId,
                    name: _currentVariable.name,
                    value: _currentVariable.value,
                    settableAtQueueTime: _currentVariable.settableAtQueueTime,
                    locked: _currentVariable.locked,
                    isOutput: _currentVariable.isOutput,
                    error: '',
                };
            });

            const _stepInputs: TPipelineStepInputState[] = [];

            const _stepOutputs: TPipelineStepOutputState[] = [];

            const _steps: TPipelineStepState[] = model.steps.map(_currentStep => {
                const _id = nanoid();

                _stepInputs.push({
                    _id,
                    inputs: _currentStep.values.map(_value => ({
                        valueId: _value.id,
                        value: formatValueForDynamicInputs({
                            inputType:
                                _value.input.inputType === inputTypes.input ||
                                _value.input.inputType === taskInputTypes.filePath
                                    ? inputTypes.dropdownOpen
                                    : _value.input.inputType,
                            options: _value.input.options,
                            value: _value.value.substring(
                                _value.value.startsWith('$(') ? 2 : 0,
                                _value.value.endsWith(')') ? _value.value.length - 1 : _value.value.length
                            ),
                        }),
                        inputName: _value.input.name + _value.input.id,
                        ..._value.input,
                        inputType:
                            _value.input.inputType === inputTypes.input ||
                            _value.input.inputType === taskInputTypes.filePath
                                ? inputTypes.dropdownOpen
                                : _value.input.inputType,
                    })),
                });

                _stepOutputs.push({
                    _id,
                    outputs: _currentStep.outputs.map(output => {
                        return {
                            id: 0,
                            name: `${_currentStep.outputReferenceName}.${output.name}`,
                            dataType: output.dataType,
                        };
                    }),
                });

                return {
                    _id,
                    id: _currentStep.id,
                    outputReferenceNameDisabled: true,
                    pipelineId: _currentStep.pipelineId,
                    versionId: _currentStep.taskVersion.id,
                    taskId: _currentStep.taskVersion.taskId,
                    displayName: _currentStep.displayName,
                    outputReferenceName: _currentStep.outputReferenceName,
                    taskTitle: _currentStep.taskTitle,
                    taskType: _currentStep.taskType,
                };
            });

            setSteps(_steps);
            setStepsInputs(_stepInputs);
            setStepsOutputs(_stepOutputs);
            setVariables(_variables);
        } catch (err) {
        } finally {
            setDetailsLoading(false);
        }
    };

    const fetchTasksVersions = async (ids: number[]) => {
        try {
            const promises: any[] = [];
            ids.forEach(_id => {
                const promise = axios.get(`/pipeline/task/version/list?taskId=${_id}`);
                promises.push(promise);
            });
            const response: TaskVersionListResponseModel[] = await Promise.all(promises);

            const tasksVersions: TTasksVersions = {};
            response.forEach((x, index) => {
                tasksVersions[ids[index]] = x.models.map(x => ({
                    id: x.id,
                    versionNumber: x.versionNumber,
                }));
            });
            setTasksVersions(tasksVersions);
        } catch (err) {}
    };

    const onChangeFields = (key: keyof TFieldValues, value: any) => {
        deleteErrorKey(key, setFieldsErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const onClickCancel = () => history.goBack();

    return (
        <CreatePipelineContext.Provider
            value={{
                setStepIdSelected,
                setSteps,
                setTasksVersions,
                setStepsInputs,
                setStepsOutputs,
                setVariables,
                stepIdSelected,
                steps,
                stepsInputs,
                stepsOutputs,
                tasksVersions,
                variables,
            }}
        >
            {detailsLoading ? (
                <DetailsLoader />
            ) : (
                <form className="flex flex-col w-full flex-1" id={formId}>
                    <HeaderForCreatePages title={t('@common.basic-information')} className="flex flex-col" />
                    <SectionContainerForCreatePages className="flex flex-col mx-auto">
                        <InputField
                            required
                            label={t('@common.title')}
                            name="taskTitle"
                            placeholder={t('@common.write.here')}
                            value={fieldValues.title}
                            onChange={e => onChangeFields('title', e.target.value)}
                            errorMessage={fieldsErrors.title}
                        />
                        <AliasInput
                            endpoint="/pipeline"
                            isEditing={isEditing}
                            onChange={e => onChangeFields('alias', e.target.value)}
                            value={fieldValues.alias}
                            className="mt-4"
                            errorMessage={fieldsErrors.alias}
                        />
                        <TextareaField
                            label={t('@common.description')}
                            placeholder={t('@common.enter-description-here')}
                            className="mt-4"
                            onChange={e => onChangeFields('description', e.target.value)}
                            value={fieldValues.description}
                        />
                    </SectionContainerForCreatePages>
                    <CustomTabContainer
                        tabs={[
                            {
                                component: <TasksTab taskErrors={taskErrors}  setTaskErrors={setTaskErrors}/>,
                                displayName: t('@tab.tasks', { defaultValue: 'Tasks' }),
                                key: tabs.tasks,
                            },
                            {
                                component: <VariablesTab />,
                                displayName: t('@tab.variables', { defaultValue: 'Variables' }),
                                key: tabs.variables,
                            },
                        ]}
                        className="mt-10"
                        contentClassName="!p-0"
                        defaultActiveTab={tabs.tasks}
                    />
                    <HeaderForCreatePages title="" className="mt-10" hideIcon />
                    <FooterButtons
                        hideSave
                        onClickCancel={onClickCancel}
                        onClickThirdButton={handleSubmit}
                        thirdButtonProps={{
                            type: 'button',
                            form: formId,
                            loading: formLoading,
                            disabled: formLoading,
                        }}
                        thirdButtonTitle={t(`@${isEditing ? 'edit' : 'create'}.pipeline`)}
                    />
                </form>
            )}
        </CreatePipelineContext.Provider>
    );
};
