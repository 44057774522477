import { AlertInfoComponent } from '../../../components/alerts';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';

export const LikelihoodToSubscribePage = () => {
    return (
        <PageAnimationContainer>
            <div>
                <AlertInfoComponent
                    text="This page will be available soon!"
                    className="bg-mainBlue bg-opacity-20"
                    textClassName="text-sm"
                    textStyle={{ color: '#1D79F2' }}
                />
            </div>
        </PageAnimationContainer>
    );
};
