import { useHistory } from 'react-router';
import ai_image from '../../assets/images/chip.png';
import { PageAnimationContainer } from '../../components/animation/PageAnimationContainer';
import { CreateButton } from '../../components/buttons';
import { InformationCard, TextInfoCard } from '../../components/cards';
import { DATASETS_PATH } from '../../constants';
import { informationPageConstants } from '../../constants/';
import { queryParams } from '../../utils';

export const WorkflowInformationPage: React.FC = () => {
    const history = useHistory();

    const onClickContinue = () => {
        history.push(DATASETS_PATH + queryParams.formatForNavigation());
    };

    return (
        <PageAnimationContainer>
            <div className="">
                <InformationCard title="Information">
                    <div className="px-10 pb-10 lg:p-12 2xl:p-20 flex h-auto w-full flex-col md:flex-row flex-wrap overflow-y-auto">
                        <div className="flex flex-col flex-1 md:pr-10 mt-10">
                            <TextInfoCard
                                title={informationPageConstants.datasets.title}
                                description={informationPageConstants.datasets.description}
                            />
                            <TextInfoCard
                                title={informationPageConstants.featureEngineering.title}
                                description={informationPageConstants.featureEngineering.description}
                                className="mt-10"
                            />
                            <CreateButton
                                icon={null}
                                className="mt-10 xl:mt-20 w-auto hidden md:flex"
                                title="Continue to Datasets"
                                onClick={onClickContinue}
                                style={{
                                    width: 'auto',
                                    maxWidth: 'fit-content',
                                }}
                            />
                        </div>
                        <div className="flex mt-10 flex-1 flex-col md:pl-10 justify-between items-start">
                            <TextInfoCard
                                title={informationPageConstants.models.title}
                                description={informationPageConstants.models.description}
                            />
                            <img src={ai_image} className="mt-10 h-52  2xl:h-72 w-auto md:hidden flex" />

                            <TextInfoCard
                                title={informationPageConstants.deployments.title}
                                className="mt-10"
                                description={informationPageConstants.deployments.description}
                            />

                            <img src={ai_image} className="mt-20 h-52  2xl:h-72 w-auto md:flex hidden" />

                            <CreateButton
                                icon={null}
                                className="mt-10 xl:mt-20 w-auto md:hidden flex"
                                title="Continue to Datasets"
                                onClick={onClickContinue}
                                style={{
                                    minWidth: 167,
                                    width: 'auto',
                                    maxWidth: 'fit-content',
                                }}
                            />
                        </div>
                    </div>
                </InformationCard>
            </div>
        </PageAnimationContainer>
    );
};
