import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RunStatusComponent } from '../../../../components/common';
import { SelectField } from '../../../../components/inputs';
import { NoDataImagesType, Table, TableActionRowProps, TableFilters } from '../../../../components/tables';
import { toast } from '../../../../components/ui';
import { CREATE_LOOKALIKE_ENGINE_PATH, LOOKALIKE_ENGINE_PATH, statusOptionTypes } from '../../../../constants';
import { useAccountId } from '../../../../hooks';
import { LookalikeEngineListModel, LookalikeEngineListResponseModel, SelectOptionType } from '../../../../models';
import { actions, selectors } from '../../../../redux/thunk/app/engines/lookalike/lookalikeEnginesThunk';
import axios from '../../../../services/axios';
import { formatArrayForSelectOptions, queryParams, showFiltersHeader } from '../../../../utils';

interface LookalikeEngineTableProps {
    fetchDataOnMount?: boolean;
}

export const LookalikeEngineTable: React.FC<LookalikeEngineTableProps> = () => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);

    /**
     * Selectors
     */
    const response: LookalikeEngineListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);

    const requestList = (query: any, resetQuery?: boolean) => {
        dispatch(actions.request({ accountId, ...query }, resetQuery));
    };

    useEffect(() => {
        requestList({ accountId, searchQuery: '' });

        return () => {
            dispatch(actions.removeNotificationQuery());
        };
    }, [accountId]);

    useEffect(() => {
        if (response.query?.status) {
            setStatusTypeSelected(response.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [response.query]);

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestList({ page: 0, status: status });
    };

    const onClickRun = async (row: LookalikeEngineListModel) => {
        try {
            await axios.post('/lookalikeEngine/run', { id: row.id, accountId });
            requestList({});
            toast.success(t('@lookalikeEngine.started.running', { defaultValue: 'Lookalike Engine started running!' }));
        } catch (err) {}
    };

    const onClickTerminate = async (row: LookalikeEngineListModel) => {
        try {
            await axios.post('/lookalikeEngine/terminate', { id: row.id, accountId });
            requestList({});
            toast.success(
                t('@lookalikeEngine.started.terminating', {
                    defaultValue: 'Lookalike Engine started terminating!',
                })
            );
        } catch (err) {}
    };

    const onClickCreate = () => {
        history.push(CREATE_LOOKALIKE_ENGINE_PATH + queryParams.formatForNavigation());
    };

    const onPageChanged = page => requestList({ page });

    const onRefresh = () => requestList({});

    const onRowClicked = (row: LookalikeEngineListModel) => {
        history.push(`${LOOKALIKE_ENGINE_PATH}/details/${row.id}${window.location.search}`);
    };

    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.no-lookalikes', { defaultValue: 'No Lookalikes have been added yet...' }),
            actionMessage: `+ ${t('@pages.create-new-lookalike', { defaultValue: 'Create new Lookalike' })}`,
            imageType: NoDataImagesType.LookalikeEngine,
            onClickAction: onClickCreate,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'details',
                displayName: t('@view.details', { defaultValue: 'View details' }),
                onClick: onRowClicked,
            },
            {
                action: 'edit',
                onClick: (row: LookalikeEngineListModel) => {
                    history.push(`${LOOKALIKE_ENGINE_PATH}/edit-lookalike/${row.id}${window.location.search}`);
                },
            },
            {
                action: (row: LookalikeEngineListModel) => (row?.runStatus === 'Running' ? 'Terminate' : 'run'),
                displayName: (row: LookalikeEngineListModel) =>
                    row?.runStatus === 'Running'
                        ? 'Terminate'
                        : row.runStatus === 'Terminating'
                        ? 'Terminating'
                        : 'Run',
                onClick: async (row: LookalikeEngineListModel) =>
                    row?.runStatus === 'Running' ? onClickTerminate(row) : onClickRun(row),
                shouldDisableActionCallback: (row: LookalikeEngineListModel) => row?.runStatus === 'Terminating',
            },
            {
                action: 'delete',
            },
        ],
        []
    );

    return (
        <div className="flex flex-col pb-4" style={{ minHeight: 320 }}>
            <Table
                onRowClicked={onRowClicked}
                highlightOnHover
                data={response.models}
                response={response}
                modelEndpoint="lookalikeEngine"
                actions={tableActions}
                loading={loading}
                pagination
                paginationProps={paginationProps}
                emptyDataProps={emptyDataProps}
                subHeader={showFiltersHeader(response)}
                showHeaderButtons
                requestList={requestList}
                selectableRows
                headerButtonProps={{
                    onRefresh,
                    onClickCreate,
                }}
                headerComponent={
                    <TableFilters
                        response={response}
                        onChangeQuery={(key, value) =>
                            requestList({
                                [key]: value,
                            })
                        }
                        onSearch={searchQuery => requestList({ searchQuery, page: 0 })}
                    >
                        <SelectField
                            disabled={false}
                            isClearable
                            label="Status"
                            className="m-2"
                            placeholder={t('@common.choose-status')}
                            style={{ width: 240 }}
                            onChange={onChangeStatusType}
                            options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                            value={selectedStatusType}
                            isLoading={loading}
                        />
                    </TableFilters>
                }
                columns={[
                    {
                        selector: row => row?.title ?? '',
                        name: t('@common.title'),
                        sortable: true,
                        cell: row => <span>{row?.title}</span>,
                        ignoreRowClick: false,
                    },
                    {
                        name: t('@common.run-status'),
                        selector: row => row?.runStatus ?? '',
                        sortable: true,
                        width: '140px',
                        cell: row => <RunStatusComponent row={row} />,
                        ignoreRowClick: false,
                    },
                    {
                        name: t('@common.last-modified'),
                        width: '180px',
                        selector: row => row?.lastModified ?? '',
                        cell: row => <span>{row?.lastModified}</span>,
                        sortable: true,
                    },
                    {
                        name: t('@common.modified-by'),
                        selector: row => row?.modifiedBy ?? '',
                        width: '140px',
                        cell: row => <span>{row?.modifiedBy}</span>,
                    },
                ]}
            />
        </div>
    );
};
