import React, { CSSProperties } from 'react';

export interface PriceLoadingShimmerProps {
    style?: CSSProperties;
    className?: string;
}

export const PriceLoadingShimmer: React.FC<PriceLoadingShimmerProps> = ({ style, className }) => {
    return (
        <div style={style} className={`bg-white flex items-end rounded-lg animate-pulse duration-100 ${className}`}>
            <div className="bg-grey300 w-28 rounded-lg h-6" />
        </div>
    );
};

export const TransactionLoadingShimmer: React.FC<PriceLoadingShimmerProps> = ({ className, style }) => {
    return (
        <div
            style={style}
            className={`flex w-full flex-row flex-nowrap bg-white rounded-lg animate-pulse duration-100 ${className}`}
        >
            <div style={{ flex: 2 }} className="flex flex-1 flex-col pr-">
                <div className="bg-grey300 w-1/2 rounded-lg h-6" />
            </div>
            <div className="flex flex-1 justify-start">
                <div className="bg-grey300 w-14 rounded-lg h-6" />
            </div>
            <div className="flex flex-1 justify-end ">
                <div className="bg-grey300 w-16 rounded-lg h-6" />
            </div>
        </div>
    );
};
