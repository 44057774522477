import { toast } from '../../../../components/ui';
import { StorageObjectListResponseModel, StorageObjectsStateType } from '../../../../models';
import {
    actionCreator,
    getAccountIdFromQuery,
    queryParams,
    removeDuplicates,
    showServerErrors,
} from '../../../../utils';
import { RootState } from '../../rootReducer';
import * as H from 'history';
import { STORAGE_PATH } from '../../../../constants';
import axios from '../../../../services/axios';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/storage/storageObjectsThunk/REQUEST',
    SUCCESS: '@app/storage/storageObjectsThunk/SUCCESS',
    FAILURE: '@app/storage/storageObjectsThunk/FAILURE',
    SET_SHOW_LOAD_MORE: '@app/storage/storageObjectsThunk/SET_SHOW_LOAD_MORE',
    SET_IS_LOADING_MORE: '@app/storage/storageObjectsThunk/SET_IS_LOADING_MORE',
};

/**
 * Initial state
 */
const initialState: StorageObjectsStateType = {
    response: {
        message: '',
        model: {
            jobStatusOptions: {},
            objectListModel: [],
            storageEventTypeOptions: {},
        },
        success: false,
        query: {},
    },
    isLoadingMore: false,
    showLoadMore: false,
    continuationToken: '',
    isLoading: false,
    errorMessage: '',
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.storageObjects.isLoading,
    getResponse: (state: RootState) => state.app.storageObjects.response,
    getShowLoadMore: (state: RootState) => state.app.storageObjects.showLoadMore,
    getIsLoadingMore: (state: RootState) => state.app.storageObjects.isLoadingMore,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE, SET_SHOW_LOAD_MORE, SET_IS_LOADING_MORE } = actionTypes;

const Reducer = (state: StorageObjectsStateType = initialState, { type, payload }) => {
    switch (type) {
        case SET_IS_LOADING_MORE:
            return {
                ...state,
                isLoadingMore: payload,
            };
        case SET_SHOW_LOAD_MORE:
            return {
                ...state,
                showLoadMore: payload,
            };
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
                isLoadingMore: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoadingMore: false,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
type requestType = {
    key: string;
    continuationToken?: string;
    isFolder?: boolean;
    onError?: (status: number) => any;
    options?: {
        notFoundMessage: string;
    };
};
export const actions = {
    request: function (props: requestType, history: H.History<unknown>) {
        return async (dispatch, getState: () => RootState) => {
            try {
                const { key, continuationToken, isFolder } = props;
                const { storageObjects } = getState().app;

                continuationToken
                    ? dispatch(actionCreator(SET_IS_LOADING_MORE, true))
                    : dispatch(actionCreator(REQUEST));

                const params: any = {
                    accountId: getAccountIdFromQuery(),
                    maxKeys: isFolder ? 20 : 50,
                };
                if (continuationToken) params.continuationToken = continuationToken;
                if (key) params.key = decodeURI(key);

                const response: StorageObjectListResponseModel = await axios.get(
                    `/storage/object/list?${new URLSearchParams(params)}`
                );
                response.query = { ...params };
                const previousObjectListModel = storageObjects.response.model.objectListModel;
                const finalResponse: StorageObjectListResponseModel = !!continuationToken
                    ? {
                          ...response,
                          model: {
                              ...response.model,
                              objectListModel: response.model.objectListModel.length
                                  ? removeDuplicates(
                                        [...previousObjectListModel, ...response.model.objectListModel],
                                        'key'
                                    )
                                  : previousObjectListModel,
                          },
                      }
                    : response;

                const finalModelsLength = finalResponse.model.objectListModel.length;
                const previousModelsLength = previousObjectListModel.length ?? 0;
                let showLoadMore = isFolder ? finalModelsLength >= 20 : finalModelsLength % 50 === 0;
                if (continuationToken && previousModelsLength === finalModelsLength) {
                    showLoadMore = false;
                }
                if (storageObjects.showLoadMore !== showLoadMore)
                    dispatch(actionCreator(SET_SHOW_LOAD_MORE, showLoadMore));

                dispatch(actionCreator(SUCCESS, finalResponse));
            } catch (err: any) {
                if (err.response && err.response.status === 404) {
                    if (props.onError) {
                        props.onError(404);
                    } else {
                        const paramsPath = window.location.pathname.split('/data/storage/').slice(1).join();
                        toast.error(props.options?.notFoundMessage ?? err.message);

                        if (window.history.length > 2) {
                            paramsPath.length && history.goBack();
                        } else {
                            history.replace(STORAGE_PATH + queryParams.formatForNavigation());
                        }
                    }
                } else showServerErrors(err);
                dispatch(actionCreator(FAILURE));
            }
        };
    },
};
