import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Overlay } from '../ui';
import Footer from './components/Footer';
import Header from './components/Header';

type WidthType = '96' | '100' | '136' | '1/2' | '1/3' | '2/3' | '3/4' | 'default';

export interface SidePopupProps {
    title: string;
    children: any;
    fireEvent?(): any;
    buttonText: string;
    show: boolean;
    hide: (e: any) => any;
    className?: string;
    formId?: any;
    showCreateButton?: boolean;
    cancelButtonText?: string;
    showFooter?: boolean;
    loading?: boolean;
}

interface SideModalProps {
    title: string;
    isVisible: boolean;
    onHide: () => void;
    widthType?: WidthType;

    className?: string;
    buttonText: string;
    formId?: any;
    showCreateButton?: boolean;
    cancelButtonText?: string;
    showFooter?: boolean;
    loading?: boolean;
}

export const SideModal: React.FC<SideModalProps> = ({
    buttonText,
    children,
    title,
    cancelButtonText,
    className,
    formId,
    showCreateButton,
    showFooter = true,
    loading,
    widthType = 'default',
    isVisible,
    onHide,
}) => {
    const modalClassName = useMemo(() => {
        const classes: Record<WidthType, string> = {
            default: `w-full sm:w-105 transition-all delay-0 duration-350 transform-gpu ${
                isVisible ? 'translate-x-0' : 'translate-x-full sm:translate-x-105'
            }`,
            '96': `sm:w-96 w-full ${isVisible ? 'mr-0' : 'sm:-mr-96 -mr-[100%]'}`,
            '100': `sm:w-100 w-full ${isVisible ? 'mr-0' : 'sm:-mr-100 -mr-[100%]'}`,
            '136': `sm:w-136 w-full ${isVisible ? 'mr-0' : 'sm:-mr-136 -mr-[100%]'}`,
            '1/3': `w-full sm:w-2/3 md:w-1/2 lg:w-1/3 ${
                isVisible ? 'mr-0' : '-mr-[100%] sm:-mr-[66.666667%] md:-mr-[50%] lg:-mr-[33.333333%]'
            }`,
            '1/2': `w-full md:w-4/5 lg:w-2/3 xl:w-1/2 ${
                isVisible ? 'mr-0' : '-mr-[100%] md:-mr-[80%] lg:-mr-[66.666667%] xl:-mr-[50%]'
            }`,
            '2/3': `w-full sm:w-4/5  lg:w-2/3 ${isVisible ? 'mr-0' : '-mr-[100%] sm:-mr-[80%]  lg:-mr-[66.666667%]'}`,
            '3/4': `w-full md:w-4/5 lg:w-3/4 ${isVisible ? 'mr-0' : '-mr-[100%] md:-mr-[80%] lg:-mr-[75%]'}`,
        };

        return classes[widthType];
    }, [isVisible, widthType]);

    return createPortal(
        <div className="inset-0">
            <Overlay
                className={`bg-mainOverlay opacity-035 z-50 transition-all delay-0 duration-350 ${
                    isVisible ? 'opacity-035' : 'opacity-0'
                }`}
                hide={onHide}
            />
            <div
                className={`transition-all duration-300 ${modalClassName} fixed top-0 right-0 bg-white h-full z-50 flex flex-col ${className}`}
            >
                <Header title={title} hide={onHide} />
                <div
                    className={`flex flex-1 flex-col justify-between overflow-y-auto w-full transition-all duration-500 ${
                        isVisible ? 'opacity-100' : 'opacity-0'
                    } `}
                >
                    {children}
                </div>
                {showFooter && (
                    <Footer
                        loading={loading}
                        fireBtn={() => {}}
                        formId={formId}
                        hide={onHide}
                        value={buttonText}
                        cancelButtonText={cancelButtonText}
                        showCreateButton={showCreateButton}
                    />
                )}
            </div>
        </div>,
        document.body
    );
};
