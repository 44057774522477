import axios from '../../../../services/axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../../../components/inputs';
import { NoDataImagesType, Table } from '../../../../components/tables';
import { Loader } from '../../../../components/ui';
import { COUNTER_TABLE_SELECTOR } from '../../../../constants';
import { useAccountId } from '../../../../hooks';
import { TabularDataSummary, TextualDataSummary } from '../../../../models';

export interface ArtifactPreviewComponent {
    data?: any;
}

export const ArtifactDetailsTablesSummary:React.FC<ArtifactPreviewComponent>= ({data}) => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [csvSummary, setCsvSummary] = useState<TabularDataSummary | null>(null);
    const [textSummary, setTextSummary] = useState<TextualDataSummary | null>(null);

    useEffect(() => {
        requestSummary();
    }, [data]);

    const fileType = useMemo(() => {
        if (data) {
            const type = data?.artifactContentTypeId;
            if (type === 1) {
                return 'csv'
            } else {
                return 'text'
            }
        }
        return '';
    }, [data]);

    
    const requestSummary = async () => {
        if (data) {
            setSummaryLoading(true);
            try {
                const { id } = data;
                if (fileType === 'csv') {
                    let csvSummary: TabularDataSummary = await axios.post('/summary/tabular', {
                        accountId,
                        artifactId: id,
                    });
                    setCsvSummary(csvSummary);
                } else if (fileType === 'text') {
                    let textSummary: TextualDataSummary = await axios.post('/summary/textual', {
                        accountId,
                        artifactId: id,
                    });
                    setTextSummary(textSummary);
                }
            } catch (err: any) {
            } finally {
                setSummaryLoading(false);
            }
        }
    };

    const renderSummary = () => {
        if (fileType === 'text') {
            const textualSummaryRows = [
                {
                    label: 'Longest Line',
                    value: textSummary?.longestLineLength,
                },
                {
                    label: 'Shortest Line',
                    value: textSummary?.shortestLineLength,
                },
                {
                    label: 'Average Line Length',
                    value: textSummary?.averageLineLength,
                },
                {
                    label: 'Average Word Count',
                    value: textSummary?.averageWordCount,
                },
                {
                    label: 'Total Lines',
                    value: textSummary?.totalLines,
                },
            ];


            return (
                <div className="w-full">
                    <span className="card-shadow base-card-style w-full p-4 flex flex-col whitespace-pre-wrap text-blueMainText font-normal text-base">
                        {summaryLoading ? (
                            <div className="flex w-full p-10 items-center justify-center">
                                <Loader />
                            </div>
                        ) : (
                            textSummary ?
                            textSummary && (
                                <>
                                    <div className="w-full flex flex-row flex-wrap">
                                        <div className="flex flex-1 flex-col">
                                            {textualSummaryRows.map((el, i) => (
                                                <div key={i} className="flex flex-row items-center justify-start mt-2">
                                                    <InputField
                                                        hideLabel
                                                        readOnly
                                                        disabled
                                                        inputClassName="bg-blueStroke"
                                                        className="mr-4"
                                                        style={{
                                                            maxWidth: 200,
                                                        }}
                                                        value={el.label}
                                                    />
                                                    <div className="w-auto h-10 px-3 flex flex-row items-center flex-1 justify-start border rounded border-mainBorder">
                                                        <span className="text-sm text-blueMainText">{el.value}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex flex-0 lg:flex-1" />
                                    </div>
                                </>
                            ) : (<div className="flex w-full p-10 items-center justify-center">No data</div>))}
                    </span>
                </div>
            );
        }

        if (fileType === 'csv') {
            return (
                <div className="w-full h-full top-0 left-0 bottom-0 right-0" style={{ height: '100%' }}>
                    <Table
                        tableAnimation={false}
                        headerButtonsAnimation={false}
                        modelEndpoint=""
                        requestList={() => {}}
                        keyField="name"
                        className="w-full "
                        style={{ overflowX: 'unset' }}
                        expandableRows={false}
                        response={{
                            success: true,
                            message: '',
                            models:
                                (csvSummary?.columns &&
                                    csvSummary?.columns?.map((x, i) => ({
                                        ...x,
                                        [COUNTER_TABLE_SELECTOR]: i + 1,
                                    }))) ||
                                [],
                            totalPages: 1,
                            currentPage: 0,
                            totalItems: 0,
                            hasNextPage: false,
                            query: {},
                        }}
                        loading={summaryLoading}
                        emptyDataProps={{
                            imageType: NoDataImagesType.Artifact,
                            emptyText: t('@common.file-not-loaded'),
                            actionMessage: t('@common.try-again'),
                            onClickAction: requestSummary,
                        }}
                        columns={[
                            {
                                name: '',
                                selector: row => row[COUNTER_TABLE_SELECTOR] ?? '',
                                grow: 0.2,
                                width: '80px',
                            },
                            { name: 'Name', selector: row => row?.name ?? '', grow: 0.5 },
                            {
                                selector: row => row?.dataType ?? '',
                                name: 'DataType',
                                grow: 0.5,
                                maxWidth: '300px',
                            },
                            { selector: row => row?.count ?? '', name: 'Count', grow: 0.5 },
                            {
                                selector: row => row?.missingValues ?? '',
                                name: 'MissingValues',
                                maxWidth: '300px',
                                grow: 0.5,
                            },
                            {
                                selector: row => row?.uniqueValueCount ?? '',
                                name: 'UniqueValueCount',
                                maxWidth: '300px',
                                grow: 0.7,
                            },
                            { selector: row => row?.mode ?? '', name: 'Mode', grow: 0.5, maxWidth: '300px' },
                            { selector: row => row?.mean ?? '', name: 'Mean', maxWidth: '300px', grow: 0.5 },
                            { selector: row => row?.max ?? '', name: 'Max', maxWidth: '300px', grow: 0.5 },
                            {
                                selector: row => row?.min ?? '',
                                name: 'Min',
                                maxWidth: '300px',
                                grow: 0.5,
                            },
                        ]}
                        isFromSelectPopup={false}
                    />
                </div>
            );
        }
        return <span className="font-normal text-sm text-blueMainText">Unsupported file type!</span>;
    };

    return (
        renderSummary()
    );
};