export const transitionStyles = {
    entering: {
        opacity: 0,
        height: 'auto',
    },
    entered: {
        opacity: 1,
        transition: 'all 300ms',
        height: 0,
    },
    exiting: {
        height: 0,
        opacity: 1,
    },
    exited: {
        opacity: 0,
        transition: 'all 300ms',
        height: 'auto',
    },
};

export const opacityAnimationStyles = {
    entering: {
        opacity: 0,
    },
    entered: {
        opacity: 1,
        transition: 'opacity 300ms ease',
    },
    exiting: {
        opacity: 1,
    },
    exited: {
        opacity: 0,
        transition: 'opacity 300ms ease',
    },
};

export const switchTabsAnimationStyles = {
    entering: {
        opacity: 0,
    },
    entered: {
        opacity: 1,
        transition: 'opacity 400ms linear',
    },
    exiting: {
        opacity: 1,
    },
    exited: {
        opacity: 0,
        transition: 'opacity 100ms ease-out',
    },
};

export const modalAnimationStyles = {
    entering: {
        opacity: 1,
        transform: 'scale(0.2)',
    },
    entered: {
        opacity: 1,
        transform: 'scale(1)',
        transition: 'opacity 300ms, transform 300ms',
    },
    exiting: {
        opacity: 1,
        transform: 'scale(1)',
    },
    exited: {
        opacity: 1,
        transform: 'scale(0.2)',
        transition: 'opacity 300ms, transform 300ms',
    },
};

export const menuListAnimationStyles = {
    entering: {
        transform: 'scaleY(0)',
        opacity: 0,
    },
    entered: {
        opacity: 1,
        transform: 'scaleY(1)',
        transition: 'all 300ms linear',
    },
    exiting: {
        opacity: 1,
        transform: 'scaleY(1)',
    },
    exited: {
        opacity: 0,
        transform: 'scaleY(0)',
        transition: 'all 300ms ease',
    },
};

export const sidebarMenuListAnimationStyles = {
    entering: {
        transform: 'scaleY(0)',
        opacity: 0,
    },
    entered: {
        opacity: 1,
        transform: 'scaleY(1)',
        transition: 'all 300ms linear',
    },
    exiting: {
        opacity: 1,
        transform: 'scaleY(1)',
    },
    exited: {
        opacity: 0,
        transform: 'scaleY(0)',
        transition: 'all 300ms ease',
    },
};

export const headerAnimationStyles = {
    entering: {
        transform: 'scaleY(0)',
        marginTop: '0px',
        position: 'absolute',
        opacity: 0,
    },
    entered: {
        opacity: 1,
        // transform: 'scaleY(1)',
        marginTop: '0px',
        transition: 'all 300ms linear',
        position: 'relative',
    },
    exiting: {
        opacity: 1,
        // transform: 'scaleY(1)',
        marginTop: '0px',
        position: 'relative',
    },
    exited: {
        opacity: 0,
        // transform: 'scaleY(0)',
        transition: 'all 300ms linear',
        marginTop: '-200px',
        position: 'absolute',
    },
};

export const rotateAnimationStyles = {
    entering: {
        transform: 'rotate(0deg)',
    },
    entered: {
        transform: 'rotate(-180deg)',
        transition: 'transform 300ms linear',
    },
    exiting: {
        transform: 'rotate(-180deg)',
    },
    exited: {
        transform: 'rotate(0deg)',
        transition: 'transform 300ms linear',
    },
};

export const sidebarAnimationStyles = {
    exiting: {
        opacity: 1,
        width: 276,
        minWidth: 276,
        // marginLeft: -276,
    },
    exited: {
        opacity: 1,
        transition: 'all 200ms linear',
        width: 80,
        minWidth: 80,
    },
    entering: {
        opacity: 1,
        width: 276,
        minWidth: 276,
        marginLeft: -196,
    },
    entered: {
        opacity: 1,
        width: 276,
        marginLeft: 0,
        minWidth: 276,
        transition: 'all 200ms linear',
    },
};
export const sidebarAnimationStylesMobile = {
    exiting: {
        opacity: 1,
        width: 276,
        minWidth: 276,
        position: 'absolute',
        marginLeft: 0,
        top: 0,
        zIndex: 50,
        height: '100vh',
    },
    exited: {
        opacity: 1,
        transition: 'all 200ms linear',
        // width: 0,
        // minWidth: 0,
        marginLeft: -276,
        position: 'absolute',
        top: 0,
        zIndex: 50,
        height: '100vh',
    },
    entering: {
        opacity: 1,
        top: 0,
        marginLeft: -276,
        // width: 0,
        // minWidth: 0,
        position: 'absolute',
        zIndex: 50,
        height: '100vh',
    },
    entered: {
        opacity: 1,
        width: 276,
        minWidth: 276,
        marginLeft: 0,
        top: 0,
        position: 'absolute',
        height: '100vh',
        zIndex: 50,
        transition: 'all 200ms linear',
    },
};
export const transformTransitionStyle = {
    entering: {
        transform: 'scaleY(0)',
        opacity: 0,
        height: 'auto',
    },
    entered: {
        opacity: 1,
        transform: 'scaleY(1)',
        transition: 'all 200ms linear',
        height: 'auto',
    },
    exiting: {
        opacity: 1,
        transform: 'scaleY(1)',
        height: 'auto',
    },
    exited: {
        opacity: 0,
        transform: 'scaleY(0)',
        transition: 'all 200ms ease',
        height: '0px',
    },
};

export const dropdownAnimationStyle = {
    entering: {
        opacity: 0,
        // transform: 'scaleY(0.2)',
        paddingTop: 50,
    },
    entered: {
        opacity: 1,
        // transform: 'scaleY(1)',
        paddingTop: 0,
        transition: 'all 300ms',
    },
    exiting: {
        opacity: 1,
        // transform: 'scaleY(1)',
        paddingTop: 0,
    },
    exited: {
        opacity: 0,
        // transform: 'scaleY(0.2)',
        paddingTop: 50,
        transition: 'all 300ms',
    },
};
export const dropdownActionAnimationStyle = {
    entering: {
        opacity: 0,
        // transform: 'scale(0.2)',
        paddingBottom: 100,
    },
    entered: {
        opacity: 1,
        // transform: 'scale(1)',
        paddingBottom: 0,
        transition: 'all 300ms',
    },
    exiting: {
        opacity: 1,
        // transform: 'scale(1)',
        paddingBottom: 0,
    },
    exited: {
        opacity: 0,
        // transform: 'scale(0.2)',
        paddingBottom: 100,
        transition: 'all 300ms',
    },
};

export const modalFadeTopAnimationStyle = {
    entering: {
        opacity: 0,
        marginTop: -100,
    },
    entered: {
        opacity: 1,
        transition: 'all 300ms ease',
        marginTop: 0,
    },
    exiting: {
        opacity: 1,
        marginTop: 0,
    },
    exited: {
        marginTop: -100,
        opacity: 0,
        transition: 'all 300ms ease',
    },
};

export const modalOverlayAnimationStyles = { ...opacityAnimationStyles };
