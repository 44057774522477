import { CSSProperties, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputField } from './InputField';

export interface ISearchInputProps {
    searchValue?: string;
    onSearch?: (value: string) => any;
    triggerSearchType?: 'onchange' | 'onenter' | 'onchange+onenter';
    className?: string;
    style?: CSSProperties;
    label?: string;
    placeholder?: string;
    withIcon?: boolean;
}

export const SearchInput = ({
    className,
    onSearch,
    searchValue,
    style,
    triggerSearchType = 'onchange+onenter',
    label,
    placeholder,
}: ISearchInputProps) => {
    const { t } = useTranslation();
    let timeoutId: any = null;

    const { handleSubmit, setValue, control } = useForm({
        shouldUnregister: false,
    });

    useEffect(() => {
        if (searchValue !== undefined) setValue('search', searchValue);
    }, [searchValue]);

    const onSubmitSearchForm = data => onSearch && onSearch(data['search'] ?? '');

    const onKeyPress = event => {
        let code = event.keyCode || event.which;

        if (code === 13 && triggerSearchType !== 'onchange') {
            clearTimeout(timeoutId);
            handleSubmit(onSubmitSearchForm)();
        }
    };

    const onInputChanged = () => {
        if (triggerSearchType === 'onenter') return;

        const timeoutMs = triggerSearchType === 'onchange' ? 500 : 800;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => handleSubmit(onSubmitSearchForm)(), timeoutMs);
    };

    return (
        <Controller
            name="search"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
                <InputField
                    hideLabel={!label}
                    label={label}
                    placeholder={placeholder ?? t('@search', { defaultValue: 'Search...' })}
                    onChange={e => {
                        onInputChanged();
                        onChange(e);
                    }}
                    className={`${className}`}
                    style={{ width: 200, ...style }}
                    value={value}
                    name={name}
                    onKeyPress={onKeyPress}
                    autoComplete="off"
                />
            )}
        />
    );
};
