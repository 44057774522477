import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
/**
 * assets
 */
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { UploadAccountImage } from '../../components/account/UploadAccountImage';
import { PageAnimationContainer } from '../../components/animation/PageAnimationContainer';
/**
 * Components
 */
import { CreateButton } from '../../components/buttons';
import { InputField } from '../../components/inputs';
import { ErrorTextComponent } from '../../components/messages';
import { LoadingModal } from '../../components/modals';
import { FallbackLoader } from '../../components/ui';
import { AccountPostResponseModel, UserDetailsResponseModel } from '../../models';
/**
 * Redux
 */
import { actionTypes as userActionTypes } from '../../redux/thunk/app/user/userThunk';
import axios from '../../services/axios';
import { actionCreator, deleteErrorKey } from '../../utils';

type AccountDataStateType = {
    name: string;
    relativeUrl: string;
};

export const WelcomePage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [accountData, setAccountData] = useState<AccountDataStateType>({
        name: '',
        relativeUrl: '',
    });
    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);

    useEffect(() => {
        initialRequest();
    }, []);

    const initialRequest = async () => {
        try {
            setInitialLoading(true);
            const userResponse: UserDetailsResponseModel = await axios.get('/user');
            dispatch(actionCreator(userActionTypes.SUCCESS, userResponse));

            if (userResponse.model.defaultAccountId) {
                history.replace('/');
            }
        } catch (err: any) {
        } finally {
            setInitialLoading(false);
        }
    };

    const validateForm = () => {
        let isValid = true;
        let _err: any = {};

        if (!accountData.name) {
            isValid = false;
            _err.name = t('@validations.field.isRequired', { field: t('@common.name') });
        }

        if (!accountData.relativeUrl) {
            isValid = false;
            _err.relativeUrl = t('@validations.field.isRequired', { field: t('@image', { defaultValue: 'Image' }) });
        }

        setErrors(_err);
        return isValid;
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const { relativeUrl, name } = accountData;
            let payload: any = {
                id: 0,
                name,
                relativeUrl: relativeUrl?.split('.tech')[1] || '',
            };

            const postResponse: AccountPostResponseModel = await axios.post('/account', payload);
            history.replace(`/?accountId=${postResponse.model.id}`);
        } catch (err: any) {
        } finally {
            setLoading(false);
        }
    };

    const onChangeInputs = (key, value) => {
        deleteErrorKey(key, setErrors);
        setAccountData(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    if (initialLoading) {
        return createPortal(<FallbackLoader />, document.body);
    }

    return (
        <PageAnimationContainer>
            <div className="flex h-screen pt-20 w-full flex-col justify-start items-center bg-mainBackground">
                <LoadingModal isVisible={loading} />
                <div className="flex flex-row items-center">
                    <Logo />
                    <span className="text-center font-bold text-2xl ml-3 text-blueMainText">
                        {t('@pages.auth.welcome-text')}
                    </span>
                </div>

                <span className="text-center mt-10 font-normal text-lg  text-blueMainText">
                    {t('@pages.auth.welcome-continue')}
                </span>

                <div className="w-96 h-auto p-7 base-card-style mt-10">
                    <form onSubmit={handleSubmit}>
                        <UploadAccountImage
                            onChangeRelativeUrl={relativeUrl => onChangeInputs('relativeUrl', relativeUrl)}
                            relativeUrl={accountData.relativeUrl}
                            className="mt-4 sm:pt-0"
                        />
                        <ErrorTextComponent message={errors?.relativeUrl} />

                        <InputField
                            onChange={e => onChangeInputs('name', e.target.value)}
                            value={accountData.name}
                            errorMessage={errors?.name}
                            className="mt-3"
                            maxLength={100}
                            placeholder={t('@common.write.here')}
                            label={t('@common.name')}
                            required
                        />
                        <CreateButton htmlType="button" className="mt-3" title={t('@common.create')} />
                    </form>
                </div>
            </div>
        </PageAnimationContainer>
    );
};
