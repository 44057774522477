import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CommonTableProps } from '.';
import axios from '../../services/axios';
import { breakpoints, CATALOGS_PATH } from '../../constants';
import { useAccountId, useCurrentWidth, useDetailsError } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { CatalogListModel, CatalogListResponseModel, SchemaTypeListResponseModel } from '../../models';
import { actions as catalogActions, selectors } from '../../redux/thunk/app/catalog/catalogsThunk';
import {
    actions as schemaTypesActions,
    selectors as schemaTypesSelectors,
} from '../../redux/thunk/app/schemaType/schemaTypesThunk';
import { formatArrayForSelectOptions, getWidth, showFiltersHeader, queryParams } from '../../utils';
import { CreateButton } from '../buttons';
import { SelectField, SelectWithPopupField, SelectWithPopupTypes } from '../inputs';
import { CreateCatalogFilterModal, CreateCatalogModal, ConfirmationPopupModal } from '../modals';
import { toast } from '../ui';
import { NoDataImagesType, TableActionRowProps } from './components';
import { TableFilters } from './components/TableFilters';
import { Table } from './Table';
import { TableMobile } from './TableMobile';

export enum CatalogTableFiltersType {
    SchemaType = 'SchemaType',
    Artifact = 'Artifact',
}
export interface CatalogsTableProps extends CommonTableProps {
    actions?: TableActionRowProps[];
    disableSpecificFilter?: CatalogTableFiltersType;
    multiple?: boolean;
    onChangeSelectedRows?: (items: CatalogListModel[]) => any;
}

export const CatalogsTable: FC<CatalogsTableProps> = ({
    className,
    fetchDataOnMount,
    headerButtonProps,
    isFromSelectPopup,
    tablePopupProps,
    query,
    disableFilters,
    hideFilters,
    onChangeSelectedRows,
    multiple,
    disableSpecificFilter,
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const { handleError, resetError } = useDetailsError();
    const [isCreateCatalogModalVisible, shouldRenderCreateCatalogModal, onToggleCreateCatalogModal] =
        useToggleVisibility({
            initialValue: false,
        });

    const [isCreateCatalogFilterModalVisible, shouldRenderCreateCatalogFilterModal, onToggleCreateCatalogFilterModal] =
        useToggleVisibility({
            initialValue: false,
        });
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    /**
     * Redux Selector
     */
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);
    const response: CatalogListResponseModel = useSelector(selectors.getResponse);
    const { models: schemaTypes }: SchemaTypeListResponseModel = useSelector(schemaTypesSelectors.getResponse);
    const schemaTypesLoading = useSelector(schemaTypesSelectors.getIsLoading);

    /**
     * Local State
     */
    const [selectedRows, setSelectedRows] = useState<CatalogListModel[]>([]);
    const [selectedCatalogForFilter, setSelectedCatalogForFilter] = useState<CatalogListModel | null>(null);
    const [isCatalogFilterEditing, setIsCatalogFilterEditing] = useState(false);
    const [filtersValues, setFiltersValues] = useState<any>({});
    const [catalogIdForEdit, setCatalogIdForEdit] = useState<number>(0);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const requestCatalogs = (payload: object) => {
        dispatch(catalogActions.request({ accountId, ...payload }));
    };

    useEffect(() => {
        schemaTypes.length < 1 && dispatch(schemaTypesActions.request());
        if (fetchDataOnMount) {
            requestCatalogs({ page: 0, searchQuery: '', schemaTypeId: null, artifactId: null, ...query, catalog: !isFromSelectPopup });
        }
    }, [accountId]);

    useEffect(() => {
        const { query } = response;
        setFiltersValues({
            schemaTypeId: query.schemaTypeId || null,
            artifactId: query.artifactId || null,
        });
    }, [response.query]);

    const onPageChanged = page => requestCatalogs({ page, catalog: !isFromSelectPopup });

    const onRefresh = () => {
        let page = getWidth() <= breakpoints.sm ? 0 : response?.currentPage || 0;
        !loading && requestCatalogs({ page, catalog: !isFromSelectPopup });
    };

    /**
     * onClick Events
     */
    const onClickCreate = () => onToggleCreateCatalogModal(true);

    const onClickEdit = (row: CatalogListModel) => {
        onToggleCreateCatalogModal(true);
        setCatalogIdForEdit(row?.id);
    };
    const onClickApplyFilter = (row: CatalogListModel) => {
        onToggleCreateCatalogFilterModal(true);
        setSelectedCatalogForFilter(row);
    };

    const onClickDetails = (row: CatalogListModel) => {
        history.push(`${CATALOGS_PATH}/details/${row.id}${window.location.search}`);
    };

    const onSelectRow = (row: CatalogListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.name,
                value: row.id.toString(),
                ...row,
            });
        }
    };

    const onSuccessCatalog = (item, isEditing) => {
        requestCatalogs({ page: 0, catalog: !isFromSelectPopup});
        onSelectRow(item);
        toast.success(
            isEditing
                ? t('@success.edit.catalog', { defaultValue: 'Catalog has been updated!' })
                : t('@success.create.catalog', { defaultValue: 'New Catalog has been created!' })
        );
    };

    const onSuccessCatalogFilter = isEditing => {
        toast.success(
            isEditing
                ? t('@success.edit.catalogFilter', { defaultValue: 'Catalog Filter has been edited!' })
                : t('@success.create.catalogFilter', { defaultValue: 'Catalog Filter has been created!' })
        );
        history.push(`${CATALOGS_PATH}/details/${selectedCatalogForFilter?.id}${window.location.search}`);
    };

    const onChangeFilters = (key: string, value: any) => {
        setFiltersValues(prev => ({ ...prev, [key]: value }));
        requestCatalogs({ [key]: value, catalog: !isFromSelectPopup });
    };

    const onClickDelete = (row: CatalogListModel) => {
        onToggleVisibility(true);
        setCatalogIdForEdit(row?.id)
    };


    const handleDelete = async () => {
        try {
            setDeleteLoading(true);
            await axios.delete(`/catalog?id=${catalogIdForEdit}`)
            toast.success(t('@success.delete.catalog', { defaultValue: 'Catalog has been deleted' }));
            setCatalogIdForEdit(0)
            onToggleVisibility(false);
            requestCatalogs({ catalog: !isFromSelectPopup })
        } catch (error) {
        handleError(error);
        setDeleteLoading(false);
        onToggleVisibility(false);
    }
    };

    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.no-catalogs'),
            actionMessage: `+ ${t('@pages.create-new-catalog')}`,
            imageType: NoDataImagesType.Catalog,
            onClickAction: onClickCreate,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const { tableActions, columns } = useMemo<{ tableActions: TableActionRowProps[]; columns: any }>(
        () => ({
            tableActions: [
                {
                    action: 'details',
                    displayName: t('@view.details', { defaultValue: 'View details' }),
                    onClick: onClickDetails,
                },
                {
                    action: 'edit',
                    displayName: t('@common.edit'),
                    onClick: onClickEdit,
                },
                {
                    action: 'filter-blue',
                    displayName: 'Filter',
                    onClick: onClickApplyFilter,
                },
                {
                    action: 'delete',
                    displayName: t('@common.delete'),
                    onClick: onClickDelete
                },
            ],
            columns: [
                {
                    selector: row => row?.name ?? '',
                    cell: row => <span>{row?.name}</span>,
                    name: t('@common.name'),
                    sortable: true,
                },
                {
                    selector: row => row?.artifact ?? '',
                    name: t('@common.artifact'),
                    sortable: true,
                },
                {
                    selector: row => row?.schemaType ?? '',
                    name: t('@common.schema-type'),
                    sortable: true,
                },
                {
                    name: t('@common.last-modified'),
                    width: '180px',
                    selector: row => row?.lastModified ?? '',
                    cell: row => <span>{row?.lastModified}</span>,
                    sortable: true,
                },
                {
                    name: t('@common.modified-by'),
                    selector: row => row?.modifiedBy ?? '',
                    width: '140px',
                    cell: row => <span>{row?.modifiedBy}</span>,
                },
            ],
        }),
        []
    );

    const commonTableProps = {
        modelEndpoint: 'catalog',
        selectableRows: true,
        requestList: requestCatalogs,
        showHeaderButtons: true,
        isFromSelectPopup,
        emptyDataProps,
        loading,
        response,
        headerButtonProps: {
            onRefresh,
            onClickCreate,
            showDelete: !isFromSelectPopup,
            ...headerButtonProps,
        },
    };

    return (
        <>
            <div className={`flex flex-col flex-1 ${className}`}>
                {shouldRenderCreateCatalogModal && (
                    <CreateCatalogModal
                        isVisible={isCreateCatalogModalVisible}
                        onHide={() => {
                            setCatalogIdForEdit(0);
                            onToggleCreateCatalogModal(false);
                        }}
                        onSuccess={onSuccessCatalog}
                        isEditing={!!catalogIdForEdit}
                        catalogIdForEdit={catalogIdForEdit}
                    />
                )}

                {shouldRenderCreateCatalogFilterModal && (
                    <CreateCatalogFilterModal
                        onSuccess={onSuccessCatalogFilter}
                        parentCatalog={selectedCatalogForFilter}
                        isVisible={isCreateCatalogFilterModalVisible}
                        onHide={() => {
                            onToggleCreateCatalogFilterModal(false);
                            setSelectedCatalogForFilter(null);
                            isCatalogFilterEditing && setIsCatalogFilterEditing(false);
                        }}
                        idForEdit={selectedCatalogForFilter ? selectedCatalogForFilter?.id : 0}
                        isEditing={isCatalogFilterEditing}
                    />
                )}

                {shouldRenderModal && (
                    <ConfirmationPopupModal
                        title={t(`@confirm-delete.catalog`)}
                        isVisible={isModalVisible}
                        onConfirm={() => handleDelete()}
                        loading={deleteLoading}
                        onCancel={() => {
                            onToggleVisibility(false);
                            setCatalogIdForEdit(0)
                        }}
                        description={'Are you sure you want to delete this item'}
                    />
                )}

                {currentWidth > breakpoints.sm && (
                    <Table
                        {...commonTableProps}
                        subHeader={showFiltersHeader(response)}
                        onRowClicked={isFromSelectPopup ? onSelectRow : onClickDetails}
                        highlightOnHover
                        headerComponent={
                            <TableFilters
                                onChangeQuery={(key, value) => {
                                    requestCatalogs({
                                        [key]: value,
                                        catalog: !isFromSelectPopup
                                    });
                                }}
                                response={response}
                                hideFilters={hideFilters}
                                onSearch={searchQuery => {
                                    requestCatalogs({ searchQuery, page: 0, catalog: !isFromSelectPopup });
                                }}
                            >
                                <SelectField
                                    disabled={
                                        disableFilters || disableSpecificFilter === CatalogTableFiltersType.SchemaType
                                    }
                                    label={t('@common.schema-type')}
                                    placeholder={t('@components.modals.choose-schema-type')}
                                    onChange={value => onChangeFilters('schemaTypeId', value)}
                                    style={{ width: 240 }}
                                    isClearable
                                    className="m-2"
                                    isLoading={schemaTypesLoading}
                                    value={filtersValues?.schemaTypeId}
                                    options={formatArrayForSelectOptions(schemaTypes)}
                                />
                                <SelectWithPopupField
                                    disabled={
                                        disableFilters || disableSpecificFilter === CatalogTableFiltersType.Artifact
                                    }
                                    tableType={SelectWithPopupTypes.artifact}
                                    isClearable
                                    fetchDataOnMount
                                    style={{ width: 200 }}
                                    className="m-2"
                                    placeholder={t('@common.choose-artifact')}
                                    label={t('@common.artifact')}
                                    onChangeItem={item => onChangeFilters('artifactId', item)}
                                    selectedItem={filtersValues?.artifactId}
                                />
                            </TableFilters>
                        }
                        pagination
                        onSelectedRowsChange={({ selectedRows: items }) => {
                            setSelectedRows(items);
                        }}
                        selectedRow={tablePopupProps && tablePopupProps.selectedItem}
                        paginationProps={paginationProps}
                        showHeader={false}
                        columns={columns}
                        actions={isFromSelectPopup ? [] : tableActions}
                    />
                )}

                {currentWidth <= breakpoints.sm && (
                    <TableMobile
                        {...commonTableProps}
                        onSelectedItemsChange={items => {
                            setSelectedRows(items);
                        }}
                        mainKeyProperty="name"
                        initialLoading={initLoading}
                        onClickItem={onSelectRow}
                        selectedItem={tablePopupProps && tablePopupProps.selectedItem}
                        columns={columns}
                        actions={isFromSelectPopup ? [] : tableActions}
                        paginationProps={{
                            ...paginationProps,
                            hasNextPage: !!response.hasNextPage,
                        }}
                    />
                )}
            </div>
            {isFromSelectPopup && (
                <div className="w-full sticky bottom-0 left-0 right-0 bg-white px-4 pb-2 pt-2">
                    <div className="flex w-full rounded border border-mainBorder p-2 justify-between items-center">
                        <span className="text-sm font-medium text-blueMainText">
                            {t(`@select.catalog${multiple ? 's' : ''}`, { defaultValue: 'Select Catalogs' })}
                        </span>
                        <CreateButton
                            icon={<GjirafaIcon name="Selection" />}
                            disabled={selectedRows.length < 1}
                            title={t('@common.select')}
                            onClick={() => {
                                onChangeSelectedRows && onChangeSelectedRows(selectedRows);
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
