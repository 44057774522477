import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { appReducer } from './app';
import { biskoReducer } from './bisko';
import themeReducer from './theme';

const rootReducer = combineReducers({
    theme: themeReducer,
    bisko: biskoReducer,
    app: appReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
