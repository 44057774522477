import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RunStatusComponent, StatusComponent } from '../../../../components/common';
import { SelectField } from '../../../../components/inputs';
import {
    NoDataImagesType,
    Table,
    TableActionRowProps,
    TableFilters,
    TableMobile,
    UrlTableComponent,
} from '../../../../components/tables';
import { toast } from '../../../../components/ui';
import {
    BASE_RECOMMENDATION_PATH,
    breakpoints,
    RecommendationEngineTypes,
    statusOptionTypes,
} from '../../../../constants';
import { useAccountId, useCurrentWidth } from '../../../../hooks';
import {
    RecommendationEngineListModel,
    RecommendationEngineListResponseModel,
    SelectOptionType,
} from '../../../../models';
import { actions, selectors } from '../../../../redux/thunk/app/engines/recommendation/recommendationEnginesThunk';
import axios from '../../../../services/axios';
import { formatArrayForSelectOptions, queryParams, showFiltersHeader } from '../../../../utils';

export interface RecommendationTableProps {
    recommendationEngineType: RecommendationEngineTypes;
}

export const RecommendationTable: React.FC<RecommendationTableProps> = ({ recommendationEngineType }) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const currentWidth = useCurrentWidth();
    const [selectedStatusType, setStatusTypeSelected] = useState<any>(null);

    /**
     * Selectors
     */
    const response: RecommendationEngineListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initLoading = useSelector(selectors.getInitialLoading);

    useEffect(() => {
        if (response.query?.status) {
            setStatusTypeSelected(response.query.status);
        } else {
            setStatusTypeSelected(null);
        }
    }, [response.query]);

    useEffect(() => {
        return () => {
            dispatch(actions.setQuery({ status: null, page: 0, searchQuery: '' }))
        }
    }, [])

    const requestList = (query: any, resetQuery?: boolean) => {
        const type = recommendationEngineType;
        dispatch(actions.request({ accountId, type, ...query }, resetQuery));
    };

    const onChangeStatusType = (status: SelectOptionType | null) => {
        setStatusTypeSelected(status);
        requestList({ page: 0, status: status });
    };

    const onClickCreate = () => {
        let search = queryParams.formatForNavigation();
        history.push(`${BASE_RECOMMENDATION_PATH}/${recommendationEngineType}/create-new-recommendation${search}`);
    };

    const onClickRun = async (row: RecommendationEngineListModel) => {
        try {
            await axios.post('/recommendationEngine/run', { id: row.id, accountId });
            requestList({});
            toast.success(
                t('@recommendationEngine.started.running', { defaultValue: 'Recommendation Engine started running!' })
            );
        } catch (err) {}
    };

    const onClickTerminate = async (row: RecommendationEngineListModel) => {
        try {
            await axios.post('/recommendationEngine/terminate', { id: row.id, accountId });
            requestList({});
            toast.success(
                t('@recommendationEngine.started.terminating', {
                    defaultValue: 'Recommendation Engine started terminating!',
                })
            );
        } catch (err) {}
    };

    const onPageChanged = page => requestList({ page });

    const onRefresh = () => requestList({});

    const onRowClicked = (row: RecommendationEngineListModel) => {
        history.push(
            `${BASE_RECOMMENDATION_PATH}/${recommendationEngineType}/details/${row.id}${window.location.search}`
        );
    };

    const emptyDataProps = useMemo(
        () => ({
            emptyText: t('@pages.no-recommendations'),
            actionMessage: `+ ${t('@pages.create-new-recommendation')}`,
            imageType: NoDataImagesType.Recommendation,
            onClickAction: onClickCreate,
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    const tableActions = useMemo<TableActionRowProps[]>(
        () => [
            {
                action: 'details',
                displayName: t('@view.details', { defaultValue: 'View details' }),
                onClick: onRowClicked,
            },
            {
                action: 'edit',
                onClick: (row: RecommendationEngineListModel) => {
                    history.push(
                        `${BASE_RECOMMENDATION_PATH}/${recommendationEngineType}/edit-recommendation/${row.id}${window.location.search}`
                    );
                },
            },
            {
                action: (row: RecommendationEngineListModel) => (row?.runStatus === 'Running' ? 'Terminate' : 'run'),
                displayName: (row: RecommendationEngineListModel) =>
                    row?.runStatus === 'Running'
                        ? 'Terminate'
                        : row.runStatus === 'Terminating'
                        ? 'Terminating'
                        : 'Run',
                onClick: async (row: RecommendationEngineListModel) =>
                    row?.runStatus === 'Running' ? onClickTerminate(row) : onClickRun(row),
                disable: (row: RecommendationEngineListModel) => row?.runStatus === 'Terminating',
            },
            {
                action: 'delete',
            },
        ],
        []
    );

    const commonTableProps = {
        modelEndpoint: 'recommendationEngine',
        selectableRows: true,
        requestList,
        showHeaderButtons: true,
        isFromSelectPopup: false,
        emptyDataProps,
        loading,
        response,
        deleteProps: {
            deleteQuery: {
                type: recommendationEngineType,
            },
        },
        headerButtonProps: {
            onRefresh,
            onClickCreate,
        },
    };

    return (
        <div className="flex flex-col pb-4" style={{ minHeight: 320 }}>
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    selectableRows
                    onRowClicked={onRowClicked}
                    highlightOnHover
                    subHeader={showFiltersHeader(response)}
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestList({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                        >
                            <SelectField
                                disabled={false}
                                isClearable
                                label="Status"
                                className="m-2"
                                placeholder={t('@common.choose-status')}
                                style={{ width: 240 }}
                                onChange={onChangeStatusType}
                                options={formatArrayForSelectOptions(statusOptionTypes, 'label', 'value')}
                                value={selectedStatusType}
                                isLoading={loading}
                            />
                        </TableFilters>
                    }
                    pagination
                    paginationProps={paginationProps}
                    showHeader={false}
                    columns={[
                        {
                            selector: row => row?.title ?? '',
                            name: t('@common.title'),
                            sortable: true,
                            cell: row => <span onClick={() => onRowClicked(row)}>{row?.title}</span>,
                            ignoreRowClick: false,
                        },
                        {
                            name: 'Endpoint',
                            selector: row => row?.endpoint ?? '',
                            width: '250px',
                            cell: (row: RecommendationEngineListModel) => {
                                const endpointDetails = row.endpointDetails ?? null;

                                return (
                                    <>
                                        <StatusComponent
                                            message={endpointDetails.message}
                                            status={endpointDetails.status}
                                            className="mr-2"
                                        />
                                        {endpointDetails.status === 'Pending' ? (
                                            <span className="text-blueMainText ml-2 ">{endpointDetails.message}</span>
                                        ) : (
                                            <UrlTableComponent url={row.endpoint ?? ''} />
                                        )}
                                    </>
                                );
                            },
                        },
                        {
                            name: t('@common.run-status'),
                            selector: row => row?.runStatus ?? '',
                            sortable: true,
                            width: '140px',
                            cell: row => <RunStatusComponent row={row} />,
                            ignoreRowClick: false,
                        },
                        {
                            name: t('@common.last-modified'),
                            width: '180px',
                            selector: row => row?.lastModified ?? '',
                            cell: row => <span>{row?.lastModified}</span>,
                            sortable: true,
                        },
                        {
                            name: t('@common.modified-by'),
                            selector: row => row?.modifiedBy ?? '',
                            width: '140px',
                            cell: row => <span>{row?.modifiedBy}</span>,
                        },
                    ]}
                    actions={tableActions}
                />
            )}

            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="title"
                    initialLoading={initLoading}
                    columns={[
                        {
                            selector: row => row?.title ?? '',
                            name: t('@common.title'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.lastModified ?? '',
                            name: t('@common.last-modified'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.modifiedBy ?? '',
                            name: t('@common.modified-by'),
                            sortable: true,
                        },
                    ]}
                    actions={tableActions}
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
