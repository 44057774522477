import deepmerge from 'deepmerge';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountId } from '../../../hooks';
import { NotificationListModel, NotificationListResponseModel } from '../../../models';
import {
    actions as notificationsActions,
    selectors as notificationsSelectors,
    actionTypes as notificationsActionTypes,
} from '../../../redux/thunk/app/notification/notificationsThunk';
import axios from '../../../services/axios';
import { actionCreator, formatDateAndTime, getAccountIdFromQuery, getFormattedQuery } from '../../../utils';

type TState = {
    timeLines: Record<string, NotificationListModel[]>;
    hasMore: boolean;
    initialLoading: boolean;
    loading: boolean;
    query: any;
};

export const useNotificationsPageHook = () => {
    const accountId = useAccountId();
    const dispatch = useDispatch();

    const lastNotificationReceived: NotificationListModel | null = useSelector(
        notificationsSelectors.getLastNotificationReceived
    );

    const [timeLines, setTimeLines] = useState<TState['timeLines']>({});
    const [hasMore, setHasMore] = useState<TState['hasMore']>(false);
    const [initialLoading, setInitialLoading] = useState<TState['initialLoading']>(false);
    const [loading, setLoading] = useState<TState['loading']>(false);
    const [query, setQuery] = useState<TState['query']>({});

    useEffect(() => {
        accountId && requestNotifications();
    }, [accountId]);

    useEffect(() => {
        if (lastNotificationReceived !== null) {
            let shouldAppend =
                !query['filterByAccount'] ||
                lastNotificationReceived?.notification.accountId?.toString() === getAccountIdFromQuery();

            shouldAppend && appendLastNotificationReceived(lastNotificationReceived);

            let timeout = setTimeout(() => {
                dispatch(actionCreator(notificationsActionTypes.SET_LAST_NOTIFICATION_RECEIVED, null));
            }, 200);

            return () => clearTimeout(timeout);
        }
    }, [lastNotificationReceived, query['filterByAccount']]);

    const appendLastNotificationReceived = (notification: NotificationListModel) => {
        setTimeLines(prev => {
            let finalTimelines = deepmerge(prev, {});

            let notificationDate = formatDateAndTime(notification.insertDateTime, 'dd MMM, yyyy');
            let currentTimeLine = finalTimelines[notificationDate];

            if (currentTimeLine) {
                const alreadyExists = currentTimeLine.find(x => x.id === notification.id) ?? null;
                !alreadyExists && currentTimeLine.unshift(notification);
            } else finalTimelines[notificationDate] = [notification];

            return finalTimelines;
        });
    };

    const requestNotifications = async (_query = {}, showLoader = true) => {
        try {
            showLoader && setInitialLoading(true);
            let finalQuery = {
                filterByAccount: false,
                accountId,
                ...query,
                ..._query,
                page: 0,
            };

            const response: NotificationListResponseModel = await axios.get(
                `/notification/user/list?${getFormattedQuery(finalQuery)}`
            );

            setQuery(finalQuery);
            let timeLines: TState['timeLines'] = {};

            response.models.forEach(model => {
                let date = formatDateAndTime(model.insertDateTime, 'dd MMM, yyyy');
                let currentTimeLine = timeLines[date];

                if (currentTimeLine) {
                    currentTimeLine.push(model);
                } else {
                    timeLines[date] = [model];
                }
            });
            setHasMore(response.totalPages ? response.totalPages > 1 : false);
            setTimeLines(timeLines);

            showLoader && setInitialLoading(false);
        } catch (err) {
            showLoader && setInitialLoading(false);
        }
    };

    const loadNextPage = async () => {
        try {
            setLoading(true);
            let newQuery = {
                ...query,
                page: (query?.page ?? 0) + 1,
            };
            setQuery(newQuery);

            const response: NotificationListResponseModel = await axios.get(
                `/notification/user/list?${getFormattedQuery(newQuery)}`
            );

            let _timelines = deepmerge(timeLines, {});

            response.models.forEach(model => {
                let date = formatDateAndTime(model.insertDateTime, 'dd MMM, yyyy');
                let currentTimeLine = _timelines[date];

                if (currentTimeLine) {
                    const alreadyExists = currentTimeLine.find(x => x.id === model.id) ?? null;
                    !alreadyExists && currentTimeLine.push(model);
                } else {
                    _timelines[date] = [model];
                }
            });

            setTimeLines(_timelines);
            setHasMore(response.totalPages ? response.totalPages - 1 > newQuery.page : false);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    const onMarkAllAsRead = async () => {
        try {
            await axios.patch('/notification/user/markAllAsRead?markAllAsRead=true');
            dispatch(notificationsActions.onMarkAllAsRead(false));
            requestNotifications({});
            return true;
        } catch (err) {
            return false;
        }
    };

    return {
        timeLines,
        hasMore,
        initialLoading,
        loading,
        loadNextPage,
        requestNotifications,
        onMarkAllAsRead,
    };
};
