import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiltersTable } from '../../../../components/tables';
import { actions } from '../../../../redux/thunk/app/filter/filtersThunk';

export const FiltersPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(actions.removeNotificationQuery());
        };
    }, []);

    return (
        <div className="w-full flex flex-col">
            <FiltersTable fetchDataOnMount isFromSelectPopup={false} />
        </div>
    );
};
