import { AxiosRequestConfig } from 'axios';
import React, { CSSProperties, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccountDetailsResponseModel } from '../../models';
import { selectors as accountSelectors } from '../../redux/thunk/app/account/accountDetailsThunk';
import { uploadToS3, uploadToS3FileProps } from '../../services/s3-services';
import { UploadButton } from '../buttons';
import { Icon, IconTypes } from '../common';
import { toast } from '../ui';
import { AccountImage } from './AccountImage';

export interface UploadAccountImageProps {
    isDisabled?: boolean;
    className?: string;
    style?: CSSProperties;
    onChangeRelativeUrl: (relativeUrl: string) => any;
    relativeUrl: string;
    accountImageStyle?: CSSProperties;
}

export const UploadAccountImage: React.FC<UploadAccountImageProps> = ({
    onChangeRelativeUrl,
    accountImageStyle,
    relativeUrl,
    className,
    isDisabled,
    style,
}) => {
    const { t } = useTranslation();

    const [progress, setProgress] = useState<number | null>(null);
    const accountResponse: AccountDetailsResponseModel = useSelector(accountSelectors.getResponse);

    const onUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length < 1) {
            return;
        }

        const file = e.target.files[0];
        let fileSize = Math.floor(file.size / 1024 / 1024);
        e.target.value = '';

        if (fileSize > 10) {
            toast.dismiss();
            toast.error(`Your image is ${fileSize}MB large, Maximum file size is 10MB!`);
            return;
        }

        try {
            setProgress(0);
            let fileProps: uploadToS3FileProps = {
                endpointForPresignedLUrl: '/account/presignedUrl/upload',
                fileContents: file,
            };
            let axiosConfig: AxiosRequestConfig = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': file.type,
                    'x-amz-acl': 'public-read',
                },
                onUploadProgress: progressEvent => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                },
            };
            let response = await uploadToS3(fileProps, axiosConfig);
            onChangeRelativeUrl(response.s3BuckerUrl);
            setProgress(null);
        } catch (err: any) {
            toast.dismiss();
            toast.error(err?.response?.data?.message || err?.message);
            setProgress(null);
        }
    };

    return (
        <div className={`flex flex-row justify-start items-start w-auto ${className}`} style={style}>
            <div className="flex w-20 h-20  items-center justify-center" style={accountImageStyle}>
                {progress !== null ? (
                    <CircularProgressbar value={progress} text={`${progress}%`} strokeWidth={4} />
                ) : (
                    <AccountImage
                        classNameForNoImage="text-xl"
                        className="w-20 h-20 border rounded border-mainBorder"
                        style={{ minWidth: 80, ...accountImageStyle }}
                        url={relativeUrl}
                        account={accountResponse.model.name}
                    />
                )}
            </div>
            <div className="flex flex-col ml-4">
                <UploadButton
                    icon={<Icon name={IconTypes.upload} className="mr-2" height={24} />}
                    inputId="editImage"
                    disabled={isDisabled}
                    multiple={false}
                    handleUpload={e => !isDisabled && onUploadImage(e)}
                    accept="image/*"
                    className=""
                    title={t('@common.upload-image')}
                />
                <span className="font-medium text-greyBlack100 text-xs mt-2">{t('@common.file-size-up-to')} 10MB</span>
            </div>
        </div>
    );
};
