import merge from 'deepmerge';
import { CSSProperties, LegacyRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DataTable, { TableProps as IDataTableProps } from 'react-data-table-component';
import { ConditionalStyles, TableColumn, TableStyles } from 'react-data-table-component/dist/src/DataTable/types';
import { useTranslation } from 'react-i18next';
import { useAccountId } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { DeleteStateType, ListResponseModel } from '../../models';
import axios from '../../services/axios';
import { AnimationComponent, AnimationComponentContainer } from '../animation';
import { ConfirmationPopupModal } from '../modals';
import { Loader, TableLoader, toast } from '../ui';
import {
    HeaderButtons,
    HeaderButtonsProps,
    NoDataComponent,
    NoDataComponentProps,
    SelectableRowComponent,
    SubHeader,
    TableActionRow,
    TableActionRowProps,
    ToggleActionButton,
} from './components';
import { PaginationComponent, PaginationComponentProps } from './components/PaginationComponent';
import { activeRowStyle, activeRowStyleHovered, customTableStyles } from './styles/styles';

export interface TableProps extends Omit<IDataTableProps<any>, 'data'> {
    defaultExpandedRows?: any[];
    isMultiExpand?: boolean;
    shouldHideActions?: (row: any) => boolean;
    columns: TableColumn<any>[];
    modelEndpoint: string;
    deleteProps?: {
        deleteQuery?: any;
        messageModelKey?: string;
    };
    headerButtonProps?: HeaderButtonsProps;
    response: ListResponseModel<any>;
    data?: Array<any>;
    headerTitle?: string;
    tableClassName?: string;
    tableContainerClassName?: string;
    headerRightComponent?: ReactNode;
    headerLeftComponent?: ReactNode | undefined;
    headerComponent?: ReactNode;
    subHeaderClassName?: string;
    showHeader?: boolean;
    style?: CSSProperties;
    pagination?: boolean;
    loading?: boolean;
    emptyDataProps?: NoDataComponentProps;
    actions?: TableActionRowProps[];
    paginationProps?: PaginationComponentProps;
    showHeaderButtons?: boolean;
    requestList?: (query: any, resetQuery?: boolean) => any;
    shouldRowExpandCallback?: (row?: any) => boolean;
    isFromSelectPopup?: boolean;
    selectedRow?: any;
    className?: string;
    tableStyle?: CSSProperties;
    tableAnimation?: boolean;
    headerButtonsAnimation?: boolean;
    tableLoaderStyle?: CSSProperties;
}

export const Table: React.FC<TableProps> = ({
    columns,
    isMultiExpand = false,
    defaultExpandedRows,
    shouldHideActions,
    headerTitle = 'Table Title',
    headerRightComponent,
    headerLeftComponent,
    tableClassName,
    headerButtonProps,
    modelEndpoint,
    headerComponent,
    subHeaderClassName,
    showHeader,
    customStyles: propsCustomStyles,
    style,
    response,
    pagination,
    tableStyle,
    highlightOnHover: _highlightOnHover,
    loading,
    emptyDataProps,
    actions,
    tableLoaderStyle,
    paginationProps,
    shouldRowExpandCallback,
    requestList,
    showHeaderButtons,
    isFromSelectPopup,
    selectedRow,
    className,
    tableAnimation = true,
    headerButtonsAnimation = true,
    tableContainerClassName,
    deleteProps,
    onSelectedRowsChange: _onSelectedRowsChange,
    ...dataTableProps
}) => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    const tableContainerRef: LegacyRef<HTMLDivElement> = useRef(null);
    const [customLoaderStyle, setCustomLoaderStyle] = useState({
        height: 0,
        width: 0,
        top: 0,
        left: 0,
    });
    const [expandedRowsId, setExpandedRowsId] = useState<any[]>(defaultExpandedRows || []);
    const [deleteState, setDeleteState] = useState<DeleteStateType>({
        loading: false,
        isFromActions: false,
    });
    const [_clearSelectedRows, setClearSelectedRows] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    useEffect(() => {
        changeCustomLoaderStyle();
    }, [loading, response]);

    useEffect(() => {
        if (defaultExpandedRows !== undefined) {
            setExpandedRowsId(defaultExpandedRows);
        }
    }, [defaultExpandedRows]);

    useEffect(() => {
        if (!loading) checkExpandedRows();
    }, [expandedRowsId, loading]);

    const checkExpandedRows = () => {
        if (!shouldRowExpandCallback) return;
        let rowsToUnExpand: any[] = [];

        response.models.forEach(_model => {
            if (!shouldRowExpandCallback(_model) && expandedRowsId?.includes(_model?.id))
                rowsToUnExpand.push(_model?.id);
        });

        if (rowsToUnExpand.length > 0) {
            setExpandedRowsId(prev => prev.filter(_row => !rowsToUnExpand.includes(_row)));
        }
    };

    const onClickAction = (action: string, row: any) => {
        if (action === 'delete') {
            setSelectedItems([row]);
            setDeleteState(prev => ({ ...prev, isFromActions: true }));
            onToggleVisibility(true);
        }
    };

    const handleDelete = async () => {
        try {
            let ids = selectedItems.map(x => x?.id);
            setDeleteState(prev => ({ ...prev, loading: true }));

            let searchQuery = '';
            ids.forEach(id => {
                searchQuery += `id=${id}&`;
            });

            const extraQuery = deleteProps?.deleteQuery ? '&' + new URLSearchParams(deleteProps.deleteQuery) : '';
            await axios.delete(`/${modelEndpoint}?${searchQuery}accountId=${accountId}${extraQuery}`);
            toast.success(t(`@toast-messages.success-${deleteProps?.messageModelKey || modelEndpoint}-delete`));

            setSelectedItems([]);
            setClearSelectedRows(prev => !prev);
            onToggleVisibility(false);

            if (requestList) {
                let currentPage = response.query?.page || 0;
                const page =
                    response.models.length - ids.length <= 1 && currentPage > 0 ? currentPage - 1 : currentPage;
                requestList({ page });
            }
        } catch (err: any) {
        } finally {
            setDeleteState(prev => ({
                ...prev,
                loading: false,
                isFromActions: false,
            }));
        }
    };

    const onSelectedRowsChange = useCallback(_props => {
        setSelectedItems(_props?.selectedRows);
        if (_onSelectedRowsChange) {
            _onSelectedRowsChange(_props);
        }
    }, []);

    const customColumns = useMemo(() => {
        if (!actions?.length) {
            return columns;
        }

        return [
            ...columns,
            {
                width: '100px',
                cell: row => {
                    if (shouldHideActions && shouldHideActions(row)) {
                        return null;
                    }

                    return (
                        <div className="flex w-full justify-start">
                            <ToggleActionButton>
                                <>
                                    {actions.map((_action, i) => {
                                        const { hide, cell } = _action;
                                        let _hide = typeof hide === 'function' ? hide(row) : hide;

                                        if (_hide) {
                                            return null;
                                        }

                                        if (cell) {
                                            return cell(row);
                                        }

                                        return (
                                            <TableActionRow
                                                key={i}
                                                onClickAction={name => onClickAction(name, row)}
                                                row={row}
                                                {..._action}
                                            />
                                        );
                                    })}
                                </>
                            </ToggleActionButton>
                        </div>
                    );
                },
                grow: 0.6,
                name: 'Actions',
                selector: () => '',
            },
        ];
    }, []);

    const changeCustomLoaderStyle = () => {
        let element = tableContainerRef.current;

        if (element) {
            setCustomLoaderStyle({
                height: element?.offsetHeight,
                width: element?.offsetWidth,
                top: element?.offsetTop + window.scrollY,
                left: element?.offsetLeft,
            });
        }
    };

    const highlightOnHover = useMemo(() => {
        return _highlightOnHover || isFromSelectPopup || dataTableProps.expandableRows;
    }, [_highlightOnHover, isFromSelectPopup, dataTableProps.expandableRows]);

    const conditionalRowStyles: ConditionalStyles<any>[] = [
        {
            when: row => expandedRowsId.includes(row.id) && !isFromSelectPopup,
            style: { ...activeRowStyle },
        },
        {
            classNames: ['table-active-row-style'],
            when: row => !!isFromSelectPopup && selectedRow?.value === row?.id?.toString(),
        },
        ...(dataTableProps.conditionalRowStyles || []),
    ];

    const customStyles = useMemo(() => {
        let style: TableStyles = {};
        style = merge(
            { ...customTableStyles },
            {
                rows: {
                    style: highlightOnHover ? activeRowStyleHovered : {},
                },
            }
        );

        return propsCustomStyles ? merge({ ...style }, propsCustomStyles) : style;
    }, [propsCustomStyles, highlightOnHover]);

    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    title={t(`@confirm-delete.${deleteProps?.messageModelKey || modelEndpoint}`)}
                    isVisible={isModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteState.loading}
                    onCancel={() => {
                        deleteState.isFromActions && setSelectedItems([]);
                        onToggleVisibility(false);
                        setDeleteState(prev => ({ ...prev, isFromActions: false }));
                    }}
                    description={`Are you sure you want to delete ${selectedItems.length} item${
                        selectedItems?.length > 1 ? 's' : ''
                    }`}
                />
            )}
            {showHeaderButtons && (
                <AnimationComponentContainer
                    enableAnimation={!!headerButtonsAnimation}
                    show={true}
                    type="fade-top"
                    delay={100}
                    duration={300}
                >
                    <div className="w-full">
                        <HeaderButtons
                            onClickDelete={() => {
                                onToggleVisibility(true);
                            }}
                            showEdit={false}
                            showDelete
                            multiSelectActive={!!selectedItems?.length && !deleteState.isFromActions}
                            {...headerButtonProps}
                        />
                    </div>
                </AnimationComponentContainer>
            )}
            <AnimationComponentContainer
                enableAnimation={tableAnimation}
                type="fade-bottom"
                delay={100}
                duration={300}
                show={true}
            >
                <div className={`flex-col h-auto sm:flex ${tableContainerClassName}`}>
                    <AnimationComponent
                        show={!!loading && response.models.length > 0}
                        type="fade"
                        delay={0}
                        duration={300}
                    >
                        <div
                            className="flex z-50 bg-mainOverlay bg-opacity-10 items-center justify-center rounded"
                            style={{
                                height: customLoaderStyle.height,
                                width: customLoaderStyle.width,
                                marginBottom: tableLoaderStyle?.marginTop
                                    ? -customLoaderStyle.height - parseInt(tableLoaderStyle.marginTop.toString())
                                    : -customLoaderStyle.height,
                            }}
                        >
                            <Loader color="#1D79F2" />
                        </div>
                    </AnimationComponent>
                    <div
                        id="tableContainerId"
                        ref={tableContainerRef}
                        className={`table-body-global-style card-shadow z-0 ${className}`}
                        style={{
                            width: '100%',
                            minWidth: '100%',
                            maxWidth: 'auto',
                            height: 'auto',
                            ...style,
                        }}
                    >
                        <DataTable
                            fixedHeader={false}
                            noHeader
                            responsive
                            className={`w-auto ${tableClassName} h-auto overflow-visible`}
                            style={{
                                minWidth: '100% !important',
                                maxWidth: 'auto !important',
                                width: 'auto !important',
                                overflowY: 'visible',
                                marginTop: showHeader ? 16 : 0,
                                ...tableStyle,
                            }}
                            subHeader={showHeader}
                            subHeaderComponent={
                                headerComponent ? (
                                    headerComponent
                                ) : (
                                    <SubHeader
                                        title={headerTitle}
                                        rightComponent={headerRightComponent}
                                        titleComponent={headerLeftComponent}
                                        className={subHeaderClassName}
                                    />
                                )
                            }
                            data={response.models}
                            columns={!!actions && actions?.length > 0 ? customColumns : columns}
                            customStyles={customStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            pagination={
                                paginationProps?.totalPages ? paginationProps?.totalPages > 1 && pagination : pagination
                            }
                            paginationComponent={() => <PaginationComponent {...paginationProps} />}
                            paginationServer
                            noDataComponent={<NoDataComponent {...emptyDataProps} />}
                            progressPending={loading && response.models.length < 1}
                            progressComponent={<TableLoader />}
                            onRowExpandToggled={(expanded, row) => {
                                if (isMultiExpand) {
                                    setExpandedRowsId(prev =>
                                        expanded ? [...prev, row?.id] : prev.filter(_prev => _prev !== row?.id)
                                    );
                                    return;
                                }
                                setExpandedRowsId(expanded ? [row.id] : []);
                            }}
                            expandableRowExpanded={row => expandedRowsId.includes(row.id)}
                            expandableRowDisabled={row =>
                                shouldRowExpandCallback ? !shouldRowExpandCallback(row) : false
                            }
                            expandOnRowClicked={!isFromSelectPopup}
                            onSelectedRowsChange={onSelectedRowsChange}
                            selectableRowsHighlight
                            selectableRowsComponent={SelectableRowComponent}
                            clearSelectedRows={_clearSelectedRows}
                            {...dataTableProps}
                        />
                    </div>
                </div>
            </AnimationComponentContainer>
        </>
    );
};
