import React, { CSSProperties } from 'react';
import { outlineButtonHoverClassName } from '../../constants';
import { toast, Tooltip } from '../ui';

export interface CopyButtonProps {
    title: string;
    textForCopy: string;
    successCopyMessage: string;
    className?: string;
    style?: CSSProperties;
}

export const CopyButton: React.FC<CopyButtonProps> = ({ textForCopy, title, successCopyMessage, className, style }) => {
    const onClick = () => {
        navigator.clipboard.writeText(textForCopy);
        toast.info(successCopyMessage, { position: 'bottom-right', delay: 100, autoClose: 1500 });
    };

    return (
        <>
            <Tooltip delayShow={400} id="copyButton" />
            <button
                type="button"
                data-for="copyButton"
                data-tip={textForCopy}
                onClick={onClick}
                className={`${outlineButtonHoverClassName} bg-white text-xs sm:text-sm flex h-8 sm:h-10 items-center border-primaryBlue200 border-solid text-primaryBlue500 border  justify-center cursor-pointer ${className}`}
                style={{
                    paddingLeft: 12,
                    borderRadius: 4,
                    paddingRight: 12,
                    ...style,
                }}
            >
                {title}
            </button>
        </>
    );
};
