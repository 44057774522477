import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ReactComponent as RecommendationIcon } from '../../../assets/images/empty/empty-recommendation.svg';
import { CopyButton } from '../../../components/buttons';
import { Icon } from '../../../components/common';
import {
    DetailsEmpty,
    DetailsInfoOverview,
    DetailsInfoOverviewDataType,
    DetailsLoader,
} from '../../../components/details-entity';
import { Error404 } from '../../../components/errors';
import { CustomTabContainer } from '../../../components/tabs/custom-tab/CustomTabContainer';
import { Tooltip, toast } from '../../../components/ui';
import { BASE_RECOMMENDATION_PATH } from '../../../constants';
import { useAccountId, useBiskoIntegrationError, useDetailsError, useToggleVisibility } from '../../../hooks';
import {
    BiskoPropertyListDataModel,
    RecommendationEngineDetailsModel,
    RecommendationEngineDetailsResponseModel,
} from '../../../models';
import { actions as recommendationEnginesRunsActions } from '../../../redux/thunk/app/engines/recommendation/recommendationEngineRunsThunk';
import { selectors as biskoPropertiesSelectors } from '../../../redux/thunk/bisko/biskoPropertiesThunk';
import axios from '../../../services/axios';
import { formatDateAndTime, upperCaseFirstCharacter } from '../../../utils';
import { frequencyTypes } from './components/RenderFrequencyComponent';
import {
    RecommendationMetricsTab,
    RecommendationRunsTab,
    RecommendationSandBoxTab,
    RecommendationSwaggerTab,
} from './details-tabs';
import { ToggleActionButton, TableActionRow } from '../../../components/tables';
import { ConfirmationPopupModal } from '../../../components/modals';

enum RecommendationTabs {
    metrics = 'metrics',
    runs = 'runs',
    swagger = 'swagger',
    sandBox = 'sandBox',
}
export const RecommendationEngineDetailsPage = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const type = params?.type;
    const accountId = useAccountId();
    const dispatch = useDispatch();
    const history = useHistory();
    const { detailsError, handleError, resetError } = useDetailsError();

    useBiskoIntegrationError({
        selectors: biskoPropertiesSelectors,
        path: `${BASE_RECOMMENDATION_PATH}/${params?.type}`,
    });

    /**
     * Selectors
     */
    const { properties }: BiskoPropertyListDataModel = useSelector(biskoPropertiesSelectors.getData);

    /**
     * Local State
     */
    const [details, setDetails] = useState<RecommendationEngineDetailsModel | null>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const entityId = parseInt(params?.id);
    const [defaultActiveTab, setDefaultActiveTab] = useState<string>(RecommendationTabs.metrics)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    useEffect(() => {
        return () => {
            dispatch(recommendationEnginesRunsActions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        if (entityId) {
            fetchDetails(entityId);
        }
    }, [accountId, entityId]);

    const fetchDetails = async id => {
        try {
            resetError();
            setDetailsLoading(true);
            const response: RecommendationEngineDetailsResponseModel = await axios.get(
                `/recommendationEngine?id=${id}&&accountId=${accountId}`
            );
            setDetails(response.model);
        } catch (err: any) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onClickEdit = () => {
        if (details) {
            history.push(
                `${BASE_RECOMMENDATION_PATH}/${type}/edit-recommendation/${details.id}${window.location.search}`
            );
        }
    };

    const onClickTerminate = async () => {
        try {

            await axios.post('/recommendationEngine/terminate', { id: entityId, accountId });
            fetchDetails(entityId)
            toast.success(
                t('@recommendationEngine.started.terminating', {
                    defaultValue: 'Recommendation Engine started terminating!',
                })
            );
        } catch (err) {
            setDefaultActiveTab(RecommendationTabs.runs)
            fetchDetails(entityId)
        }
    };

    const onClickRun = async () => {
        try {
            await axios.post('/recommendationEngine/run', { id: entityId, accountId });
            fetchDetails(entityId)
            toast.success(
                t('@recommendationEngine.started.running', { defaultValue: 'Recommendation Engine started running!' })
            );
        } catch (err) {
            setDefaultActiveTab(RecommendationTabs.runs)
            fetchDetails(entityId)
        }
    };

    const onClickDelete = () => {
        onToggleVisibility(true)
    }

    const handleDelete = async () => {
        try {
            setDeleteLoading(true)
            await axios.delete(`recommendationEngine?id=${entityId}&accountId=${accountId}&type=${type}`)
            toast.success(`Recommendation Engine ${upperCaseFirstCharacter(type)} has been deleted!`);
            history.push(`${BASE_RECOMMENDATION_PATH}/${type}${window.location.search}`)
        } catch (err) {
            setDeleteLoading(false)
            onToggleVisibility(false)
            handleError(err)
        }
    }

    const actions = [
        {
            action: 'edit',
            onClick: onClickEdit
        },
        {
            action: () => (details?.runs[0]?.status === 'Running' ? 'Terminate' : 'run'),
            displayName: () =>
                details?.runs[0]?.status === 'Running'
                    ? 'Terminate'
                    : details?.runs[0]?.status === 'Terminating'
                    ? 'Terminating'
                    : 'Run',
            onClick: async () =>
                details?.runs[0].status === 'Running' ? onClickTerminate() : onClickRun(),
            disable: () => details?.runs[0]?.status === 'Terminating',
        },
        {
            action: 'delete',
            onClick: onClickDelete
        }
    ]

    const detailsData = useMemo(() => {
        let data: DetailsInfoOverviewDataType = {};

        if (details) {
            const {
                title,
                catalogs,
                propertyIds,
                createdAt,
                createdBy,
                lastModified,
                modifiedBy,
                frequency,
                objective,
            } = details;
            const { aggressive, soft } = frequencyTypes;
            let propertyIdsArr = propertyIds.split(',');

            data = {
                '@common.title': { value: title },
                '@common.catalogs': { value: catalogs.map(x => x.catalogName).join(', ') },
                '@bisko.properties': {
                    style: propertyIds.length ? {} : { color: '#A3B0C2' },
                    value: propertyIds.length
                        ? properties
                              .filter(x => propertyIdsArr.includes(x.id.toString()))
                              .map(x => x.name)
                              .join(', ')
                        : t('@no.property.selected', { defaultValue: 'No property selected!' }),
                },
                '@recommendation.objective': {
                    value: objective?.nameResourceKey ? t(objective.nameResourceKey) : objective?.name ?? '',
                },
                '@recommendation.frequency/cycling': {
                    style: frequency !== 0 ? {} : { color: '#A3B0C2' },
                    value:
                        frequency === soft
                            ? t('@recommendation.soft.title')
                            : frequency === aggressive
                            ? t('@recommendation.aggressive.title')
                            : t('@recommendation.frequency.off', { defaultValue: 'Off' }),
                },
                '@createdAt': { value: formatDateAndTime(createdAt) },
                '@createdBy': { value: createdBy },
                '@common.last-modified': { value: formatDateAndTime(lastModified) },
                '@modifiedBy': { value: modifiedBy },
            };
        }
        return data;
    }, [details, properties]);

    const { endpointStatusMessage, endpointStatus, tabsDisabled } = useMemo(() => {
        let endpointStatus: any = details?.endpointDetails.status ?? '';

        return {
            endpointStatus,
            endpointStatusMessage: details?.endpointDetails.message ?? details?.endpointDetails.status ?? '',
            tabsDisabled: endpointStatus === 'Pending' || endpointStatus === 'Failed',
        };
    }, [details?.endpointDetails]);

    if (detailsError.status === 404) {
        return (
            <Error404
                model="recommendationEngine"
                onClickButton={() => {
                    history.push(`${BASE_RECOMMENDATION_PATH}/${params?.type}${window.location.search}`);
                }}
            />
        );
    }

    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    title={`Recommendation Engine ${upperCaseFirstCharacter(type)} delete confirmation`}
                    isVisible={isModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteLoading}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    description={'Are you sure you want to delete this item'}
                />
            )}
            <div className="flex flex-col flex-1">
                <DetailsInfoOverview
                    data={detailsData}
                    loading={detailsLoading}
                    titleIconComponent={<RecommendationIcon className="h-6 w-6 mr-4 ml-2" />}
                    titleRightComponent={
                        <ToggleActionButton>
                            <>
                                {actions.map((_action, i) => {
                                    return (
                                        <TableActionRow
                                            key={i}
                                            onClickAction={_action.onClick || (() => {})}
                                            {..._action}
                                            row={i}
                                        />
                                    );
                                })}
                            </>
                        </ToggleActionButton>
                    }
                    title={t('@recommendationEngine.overview', { defaultValue: 'Recommendation Engine overview' })}
                >
                    {!!details?.apiKey && (
                        <CopyButton
                            className="absolute right-4 bottom-3"
                            successCopyMessage="Api key has been copied to clipboard!"
                            title="Copy Api key"
                            textForCopy={details?.apiKey}
                        />
                    )}
                </DetailsInfoOverview>

                {detailsLoading ? (
                    <DetailsLoader />
                ) : !details ? (
                    <DetailsEmpty onRefresh={() => fetchDetails(entityId)} />
                ) : (
                    <div className="flex flex-1 flex-col">
                        <CustomTabContainer
                            className="mt-6"
                            style={{ minHeight: 500 }}
                            animationEnabled={false}
                            defaultActiveTab={defaultActiveTab}
                            tabHeaderProps={{
                                rightComponent: endpointStatus ? (
                                    <div
                                        className="flex w-auto justify-end  items-center mr-6"
                                        data-for="endpointStatus"
                                        data-tip={endpointStatusMessage}
                                    >
                                        {/* RunStatus icon  */}
                                        <Icon size={28} className="cursor-pointer" name={endpointStatus} />
                                        <Tooltip id="endpointStatus" className="min-w-auto border border-mainBlue" />
                                    </div>
                                ) : undefined,
                            }}
                            tabs={[
                                {
                                    component: <RecommendationMetricsTab recommendationEngineId={details?.id ?? 0} />,
                                    displayName: t('@recommendation.tab.metrics', { defaultValue: 'Metrics' }),
                                    key: RecommendationTabs.metrics,
                                },
                                {
                                    component: <RecommendationRunsTab recommendationEngineId={details?.id ?? 0} />,
                                    displayName: t('@recommendation.tab.runs', { defaultValue: 'Runs' }),
                                    key: RecommendationTabs.runs,
                                },
                                {
                                    component: (
                                        <RecommendationSwaggerTab endpoint={details?.endpointDetails.endpoint ?? null} />
                                    ),
                                    displayName: t('@recommendation.tab.swagger', { defaultValue: 'Swagger' }),
                                    key: RecommendationTabs.swagger,
                                    disabled: tabsDisabled,
                                },
                                {
                                    component: (
                                        <RecommendationSandBoxTab
                                            apiKey={details?.apiKey}
                                            endpoint={details?.endpointDetails.endpoint ?? null}
                                        />
                                    ),
                                    displayName: t('@recommendation.tab.sandBox', { defaultValue: 'SandBox' }),
                                    key: RecommendationTabs.sandBox,
                                    disabled: tabsDisabled,
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
