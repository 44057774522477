import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TabularIcon } from '../../../../../assets/icons/datasource.svg';
import { ReactComponent as TextualIcon } from '../../../../../assets/icons/file-text-icon.svg';
import { ReactComponent as ImageIcon } from '../../../../../assets/icons/image-icon.svg';
import { ReactComponent as VideoIcon } from '../../../../../assets/icons/video-player-icon.svg';
import { RadioButton } from '../../../../../components/buttons';
import { FieldLabel } from '../../../../../components/inputs/components/FieldLabel';
import { ErrorTextComponent } from '../../../../../components/messages';

interface IDataTypesComponent {
    selectedId: number;
    onSelect: (id: number) => any;
    errorMessage?: string;
}
interface IDataType {
    imageUrl: string;
    image?: ReactNode;
    titleKey: string;
    id: number;
    comingSoon?: boolean;
}

export const dataTypes = {
    image: 1,
    textual: 2,
    tabular: 3,
    video: 4,
};

const dataTypesArray: IDataType[] = [
    {
        imageUrl: '',
        image: <TabularIcon height={30} width={30} />,
        titleKey: '@dataTypes.tabular',
        id: dataTypes.tabular,
    },
    {
        imageUrl: '',
        image: <ImageIcon />,
        titleKey: '@dataTypes.image',
        id: dataTypes.image,
    },
    {
        imageUrl: '',
        image: <TextualIcon />,
        titleKey: '@dataTypes.textual',
        id: dataTypes.textual,
    },
    {
        imageUrl: '',
        image: <VideoIcon />,
        titleKey: '@dataTypes.video',
        id: dataTypes.video,
        comingSoon: true,
    },
];

export const DataTypesComponent = ({ onSelect, selectedId, errorMessage }: IDataTypesComponent) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!selectedId) {
            onSelect(dataTypesArray[0].id);
        }
    }, []);

    return (
        <div className="w-full mt-4">
            <FieldLabel label={t('@select.dataType', { defaultValue: 'Select a data Type' })} />
            <div className="w-full flex flex-col justify-center items-center sm:flex-row sm:justify-start">
                {dataTypesArray.map((_dataType, i) => {
                    const disabled = _dataType.comingSoon;
                    return (
                        <div
                            onClick={() => {
                                if (selectedId !== _dataType.id && !disabled) {
                                    onSelect(_dataType.id);
                                }
                            }}
                            className={`rounded relative bg-white card-shadow flex-col p-2 flex w-2/3 sm:flex-1 mt-2 sm:mt-0 h-28 ${
                                disabled ? 'cursor-not-allowed bg-opacity-50' : 'cursor-pointer'
                            } ${i > 0 ? 'sm:ml-3' : ''}`}
                        >
                            <div className="h-16 w-full justify-center items-center flex rounded bg-mainBackground">
                                {_dataType.comingSoon ? (
                                    <div
                                        style={{ fontSize: 10 }}
                                        className="flex font-medium text-xs  text-center justify-center items-center px-1.5 py-0.5 text-primaryBlue500 bg-mainBlue bg-opacity-20 rounded-full"
                                    >
                                        Coming soon
                                    </div>
                                ) : (
                                    _dataType.image
                                )}
                            </div>
                            <div className={`flex mt-2 items-center ${disabled && 'opacity-50'}`}>
                                <RadioButton size="small" isChecked={_dataType.id === selectedId} />
                                <h4 className="text-blueMainText text-xs font-medium ml-2">{t(_dataType.titleKey)}</h4>
                            </div>
                        </div>
                    );
                })}
            </div>
            <ErrorTextComponent message={errorMessage} />
        </div>
    );
};
