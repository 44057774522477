import { BaseEntityProperty } from '../models';

export const addNewOptionToDynamicInputs = (inputs: BaseEntityProperty[], inputName: string, value: string) => {
    let newOption = { label: value, value };

    let _inputs = inputs.map((x: BaseEntityProperty) => {
        if (x?.inputName === inputName) {
            let options = x?.options.length > 0 ? [...x.options, newOption] : [newOption];
            return {
                ...x,
                options,
                value: newOption,
            };
        }
        return x;
    });

    return _inputs;
};
