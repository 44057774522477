import { Dispatch } from 'redux';
import { InitialStateType, PipelineListResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator, getFormattedQuery, getFormattedResponseForPayload, getSearchParams } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/pipeline/pipelinesThunk/REQUEST',
    SUCCESS: '@app/pipeline/pipelinesThunk/SUCCESS',
    FAILURE: '@app/pipeline/pipelinesThunk/FAILURE',
};

/**
 * Initial state
 */
const initialState: InitialStateType<PipelineListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    initialLoading: false,
    response: {
        success: false,
        message: '',
        models: [],
        currentPage: 0,
        totalPages: 1,
        hasNextPage: false,
        query: {},
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.pipelines.isLoading,
    getResponse: (state: RootState) => state.app.pipelines.response,
    getErrorMessage: (state: RootState) => state.app.pipelines.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;
const Reducer = (state: InitialStateType<PipelineListResponseModel> = initialState, { type, payload }) => {
    const stateUpdate = {
        [REQUEST]: {
            ...state,
            isLoading: true,
            errorMessage: '',
        },
        [SUCCESS]: {
            ...state,
            response: payload,
            isLoading: false,
            initialLoading: false,
        },
        [FAILURE]: {
            ...state,
            errorMessage: payload,
            isLoading: false,
            initialLoading: false,
        },
    };
    return stateUpdate[type] ?? state;
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function (query: any, resetQuery = false) {
        return async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const previousQuery = getState().app.pipelines.response.query;

                if (query?.page === undefined) query.page = previousQuery?.page || 0;

                dispatch(actionCreator(REQUEST));

                let searchParams = getSearchParams(previousQuery, query, resetQuery);

                const response: PipelineListResponseModel = await axios.get(
                    `pipeline/list?${getFormattedQuery(searchParams)}`
                );
                let res = getFormattedResponseForPayload(response, [], query, searchParams);

                dispatch(actionCreator(SUCCESS, res));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
