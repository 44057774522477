import { CSSProperties } from 'react';

import { BaseEntityProperty } from '../../../models';
import { SingleDynamicInput } from './SingleDynamicInput';
export interface DynamicInputsProps {
    properties: BaseEntityProperty[];
    className?: string;
    style?: CSSProperties;
    errors?: any;
    placeholder?: string;
    isLoading?: boolean;
    disabled?: boolean;
    onChangeInput: (inputName: string, item: any, inputType: any) => any;
    onBlurInput?: (inputName: string, e: any, inputType: any) => any;
    onScrollToBottom?: () => any;
    handleCreateOption?: (inputName: string, item: any) => any;
    onChangeInputsList?: (arr: BaseEntityProperty[]) => any;
}

export const DynamicInputs: React.FC<DynamicInputsProps> = props => {
    return (
        <>
            {props.properties.map(property => (
                <SingleDynamicInput key={property.id + property.inputName} {...props} property={{ ...property }} />
            ))}
        </>
    );
};
