// import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../../../../../components/inputs';
import { FieldLabel } from '../../../../../../components/inputs/components/FieldLabel';
import { TInputOption } from '../../hooks';
// import { AddButton } from '../AddButton';
// import { RemoveButton } from '../RemoveButton';

interface IOptionsProps {
    options: TInputOption[];
    setOptions: (options: TInputOption[]) => void;
}

const inputClassName = 'w-[150px] mr-3';

export const Options = React.memo(({ setOptions, options }: IOptionsProps) => {
    const { t } = useTranslation();

    const onChange = (_id: string, key: 'label' | 'value', _value: string) => {
        const newOptions: TInputOption[] = options.map(_option => {
            if (_option._id === _id) {
                const valueSameAsLabel = _option.label === _option.value;
                return {
                    label: key === 'label' ? _value : _option.label,
                    value: key === 'label' && valueSameAsLabel ? _value : key === 'value' ? _value : _option.value,
                    _id: _option._id,
                    id: _option.id,
                };
            }
            return _option;
        });
        setOptions(newOptions);
    };

    // const onRemove = (_id: string) => () => setOptions(options.filter(x => x._id !== _id));

    // const onAdd = () => {
    //     const _id = nanoid();
    //     const newOption: TInputOption = {
    //         _id,
    //         id: 0,
    //         label: '',
    //         value: '',
    //     };
    //     setOptions([...options, newOption]);
    // };

    return (
        <div className="flex flex-col w-full mt-6">
            <h2 className="text-blueMainText text-sm font-medium">Options:</h2>
            <div className="flex mt-3">
                <FieldLabel label={t('@label', { defaultValue: 'Label' })} className={inputClassName} />
                <FieldLabel label={t('@value', { defaultValue: 'Value' })} />
            </div>
            {options.map((_option, index) => (
                <div key={_option._id} className={`flex w-full ${index > 0 ? 'mt-3' : ''}`}>
                    <InputField
                        onChange={({ target }) => onChange(_option._id, 'label', target.value)}
                        hideLabel
                        className={inputClassName}
                        value={_option.label}
                        placeholder={t('@label')}
                        disabled
                    />
                    <InputField
                        disabled
                        hideLabel
                        onChange={({ target }) => onChange(_option._id, 'value', target.value)}
                        className={inputClassName}
                        value={_option.value}
                        placeholder={t('@value')}
                    />
                    {/* {options.length > 2 && <RemoveButton className="mt-auto mb-1" onClick={onRemove(_option._id)} />} */}
                </div>
            ))}
            {/* <AddButton className="mt-2" title={t('@add.new.option', { defaultValue: 'Add option' })} onClick={onAdd} /> */}
        </div>
    );
}, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(nextProps)) {
        return false;
    }

    return true;
}
