import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
    DetailsEmpty,
    DetailsInfoOverview,
    DetailsLoader,
} from '../../../../components/details-entity';
import { Error404 } from '../../../../components/errors';
import { CustomTabContainer } from '../../../../components/tabs/custom-tab/CustomTabContainer';
import { TASKS_PATH } from '../../../../constants';
import { useAccountId, useDetailsError, useToggleVisibility } from '../../../../hooks';
import { Nullable, TaskDetailsModel, TaskDetailsResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import { formatDateAndTime, queryParams } from '../../../../utils';
import { TaskVersionsTab } from './tabs';
import { ToggleActionButton, TableActionRow } from '../../../../components/tables';
import { toast } from '../../../../components/ui';
import { ConfirmationPopupModal } from '../../../../components/modals';
import { useDispatch } from 'react-redux';
import { actions as versionActions } from '../../../../redux/thunk/app/task/taskVersionsThunk';

enum tabs {
    versions = 'versions',
}

export const TaskDetailsPage = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const accountId = useAccountId();
    const history = useHistory();
    const dispatch = useDispatch();
    const { detailsError, handleError, resetError } = useDetailsError();
    const entityId = parseInt(params?.id);

    const [details, setDetails] = useState<Nullable<TaskDetailsModel>>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility({ durationHide: 400 });

    useEffect(() => {
        fetchDetails();
    }, [accountId, entityId]);

    const fetchDetails = async () => {
        try {
            resetError();
            setDetailsLoading(true);
            const response: TaskDetailsResponseModel = await axios.get(`/pipeline/task?id=${entityId}`);
            setDetails(response.model);
        } catch (err) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onClickEdit = () =>
        history.push(`${TASKS_PATH}/edit-task/${entityId}${queryParams.formatForNavigation()}`);

    const onRebuild = async () => {
        try {
            const payload = {
                accountId,
                id: entityId,
            };
            await axios.post(`/pipeline/task/rebuild`, payload);
            toast.success('Task has been rebuilt!');
            dispatch(versionActions.request({ page: 0 }))
        } catch (err) {}
    };

    const onClickDelete = () => {
        onToggleVisibility(true)
    }

    const handleDelete = async () => {
        try {
            setDeleteLoading(true)
            await axios.delete(`pipeline/task?id=${entityId}&accountId=${accountId}`)
            toast.success('Task has been deleted!');
            history.push(`${TASKS_PATH}${queryParams.formatForNavigation()}`)
        } catch (err) {
            setDeleteLoading(false)
            onToggleVisibility(false)
            handleError(err)
        }
    }

    if (detailsError.status === 404) {
        return <Error404 model="task" />;
    }

    const actions = [
        {
            action: 'edit',
            onClick: onClickEdit,
        },
        {
            action: 'deploy',
            displayName: 'Rebuild',
            onClick: onRebuild,
        },
        {
            action: 'delete',
            onClick: onClickDelete
        }
    ]

    return (
        <>
            {shouldRenderModal && (
                <ConfirmationPopupModal
                    title={t(`@confirm-delete.pipelines-task`)}
                    isVisible={isModalVisible}
                    onConfirm={() => handleDelete()}
                    loading={deleteLoading}
                    onCancel={() => {
                        onToggleVisibility(false);
                    }}
                    description={'Are you sure you want to delete this item'}
                />
            )}
            <DetailsInfoOverview
                data={
                    details
                        ? {
                              '@common.title': details?.title,
                              '@alias': details?.alias,
                              '@inputs': details.inputs.length,
                              '@outputs': details.outputs.length,
                              '@python.script': details?.scriptPath,
                              '@requirements.text.file': details?.requirementsPath ?? '',
                              '@createdAt': formatDateAndTime(details?.createdAt),
                              '@createdBy': details.createdBy,
                              '@common.last-modified': formatDateAndTime(details?.lastModified),
                              '@modifiedBy': details?.modifiedBy,
                          }
                        : {}
                }
                loading={detailsLoading}
                title={t('@task.overview', { defaultValue: 'Task overview' })}
                titleRightComponent={
                    <ToggleActionButton>
                        <>
                            {actions.map((_action, i) => {
                                return (
                                    <TableActionRow
                                        key={i}
                                        onClickAction={_action.onClick || (() => {})}
                                        {..._action}
                                        row={i}
                                    />
                                );
                            })}
                        </>
                    </ToggleActionButton>
                }
            />

            {detailsLoading ? (
                <DetailsLoader />
            ) : !details ? (
                <DetailsEmpty onRefresh={fetchDetails} />
            ) : (
                <CustomTabContainer
                    defaultActiveTab={tabs.versions}
                    animationEnabled={false}
                    tabs={[
                        {
                            component: <TaskVersionsTab taskId={details.id} />,
                            displayName: t('@versions'),
                            key: tabs.versions,
                        },
                    ]}
                    style={{ minHeight: 500 }}
                    className="mt-6"
                />
            )}
        </>
    );
};
