import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useKeyPressListener } from '../../../hooks';
import { modalAnimationStyles, modalOverlayAnimationStyles } from '../../../styles/animationStyles';
import { Button } from '../../buttons';
import { Loader } from '../../ui';

export interface ConfirmationPopupModalProps {
    onConfirm?: (e: any) => any;
    onCancel?: () => any;
    title?: string;
    description?: string;
    confirmTitle?: string;
    cancelTitle?: string;
    isVisible: boolean;
    confirmButtonClassName?: string;
    customIcon?: ReactNode;
    loading?: boolean;
}

export const ConfirmationPopupModal: React.FC<ConfirmationPopupModalProps> = ({
    cancelTitle = 'Cancel',
    confirmTitle = 'Delete',
    description,
    onCancel,
    onConfirm,
    title,
    confirmButtonClassName,
    isVisible,
    loading,
    customIcon,
}) => {
    /**
     * hooks
     */
    useKeyPressListener(onKeyPress);

    function onKeyPress({ key }: KeyboardEvent) {
        key === 'Escape' && onCancel && onCancel();
    }

    return createPortal(
        <CSSTransition in={isVisible} timeout={100} unmountOnExit={false}>
            {state => (
                <div
                    onClick={e => {
                        e.stopPropagation();
                        onCancel && onCancel();
                    }}
                    className={`fixed z-50 inset-0 overflow-y-auto bg-mainOverlay/50`}
                    role="dialog"
                    aria-modal="true"
                    style={{
                        transition: 'opacity 500ms ease-in-out',
                        opacity: 0,
                        ...modalOverlayAnimationStyles[state],
                    }}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-mainOverlay/50 transition-opacity" aria-hidden="true"></div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>

                        <div
                            style={{
                                transition: 'opacity 500ms ease-in-out',
                                opacity: 0,
                                ...modalAnimationStyles[state],
                            }}
                            onClick={e => e.stopPropagation()}
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        >
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        {customIcon ? (
                                            customIcon
                                        ) : (
                                            <svg
                                                className="h-6 w-6 text-red-600"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="#E34850"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            {isVisible && title}
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">{isVisible && description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={loading}
                                    onClick={e => {
                                        e.stopPropagation();
                                        !loading && onConfirm && onConfirm(e);
                                    }}
                                    type="button"
                                    style={{ minWidth: 120 }}
                                    className={`transition-all duration-300 mt-2 hover:bg-redHover w-full  bg-deleteColor flex items-center justify-center rounded px-4 h-10 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm ${confirmButtonClassName}`}
                                >
                                    {loading ? <Loader color="white" size={20} /> : confirmTitle}
                                </button>
                                <Button
                                    onClick={e => {
                                        e.stopPropagation();
                                        onCancel && onCancel();
                                    }}
                                    title={cancelTitle}
                                    className="!text-blueMainText rounded-md mt-2 sm:w-auto w-full h-10"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </CSSTransition>,
        document.body
    );
};
