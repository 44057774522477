import { useEffect, useState } from 'react';

export function useKeyPressListener(onKeyPress?: (e: KeyboardEvent) => any) {
    const [keyboardEvent, setKeyboardEvent] = useState<KeyboardEvent | null>(null);

    useEffect(() => {
        document.addEventListener('keydown', _onKeyPress);
        return () => document.removeEventListener('keydown', _onKeyPress);
    }, []);

    const _onKeyPress = (e: KeyboardEvent) => {
        onKeyPress && onKeyPress(e);
        setKeyboardEvent(e);
    };

    return { keyPressed: keyboardEvent ? keyboardEvent.key : '', keyboardEvent };
}
