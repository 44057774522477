import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import { outlineButtonHoverClassName } from '../../constants';
import { Loader, Tooltip } from '../ui';

export interface OutlineButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
    className?: string;
    icon?: any;
    htmlType?: any;
    tooltipMessage?: string | undefined;
    style?: CSSProperties;
    isLoading?: boolean;
    loadingColor?: string;
    disableScaleAnimation?: boolean;
    noStyle?: boolean;
}

export const OutlineButton: React.FC<OutlineButtonProps> = ({
    className,
    onClick,
    icon,
    loadingColor,
    htmlType = 'button',
    title,
    style,
    tooltipMessage,
    isLoading,
    disableScaleAnimation,
    noStyle,
    ...props
}) => {
    return (
        <>
            <button
                type={htmlType}
                disabled={isLoading || props.disabled}
                onClick={e => !isLoading && !props.disabled && onClick && onClick(e)}
                style={style}
                className={
                    noStyle
                        ? className
                        : `${!props.disabled && !disableScaleAnimation && outlineButtonHoverClassName} ${
                              props.disabled && 'cursor-not-allowed'
                          } flex flex-row h-8 sm:h-10 justify-center items-center border-1 rounded w-auto border px-4 text-xs sm:text-sm border-primaryBlue200 border-solid text-primaryBlue500 bg-white ${className}`
                }
                {...props}
                data-tip={tooltipMessage}
            >
                {isLoading ? (
                    <Loader size={24} color={loadingColor || '#1D79F2'} />
                ) : (
                    <>
                        {icon}
                        {title}
                    </>
                )}
            </button>
            <Tooltip />
        </>
    );
};
