import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../components/dropdown';

interface GlobalActionsComponentProps {
    onClickAction: (action: 'remove' | 'move') => any;
    isActive: boolean;
    triggerClassName?: string;
}

const style = { width: 160 };

type actionType = {
    action: string;
    onClick: () => any;
    displayName: string;
    disabled?: boolean;
};
export const GlobalActionsComponent: React.FC<GlobalActionsComponentProps> = ({
    onClickAction,
    isActive,
    triggerClassName,
}) => {
    const { t } = useTranslation();

    const actions = useMemo(() => {
        let actions: actionType[] = [
            {
                action: 'Reply',
                onClick: () => onClickAction('move'),
                displayName: t('@move', { defaultValue: 'Move' }),
            },
            {
                action: 'Delete',
                displayName: t('@remove', { defaultValue: 'Remove' }),
                onClick: () => onClickAction('remove'),
            },
        ];
        return actions;
    }, []);

    return (
        <Dropdown
            withArrow={false}
            childrenContainerClassName="left-0"
            triggerProps={{
                disabled: !isActive,
                style: {
                    ...style,
                    justifyContent: 'space-between',
                    cursor: isActive ? 'pointer' : 'default',
                },
                className: `bg-white text-xs sm:text-sm flex h-8 sm:h-10 rounded items-center text-mainGrayText !border-grey200 border-solid border px-3 ${
                    isActive ? '!text-greyBlack300' : 'opacity-50'
                } ${triggerClassName}`,
                children: (
                    <>
                        <span>{t('@actions', { defaultValue: 'Actions' })}</span>
                        <div>
                            <GjirafaIcon name="ArrowDown" />
                        </div>
                    </>
                ),
            }}
        >
            <div
                style={{ ...style }}
                className="bg-white base-card-style z-50 rounded-lg mt-2 flex flex-col dropdown-shadow py-1"
            >
                {actions.map(_action => (
                    <div
                        key={_action.action}
                        onClick={() => {
                            !_action.disabled && _action.onClick();
                        }}
                        className={`transition-colors  hover:bg-mainBackground px-4 py-2 flex flex-row items-center text-sm font-regular text-blueMainText ${
                            _action.disabled ? 'opacity-50' : 'cursor-pointer hover:text-mainBlue'
                        }`}
                    >
                        <div className="pr-4">
                            <GjirafaIcon name={_action.action} />
                        </div>
                        {_action.displayName}
                    </div>
                ))}
            </div>
        </Dropdown>
    );
};
