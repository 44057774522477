import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CommonFlowProps } from '..';
import {
    FooterButtons,
    HeaderForCreatePages,
    RunScheduleComponent,
    shouldDynamicInputRender,
} from '../../../../components/common';
import { Loader, toast } from '../../../../components/ui';
import { artifactContentTypes, inputTypes, runSchedules } from '../../../../constants';
import { useAccountId, useCurrentWidth } from '../../../../hooks';
import {
    BaseEntityProperty,
    BaseEntityValue,
    FlowActionDetailsModel,
    FlowActionDetailsResponseModel,
    FlowActionListResponseModel,
    FlowInstanceTypeListResponseModel,
    SelectOptionType,
    TabularDataSummary,
} from '../../../../models';
import { selectors as instanceTypesSelectors } from '../../../../redux/thunk/app/flow/flowInstanceTypesThunk';
import axios from '../../../../services/axios';
import {
    addNewOptionToDynamicInputs,
    deleteErrorKey,
    formatArrayForSelectOptions,
    getFormattedQuery,
} from '../../../../utils';
import { TextualFlowStep } from './TextualFlowStep';

export interface TextualFlowStepStateType {
    id: number;
    _id: number;
    inputs: BaseEntityProperty[];
    action: FlowActionDetailsModel;
    errors?: any;
    otherSteps?: TextualFlowStepStateType[];
}

export interface CreateTextualFlowProps extends CommonFlowProps {}

type runScheduleDataType = {
    scheduleFrequency: any;
    instanceType: SelectOptionType | null;
};

export const CreateTextualFlow: React.FC<CreateTextualFlowProps> = ({
    basicInformation,
    flowEditResponseModel,
    formId,
    onCancel,
    onSuccess,
    onValidateBasicInformation,
}) => {
    const accountId = useAccountId();
    const currentWidth = useCurrentWidth();
    const { t } = useTranslation();
    const isEditing = useMemo(() => !!flowEditResponseModel, [flowEditResponseModel]);
    const artifactId = parseInt(basicInformation.artifact?.value || '0');

    /**
     * Local State
     */
    const [stepsLoading, setStepsLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [flowSteps, setFlowSteps] = useState<TextualFlowStepStateType[]>([]);
    const [runScheduleData, setRunScheduleData] = useState<runScheduleDataType>({
        scheduleFrequency: '',
        instanceType: null,
    });
    const [scheduleSelectedOption, setScheduleSelectedOption] = useState<SelectOptionType | null>(null);
    const [formLoading, setFormLoading] = useState<{ runImmediately: boolean; loading: boolean }>({
        runImmediately: false,
        loading: false,
    });

    /**
     * Selectors
     */
    const instanceTypesResponse: FlowInstanceTypeListResponseModel = useSelector(instanceTypesSelectors.getResponse);
    const instanceTypesLoading = useSelector(instanceTypesSelectors.getIsLoading);
    const instanceTypesOptions: SelectOptionType[] = useMemo(
        () =>
            instanceTypesResponse.models
                ? instanceTypesResponse.models.map(x => ({ value: x.id?.toString(), label: x.name, ...x }))
                : [],
        [instanceTypesResponse]
    );

    useEffect(() => {
        if (!flowEditResponseModel) {
            fetchTextualSteps();
        }

        if (flowEditResponseModel) {
            mapFlowDetails();
        }
    }, [flowEditResponseModel]);

    const fetchTextualSteps = async () => {
        try {
            setStepsLoading(true);
            const flowActionListQuery = {
                page: -1,
                actionType: artifactContentTypes.textual,
            };

            const { models }: FlowActionListResponseModel = await axios.get(
                `/flowAction/list?${getFormattedQuery(flowActionListQuery)}`
            );

            let _flows: TextualFlowStepStateType[] = [];

            for (let i = 0; i < models.length; i++) {
                let currentAction = models[i];

                const flowActionQuery = {
                    id: currentAction.id,
                };
                const { model: flowActionDetails }: FlowActionDetailsResponseModel = await axios.get(
                    `/flowAction?${getFormattedQuery(flowActionQuery)}`
                );
                let inputs = flowActionDetails.properties.map(el => {
                    if (el.inputType === inputTypes.dropdown || el.inputType === inputTypes.dropdownOpen) {
                        const options = el?.options || [];
                        return {
                            ...el,
                            value: !!el?.defaultValue ? options?.find(x => x?.value === el?.defaultValue) : null,
                            inputName: el?.name,
                            options,
                        };
                    }
                    return {
                        ...el,
                        value: el?.defaultValue || '',
                        inputName: el?.name,
                    };
                });
                let _flow = {
                    _id: i + 1,
                    action: flowActionDetails,
                    id: 0,
                    inputs,
                    errors: {},
                };
                //for repeatable action
                _flows.push({ ..._flow, otherSteps: flowActionDetails.isRepeatable ? [_flow] : [] });
            }

            setFlowSteps(_flows);
        } catch (err: any) {
        } finally {
            setStepsLoading(false);
        }
    };

    const onValidateSteps = () => {
        let response = {
            message: '',
            isValid: true,
        };

        let steps = flowSteps.map(step => {
            let _errors: any = {};

            if (step.action.isRepeatable && step.otherSteps) {
                step.otherSteps.forEach(otherStep => {
                    otherStep.inputs.forEach(inp => {
                        if (inp.required && !inp.value) {
                            response.isValid = false;
                            _errors[inp.inputName] = 'This Fields is Required!';
                        }
                    });
                });
            } else {
                step.inputs.forEach(inp => {
                    if (shouldDynamicInputRender(inp, step.inputs) && inp.required && !inp.value) {
                        _errors[inp.inputName] = 'This field is required!';
                        response.isValid = false;
                    }
                });
            }

            return {
                ...step,
                errors: _errors,
            };
        });
        setFlowSteps(steps);

        return response;
    };

    const handleCreateOption = (_id, inputName: string, value: string) => {
        const flowStep: TextualFlowStepStateType | null = flowSteps.find(x => x._id === _id) || null;
        if (flowStep) {
            let inputs = addNewOptionToDynamicInputs(flowStep.inputs, inputName, value);
            flowStep.inputs = inputs;
            setFlowSteps(prev => prev.map(x => (x._id === _id ? flowStep : x)));
        }
    };

    const onChangeInputs = (_id: number, key: string, value: any) => {
        let flow: TextualFlowStepStateType | null = flowSteps.find(x => x._id === _id) || null;
        if (flow) {
            let inputs = flow.inputs.map(inp => (inp.inputName === key ? { ...inp, value } : inp));
            let _errors = flow.errors;
            if (_errors[key]) delete _errors[key];

            setFlowSteps(prev => prev.map(x => (x._id === _id ? { ...x, inputs, errors: _errors } : x)));
        }
    };

    const onValidateRunSchedule = () => {
        let isValid = true;
        let _errors: any = {};

        if (!scheduleSelectedOption) {
            _errors.schedule = 'Please select a schedule!';
            isValid = false;
        }

        if (scheduleSelectedOption?.value === '2' || scheduleSelectedOption?.value === '3') {
            if (!runScheduleData?.scheduleFrequency) {
                _errors.scheduleFrequency = 'This field is required!';
                isValid = false;
            }
        }

        if (!runScheduleData.instanceType) {
            _errors.instanceType = 'Instance type is required!';
            isValid = false;
        }

        setErrors(_errors);
        return isValid;
    };

    const onValidateForm = () => {
        toast.dismiss();

        if (!onValidateBasicInformation()) {
            toast.error('You have field errors at basic information!');
            return false;
        }
        if (!onValidateSteps().isValid) {
            toast.error('You have field errors at steps!');
            return false;
        }
        if (!onValidateRunSchedule()) {
            toast.error('You have errors at Run schedule fields!');
            return false;
        }

        return true;
    };

    const mapInputs = (
        properties: BaseEntityProperty[],
        values: BaseEntityValue[],
        initialSummary?: TabularDataSummary,
        summaryForGroupBy?: TabularDataSummary
    ) => {
        return properties
            .map((property: BaseEntityProperty, index) => {
                let _inputs: BaseEntityValue[] | undefined = values.filter(el => el.propertyId === property.id) || [];

                if (_inputs.length < 1) {
                    _inputs = [
                        {
                            id: 0,
                            propertyId: property.id,
                            propertyName: property.name,
                            value: property.defaultValue,
                        },
                    ];
                }

                let _arr = _inputs.map(input => {
                    let options = property.options;
                    if (property.isColumn) {
                        options = formatArrayForSelectOptions(
                            index === 0
                                ? summaryForGroupBy?.columns || initialSummary?.columns || []
                                : initialSummary?.columns,
                            'name',
                            'name'
                        );
                    }

                    let value: any = null;
                    if (property.inputType === inputTypes.dropdown || property.inputType === inputTypes.dropdownOpen) {
                        value = input?.value
                            ? options?.find(x => x.value === input?.value)
                            : options?.find(x => x.value === property?.defaultValue) || null;

                        if (!value && input?.value) {
                            let newOption = {
                                label: input?.value || '',
                                value: input?.value || '',
                            };
                            options.push(newOption);
                            value = newOption;
                        }
                    } else {
                        value = input?.value || property?.defaultValue || '';
                    }

                    return {
                        ...property,
                        options,
                        value,
                        inputName: property?.isRepeatable ? `${property.name} ${nanoid()}` : property.name,
                        valueId: input?.id || 0,
                    };
                });
                return _arr;
            })
            .flat();
    };

    const mapFlowDetails = async () => {
        if (!flowEditResponseModel) return;

        try {
            setStepsLoading(true);

            const { scheduleId, scheduleFrequency, steps, instanceType } = flowEditResponseModel.model;
            setScheduleSelectedOption(runSchedules.find(x => x.value === scheduleId.toString()) || null);
            setRunScheduleData({
                scheduleFrequency: scheduleFrequency,
                instanceType: {
                    label: instanceType.name,
                    value: instanceType.id?.toString(),
                },
            });
            //Textual steps
            let finalSteps: TextualFlowStepStateType[] = [];

            for (let i = 0; i < steps.length; i++) {
                let currentStep = steps[i];

                const query = {
                    id: currentStep?.flowActionId,
                    artifactId,
                };
                let { model: flowActionDetails }: FlowActionDetailsResponseModel = await axios.get(
                    `/flowAction?${getFormattedQuery(query)}`
                );

                let inputs: BaseEntityProperty[] = mapInputs(flowActionDetails.properties, currentStep.values);

                finalSteps.push({
                    _id: i + 1,
                    id: currentStep.id,
                    inputs,
                    errors: {},
                    action: flowActionDetails,
                });
            }

            // new code for repeatable action
            let otherSteps = finalSteps.filter(x => x.action.isRepeatable);

            let firstRepeatableIndex = -1;

            finalSteps.forEach((x, i) => {
                if (x.action.isRepeatable) {
                    firstRepeatableIndex = firstRepeatableIndex < 0 ? i : firstRepeatableIndex;
                }
            });

            let _finalFlows: TextualFlowStepStateType[] = [
                ...finalSteps.slice(0, firstRepeatableIndex),
                {
                    _id: otherSteps[0]._id,
                    action: otherSteps[0].action,
                    id: 0,
                    inputs: otherSteps[0].inputs.map(x => ({ ...x, value: '' })),
                    errors: {},
                    otherSteps: otherSteps.map(x => ({
                        ...x,
                        inputs: x.inputs.map(inp => ({ ...inp, inputName: `${inp.name} ${nanoid()}` })),
                    })),
                },
                ...finalSteps.slice(firstRepeatableIndex + 1).filter(f => !f.action.isRepeatable),
            ];

            setFlowSteps(
                _finalFlows.sort(
                    (a: TextualFlowStepStateType, b: TextualFlowStepStateType) =>
                        a.action.orderNumber - b.action.orderNumber
                )
            );
        } catch (err) {
        } finally {
            setStepsLoading(false);
        }
    };

    const handleSave = async (runImmediately = false) => {
        if (!onValidateForm()) {
            return;
        }

        try {
            setFormLoading({ runImmediately, loading: true });

            // For Repeatable action
            let { otherSteps, index } = (() => {
                let res: { otherSteps: TextualFlowStepStateType[]; index: number } = {
                    otherSteps: [],
                    index: 0,
                };

                flowSteps.forEach((tFlow, i) => {
                    if (tFlow.action.isRepeatable && tFlow.otherSteps) {
                        if (tFlow.otherSteps.length < 1) {
                            res.otherSteps = [
                                {
                                    _id: 1,
                                    action: tFlow.action,
                                    id: 0,
                                    inputs: tFlow.inputs,
                                    errors: {},
                                },
                            ];
                        } else res.otherSteps = tFlow.otherSteps;

                        res.index = i;
                    }
                });

                return res;
            })();

            let _steps = flowSteps.slice(0, index);
            let finalSteps: TextualFlowStepStateType[] = [..._steps, ...otherSteps, ...flowSteps.slice(index + 1)];

            const { title, artifact, description, optionalArtifact } = basicInformation;
            const { scheduleFrequency, instanceType } = runScheduleData;

            const payload: any = {
                id: isEditing ? flowEditResponseModel?.model.id : 0,
                accountId,
                instanceTypeId: instanceType?.value,
                title,
                description,
                inputArtifactId: artifact?.value,
                runImmediately,
                scheduleId: scheduleSelectedOption?.value,
                scheduleFrequency: !!scheduleFrequency ? scheduleFrequency : 0,
                steps: finalSteps.map(flow => ({
                    id: flow.id,
                    flowActionId: flow.action.id?.toString(),
                    values: flow.inputs.map(inp => ({
                        id: isEditing ? inp.valueId : 0,
                        propertyId: inp.id,
                        value: typeof inp.value === 'object' ? inp.value?.value : inp.value,
                    })),
                })),
            };

            if (basicInformation.optionalArtifact) {
                payload.optionalArtifactId = typeof optionalArtifact === 'object' ? optionalArtifact?.value : 0;
            }

            await axios.post('/flow', payload);
            setFormLoading({ runImmediately, loading: false });
            onSuccess(isEditing, runImmediately);
        } catch (err) {
            setFormLoading({ runImmediately, loading: false });
        }
    };
    const handleSubmit = e => {
        e.preventDefault();
        handleSave();
    };

    const onDuplicateInputs = (_id: number) => {
        try {
            let currentFlow: TextualFlowStepStateType | null = flowSteps.find(x => x._id === _id) || null;
            if (!currentFlow?.otherSteps) {
                return;
            }

            let lastOtherStep = currentFlow.otherSteps[currentFlow.otherSteps.length - 1];
            currentFlow.otherSteps = [
                ...currentFlow.otherSteps,
                {
                    _id: (lastOtherStep?._id || 0) + 1,
                    id: 0,
                    action: currentFlow.action,
                    inputs: currentFlow.inputs.map(x => ({
                        ...x,
                        valueId: 0,
                        value: '',
                        inputName: lastOtherStep ? `${x.name} ${nanoid()}` : x.name,
                    })),
                    errors: {},
                    otherSteps: [],
                },
            ];

            setFlowSteps(prev => prev.map(p => (p._id === _id ? currentFlow || p : p)));
        } catch (err: any) {}
    };
    const onDeleteInputs = (_id: number, _otherStepId: number) => {
        setFlowSteps(prev => {
            return prev.map(p =>
                p._id === _id
                    ? {
                          ...p,
                          otherSteps: p.otherSteps?.filter(x => x._id !== _otherStepId),
                      }
                    : p
            );
        });
    };
    const onChangeOtherStepsInputs = (_id: number, _otherStepId: number, key: string, value: string) => {
        try {
            let currentFlow: TextualFlowStepStateType | null = flowSteps.find(x => x._id === _id) || null;
            if (!currentFlow || !currentFlow.otherSteps) {
                return;
            }

            currentFlow.otherSteps = currentFlow.otherSteps.map(oStep =>
                oStep._id === _otherStepId
                    ? { ...oStep, inputs: oStep.inputs.map(x => (x.inputName === key ? { ...x, value } : x)) }
                    : oStep
            );

            let _errors = currentFlow.errors;
            if (_errors[key]) delete _errors[key];

            currentFlow.errors = _errors;

            setFlowSteps(prev => prev.map(x => (x._id === _id ? currentFlow || x : x)));
        } catch (err: any) {}
    };
    const onChangeRunScheduleData = (key, value) => {
        deleteErrorKey(key, setErrors);
        setRunScheduleData(prev => ({ ...prev, [key]: value }));
    };

    const renderSteps = () => {
        return flowSteps.map((flow, i) => {
            const { _id, action, inputs, errors, otherSteps } = flow;
            return (
                <TextualFlowStep
                    key={_id}
                    isRepeatable={action.isRepeatable}
                    isLastItem={flowSteps.length - 1 === i}
                    inputs={inputs}
                    onChangeInputs={(key, value) => onChangeInputs(_id, key, value)}
                    title={action.name}
                    handleCreateOption={(i, v) => handleCreateOption(_id, i, v)}
                    errors={errors}
                    onDeleteInputs={otherId => onDeleteInputs(_id, otherId)}
                    onDuplicateInputs={() => onDuplicateInputs(_id)}
                    onChangeOtherStepsInputs={(oId, k, v) => onChangeOtherStepsInputs(_id, oId, k, v)}
                    otherSteps={otherSteps}
                    className=""
                />
            );
        });
    };

    const saveButtonsDisabled = useMemo(() => stepsLoading || formLoading.loading, [stepsLoading, formLoading]);
    return (
        <form id={formId} className="w-full" onSubmit={handleSubmit}>
            <HeaderForCreatePages
                className="mt-10"
                title={t('@pages.workflow.feature.engineering-flows') + ' - Textual'}
            />
            {stepsLoading ? (
                <div className="w-full items-center justify-center flex flex-col p-10">
                    <Loader size={40} />
                    <span className="mt-2 text-sm font-medium text-blueMainText">Loading steps</span>
                </div>
            ) : (
                <div className={`w-full flex flex-col ${currentWidth > 1120 && 'items-center'} `}>{renderSteps()}</div>
            )}
            <RunScheduleComponent
                instanceTypeProps={{
                    loading: instanceTypesLoading,
                    onChange: value => {
                        onChangeRunScheduleData('instanceType', value);
                    },
                    options: instanceTypesOptions,
                    value: runScheduleData.instanceType,
                }}
                errors={errors}
                onChangeRunScheduleData={onChangeRunScheduleData}
                onChangeScheduleSelected={value => {
                    setScheduleSelectedOption(value);
                    deleteErrorKey('schedule', setErrors);
                }}
                runScheduleData={runScheduleData}
                scheduleSelectedOption={scheduleSelectedOption}
            />
            <HeaderForCreatePages title="" hideIcon className="mt-10" />
            <div className={`w-full flex flex-col ${currentWidth > 1120 && 'items-center'} `}>
                <FooterButtons
                    onClickCancel={onCancel}
                    saveButtonLoading={!formLoading.runImmediately && formLoading.loading}
                    saveButtonProps={{
                        form: formId,
                        type: 'submit',
                        disabled: saveButtonsDisabled,
                    }}
                    thirdButtonProps={{
                        type: 'button',
                        disabled: saveButtonsDisabled,
                    }}
                    thirdButtonLoading={formLoading.runImmediately && formLoading.loading}
                    onClickThirdButton={() => handleSave(true)}
                    thirdButtonTitle={t('@common.save-and-run')}
                />
            </div>
        </form>
    );
};
