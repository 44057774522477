import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ArtifactsTable } from '../../../components/tables';
import { useAccountId } from '../../../hooks';
import { actions as artifactsActions } from '../../../redux/thunk/app/artifact/artifactsThunk';
import { actions as dataSourceActions } from '../../../redux/thunk/app/dataSource/dataSourcesThunk';

export const ArtifactsPage: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const accountId = useAccountId();

    useEffect(() => {
        dispatch(dataSourceActions.request({ accountId }));
        dispatch(
            artifactsActions.request({
                accountId,
                page: 0,
                searchQuery: '',
                artifactContentTypeId: null,
            })
        );
    }, [accountId]);

    useEffect(() => {
        return () => {
            dispatch(artifactsActions.removeNotificationQuery());
        };
    }, []);

    return (
        <div className="flex flex-1 flex-col h-full">
            <ArtifactsTable fetchDataOnMount={false} isFromSelectPopup={false} />
        </div>
    );
};
