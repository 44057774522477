import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentWidth } from '../../hooks';
import { useToggleVisibility } from '../../hooks/useToggleVisibility';
import { AccountDetailsResponseModel } from '../../models';
import { selectors as accountDetailsThunk } from '../../redux/thunk/app/account/accountDetailsThunk';
import { actions as themeActions, selectors as themeSelectors } from '../../redux/thunk/theme';
import { Loader, Tooltip } from '../ui';
import { AccountImage } from './AccountImage';
import SwitchAccount from './SwitchAccount';

const SwitchAcc: React.FC = () => {
    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility({
        initialValue: false,
    });

    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const dispatch = useDispatch();

    const isMenuOpen = useSelector(themeSelectors.getIsMenuOpened);
    const currentAccount: AccountDetailsResponseModel = useSelector(accountDetailsThunk.getResponse);
    const isLoading = useSelector(accountDetailsThunk.getIsLoading);

    const onClickSwitchAccount = () => {
        onToggleVisibility(true);
        currentWidth < 900 && dispatch(themeActions.closeMenu());
    };

    return (
        <>
            <div
                className={`border-b border-mainBorder flex items-center pb-6 pt-3 ${
                    !isMenuOpen && 'justify-center p-0 flex-col'
                }`}
            >
                {isLoading ? (
                    <Loader color="#1D79F2" />
                ) : (
                    <div
                        onClick={onClickSwitchAccount}
                        className={`flex flex-row items-center cursor-pointer ${
                            !isMenuOpen && 'p-0 justify-center w-auto'
                        }`}
                        data-for="@components.account.switch-account"
                        data-tip={!isMenuOpen ? t('@components.account.switch-account') : ''}
                    >
                        <AccountImage
                            account={currentAccount.model.name}
                            url={currentAccount.model.imageUrl}
                            className={isMenuOpen ? 'mr-3' : ''}
                            style={{
                                height: 36,
                                width: 36,
                                padding: 1,
                            }}
                        />

                        {isMenuOpen && (
                            <div className="cursor-pointer flex flex-col justify-center">
                                <span className="text-blueMainText text-sm font-normal ">
                                    {currentAccount?.model?.name}
                                </span>
                                <span className="text-secondaryText font-medium text-xs ">
                                    {t('@components.account.switch-account')}
                                </span>
                            </div>
                        )}
                        {!isMenuOpen && <Tooltip id="@components.account.switch-account" place="right" />}
                    </div>
                )}
            </div>
            {shouldRender && <SwitchAccount hide={() => onToggleVisibility(false)} isVisible={isVisible} />}
        </>
    );
};

export default SwitchAcc;
