import React, { CSSProperties } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, ObjectUploadingType, selectors } from '../../redux/thunk/app/storage/uploadObjectsThunk';
import { toast } from '../ui';
import { UploadingRow } from './UploadingRow';

export interface UploadingObjectsRowListProps {
    isVisible?: boolean;
    className?: string;
    style?: CSSProperties;
}
export const UploadingObjectsRowList: React.FC<UploadingObjectsRowListProps> = ({
    isVisible = true,
    className,
    style,
}) => {
    const dispatch = useDispatch();
    const objectsUploading: ObjectUploadingType[] = useSelector(selectors.getObjectsUploading);
    const { t } = useTranslation();

    const onCancelSingleUpload = (_upload: ObjectUploadingType) => {
        _upload.cancelToken.cancel();
        dispatch(actions.closeSingleUpload(_upload.id));
        setTimeout(() => {
            const _messageKey = '@request.has.been.canceled';
            toast.info(t(_messageKey, { title: _upload.title, defaultValue: '{{title}} has been canceled!' }));
        }, 10);
    };

    const renderUploadingObjects = () => {
        return objectsUploading.map(_upload => (
            <UploadingRow
                key={_upload.id}
                id={_upload.id}
                errorMessage={_upload.errorMessage}
                onCancelRequest={() => onCancelSingleUpload(_upload)}
                title={_upload.title}
                progress={_upload.uploadProgress}
            />
        ));
    };
    return (
        <AnimateHeight duration={250} delay={50} height={isVisible ? 'auto' : '0'}>
            <div
                className={`bg-white rounded-b ${className}`}
                style={{
                    maxHeight: '60vh',
                    overflowY: 'auto',
                    ...style,
                }}
            >
                {renderUploadingObjects()}
            </div>
        </AnimateHeight>
    );
};
