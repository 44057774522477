import { Dispatch } from 'redux';
import { InitialStateType, NotebookInstanceTypeListResponseModel } from '../../../../models';
import axios from '../../../../services/axios';
import { actionCreator, getAccountIdFromQuery } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    REQUEST: '@app/notebook/notebookInstanceTypesThunk/REQUEST',
    SUCCESS: '@app/notebook/notebookInstanceTypesThunk/SUCCESS',
    FAILURE: '@app/notebook/notebookInstanceTypesThunk/FAILURE',
};

/**
 * Initial state
 */

const initialState: InitialStateType<NotebookInstanceTypeListResponseModel> = {
    isLoading: false,
    errorMessage: '',
    response: {
        message: '',
        success: false,
        models: [],
    },
};

/**
 * selectors
 */
export const selectors = {
    getIsLoading: (state: RootState) => state.app.notebookInstanceTypes.isLoading,
    getResponse: (state: RootState) => state.app.notebookInstanceTypes.response,
    getErrorMessage: (state: RootState) => state.app.notebookInstanceTypes.errorMessage,
};

/**
 * Reducer
 */
const { REQUEST, SUCCESS, FAILURE } = actionTypes;

const Reducer = (state: InitialStateType<NotebookInstanceTypeListResponseModel> = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
            };
        case SUCCESS:
            return {
                ...state,
                response: payload,
                isLoading: false,
            };
        case FAILURE:
            return {
                ...state,
                errorMessage: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
export const actions = {
    request: function () {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(actionCreator(REQUEST));

                const accountId = getAccountIdFromQuery();
                const response: NotebookInstanceTypeListResponseModel = await axios.get(
                    `/notebookInstanceType/list?accountId=${accountId}`
                );

                dispatch(actionCreator(SUCCESS, { ...response, models: response.models.reverse() }));
            } catch (err: any) {
                dispatch(actionCreator(FAILURE, err.message));
            }
        };
    },
};
