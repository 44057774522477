import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { CSSProperties } from 'react';
import AnimateHeight from 'react-animate-height';
import { AnimationComponent, AnimationProps } from '../animation';

interface IAlertErrorComponentProps {
    className?: string;
    style?: CSSProperties;
    text: string;
    onClose?: () => any;
    show: boolean;
    animationType?: AnimationProps['type'];
}

export const AlertErrorComponent = ({
    text,
    onClose,
    show,
    className,
    style,
    animationType,
}: IAlertErrorComponentProps) => {
    const renderContent = () => {
        return (
            <div
                className={`flex w-full items-center justify-start py-4 px-4 bg-red500/30 rounded ${className}`}
                style={{ ...style }}
            >
                <div className="text-greyBlack300">
                    <GjirafaIcon name="Warning" />
                </div>
                <div className="flex flex-1 pl-2.5">
                    <span className="font-normal text-sm text-greyBlack300">{text}</span>
                </div>
                {onClose !== undefined && (
                    <div
                        className="px-2 cursor-pointer h-full flex items-center justify-center"
                        onClick={() => onClose()}
                    >
                        <GjirafaIcon name="Close" />
                    </div>
                )}
            </div>
        );
    };
    if (animationType !== 'height')
        return (
            <AnimationComponent
                show={show}
                duration={300}
                delay={50}
                transform="transform-gpu"
                type={animationType ?? 'fade-top'}
            >
                {renderContent()}
            </AnimationComponent>
        );

    return <AnimateHeight height={show ? 'auto' : 0}>{renderContent()}</AnimateHeight>;
};
