import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CommonTableProps, TableFilters } from '.';
import { breakpoints } from '../../constants';
import { useCurrentWidth } from '../../hooks';
import { AlgorithmListModel, AlgorithmListResponseModel } from '../../models';
import {
    actions as algorithmActions,
    selectors as algorithmSelectors,
} from '../../redux/thunk/app/algorithm/algorithmsThunk';
import { showFiltersHeader } from '../../utils';
import { RefreshButton } from '../buttons/RefreshButton';
import { Table } from './Table';
import { TableMobile } from './TableMobile';

export interface AlgorithmsTableProps extends CommonTableProps {}

export const AlgorithmsTable: FC<AlgorithmsTableProps> = ({
    className,
    fetchDataOnMount = true,
    isFromSelectPopup,
    tablePopupProps,
}) => {
    const currentWidth = useCurrentWidth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loading = useSelector(algorithmSelectors.getIsLoading);
    const response: AlgorithmListResponseModel = useSelector(algorithmSelectors.getResponse);

    const requestList = (payload, resetQuery?: boolean) => {
        dispatch(algorithmActions.request({ ...payload }, resetQuery));
    };

    useEffect(() => {
        if (fetchDataOnMount) {
            requestList({});
        }
    }, []);

    const onSelectRow = (row: AlgorithmListModel) => {
        if (isFromSelectPopup && tablePopupProps && tablePopupProps.onSelect) {
            tablePopupProps.onSelect({
                label: row?.name,
                value: row.id.toString(),
                ...row,
            });
        }
    };
    const onPageChanged = page => {
        requestList({ page });
    };

    const onRefresh = () => {
        requestList({});
    };
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );
    const commonTableProps = {
        requestList,
        headerButtonProps: {
            onRefresh,
            showRefresh: false,
            showCreate: false,
            showEdit: false,
            showDelete: false,
        },
        modelEndpoint: 'artifact',
        isFromSelectPopup,
        showHeaderButtons: true,
        emptyDataProps: {
            emptyText: 'No Algorithms found',
            actionMessage: null,
        },
        loading,
        response,
    };

    return (
        <div className={`flex flex-col ${className}`}>
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    pagination
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                requestList({
                                    [key]: value,
                                });
                            }}
                            response={response}
                            onSearch={searchQuery => {
                                requestList({ searchQuery, page: 0 });
                            }}
                            className="flex-row-reverse justify-between"
                        >
                            <div className="flex flex-1 justify-end">
                                <RefreshButton onRefresh={onRefresh} />
                            </div>
                        </TableFilters>
                    }
                    paginationProps={paginationProps}
                    selectedRow={tablePopupProps && tablePopupProps.selectedItem}
                    subHeader={showFiltersHeader(response)}
                    onRowClicked={isFromSelectPopup ? onSelectRow : undefined}
                    actions={isFromSelectPopup ? [] : undefined}
                    columns={[
                        {
                            selector: row => row?.name ?? '',
                            name: t('@common.name'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.groupName ?? '',
                            name: t('@common.group-name'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.url ?? '',
                            name: 'Reference',
                            cell: row => (
                                <a href={row?.url} className="text-blueMainText underline" target="_blank">
                                    {row.url}
                                </a>
                            ),
                        },
                    ]}
                />
            )}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    mainKeyProperty="name"
                    initialLoading={loading}
                    columns={[
                        {
                            selector: row => row?.name ?? '',
                            name: t('@common.name'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.groupName ?? '',
                            name: t('@common.group-name'),
                            sortable: true,
                        },
                        {
                            selector: row => row?.url ?? '',
                            name: 'Reference',
                            cell: row => (
                                <a
                                    href={row?.url}
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                    className="text-blueMainText underline text-ellipsis"
                                    target="_blank"
                                >
                                    {row.url}
                                </a>
                            ),
                        },
                    ]}
                    onClickItem={onSelectRow}
                    selectedItem={tablePopupProps && tablePopupProps.selectedItem}
                    paginationProps={{ ...paginationProps, hasNextPage: !!response.hasNextPage }}
                />
            )}
        </div>
    );
};
