import update from 'immutability-helper';
// import { nanoid } from 'nanoid';
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { HeaderForCreatePages, SectionContainerForCreatePages } from '../../../../../../components/common';
import { SelectOptionType } from '../../../../../../models';
import {
    taskFilePathDataTypesOptions,
    taskInputDataTypesOptions,
    taskInputTypes,
    taskSwitchDataTypesOptions,
} from '../../../constants';
import { TInput } from '../../hooks';
// import { AddButton } from '../AddButton';
import { TaskInputCard } from './TaskInputCard';
interface ITaskInputsProps {
    inputs: TInput[];
    setInputs: Dispatch<SetStateAction<TInput[]>>;
    isEditing: boolean;
}

export const TaskInputs = memo(({ inputs, setInputs, isEditing }: ITaskInputsProps) => {
    const { t } = useTranslation();
    const [openedInput, setOpenedInput] = useState(isEditing ? 'empty' : '');

    useEffect(() => {
        console.log({ openedInput });
        if (!openedInput && inputs.length) {
            setOpenedInput(inputs[inputs.length - 1]._id);
        }
    }, [inputs.length]);

    const checkIsOpen = useCallback(
        (_id: string) => {
            return _id === openedInput;
        },
        [openedInput, setOpenedInput]
    );

    const onToggleOpen = useCallback(
        (_id: string) => () => {
            setOpenedInput(prev => (prev === _id ? '' : _id));
        },
        [setOpenedInput, openedInput]
    );

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setInputs((prev: TInput[]) =>
            update(prev, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prev[dragIndex] as TInput],
                ],
            })
        );
    }, []);

    const removeCard = useCallback(
        (_id: string) => {
            const remainingIds = inputs.map(x => x._id).filter(x => x !== _id);
            setInputs(prev => prev.filter(x => x._id !== _id));
            if (remainingIds.length) {
                setTimeout(() => setOpenedInput(remainingIds[remainingIds.length - 1]), 100);
            }
        },
        [inputs.length]
    );

    // const addCard = useCallback(() => {
    //     const _id = nanoid();
    //     const getNewOption = () => ({ _id: nanoid(), id: 0, label: '', value: '' });

    //     const newInput: TInput = {
    //         _id,
    //         id: 0,
    //         inputType: null,
    //         name: '',
    //         required: true,
    //         options: [getNewOption(), getNewOption()],
    //     };
    //     setInputs(prevInputs => [...prevInputs, newInput]);
    //     setTimeout(() => setOpenedInput(_id), 100);
    // }, []);

    const onChangeInput = useCallback(
        (_id: string) => (key: keyof TInput, value: any) => {
            setInputs(prevInputs => {
                const newInputs = prevInputs.map(_input => {
                    let dataTypeOptions: SelectOptionType[] = [];
                    let dataType = _input.dataType;
                    if (key === 'inputType') {
                        let inputType = value?.label;
                        dataTypeOptions =
                            inputType === taskInputTypes.filePath
                                ? taskFilePathDataTypesOptions
                                : inputType === taskInputTypes.input
                                ? taskInputDataTypesOptions
                                : inputType === taskInputTypes.switch
                                ? taskSwitchDataTypesOptions
                                : [];

                        dataType = !dataTypeOptions.includes(_input.dataType?.label)
                            ? dataTypeOptions[0]
                            : _input.dataType;
                    }

                    return _input._id === _id
                        ? {
                              ..._input,
                              dataType: dataType,
                              [key]: value,
                          }
                        : _input;
                });
                return newInputs;
            });
        },
        [setInputs]
    );

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <HeaderForCreatePages title={t('@inputs', { defaultValue: 'Inputs' })} className="flex flex-col mt-8" />
                <SectionContainerForCreatePages width="large">
                    <div className={`flex flex-col w-full py-4 rounded-lg bg-white card-shadow-sm`}>
                        {inputs.map((input, index) => (
                            <TaskInputCard
                                key={input._id}
                                index={index}
                                input={input}
                                onChangeInput={onChangeInput(input._id)}
                                moveCard={moveCard}
                                removeCard={removeCard}
                                isOpen={checkIsOpen(input._id)}
                                onToggleOpen={onToggleOpen(input._id)}
                            />
                        ))}
                        {/* <AddButton
                            className={`ml-4 ${inputs.length ? 'mt-4' : ''}`}
                            onClick={addCard}
                            title={t('@add.new.input', { defaultValue: 'Add new Input' })}
                        /> */}
                        {inputs.length === 0 ? (<div className="text-gray-500 ml-6">No Inputs</div>) : ''}
                    </div>
                </SectionContainerForCreatePages>
            </DndProvider>
        </>
    );
});
