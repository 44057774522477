import { CSSProperties, ReactNode } from 'react';
import { Icon } from '.';
import { IconTypes } from './Icon';
export interface HeaderForCreatePagesProps {
    title?: string;
    icon?: IconTypes;
    className?: string;
    style?: CSSProperties;
    hideIcon?: boolean;
    rightComponent?: ReactNode;
}

export const HeaderForCreatePages: React.FC<HeaderForCreatePagesProps> = ({
    className,
    icon = IconTypes.info,
    style,
    title,
    rightComponent,
    hideIcon,
}) => {
    return (
        <div
            className={`pb-2 border-b flex border-mainBorder  justify-between w-full h-auto mb-6 ${className}`}
            style={{ borderBottom: '1px solid #E0E5EB', ...style }}
        >
            <div className="flex flex-row items-center justify-start">
                <span className="font-normal text-sm sm:text-base text-blueMainText">{title}</span>
                {!hideIcon && <Icon name={icon} className="ml-2" />}
            </div>
            {rightComponent}
        </div>
    );
};
