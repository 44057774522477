import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoBackRowComponent } from '.';
import { UploadButton } from '../../../../components/buttons';
import { NoDataComponent, NoDataImagesType } from '../../../../components/tables';

interface StorageNoDataComponentProps {
    displayGoBack: boolean;
    onClickGoBack: () => void;
    handleFilesSelected: (files: FileList) => any;
}

export const StorageNoDataComponent: React.FC<StorageNoDataComponentProps> = ({
    displayGoBack,
    onClickGoBack,
    handleFilesSelected,
}) => {
    const { t } = useTranslation();

    return (
        <div className="w-full flex flex-col">
            {displayGoBack && (
                <div className="flex w-full hover:bg-mainBackground cursor-pointer hover:text-mainBlue">
                    <GoBackRowComponent className="py-3 pl-10" onClick={onClickGoBack} />
                </div>
            )}
            <NoDataComponent
                imageType={NoDataImagesType.Artifact}
                actionMessageComponent={
                    <UploadButton
                        multiple
                        accept="*"
                        handleUpload={e => {
                            if (e.target.files && e.target.files.length) handleFilesSelected(e.target.files);
                        }}
                    >
                        <span className="text-mainBlue font-medium cursor-pointer text-sm sm:text-base hover:underline">
                            +{' '}
                            {t('@click.or.drop.here.to.upload.files', {
                                defaultValue: 'Click or Drag and drop here to upload files',
                            })}
                        </span>
                    </UploadButton>
                }
                emptyText={t('@directory.is.empty', { defaultValue: 'Directory is empty' })}
            />
        </div>
    );
};
