import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../../components/animation';
import {
    FooterButtons,
    HeaderForCreatePages,
    RunScheduleComponent,
    SectionContainerForCreatePages,
} from '../../../../components/common';
import { DetailsLoader } from '../../../../components/details-entity';
import { InputField, TextareaField } from '../../../../components/inputs';
import { runSchedules } from '../../../../constants';
import { BaseInstanceTypeListModel, BaseInstanceTypeListResponseModel, SelectOptionType } from '../../../../models';
import axios from '../../../../services/axios';
import { deleteErrorKey, formatArrayForSelectOptions } from '../../../../utils';
import { useCreatePipelineJobHook } from './hooks';

const formId = 'createNewPipelineJobForm';
type runScheduleDataType = {
    scheduleFrequency: any;
    instanceType: SelectOptionType | null;
};

export const CreatePipelineJobPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const isEditing = !!useParams<{ id: string }>().id;

    const [instanceTypes, setInstanceTypes] = useState<BaseInstanceTypeListModel[]>([]);
    const [instanceTypesLoading, setInstanceTypesLoading] = useState(false);
    const [scheduleSelectedOption, setScheduleSelectedOption] = useState<SelectOptionType | null>(null);
    const [runScheduleData, setRunScheduleData] = useState<runScheduleDataType>({
        scheduleFrequency: '',
        instanceType: null,
    });
    const { errors, setErrors, loading, detailsLoading, formValues, onChangeFormValues, onChangeValues, onSubmit } =
        useCreatePipelineJobHook({
            instanceTypesLoading,
            instanceTypes,
            scheduleSelectedOption,
            setScheduleSelectedOption,
            runScheduleData,
            setRunScheduleData,
        });

    useEffect(() => {
        fetchInstanceTypes();
    }, []);

    const fetchInstanceTypes = async () => {
        try {
            setInstanceTypesLoading(true);
            const response: BaseInstanceTypeListResponseModel = await axios.get('/pipeline/jobInstanceType/list');
            setInstanceTypes(response.models);
        } catch (err) {
        } finally {
            setInstanceTypesLoading(false);
        }
    };

    const onChangeRunScheduleData = (key, value) => {
        deleteErrorKey(key, setErrors);
        setRunScheduleData(prev => ({ ...prev, [key]: value }));
    };

    const onClickCancel = () => history.goBack();

    const instanceTypesOptions = useMemo(
        () => formatArrayForSelectOptions(instanceTypes, 'name', 'id', true),
        [instanceTypes]
    );

    if (detailsLoading) {
        return (
            <PageAnimationContainer>
                <DetailsLoader />
            </PageAnimationContainer>
        );
    }

    return (
        <PageAnimationContainer>
            <div className="flex flex-col">
                <form id={formId} className="flex flex-col items-center" onSubmit={onSubmit}>
                    <HeaderForCreatePages title={t('@common.basic-information')} className="flex flex-col" />
                    <SectionContainerForCreatePages className="flex flex-col">
                        <InputField
                            required
                            label={t('@common.title')}
                            name="taskTitle"
                            placeholder={t('@common.write.here')}
                            onChange={e => onChangeFormValues('title', e.target.value)}
                            value={formValues.title}
                            errorMessage={errors['title']}
                        />
                        <TextareaField
                            label={t('@common.description')}
                            placeholder={t('@common.enter-description-here')}
                            className="mt-4"
                            onChange={e => onChangeFormValues('description', e.target.value)}
                            value={formValues.description}
                        />

                        {formValues.values?.map(value => (
                            <InputField
                                label={value.name}
                                name={value.name}
                                className="mt-4"
                                placeholder={t('@common.write.here')}
                                onChange={e => onChangeValues(value.name, e.target.value)}
                                value={value.value}
                            />
                        ))}
                    </SectionContainerForCreatePages>

                    <RunScheduleComponent
                        instanceTypeProps={{
                            loading: instanceTypesLoading,
                            onChange: value => {
                                onChangeRunScheduleData('instanceType', value);
                                deleteErrorKey('instanceTypeId', setErrors);
                            },
                            options: instanceTypesOptions,
                            value: runScheduleData.instanceType,
                        }}
                        errors={errors}
                        onChangeRunScheduleData={onChangeRunScheduleData}
                        onChangeScheduleSelected={value => {
                            setScheduleSelectedOption(value);
                            deleteErrorKey('scheduleId', setErrors);
                        }}
                        scheduleError={errors?.scheduleId}
                        instanceTypeError={errors?.instanceTypeId}
                        runScheduleData={runScheduleData}
                        scheduleSelectedOption={scheduleSelectedOption}
                        runSchedulesOptions={runSchedules.filter(x => x.label !== 'On every Artifact update')}
                    />

                    <HeaderForCreatePages title="" className="mt-10" hideIcon />
                    <FooterButtons
                        hideSave
                        onClickCancel={onClickCancel}
                        thirdButtonProps={{
                            type: 'submit',
                            form: formId,
                            loading: loading,
                            disabled: loading,
                        }}
                        thirdButtonTitle={t(`@${isEditing ? 'edit' : 'create'}.pipelineJob`)}
                    />
                </form>
            </div>
        </PageAnimationContainer>
    );
};
