import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { MenuLinkArrow } from '.';
import { SidebarTagTypes } from '..';
import { AccountDetailsModel, UserDetailsModel } from '../../../models';
import { cx, getWidth, queryParams } from '../../../utils';
import { Tooltip } from '../../ui';
import { MainSidebarMenu } from './MainSidebarMenu';
export interface ItemProps extends SidebarItemProps {
    nestedList?: SidebarItemProps[];
}

export interface SidebarItemProps extends Omit<NavLinkProps, 'to'> {
    to?: string;
    disable?: boolean;
    icon?: { name: string; size?: number };
    titleKey: string;
    nestedList?: ItemProps[];
    activeLinkIncludes?: string;
    activePath?: string;
    checkIsPathActive?: (location: Location) => boolean;
    isMenuOpen?: boolean;
    isPathDisabled?: () => boolean;
    disabledTooltip?: string;
    toggleMenu?: () => any;
    onLinkClicked?: () => any;
    shouldRenderLink?: (account: AccountDetailsModel, user: UserDetailsModel) => boolean;
    className?: string;
    isListOpen?: boolean;
    onToggleList?: (open: boolean) => any;
    tagType?: SidebarTagTypes;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
    icon,
    titleKey,
    nestedList,
    className,
    activeClassName,
    activeLinkIncludes,
    activePath,
    isMenuOpen,
    checkIsPathActive,
    toggleMenu,
    onLinkClicked,
    isPathDisabled,
    disabledTooltip,
    disable,
    isListOpen,
    onToggleList,
    tagType,
    ...navLinkrest
}) => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const location = useLocation();
    const isLinkActive =
        navLinkrest?.to &&
        (checkIsPathActive ? checkIsPathActive(window.location) : location.pathname.includes(navLinkrest?.to));
    const isLinkDisabled = isPathDisabled ? isPathDisabled() : false;
    const _disable = disable || isLinkDisabled;

    const _onClickNestedList = () => {
        if (!isMenuOpen) {
            toggleMenu && toggleMenu();
            !isListOpen && onToggleList && onToggleList(true);
            return;
        }
        if (onToggleList) onToggleList(!isListOpen);
    };

    const navLinkClassName = `flex items-center px-4 py-3 text-sm  ${_disable && 'opacity-50 cursor-not-allowed'} ${
        isMenuOpen && !_disable && 'transition-all ease-in duration-150 delay-75 transform-gpu hover:translate-x-1.5'
    }`;
    const tooltipMessage = !isMenuOpen ? t(titleKey) : disabledTooltip ? disabledTooltip : '';
    const tooltipFor = 'nestedList:tooltip';
    const isMobile = getWidth() < 900;

    const renderTag = () => {
        return (
            <span
                style={{ fontSize: 9 }}
                className="flex font-medium text-xs flex-none text-center justify-center items-center px-1.5 py-0.5 text-mainBlue bg-mainBlue/20 rounded-full ml-auto"
            >
                {tagType}
            </span>
        );
    };

    if (nestedList)
        return (
            <li className="select-none overflow-hidden" data-tip={tooltipMessage} data-for={tooltipFor}>
                <Tooltip place="right" id={tooltipFor} />
                <div
                    className={`${navLinkClassName} ${!isMenuOpen && 'justify-center'}`}
                    onClick={() => !_disable && _onClickNestedList()}
                    role="button"
                >
                    {icon && (
                        <div
                            className={`w-5 h-5 flex-none ${
                                !isMenuOpen && !isMobile
                                    ? 'w-full flex justify-center mr-0 h-full items-center'
                                    : 'mr-4'
                            }`}
                        >
                            <GjirafaIcon {...icon} size={icon?.size || 20} />
                        </div>
                    )}
                    {(isMenuOpen || isMobile) && (
                        <>
                            {t(titleKey)}
                            <MenuLinkArrow animationIn={!isListOpen} />
                        </>
                    )}
                </div>
                {(isMenuOpen || isMobile) && (
                    <AnimateHeight height={!!isListOpen ? 'auto' : 0} duration={300} delay={50}>
                        <div className={`${(isMenuOpen || isMobile) && 'pl-5'}`}>
                            <MainSidebarMenu list={nestedList} />
                        </div>
                    </AnimateHeight>
                )}
            </li>
        );

    if (navLinkrest.to)
        return (
            <li data-tip={tooltipMessage} data-for={tooltipFor}>
                <Tooltip place="right" id={tooltipFor} />

                <NavLink
                    id={titleKey.split(' ').join('')}
                    {...navLinkrest}
                    exact
                    className={cx([
                        `${navLinkClassName} ${isLinkActive && 'text-mainBlue bg-mainBackground rounded-l-md'} ${
                            !isMenuOpen && !isMobile && 'justify-center p-0'
                        } `,
                        className,
                    ])}
                    to={_disable ? {} : { pathname: navLinkrest.to, search: queryParams.formatForNavigation() }}
                    activeClassName={`text-mainBlue bg-mainBackground rounded-l-md ${activeClassName}`}
                    onClick={e => {
                        if (_disable) {
                            e.preventDefault();
                            return;
                        }
                        onLinkClicked && onLinkClicked();
                    }}
                >
                    {icon ? (
                        <span
                            className={`w-5 h-5 ${
                                !isMenuOpen && !isMobile
                                    ? 'w-full flex justify-center mr-0 h-full items-center'
                                    : `mr-4`
                            }`}
                        >
                            <GjirafaIcon {...icon} size={icon.size || 20} />
                        </span>
                    ) : (
                        !isMenuOpen && !isMobile && t(titleKey)[0]?.toUpperCase()
                    )}
                    {(isMenuOpen || isMobile) && t(titleKey)}
                    {tagType && isMenuOpen && renderTag()}
                </NavLink>
            </li>
        );

    return null;
};
