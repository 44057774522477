import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import { AllHTMLAttributes, CSSProperties, ReactNode } from 'react';

export interface ProfileItemProps extends AllHTMLAttributes<HTMLAnchorElement> {
    iconName?: string;
    title: string;
    className?: string;
    onClick?: (e: any) => any;
    isActive?: boolean;
    activeClassName?: string;
    style?: CSSProperties;
    href?: string;
    rightComponent?: ReactNode;
}

export const ProfileItem: React.FC<ProfileItemProps> = ({
    title,
    children,
    href,
    className,
    iconName,
    onClick,
    activeClassName,
    isActive,
    style,
    rightComponent,
    ...props
}) => {
    return (
        <a
            style={style}
            onClick={e => {
                e.stopPropagation();
                onClick && onClick(e);
            }}
            href={href}
            className={`w-full py-2 cursor-pointer items-center flex justify-between text-greysBlack200 ${className} transition-all duration-300 hover:text-mainBlue hover:bg-grey200 ${
                isActive ? activeClassName : ''
            }`}
            {...props}
        >
            <div className="w-8 h-auto flex justify-center items-center">
                {iconName && <GjirafaIcon name={iconName} size={20} />}
            </div>
            <span className="font-normal text-sm flex-1 flex pl-4">{title}</span>
            {rightComponent}
        </a>
    );
};
