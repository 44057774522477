import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CreateDeploymentModal } from '../../../components/modals';
import { ModelsTable } from '../../../components/tables';
/**
 * Components
 */
import { toast } from '../../../components/ui';
import { DEPLOYMENTS_PATH } from '../../../constants';
import { useAccountId } from '../../../hooks';
import { useToggleVisibility } from '../../../hooks/useToggleVisibility';
import { WorkflowModelListModel } from '../../../models';
import { actions as deploymentInstanceTypeActions } from '../../../redux/thunk/app/deployment/deploymentInstanceTypesThunk';
/**
 * Redux
 */
import { actions as instanceTypeActions } from '../../../redux/thunk/app/model/modelInstanceTypesThunk';
import { actions as workflowModelsActions } from '../../../redux/thunk/app/model/modelsThunk';
import axios from '../../../services/axios';

export const ModelsPage: React.FC = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const location = useLocation();
    const { t } = useTranslation();

    /**
     * Local State
     */
    const [isCreateModalVisible, shouldRenderCreateModal, onToggleCreateModal] = useToggleVisibility({
        initialValue: false,
    });
    const [selectedModelForDeployment, setModelForDeployment]: SetStateAction<any> = useState(null);

    const requestList = (query, resetQuery?: boolean) => {
        dispatch(
            workflowModelsActions.request(
                {
                    accountId,
                    ...query,
                },
                resetQuery
            )
        );
    };

    useEffect(() => {
        return () => {
            dispatch(workflowModelsActions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        requestList({
            page: 0,
            searchQuery: '',
            algorithmId: null,
            notDeployed: false,
        });
        dispatch(deploymentInstanceTypeActions.request());
        dispatch(instanceTypeActions.request());
    }, [accountId]);

    const onClickEdit = row => {
        history.push(`/workflow/models/edit-model/${row.id}${window.location.search}`);
    };

    const onClickRunModel = async (row: WorkflowModelListModel) => {
        try {
            await axios.post('/model/run', { accountId, id: row.id });
            toast.success('Model started running!');
            requestList({});
        } catch (err) {}
    };

    const onClickTerminate = async row => {
        try {
            await axios.post('/model/terminate', { id: row.id, accountId });
            toast.success('Model is terminating!');
            requestList({});
        } catch (err: any) {}
    };

    const onClickDeployModel = row => {
        onToggleCreateModal(true);
        const model = { label: row?.title, value: row?.id.toString() };
        setModelForDeployment(model);
    };

    const onSuccessDeployedModel = () => {
        toast.success(t('@model.success.deployed', { defaultValue: 'Model has been deployed!' }));
        history.push(DEPLOYMENTS_PATH + location.search);
    };

    return (
        <div className="">
            {shouldRenderCreateModal && (
                <CreateDeploymentModal
                    onSuccess={onSuccessDeployedModel}
                    isVisible={isCreateModalVisible}
                    modelDefaultValue={selectedModelForDeployment}
                    onHide={() => {
                        onToggleCreateModal(false);
                        setModelForDeployment(null);
                    }}
                />
            )}
            <ModelsTable
                actions={[
                    {
                        action: 'edit',
                        onClick: onClickEdit,
                    },
                    {
                        action: row => (row?.runStatus === 'Running' ? 'Terminate' : 'run'),
                        onClick: async (row: WorkflowModelListModel) =>
                            row?.runStatus === 'Running' ? onClickTerminate(row) : onClickRunModel(row),
                        displayName: (row: WorkflowModelListModel) =>
                            row?.runStatus === 'Running' ? 'Terminate' : 'Run',
                        disable: (row: WorkflowModelListModel) => row?.runStatus === 'Terminating',
                    },
                    {
                        action: 'deploy',
                        disable: row => !(row?.trained && !row?.deployed),
                        onClick: onClickDeployModel,
                        tooltipMessage: row =>
                            !(row?.trained && !row?.deployed) ? (row?.trained ? 'Already deployed' : 'N/A') : '',
                    },
                    {
                        action: 'delete',
                    },
                ]}
                isFromSelectPopup={false}
                fetchDataOnMount={false}
            />
        </div>
    );
};
