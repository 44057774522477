import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useCurrentWidth } from '../../hooks';
import { actions as themeActions, selectors as themeSelectors } from '../../redux/thunk/theme';
import { sidebarAnimationStyles, sidebarAnimationStylesMobile } from '../../styles/animationStyles';
import { cx } from '../../utils';
import { Icon, IconTypes } from '../common';
import { AdminSidebarMenu } from './components';
import { adminSidebarLinks } from './sidebar-links';

export interface AdminSidebarProps {
    wrapperClassName?: string;
}

export const AdminSidebar: React.FC<AdminSidebarProps> = ({ wrapperClassName }) => {
    const dispatch = useDispatch();
    const isMenuOpened = useSelector(themeSelectors.getIsMenuOpened);
    const width = useCurrentWidth();

    const styleAnimation = useMemo(() => {
        return width < 900 ? sidebarAnimationStylesMobile : sidebarAnimationStyles;
    }, [width]);

    const toggleMenu = () => {
        dispatch(themeActions.toggleMenu());
    };

    return (
        <>
            <CSSTransition in={isMenuOpened} timeout={100} unmountOnExit={false} mountOnEnter={false}>
                {state => (
                    <aside
                        style={{
                            width: 276,
                            transition: 'width 300ms linear',
                            background: 'white',
                            ...styleAnimation[state],
                        }}
                        className={cx([
                            `py-4 ${isMenuOpened && 'pl-3'} bg-white overflow-y-auto h-full`,
                            wrapperClassName,
                        ])}
                    >
                        {width < 900 && isMenuOpened && (
                            <div className="flex w-full justify-end pr-2">
                                <div className="p-1 cursor-pointer" onClick={toggleMenu}>
                                    <Icon name={IconTypes.close} />
                                </div>
                            </div>
                        )}
                        <AdminSidebarMenu list={adminSidebarLinks} />
                    </aside>
                )}
            </CSSTransition>
            {width < 900 && isMenuOpened && (
                <div className="absolute h-screen w-full bg-mainOverlay opacity-30 z-40 top-0" onClick={toggleMenu} />
            )}
        </>
    );
};
