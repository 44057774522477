import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AlertInfoComponent } from '../../../components/alerts';
import { CreateButton } from '../../../components/buttons';
import { Icon, IconTypes } from '../../../components/common';
import { InputField } from '../../../components/inputs';
import { NoDataImagesType, Table, TableFilters, TableMobile } from '../../../components/tables';
import { toast } from '../../../components/ui';
import { breakpoints } from '../../../constants';
import { useAccountId, useCurrentWidth } from '../../../hooks';
import {
    AccountDetailsResponseModel,
    AccountInvoiceListModel,
    AccountInvoiceListResponseModel,
    StripeCustomerDetailsResponseModel,
} from '../../../models';
import { selectors as accountDetailsSelectors } from '../../../redux/thunk/app/account/accountDetailsThunk';
import { actions, selectors } from '../../../redux/thunk/app/account/accountInvoicesThunk';
import axios from '../../../services/axios';
import { showFiltersHeader } from '../../../utils';
import { StripeEmailSchema } from '../../../validations';

export const InvoicesTab = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const currentWidth = useCurrentWidth();

    const response: AccountInvoiceListResponseModel = useSelector(selectors.getResponse);
    const loading = useSelector(selectors.getIsLoading);
    const initialLoading = useSelector(selectors.getInitialLoading);
    const { model: accountDetails }: AccountDetailsResponseModel = useSelector(accountDetailsSelectors.getResponse);

    const { handleSubmit, setValue, control } = useForm({
        resolver: yupResolver(StripeEmailSchema),
        reValidateMode: 'onChange',
        shouldUnregister: false,
        mode: 'onBlur',
    });
    /**
     * Local State
     */
    const [submitEmailLoading, setSubmitEmailLoading] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(actions.removeNotificationQuery());
        };
    }, []);

    useEffect(() => {
        if (accountId) {
            requestList({});
        }
    }, [accountId]);

    useEffect(() => {
        if (accountDetails && accountDetails.stripeCustomerId) {
            fetchStripeCustomerEmail();
        }
    }, [accountDetails]);

    const fetchStripeCustomerEmail = async () => {
        try {
            let response: StripeCustomerDetailsResponseModel = await axios.get(
                `/stripe/customer?accountId=${accountId}`
            );
            setValue('email', response.model || '');
        } catch (err) {}
    };
    const requestList = (query, resetQuery?: boolean) => {
        dispatch(actions.request({ accountId, ...query }, resetQuery));
    };

    const onPageChanged = page => {
        requestList({ page });
    };

    const onSubmitEmail = async data => {
        try {
            setSubmitEmailLoading(true);
            await axios.patch(`/stripe/customer?accountId=${accountId}&email=${data?.email}`);
            toast.success('Email has been changed!');
        } catch (err) {
        } finally {
            setSubmitEmailLoading(false);
        }
    };

    const emptyDataProps = useMemo(
        () => ({
            imageType: NoDataImagesType.Invoice,
            onClickAction: () => {},
            actionMessage: t('table.create.invoice'),
            emptyText: t('table.no.invoice'),
        }),
        []
    );
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage,
            totalItems: response.totalItems,
            totalPages: response.totalPages,
            pageItems: response.models.length,
            onChangePage: onPageChanged,
        }),
        [response]
    );
    const commonTableProps = {
        selectableRows: false,
        showHeaderButtons: false,
        response,
        loading,
        emptyDataProps,
        isFromSelectPopup: false,
        requestList,
        modelEndpoint: 'accountInvoice',
        columns: [
            {
                name: t('invoice.date'),
                selector: row => row?.invoiceDate ?? '',
                sortable: true,
                cell: (row: AccountInvoiceListModel) => <span>{format(new Date(row.invoiceDate), 'd MMM, yyyy')}</span>,
            },
            {
                name: t('@common.description'),
                selector: row => row?.description ?? '',
                cell: (row: AccountInvoiceListModel) => <span className="w-full">{row.description}</span>,
            },
            {
                name: t('status'),
                selector: row => row?.isPaid ?? '',
                sortable: true,
                cell: (row: AccountInvoiceListModel) => (
                    <span className={`font-medium ${row.isPaid ? 'text-greenAccent' : 'text-delete'}`}>
                        {t(row.isPaid ? 'paid' : 'not.paid')}
                    </span>
                ),
            },
            {
                name: t('amount'),
                selector: row => row?.amount ?? '',
                sortable: true,
                cell: (row: AccountInvoiceListModel) => <span className={`font-medium`}>{row.amount} EUR</span>,
            },
            {
                name: t('download'),
                selector: row => row?.url ?? '',
                cell: (row: AccountInvoiceListModel) => (
                    <a
                        target="_blank"
                        href={row.url}
                        className={`flex items-start ${
                            row.url ? 'cursor-pointer' : 'pointer-events-none cursor-default opacity-30'
                        }`}
                    >
                        <Icon name={IconTypes.download} size={20} className="mr-2" /> PDF
                    </a>
                ),
            },
        ],
    };

    return (
        <div className="">
            <AlertInfoComponent text={t('billing.info.message')} />
            {(!!(accountDetails && accountDetails.stripeCustomerId) && (
                <div className="w-full flex flex-col p-4 border border-mainBorder rounded mt-4">
                    <span className="text-base font-normal text-blueMainText">{t('stripe.customer.email')}</span>
                    <span className="mt-1.5 sm:mt-2 text-xs font-medium text-secondaryText">
                        {t('stripe.customer.email.description')}
                    </span>
                    <form
                        id="stripeCustomerEmailForm"
                        onSubmit={handleSubmit(onSubmitEmail)}
                        className="flex w-full mt-4 justify-between items-start"
                    >
                        <Controller
                            name="email"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputField
                                    hideLabel
                                    placeholder={t('@common.write.here')}
                                    errorMessage={error?.message}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                />
                            )}
                        />

                        <CreateButton
                            type="submit"
                            form="stripeCustomerEmailForm"
                            loading={submitEmailLoading}
                            title={t('save.email.address')}
                            className="ml-3 px-4 flex-none"
                        />
                    </form>
                </div>
            )) ||
                null}
            {currentWidth > breakpoints.sm && (
                <Table
                    {...commonTableProps}
                    pagination
                    subHeader={showFiltersHeader(response)}
                    style={{ boxShadow: 'none' }}
                    className="w-full border border-mainBorder rounded"
                    tableContainerClassName="mt-4"
                    headerComponent={
                        <TableFilters
                            onChangeQuery={(key, value) => {
                                dispatch(
                                    actions.request({
                                        [key]: value,
                                    })
                                );
                            }}
                            response={response}
                            onSearch={searchQuery => {
                                dispatch(
                                    actions.request({
                                        searchQuery,
                                        page: 0,
                                    })
                                );
                            }}
                        >
                            <span className="h-full text-blueMainText font-normal m-2 text-base flex flex-1 mb-10">
                                {t('billing.history')}
                            </span>
                        </TableFilters>
                    }
                    paginationProps={paginationProps}
                />
            )}
            {currentWidth <= breakpoints.sm && (
                <TableMobile
                    {...commonTableProps}
                    className="mt-2"
                    initialLoading={initialLoading}
                    mainKeyProperty="title"
                    paginationProps={{
                        ...paginationProps,
                        hasNextPage: !!response.hasNextPage,
                    }}
                />
            )}
        </div>
    );
};
