import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField, InputFieldProps } from '../inputs';
import axios from '../../services/axios';
import { Loader } from '../ui';

interface IAliasInputProps {
    value: string;
    onChange: InputFieldProps['onChange'];
    endpoint: string;
    className?: string;
    errorMessage?: string;
    isEditing: boolean;
}

export const AliasInput = ({ onChange, value, className, endpoint, isEditing, errorMessage }: IAliasInputProps) => {
    const { t } = useTranslation();
    const timeoutId: any = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const onValidateAlias = async (_value: string) => {
        try {
            setError('');
            setLoading(true);
            await axios.get(`${endpoint}/validateAlias?alias=${_value}`);
        } catch (err: any) {
            setError(err?.response?.data?.message ?? 'Alias is not valid');
        } finally {
            setLoading(false);
        }
    };

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (isEditing) return;

        onChange?.(e);
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => onValidateAlias(e.target.value), 500);
    };

    return (
        <div className="flex w-full relative">
            <InputField
                className={className}
                name="alias"
                value={value}
                label={t('@alias', { defaultValue: 'Alias' })}
                placeholder={t('@common.write.here')}
                required
                disabled={isEditing}
                onChange={_onChange}
                errorMessage={error || errorMessage}
            />
            {loading && <Loader className="absolute  top-[52px] right-2" size={22} />}
        </div>
    );
};
