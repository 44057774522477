import { CSSProperties } from 'react';

export interface CardProps {
    style?: CSSProperties;
    className?: string;
}

export const Card: React.FC<CardProps> = ({ children, style, className }) => {
    return (
        <div
            className={`base-card-style card-shadow max-w-full ${className}`}
            style={{ minWidth: 835, height: 'auto', ...style }}
        >
            {children}
        </div>
    );
};
