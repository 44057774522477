import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/cards';
import { SelectWithPopupField, SelectWithPopupTypes } from '../../../../components/inputs';
import { tabularQueryArtifacts } from '../../../../constants';
import { useCurrentWidth } from '../../../../hooks';

export interface AlgorithmCardProps {
    onChangeAlgorithm: (e: any) => any;
    algorithmSelected?: object | any;
    artifactSelected?: object | any;
    onChangeArtifact: (e: any) => any;
    errors?: any;
    isEditing?: boolean;
    childrenContainerStyle?: CSSProperties;
}

const AlgorithmCard: React.FC<AlgorithmCardProps> = ({
    onChangeAlgorithm,
    algorithmSelected,
    artifactSelected,
    children,
    onChangeArtifact,
    errors,
    isEditing,
    childrenContainerStyle,
}) => {
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();

    return (
        <Card
            style={{
                minWidth: currentWidth > 1120 ? 835 : '100%',
                width: currentWidth > 1120 ? 835 : '100%',
            }}
        >
            <div className="h-auto p-4 sm:p-6 border-mainBackground border-b w-full">
                <SelectWithPopupField
                    required
                    disabled={isEditing}
                    tableType={SelectWithPopupTypes.algorithm}
                    label={t('@common.algorithm')}
                    placeholder={t('@common.choose-algorithm')}
                    selectedItem={algorithmSelected}
                    onChangeItem={onChangeAlgorithm}
                    errorMessage={errors?.algorithm}
                />

                {algorithmSelected && algorithmSelected?.hasArtifact && (
                    <SelectWithPopupField
                        disableFilters
                        required
                        fetchDataOnMount
                        className="mt-6"
                        query={{ ...tabularQueryArtifacts }}
                        label={t('@common.artifact')}
                        placeholder={t('@common.choose-artifact')}
                        onChangeItem={onChangeArtifact}
                        selectedItem={artifactSelected}
                        errorMessage={errors?.artifact}
                        tableType={SelectWithPopupTypes.artifact}
                        disabled={isEditing}
                        selectedId={artifactSelected}
                    />
                )}
            </div>
            <div className="p-4 sm:p-6 h-auto w-full" style={childrenContainerStyle}>
                {children}
            </div>
        </Card>
    );
};

export default AlgorithmCard;
