import GjirafaIcon from '@gjirafatech/gjirafa-icons/Icon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '../../../../../hooks';

export const multiValueSplitChar = '§';

interface MultiValueInputComponentProps {
    values: string;
    onChangeValues: (newValues: string) => any;
    className?: string;
}

export const MultiValueInputComponent: React.FC<MultiValueInputComponentProps> = ({
    values,
    onChangeValues,
    className,
}) => {
    const { t } = useTranslation();
    const wrapperRef = useRef(null);
    const valuesArray = useMemo(() => (values.length > 0 ? values.split(multiValueSplitChar) : []), [values]);

    const { setValue, setFocus, register, getValues } = useForm({});
    useOutsideClick(wrapperRef, onClickOutside);

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isClickedOutside, setIsClickedOutside] = useState(false);

    useEffect(() => {
        if (isClickedOutside) {
            let value = getValues('value');
            if (typeof value === 'string') {
                setIsFocused(false);
                onAddNewValue(value);
            }
            setIsClickedOutside(false);
        }
    }, [isClickedOutside]);

    function onClickOutside() {
        setIsClickedOutside(true);
    }

    const onRemoveValue = (value: string) => {
        onChangeValues(
            valuesArray
                .filter(x => x !== value)
                .join(multiValueSplitChar)
                .toString()
        );
    };

    const onRemoveLastValue = () => {
        onChangeValues(
            valuesArray
                .slice(0, valuesArray.length - 1)
                .join(multiValueSplitChar)
                .toString()
        );
    };

    const onAddNewValue = (value: string) => {
        let _value = value.trim();
        setValue('value', '');

        if (_value.length && !valuesArray.includes(_value)) {
            onChangeValues([...valuesArray, _value].join(multiValueSplitChar).toString());
        }
    };

    const onKeyPress = event => {
        let code = event.keyCode || event.which;
        code === 13 && onAddNewValue(getValues('value'));
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let code = event['keyCode'] || event['which'];

        if (code === 8) {
            let value = getValues('value');
            if (!value.length && values.length) {
                onRemoveLastValue();
            }
        }
    };

    return (
        <div className="flex flex-col w-full">
            <div
                ref={wrapperRef}
                onClick={() => {
                    setIsFocused(true);
                    setFocus('value');
                }}
                style={{ minHeight: 40, height: 'auto' }}
                className={`border flex w-full flex-wrap px-2 bg-white cursor-pointer items-center rounded transition-all hover:border-blueDark300 duration-300 ease-in  ${
                    isFocused ? 'ring-1 ring-primaryBlue500' : 'border-grey200'
                } ${className}`}
            >
                {valuesArray.map(value => (
                    <div className="m-1 px-1 items-center rounded-lg flex w-auto text-xs border border-grey200">
                        {value}
                        <div className="cursor-pointer" onClick={() => onRemoveValue(value)}>
                            <GjirafaIcon size={14} name="Close" />
                        </div>
                    </div>
                ))}

                <input
                    autoComplete="off"
                    {...register('value')}
                    style={{ width: 85 }}
                    className="flex text-xs text-greyBlack300 m-1 outline-none bg-white"
                    placeholder={t('@value', { defaultValue: 'Value' })}
                    form="addValueId"
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                />
            </div>
            <span className="text-secondaryText text-xs mt-0.5">
                {t('@filters.enter.value.by.enter', {
                    defaultValue: '* Enter tags by pressing enter or by clicking outside of the input',
                })}
            </span>
        </div>
    );
};
