import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DataSourceTable } from '../../../components/tables';
import { useAccountId } from '../../../hooks';
/**
 * Redux
 */
import { actions as dataSourcesActions } from '../../../redux/thunk/app/dataSource/dataSourcesThunk';

export const DataSourcesPage: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch();
    const accountId = useAccountId();

    useEffect(() => {
        dispatch(dataSourcesActions.request({ accountId, page: 0, searchQuery: '', dataSourceTypeId: null }));
    }, [accountId]);

    useEffect(() => {
        return () => {
            dispatch(dataSourcesActions.removeNotificationQuery());
        };
    }, []);

    return (
        <div className="">
            <DataSourceTable fetchDataOnMount={false} isFromSelectPopup={false} />
        </div>
    );
};
