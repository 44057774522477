import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAccountIntegrationModal } from '../../../components/modals';
import { NoDataImagesType, Table, TableFilters, TableMobile } from '../../../components/tables';
import { toast } from '../../../components/ui';
import { breakpoints } from '../../../constants';
import { useAccountId, useCurrentWidth, useToggleVisibility } from '../../../hooks';
import { IntegrationDetailsModel, IntegrationListModel, IntegrationListResponseModel } from '../../../models';
import { actions, selectors } from '../../../redux/thunk/app/account/accountIntegrationsThunk';
import { actions as biskoPropertiesActions } from '../../../redux/thunk/bisko/biskoPropertiesThunk';
import { actions as biskoSegmentsActions } from '../../../redux/thunk/bisko/biskoSegmentsThunk';
import { showFiltersHeader } from '../../../utils';
import { DisconnectIntegrationComponent } from '../components';

export const AccountIntegrationsTab = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const currentWidth = useCurrentWidth();
    const [isVisible, shouldRender, onToggleVisibility] = useToggleVisibility();

    /**
     * Selectors
     */
    const response: IntegrationListResponseModel = useSelector(selectors.getResponse);
    const loading: boolean = useSelector(selectors.getIsLoading);
    const initialLoading: boolean = useSelector(selectors.getInitialLoading);

    const requestList = (query: any, resetQuery?: boolean) =>
        dispatch(actions.request({ accountId, ...query }, resetQuery));

    useEffect(() => {
        requestList({ accountId, searchQuery: '' });
    }, [accountId]);

    const onClickCreate = () => onToggleVisibility(true);

    const onPageChanged = (page: number) => requestList({ page });

    const onRefresh = () => requestList({});

    const requestBiskoData = () => {
        dispatch(biskoPropertiesActions.request());
        dispatch(biskoSegmentsActions.request());
    };

    const onSuccessCreated = (integration: IntegrationDetailsModel) => {
        onToggleVisibility(false);
        onRefresh();
        toast.success(
            t('@toast-messages.success-create-integration', {
                defaultValue: 'Integration has been added successfully!',
            })
        );

        if (integration.integrationType.code?.toLocaleLowerCase().includes('bisko')) {
            requestBiskoData();
        }
    };

    const onSuccessDisconnect = (row: IntegrationListModel) => {
        onRefresh();
        if (row.integrationType.code?.toLocaleLowerCase().includes('bisko')) {
            requestBiskoData();
        }
    };

    const commonTableProps = {
        requestList,
        showHeaderButtons: true,
        headerButtonProps: {
            onRefresh,
            onClickCreate,
            createTitle: t('@add.new.integration'),
            showDelete: false,
        },
        emptyDataProps: {
            imageType: NoDataImagesType.AccountIntegration,
            emptyText: t('@no.accountIntegrations', { defaultValue: 'No integrations yet...' }),
            actionMessage: '+ ' + t('@create.new.accountIntegration', { defaultValue: 'Add new Integration' }),
            onClickAction: onClickCreate,
        },
        modelEndpoint: 'accountIntegration',
        isFromSelectPopup: false,
        pagination: true,
        loading,
        response,
    };

    const paginationProps = {
        currentPage: response.currentPage || 0,
        pageItems: response.models.length,
        totalPages: response.totalPages,
        totalItems: response.totalItems,
        onChangePage: onPageChanged,
    };

    return (
        <>
            {shouldRender && (
                <CreateAccountIntegrationModal
                    onSuccess={onSuccessCreated}
                    isVisible={isVisible}
                    onHide={() => onToggleVisibility(false)}
                />
            )}
            <div className="flex w-full flex-col">
                {currentWidth > breakpoints.sm && (
                    <Table
                        {...commonTableProps}
                        subHeader={showFiltersHeader(response)}
                        data={response.models}
                        headerComponent={
                            <TableFilters
                                onSearch={searchQuery => requestList({ searchQuery, page: 0 })}
                                response={response}
                            />
                        }
                        columns={[
                            {
                                selector: (row: IntegrationListModel) => row.integrationType?.name,
                                cell: (row: IntegrationListModel) => (
                                    <div className="flex w-full items-center py-1">
                                        <img
                                            className="object-contain h-10 w-10 rounded  border-mainBorder"
                                            src={row.integrationType?.imageUrl}
                                        />

                                        <span className="ml-4">{row.integrationType?.name}</span>
                                    </div>
                                ),
                                name: 'Integration Type',
                            },
                            {
                                name: t('@common.name'),
                                selector: (row: IntegrationListModel) => row.name,
                            },
                            {
                                name: t('@common.last-modified'),
                                width: '180px',
                                selector: row => row?.lastModified ?? '',
                                cell: row => <span>{row?.lastModified}</span>,
                                sortable: true,
                            },
                            {
                                name: t('@common.modified-by'),
                                selector: row => row?.modifiedBy ?? '',
                                width: '140px',
                                cell: row => <span>{row?.modifiedBy}</span>,
                            },
                            {
                                selector: (row: IntegrationListModel) => row.id,
                                name: '',
                                width: '180px',
                                cell: (row: IntegrationListModel) => (
                                    <DisconnectIntegrationComponent
                                        row={row}
                                        onSuccess={() => onSuccessDisconnect(row)}
                                    />
                                ),
                            },
                        ]}
                        paginationProps={paginationProps}
                    />
                )}
                {currentWidth <= breakpoints.sm && (
                    <TableMobile
                        {...commonTableProps}
                        mainKeyProperty="type"
                        initialLoading={initialLoading}
                        columns={[
                            {
                                selector: (row: IntegrationListModel) => row.integrationType?.name,
                                cell: (row: IntegrationListModel) => (
                                    <div className="flex w-full items-center py-1">
                                        <img
                                            style={{ height: 40, width: 40 }}
                                            className="object-contain"
                                            src={row.integrationType?.imageUrl}
                                        />

                                        <span className="ml-2">{row.integrationType?.name}</span>
                                    </div>
                                ),
                                name: 'Integration Type',
                            },
                            {
                                selector: (row: IntegrationListModel) => row?.lastModified ?? '',
                                name: t('@common.last-modified'),
                                sortable: true,
                            },
                            {
                                selector: (row: IntegrationListModel) => row?.modifiedBy ?? '',
                                name: t('@common.modified-by'),
                            },
                            {
                                selector: (row: IntegrationListModel) => row.id,
                                name: '',
                                width: '180px',
                                cell: (row: IntegrationListModel) => (
                                    <DisconnectIntegrationComponent row={row} onSuccess={() => onRefresh()} />
                                ),
                            },
                        ]}
                        paginationProps={{
                            ...paginationProps,
                            hasNextPage: !!response.hasNextPage,
                        }}
                    />
                )}
            </div>
        </>
    );
};
