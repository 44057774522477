import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveCardComponent } from '.';
import { ToggleInput } from '../../../../components/buttons';
import { ErrorTextComponent } from '../../../../components/messages';

export const frequencyTypes = {
    soft: 1,
    aggressive: 2,
};

export const frequencyImages = {
    [frequencyTypes.soft]: 'https://jm9wr4wcpo.gjirafa.net/tru-ai-production/images/soft.svg',
    [frequencyTypes.aggressive]: 'https://jm9wr4wcpo.gjirafa.net/tru-ai-production/images/agressive.svg',
};

interface RenderFrequencyComponentProps {
    frequencySelected: number;
    onChangeFrequencySelected: (frequency: number) => any;
    className?: string;
    errorMessage?: string;
}
export const RenderFrequencyComponent: React.FC<RenderFrequencyComponentProps> = ({
    frequencySelected,
    onChangeFrequencySelected,
    className,
    errorMessage,
}) => {
    const { t } = useTranslation();
    const isToggled = useMemo(() => frequencySelected > 0, [frequencySelected]);

    const { isAggressiveSelected, isSoftSelected } = useMemo(
        () => ({
            isSoftSelected: frequencySelected === frequencyTypes.soft,
            isAggressiveSelected: frequencySelected === frequencyTypes.aggressive,
        }),
        [frequencySelected]
    );

    return (
        <div className={`flex w-full flex-col ${className}`}>
            <div className="flex w-full items-center">
                <ToggleInput onToggle={() => onChangeFrequencySelected(isToggled ? 0 : 1)} isToggled={isToggled} />
                <span className="ml-2 text-blueMainText text-sm">
                    {t('@recommendation.frequency/cycling', { defaultValue: 'Frequency/Cycling' })}
                </span>
            </div>
            <div className="mt-4 w-full flex flex-col items-center sm:grid sm:grid-flow-col sm:grid-rows-1 sm:gap-4">
                <ObjectiveCardComponent
                    imageUrl={frequencyImages[frequencyTypes.soft]}
                    isSelected={isSoftSelected}
                    onClickSelect={() => !isSoftSelected && isToggled && onChangeFrequencySelected(frequencyTypes.soft)}
                    titleKey={t('@recommendation.soft.title', { defaultValue: 'Soft' })}
                    descriptionKey={t('@recommendation.soft.description', {
                        defaultValue: 'Augue turpis pharetra a, amet, id porta vel velit.',
                    })}
                    className="mb-4 sm:mb-0"
                    disabled={!isToggled}
                />
                <ObjectiveCardComponent
                    imageUrl={frequencyImages[frequencyTypes.aggressive]}
                    isSelected={isAggressiveSelected}
                    onClickSelect={() =>
                        !isAggressiveSelected && isToggled && onChangeFrequencySelected(frequencyTypes.aggressive)
                    }
                    titleKey={t('@recommendation.aggressive.title', { defaultValue: 'Aggressive' })}
                    descriptionKey={t('@recommendation.aggressive.description', {
                        defaultValue: 'Augue turpis pharetra a, amet, id porta vel velit.',
                    })}
                    disabled={!isToggled}
                />
            </div>
            <ErrorTextComponent message={errorMessage} />
        </div>
    );
};
