import React, { CSSProperties } from 'react';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

interface AlertInfoComponentProps {
    className?: string;
    style?: CSSProperties;
    textStyle?: CSSProperties;
    textClassName?: string;
    text: string;
}
export const AlertInfoComponent: React.FC<AlertInfoComponentProps> = ({
    className,
    style,
    textClassName,
    textStyle,
    text,
}) => {
    return (
        <div
            className={`w-full flex bg-oldBlue4  rounded h-14 py-4 items-center  px-4  ${className}`}
            style={{ border: 'none', minHeight: 56, height: 'auto', ...style }}
        >
            <span
                className="h-6 w-6 flex justify-center items-center mr-0.5 text-mainBlue"
                style={{ color: '#1D79F2' }}
            >
                <InfoIcon
                    style={{
                        stroke: '#1D79F2',
                        strokeWidth: 0.5,
                    }}
                />
            </span>
            <span className={`ml-1.5 font-medium text-xs text-blueMainText ${textClassName}`} style={{ ...textStyle }}>
                {text}
            </span>
        </div>
    );
};
