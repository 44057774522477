import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Icon, IconTypes } from '../common';
import { Tooltip } from '../ui';

export interface UploadingRowProps {
    title: string;
    progress: number;
    errorMessage: string;
    id: string;
    onCancelRequest: () => void;
}

export const UploadingRow: React.FC<UploadingRowProps> = ({
    title,
    progress,
    errorMessage = 'test',
    id,
    onCancelRequest,
}) => {
    return (
        <div className="flex w-full px-2 py-2 items-center justify-between">
            <div
                onClick={onCancelRequest}
                className="justify-center cursor-pointer items-center flex h-8 w-8 rounded-full transition-all duration-500 bg-white hover:bg-grey300"
            >
                <Icon name={IconTypes.close} size={20} />
            </div>
            <span
                className={`flex-1 pl-1 items-center justify-start m-auto font-normal text-sm truncate  transition-all duration-300 ease-in  ${
                    errorMessage ? 'text-mainError transition-all' : 'text-blueMainText transition-all'
                }`}
            >
                {title}
            </span>
            <div className="w-12 h-12 ml-2 flex justify-center items-center">
                {!!errorMessage ? (
                    <div
                        className="cursor-pointer w-full flex justify-center items-center"
                        data-tip={errorMessage}
                        data-for={id}
                    >
                        <Icon name={IconTypes.Error} size={30} />
                        <Tooltip place="top" id={id} />
                    </div>
                ) : (
                    <CircularProgressbar
                        strokeWidth={10}
                        backgroundPadding={2}
                        text={`${progress}%`}
                        value={progress}
                        minValue={0}
                        maxValue={100}
                    />
                )}
            </div>
        </div>
    );
};
