import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CopyTextIcon, StatusComponent } from '../../../../components/common';
import { NoDataImagesType, Table, TableFilters } from '../../../../components/tables';
import { toast } from '../../../../components/ui';
import { CREATE_TASK_PATH, TASKS_PATH } from '../../../../constants';
import { useAccountId } from '../../../../hooks';
import { CustomEndpointListResponseModel, TaskListModel, UserDetailsResponseModel } from '../../../../models';
import { actions, selectors } from '../../../../redux/thunk/app/task/tasksThunk';
import { selectors as userSelectors } from '../../../../redux/thunk/app/user/userThunk';
import axios from '../../../../services/axios';
import { queryParams, showFiltersHeader } from '../../../../utils';

export const TasksPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const history = useHistory();

    const loading = useSelector(selectors.getIsLoading);
    const response: CustomEndpointListResponseModel = useSelector(selectors.getResponse);
    const {
        model: { isAdmin },
    }: UserDetailsResponseModel = useSelector(userSelectors.getResponse);

    useEffect(() => {
        requestList({ searchQuery: '' });
    }, [accountId]);

    const requestList = (query: any, resetQuery?: boolean) =>
        dispatch(actions.request({ accountId, ...query }, resetQuery));

    const onRefresh = () => requestList({});

    const onRowClicked = (row: TaskListModel) =>
        history.push(`${TASKS_PATH}/details/${row.id}${queryParams.formatForNavigation()}`);

    const onClickEdit = (row: TaskListModel) =>
        history.push(`${TASKS_PATH}/edit-task/${row.id}${queryParams.formatForNavigation()}`);

    const onClickCreate = () => history.push(CREATE_TASK_PATH + queryParams.formatForNavigation());

    const onRebuild = async (row: TaskListModel) => {
        try {
            const payload = {
                accountId,
                id: row.id,
            };
            await axios.post(`/pipeline/task/rebuild`, payload);
            toast.success('Task has been Rebuilded!');
        } catch (err) {}
    };

    const onPageChanged = page => dispatch(actions.request({ page }));
    
    const paginationProps = useMemo(
        () => ({
            currentPage: response.currentPage || 0,
            pageItems: response.models.length,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            onChangePage: onPageChanged,
        }),
        [response]
    );

    return (
        <div className="w-full">
            <Table
                highlightOnHover
                selectableRows
                showHeaderButtons
                onRowClicked={onRowClicked}
                modelEndpoint="pipeline/task"
                requestList={requestList}
                isFromSelectPopup={false}
                emptyDataProps={{
                    emptyText: t('@pages.no-tasks'),
                    actionMessage: `+ ${t('@pages.create-new-task')}`,
                    imageType: NoDataImagesType.Task,
                    onClickAction: onClickCreate,
                }}
                loading={loading}
                response={response}
                headerButtonProps={{
                    onRefresh,
                    onClickCreate,
                    createTitle: t('@pages.create-new-task', { defaultValue: 'Create new Task' }),
                }}
                pagination
                paginationProps={paginationProps}
                subHeader={showFiltersHeader(response)}
                headerComponent={
                    <TableFilters
                        onChangeQuery={(key, value) => {
                            requestList({
                                [key]: value,
                            });
                        }}
                        response={response}
                        onSearch={searchQuery => requestList({ searchQuery, page: 0 })}
                    />
                }
                data={response.models}
                shouldHideActions={(row: TaskListModel) => !isAdmin && !row.accountId}
                actions={[
                    {
                        action: 'details',
                        displayName: t('@view.details', { defaultValue: 'View details' }),
                        onClick: onRowClicked,
                    },
                    {
                        action: 'edit',
                        onClick: onClickEdit,
                    },
                    {
                        action: 'deploy',
                        displayName: 'Rebuild',
                        onClick: onRebuild,
                    },
                    {
                        action: 'delete',
                    },
                ]}
                columns={[
                    {
                        name: t('@common.title'),
                        selector: (row: TaskListModel) => row.title,
                    },
                    {
                        name: 'Alias',
                        selector: (row: TaskListModel) => row.alias,
                        cell: (row: TaskListModel) => (
                            <div className="flex w-full items-center group">
                                {row.alias}
                                <CopyTextIcon
                                    textToCopy={row.alias}
                                    tooltipId="copyTaskAlias"
                                    tooltipContent={t('@copy.alias', { defaultValue: 'Copy alias' })}
                                    className="ml-2"
                                />
                            </div>
                        ),
                    },
                    {
                        width: '120px',
                        name: t('@last.build.status', { defaultValue: 'Last build status' }),
                        selector: (row: TaskListModel) => row.lastVersion?.buildStatus ?? '',
                        cell: (row: TaskListModel) => (
                            <StatusComponent
                                style={{ width: '100%' }}
                                className="justify-center"
                                message={row.lastVersion?.buildStatusMessage}
                                status={row.lastVersion?.buildStatus}
                            />
                        ),
                    },
                    {
                        name: t('@common.last-modified'),
                        width: '180px',
                        selector: row => row?.lastModified ?? '',
                        cell: row => <span>{row?.lastModified}</span>,
                        sortable: true,
                    },
                    {
                        name: t('@common.modified-by'),
                        selector: row => row?.modifiedBy ?? '',
                        width: '140px',
                        cell: row => <span>{row?.modifiedBy}</span>,
                    },
                ]}
            />
        </div>
    );
};
