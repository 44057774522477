import React, { CSSProperties, ReactNode } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { ErrorTextComponent } from '../messages';
import { useTranslation } from 'react-i18next';
import { inputAnimationClassName } from '../../constants';

export interface DatePickerInputProps extends Omit<ReactDatePickerProps, 'onChange'> {
    errorMessage?: string;
    errorStyle?: CSSProperties;
    errorClassName?: string;
    label?: string;
    name?: string;
    className?: string;
    style?: CSSProperties;
    inputStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    labelClassName?: string;
    hideLabel?: boolean;
    labelRight?: ReactNode;
    required?: boolean;
    placeholder?: string;
    onChange: (date: string) => any;
    isClearable?: boolean;
}

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
    className,
    style,
    errorClassName,
    errorMessage,
    errorStyle,
    inputStyle,
    label,
    labelClassName,
    labelStyle,
    name,
    hideLabel,
    labelRight,
    required,
    placeholder,
    value,
    isClearable,
    onChange,
    ...restProps
}) => {
    const { t } = useTranslation();

    const borderTransitionClassName = `transition-all duration-300 ease-in  ${
        errorMessage ? 'border-mainError transition-all' : 'border-grey200 transition-all'
    }`;

    return (
        <div className={`w-full flex flex-col ${className}`} style={style}>
            {!hideLabel && (
                <div
                    style={{
                        minHeight: 24,
                    }}
                    className={`flex mb-1 flex-row justify-between items-center text-left ${labelClassName}`}
                >
                    <label className="text-xs sm:text-sm text-secondaryText font-normal">
                        {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                    </label>
                    {labelRight && labelRight}
                </div>
            )}
            <DatePicker
                className={`w-full  flex border text-blueMainText rounded h-8 sm:h-10 box-border py-2 px-3 placeholder-mainPlaceholder font-normal text-xs sm:text-sm ${borderTransitionClassName} ${inputAnimationClassName} ${
                    restProps.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                } `}
                selected={value ? new Date(value) : null}
                calendarClassName="z-50 border"
                timeInputLabel="Time:"
                dateFormat={restProps.dateFormat || `dd/MM/yyyy ${restProps.showTimeInput ? 'HH:mm' : ''}`}
                shouldCloseOnSelect={false}
                fixedHeight
                showTimeInput
                isClearable={isClearable || !required}
                placeholderText={placeholder}
                onChange={date => onChange(date ? new Date(date.toString()).toISOString() : '')}
                clearButtonClassName="mr-1.5"
                clearButtonTitle={t('clear.date')}
                {...restProps}
            />
            <ErrorTextComponent message={errorMessage} />
        </div>
    );
};
