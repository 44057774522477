import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { StateErrorType } from '../../models';
import { AlertErrorComponent } from '../alerts';

interface IBiskoErrorAlertProps {
    error: StateErrorType;
    className?: string;
}

const getErrorMessage = (t: TFunction<'translation', undefined>) => ({
    404: t('@error.missing.bisko.integration', {
        defaultValue: 'Bisko integration is missing for this account!',
    }),
    403: t('@error.bisko.user.access', {
        defaultValue: `Bisko Error: You don't have access to bisko organization!`,
    }),
});

export const Bisko403ErrorAlert = ({ error, className }: IBiskoErrorAlertProps) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const _showAlert = error ? error.status === 403 || error.status === 404 : false;
        setShowAlert(_showAlert);
    }, [error]);

    return (
        <>
            <AlertErrorComponent
                show={showAlert}
                animationType="height"
                onClose={() => setShowAlert(false)}
                className={className}
                text={getErrorMessage(t)[error?.status ?? -1]}
            />
        </>
    );
};
