import React, { useCallback, useState } from 'react';

export interface UseToggleVisibilityOptions {
    initialValue?: boolean;
    durationShow?: number;
    durationHide?: number;
}

type TUseToggleVisibility = (options?: UseToggleVisibilityOptions) => [boolean, boolean, (visible: boolean) => void];

export const useToggleVisibility: TUseToggleVisibility = options => {
    const durationShow = options?.durationShow ?? 0;
    const durationHide = options?.durationHide ?? 350;
    const initialValue = options?.initialValue ?? false;
    /**
     * Local state
     */
    const [isVisible, setIsVisible] = useState(initialValue);
    const [shouldRender, setShouldRender] = useState(initialValue);

    /**
     * refs
     */
    let timeoutHide: any = React.useRef().current;
    let timeoutShow: any = React.useRef().current;

    const onToggleVisibility = useCallback((visible: boolean) => {
        visible ? onShow() : onHide();
    }, []);

    const onShow = () => {
        clearTimeout(timeoutHide);
        setShouldRender(true);
        timeoutShow = setTimeout(() => {
            setIsVisible(true);
        }, durationShow);
    };

    const onHide = () => {
        clearTimeout(timeoutShow);
        setIsVisible(false);
        timeoutHide = setTimeout(() => {
            setShouldRender(false);
        }, durationHide);
    };

    return [isVisible, shouldRender, onToggleVisibility];
};
