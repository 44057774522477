import { AnimationComponent } from '../components/animation';
import { Button } from '../components/buttons';
import { Icon, IconTypes } from '../components/common';

export const NotAuthorized = () => {
    return (
        <AnimationComponent show={true} type="fade-in">
            <div className="flex flex-1 h-screen flex-col justify-center items-center">
                <span className="text-lg font-normal text-blueMainText">Login attempt failed</span>

                <Button
                    className="mt-4 !text-blueMainText w-40"
                    title="Retry"
                    icon={<Icon name={IconTypes.refresh} className="mr-2" />}
                    onClick={() => {
                        window.location.reload();
                    }}
                />
            </div>
        </AnimationComponent>
    );
};
