import React, { CSSProperties } from 'react';

interface RadioButtonProps {
    isChecked: boolean;
    onCheck?: () => any;
    className?: string;
    style?: CSSProperties;
    size?: 'small' | 'medium' | 'large';
}
export const RadioButton: React.FC<RadioButtonProps> = ({ isChecked, onCheck, className, size = 'medium' }) => {
    const heightClassName = (() => {
        let sizeNumber = size === 'large' ? 6 : size === 'medium' ? 5 : 4;
        return `h-${sizeNumber} w-${sizeNumber}`;
    })();

    return (
        <div
            onClick={() => onCheck && onCheck()}
            className={`${heightClassName} rounded-full p-1 border transition-all ${
                isChecked ? 'border-primaryBlue500' : 'border-mainGrayText'
            } ${className}`}
        >
            <div
                className={`h-full w-full rounded-full transition-all ${isChecked ? 'bg-primaryBlue500' : 'bg-none'}`}
            />
        </div>
    );
};
