import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_ADMIN_PATH, scaleAnimationClassName } from '../../constants';
import { UserDetailsResponseModel } from '../../models';
/**
 * Redux
 */
import { selectors as userSelectors } from '../../redux/thunk/app/user/userThunk';
import { actions as themeActions } from '../../redux/thunk/theme';
/**
 * Components
 */
import { Dropdown } from '../dropdown';
import { Loader } from '../ui';
import { ProfileItem, ProfileLanguages } from './components';
import { ProfileImage } from './ProfileImage';

export const MyProfile = () => {
    /**
     * Hooks
     */
    const auth = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    /**
     * Redux selectors
     */
    const isLoading = useSelector(userSelectors.getIsLoading);
    const { model: user }: UserDetailsResponseModel = useSelector(userSelectors.getResponse);

    const onSignOut = () => {
        if (auth) {
            dispatch(themeActions.setIsLoggingOut(true));
            auth?.signOutRedirect({ id_token_hint: auth.userData?.id_token });
        }
    };

    return (
        <Dropdown
            wrapperClassName="ml-2 z-40"
            withArrow={false}
            triggerProps={{
                children: (
                    <ProfileImage
                        className={scaleAnimationClassName}
                        isLoading={isLoading}
                        firstCharacter={(user?.fullName && user?.fullName?.length && user?.fullName[0]) || 'A'}
                        imageUrl={user.imageUrl}
                    />
                ),
            }}
        >
            <div className="mt-3 h-auto w-72 base-card-style rounded-lg dropdown-shadow z-40">
                {isLoading ? (
                    <div className="w-full flex items-center justify-center py-10 pl-0 pr-0">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="w-full">
                            <div className="flex flex-row justify-between pt-4 px-4">
                                <ProfileImage
                                    isLoading={isLoading}
                                    firstCharacter={user?.fullName[0] || 'D'}
                                    imageUrl={user.imageUrl}
                                />
                                <div className="flex flex-col flex-1 justify-between pl-4">
                                    <span className="font-medium text-greyBlack300 text-sm">{user?.fullName}</span>
                                    <span className="font-normal text-xs text-greysBlack100 mt-0.5">{user?.email}</span>
                                </div>
                            </div>
                            <ProfileItem
                                href="https://myaccount.gjirafa.tech/profile"
                                target="_blank"
                                title={t('@common.profile')}
                                className="mt-2 py-4 px-4"
                                iconName="Avatar"
                            />
                        </div>
                        {user?.isAdmin && !window.location.pathname.includes('/admin') && (
                            <ProfileItem
                                href={BASE_ADMIN_PATH}
                                iconName="Avatar"
                                className="border-t border-grey200 py-4 px-4"
                                title={t('@common.admin')}
                            />
                        )}
                        {window.location.pathname.includes('/admin') && (
                            <ProfileItem
                                iconName="Grid"
                                className="border-t border-grey200 py-4 px-4"
                                title={t('@common.main')}
                                href="/"
                            />
                        )}
                        <ProfileLanguages />
                        <ProfileItem
                            iconName="Redirect"
                            className="border-t border-grey200 py-4 px-4"
                            title={t('@common.log-out')}
                            onClick={onSignOut}
                        />
                    </>
                )}
            </div>
        </Dropdown>
    );
};
//
