import React, { CSSProperties } from 'react';
import { Tooltip } from '../../ui';
import { Icon } from '../Icon';

interface StatusComponentProps {
    status: any;
    message: string;
    className?: string;
    style?: CSSProperties;
    iconSize?: number;
    tooltipId?: string;
}

export const StatusComponent: React.FC<StatusComponentProps> = ({
    message,
    status,
    className,
    style,
    iconSize = 28,
    tooltipId = 'statusComponentTooltip',
}) => {
    return (
        <div
            className={`flex w-auto cursor-pointer justify-end items-center ${className}`}
            style={{ ...style }}
            data-for={tooltipId}
            data-tip={message}
        >
            <Icon size={iconSize} name={status} />
            <Tooltip globalEventOff="hover" id={tooltipId} className="min-w-auto border border-mainBlue" />
        </div>
    );
};
