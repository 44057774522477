import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { PageAnimationContainer } from '../../../components/animation/PageAnimationContainer';
import {
    FooterButtons,
    HeaderForCreatePages,
    RunScheduleComponent,
    SectionContainerForCreatePages,
} from '../../../components/common';
import { Error404 } from '../../../components/errors';
import { InputField, MultiSelectField, SelectWithFilterPopupField } from '../../../components/inputs';
import { SelectWithCatalogPopupField } from '../../../components/inputs/SelectWithCatalogPopupField';
import { Loader, toast } from '../../../components/ui';
import i18n from '../../../config/i18n';
import { BASE_RECOMMENDATION_PATH, conditionsOperators, NOT_FOUND_PATH, runSchedules } from '../../../constants';
import { useAccountId, useBiskoIntegrationError, useCurrentWidth, useDetailsError } from '../../../hooks';
import {
    BiskoPropertyListDataModel,
    RecommendationEngineDetailsResponseModel,
    RecommendationEnginePostModel,
    SchemaTypeListResponseModel,
    SelectOptionType,
} from '../../../models';
import { actions as catalogActions } from '../../../redux/thunk/app/catalog/catalogsThunk';
import { actions as filtersActions } from '../../../redux/thunk/app/filter/filtersThunk';
import {
    actions as schemaTypesActions,
    selectors as schemaTypesSelectors,
} from '../../../redux/thunk/app/schemaType/schemaTypesThunk';
/**
 * Redux
 */
import { selectors as biskoPropertiesSelectors } from '../../../redux/thunk/bisko/biskoPropertiesThunk';
/**
 * services - utils
 */
import axios from '../../../services/axios';
import {
    deleteErrorKey,
    formatArrayForSelectOptions,
    formatRecommendationType,
    getFormattedQuery,
    queryParams,
    showTimeDecay,
} from '../../../utils';
import { getRecommendationEngineFormSchema, onValidateWithYup } from '../../../validations';
/**
 * Local Components
 */
import { RenderFrequencyComponent } from './components/RenderFrequencyComponent';
import { RenderObjectiveCardsComponent } from './components/RenderObjectiveCardsComponent';
import { TimeDecayInput } from './components/TimeDecayInput';

type FieldValuesType = {
    title: string;
    propertyIds: string;
    catalogs: SelectOptionType[];
    filters: SelectOptionType[];
    operator: SelectOptionType | null;
    objectiveId: number;
    timeDecay?: number;
    frequency: number;
};

export const CreateRecommendationEnginePage = () => {
    /**
     * Hooks
     */
    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();
    const { t } = useTranslation();
    const currentWidth = useCurrentWidth();
    const accountId = useAccountId();
    const location = useLocation();
    const SchemaValidation = useMemo(
        () => i18n.isInitialized && !!t && getRecommendationEngineFormSchema(t),
        [i18n.isInitialized, t]
    );
    const { detailsError, handleError, resetError } = useDetailsError();

    useBiskoIntegrationError({
        selectors: biskoPropertiesSelectors,
        path: `${BASE_RECOMMENDATION_PATH}/${params?.type}`,
    });

    /**
     * Selectors
     */
    const biskoPropertiesData: BiskoPropertyListDataModel = useSelector(biskoPropertiesSelectors.getData);
    const biskoPropertiesLoading = useSelector(biskoPropertiesSelectors.getIsLoading);
    const schemaTypesResponse: SchemaTypeListResponseModel = useSelector(schemaTypesSelectors.getResponse);
    const schemaTypesLoading = useSelector(schemaTypesSelectors.getIsLoading);

    /**
     * constants
     */
    const isEditing = params?.id;
    const biskoPropertiesOptions: SelectOptionType[] = formatArrayForSelectOptions(
        biskoPropertiesData.properties,
        'name',
        'id'
    );

    /**
     * Local State
     */
    const [formLoading, setFormLoading] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [runScheduleData, setRunScheduleData] = useState({
        scheduleFrequency: '',
    });
    const [scheduleSelectedOption, setScheduleSelectedOption] = useState<SelectOptionType | null>(null);
    const [fieldValues, setFieldValues] = useState<FieldValuesType>({
        catalogs: [],
        propertyIds: '',
        operator: conditionsOperators[0],
        title: '',
        objectiveId: 0,
        frequency: 0,
        filters: [],
        timeDecay: showTimeDecay(params.type) ? 50 : 0,
    });
    const [recommendationFilters, setRecommendationFilters] = useState<{ id: number; filterId: number }[]>([]);
    const [recommendationCatalogs, setRecommendationCatalogs] = useState<{ id: number; catalogId: number }[]>([]);

    const runSchedulesOptions = useMemo(() => runSchedules.filter(x => x.value !== '5'), []);

    useEffect(() => {
        if (!params.type || !location.pathname.includes(params.type)) {
            history.push(NOT_FOUND_PATH + location.search);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (schemaTypesResponse.models.length < 1) {
            dispatch(schemaTypesActions.request());
        }
    }, []);

    useEffect(() => {
        if (params?.id) {
            fetchDetails(params.id);
        }
    }, [params?.id, accountId]);

    useEffect(() => {
        //Fetch catalogs and filters based on schema type
        if (schemaTypesResponse.models.length && !schemaTypesLoading && params?.type) {
            const schemaType = schemaTypesResponse.models.find(x => x.code === params.type) || null;

            let query = schemaType
                ? {
                      schemaTypeId: { label: schemaType.name, value: schemaType.id.toString() },
                  }
                : {};
            dispatch(filtersActions.request({ page: 0, ...query }));
            dispatch(catalogActions.request({ page: 0, catalog: false, ...query }));
        }
    }, [schemaTypesResponse, schemaTypesLoading, accountId, params?.type]);

    const fetchDetails = async id => {
        try {
            setDetailsLoading(true);
            resetError();

            const query = {
                accountId,
                id,
            };

            let { model }: RecommendationEngineDetailsResponseModel = await axios.get(
                `/recommendationEngine?${getFormattedQuery(query)}`
            );

            setFieldValues({
                catalogs: model.catalogs.map(x => ({
                    label: x.catalogName,
                    value: x.catalogId?.toString(),
                })),
                filters: model.filters.map(x => ({
                    label: x.filterTitle,
                    value: x.filterId?.toString(),
                })),
                timeDecay: model?.timeDecay ? model.timeDecay * 100 : 0,
                frequency: model.frequency,
                operator: conditionsOperators.find(x => x.value === model.filtersOperator) || conditionsOperators[0],
                objectiveId: model?.objective?.id ?? 0,
                title: model.title,
                propertyIds: model.propertyIds,
            });
            setRunScheduleData({
                scheduleFrequency: model.scheduleFrequency?.toString() || '',
            });
            setScheduleSelectedOption(runSchedulesOptions.find(y => y.value === model?.scheduleId?.toString()) || null);
            setRecommendationFilters(model.filters);
            setRecommendationCatalogs(model.catalogs);
        } catch (err: any) {
            handleError(err);
        } finally {
            setDetailsLoading(false);
        }
    };

    const onChangeFieldValues = (key: string, value: any) => {
        deleteErrorKey(key, setErrors);
        setFieldValues(prev => ({
            ...prev,
            [key]: value,
        }));
    };
    const onChangeRunScheduleData = (key, value) => {
        deleteErrorKey(key, setErrors);
        setRunScheduleData(prev => ({ ...prev, [key]: value }));
    };

    const handleCancel = () => history.goBack();

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            if (formLoading || !SchemaValidation) return;

            const { catalogs, filters, frequency, objectiveId, operator, propertyIds, title, timeDecay } = fieldValues;
            let payload: RecommendationEnginePostModel = {
                accountId,
                id: isEditing ? params?.id : 0,
                title,
                type: params?.type,
                filtersOperator: operator?.value ?? '',
                frequency,
                objectiveId,
                propertyIds: propertyIds,
                runImmediately: true,
                scheduleId: scheduleSelectedOption ? parseInt(scheduleSelectedOption?.value) : 0,
                scheduleFrequency: parseInt(runScheduleData.scheduleFrequency) || 0,
                catalogs: catalogs.map(x => {
                    let catalogId = parseInt(x.value) ?? 0;
                    return {
                        id: isEditing ? recommendationCatalogs.find(x => x.catalogId === catalogId)?.id ?? 0 : 0,
                        catalogId,
                    };
                }),
                filters: filters.map(x => {
                    let filterId = parseInt(x.value) ?? 0;
                    return {
                        id: isEditing ? recommendationFilters.find(x => x.filterId === filterId)?.id ?? 0 : 0,
                        filterId,
                    };
                }),
                description: '',
            };
            if (showTimeDecay(params.type)) {
                payload.timeDecay = (timeDecay && timeDecay / 100) ?? 0;
            }
            const { isValid, errors } = await onValidateWithYup(SchemaValidation, { ...payload });
            setErrors(errors);
            if (!isValid) return;

            setFormLoading(true);

            await axios.post(`/recommendationEngine`, payload);

            toast.success(
                `${formatRecommendationType(params?.type)} Recommendation engine has been ${
                    isEditing ? 'edited' : 'created'
                }!`
            );
            history.push(`${BASE_RECOMMENDATION_PATH}/${params.type}` + queryParams.formatForNavigation());
        } catch (err: any) {
        } finally {
            setFormLoading(false);
        }
    };

    const { formId, buttonTitle } = useMemo(
        () => ({
            formId: 'createRecommendationForm',
            buttonTitle: t(`@common.${isEditing ? 'edit' : 'create'}`),
        }),
        [isEditing]
    );

    if (isEditing && detailsError.status === 404) {
        return (
            <Error404
                model="recommendationEngine"
                onClickButton={() => {
                    history.push(`${BASE_RECOMMENDATION_PATH}/${params?.type}${queryParams.formatForNavigation()}`);
                }}
            />
        );
    }

    return (
        <PageAnimationContainer>
            <div className="flex flex-col">
                {detailsLoading ? (
                    <div className="w-full flex justify-center items-center p-10">
                        <Loader size={40} />
                    </div>
                ) : (
                    <form className="flex flex-col items-center" id={formId} onSubmit={handleSubmit}>
                        <HeaderForCreatePages hideIcon title={t('@common.basic-information')} />
                        <SectionContainerForCreatePages className="flex flex-col">
                            <InputField
                                disabled={formLoading}
                                label={t('@common.title')}
                                name={`title-${params?.type}`}
                                required
                                value={fieldValues.title}
                                onChange={e => onChangeFieldValues('title', e.target.value)}
                                placeholder={t('@common.write.here')}
                                errorMessage={errors['title']}
                            />
                            <MultiSelectField
                                disabled={formLoading}
                                errorMessage={errors?.propertyIds}
                                options={biskoPropertiesOptions}
                                isLoading={biskoPropertiesLoading}
                                className="mt-4"
                                value={fieldValues.propertyIds}
                                onChange={value => onChangeFieldValues('propertyIds', value)}
                                label={t('@pages.bisko-properties')}
                                placeholder={`${t('@common.choose-a') + ' Property'}`}
                            />
                        </SectionContainerForCreatePages>

                        <HeaderForCreatePages
                            hideIcon
                            title={t('@recommendation.catalog.and.filters', { defaultValue: 'Catalog & Filters' })}
                            className="mt-10"
                        />
                        <SectionContainerForCreatePages className="flex flex-col">
                            <SelectWithCatalogPopupField
                                label={t('@common.catalogs', { defaultValue: 'Catalogs' })}
                                placeholder={t('@select.catalogs')}
                                renderSelectedItemsType="full-width"
                                required
                                commonTableProps={{
                                    hideFilters: true,
                                }}
                                options={{
                                    multiple: true,
                                    showSelectedItemInside: false,
                                }}
                                onChangeSelectedItems={(items, toDelete = false) => {
                                    let schemaTypeCodes = items.map((x: any) => x?.schemaTypeCode);
                                    if (!schemaTypeCodes.every(x => x === params?.type) && !toDelete) {
                                        toast.error(
                                            `Cannot select this catalog, you can only select catalogs with "${params?.type}" Schema type!`
                                        );
                                        return;
                                    }
                                    onChangeFieldValues('catalogs', items);
                                }}
                                selectedItems={fieldValues.catalogs}
                                errorMessage={errors?.catalogs}
                            />
                            <SelectWithFilterPopupField
                                className="mt-4"
                                placeholder={t('@select.filters')}
                                operatorSelected={fieldValues.operator}
                                onChangeOperator={item => onChangeFieldValues('operator', item)}
                                selectedItems={fieldValues.filters}
                                onChangeSelectedItems={items => onChangeFieldValues('filters', items)}
                                renderSelectedItemsType="full-width"
                                multiple
                                label={t('Filters')}
                                errorMessage={errors?.filters}
                                commonTablePros={{
                                    hideFilters: true,
                                }}
                            />
                        </SectionContainerForCreatePages>

                        <HeaderForCreatePages
                            title={t('@recommendation.advancedOptions', { defaultValue: 'Advanced options' })}
                            className="mt-10"
                            hideIcon
                        />
                        <SectionContainerForCreatePages>
                            <RenderObjectiveCardsComponent
                                isEditing={isEditing}
                                recommendationType={params?.type}
                                selectedObjectiveId={fieldValues.objectiveId}
                                setSelectedObjectiveId={id => onChangeFieldValues('objectiveId', id)}
                                errorMessage={errors?.objectiveId}
                            />
                            {showTimeDecay(params?.type) && (
                                <>
                                    <div className="w-full  border-t border-mainBorder h-0.5 mt-6" />
                                    <TimeDecayInput
                                        value={fieldValues.timeDecay ?? 0}
                                        onChange={value => onChangeFieldValues('timeDecay', value)}
                                    />
                                </>
                            )}
                            <div className="w-full  border-t border-mainBorder h-0.5 mt-6" />
                            <RenderFrequencyComponent
                                className="mt-6"
                                frequencySelected={fieldValues.frequency}
                                onChangeFrequencySelected={frequency => onChangeFieldValues('frequency', frequency)}
                            />
                        </SectionContainerForCreatePages>

                        <RunScheduleComponent
                            scheduleError={errors?.scheduleId}
                            scheduleFrequencyError={errors?.scheduleFrequency}
                            width="small"
                            onChangeRunScheduleData={onChangeRunScheduleData}
                            onChangeScheduleSelected={value => {
                                setScheduleSelectedOption(value);
                                deleteErrorKey('scheduleId', setErrors);
                            }}
                            runScheduleData={runScheduleData}
                            scheduleSelectedOption={scheduleSelectedOption}
                            runSchedulesOptions={runSchedulesOptions}
                        />

                        <HeaderForCreatePages title="" className="mt-10" hideIcon />
                        <FooterButtons
                            hideSave
                            onClickCancel={handleCancel}
                            onClickThirdButton={handleSubmit}
                            thirdButtonProps={{
                                type: 'submit',
                                form: formId,
                                loading: formLoading,
                                disabled: formLoading,
                            }}
                            thirdButtonTitle={buttonTitle}
                            style={{
                                minWidth: currentWidth < 900 ? '100%' : 546,
                            }}
                        />
                    </form>
                )}
            </div>
        </PageAnimationContainer>
    );
};
