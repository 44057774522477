import AXIOS, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { nanoid } from 'nanoid';
import { storageUploadToS3, storageUploadToS3FileProps } from '../../../../services/s3-services';
import { actionCreator } from '../../../../utils';
import { RootState } from '../../rootReducer';

/**
 * Types
 */
export const actionTypes = {
    SET_UPLOAD_OBJECT_BY_ID: '@app/storage/uploadObjectThunk/SET_UPLOAD_OBJECT_BY_ID',
    SET_UPLOAD_OBJECTS: '@app/storage/uploadObjectThunk/SET_UPLOAD_OBJECTS',
    SET_HIDE_OBJECT_BY_ID: '@app/storage/uploadObjectThunk/SET_HIDE_OBJECT_BY_ID',
    SET_IS_DIALOG_HIDDEN: '@app/storage/uploadObjectThunk/SET_IS_DIALOG_HIDDEN',
    SET_OBJECTS_SUCCEEDED: '@app/storage/uploadObjectThunk/SET_OBJECTS_SUCCEEDED',
    SET_OBJECTS_FAILED: '@app/storage/uploadObjectThunk/SET_OBJECTS_FAILED',
    APPEND_FAILED_OBJECT: '@app/storage/uploadObjectThunk/APPEND_FAILED_OBJECT',
    APPEND_SUCCEEDED_OBJECT: '@app/storage/uploadObjectThunk/APPEND_SUCCEEDED_OBJECT',
};

/**
 * Initial state
 */
export interface ObjectUploadingType {
    id: string;
    uploadProgress: number;
    title: string;
    cancelToken: CancelTokenSource;
    errorMessage: string;
}

type initialStateType = {
    objectsUploading: ObjectUploadingType[];
    objectsSucceeded: string[];
    objectsFailed: string[];
    isDialogHidden: boolean;
};

const initialState: initialStateType = {
    objectsUploading: [],
    objectsSucceeded: [],
    objectsFailed: [],
    isDialogHidden: false,
};

/**
 * selectors
 */
export const selectors = {
    getObjectsUploading: (state: RootState) => state.app.uploadObject.objectsUploading,
    getIsDialogHidden: (state: RootState) => state.app.uploadObject.isDialogHidden,
    getObjectsFailed: (state: RootState) => state.app.uploadObject.objectsFailed,
    getObjectsSucceeded: (state: RootState) => state.app.uploadObject.objectsSucceeded,
};

/**
 * Reducer
 */
const {
    SET_UPLOAD_OBJECTS,
    SET_UPLOAD_OBJECT_BY_ID,
    SET_HIDE_OBJECT_BY_ID,
    SET_IS_DIALOG_HIDDEN,
    SET_OBJECTS_FAILED,
    SET_OBJECTS_SUCCEEDED,
    APPEND_FAILED_OBJECT,
    APPEND_SUCCEEDED_OBJECT,
} = actionTypes;

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case APPEND_SUCCEEDED_OBJECT:
            return {
                ...state,
                objectsSucceeded: [...state.objectsSucceeded, payload],
            };
        case APPEND_FAILED_OBJECT:
            return {
                ...state,
                objectsFailed: [...state.objectsFailed, payload],
            };
        case SET_OBJECTS_FAILED:
            return {
                ...state,
                objectsFailed: payload,
            };
        case SET_OBJECTS_SUCCEEDED:
            return {
                ...state,
                objectsSucceeded: payload,
            };
        case SET_UPLOAD_OBJECTS:
            return {
                ...state,
                objectsUploading: payload,
            };
        case SET_UPLOAD_OBJECT_BY_ID:
            return {
                ...state,
                objectsUploading: state.objectsUploading.map(_object => {
                    if (_object.id === payload?.id) {
                        return {
                            ..._object,
                            ...payload?.item,
                        };
                    }
                    return _object;
                }),
            };
        case SET_HIDE_OBJECT_BY_ID:
            return {
                ...state,
                objectsUploading: state.objectsUploading.filter(x => x.id !== payload),
            };
        case SET_IS_DIALOG_HIDDEN:
            return {
                ...state,
                isDialogHidden: payload,
            };
        default:
            return state;
    }
};
export default Reducer;

/**
 * Actions
 */
type uploadFilesType = {
    fileContents: File;
    key: string;
    name: string;
    accountId: number;
};
export const actions = {
    setObjectsUploading: (arr: ObjectUploadingType[]) => actionCreator(SET_UPLOAD_OBJECTS, arr),
    resetFailedObjects: () => actionCreator(SET_OBJECTS_FAILED, []),
    resetSucceededObjects: () => actionCreator(SET_OBJECTS_SUCCEEDED, []),
    closeSingleUpload: (id: string) => actionCreator(SET_HIDE_OBJECT_BY_ID, id),
    upload: function (
        { fileContents, accountId, name: fileName, key }: uploadFilesType,
        successCallback?: (name: string) => void
    ) {
        return async (dispatch, getState: () => RootState) => {
            try {
                let uploadObject = getState().app.uploadObject;
                const CancelToken = AXIOS.CancelToken;
                const source = CancelToken.source();
                const _uploadObject: ObjectUploadingType = {
                    id: nanoid(),
                    cancelToken: source,
                    title: fileName,
                    uploadProgress: 0,
                    errorMessage: '',
                };

                dispatch(actionCreator(SET_IS_DIALOG_HIDDEN, false));
                dispatch(actionCreator(SET_UPLOAD_OBJECTS, [...uploadObject.objectsUploading, _uploadObject]));

                let axiosConfig: AxiosRequestConfig = {
                    cancelToken: _uploadObject.cancelToken.token,
                    headers: {
                        uploadId: _uploadObject.id,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': fileContents.type || '',
                        'x-amz-acl': 'private',
                    },
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(
                            actionCreator(SET_UPLOAD_OBJECT_BY_ID, {
                                id: _uploadObject.id,
                                item: {
                                    uploadProgress: percentCompleted,
                                },
                            })
                        );
                    },
                };

                let uploadToS3Props: storageUploadToS3FileProps = {
                    fileContents,
                    accountId,
                    key,
                    uploadId: _uploadObject.id,
                };
                await storageUploadToS3(uploadToS3Props, axiosConfig);

                dispatch(actionCreator(APPEND_SUCCEEDED_OBJECT, fileName));
                successCallback && successCallback(fileName);
                dispatch(actionCreator(SET_HIDE_OBJECT_BY_ID, _uploadObject.id));
            } catch (err: any) {
                const uploadObject = getState().app.uploadObject;
                let uploadId = err?.config?.headers?.uploadId;

                if (AXIOS.isCancel(err)) {
                    return;
                }

                dispatch(actionCreator(APPEND_FAILED_OBJECT, uploadId));

                let message = err?.response?.data?.message || err?.message;
                dispatch(
                    actionCreator(
                        SET_UPLOAD_OBJECTS,
                        uploadObject.objectsUploading?.map((_objectUploading: ObjectUploadingType) => {
                            if (_objectUploading?.id === uploadId) {
                                return {
                                    ..._objectUploading,
                                    errorMessage: message,
                                };
                            }
                            return _objectUploading;
                        })
                    )
                );
            }
        };
    },
};
