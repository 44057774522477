import { Loader } from '../ui';
import { createPortal } from 'react-dom';

export interface LoadingModalProps {
    height?: number;
    width?: number;
    color?: string;
    className?: string;
    isVisible?: boolean;
}

export const LoadingModal: React.FC<LoadingModalProps> = ({
    color = '#1D79F2',
    width = 60,
    height = 60,
    className,
    isVisible,
}) => {
    if (!isVisible) {
        return null;
    }
    return createPortal(
        <div className="w-full h-full fixed flex items-center justify-center bg-mainOverlay/40 inset-0 z-50">
            <Loader height={height} width={width} className={className} color={color} />
        </div>,
        document.body
    );
};
