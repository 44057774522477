import React, { CSSProperties, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { inputAnimationClassName } from '../../constants';
import { useToggleVisibility } from '../../hooks';
import { SelectOptionType } from '../../models';
import { Icon, IconTypes } from '../common';
import { ErrorTextComponent } from '../messages';
import { FullWrapperModal } from '../modals';
import { CommonTableProps } from '../tables';
import { CatalogsTable } from '../tables/CatalogsTable';
import { Loader } from '../ui';
import { RenderSelectedItemsComponent } from './components/RenderSelectedItemsComponent';

interface SelectWithCatalogPopupFieldProps {
    label?: string;
    loading?: boolean;
    labelStyle?: CSSProperties;
    className?: string;
    style?: CSSProperties;
    errorMessage?: string;
    errorStyle?: CSSProperties;
    required?: boolean;
    placeholder?: string;
    selectedItems: SelectOptionType[];
    onChangeSelectedItems: (items: SelectOptionType[]) => any;
    disabled?: boolean;
    isClearable?: boolean;
    labelRight?: ReactNode;
    renderSelectedItemsType?: 'auto-width' | 'full-width';
    options: {
        multiple?: boolean;
        showSelectedItemInside?: boolean;
    };
    commonTableProps?: CommonTableProps;
}
export const SelectWithCatalogPopupField: React.FC<SelectWithCatalogPopupFieldProps> = ({
    className,
    disabled,
    errorMessage,
    isClearable,
    label,
    labelRight,
    loading,
    placeholder,
    required,
    style,
    selectedItems,
    commonTableProps,
    renderSelectedItemsType,
    onChangeSelectedItems,
    options,
}) => {
    const { t } = useTranslation();
    const [isModalVisible, shouldRenderModal, onToggleVisibility] = useToggleVisibility();

    const onHideModal = useCallback(() => onToggleVisibility(false), []);
    const onShowModal = useCallback(() => onToggleVisibility(true), []);

    const inputText = useMemo(() => {
        if (selectedItems.length < 1) return placeholder;

        if (options?.showSelectedItemInside) {
            return selectedItems[0].label;
        }

        return t('@items.selected', { count: selectedItems.length, defaultValue: '{{count}} selected!' });
    }, [options, placeholder, selectedItems, t]);

    return (
        <>
            <div style={{ ...style }} className={`flex flex-col w-full ${className}`}>
                {label && (
                    <div
                        style={{
                            minHeight: 24,
                        }}
                        className={`flex mb-1 flex-row justify-between items-center text-left`}
                    >
                        <label className="text-xs sm:text-sm text-secondaryText font-normal">
                            {label} {required && <span className="text-mainError text-base">{' *'}</span>}
                        </label>
                        {labelRight && labelRight}
                    </div>
                )}
                <button
                    type="button"
                    className={`
                        ${disabled ? 'bg-disabledColor cursor-not-allowed' : 'cursor-pointer'} 
                        w-full bg-white rounded box-border flex flex-row items-center justify-between py-2 px-3 h-8 sm:h-10 border 
                        ${errorMessage ? 'border-mainError transition-all duration-300 ease-in' : 'border-grey200'}
                        ${!disabled && inputAnimationClassName}
                    `}
                    onClick={e => {
                        e.stopPropagation();
                        !disabled && onShowModal();
                    }}
                >
                    <span
                        className={`truncate font-normal text-xs sm:text-sm ${
                            selectedItems.length ? 'text-blueMainText' : 'text-mainPlaceholder'
                        }`}
                    >
                        {loading ? <Loader /> : inputText}
                    </span>
                    {isClearable && selectedItems.length > 0 && (
                        <Icon
                            name={IconTypes.close}
                            size={22}
                            onClick={e => {
                                e.stopPropagation();
                                onChangeSelectedItems && onChangeSelectedItems([]);
                            }}
                        />
                    )}
                </button>
                <ErrorTextComponent message={errorMessage} />
                {(!options?.showSelectedItemInside || options?.multiple) && (
                    <RenderSelectedItemsComponent
                        entity="catalog"
                        onChangeSelectedItems={onChangeSelectedItems}
                        selectedItems={selectedItems}
                        renderType={renderSelectedItemsType}
                    />
                )}
                {/* <div className="flex flex-row flex-wrap mt-1 -ml-1">
                    {options?.multiple &&
                        !options.showSelectedItemInside &&
                        selectedItems.map(item => (
                            <SelectedOption
                                label={item.label}
                                onRemove={() =>
                                    onChangeSelectedItems(selectedItems.filter(x => x.value !== item.value))
                                }
                            />
                        ))}
                </div> */}
            </div>

            {shouldRenderModal && (
                <FullWrapperModal isVisible={isModalVisible} onHide={onHideModal}>
                    <div className="text-xl flex flex-row justify-between items-center px-4 mb-2 md:pl-6 pt-3 z-10 font-semibold text-secondaryText  top-0 w-full bg-white">
                        {t('@common.catalogs', { defaultValue: 'Catalogs' })}
                        <span className="cursor-pointer p-2.5" onClick={() => onHideModal()}>
                            <Icon name={IconTypes.close} size={30} />
                        </span>
                    </div>
                    <CatalogsTable
                        onChangeSelectedRows={items => {
                            onChangeSelectedItems(items.map(x => ({ label: x.name, value: x.id.toString(), ...x })));
                            onHideModal();
                        }}
                        fetchDataOnMount={false}
                        isFromSelectPopup={true}
                        className="px-4 flex-1"
                        multiple={options.multiple}
                        {...commonTableProps}
                    />
                </FullWrapperModal>
            )}
        </>
    );
};
