import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnimationComponent } from '../components/animation';
import { Button } from '../components/buttons';
import { Icon, IconTypes } from '../components/common';
import { FixedLoader } from '../components/ui';
import { useLocationBlocker } from '../hooks';
import { UserDetailsResponseModel } from '../models';
import { actions as userDetailsActions } from '../redux/thunk/app/user/userThunk';
import { selectors as themeSelectors } from '../redux/thunk/theme';
import axios from '../services/axios';
import { NotAuthorized } from './NotAuthorized';
import dayjs from 'dayjs';
import { LoginLoading } from './components';

export const Protected = ({ children }) => {
    useLocationBlocker();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const auth = useAuth();

    const [fetchUserFailed, setFetchUserFailed] = useState({
        message: '',
        error: false,
    });
    const [loadingType, setLoadingType] = useState<'login' | 'user' | null>('login');
    const isLoggingOut = useSelector(themeSelectors.getIsLoggingOut);

    useEffect(() => {
        auth.userManager.events.addSilentRenewError(e => {
            console.log(e, 'Error');
        });
        checkForExpiredToken();
        if (!auth.isLoading && auth.userData) {
            let loadingTimeout = setTimeout(() => {
                fetchUser();
            }, 200);

            return () => clearTimeout(loadingTimeout);
        }
    }, [auth]);

    const checkForExpiredToken = () => {
        if (auth.userData && dayjs().unix() >= auth.userData?.expires_at) {
            auth.userManager.signinSilent().catch(err => {
                window.location.replace(window.location.host);
            });
        }
    };

    const fetchUser = async () => {
        try {
            setLoadingType('user');
            setFetchUserFailed({
                message: '',
                error: false,
            });

            const response: UserDetailsResponseModel = await axios.get('/user');
            dispatch(userDetailsActions.updateResponse(response));

            const previousLanguage = i18n.language || 'en-US';
            const newLanguage = response.model.languageIsoCode || previousLanguage;

            if (previousLanguage !== newLanguage) {
                i18n.changeLanguage(newLanguage);
            }
        } catch (err: any) {
            setFetchUserFailed({
                message: err.message || '',
                error: true,
            });
        } finally {
            setLoadingType(null);
        }
    };

    const onReload = () => window.location.reload();

    if (fetchUserFailed.error) {
        return (
            <AnimationComponent show={true} type="fade-in">
                <div className="flex flex-1 h-screen flex-col justify-center items-center">
                    <span className="text-lg font-normal text-blueMainText">Coudn't fetch User Data</span>
                    {fetchUserFailed.message && (
                        <span className="mt-2 text-mainError font-normal text-lg">
                            {' '}
                            <span className="text-blueMainText mr-1">message :</span> {fetchUserFailed.message}
                        </span>
                    )}
                    <Button
                        className="mt-4 !text-blueMainText w-40"
                        title="Retry"
                        icon={<Icon name={IconTypes.refresh} className="mr-2" />}
                        onClick={onReload}
                    />
                </div>
            </AnimationComponent>
        );
    }

    if (loadingType) {
        return <LoginLoading text={loadingType === 'user' ? 'Loading user data' : 'Logging in'} />;
    }

    if (auth && auth.userData) {
        return children;
    }

    if (isLoggingOut)
        return (
            <FixedLoader>
                <span className="mt-2 text-blueMainText font-normal text-lg">Logging out</span>
            </FixedLoader>
        );

    return <NotAuthorized />;
};
